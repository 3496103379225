import React, { Component } from "react";
import { Table } from "antd";
import moment from "moment";

const { Column } = Table;

export default class ShowSalesDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      returnData: [],
  }};
  shouldComponentUpdate(nextProps, prevState) {
    if (
      nextProps.data != undefined &&
      nextProps.data.length != prevState.returnData.length
    ) {
      this.setState({
        returnData: nextProps.data
        // .map(item =>({
        //   ...item,
        //   quantity: item.quantity || 1,
        // })),
        
      });console.log("ShowSalesDetails ",nextProps.data)
    }

    return true;
  }

  render() {
    console.log("#this.props.salesBillsUser", this.props.salesBillsUser);
    console.log("#this.props.showreturnlistdata", this.state.showReturnListData);
    console.log("#this.props.salesreturndata", this.state.salesReturnData);
    return (
      <div className="sale-list">
        <Table
          dataSource={this.props.salesBillsUser}
          bordered="true"
          size="middle"
          pagination={false}
          scroll={{ y: 240 }}
        >
          <Column
            title="SRN"
            key="index"
            render={(value, item, index) => index + 1}
            width={60}
          />
          <Column
            title="Invoice Id"
            dataIndex="invoiceId"
            key="invoiceId"
            width={90}
          />
          <Column
            title="Bill Date"
            dataIndex="created_at"
            key="created_at"
            width={90}
            render={(value, item, index) =>
              moment(item.created_at).format("YYYY-MM-DD")
            }
          />
          <Column
            title="Total Boxes"
            dataIndex="totalBoxes"
            key="totalBoxes"
            width={90}
          />
          <Column
            title="Total Qnty"
            dataIndex="totalQuantity"
            key="saleReturnQty"
            width={100}
          />
          {/* <Column
            title="Total Amt"
            dataIndex="totalAmount"
            key="totalAmount"
            width={100}
          /> */}
          <Column
            title="Total Price"
            dataIndex="totalAmount"
            key="totalAmount"
            width={100}
          />
          
        </Table>
      </div>
    );
  }
}
