import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/Daga_Impex_Logo.png";
const Logo = ({ imageUrl, logoClass }) => {
  return (
    <div className={`${logoClass ? logoClass : ""}`}>
      <Link to={process?.env?.PUBLIC_URL + "/"}>
        {/** <img alt="" src={process.env.PUBLIC_URL + imageUrl} />*/}
        <div>
        <img
            src={logo}
            alt="Logo"
            width={85}
            height={60}
            style={{ margin: "0 0 0 0" }}
          />

        </div>
      </Link>
    </div>
  );
};

Logo.propTypes = {
  imageUrl: PropTypes.string,
  logoClass: PropTypes.string,
};

export default Logo;