import React, { Component } from "react";
import { Row, Col, Breadcrumb, AutoComplete, Button } from "antd";
import EcomSaleList from "./EcomSaleList";
import { NavLink } from "react-router-dom";

export default class EcomSale extends Component {
  render() {
    return (
      <div className="subCategory-dashboard">
        <div className="container-fluid category-list">
          <EcomSaleList />
        </div>
      </div>
    );
  }
}
