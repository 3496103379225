import React, { Component } from "react";
import {
    Row,
    Col,
    Tooltip,
    Form,
    Select,
    DatePicker,
    Button,
    message,
    Input,
} from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import {
    getStockReport,
} from "../../../../redux/actions/reportsActions";
import debounce from "lodash.debounce";
import DateWiseSearch from "../../../../util/DateWiseSearch";
import TopBarProgress from "react-topbar-progress-indicator";
import StockReportList from "./StockReportList";
import { getBrandBySearch } from "../../../../redux/actions/brandAction";
import { getCategories } from "../../../../redux/actions/categoryAction";
import { getGroups } from "../../../../redux/actions/groupAction";
import { getStyles } from "../../../../redux/actions/styleAction";
import { getSeasons } from "../../../../redux/actions/seasonAction";
import { getSections } from "../../../../redux/actions/sectionAction";
import { getSizes } from "../../../../redux/actions/sizeAction";
import { getFloors } from "../../../../redux/actions/floorAction";
import BackButton from "../../Report/Button";

const dateFormat = "YYYY-MM-DD";
const { Option } = Select;
class StockReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            styleName: "",
            styleId: "",
            categoryName: "",
            categoryId: "",
            groupName: [],
            groupId: "",
            seasonName: [],
            seasonId: "",
            sectionName: [],
            sectionId: "",
            brandName: [],
            brandId: "",
            floorName: [],
            floorId: "",
            sizeName: [],
            sizeId: "",
            designNumber: "",
            barcodes: []
        };
        this.debouncedGetData = debounce(this.getData, 500);
    }

    componentWillUnmount(){
        this.props.getStockReport("reset");
      }
      
    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };
    handleChangeBrand = (key, value) => {
        let brandName = value.map((obj) => obj.label);
        this.setState({ brandName });
        this.debouncedGetData("brand");
    };
    handleChangeCategory = (key, value) => {
        this.setState({ categoryId: value.value, categoryName: value.name });
        this.debouncedGetData("category");
    };
    handleChangeGroup = (key, value) => {
        let groupName = value.map((obj) => obj.label);
        this.setState({ groupName });
        this.debouncedGetData("group");
    };
    handleChangeStyle = (key, value) => {
        this.setState({ styleId: value.value, styleName: value.name });
        this.debouncedGetData("style");
    };
    handleChangeSeason = (key, value) => {
        let seasonName = value.map((obj) => obj.label);
        this.setState({ seasonName });
        this.debouncedGetData("season");
    };
    handleChangeSection = (key, value) => {
        let sectionName = value.map((obj) => obj.label);
        this.setState({ sectionName });
        this.debouncedGetData("section");
    };
    handleChangeSize = (key, value) => {
        let sizeName = value.map((obj) => obj.label);
        this.setState({ sizeName });
        this.debouncedGetData("size");
    };
    handleChangeFloor = (key, value) => {
        let floorName = value.map((obj) => obj.label);
        this.setState({ floorName });
        this.debouncedGetData("floor");
    };

    getData = (name, value) => {
        if (name == "brand") this.props.getBrandBySearch(value);
        if (name == "category") {
            this.props.getCategories(value);
        }
        if (name == "group") this.props.getGroups(value);
        if (name == "style") {
            if (this.state.categoryId != "") {
                this.props.getStyles(this.state.categoryId);
            } else {
                message.warning("Please Select Category");
            }
        }
        if (name == "season") this.props.getSeasons(value);
        if (name == "section") this.props.getSections(value);
        if (name == "size") this.props.getSizes(value);
        if (name == "floor") this.props.getFloors(value);
    };

    // Function to generate the request body
    generateRequestBody = () => {
        let state = {
            group: this.state.groupName,
            season: this.state.seasonName,
            section: this.state.sectionName,
            Brand: this.state.brandName,
            floor: this.state.floorName,
            size: this.state.sizeName,
            barcode: this.state.barcodes,
            Category: this.state.categoryName ? [this.state.categoryName] : [],
            style: this.state.styleName ? [this.state.styleName] : [],
            designNumber: this.state.designNumber ? [this.state.designNumber] : [],
        };
        let finalState = { type: "" };
        for (let obj in state) {
            if (state[obj].length > 0) {
                finalState.type += obj + " & ";
                finalState[obj] = state[obj];
            }
        }
        if (finalState.type.endsWith(" & ")) {
            finalState.type = finalState.type.slice(0, -3);
        }
        return finalState;
    };

    handleChangeBarcode = (value) => {
        this.setState({ barcodes: value })
    }

    submitForm = () => {
        let status = true
        const requestBody = this.generateRequestBody();
        if (requestBody.barcode && requestBody.barcode.length > 0) {
            for (let index = 0; index < requestBody.barcode.length; index++) {
                if (requestBody.barcode[index].length > 9 || requestBody.barcode[index].length < 9) {
                    status = false
                }
            }
        }
        if (status) {
            this.props.getStockReport(requestBody);
        } else {
            message.error("Barcode must be 9 digit.")
        }
    };

    render() {
        let brandOptions = this.props?.brandBysearch?.data?.map((brand) => {
            return {
                value: brand.name,
                label: brand.name,
            };
        });

        let categoryOptions = this.props.categories.map((category) => {
            return (
                <Option name={category.name} value={category._id}>
                    {category.name}
                </Option>
            );
        });
        let groupOptions = this.props.groups.map((group) => {
            return {
                value: group.name,
                label: group.name,
            };
        });
        let styleOptions = this.props.styles.map((style) => {
            return (
                <Option name={style.name} value={style._id}>
                    {style.name}
                </Option>
            );
        });
        let seasonOptions = this.props.seasons.map((season) => {
            return {
                value: season.name,
                label: season.name,
            };
        });
        let sectionOptions = this.props.sections.map((section) => {
            return {
                value: section.name,
                label: section.name,
            };
        });
        let sizeOptions = this.props.sizes.map((size, index) => {
            return {
                value: size.name,
                label: size.name,
                key: index
            };
        });
        let floorOptions = this.props.floors.map((floor) => {
            return {
                value: floor.name,
                label: floor.name,
            };
        });
        return (
            <div className="subCategory-dashboard">
                {this.props.progressBarStatus && <TopBarProgress />}
                <div className="container-fluid category-list">
                    {/* ==============Search Bar and Date Filter================= */}
                    {/* <Row className="title-row">
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <div className="page-header">Stock Report</div>
                        </Col>
                    </Row> */}
                    <Row className="title-row">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <div className="page-header">Stock Report</div>
                                </Col>
                                <Col span={12}>
                                    <BackButton />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div className="card mt-2 final-purchases-list mt-4">
                        <div className="card-body">
                            <div className="mb-5 mt-2">
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <Form
                                            ref={this.formRef}
                                            name="stock-report"
                                            className="add-Purchase"
                                            onFinish={this.submitForm}
                                        // initialValues={initialValues}
                                        >
                                            <Row gutter={[8, 0]}>
                                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                    <Tooltip title="Enter Design Number">
                                                        <Form.Item
                                                        // rules={[
                                                        //     {
                                                        //         required: true,
                                                        //         message: "Please Enter Design Number !",
                                                        //     },
                                                        // ]}
                                                        >
                                                            <Input
                                                                placeholder="Enter Design Number"
                                                                id="designNumber"
                                                                name="designNumber"
                                                                value={this.state.designNumber}
                                                                onChange={this.onChange}
                                                                required
                                                                maxLength={15}
                                                            />
                                                        </Form.Item>
                                                    </Tooltip>
                                                </Col>

                                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                    <Tooltip title="Select Category">
                                                        <Form.Item
                                                            name="category"
                                                        // rules={[
                                                        //     {
                                                        //         required: true,
                                                        //         message: "Please Select Category Name !",
                                                        //     },
                                                        // ]}
                                                        >
                                                            <Select
                                                                // disabled={disabled}
                                                                name="category"
                                                                id="category"
                                                                showSearch
                                                                placeholder="Select Category"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.children
                                                                        .toLowerCase()
                                                                        .indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                filterSort={(optionA, optionB) =>
                                                                    optionA.children
                                                                        .toLowerCase()
                                                                        .localeCompare(
                                                                            optionB.children.toLowerCase()
                                                                        )
                                                                }
                                                                onChange={this.handleChangeCategory}
                                                                onClick={() => {
                                                                    this.debouncedGetData("category");
                                                                }}
                                                                onInputKeyDown={() => {
                                                                    this.debouncedGetData("category");
                                                                }}
                                                            >
                                                                {categoryOptions}
                                                            </Select>
                                                        </Form.Item>
                                                    </Tooltip>
                                                </Col>

                                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                    <Tooltip title="Select Style">
                                                        <Form.Item
                                                            name="style"
                                                        // rules={[
                                                        //     {
                                                        //         required: true,
                                                        //         message: "Please Select Style Name !",
                                                        //     },
                                                        // ]}
                                                        >
                                                            <Select
                                                                // disabled={true}
                                                                name="style"
                                                                id="style"
                                                                showSearch
                                                                placeholder="Select Style"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.children
                                                                        .toLowerCase()
                                                                        .indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                filterSort={(optionA, optionB) =>
                                                                    optionA.children
                                                                        .toLowerCase()
                                                                        .localeCompare(
                                                                            optionB.children.toLowerCase()
                                                                        )
                                                                }
                                                                onChange={this.handleChangeStyle}
                                                                onClick={() => this.debouncedGetData("style")}
                                                                onInputKeyDown={() =>
                                                                    this.debouncedGetData("style")
                                                                }
                                                            >
                                                                {styleOptions}
                                                            </Select>
                                                        </Form.Item>
                                                    </Tooltip>
                                                </Col>

                                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                    <Tooltip title="Select Group">
                                                        <Form.Item
                                                            name="group"
                                                        // rules={[
                                                        //     {
                                                        //         required: true,
                                                        //         message: "Please Select Group Name !",
                                                        //     },
                                                        // ]}
                                                        >
                                                            <Select
                                                                name="group"
                                                                id="group"
                                                                mode="tags"
                                                                // disabled={disabled}

                                                                showSearch
                                                                placeholder="Select Group"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.value
                                                                        .toLowerCase()
                                                                        .indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                filterSort={(optionA, optionB) =>
                                                                    optionA.value
                                                                        .toLowerCase()
                                                                        .localeCompare(optionB.value.toLowerCase())
                                                                }
                                                                onChange={this.handleChangeGroup}
                                                                onClick={() => this.debouncedGetData("group")}
                                                                onInputKeyDown={() =>
                                                                    this.debouncedGetData("group")
                                                                }
                                                                options={groupOptions}
                                                            />
                                                        </Form.Item>
                                                    </Tooltip>
                                                </Col>
                                            </Row>

                                            <Row gutter={[8, 0]}>
                                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                    <Tooltip title="Select Season">
                                                        <Form.Item
                                                            name="season"
                                                        // rules={[
                                                        //     {
                                                        //         required: true,
                                                        //         message: "Please Select Season Name !",
                                                        //     },
                                                        // ]}
                                                        >
                                                            <Select
                                                                name="season"
                                                                id="season"
                                                                mode="tags"
                                                                // disabled={disabled}
                                                                showSearch
                                                                placeholder="Select Season"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.value
                                                                        .toLowerCase()
                                                                        .indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                filterSort={(optionA, optionB) =>
                                                                    optionA.value
                                                                        .toLowerCase()
                                                                        .localeCompare(optionB.value.toLowerCase())
                                                                }
                                                                onChange={this.handleChangeSeason}
                                                                onClick={() => this.debouncedGetData("season")}
                                                                onInputKeyDown={() =>
                                                                    this.debouncedGetData("season")
                                                                }
                                                                options={seasonOptions}
                                                            />
                                                        </Form.Item>
                                                    </Tooltip>
                                                </Col>

                                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                    <Tooltip title="Select Section">
                                                        <Form.Item
                                                            name="section"
                                                        // rules={[
                                                        //     {
                                                        //         required: true,
                                                        //         message: "Please Select Section Name !",
                                                        //     },
                                                        // ]}
                                                        >
                                                            <Select
                                                                name="section"
                                                                id="section"
                                                                mode="tags"
                                                                // disabled={disabled}
                                                                showSearch
                                                                placeholder="Select Section"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.value
                                                                        .toLowerCase()
                                                                        .indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                filterSort={(optionA, optionB) =>
                                                                    optionA.value
                                                                        .toLowerCase()
                                                                        .localeCompare(optionB.value.toLowerCase())
                                                                }
                                                                onChange={this.handleChangeSection}
                                                                onClick={() => this.debouncedGetData("section")}
                                                                onInputKeyDown={() =>
                                                                    this.debouncedGetData("section")
                                                                }
                                                                options={sectionOptions}
                                                            />
                                                        </Form.Item>
                                                    </Tooltip>
                                                </Col>

                                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                    <Tooltip title="Select Floor">
                                                        <Form.Item
                                                            name="floor"
                                                        // rules={[
                                                        //     {
                                                        //         required: true,
                                                        //         message: "Please Select Floor Name !",
                                                        //     },
                                                        // ]}
                                                        >
                                                            <Select
                                                                name="floor"
                                                                id="floor"
                                                                mode="tags"
                                                                // disabled={disabled}
                                                                showSearch
                                                                placeholder="Select Floor"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.value
                                                                        .toLowerCase()
                                                                        .indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                filterSort={(optionA, optionB) =>
                                                                    optionA.value
                                                                        .toLowerCase()
                                                                        .localeCompare(optionB.value.toLowerCase())
                                                                }
                                                                onChange={this.handleChangeFloor}
                                                                onClick={() => this.debouncedGetData("floor")}
                                                                onInputKeyDown={() =>
                                                                    this.debouncedGetData("floor")
                                                                }
                                                                options={floorOptions}
                                                            />
                                                        </Form.Item>
                                                    </Tooltip>
                                                </Col>

                                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                    <Tooltip title="Select Size">
                                                        <Form.Item
                                                        // {...field}
                                                        // fieldKey={[field.fieldKey, "size"]}
                                                        // name={[field.name, "size"]}
                                                        // rules={[
                                                        //     {
                                                        //         required: true,
                                                        //         message: "Please Select Size !",
                                                        //     },
                                                        // ]}
                                                        >
                                                            <Select
                                                                mode="tags"
                                                                // disabled={disabled}
                                                                // id="467396"
                                                                name="size"
                                                                id="size"
                                                                showSearch
                                                                placeholder="Select Size"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.value
                                                                        .toLowerCase()
                                                                        .indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                filterSort={(optionA, optionB) =>
                                                                    optionA.value
                                                                        .toLowerCase()
                                                                        .localeCompare(optionB.value.toLowerCase())
                                                                }
                                                                onChange={this.handleChangeSize}
                                                                onClick={() => this.debouncedGetData("size")}
                                                                onInputKeyDown={() =>
                                                                    this.debouncedGetData("size")
                                                                }
                                                                options={sizeOptions}
                                                            />
                                                        </Form.Item>
                                                    </Tooltip>
                                                </Col>
                                            </Row>
                                            <Row gutter={[8, 0]}>
                                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                    <Tooltip title="Select Brand">
                                                        <Form.Item
                                                            name="brand"
                                                        // rules={[
                                                        //   {
                                                        //     required: true,
                                                        //     message: "Please Select Brand Name !",
                                                        //   },
                                                        // ]}
                                                        >
                                                            <Select
                                                                mode="multiple"
                                                                name="brand"
                                                                id="brand"
                                                                showSearch
                                                                placeholder="Select Brand"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) =>
                                                                    option.value
                                                                        .toLowerCase()
                                                                        .indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                filterSort={(optionA, optionB) =>
                                                                    optionA.value
                                                                        .toLowerCase()
                                                                        .localeCompare(optionB.value.toLowerCase())
                                                                }
                                                                onChange={this.handleChangeBrand}
                                                                onClick={() => this.debouncedGetData("brand")}
                                                                onInputKeyDown={() =>
                                                                    this.debouncedGetData("brand")
                                                                }
                                                                // onSelect={this.showLookup}
                                                                options={brandOptions}
                                                            />
                                                        </Form.Item>
                                                    </Tooltip>
                                                </Col>
                                                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                                    <Tooltip title="Enter Barcode">
                                                        <Form.Item
                                                            name="barcode"
                                                        >
                                                            <Select
                                                                mode="tags"
                                                                name="barcode"
                                                                id="barcode"
                                                                // showSearch
                                                                placeholder="Enter Barcode"
                                                                optionFilterProp="children"
                                                                onChange={this.handleChangeBarcode}
                                                                options={[]}
                                                            />
                                                        </Form.Item>
                                                    </Tooltip>
                                                </Col>
                                            </Row>

                                            <Row gutter={[8, 0]}>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Button
                                                        type="submit"
                                                        className="btn-stock-report-search"
                                                        block
                                                        onClick={() => this.submitForm()}
                                                    >
                                                        Search
                                                    </Button>
                                                </Col>

                                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <Button
                                                        type="primary"
                                                        onClick={() => window.location.reload(true)}
                                                        className="close-modal"
                                                        block
                                                    >
                                                        Reset
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                            <StockReportList reports={this.props.reports} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
StockReport.propTypes = {
    getBrandBySearch: PropTypes.func.isRequired,
    getCategories: PropTypes.func.isRequired,
    getGroups: PropTypes.func.isRequired,
    getStyles: PropTypes.func.isRequired,
    getSeasons: PropTypes.func.isRequired,
    getSections: PropTypes.func.isRequired,
    getSizes: PropTypes.func.isRequired,
    getFloors: PropTypes.func.isRequired,
    getStockReport: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    reports: state.reportData.stockReport,
    progressBarStatus: state.progressBarReducer.showProgressBar,
    brands: state.brandsData.brands,
    brandBysearch: state.brandsData.brandBysearch,
    sections: state.sectionData.sections,
    seasons: state.seasonData.seasons,
    categories: state.categoryData.categories,
    styles: state.styleData.styles,
    sizes: state.sizeData.sizes,
    floors: state.floorData.floors,
    groups: state.groupData.groups,
});

export default connect(mapStateToProps, {
    getBrandBySearch,
    getCategories,
    getGroups,
    getStyles,
    getSeasons,
    getSections,
    getSizes,
    getFloors,
    getStockReport,
})(StockReport);
