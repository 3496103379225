import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUploadedLrList } from "../../../redux/actions/saleAction";
import {
  Table,
  Button,
  Space,
  Input,
  Row,
  Col,
  Popconfirm,
  Tooltip,
  DatePicker,
  Form,
  message,
  Modal,
} from "antd";
import moment from "moment";
import ShowInvoiceLR from "./ShowInvoiceLR";
import ShowLRPdf from "./ShowLRPdf";
import UpdateLR from "./UpdateLR";
const { Column } = Table;

const UploadedLRList = () => {
  const dispatch = useDispatch();
  const uplodedLrData = useSelector((state) => state.saleData.uplodedLRList);

  const getUploadedLrList = async () => {
    await dispatch(fetchUploadedLrList());
  };

  useEffect(() => {
    getUploadedLrList();
  }, []);

  return (
    <>
      <div className="subCategory-dashboard">
        {console.log("check moyyyyyyyy", uplodedLrData)}
        <div className="container-fluid category-list">
          <Row className="title-row">
            <Col xs={12} sm={12} md={12} lg={12} xl={12} align="start">
              <div className="page-header">Uploded LR List</div>
            </Col>
          </Row>
          <div className="card mt-4">
            <div className="">
              <Table
                dataSource={(uplodedLrData && uplodedLrData.data) || []}
                bordered="true"
                size="middle"
                pagination={false}
              >
                <Column
                  title="SRN"
                  key="index"
                  render={(value, item, index) => index + 1}
                  width={60}
                />
                <Column
                  title="LR Number"
                  dataIndex="lrNumber"
                  width={100}
                  key="lrNumber"
                />
                <Column
                  title="LR Date"
                  dataIndex="lrDate"
                  key="lrDate"
                  width={100}
                  render={(value, item, index) =>
                    `${moment(item.lrDate).format("YYYY-MM-DD")}`
                  }
                />

                <Column
                  title="Action"
                  key="action"
                  width={300}
                  render={(text, record) => (
                    <Row gutter={20}>
                      <Col span={8}>
                        <ShowInvoiceLR data={record} />
                      </Col>
                      <Col span={8}>
                        <ShowLRPdf data={record} />
                      </Col>
                      <Col span={8}>
                        <UpdateLR />
                      </Col>
                    </Row>
                  )}
                />
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadedLRList;
