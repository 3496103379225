import React, { Component, useState } from "react";
import { Layout, Menu, Dropdown, Avatar, Button } from "antd";
import { NavLink } from "react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import SiderDrawerPhone from "../SiderDrawerPhone";
const { Header } = Layout;
const SalesHeaderAdmin = ({ collapsed, user, history, toggle }) => {
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("left")



  const logout = () => {


    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("isCreditUser");
    localStorage.removeItem("userId")
    localStorage.removeItem("roleName")

    // localStorage.clear();
    history.push({
      pathname: process.env.PUBLIC_URL + "/salesPerson/login",
      state: {
        tabType: "login"
      }
    })
    Redirect("/salesPerson/login")
    window.location.reload();
  }

  const menu = (
    <Menu>
      <Menu.Item key="3" onClick={logout}>
        <LogoutOutlined /> Logout
      </Menu.Item>
    </Menu>
  );


  return (
    <Header className="site-layout-background sales-header" style={{ padding: 0 }}>
      {React.createElement(
        collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
        {
          className: "trigger",
          onClick: toggle,
        }
      )}
      <SiderDrawerPhone user={user} />
      {/* <Dropdown overlay={menu} placement="bottomRight">
        <Avatar
          style={{
            color: "#0060aa",
            backgroundColor: "#afe7fc",
            letterSpacing: "1px",
          }}
          role="button"
          className="float-right mr-5 mt-2"
          onClick={(e) => e.preventDefault()}
          size={45}
        >
          {user?.name
            .match(/(^\S\S?|\b\S)?/g)
            .join("")
            .match(/(^\S|\S$)?/g)
            .join("")
            .toUpperCase()}
        </Avatar>
      </Dropdown> */}
      <Button type="primary" onClick={() => logout()} className="save">Logout</Button>

    </Header>

  )
}

export default SalesHeaderAdmin