import React, { Component } from "react";
import {
  Table,
  Button,
  Space,
  Input,
  Tag,
  Row,
  Col,
  Popconfirm,
  message,
} from "antd";
import { connect } from "react-redux";
import Highlighter from "react-highlight-words";
import TopBarProgress from "react-topbar-progress-indicator";
import gifLoader from "../../../../assets/images/giphy.gif";
import { SearchOutlined, UndoOutlined } from "@ant-design/icons";
import {
  getDltSale,
  restoreSale,
} from "../../../../redux/actions/recycleBinAction";
import moment from "moment";

const { Column } = Table;

export class RecycleSales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      data: [],
    };
  }

  componentDidMount = () => {
    this.props.getDltSale(1);
  };

  handleRestore = (record) => {
    const obj = {
      _id: record._id,
      salesid: record.salesid,
    };
    this.props.restoreSale(obj).then(() => {
      message.success("Restored Successfully");
      this.props.getDltSale(1);
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  this.setState({
                    searchText: selectedKeys[0],
                    searchedColumn: dataIndex,
                  });
                }}
              >
                Filter
              </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  render() {
    const saleList = this.props.saleList;
    return (
      <>
        <div className="subCategory-dashboard">
          {this.props.progressBarStatus && <TopBarProgress />}
          <div className="container-fluid category-list">
            <Row className="title-row mb-4">
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <div className="page-header">Recycle Sales</div>
              </Col>
            </Row>
            <div className="card mt-4 final-purchases-list">
              <div className="card-body">
                {saleList && saleList?.count > 0 ? (
                  <Table
                    dataSource={saleList?.data}
                    bordered="true"
                    size="middle"
                    scroll={{ y: 240 }}
                    pagination={{
                      pageSize: 30,
                      total: saleList?.count,
                      onChange: (page) => {
                        this.props.getDltSale(page);
                      },
                    }}
                  >
                    <Column
                      title="SRN"
                      key="index"
                      render={(value, item, index) => index + 1}
                      width={60}
                    />
                    <Column
                      title="Bill Date"
                      dataIndex="date"
                      key="date"
                      width={210}
                      render={(value, item, index) =>
                        `${moment(item?.date).format("YYYY-MM-DD")}`
                      }
                    />
                    <Column
                      title="Customer Name"
                      key="userId"
                      render={(text, record) => record?.userId?.name || ""}
                      width={180}
                    />
                    <Column
                      title="Sale Id"
                      {...this.getColumnSearchProps("invoiceId")}
                      dataIndex="invoiceId"
                      key="invoiceId"
                      width={100}
                    />
                    <Column
                      title="Barcode"
                      {...this.getColumnSearchProps("barcode")}
                      dataIndex="barcode"
                      key="barcode"
                      width={130}
                    />
                    <Column
                      title="Brand"
                      dataIndex="salesid"
                      key="salesid"
                      width={130}
                      render={(text, record) => record?.details?.brand || ""}
                    />
                    <Column
                      title="D. No"
                      dataIndex="salesid"
                      key="salesid"
                      width={130}
                      render={(text, record) =>
                        record?.details?.designNumber || ""
                      }
                    />
                    <Column
                      title="Quantity"
                      dataIndex="quantity"
                      key="quantity"
                      width={140}
                    />
                    <Column title="MOQ" dataIndex="MOQ" key="MOQ" width={100} />
                    <Column
                      title="Action"
                      key="action"
                      fixed="right"
                      render={(text, record) => (
                        <Space size="middle">
                          <Popconfirm
                            title="Are you sure you want to restore this item?"
                            onConfirm={() => this.handleRestore(record)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <UndoOutlined />
                          </Popconfirm>
                        </Space>
                      )}
                      width={100}
                    />
                  </Table>
                ) : (
                  <>
                    <div className="d-flex justify-content-center align-items-center">
                      <img src={gifLoader} width={"300px"} height={"300px"} />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

RecycleSales.propTypes = {
  // getTopCustomerReports: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  saleList: state.recycleBin.saleList,
});

export default connect(mapStateToProps, {
  getDltSale,
  restoreSale,
})(RecycleSales);
