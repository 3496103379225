import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { uuid } from "uuidv4";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Select,
  Tooltip,
  message,
} from "antd";
import debounce from 'lodash.debounce';
import { getDealers } from "../../../redux/actions/dealerAction";
import { getSizes } from "../../../redux/actions/sizeAction";
import {
  getPOdatabyId, getpo,
  poCredentials,
} from "../../../redux/actions/poOrderAction"
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { getBrandBySearch } from "../../../redux/actions/brandAction";
import {
  StopOutlined,
  LoadingOutlined
} from "@ant-design/icons";
import POList from "./POList";
import logo from "../../../assets/images/Daga_Impex_Logo.png"
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;


class PO extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      createPoStatus: false,
      showReturnListData: [],
      returnData: [],
      data: {
        loading: false,
        values: {},
        dealerName: "",
        dealerId: "",
        brandName: "",
        brandId: "",
        designNumber: "",
        sizeName: "",
        sizeId: "",
        quantity: "",
        errors: {},
        myPOData: [],
        poId: '',
        pdfCreated: false,
        poDatabyId: {}
      },
      visible: false,
      spinLoad: false,
    };
    this.onChange = this.onChange.bind(this);
    this.handleScan = this.handleScan.bind(this);
    this.handleKeypress = this.handleKeypress.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.debouncedGetData = debounce(this.getData, 500);
  }

  handleBlur(e) {
    if (e.currentTarget.value === "0") e.currentTarget.value = "1";
  }
  handleKeypress(e) {
    const characterCode = e.key;
    if (characterCode === "Backspace") return;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      } else if (characterNumber === 0) {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errors !== this.props.errors) {

      message.error(this.props.errors.message);
    }
  }

  onChange(event, key, temp) {


    this.setState({ [event.target.name]: event.target.value });



    let data = this.state.data;
    data[event.target.name] = event.target.value;
    this.setState({ data });
  }
  onDateChange = (date, dateString) => {
    let data = this.state.data;

    this.setState({ data });
  };
  createpoCredential = async () => {
    this.setState({
      createPoStatus: true
    })
    await this.props.poCredentials({
      returnData: this.state.showReturnListData
    });
    const { poId } = this.props;
    if (poId) {
      this.setState({ pdfCreated: true, poId: poId.poId });
    } else {
      console.error('Error: poId not available.');
    }
  };

  createpdf = async () => {

    await this.props.getPOdatabyId({
      poId: this.state.poId
    });

    const { poDatabyId } = this.props;
    if (poDatabyId && poDatabyId.data) {

      const doc = new jsPDF();

      const logoUrl = logo;

      const img = new Image();
      img.src = logoUrl;
      img.onload = function () {
        const imgWidth = 25;
        const imgHeight = 25;
        const imgX = doc.internal.pageSize.getWidth() - imgWidth - 10; // 10 units from right edge
        const imgY = 8;

        doc.addImage(img, 'PNG', imgX, imgY, imgWidth, imgHeight);

        doc.setFontSize(16);
        doc.setFont("helvetica", "bold");
        var text = "Purchase Order Details";

        var pageWidth = doc.internal.pageSize.getWidth();

        doc.text(text, pageWidth / 2, 21, { align: 'center' });

        var textWidth = doc.getTextWidth(text);

        var startX = (pageWidth - textWidth) / 2;
        var endX = startX + textWidth;
        var y = 23;
        doc.line(startX, y, endX, y);
        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        doc.text("Dealer Name :", 15, 35);
        doc.text("PO Number :", 15, 45);

        doc.setFont("helvetica", "normal");
        doc.text(`${poDatabyId.data[0].dealerName}`, 47, 35);
        doc.text(`${poDatabyId.data[0].poId}`, 44, 45);

        let finalArray = [];
        poDatabyId.data.forEach(item => {
          finalArray.push([item.designNumber, item.brandName, item.sizeName, item.quantity]);
        });

        doc.autoTable({
          startY: 55,
          head: [['Design Number', 'Brand Name', 'Size Name', 'Quantity']],
          body: finalArray,
          theme: 'striped',
          styles: {

            fontSize: 10, cellPadding: 2, overflow: 'ellipsize', halign: "center", halign: 'center', lineWidth: 0.1, lineColor: [0, 0, 0]
          },
          columnStyles: {
            0: { halign: 'center', overflow: 'ellipsize' },
            1: { halign: 'center', overflow: 'ellipsize' },
          },
        });

        doc.save(`${poDatabyId.data[0].poId}.pdf`);
      };
    }


  }


  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  checkBarcodeExists = (brandId, sizeId, designNumber) => {
    let exist = this.state.showReturnListData.findIndex(
      (element) => element.brandId === brandId && element.sizeId === sizeId && element.designNumber === designNumber
    );
    return exist;
  };

  handleChangeDealer = (key, value) => {
    let data = this.state.data;
    data["dealerId"] = value.value;
    data["dealerName"] = value.name;

    this.setState({ data });
  };
  handleChangeBrand = (key, value) => {
    let data = this.state.data;
    data["brandId"] = value.value;
    data["brandName"] = value.name;
    this.setState({ data });
    this.debouncedGetData("brand")
  };

  handleChangeSize = (key, value) => {
    let data = this.state.data;
    data["sizeId"] = value.value;
    data["sizeName"] = value.name;
    this.setState({ data });
  };

  componentDidMount() {
    this.props.getDealers();
    this.props.getSizes();
  }
  getData = (name, value) => {
    // if (name == "dealer") this.props.getDealers();
    if (name == "brand") this.props.getBrandBySearch(value);

    // if (name == "size") this.props.getSizes();

  };

  addpoCredentials = () => {

    this.setState({ disableField: true });

    const poObj = {
      quantity: Number(this.state.data.quantity),
      dealerName: this.state.data.dealerName,
      dealerId: this.state.data.dealerId,
      brandName: this.state.data.brandName,
      brandId: this.state.data.brandId,
      designNumber: this.state.data.designNumber,
      sizeName: this.state.data.sizeName,
      sizeId: this.state.data.sizeId
    };
    this.setState({
      showReturnListData: [poObj,
        ...this.state.showReturnListData]
    })

    const existingObjIndex = this.state.showReturnListData.findIndex(obj => obj.designNumber === this.state.designNumber);
    if (existingObjIndex >= 0) {

    } else {
      const newObj = {
        quantity: Number(this.state.data.quantity),
        dealerName: this.state.data.dealerName,
        dealerId: this.state.data.dealerId,
        brandName: this.state.data.brandName,
        brandId: this.state.data.brandId,
        designNumber: this.state.data.designNumber,
        sizeName: this.state.data.sizeName,
        sizeId: this.state.data.sizeId

      };

      this.setState({
        showReturnListData: [
          newObj,
          ...this.state.showReturnListData,
        ],
      });

    }

    this.setState({
      quantity: "",
      designNumber: "",
      dealerName: "",
      dealerId: "",
      brandName: "",
      brandId: "",
      sizeId: "",
      sizeName: ""
    });

  };

  async handleScan(data) {
    if (data.length == 9) {
      await this.props.getpo(
        data,
        this.state.dealerId
      );
    }
  }

  onRemoveQnty = (obj, index) => {

    let temp = [...this.state.showReturnListData];

    temp[index].quantity = Math.max(0, temp[index].quantity - 1);

    this.setState({
      showReturnListData: temp,
      pd: uuid(),
    });
  };


  onAddQnty = (obj, index) => {
    let temp = [...this.state.showReturnListData];
    temp[index].quantity = Math.max(0, temp[index].quantity + 1);
    this.setState({
      showReturnListData: temp,
      pd: uuid(),
    });
  }


  // ------------------------------
  delete = (index) => {
    let temp = [...this.state.showReturnListData];
    temp.splice(index, 1);
    this.setState({
      showReturnListData: temp,
      POData: temp
    });
  };
  // -------------------------------
  render() {
    let dealerOptions = this.props.dealers.map((dealer) => {
      return (
        <Option name={dealer.name} value={dealer._id}>
          {dealer.name}
        </Option>
      );
    });

    let brandOptions = this.props?.brandBysearch?.data?.map((brand) => {
      return (
        <Option name={brand.name} value={brand._id}>
          {brand.name}
        </Option>
      );
    });
    let sizeOptions = this.props.sizes.map((size) => {
      return (
        <Option name={size.name} value={size._id}>
          {size.name}
        </Option>
      )

    })
    const { loading } = this.state.data;
    const initialValues = {
      users: [
        { size: undefined, colors: [undefined] }, // undefined will render the placeholder
      ],
    };
    var disabled = false;
    if (this.state.disableField) {
      disabled = true;
    }

    return (
      <div className="add-PO ">
     
        <div className="container-fluid">
          <Row className="title-row">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="page-header">PO Order</div>
            </Col>
          </Row>{" "}
          <div className="card mt-4">
            <div className="card-body">
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form
                    ref={this.formRef}
                    name="add-PO-Order"
                    className="add-PO-Order"
                    onFinish={this.addpoCredentials}
                    initialValues={{ remember: true }}
                  >
                    <Row gutter={[16, 0]}>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Tooltip title="Select Dealer">
                          <Form.Item
                            name="dealerName"
                            rules={[
                              {
                                required: true,
                                message: "Please Select Dealer Name !",
                              },
                            ]}
                          >
                            <Select
                              disabled={disabled}
                              showSearch
                              placeholder="Select Dealer"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                              onChange={this.handleChangeDealer}
                            // onClick={() => this.getData("dealer")}
                            // onInputKeyDown={() => this.getData("dealer")}
                            >
                              {dealerOptions}
                            </Select>
                          </Form.Item>
                        </Tooltip>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Tooltip title="Select Brand">
                          <Form.Item
                            name="brandName"
                            rules={[
                              {
                                required: true,
                                message: "Please Select Brand Name !",
                              },
                            ]}
                          >
                            <Select
                              name="brand"
                              id="brand"
                              showSearch
                              placeholder="Select Brand"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                              onChange={this.handleChangeBrand}
                              onClick={() => this.debouncedGetData("brand")}
                              onInputKeyDown={() => this.debouncedGetData("brand")}
                            >
                              {brandOptions}
                            </Select>
                          </Form.Item>
                        </Tooltip>
                      </Col>


                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Tooltip title="Design Number">
                          <Form.Item
                            //name="designNumber"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Design Number !",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Enter Design Number"
                              id="designNumber"
                              name="designNumber"
                              value={this.state.designNumber}
                              onChange={this.onChange}
                              required
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Tooltip title="Select Size">
                          <Form.Item
                            name="sizeName"
                            rules={[
                              {
                                required: true,
                                message: "Please Select Size !",
                              },
                            ]}
                          >
                            <Select
                              name="size"
                              showSearch
                              placeholder="Select Size"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                              onChange={this.handleChangeSize}
                            // onClick={() => this.getData("size")}
                            // onInputKeyDown={() => this.getData("size")}
                            >
                              {sizeOptions}
                            </Select>
                          </Form.Item>
                        </Tooltip>
                      </Col>

                      {/* <LookupModalSales
                        purchases={this.props.purchases}
                        s={this.state.s}
                        selectedData={this.selectedData}
                      /> */}
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>

                        {" "}
                        <Tooltip title="Enter Quantity">
                          <Form.Item

                            rules={[{
                              required: true,
                              message:
                                "Please Enter Quantity !",
                            },
                            ]}
                          >

                            <Input
                              onWheel={(e) => e.target.blur()}
                              placeholder="Enter Quantity"
                              name="quantity"

                              value={this.state.quantity}
                              onChange={this.onChange}
                              type="number"
                              min="0"
                              max="1000000"
                              step="1"
                              required
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col></Row>
                  </Form>
                  {/* <ShowPurchaseReturnDetails
                    purchaseReturnBarcodes={[this.props.purchaseReturnBarcodes]}
                  /> */}

                  <Row gutter={[8, 0]} className="mt-3">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="close-modal"
                        onClick={this.addpoCredentials}
                        block
                      >
                        Add More PO Order
                      </Button>
                    </Col>
                  </Row>
                  <POList
                    onAddQnty={this.onAddQnty}
                    onRemoveQnty={this.onRemoveQnty}
                    quantity={this.state.quantity}
                    data={this.state.showReturnListData}

                    key={this.state.pd}
                    delete={this.delete}
                  />
                  {(() => {

                  })}
                  < Row justify={'space-between'}>
                    <Row gutter={[8, 0]} className="mt-3">
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        {(() => {
                          if (this.state.showReturnListData.length === 0) {
                            return (
                              <Button
                                type="primary"
                                className="save-modal text-white"
                                disabled
                                block
                              >
                                Create PO Order <StopOutlined />
                              </Button>
                            );
                          } else {
                            return (
                              <Button
                                type="primary"
                                htmlType="submit"
                                className="save-modal"
                                disabled={this.state.createPoStatus}
                                onClick={this.createpoCredential}
                              >
                                Create Po Order
                              </Button>
                            );
                          }
                        })()}
                      </Col>
                    </Row>

                    <Row gutter={[8, 0]} className="mt-3">
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        {(() => {
                          if (this.state.showReturnListData.length === 0 || !this.state.pdfCreated) {
                            return (
                              <Button
                                type="primary"
                                className="save-modal text-white"
                                disabled
                                block
                              >
                                Generate PDF <StopOutlined />
                              </Button>
                            );

                          } else {
                            return (
                              <Button
                                type="primary"
                                htmlType="submit"
                                className="save-modal"
                                onClick={this.createpdf}
                              >
                                Generate PDF
                              </Button>
                            );
                          }
                        })()}
                      </Col>
                    </Row>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
PO.propTypes = {
  getDealers: PropTypes.func.isRequired,
  getpo: PropTypes.func.isRequired,
  getBrandBySearch: PropTypes.func.isRequired,
  getSizes: PropTypes.func.isRequired,
  poCredentials: PropTypes.func.isRequired,
  poCredential: PropTypes.func.isRequired,
  getPOdatabyId: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => (
  {
    dealers: state.dealersData.dealers,
    brands: state.brandsData.brands,
    brandBysearch: state.brandsData.brandBysearch,
    poId: state.poData.poId,
    poDatabyId: state.poData.poDatabyId,
    sizes: state.sizeData.sizes,
    // purchases: state.purchaseData.purchases,
    errors: state.errorData.error
  }
);

export default connect(mapStateToProps, {
  getDealers,
  getpo,
  getBrandBySearch,
  getSizes,
  poCredentials,
  getPOdatabyId

})(PO);