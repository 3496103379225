/* /////////////////////////////////////// */
/* 
Date              created by               Modifications

06-07-2023        Mohit                    onSaleChange function modified               


*/
/* /////////////////////////////////////// */


import React, { Component } from "react";
import {
  Table,
  Switch,
  Pagination,
  Button,
  Space,
  Input,
  Row,
  Col,
  Tooltip,
  Tag,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getUniquePurchases,
  addEcomSale,
  ecomBySearchValue,
  getBrandAndDesignComboSales
} from "../../../redux/actions/purchaseAction.js";
import Highlighter from "react-highlight-words";
import AddEcomSale from "./AddEcomSale.js";
import Search from "../../../util/Search.js";
import TopBarProgress from "react-topbar-progress-indicator";
import ShowProducts from "./ShowProducts.js";
import { getBrands } from "../../../redux/actions/brandAction.js"
const searchOptions = ["Brand"]
const { Column } = Table;

class EcomSaleList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      options: [],
      searchValue: "",
    };
  }
  componentDidMount() {
    // this.props.getUniquePurchases(1, "salesList");
    this.props.getBrandAndDesignComboSales(1)
    this.props.getBrands()
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };
  onSaleChange(barcode, newToggalArray) {

    let matchingArray = newToggalArray.filter(obj => obj[0].barcode == barcode)
    let objarray = matchingArray[0].map(obj => ({ _id: obj._id, barcode: obj.barcode, status: "OFF" }))

    // let status = "OFF";
    // let saleStatusChange = {
    //   barcode,
    //   status,
    // };
    this.props.addEcomSale({ toggleData: objarray });
    this.props.getUniquePurchases(1, "salesList");
  }
  onChange = (event) => {
    this.setState({ searchValue: event });
  };
  cancelState = () => {
    this.setState({ searchValue: "" });
  }
  handleSearchButtonClick = (value) => {
    console.log("value", typeof this.state.searchValue)



    let obj = {
      searchValue: this.state.searchValue
    }
    this.props.ecomBySearchValue(obj)
    console.log("obj--------- numberrr", obj)

    // this.props.userByNameOrUsername(obj)

  }
  render() {
    let brands = this.props?.brands?.map(obj => obj.name)
    const { searchValue } = this.state;
    const { ecomSalesPurchases, searchData } = this.props
    const newEcomArray = ecomSalesPurchases ? ecomSalesPurchases.map(item => item.data[0]).sort((a, b) => parseInt(a.barcode) - parseInt(b.barcode)) : []
    const newToggalArray = ecomSalesPurchases ? ecomSalesPurchases.map(item => item.data) : []
    const search = searchData || []

    return (
      <div >
        {this.props.progressBarStatus && <TopBarProgress />}
        {/* <AddEcomSale/> */}
        <Row className="title-row">
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <div className="page-header">E-com Offer</div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Search title="E-COM PRODUCTS" placeholder="Enter Design No" searchopt={brands}
            type="salesOffer"
            cancelState={this.cancelState} changeFunction={this.onChange} />
        </Row>
        {/* <Row className="title-row">
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="page-header">E-com Sale</div>
            </Col>

            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Input
                placeholder="Brand "
                value={this.state.searchValue}
                onChange={this.onChange}
                onPressEnter={() => this.handleSearchButtonClick()}
                style={{ width: "100%" }}
                suffix={
                  <Button
                    type="primary"
                    icon={<SearchOutlined style={{marginTop:"12px"}}/>}
                    onClick={() => this.handleSearchButtonClick()}
                  />
                }
              />
            </Col>
          </Row> */}
        <div className="card mt-4 final-purchases-list">
          <div className="card-body">
            <Table
              dataSource={this.props?.brandanddesignNo?.data}
              // dataSource={this.props.ecomSalesPurchases.data}
              bordered="true"
              size="middle"
              scroll={{ y: 240 }}
              pagination={{
                pageSize: 30,
                total: this.props?.brandanddesignNo?.totalCount,
                onChange: (page) => {
                  this.props.getBrandAndDesignComboSales(page)
                },
              }}
            >
              <Column
                title="SRN"
                key="index"
                render={(value, item, index) => index + 1}
                width={60}
              />
              <Column
                title="Design No."
                dataIndex="_id"
                key="_id"
                width={150}
                render={(value, item, index) => item._id.designNumber}
              // {...this.getColumnSearchProps("designNumber")}
              />
              <Column
                title="Brand"
                dataIndex="_id"
                key="_id"
                render={(value, item, index) => item._id.brand}
                // {...this.getColumnSearchProps("brand")}
                width={120}
              />
              <Column
                title="Sale Discount"
                dataIndex="salesDiscount"
                key="salesDiscount"
                render={(value, item, index) => `${item.salesDiscount}%`}
                width={80}
              />
              {/* <Column
                title="Price"
                dataIndex="purchasePrice"
                key="purchasePrice"
                width={90}
              />
              <Column title="WSP" dataIndex="wsp" key="wsp" width={80} /> */}
              {/* <Column
                title="Sale"
                dataIndex="sales"
                key="sales"
                width={90}
                render={(sales) => (
                  <>
                    {(() => {
                      if (sales == "OFF") {
                        return (
                          <Tag color="volcano" key={sales}>
                            {sales}
                          </Tag>
                        );
                      } else {
                        return (
                          <Tag color="green" key={sales}>
                            {sales}
                          </Tag>
                        );
                      }
                    })()}
                  </>
                )}
              /> */}
              {/* <Column
                title="Sale Disc"
                dataIndex="salesDiscount"
                key="salesDiscount"
                render={(value, item, index) => `${item.salesDiscount}%`}
                width={100}
              /> */}
              {/* <Column
                title="Action"
                key="action"
                width={90}
                render={(text, record) => (
                  <>
                    {(() => {
                      if (text.sales == "OFF") {
                        return (
                          <Row gutter={[8, 0]}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                              <AddEcomSale ecomSale={text} newToggalArray={newToggalArray}/>
                            </Col>
                          </Row>
                        );
                      } else {
                        return (
                          <div className="btn">
                            <Tooltip title="Remove Ecom Sale">
                              <Switch
                                size="small"
                                defaultChecked={text.sales}
                                onChange={() => this.onSaleChange(text.barcode, newToggalArray)}
                              />
                            </Tooltip>
                          </div>
                        );
                      }
                    })()}
                  </>
                )}
              /> */}
              <Column
                title="Action"
                key="action"
                width={90}
                render={(text, record) => (
                  <Row gutter={[8, 0]} style={{ placeContent: "center" }}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <ShowProducts obj={text._id} type="salesOffer" />
                    </Col>

                    {/* {(() => {
                      return (
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <div className="btn">
                            <Tooltip title="Change Status">
                              <Switch
                                size="large"
                                checked={text.ecommProductVisible == "OFF" ? false : true}
                                onChange={() => this.onSaleChange(text.barcode, newToggalArray, text)}
                              />
                            </Tooltip>
                          </div>
                        </Col>
                      );
                    })()} */}


                  </Row>
                )}
              />
            </Table>{" "}
            {/* <div className="float-right mt-3">
              <Pagination
                defaultCurrent
                defaultPageSize
                total="2"
                //   total={totalPages}
                onChange={this.onChangePage}
                showLessItems="true"
              />
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

EcomSaleList.propTypes = {
  getUniquePurchases: PropTypes.func.isRequired,
  addEcomSale: PropTypes.func.isRequired,
  ecomBySearchValue: PropTypes.func.isRequired,
  getBrandAndDesignComboSales: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  brands: state.brandsData.brands,
  ecomSalesPurchases: state.purchaseData.ecomSalesPurchases,
  searchData: state.purchaseData.purchaseSearchData,
  progressBarStatus: state.progressBarReducer.showProgressBar,
  brandanddesignNo: state.purchaseData.brandanddesigncomboforSale,
});

export default connect(mapStateToProps, { getUniquePurchases, addEcomSale, getBrands, ecomBySearchValue, getBrandAndDesignComboSales })(
  EcomSaleList
);
