import React, { Component } from "react";
import { Modal, Button, Form, Input, Upload, Select, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { EditOutlined } from "@ant-design/icons";
import { updateBrand, getPaginatedList, getBrands } from "../../../../redux/actions/brandAction";

class UpdateBrand extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      name: "",
      markup: "",
      errors: {},
    };
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmit = (event) => {
    let updateBrand = {
      name: this.state.name.trim(),
      markup: this.state.markup.trim(),
      _id: this.props.brands._id,
      status: this.props.brands.status,
    };

    this.props.updateBrand(updateBrand);

    this.props.getBrands();
    this.setState({ loading: false, visible: false });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 2000);
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleModalCancel = () => {
    this.setState({ visible: false });
  };

  shouldComponentUpdate(nextProps, prevState) {
    if (nextProps.brands._id != prevState._id) {
      this.setState({
        name: nextProps.brands.name,
        _id: nextProps.brands._id,
        markup: nextProps.brands.markup,
      });
      return true;
    }
    return true;
  }
  render() {
    const { visible, loading } = this.state;
    return (
      <>
        <button
          className="btn"
          type="button"
          data-original-title=""
          onClick={this.showModal}
        >
          <Tooltip title="Update Brand">
            <EditOutlined />
          </Tooltip>
        </button>

        <Modal
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleModalCancel}
          title="Update Brand"
          style={{ top: 20 }}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
        >
          <Form
            name="add-brand"
            className="add-brand"
            onFinish={this.onSubmit}
            initialValues={{
              remember: true,
            }}
          >
            <Form.Item
              // name="name"
              rules={[
                {
                  required: true,
                  message: "Please Enter Brand Name !",
                },
              ]}
              initialValue={this.state.name}
            >
              <Input
                autoFocus
                placeholder="Brand Name"
                name="name"
                value={this.state.name}
                onChange={this.onChange}
              />
            </Form.Item>
            <Form.Item
              // name="markup"
              rules={[
                {
                  required: true,
                  message: "Please Enter Markup !",
                },
              ]}
              initialValue={this.state.markup}
            >
              <Input
                type="number"
                placeholder="Brand Markup"
                name="markup"
                value={this.state.markup}
                onChange={this.onChange}
              />
            </Form.Item>

            <Form.Item className="float-right">
              <Button
                className="close-modal mr-3"
                onClick={this.handleModalCancel}
              >
                Close
              </Button>
              {(() => {
                if (this.state.name == "" || this.state.markup == "") {
                  return (
                    <Button disabled type="primary" className="ok-modal">
                      Save
                    </Button>
                  );
                } else {
                  return (
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="ok-modal"
                      loading={loading}
                    >
                      Save
                    </Button>
                  );
                }
              })()}
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}
UpdateBrand.propTypes = {
  updateBrand: PropTypes.func.isRequired,
  getBrands: PropTypes.func.isRequired,

};

export default connect(null, { updateBrand, getBrands })(UpdateBrand);