import React, { useState } from "react";
import { Modal, Button, Table, Tooltip } from "antd";
import Column from "antd/lib/table/Column";
import { InfoCircleOutlined } from "@ant-design/icons";

const InfoModal = ({ data }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="btn">
        <Tooltip title="Agent Info">
          <InfoCircleOutlined disabled={!data?.length>0} onClick={showModal} />
        </Tooltip>
      </div>

      <Modal
        title="Customer Info"
        visible={isModalVisible}
        onCancel={handleCancel}
        width={800}
        footer={null}
      >
        {/* Ant Design Table to display user data */}
        <Table dataSource={data} rowKey="_id" pagination={false} bordered>
          <Column title="Name" dataIndex="name" key="name" />
          <Column
            title="Mobile Number"
            dataIndex="mobileNumber"
            key="mobileNumber"
          />
        </Table>
      </Modal>
    </>
  );
};

export default InfoModal;