import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { Table, Tooltip, Button, Space, Input, Modal, Row, Col, Popconfirm, message, Tag } from "antd";
import {
    PlusCircleOutlined,
    MinusCircleOutlined,
    CheckCircleOutlined,
    DeleteOutlined
} from "@ant-design/icons";
import { getSalesBillListByIds, getProductsVerified, getSalesListBydate, checkBillIsVerified } from "../../../redux/actions/saleAction";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import BarcodeReader from "react-barcode-reader";
import moment from "moment";
import gifLoader from "../../../assets/images/giphy.gif"

const { Column } = Table;

const VerifyBillProduct = ({ title, invoiceData, alreadyVerify, record }) => {
    const dispatch = useDispatch()
    const products = useSelector(state => state.saleData.saleListByInvoiceId)
    const verifiedStatus = useSelector(state => state.saleData.billVerifiedStatus)
    const dataByID = useSelector(state => state.saleData.saleListByInvoiceId)
    const [saleProduct, setSaleProduct] = useState([])
    const [unbillProduct, setUnbillProduct] = useState([])
    const [neArr, setnewArr] = useState([])
    const [visible, setVisible] = useState(false)
    const [barcode, setBarcode] = useState("")

    const showModal = async () => {
        dispatch(checkBillIsVerified("reset"))
        let data = invoiceData?.map(obj => obj.invoiceId)
        if (data.length > 0) {
            let requestVerifed = {
                salesId: data[0]
            }

            dispatch(checkBillIsVerified(requestVerifed)).then(response => {
                if (response.verified === false) {
                    getAllSalesBill()
                } else if (response.verified === true) {
                    getAllSalesBill()
                    alreadyVerify()
                    showModalCancel()
                }
            }).catch(error => console.log("error", error))
            setVisible(true)
        } else {
            message.warn("Please Select Bills!")
        }
    };

    useEffect(() => {
        if (dataByID && dataByID.length > 0) {
            const verifiedProducts = dataByID.filter(obj => obj.isVerified === true);
            const unverifiedProducts = dataByID.filter(obj => obj.isVerified === false);
            setnewArr(verifiedProducts);
            setSaleProduct(unverifiedProducts);
        }
    }, [dataByID]);

    const showModalCancel = () => {
        dispatch(checkBillIsVerified("reset"))
        setVisible(false)
        setnewArr([])
        setSaleProduct([])
    };

    const getAllSalesBill = () => {
        let data = invoiceData?.map(obj => obj.invoiceId)
        let obj = {
            invoiceId: data
        }
        dispatch(getSalesBillListByIds(obj))
    }

    const changeMy = (barcode) => {
        let checkIndex = saleProduct.findIndex(obj => obj.barcode == barcode)
        let newArrIndex = neArr.findIndex(obj => obj.barcode == barcode)

        if (checkIndex >= 0) {
            let findIndexNo
            if (neArr && neArr.length > 0) {
                findIndexNo = neArr.findIndex(obj => obj.barcode == barcode)
            } else {
                findIndexNo = -1
            }
            // let findIndexNo = neArr.findIndex(obj => obj.barcode == barcode)
            if (findIndexNo >= 0) {
                let updatedArr = [...neArr]; // Create a new array with the same elements as neArr
                updatedArr[findIndexNo] = {
                    ...updatedArr[findIndexNo],
                    quantity: updatedArr[findIndexNo].quantity + updatedArr[findIndexNo].MOQ
                };

                let updatedSaleProduct = saleProduct.map(item => {
                    if (item.barcode == barcode) {

                        return { ...item, quantity: item.quantity - item.MOQ };
                    }
                    return item;
                }).filter(item => item.quantity !== 0);
                setnewArr(updatedArr);
                setSaleProduct(updatedSaleProduct);
            } else {
                let maxQuantity
                let updatedSaleProduct = saleProduct.map(item => {
                    if (item.barcode == barcode) {
                        maxQuantity = item.quantity
                        return { ...item, maxQuantity: item.quantity, quantity: item.quantity - item.MOQ };
                    }
                    return item;
                });

                let foundObject = saleProduct.find(obj => {

                    if (obj.barcode == barcode) {
                        obj.maxQuantity = maxQuantity
                        obj.quantity = obj.MOQ;

                        // Update the quantity property of the found object to 1
                        return true; // Stop searching and return the found object
                    }
                });
                let checkZeroQty = updatedSaleProduct.filter(obj => obj.quantity != 0 || obj.quantity < 0)
                setnewArr([...neArr, foundObject])
                setSaleProduct(checkZeroQty);
            }
        } else if (newArrIndex >= 0) {
            // message.warn("Product Already Added!")
            let checkIndex = unbillProduct.findIndex(obj => obj.barcode == barcode)
            if (checkIndex < 0) {
                setUnbillProduct([...unbillProduct, { barcode: barcode, quantity: 1 }])
            } else {
                let newArr = unbillProduct.map(obj => {
                    if (obj.barcode == barcode) {
                        return { ...obj, quantity: obj.quantity + 1 }
                    } else {
                        return obj
                    }
                })
                setUnbillProduct(newArr)
                // message.warn("Unbill Product Added!")
            }
        } else {
            let checkIndex = unbillProduct.findIndex(obj => obj.barcode == barcode)
            if (checkIndex < 0) {
                setUnbillProduct([...unbillProduct, { barcode: barcode, quantity: 1 }])
            } else {
                let newArr = unbillProduct.map(obj => {
                    if (obj.barcode == barcode) {
                        return { ...obj, quantity: obj.quantity + 1 }
                    } else {
                        return obj
                    }
                })
                setUnbillProduct(newArr)
                message.warn("Unbill Product Added!")
            }
        }
        setBarcode("")
    }

    const manageProducts = () => {
        let falseVerified = products?.filter(obj => obj.isVerified == false)
        setSaleProduct(falseVerified)
    }

    useEffect(() => {
        if (products && products.length > 0) {
            manageProducts()
        }
    }, [products])

    const onAddQnty = (barcode, data) => {
        if (data.quantity + data.MOQ <= data.maxQuantity) {
            let findIndexNo = neArr.findIndex(obj => obj.barcode == barcode)
            let updatedArr = [...neArr]; // Create a new array with the same elements as neArr
            updatedArr[findIndexNo] = {
                ...updatedArr[findIndexNo],
                quantity: updatedArr[findIndexNo].quantity + updatedArr[findIndexNo].MOQ
            };

            let updatedSaleProduct = saleProduct.map(item => {
                if (item.barcode == barcode) {

                    return { ...item, quantity: item.quantity - item.MOQ };
                }
                return item;
            }).filter(item => item.quantity !== 0);
            setnewArr(updatedArr);
            setSaleProduct(updatedSaleProduct);
        } else {
            message.error("Maximum Quantity Reached")
        }

    }
    const onRemoveQnty = (barcode, data) => {
        if (data.quantity - data.MOQ >= data.MOQ) {
            let findIndexNo = neArr.findIndex(obj => obj.barcode == barcode)
            let updatedArr = [...neArr]; // Create a new array with the same elements as neArr
            updatedArr[findIndexNo] = {
                ...updatedArr[findIndexNo],
                quantity: updatedArr[findIndexNo].quantity - updatedArr[findIndexNo].MOQ
            };

            let updatedSaleProduct = saleProduct.map(item => {
                if (item.barcode == barcode) {

                    return { ...item, quantity: item.quantity + item.MOQ };
                }
                return item;
            }).filter(item => item.quantity !== 0);
            setnewArr(updatedArr);
            setSaleProduct(updatedSaleProduct);
        } else {
            // message.error("Minimum Quantity Reached")
            onDeleteQnty(barcode, data)
        }

    }

    const onBarcodeChange = (e) => {
        setBarcode(e)
        if (e.length == 9) {
            changeMy(e)
        }
    }

    const handleScan = (data) => {
        setBarcode(data)
        if (data.length == 9) {
            changeMy(data)
        }
    }

    const onDeleteQnty = (barcode, data) => {
        let findIndexNeArr = neArr.findIndex(obj => obj.barcode == barcode);
        if (findIndexNeArr >= 0) {
            let removedItem = neArr[findIndexNeArr];
            let updatedNeArr = neArr.filter(obj => obj.barcode != barcode);
            setnewArr(updatedNeArr);

            let existingItemIndex = saleProduct.findIndex(obj => obj.barcode == barcode);
            if (existingItemIndex >= 0) {

                // If the item is already in saleProduct, update its quantity
                let updatedSaleProduct = [...saleProduct];
                updatedSaleProduct[existingItemIndex] = {
                    ...updatedSaleProduct[existingItemIndex],
                    quantity: updatedSaleProduct[existingItemIndex].maxQuantity
                };
                setSaleProduct(updatedSaleProduct);
            } else {

            }
        }
    }

    const onDeleteUnbillQnty = (barcode, data) => {
        let findIndexNeArr = unbillProduct.findIndex(obj => obj.barcode == barcode);
        if (findIndexNeArr >= 0) {
            let updatedNeArr = unbillProduct.filter(obj => obj.barcode != barcode);
            setUnbillProduct(updatedNeArr)
        }
    }

    const getVefiedProducts = () => {
        let requesObj

        if (title == "mergebill") {
            let data = invoiceData?.map(obj => obj.invoiceId)
            requesObj = {
                invoiceId: data
            }
        } else {
            requesObj = {
                invoiceId: [neArr[0]?.invoiceId]
            }
        }

        dispatch(getProductsVerified(requesObj)).then(response => {
            if (response.status == 200) {
                message.success("Verified!")
            }
            let startDate = moment(new Date()).format("YYYY-MM-DD")
            let endDate = moment(new Date()).format("YYYY-MM-DD")
            let reportObj = {
                startDate: startDate,
                endDate: endDate + " " + moment().format("HH:mm:ss"),
            };
            dispatch(getSalesListBydate(reportObj))
            setVisible(false)
        })
            .catch(error => {
                console.error("Error:", error);
            });
    }

    return (
        <div>
            {
                title == "mergebill" ? <>
                    <Button
                        type="primary"
                        onClick={() => showModal()}
                        // className="close-modal"
                        block
                        disabled={record?.remark === "Bill Deleted." ? true : false}
                    >
                        Verfy Bill
                    </Button>
                </> : <>
                    <button
                        className="btn"
                        type="button"
                        data-original-title=""
                        disabled={record?.remark === "Bill Deleted." ? true : false}
                        onClick={() => showModal()}
                    >
                        <Tooltip title="Verify Products">
                            <CheckCircleOutlined />
                        </Tooltip>
                    </button>
                </>
            }

            <Modal
                open={visible}
                // onOk={this.handleOk}
                onCancel={() => showModalCancel()}
                title="Verify Products"
                style={{ top: 20 }}
                okButtonProps={{ hidden: true }}
                cancelButtonProps={{ hidden: true }}
                destroyOnClose={true}
                width={1500}
            >
                {
                    products && products.length > 0 ? <>
                        <div className="verifyBillProduct-text"><b style={{
                            marginRight: "12px"
                        }}>Customer Name   :</b>
                            {products[0]?.userId?.name}</div>
                        <Table
                            dataSource={saleProduct}
                            bordered="true"
                            size="middle"
                            pagination={false}
                            scroll={{ y: 500 }}
                        >
                            <Column
                                title="SRN"
                                key="index"
                                render={(value, item, index) => index + 1}
                                width={60}
                            />
                            <Column
                                title="Barcode"
                                dataIndex="barcode"
                                key="barcode"
                                width={100}
                            />
                            <Column
                                title="Design No."
                                // {...this.getColumnSearchProps("designNumber")}
                                dataIndex="designNumber"
                                key="designNumber"
                                render={(value, item, index) => `${item?.details?.designNumber}`}
                                width={115}
                            />
                            <Column
                                title="Brand"
                                // {...this.getColumnSearchProps("brand")}
                                dataIndex="brand"
                                key="brand"
                                render={(value, item, index) => `${item?.details?.brand}`}
                                width={120}
                            />
                            <Column
                                title="Size"
                                dataIndex="size"
                                key="size"
                                width={80}
                                render={(value, item, index) => `${item?.details?.size}`}
                            />
                            <Column
                                title="Color"
                                dataIndex="color"
                                key="color"
                                width={90}
                                render={(value, item, index) => `${item?.details?.color}`}
                            />
                            <Column
                                title="Qnty"
                                dataIndex="quantity"
                                key="quantity"
                                width={60}
                            />
                        </Table>
                        <Row className="mt-4 mb-4">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div className="verify-Product">Verify Products</div>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Tooltip title="Barcode">
                                    <BarcodeReader
                                        // onError={this.handleError}
                                        onScan={handleScan}
                                    />
                                    <Input
                                        placeholder="Enter Barcode Number"
                                        name="barcode"
                                        value={barcode}
                                        onChange={e => onBarcodeChange(e.target.value)}
                                        required
                                    />
                                </Tooltip>
                            </Col>
                        </Row>
                        <Table
                            dataSource={neArr}
                            bordered="true"
                            size="middle"
                            pagination={false}
                            scroll={{ y: 500 }}
                            rowClassName={(record, index) => {
                                if (record.quantity != record.maxQuantity) {
                                    return "table-row-dark"
                                }
                            }}
                        >
                            <Column
                                title="SRN"
                                key="index"
                                render={(value, item, index) => index + 1}
                                width={60}
                            />

                            <Column
                                title="Barcode"
                                dataIndex="barcode"
                                key="barcode"
                                width={100}
                            />
                            <Column
                                title="Design No."
                                dataIndex="designNumber"
                                key="designNumber"
                                render={(value, item, index) => `${item?.details?.designNumber}`}
                                width={115}
                            />
                            <Column
                                title="Brand"
                                dataIndex="brand"
                                key="brand"
                                render={(value, item, index) => `${item?.details?.brand}`}
                                width={120}
                            />
                            <Column
                                title="Size"
                                dataIndex="size"
                                key="size"
                                width={80}
                                render={(value, item, index) => `${item?.details?.size}`}
                            />
                            <Column
                                title="Color"
                                dataIndex="color"
                                key="color"
                                width={90}
                                render={(value, item, index) => `${item?.details?.color}`}
                            />
                            <Column
                                title="Qnty"
                                dataIndex="quantity"
                                key="quantity"
                                width={60}
                            />
                            <Column
                                title="Action"
                                key="action"
                                width={70}
                                align="center"
                                render={(text, record, index) => {
                                    if (text.quantity == text.maxQuantity) {
                                        return <Tag color="success">
                                            Verified
                                        </Tag>
                                    } else {
                                        return <Row gutter={[8, 0]}>
                                            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                                <Tooltip title="Add Qnty">
                                                    <a type="button"
                                                        onClick={() => onAddQnty(text.barcode, text)}
                                                    >
                                                        <PlusCircleOutlined />
                                                    </a>
                                                </Tooltip>
                                            </Col>
                                            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                                <Tooltip title="Remove Qnty">
                                                    <a type="button"
                                                        onClick={() => onRemoveQnty(text.barcode, text)}
                                                    >
                                                        <MinusCircleOutlined />
                                                    </a>
                                                </Tooltip>
                                            </Col>
                                            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                                                <a type="button">
                                                    <Popconfirm
                                                        title="Are you sure to delete this Product?"
                                                        onConfirm={() => onDeleteQnty(text.barcode, text)}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <Tooltip title="Delete Product">
                                                            <DeleteOutlined />
                                                        </Tooltip>
                                                    </Popconfirm>
                                                </a>

                                            </Col>
                                        </Row>
                                    }
                                }
                                }
                            />
                        </Table>
                        {
                            unbillProduct && unbillProduct.length > 0 ? <>

                                <Row className="mt-4 mb-4">
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                        <div className="verify-Product">Unbill Products</div>
                                    </Col>
                                </Row>
                                <Table
                                    dataSource={unbillProduct}
                                    bordered="true"
                                    size="middle"
                                    pagination={false}
                                    scroll={{ y: 500 }}
                                    rowClassName="table-row-dark"
                                >
                                    <Column
                                        title="SRN"
                                        key="index"
                                        render={(value, item, index) => index + 1}
                                        width={60}
                                    />

                                    <Column
                                        title="Barcode"
                                        dataIndex="barcode"
                                        key="barcode"
                                        width={100}
                                    />
                                    <Column
                                        title="Qnty"
                                        dataIndex="quantity"
                                        key="quantity"
                                        width={60}
                                    />
                                    <Column
                                        title="Action"
                                        key="action"
                                        width={70}
                                        align="center"
                                        render={(text, record, index) => {
                                            return <Row gutter={[8, 0]}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                    <a type="button">
                                                        <Popconfirm
                                                            title="Are you sure to delete this Product?"
                                                            onConfirm={() => onDeleteUnbillQnty(text.barcode, text)}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <Tooltip title="Delete Product">
                                                                <DeleteOutlined />
                                                            </Tooltip>
                                                        </Popconfirm>
                                                    </a>

                                                </Col>
                                            </Row>

                                        }
                                        }
                                    />
                                </Table>

                            </> : <></>
                        }

                        {unbillProduct.length <= 0 && saleProduct.length <= 0 ? <>
                            <Row gutter={[8, 0]} justify="center">
                                <Col className="mt-4 mb-2" xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                    <Button className="close-modal-save-button" onClick={() => getVefiedProducts()}>Click to verify</Button>
                                </Col>
                            </Row>
                        </> : <>
                        </>}
                    </> : <>
                        <div className="d-flex justify-content-center align-items-center">
                            <img src={gifLoader} width={"300px"} height={"300px"} />
                        </div>
                    </>
                }

            </Modal>
        </div>
    );
}


VerifyBillProduct.propTypes = {
    getSalesBillListByIds: PropTypes.func.isRequired,
    getProductsVerified: PropTypes.func.isRequired,
    getSalesListBydate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    saleProducts: state.saleData.saleListByInvoiceId,
});

// export default VerifyBillProduct


export default connect(mapStateToProps, { getSalesBillListByIds, getProductsVerified, getSalesListBydate })(
    VerifyBillProduct
);