import React, { Component } from "react";
import { Row, Col, Tooltip, Form, Select, DatePicker, Button, Typography } from "antd";
import SalesReturnReportList from "./SalesReturnReportList";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { getSalesReturnReports, getSalesReturnReportTotalAmt } from "../../../../redux/actions/reportsActions";
import { getCustomers } from "../../../../redux/actions/customerAction";
import TopBarProgress from "react-topbar-progress-indicator";
import { debounce } from "lodash";
import BackButton from "../../Report/Button";

const { Option } = Select;
const dateFormat = "YYYY-MM-DD";
const { Title } = Typography

class SalesReturnReport extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      endDate: moment(new Date()).format("YYYY-MM-DD"),
      startDate: "",
      customerName: "",
      customerId: "",
      returnType: 1,
      colVisible: 1,
    };
    this.debouncedGetData = debounce(this.getData, 500);
  }

  componentDidMount() {
    this.props.getCustomers("STORE_USER");
  }
  handleChangeCustomer = (key, value) => {
    let customerId = value.value;
    let customerName = value.name;
    this.setState({
      customerId: customerId,
      customerName: customerName,
    });
    // this.debouncedGetData("customer");
  };
  handleReturnChange = (key, value) => {
    this.setState({
      returnType: key,
    });
  };
  onStartDateChange = (date, dateString) => {
    this.setState({ startDate: dateString });
  };
  onEndDateChange = (date, dateString) => {
    this.setState({ endDate: dateString });
  };
  showDataOnList = async () => {
    let today = moment(new Date()).format("YYYY-MM-DD")
    if (today == this.state.endDate) {
      let reportObj = {
        userId: this.state.customerId,
        returnType: this.state.returnType,
        startDate: this.state.startDate,
        endDate: this.state.endDate + " " + moment().format("HH:mm:ss"),
      };
      await this.props.getSalesReturnReportTotalAmt(reportObj)
      await this.props.getSalesReturnReports(reportObj);
    } else {
      const dateMoment = moment(this.state.endDate, 'YYYY-MM-DD');
      const endTime = dateMoment.endOf('day').format('HH:mm:ss');
      const endDateWithEndTime = `${this.state.endDate} ${endTime}`;
      let reportObj = {
        userId: this.state.customerId,
        returnType: this.state.returnType,
        startDate: this.state.startDate,
        endDate: endDateWithEndTime,
      };
      await this.props.getSalesReturnReportTotalAmt(reportObj)
      await this.props.getSalesReturnReports(reportObj);
    }

    this.setState({
      colVisible: this.state.returnType
    })
  };
  getData = (name) => {
    if (name == "customer") this.props.getCustomers("STORE_USER");
  };
  render() {
    let customerOptions = this.props.customers.map((customer) => {
      return (
        <Option name={customer.name} value={customer._id}>
          {customer.name}
        </Option>
      );
    });
    return (
      <div className="subCategory-dashboard">
        {this.props.progressBarStatus && <TopBarProgress />}
        <div className="container-fluid category-list">
          <Row className="title-row">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row gutter={24}>
                <Col span={12}>
                  <div className="page-header">Sales Return Report</div>
                </Col>
                <Col span={12}>
                  <BackButton />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form
                ref={this.formRef}
                name="add-SalesReturn"
                className="add-SalesReturn"
                onFinish={this.showDataOnList}
                initialValues={{ remember: true }}
              >
                <Row gutter={[16, 0]} className="mt-4">
                  <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                    <Tooltip title="Select Customer">
                      <Form.Item
                        name="customerName"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please Select Customer Name !",
                      //   },
                      // ]}
                      >
                        <Select
                          showSearch
                          placeholder="Select Customer"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                          onChange={this.handleChangeCustomer}
                        // onClick={() => this.debouncedGetData("customer")}
                        // onInputKeyDown={() => this.debouncedGetData("customer")}
                        >
                          {customerOptions}
                        </Select>
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                    <Tooltip title="Select Start Date">
                      <Form.Item
                        name="startDate"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Start Date !",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          onChange={this.onStartDateChange}
                          placeholder="Select Start Date"
                          format={dateFormat}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                    <Tooltip title="Select End Date">
                      <Form.Item
                        name="endDate"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Start Date !",
                          },
                        ]}
                        initialValue={moment()}
                      >
                        <DatePicker
                          disabledDate={(current) => {
                            return current && current > Date.now();
                          }}
                          style={{ width: "100%" }}
                          onChange={this.onEndDateChange}
                          placeholder="Select End Date"
                          format={dateFormat}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                    <Tooltip title="Return Type">
                      <Form.Item
                        name="returnType"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please Select Return Type !",
                      //   },
                      // ]}
                      >
                        <Select
                          style={{ width: "100%" }}
                          showSearch
                          placeholder="Select Return Type"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                          defaultValue="1"
                          onChange={this.handleReturnChange}
                        >
                          <Option value="1">Normal</Option>
                          <Option value="2">Defective</Option>
                        </Select>
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="close-modal"
                        block
                      >
                        Search
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Title level={5}>
                Total Amount (Normal & Defective) :{" "}
                <span style={{ color: "#52c41a" }}>
                  ₹ {this.props && this.props?.reportAmt && this.props?.reportAmt?.totalAmount && this.props?.reportAmt?.totalAmount || 0}
                </span>
              </Title>
            </Col>
          </Row>
          <SalesReturnReportList
            reports={this.props.reports}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            customerName={this.state.customerName}
            returnType={this.state.returnType}
            colVisible={this.state.colVisible}
            totalAmount={this.props.reportAmt}
          />
        </div>
      </div>
    );
  }
}
SalesReturnReport.propTypes = {
  getSalesReturnReports: PropTypes.func.isRequired,
  getSalesReturnReportTotalAmt: PropTypes.func.isRequired,
  getCustomers: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  reports: state.reportData.salesreturnreports,
  reportAmt: state.reportData.salesReturnReportAmt,
  customers: state.customerData.customers,
  progressBarStatus: state.progressBarReducer.showProgressBar
});

export default connect(mapStateToProps, {
  getSalesReturnReports,
  getCustomers,
  getSalesReturnReportTotalAmt
})(SalesReturnReport);
