/* /////////////////////////////////////// */
/*
Date              created by               Modifications
 
17-08-2023        Mohit                   Create This Page for Update Sales Bills  
22-08-2023        Mohit                   Add Qty and Remove Qty function update        

*/
/* /////////////////////////////////////// */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Checkbox,
  DatePicker,
  Button,
  Form,
  Input,
  Select,
  notification,
  Tooltip,
  Alert,
  message,
  Spin,
} from "antd";
import moment from "moment";
import {
  PlusOutlined,
  StopOutlined,
  ArrowLeftOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import debounce from "lodash.debounce";
import {
  getCustomers,
  getActiveandInactiveCustomer,
} from "../../../redux/actions/customerAction";
import { getBrandBySearch } from "../../../redux/actions/brandAction";
import ShowSalesDetails from "./ShowSalesDetails";
import {
  getLookupData,
  getPurchaseByBarcodeSale,
} from "../../../redux/actions/purchaseAction";
import {
  updateSales,
  deleteSales,
  checkSalesitemisdeleted,
} from "../../../redux/actions/saleAction";
import LookupModalSales from "../sales/LookupModalSales";
import { uuid } from "uuidv4";
import GenerateBill from "./GenerateBill";
import BarcodeReader from "react-barcode-reader";
import gifLoader from "../../../assets/images/giphy.gif";
import ViewGenerateBill from "./ViewGenerateBill";
import Swal from "sweetalert2";
let order = 1;
let tempObject = [];
const dateFormat = "DD/MM/YYYY";
const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;
// const dateFormat = "YYYY/MM/DD";
const { Option } = Select;

class UpdateSales extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    const currentDate = moment().format("DD/MM/YYYY");
    const currentTime = moment().format("HH:mm:ss");
    this.state = {
      mySaleData: [],
      salesOrderChangeStatus: false,
      sweetLoading: false,
      deletedSales: [],
      restoredSale: [],
      myPurchaseData: [],
      invoiceNumber: "",
      saleDate: currentDate,
      saleTime: currentTime,
      saleBillDate: moment(
        `${currentDate} ${currentTime}`,
        "DD/MM/YYYY HH:mm:ss"
      ).toDate(),
      customerName: "",
      customerId: "",
      agentId: "",
      agent: "",
      salesPersonName: "",
      lrNumber: "",
      lrDate: "",
      remark: "",
      transport: "",
      grAmount: 0,
      barcode: "",
      designNumber: "",
      boxes: "",
      totalBoxes: "",
      totalQuantity: 0,
      totalAmount: 0,
      brandName: "",
      brandId: "",
      s: "",
      selectedData: "",
      salesPersonId: "",
      salesPersonName: "",
      saleQty: 0,
      discount: 0,
      tax: 5,
      pf: 0,
      h: 0,
      billedGR: false,
      netAmount: 0, //Prateek Logic to disable the button when netAmount is empty
    };
    this.handleScan = this.handleScan.bind(this);
    this.debouncedGetData = debounce(this.getData, 500);
  }
  handleChangeCustomer = (key, value) => {
    const discount=value?.name[3]?.userDiscount ? value?.name[3]?.userDiscount : 0;
    this.setState({discount})
    // if (value.name)
    let transport = value.name[0];
    let agentName = "";
    let agentId = "";
    if (!value.name[1]) {
      agentName = "";
      agentId = "";
    } else {
      agentName = value.name[1].name;

      agentId = value.name[1]._id;
    }
    let customerId = value.value;
    let grAmount = value.name[2];
    let clientName = value.children;
    let grFlag = false;
    if (grAmount) {
      grFlag = true;
    }
    this.setState({
      customerName: clientName,
      agent: agentName,
      agentId,
      transport,
      customerId,
      grAmount,
      grFlag,
    });
    if (grFlag && this.state.totalAmount) {
      let total = this.state.totalAmount - grAmount;
      let discountedAmount;
      discountedAmount = (total * discount) / 100;
      let finalDiscountedAmount = total - discountedAmount;

      let taxedAmount = ((total - discountedAmount) * this.state.tax) / 100;
      let finalAmount = total - discountedAmount + taxedAmount;
      finalAmount = Math.round(finalAmount);
      let netAmount;
      if (this.state.pf != undefined && this.state.h != undefined) {
        netAmount =
          Number(finalAmount) + Number(this.state.pf) + Number(this.state.h);
      } else if (this.state.pf != undefined) {
        netAmount = Number(finalAmount) + Number(this.state.pf);
      } else if (this.state.h != undefined) {
        netAmount = Number(finalAmount) + Number(this.state.h);
      }
      this.setState({
        netAmount,
        finalAmount,
        taxedAmount,
        discountedAmount,
        finalDiscountedAmount,
      });
    } else {
      let total = this.state.totalAmount;
      let discountedAmount;
      discountedAmount = (total * discount) / 100;
      let finalDiscountedAmount = total - discountedAmount;
      let taxedAmount = ((total - discountedAmount) * this.state.tax) / 100;
      let finalAmount = total - discountedAmount + taxedAmount;
      finalAmount = Math.round(finalAmount);
      let netAmount;
      if (this.state.pf != undefined && this.state.h != undefined) {
        netAmount =
          Number(finalAmount) + Number(this.state.pf) + Number(this.state.h);
      } else if (this.state.pf != undefined) {
        netAmount = Number(finalAmount) + Number(this.state.pf);
      } else if (this.state.h != undefined) {
        netAmount = Number(finalAmount) + Number(this.state.h);
      }
      this.setState({
        netAmount,
        finalAmount,
        taxedAmount,
        discountedAmount,
        finalDiscountedAmount,
      });
    }
  };

  handleChangeSalesPerson = (value, option) => {
    this.setState({ salesPersonId: value, salesPersonName: option.name });
  };
  handleChangeBrand = async (key, value) => {
    let brandName = value.name;
    let brandId = value.value;
    this.setState({ brandName, brandId });
    if (this.state.designNumber) {
      await this.props.getLookupData({
        designNumber: this.state.designNumber,
        brandName,
      });
      this.setState({ s: uuid() });
      this.debouncedGetData("brand");
    }
  };

  handleBlur = (e) => {
    if (e.currentTarget.value === "0") e.currentTarget.value = "1";
  };
  preventScroll = (e) => {
    if (document.activeElement === e.target) {
      e.preventDefault();
    }
  };

  clearSatate = () => {
    this.setState({
      myPurchaseData: [],
      saleDate: "",
      saleBillDate: "",
      saleTime: "",
      customerName: "",
      remark: "",
      discount: "",
      tax: "",
      pf: "",
      finalAmount: "",
      netAmount: 0,
      MOQ: "",
      totalQuantity: "",
      updateBill: "",
      grAmount: "",
      grFlag: false,
      totalAmount: "",
      salesOrderChangeStatus: false,
    });
  };
  componentWillUnmount = () => {
    // this.props.createSales({}, {}, "blank");

    // if (this.state.netAmount > this.state.grAmount && this.state.netAmount != "" && this.state.customerId != "" && this.state.netAmount > 0) {
    //   this.createSales(false)
    // }
    this.props.updateSales({}, {}, "blank");
    this.clearSatate();
    order = 1;
  };
  onChange = (event) => {
    if (event.target.name === "discount" && this.state.totalAmount) {
      let value = event.target.value;
      let finalAmount;
      let discountedAmount = 0;
      let taxedAmount = 0;

      if (this.state.grFlag) {
        let total = this.state.totalAmount - this.state.grAmount;
        discountedAmount = (total * value) / 100;
      } else {
        discountedAmount = (this.state.totalAmount * value) / 100;
      }

      if (this.state.tax) {
        if (this.state.grFlag) {
          let total =
            this.state.totalAmount - this.state.grAmount - discountedAmount;
          taxedAmount = (total * this.state.tax) / 100;
        } else {
          taxedAmount =
            ((this.state.totalAmount - discountedAmount) * this.state.tax) /
            100;
        }
      }

      if (this.state.grFlag) {
        let total = this.state.totalAmount - this.state.grAmount;
        finalAmount = total - discountedAmount + taxedAmount;
      } else {
        finalAmount = this.state.totalAmount - discountedAmount + taxedAmount;
      }
      finalAmount = Math.round(finalAmount);

      let updatedNetAmount =
        finalAmount + Number(this.state.pf) + Number(this.state.h);

      // if (updatedNetAmount > this.state.grAmount && this.state.grFlag) {
      //   updatedNetAmount -= this.state.grAmount;
      // }
      let finalDiscountedAmount;

      if (this.state.grFlag) {
        let total = this.state.totalAmount - this.state.grAmount;
        finalDiscountedAmount = total - discountedAmount;
      } else {
        finalDiscountedAmount = this.state.totalAmount - discountedAmount;
      }

      return this.setState({
        [event.target.name]: event.target.value,
        finalAmount,
        discountedAmount,
        taxedAmount,
        finalDiscountedAmount,
        netAmount: updatedNetAmount,
      });
    } else if (event.target.name === "tax" && this.state.totalAmount) {
      let value = event.target.value;
      let finalAmount;
      let discountedAmount = 0;
      let taxedAmount = 0;
      if (this.state.discount) {
        if (this.state.grFlag) {
          let total = this.state.totalAmount - this.state.grAmount;
          discountedAmount = (total * this.state.discount) / 100;
        } else {
          discountedAmount =
            (this.state.totalAmount * this.state.discount) / 100;
        }
      }
      if (this.state.grFlag) {
        let total =
          this.state.totalAmount - this.state.grAmount - discountedAmount;
        taxedAmount = (total * value) / 100;
      } else {
        taxedAmount = (this.state.finalDiscountedAmount * value) / 100;
      }

      if (this.state.grFlag) {
        let total = this.state.totalAmount - this.state.grAmount;
        total = total - discountedAmount;
        finalAmount = total + taxedAmount;
      } else {
        finalAmount = this.state.totalAmount - discountedAmount + taxedAmount;
      }
      finalAmount = Math.round(finalAmount);

      let updatedNetAmount =
        finalAmount + Number(this.state.pf) + Number(this.state.h);
      // if (updatedNetAmount > this.state.grAmount && this.state.grFlag) {
      //   updatedNetAmount -= this.state.grAmount;
      // }
      let finalDiscountedAmount;

      if (this.state.grFlag) {
        let total = this.state.totalAmount - this.state.grAmount;
        finalDiscountedAmount = total - discountedAmount;
      } else {
        finalDiscountedAmount = this.state.totalAmount - discountedAmount;
      }

      return this.setState({
        [event.target.name]: value,
        finalAmount,
        taxedAmount,
        discountedAmount,

        finalDiscountedAmount,
        netAmount: updatedNetAmount,
      });
    } else if (event.target.name === "pf" && this.state.finalAmount) {
      let value = event.target.value;
      let netAmount;
      netAmount = Number(this.state.finalAmount) + Number(value);
      if (this.state.h) {
        netAmount =
          Number(this.state.finalAmount) +
          (Number(value) + Number(this.state.h));
      }
      //netAmount += this.state.finalAmount;
      // if (this.state.grFlag) {
      //   netAmount = netAmount - this.state.grAmount;
      // }
      return this.setState({
        [event.target.name]: event.target.value,
        netAmount,
      });
    } else if (event.target.name === "h" && this.state.finalAmount) {
      let value = event.target.value;
      let netAmount;
      netAmount = Number(this.state.finalAmount) + Number(value);
      if (this.state.pf) {
        netAmount =
          Number(this.state.finalAmount) +
          (Number(value) + Number(this.state.pf));
      }
      // if (this.state.grFlag) {
      //   netAmount = netAmount - this.state.grAmount;
      // }
      //netAmount += this.state.finalAmount;
      return this.setState({
        [event.target.name]: event.target.value,
        netAmount,
      });
    }
    this.setState({ [event.target.name]: event.target.value });
  };

  onBarcodeChange = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.value.length == 9 || event.target.value.length == 7) {
      let check = this.state.restoredSale.some((item) => {
        return item?.barcode == event.target.value;
      });
      console.log("check", check);
      if (!check) {
        const res = await this.props.checkSalesitemisdeleted({
          barcode: event.target.value,
          invoiceId: this.state.invoiceNumber,
        });
        if (res.data.status) {
          const result = await Swal.fire({
            title: "Do you want to restore this Sale?",
            showDenyButton: true,
            confirmButtonText: "Yes",
            denyButtonText: "No",
            customClass: {
              actions: "my-actions",
              cancelButton: "order-1 right-gap",
              confirmButton: "order-2",
              denyButton: "order-3",
            },
          });
          if (result.isConfirmed) {
            console.log("result", result);
            await this.props.getPurchaseByBarcodeSale({
              barcode: event.target.value,
              invoiceId: this.state.invoiceNumber,
            });
            if (this.props.purchase) {
              this.setState({
                restoredSale: [...this.state.restoredSale, this.props.purchase],
              });
              this.selectedData(this.props.purchase, "barcode");
            }
            this.showDataOnList();
            this.setState({ modalVisible: true });
          }
        } else {
          await this.props.getPurchaseByBarcodeSale({
            barcode: event.target.value,
            invoiceId: this.state.invoiceNumber,
          });
          if (this.props.purchase) {
            this.selectedData(this.props.purchase, "barcode");
          }
        }
      } else {
        await this.props.getPurchaseByBarcodeSale({
          barcode: event.target.value,
          invoiceId: this.state.invoiceNumber,
        });
        if (this.props.purchase) {
          this.selectedData(this.props.purchase, "barcode");
        }
      }
    }
  };
  onSaleDateChange = (date, dateString) => {
    // let date_ = moment(new Date(dateString));

    // this.setState({ saleDate: dateString , saleTime : date.format('HH:mm:ss') });
    // this.setState({ saleBillDate: d });
    // Create a moment instance for the provided dateString

    let currentTime = moment().format("HH:mm:ss");
    this.setState({ saleDate: dateString, saleTime: currentTime });
    let d = moment(
      dateString + " " + currentTime,
      "DD/MM/YYYY HH:mm:ss"
    ).toDate();
    this.setState({ saleBillDate: d });
  };
  onLRDateChange = (date, dateString) => {
    this.setState({ lrDate: dateString });
  };
  selectedData = (data, name = undefined, scan = undefined) => {
    // alert("bubye");
    data.boxes = 1;
    // data.saleQty = data.MOQ;
    if (name == "barcode") {
      this.setState({
        selectedData: data,
        //boxes: data.quantity,
        designNumber: data.designNumber,
        brandName: data.brand,
      });
      this.formRef.current.setFieldsValue({
        brand: data.brand,
      });
      if (scan) {
        this.showDataOnList(scan);
      }
    }
    if (this.state.designNumber && this.state.brandName) {
      this.setState({
        selectedData: data,
        //boxes: data.quantity,
        barcode: data.barcode,
      });
    }
  };
  checkBarcodeExists = (barcode) => {
    let exist = this.state.myPurchaseData.findIndex(
      (element) => element.barcode === barcode
    );
    return exist;
  };
  async handleScan(data) {
    if (data.length == 9 || data.length == 7) {
      let check = this.state.restoredSale.some((item) => {
        return item?.barcode == data;
      });
      if (!check) {
        const res = await this.props.checkSalesitemisdeleted({
          barcode: data,
          invoiceId: this.state.invoiceNumber,
        });
        if (res.data.status) {
          const result = await Swal.fire({
            title: "Do you want to restore this Sale?",
            showDenyButton: true,
            confirmButtonText: "Yes",
            denyButtonText: "No",
            customClass: {
              actions: "my-actions",
              cancelButton: "order-1 right-gap",
              confirmButton: "order-2",
              denyButton: "order-3",
            },
          });
          if (result.isConfirmed) {
            console.log("result", result);
            await this.props.getPurchaseByBarcodeSale({
              barcode: data,
              invoiceId: this.state.invoiceNumber,
            });
            if (this.props.purchase) {
              this.setState({
                restoredSale: [...this.state.restoredSale, this.props.purchase],
              });
              this.selectedData(this.props.purchase, "barcode", "scan");
            }
            this.showDataOnList();
            this.setState({ modalVisible: true });
          }
        } else {
          await this.props.getPurchaseByBarcodeSale({
            barcode: data,
            invoiceId: this.state.invoiceNumber,
          });
          if (this.props.purchase) {
            this.props.purchase && this.props.purchase.totalPurchaseValue <= 0
              ? message.warn("out of stock")
              : this.selectedData(this.props.purchase, "barcode", "scan");
          }
        }
      } else {
        await this.props.getPurchaseByBarcodeSale({
          barcode: data,
          invoiceId: this.state.invoiceNumber,
        });
        if (this.props.purchase) {
          this.props.purchase && this.props.purchase.totalPurchaseValue <= 0
            ? message.warn("out of stock")
            : this.selectedData(this.props.purchase, "barcode", "scan");
        }
      }
    }
    // alert("bye");
  }
  openNotificationWithIcon = (type) => {
    notification[type]({
      message: "Field Missing",
      description: "Please Select Sales Person Name.",
    });
  };

  handleBarcodeCheckInDelete = (barcode, data) => {
    let findBarcode = this.state.deletedSales.find(
      (obj) => obj.barcode === barcode
    );
    if (findBarcode) {
      let updateId = data.map((obj) => {
        if (obj.barcode === findBarcode.barcode) {
          return { ...obj, salesOrderId: findBarcode.salesOrderId };
        } else {
          return obj;
        }
      });
      return updateId;
    } else {
      return data;
    }
  };

  showDataOnList = async (scan) => {
    if (this.state.selectedData) {
      let index = this.checkBarcodeExists(this.state.selectedData.barcode);

      let temp = [];
      temp = this.state.myPurchaseData.map((ele) => {
        return {
          ...ele,
          updateQty: false,
          netTotalPurchaseValue:
            ele.netTotalPurchaseValue || ele.netTotalPurchaseValue == 0
              ? ele.netTotalPurchaseValue
              : ele.totalPurchaseValue,
        };
      });

      // let saleQtyTemp = 0;
      if (index != -1) {
        // if (temp[index].saleQty >= (temp[index].totalPurchaseValue + temp[index].saleQty)) {
        if (temp[index].MOQ <= temp[index].netTotalPurchaseValue) {
          temp[index].boxes += 1;
          delete temp[index]["saleQty"];
          temp[index]["saleQty"] = temp[index].boxes * temp[index].MOQ;
          temp[index]["xyz"] = temp[index].boxes * temp[index].MOQ;
          temp[index]["updateQty"] = true;
          temp[index]["totalPurchaseValue"] =
            temp[index].totalPurchaseValue - temp[index].MOQ;
          temp[index]["finalAmount"] =
            temp[index].saleQty * this.state.selectedData.wsp;
          temp[index]["netTotalPurchaseValue"] =
            temp[index].netTotalPurchaseValue - temp[index].MOQ;
          temp[index].key = uuid();
          this.setState({ myPurchaseData: temp });
        } else {
          message.warn("You can not add more then the purchase quantity");
        }
      } else {
        if (
          this.props &&
          this.props.purchase &&
          this.props.purchase.totalPurchaseValue <= 0
        ) {
          message.warn("out of stock");
        } else {
          if (scan && !this.state.salesPersonId) {
            this.openNotificationWithIcon("error");
            return;
            // return (
            //   <Alert
            //     message="Error"
            //     description="This is an error message about copywriting."
            //     type="error"
            //     showIcon
            //     closable
            //   />
            // );
          }
          this.state.selectedData["salesPersonId"] = this.state.salesPersonId;
          this.state.selectedData["salesPersonName"] =
            this.state.salesPersonName;
          this.state.selectedData["finalAmount"] =
            this.state.selectedData.MOQ * this.state.selectedData.wsp;
          this.state.selectedData["netTotalPurchaseValue"] =
            this.state.selectedData.totalPurchaseValue -
            this.state.selectedData.MOQ;
          this.state.selectedData["totalPurchaseValue"] =
            this.state.selectedData.totalPurchaseValue -
            this.state.selectedData.MOQ;

          // order = 1
          let e = 0;
          let v = this.state.myPurchaseData.length;
          let tempPurchaseData = [
            ...this.state.myPurchaseData,
            this.state.selectedData,
          ];
          while (e <= v) {
            if (tempPurchaseData[e].order) {
            } else {
              tempPurchaseData[e]["order"] = order;
              order = order + 1;
            }
            e++;
          }
          temp = tempPurchaseData;
        }
      }
      // ========Check===
      let e = 0;
      let v = temp.length;
      let totalAmount = 0;
      let totalQuantity = 0;
      let totalBoxes = 0;
      let finalAmount = 0;
      let discountedAmount = 0;
      let taxedAmount = 0;
      let netAmount = 0;
      while (e < v) {
        totalBoxes += temp[e].boxes;
        temp[e].saleQty = temp[e].boxes * temp[e].MOQ;
        totalQuantity += temp[e].saleQty;
        totalAmount += temp[e].wsp * temp[e].saleQty;

        ++e;
      }

      temp = await this.handleBarcodeCheckInDelete(this.state.barcode, temp);

      // if (this.state.discount) {
      //   discountedAmount = (totalAmount * this.state.discount) / 100;
      // }
      // if (this.state.tax) {
      //   taxedAmount = ((totalAmount - discountedAmount) * this.state.tax) / 100;
      // }

      if (this.state.discount) {
        if (this.state.grFlag && totalAmount) {
          let total = totalAmount - this.state.grAmount;
          discountedAmount = (total * this.state.discount) / 100;
        } else {
          discountedAmount = (totalAmount * this.state.discount) / 100;
        }
      }

      if (this.state.tax) {
        if (this.state.grFlag && totalAmount) {
          let total = totalAmount - this.state.grAmount;
          taxedAmount = ((total - discountedAmount) * this.state.tax) / 100;
        } else {
          taxedAmount =
            ((totalAmount - discountedAmount) * this.state.tax) / 100;
        }
      }
      if (this.state.grFlag) {
        let total = totalAmount - this.state.grAmount;
        finalAmount = total - discountedAmount + taxedAmount;
      } else {
        finalAmount = totalAmount - discountedAmount + taxedAmount;
      }

      finalAmount = Math.round(finalAmount);
      if (this.state.pf != undefined && this.state.h != undefined) {
        netAmount =
          Number(finalAmount) + Number(this.state.pf) + Number(this.state.h);
      } else if (this.state.pf != undefined) {
        netAmount = Number(finalAmount) + Number(this.state.pf);
      } else if (this.state.h != undefined) {
        netAmount = Number(finalAmount) + Number(this.state.h);
      }

      // if (this.state.grFlag && netAmount) {
      //   netAmount = netAmount - this.state.grAmount;
      // }
      let finalDiscountedAmount;
      if (this.state.grFlag) {
        let total = totalAmount - this.state.grAmount;
        finalDiscountedAmount = total - discountedAmount;
      } else {
        finalDiscountedAmount = totalAmount - discountedAmount;
      }
      this.setState({
        myPurchaseData: temp,
        selectedData: undefined,
        barcode: "",
        boxes: "",
        designNumber: "",
        brandName: "",
        totalAmount,
        totalQuantity,
        totalBoxes,
        finalAmount,
        taxedAmount,
        discountedAmount,
        netAmount,

        finalDiscountedAmount,
      });

      this.formRef.current.setFieldsValue({
        brand: undefined,
        //salesPersonName: undefined,
      });
    }
  };

  getData = (name, value) => {
    if (name == "customer") this.props.getActiveandInactiveCustomer();
    if (name == "sales") this.props.getCustomers("SALES");
    if (name == "brand") this.props.getBrandBySearch(value);
  };

  createSales = async (salesOrder) => {
    // this.setState({ sweetLoading: true })
    this.setState({ sweetLoading: true }, async () => {
      if (this.state.myPurchaseData.length) {
        let salesOrderObj = {
          totalQuantity: this.state.totalQuantity,
          salesOrder: salesOrder,
          remark: this.state.remark,
          pAndf: this.state.pf,
          labour: this.state.h,
          tax: this.state.tax,
          discount: this.state.discount,
          userId: this.state.customerId,
          netAmount: this.state.netAmount,
          totalAmount: this.state.totalAmount,
          totalBoxes: this.state.totalBoxes,
          date: this.state.saleBillDate,
          isGrAmount: this.state.grFlag,
          deletedSales:
            this.state.deletedSales.length > 0 ? this.state.deletedSales : [],
          salesOrderChangeStatus: salesOrder
            ? false
            : this.state.salesOrderChangeStatus,
          restoredSale: this.state.restoredSale,
        };

        if (this.state.grFlag) {
          salesOrderObj["srAmount"] = this.state.grAmount;
        }

        let sales = [];
        for (let i = 0; i < this.state.myPurchaseData.length; ++i) {
          let check = this.state.restoredSale.some((barcode) => {
            return barcode.barcode == this.state.myPurchaseData[i].barcode;
          });

          //divyesh moq
          if (!check) {
            let newObj = {
              salesPersonName: this.state.salesPersonName,
              salesPersonId: this.state.salesPersonId,
              quantity: this.state.myPurchaseData[i].saleQty,
              barcode: this.state.myPurchaseData[i].barcode,
              MOQ: this.state.myPurchaseData[i].MOQ,
            };
            if (this.state.myPurchaseData[i].salesOrderId) {
              newObj["_id"] = this.state.myPurchaseData[i].salesOrderId;
            }
            sales.push(newObj);
          } else {
            let itemToUpdate = this.state.restoredSale.find(
              (item) => item.barcode == this.state.myPurchaseData[i].barcode
            );
            if (itemToUpdate) {
              itemToUpdate.saleQty = this.state.myPurchaseData[i].saleQty;
            }
          }
        }
        salesOrderObj["sales"] = sales;
        if (this.state.invoiceNumber) {
          salesOrderObj["invoiceNumber"] = this.state.invoiceNumber;
        }
        if (salesOrderObj.userId) {
          let newdeletedSalesArr = salesOrderObj?.deletedSales?.filter(
            (deletedSale) =>
              !salesOrderObj.sales.some(
                (sale) => sale.barcode === deletedSale.barcode
              ) && deletedSale.salesOrderId
          );

          newdeletedSalesArr = newdeletedSalesArr?.map((obj) => {
            const saleObj = this.state.mySaleData.find(
              (sale) => sale.details.barcode === obj.barcode
            );
            return saleObj ? { ...obj, saleQty: saleObj.quantity } : obj;
          });
          salesOrderObj["deletedSales"] = newdeletedSalesArr;
          console.log("saleobj", salesOrderObj);
          await this.props
            .updateSales(salesOrderObj, this.props.history)
            .then((respo) => {
              if (respo.invoiceId) {
                this.setState({ sweetLoading: false });
              }
            })
            .catch((error) => console.log("catch", error))
            .finally(() => this.setState({ sweetLoading: false }));
        } else {
          message.error("Please Select Customer");
        }
        this.setState({
          // myPurchaseData: [],
          invoiceNumber: "",
          // saleDate: "",
          // customerName: "",
          customerId: "",
          agentId: "",
          agent: "",
          salesPersonName: "",
          lrNumber: "",
          // remark: "",
          transport: "",
          // grAmount: "",
          // discount: "",
          // tax: "",
          h: "",
          // pf: "",
          // finalAmount: "",
          // netAmount: "",
          // MOQ: "",
          boxes: "",
          totalBoxes: "",
          // totalQuantity: "",
          agentId: "",
          selectedData: "",
          salesPersonId: "",
          salesPersonName: "",
          billedGR: false,
        });
        this.formRef.current.setFieldsValue({
          salesPersonName: undefined,
          customerName: undefined,
        });
      }
    });
  };
  //checkbox in addsale to get amount(including any previous due)
  oncheckChange = (e) => {
    if (e.target.checked && this.state.netAmount && this.state.grAmount) {
      let total = this.state.totalAmount - this.state.grAmount;
      let discountedAmount = (total * this.state.discount) / 100;
      let finalDiscountedAmount = total - discountedAmount;
      let taxedAmount = (finalDiscountedAmount * this.state.tax) / 100;
      let finalAmount = finalDiscountedAmount + taxedAmount;
      let updatedNetAmount =
        Number(finalAmount) + Number(this.state.h) + Number(this.state.pf);
      this.setState({
        netAmount: updatedNetAmount,
        grFlag: true,
        discountedAmount: discountedAmount,
        finalDiscountedAmount: finalDiscountedAmount,
        taxedAmount: taxedAmount,
        finalAmount: finalAmount,
      });
      this.setState({ billedGR: true });
    } else if (
      !e.target.checked &&
      this.state.netAmount &&
      this.state.grAmount
    ) {
      let total = this.state.totalAmount;
      let discountedAmount = (total * this.state.discount) / 100;
      let finalDiscountedAmount = total - discountedAmount;
      let taxedAmount = (finalDiscountedAmount * this.state.tax) / 100;
      let finalAmount = finalDiscountedAmount + taxedAmount;

      let updatedNetAmount =
        Number(finalAmount) + Number(this.state.h) + Number(this.state.pf);
      this.setState({
        netAmount: updatedNetAmount,
        grFlag: false,
        discountedAmount: discountedAmount,
        finalDiscountedAmount: finalDiscountedAmount,
        taxedAmount: taxedAmount,
        finalAmount: finalAmount,
      });
      this.setState({ billedGR: false });
    } else {
      this.setState({ grFlag: e.target.checked });
    }
  };
  deleteSale = async (id) => {
    let toDelete = {};
    let purchases = this.state.myPurchaseData.filter((purchase) => {
      if (purchase._id != id) return purchase;
      toDelete = purchase;
    });
    console.log("toDelete", toDelete);
    const res = await this.props.checkSalesitemisdeleted({
      barcode: toDelete?.barcode,
      invoiceId: this.state.invoiceNumber,
    });
    if (!res.data.status) {
      let deletedData = this.state.deletedSales.concat(toDelete);
      this.setState({
        deletedSales: deletedData,
      });
    } else {
      let restoreData = this.state.restoredSale.find((sale) => {
        if (sale._id == toDelete._id) {
          return true;
        }
      });
      if (restoreData) {
        let restoredSales = this.state.restoredSale.filter(
          (sale) => sale._id != toDelete._id
        );
        this.setState({
          restoredSale: restoredSales,
        });
      }
    }

    if (toDelete && toDelete.salesOrderId) {
      // this.props.deleteSales(toDelete.salesOrderId);
      let updatedQuantity = this.state.totalQuantity - toDelete.saleQty;
      let updatedBoxes = this.state.totalBoxes - toDelete.boxes;
      let updatedTotalAmount =
        this.state.totalAmount - toDelete.wsp * toDelete.saleQty;
      let updatedFinalAmount = 0;
      let updatedNetAmount = 0;

      let total,
        discountedAmount,
        finalDiscountedAmount,
        taxedAmount,
        finalAmount,
        netAmount;

      if (this.state.grFlag) {
        total = updatedTotalAmount - this.state.grAmount;
      } else {
        total = updatedTotalAmount;
      }

      discountedAmount = (total * this.state.discount) / 100;
      finalDiscountedAmount = total - discountedAmount;
      taxedAmount = (finalDiscountedAmount * this.state.tax) / 100;
      finalAmount = finalDiscountedAmount + taxedAmount;
      netAmount =
        Number(finalAmount) + Number(this.state.h) + Number(this.state.pf);

      // if (this.state.discount) {
      //   discountedAmount = (updatedTotalAmount * this.state.discount) / 100;
      // }
      // if (this.state.tax) {
      //   taxedAmount =
      //     ((updatedTotalAmount - discountedAmount) * this.state.tax) / 100;
      // }
      // updatedFinalAmount = updatedTotalAmount - discountedAmount + taxedAmount;
      // updatedFinalAmount = Math.round(updatedFinalAmount);
      // if (this.state.pf && this.state.h) {
      //   updatedNetAmount = updatedFinalAmount + this.state.pf + this.state.h;
      // } else if (this.state.pf) {
      //   updatedNetAmount = updatedFinalAmount + this.state.pf;
      // } else if (this.state.h) {
      //   updatedNetAmount = updatedFinalAmount + this.state.h;
      // }
      // let finalDiscountedAmount = updatedTotalAmount - discountedAmount;

      if (purchases && purchases.length == 0) {
        this.setState({
          totalQuantity: 0,
          totalAmount: 0,
          totalBoxes: 0,
          myPurchaseData: purchases,
          finalAmount: 0,
          netAmount: 0,
          taxedAmount: 0,
          discountedAmount: 0,
          finalDiscountedAmount: 0,
          grFlag: false,
        });
      } else {
        this.setState({
          totalQuantity: updatedQuantity,
          totalAmount: updatedTotalAmount,
          totalBoxes: updatedBoxes,
          myPurchaseData: purchases,
          finalAmount,
          netAmount,
          taxedAmount,
          discountedAmount,

          finalDiscountedAmount,
        });
      }
    } else {
      let updatedQuantity = this.state.totalQuantity - toDelete.saleQty;
      let updatedBoxes = this.state.totalBoxes - toDelete.boxes;
      let updatedTotalAmount =
        this.state.totalAmount - toDelete.wsp * toDelete.saleQty;

      let total,
        discountedAmount,
        finalDiscountedAmount,
        taxedAmount,
        finalAmount,
        netAmount;

      if (this.state.grFlag) {
        total = updatedTotalAmount - this.state.grAmount;
      } else {
        total = updatedTotalAmount;
      }

      discountedAmount = (total * this.state.discount) / 100;
      finalDiscountedAmount = total - discountedAmount;
      taxedAmount = (finalDiscountedAmount * this.state.tax) / 100;
      finalAmount = finalDiscountedAmount + taxedAmount;
      netAmount =
        Number(finalAmount) + Number(this.state.h) + Number(this.state.pf);

      if (purchases && purchases.length == 0) {
        this.setState({
          totalQuantity: 0,
          totalAmount: 0,
          totalBoxes: 0,
          myPurchaseData: purchases,
          finalAmount: 0,
          netAmount: 0,
          taxedAmount: 0,
          discountedAmount: 0,
          finalDiscountedAmount: 0,
          grFlag: false,
        });
      } else {
        this.setState({
          totalQuantity: updatedQuantity,
          totalAmount: updatedTotalAmount,
          totalBoxes: updatedBoxes,
          myPurchaseData: purchases,
          finalAmount,
          netAmount,
          taxedAmount,
          discountedAmount,

          finalDiscountedAmount,
        });
      }
    }

    if (purchases && purchases.length == 0) {
      this.props.history.push("/dagaImpex/salesList", true);
    }
  };

  onRemoveBoxes = (obj) => {
    let index = this.checkBarcodeExists(obj.barcode);
    let temp = [];
    temp = this.state.myPurchaseData;

    tempObject = temp;
    let netTotalPurchaseValue = tempObject[index].netTotalPurchaseValue || 0;

    tempObject[index].boxes -= 1;
    tempObject[index].saleQty = obj.MOQ * temp[index].boxes;
    tempObject[index].netTotalPurchaseValue =
      netTotalPurchaseValue + tempObject[index].MOQ;

    temp = tempObject;
    // temp[index].MOQ -= obj.MOQ / temp[index].boxes;
    // temp[index].boxes -= 1;

    temp[index]["finalAmount"] = temp[index].saleQty * temp[index].wsp;
    temp[index].key = uuid();
    this.setState({ myPurchaseData: temp });

    let e = 0;
    let v = temp.length;
    let totalAmount = 0;
    let totalQuantity = 0;
    let totalBoxes = 0;
    while (e < v) {
      totalQuantity += temp[e].saleQty;
      totalAmount += temp[e].wsp * temp[e].saleQty;
      totalBoxes += temp[e].boxes;
      ++e;
    }

    let discountedAmount,
      finalDiscountedAmount,
      taxedAmount,
      finalAmount,
      netAmount;

    if (this.state.grFlag) {
      let total = totalAmount - this.state.grAmount;
      discountedAmount = (total * this.state.discount) / 100;
      finalDiscountedAmount = total - discountedAmount;
      taxedAmount = (finalDiscountedAmount * this.state.tax) / 100;
      finalAmount = finalDiscountedAmount + taxedAmount;
      netAmount =
        Number(finalAmount) + Number(this.state.h) + Number(this.state.pf);
    } else {
      let total = totalAmount;
      discountedAmount = (total * this.state.discount) / 100;
      finalDiscountedAmount = total - discountedAmount;
      taxedAmount = (finalDiscountedAmount * this.state.tax) / 100;
      finalAmount = finalDiscountedAmount + taxedAmount;
      netAmount =
        Number(finalAmount) + Number(this.state.h) + Number(this.state.pf);
    }
    // let discountedAmount = (totalAmount * this.state.discount) / 100;

    // let taxedAmount = ((totalAmount - discountedAmount) * this.state.tax) / 100;
    // let updatedFinalAmount = parseInt(totalAmount) + parseInt(taxedAmount) - parseInt(discountedAmount);
    // updatedFinalAmount = Math.round(updatedFinalAmount);
    // let updatedNetAmount = parseInt(updatedFinalAmount) + Number(this.state.pf) + Number(this.state.h);
    // if (this.state.grFlag) {
    //   if (updatedNetAmount > this.state.grAmount) {
    //     updatedNetAmount -= parseInt(this.state.grAmount) ? parseInt(this.state.grAmount) : 0;
    //   } else {
    //     updatedNetAmount -= parseInt(this.state.grAmount) ? parseInt(this.state.grAmount) : 0;
    //   }
    // }
    // let finalDiscountedAmount = parseInt(totalAmount) - parseInt(discountedAmount);
    this.setState({
      totalAmount,
      totalQuantity,
      totalBoxes,
      taxedAmount,
      discountedAmount,

      finalDiscountedAmount,
      finalAmount,
      netAmount,
    });
  };
  onAddBoxes = (obj) => {
    let index = this.checkBarcodeExists(obj.barcode);
    let temp = [];
    temp = this.state.myPurchaseData;

    let netMoq = temp[index].MOQ;
    let netTotalPurchaseValue =
      temp[index].netTotalPurchaseValue ||
      temp[index].netTotalPurchaseValue == 0
        ? temp[index].netTotalPurchaseValue
        : temp[index].totalPurchaseValue;
    // if (netMoq <= netTotalPurchaseValue || temp[index].saleQty < temp[index].totalPurchaseValue)
    console.log("netMoq", netMoq);

    if (netMoq <= netTotalPurchaseValue) {
      temp[index].boxes += 1;
      temp[index].saleQty = obj.MOQ * temp[index].boxes;
      temp[index].netTotalPurchaseValue = netTotalPurchaseValue - netMoq;
      console.log("netTotalPurchaseValue", temp[index].netTotalPurchaseValue);
    } else {
      message.warn("You can not add more then the purchase quantity");
    }

    // tempObject = temp
    // if (tempObject[index].netTotalPurchaseValue == undefined || null) {
    //   tempObject[index].netTotalPurchaseValue = tempObject[index].totalPurchaseValue
    // }
    // let netMoq = tempObject[index].MOQ
    // let netTotalPurchaseValue = tempObject[index].netTotalPurchaseValue || tempObject[index].netTotalPurchaseValue == 0 ? tempObject[index].netTotalPurchaseValue : tempObject[index].totalPurchaseValue - temp[index].MOQ

    // if (netMoq <= netTotalPurchaseValue) {
    //   tempObject[index].boxes += 1;
    //   tempObject[index].saleQty = obj.MOQ * tempObject[index].boxes;
    //   tempObject[index].netTotalPurchaseValue = netTotalPurchaseValue - netMoq
    // } else {
    //   message.warn("You can not add more then the purchase quantity")
    // }
    // temp = tempObject

    // if (temp[index].saleQty < temp[index].totalPurchaseValue) {
    //   temp[index].boxes += 1;
    //   temp[index].saleQty = obj.MOQ * temp[index].boxes;
    // } else {
    //   message.warn("You can not add more then the purchase quantity")
    // }

    // temp[index].MOQ += obj.MOQ / temp[index].boxes;
    // temp[index].boxes += 1;

    temp[index]["finalAmount"] = temp[index].saleQty * temp[index].wsp;
    temp[index].key = uuid();
    this.setState({ myPurchaseData: temp });
    let e = 0;
    let v = temp.length;
    let totalAmount = 0;
    let totalQuantity = 0;
    let totalBoxes = 0;
    while (e < v) {
      if (temp[e].saleQty <= temp[e].totalPurchaseValue) {
        totalQuantity += temp[e].saleQty;
        totalAmount += temp[e].wsp * temp[e].saleQty;
        totalBoxes += temp[e].boxes;
      } else {
        totalQuantity += temp[e].saleQty;
        totalAmount += temp[e].wsp * temp[e].saleQty;
        totalBoxes += temp[e].boxes;
      }
      e++;
    }

    let discountedAmount,
      finalDiscountedAmount,
      taxedAmount,
      finalAmount,
      netAmount;

    if (this.state.grFlag) {
      let total = totalAmount - this.state.grAmount;
      discountedAmount = (total * this.state.discount) / 100;
      finalDiscountedAmount = total - discountedAmount;
      taxedAmount = (finalDiscountedAmount * this.state.tax) / 100;
      finalAmount = finalDiscountedAmount + taxedAmount;
      netAmount =
        Number(finalAmount) + Number(this.state.h) + Number(this.state.pf);
    } else {
      let total = totalAmount;
      discountedAmount = (total * this.state.discount) / 100;
      finalDiscountedAmount = total - discountedAmount;
      taxedAmount = (finalDiscountedAmount * this.state.tax) / 100;
      finalAmount = finalDiscountedAmount + taxedAmount;
      netAmount =
        Number(finalAmount) + Number(this.state.h) + Number(this.state.pf);
    }
    // let discountedAmount = (totalAmount * this.state.discount) / 100;

    // let taxedAmount = ((totalAmount - discountedAmount) * this.state.tax) / 100;
    // let updatedFinalAmount = parseInt(totalAmount) + parseInt(taxedAmount) - parseInt(discountedAmount);
    // updatedFinalAmount = Math.round(updatedFinalAmount);
    // let updatedNetAmount = parseInt(updatedFinalAmount) + Number(this.state.pf) + Number(this.state.h);
    // if (this.state.grFlag) {
    //   if (updatedNetAmount > this.state.grAmount) {
    //     updatedNetAmount -= parseInt(this.state.grAmount) ? parseInt(this.state.grAmount) : 0;
    //   } else {
    //     updatedNetAmount -= parseInt(this.state.grAmount) ? parseInt(this.state.grAmount) : 0;
    //   }
    // }
    // let finalDiscountedAmount = parseInt(totalAmount) - parseInt(discountedAmount);
    this.setState({
      totalAmount,
      totalQuantity,
      totalBoxes,
      taxedAmount,
      discountedAmount,
      finalDiscountedAmount,
      finalAmount,
      netAmount,
    });
  };

  componentDidMount() {
    if (
      this.props.history.location.state == undefined ||
      this.props.history.location.state == null
    )
      return null;
    if (
      this.props.history.location.state != undefined ||
      this.props.history.location.state != null
    ) {
      // ============
      var currentDate = moment(
        this.props?.history?.location?.state?.saleObj?.[0]?.date
      );
      const dateObject = currentDate.format("DD/MM/YYYY");
      this.setState({ saleBillDate: currentDate._d, saleDate: dateObject });
      // ============
      this.setState({
        updateBill: this.props.history.location.state.updateBill,
        mySaleData: this.props.history.location.state.saleObj[1],
        salesOrderChangeStatus:
          this.props.history.location.state.salesOrderChangeStatus,
      });
      let saleObj = this.props.history.location.state.saleObj;
      if (saleObj) {
        this.setState({ update: true });
      }
      // this.setState({ tempSaleObj: saleObj });
      let data = saleObj[1];
      let billData = saleObj[0];
      let e = 0;
      let f = data?.length;
      let finalSaleData = [];
      this.setState({
        salesPersonName: data[0].salesPersonId.name,
        salesPersonId: data[0].salesPersonId._id,
      });
      while (e < f) {
        data[e].details.boxes = data[e].quantity / data[e].details.MOQ;
        data[e].details.saleQty = data[e].quantity;
        // data[e].details.MOQ = data[e].details.MOQ * data[e].details.boxes;
        data[e].details.finalAmount =
          data[e].details.saleQty * data[e].details.wsp;
        data[e].details.salesPersonId = data[e].salesPersonId._id;
        data[e].details.salesPersonName = data[e].salesPersonId.name;
        data[e].details.salesOrderId = data[e]._id;
        data[e].details.totalPurchaseValue = data[e].details.totalPurchaseValue;
        // data[e].details.netTotalPurchaseValue = data[e].details.totalPurchaseValue > 0 ? data[e].details.totalPurchaseValue : data[e].quantity
        finalSaleData.push(data[e].details);
        ++e;
      }
      let grAmount = undefined;
      let grFlag = false;
      if (billData.grAmountUsed > 0) {
        grAmount = billData.srAmount;
        grFlag = true;
      } else if (billData && billData.userId && billData.userId.grAmount > 0) {
        grAmount = billData.userId.grAmount;
      } else {
        grAmount = 0;
      }
      let totalAmt = billData.totalAmount - billData.grAmountUsed;
      let discountedAmount = (totalAmt * billData.discount) / 100;

      let taxedAmount = ((totalAmt - discountedAmount) * billData.tax) / 100;
      let finalAmount = totalAmt - discountedAmount + taxedAmount;
      finalAmount = Math.round(finalAmount);
      let finalDiscountedAmount = totalAmt - discountedAmount;
      let agentIdSalesOrder, agentSalesOrder;
      if (billData && billData.userId && billData.userId.agentId == null) {
        agentIdSalesOrder = null;
        agentSalesOrder = null;
      } else if (
        billData &&
        billData.userId &&
        billData.userId.agentId != null
      ) {
        agentIdSalesOrder = billData.userId.agentId._id;
        agentSalesOrder = billData.userId.agentId.name;
      } else {
        agentIdSalesOrder = null;
        agentSalesOrder = null;
      }
      this.setState({
        myPurchaseData: finalSaleData,
        MOQ: billData.MOQ,
        totalBoxes: billData.totalBoxes,
        totalQuantity: billData.totalQuantity,
        totalAmount: billData.totalAmount,
        discount: billData.discount,
        tax: billData.tax,
        pf: billData.pAndf,
        h: billData.labour,
        netAmount: billData.netAmount,
        grAmount,
        remark: billData.remark,
        invoiceNumber: billData.invoiceId,
        customerName: billData?.userId?.name,
        customerId: billData?.userId?._id,
        transport: billData?.userId?.transport,
        agentId: agentIdSalesOrder,
        agent: agentSalesOrder,
        // salesPersonId: data.salesPersonId,
        // salesPersonName: data.salesPersonName,
        orderedSale: true,
        discountedAmount,
        taxedAmount,
        finalAmount,
        grFlag,
        finalDiscountedAmount,
      });
      this.formRef.current.setFieldsValue({
        customerName: billData?.userId?.name,
      });
      // temp = [this.state.selectedData, ...this.state.myPurchaseData];

      return this.props.history.replace({ state: null });
    }
  }

  render() {
    let customerOptions = this.props.customers?.data?.map((customer) => {
      return (
        <Option
          name={[
            customer.transport,
            customer.agentId,
            customer.grAmount,
            customer,
          ]}
          value={customer._id}
        >
          {customer.name}
        </Option>
      );
    });
    let salesOptions = this.props.salesPerson.map((sales) => {
      return (
        <Option name={sales.name} value={sales._id}>
          {sales.name}
        </Option>
      );
    });

    let brandOptions = this.props?.brandBysearch?.data?.map((brand) => {
      return (
        <Option name={brand.name} value={brand._id}>
          {brand.name}
        </Option>
      );
    });

    var disabled = false;
    if (this.state.orderedSale) {
      disabled = true;
    }
    return (
      <div className="add-purchase ">
        {/* {this.state.sweetLoading === true ? (
          <Spin indicator={antIcon} />
        ) : (<> */}
        <div className="container-fluid">
          <Row className="title-row">
            <Col xs={22} sm={22} md={22} lg={22} xl={22}>
              <div className="page-header">Update Sales</div>
            </Col>
            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
              <Button
                type="primary"
                className="save-modal text-white"
                onClick={() => {
                  this.props.history.push("/dagaImpex/salesList", true);
                }}
              >
                <ArrowLeftOutlined /> Back
              </Button>
            </Col>
          </Row>

          <div className="card mt-4">
            <div className="card-body">
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form
                    ref={this.formRef}
                    name="add-Purchase"
                    className="add-Purchase"
                    onFinish={this.showDataOnList}
                    initialValues={{ remember: true }}
                  >
                    <Row gutter={[8, 0]}>
                      <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Tooltip title="Invoice No.">
                          <Form.Item>
                            <Input
                              disabled
                              placeholder="Enter Invoice Number"
                              name="invoiceNumber"
                              value={this.state.invoiceNumber}
                              onChange={this.onChange}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <div className="datePicker-hideCross">
                          <Tooltip title="Select Sale Date">
                            <Form.Item name="saleDate">
                              <DatePicker
                                onChange={this.onSaleDateChange}
                                placeholder="Select Sale Date"
                                value={moment(this.state.saleDate, dateFormat)}
                                format={dateFormat}
                              />
                              <DatePicker
                                style={{ display: "none" }}
                                value={moment(this.state.saleDate, dateFormat)}
                                placeholder="Select Sale Date"
                                onChange={this.onSaleDateChange}
                                format={dateFormat}
                              />
                            </Form.Item>
                          </Tooltip>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Tooltip title="Select Customer">
                          <Form.Item
                            name="customerName"
                            rules={[
                              {
                                required: true,
                                message: "Please Select Customer Name !",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Select Customer"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                              onChange={this.handleChangeCustomer}
                              onClick={() => this.getData("customer")}
                              onInputKeyDown={() => this.getData("customer")}
                            >
                              {customerOptions}
                            </Select>
                          </Form.Item>
                        </Tooltip>
                      </Col>

                      <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <Tooltip title="Agent">
                          <Form.Item>
                            <Input
                              id="agent"
                              placeholder="Agent"
                              name="agent"
                              value={this.state.agent}
                              onChange={this.onChange}
                              disabled
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                    </Row>

                    <Row gutter={[8, 0]}>
                      <Col xs={8} sm={8} md={8} lg={4} xl={4}>
                        <Tooltip title="Transport">
                          <Form.Item>
                            <Input
                              id="transport"
                              placeholder="Transport"
                              name="transport"
                              value={this.state.transport}
                              onChange={this.onChange}
                              disabled
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                      <Col xs={8} sm={8} md={8} lg={5} xl={5}>
                        <Tooltip title="Remark">
                          <Form.Item
                          //name="remark"
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: "Please Enter Remark !",
                          //   },
                          // ]}
                          >
                            <Input
                              placeholder="Enter Remark"
                              name="remark"
                              value={this.state.remark}
                              onChange={this.onChange}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                      <Col xs={8} sm={8} md={8} lg={5} xl={5}>
                        <Tooltip title="LR No.">
                          <Form.Item name="lrNumber">
                            <Input
                              placeholder="Enter LR Number"
                              name="lrNumber"
                              value={this.state.lrNumber}
                              onChange={this.onChange}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={5} xl={5}>
                        <Tooltip title="Select LR Date">
                          <Form.Item name="lrDate">
                            <DatePicker
                              onChange={this.onLRDateChange}
                              placeholder="Select LR Date"
                              // defaultValue={moment()}
                              format={dateFormat}
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={5} xl={5}>
                        <Tooltip title="Select Sales Person">
                          <Form.Item
                            name="salesPersonName"
                            rules={[
                              {
                                required: true,
                                message: "Please Select Sales Person !",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Select Sales Person"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                              onChange={this.handleChangeSalesPerson}
                              onClick={() => this.getData("sales")}
                              onInputKeyDown={() => this.getData("sales")}
                            >
                              {salesOptions}
                            </Select>
                          </Form.Item>
                        </Tooltip>
                      </Col>
                    </Row>

                    <Row gutter={[8, 0]}>
                      <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                        <Tooltip title="Barcode">
                          <Form.Item
                            //name="barcode"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Barcode Number !",
                              },
                            ]}
                          >
                            <BarcodeReader
                              onError={this.handleError}
                              onScan={this.handleScan}
                            />
                            <Input
                              placeholder="Enter Barcode Number"
                              name="barcode"
                              value={this.state.barcode}
                              onChange={this.onBarcodeChange}
                              required
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <Tooltip title="Design Number">
                          <Form.Item
                            //name="designNumber"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter Design Number !",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Enter Design Number"
                              name="designNumber"
                              value={this.state.designNumber}
                              onChange={this.onChange}
                              required
                            />
                          </Form.Item>
                        </Tooltip>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                        <Tooltip title="Select Brand">
                          <Form.Item
                            name="brand"
                            rules={[
                              {
                                required: true,
                                message: "Please Select Brand Name !",
                              },
                            ]}
                          >
                            <Select
                              // name="brand"
                              // id="brand"
                              showSearch
                              placeholder="Select Brand"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              filterSort={(optionA, optionB) =>
                                optionA.children
                                  .toLowerCase()
                                  .localeCompare(optionB.children.toLowerCase())
                              }
                              onChange={this.handleChangeBrand}
                              onClick={() => this.debouncedGetData("brand")}
                              onInputKeyDown={() =>
                                this.debouncedGetData("brand")
                              }
                            >
                              {brandOptions}
                            </Select>
                          </Form.Item>
                        </Tooltip>
                      </Col>
                      <LookupModalSales
                        purchases={this.props.purchases}
                        s={this.state.s}
                        selectedData={this.selectedData}
                      />
                      <Col xs={2} sm={2} md={2} lg={1} xl={1}>
                        <Tooltip title="Add ">
                          <Form.Item>
                            {this.props.invoiceId ? (
                              <>
                                <Button
                                  type="primary"
                                  // htmlType="submit"
                                  // onClick={this.showDataOnList}
                                  className="close-modal"
                                  disabled
                                >
                                  <PlusOutlined />
                                </Button>
                              </>
                            ) : (
                              <>
                                {
                                  <Button
                                    type="primary"
                                    htmlType="submit"
                                    // onClick={this.showDataOnList}
                                    className="close-modal"
                                  >
                                    <PlusOutlined />
                                  </Button>
                                  // this.props && this.props.purchase
                                  //   // && this.props.purchase.totalPurchaseValue <= 0
                                  //   ? <>
                                  //     <Button
                                  //       type="primary"
                                  //       onClick={() => message.warn("Out of Stock")}
                                  //       className="close-modal"
                                  //     >
                                  //       <PlusOutlined />
                                  //     </Button>
                                  //   </> : <>
                                  //     <Button
                                  //       type="primary"
                                  //       htmlType="submit"
                                  //       // onClick={this.showDataOnList}
                                  //       className="close-modal"
                                  //     >
                                  //       <PlusOutlined />
                                  //     </Button>
                                  //   </>
                                }
                              </>
                            )}
                          </Form.Item>
                        </Tooltip>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>

              {this.state.myPurchaseData &&
              this.state.myPurchaseData.length > 0 &&
              !this.state.sweetLoading ? (
                <>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <ShowSalesDetails
                        myPurchaseData={this.state.myPurchaseData}
                        deleteSale={this.deleteSale}
                        onAddBoxes={this.onAddBoxes}
                        onRemoveBoxes={this.onRemoveBoxes}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[8, 0]} className="mt-3">
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Tooltip title="Total Boxes">
                        <Form.Item>
                          <Input
                            placeholder="Total Boxes"
                            name="totalBoxes"
                            value={this.state.totalBoxes}
                            onChange={this.onChange}
                            disabled
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Tooltip title="Total Quantity">
                        <Form.Item>
                          <Input
                            placeholder="Total Quantity"
                            type="number"
                            name="totalQuantity"
                            value={parseInt(this.state.totalQuantity).toFixed()}
                            onChange={this.onChange}
                            disabled
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                      <Tooltip title="GR Amount">
                        <Form.Item>
                          <Input
                            placeholder="GR Amount"
                            name="grAmount"
                            value={this.state.grAmount}
                            onChange={this.onChange}
                            disabled
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                      <Tooltip title="Check">
                        <Form.Item>
                          <Checkbox
                            key={this.state.grFlag}
                            onChange={this.oncheckChange}
                            defaultChecked={this.state.grFlag}
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                      <Tooltip title="Total Amount">
                        <Form.Item>
                          <Input
                            type="number"
                            placeholder="Total Amount"
                            name="totalAmount"
                            value={this.state.totalAmount}
                            onChange={this.onChange}
                            disabled
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                  </Row>

                  <Row gutter={[8, 0]}>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                      <Tooltip title="Enter Discount">
                        <Form.Item>
                          <Input
                            type="number"
                            addonBefore="%"
                            placeholder="Enter Discount"
                            name="discount"
                            value={this.state.discount}
                            onChange={this.onChange}
                            onFocus={(e) => {
                              e.target.addEventListener(
                                "wheel",
                                this.preventScroll
                              );
                            }}
                            onBlur={(e) => {
                              e.target.removeEventListener(
                                "wheel",
                                this.preventScroll
                              );
                              this.handleBlur(e);
                            }}
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>

                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                      <Tooltip title="Discount">
                        <Form.Item>
                          <Input
                            placeholder="Discount"
                            name="discountedAmount"
                            value={this.state.discountedAmount}
                            onChange={this.onChange}
                            disabled
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                      <Tooltip title="Discounted Amount">
                        <Form.Item>
                          <Input
                            placeholder="Discounted Amount"
                            name="finalDiscountedAmount"
                            value={this.state.finalDiscountedAmount}
                            onChange={this.onChange}
                            disabled
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                  </Row>
                  <Row gutter={[8, 0]}>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                      <Tooltip title="Enter Tax">
                        <Form.Item>
                          <Input
                            type="number"
                            addonBefore="%"
                            placeholder="Enter Tax"
                            name="tax"
                            value={this.state.tax}
                            onChange={this.onChange}
                            min="0"
                            step="1"
                            onFocus={(e) => {
                              e.target.addEventListener(
                                "wheel",
                                this.preventScroll
                              );
                            }}
                            onBlur={(e) => {
                              e.target.removeEventListener(
                                "wheel",
                                this.preventScroll
                              );
                              this.handleBlur(e);
                            }}
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                      <Tooltip title="Tax">
                        <Form.Item>
                          <Input
                            placeholder="Tax"
                            name="taxedAmount"
                            value={this.state.taxedAmount}
                            onChange={this.onChange}
                            disabled
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                      <Tooltip title="Final Amount">
                        <Form.Item
                          //name="finalAmount"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter Final Amount !",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Final Amount"
                            name="finalAmount"
                            value={Number(this.state.finalAmount).toFixed()}
                            onChange={this.onChange}
                            disabled
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                  </Row>
                  <Row gutter={[8, 0]}>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                      <Tooltip title="P & F">
                        <Form.Item>
                          <Input
                            type="number"
                            placeholder="P & F"
                            name="pf"
                            value={this.state.pf}
                            onChange={this.onChange}
                            onFocus={(e) => {
                              e.target.addEventListener(
                                "wheel",
                                this.preventScroll
                              );
                            }}
                            onBlur={(e) => {
                              e.target.removeEventListener(
                                "wheel",
                                this.preventScroll
                              );
                              this.handleBlur(e);
                            }}
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                      <Tooltip title="Labour">
                        <Form.Item>
                          <Input
                            type="number"
                            placeholder="Labour"
                            name="h"
                            value={this.state.h}
                            onChange={this.onChange}
                            onFocus={(e) => {
                              e.target.addEventListener(
                                "wheel",
                                this.preventScroll
                              );
                            }}
                            onBlur={(e) => {
                              e.target.removeEventListener(
                                "wheel",
                                this.preventScroll
                              );
                              this.handleBlur(e);
                            }}
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>

                    {/* <Col xs={11} sm={11} md={11} lg={6} xl={6}>
                  <Tooltip title="GR Amount">
                    <Form.Item>
                      <Input
                        placeholder="GR Amount"
                        name="grAmount"
                        value={this.state.grAmount}
                        onChange={this.onChange}
                        disabled
                      />
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col xs={2} sm={2} md={2} lg={1} xl={1}>
                  <Tooltip title="Check">
                    <Form.Item>
                      <Checkbox
                        key={this.state.grFlag}
                        onChange={this.oncheckChange}
                        defaultChecked={this.state.grFlag}
                      />
                    </Form.Item>
                  </Tooltip>
                </Col> */}
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                      <Tooltip title="Net Amount">
                        <Form.Item>
                          <Input
                            placeholder="Net Amount"
                            name="netAmount"
                            value={Number(this.state.netAmount).toFixed()}
                            onChange={this.onChange}
                            disabled
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                  </Row>

                  {(() => {
                    if (this.state.updateBill) {
                      return (
                        <Row gutter={[8, 0]}>
                          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item>
                              {/**Prateek Logic to submit when all the fields are filled*/}
                              {(() => {
                                if (
                                  this.state.netAmount == "" ||
                                  this.state.customerId == "" ||
                                  this.state.netAmount <= 0
                                ) {
                                  return (
                                    <Button
                                      type="primary"
                                      className="close-modal-save-button"
                                      block
                                      disabled
                                    >
                                      Save Bill <StopOutlined />
                                    </Button>
                                  );
                                } else {
                                  let gr = 0;
                                  if (this.state.billedGR) {
                                    gr = this.state.grAmount;
                                  }
                                  return (
                                    <Button
                                      type="primary"
                                      className="close-modal-save-button"
                                      block
                                      onClick={() => this.createSales(false)}
                                    >
                                      Save Bill
                                    </Button>
                                  );
                                }
                              })()}
                            </Form.Item>
                          </Col>
                          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item>
                              {/**Prateek Logic to submit when all the fields are filled*/}
                              {(() => {
                                if (this.props.invoiceId) {
                                  let gr = 0;
                                  if (this.state.billedGR) {
                                    gr = this.state.grAmount;
                                  }

                                  return (
                                    <ViewGenerateBill
                                      invoiceId={this.props.invoiceId}
                                    />
                                    // <GenerateBill
                                    //   myPurchaseData={this.state.myPurchaseData}
                                    //   customerName={this.state.customerName}
                                    //   remark={this.state.remark}
                                    //   totalQuantity={this.state.totalQuantity}
                                    //   discount={this.state.discount}
                                    //   tax={this.state.tax}
                                    //   pAndf={this.state.pf}
                                    //   grAmount={this.state.grFlag ? this.state.grAmount : 0}
                                    //   saleDate={this.state.saleBillDate}
                                    //   netAmount={this.state.netAmount}
                                    //   finalAmount={this.state.finalAmount}
                                    //   discountedAmount={this.state.discountedAmount}
                                    //   taxedAmount={this.state.taxedAmount}
                                    //   invoiceId={this.props.invoiceId}
                                    //   createSales={this.createSales}
                                    //   clearState={this.clearSatate}
                                    //   totalAmount = {this.state.totalAmount}
                                    //   agentName = {this.props.agentName}
                                    // />
                                  );
                                } else {
                                  return (
                                    <Button
                                      type="primary"
                                      className="close-modal"
                                      block
                                      disabled
                                    >
                                      Generate Bill <StopOutlined />
                                    </Button>
                                  );
                                }
                              })()}
                            </Form.Item>
                          </Col>
                        </Row>
                      );
                    } else {
                      return (
                        <Row gutter={[8, 0]}>
                          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item>
                              {/**Prateek Logic to submit when all the fields are filled*/}
                              {(() => {
                                if (
                                  this.state.netAmount == "" ||
                                  this.state.customerId == "" ||
                                  this.state.netAmount <= 0
                                ) {
                                  return (
                                    <Button
                                      type="primary"
                                      className="save-modal text-white"
                                      block
                                      disabled
                                    >
                                      Create Sales Order <StopOutlined />
                                    </Button>
                                  );
                                } else {
                                  if (this.state.update) {
                                    return (
                                      <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="save-modal"
                                        block
                                        onClick={() => this.createSales(true)}
                                      >
                                        Update Sales Order
                                      </Button>
                                    );
                                  } else {
                                    return (
                                      <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="save-modal"
                                        block
                                        onClick={() => this.createSales(true)}
                                      >
                                        Create Sales Order
                                      </Button>
                                    );
                                  }
                                }
                              })()}
                            </Form.Item>
                          </Col>
                          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item>
                              {/**Prateek Logic to submit when all the fields are filled*/}
                              {(() => {
                                if (
                                  this.state.netAmount == "" ||
                                  this.state.customerId == "" ||
                                  this.state.netAmount <= 0
                                ) {
                                  return (
                                    <Button
                                      type="primary"
                                      className="close-modal-save-button"
                                      block
                                      disabled
                                      // onClick={() => this.createSales()}
                                    >
                                      Save Bill <StopOutlined />
                                    </Button>
                                  );
                                } else {
                                  let gr = 0;
                                  if (this.state.billedGR) {
                                    gr = this.state.grAmount;
                                  }
                                  return (
                                    <Button
                                      type="primary"
                                      className="close-modal-save-button"
                                      block
                                      onClick={() => this.createSales(false)}
                                    >
                                      Save Bill
                                    </Button>
                                  );
                                }
                              })()}
                            </Form.Item>
                          </Col>

                          <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item>
                              {/**Prateek Logic to submit when all the fields are filled*/}

                              {(() => {
                                if (this.props.invoiceId) {
                                  let gr = 0;
                                  if (this.state.billedGR) {
                                    gr = this.state.grAmount;
                                  }
                                  return (
                                    <ViewGenerateBill
                                      invoiceId={this.props.invoiceId}
                                    />

                                    // <GenerateBill
                                    //   myPurchaseData={this.state.myPurchaseData}
                                    //   customerName={this.state.customerName}
                                    //   remark={this.state.remark}
                                    //   totalQuantity={this.state.totalQuantity}
                                    //   discount={this.state.discount}
                                    //   tax={this.state.tax}
                                    //   pAndf={this.state.pf}
                                    //   grAmount={this.state.grFlag ? this.state.grAmount : 0}
                                    //   netAmount={this.state.netAmount}
                                    //   finalAmount={this.state.finalAmount}
                                    //   discountedAmount={this.state.discountedAmount}
                                    //   saleDate={this.state.saleBillDate}
                                    //   taxedAmount={this.state.taxedAmount}
                                    //   createSales={this.createSales}
                                    //   invoiceId={this.props.invoiceId}
                                    //   clearState={this.clearSatate}
                                    //   totalAmount = {this.state.totalAmount}
                                    //   agentName = {this.props.agentName}

                                    // />
                                  );
                                } else {
                                  return (
                                    <Button
                                      type="primary"
                                      className="close-modal"
                                      block
                                      disabled
                                    >
                                      Generate Bill <StopOutlined />
                                    </Button>
                                  );
                                }
                              })()}
                            </Form.Item>
                          </Col>
                        </Row>
                      );
                    }
                  })()}
                </>
              ) : (
                <>
                  <div className="d-flex justify-content-center align-items-center">
                    <img src={gifLoader} width={"300px"} height={"300px"} />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {/* </>)} */}
      </div>
    );
  }
}

UpdateSales.propTypes = {
  getCustomers: PropTypes.func.isRequired,
  //addPurchase: PropTypes.func.isRequired,
  getBrandBySearch: PropTypes.func.isRequired,
  getLookupData: PropTypes.func.isRequired,
  getPurchaseByBarcodeSale: PropTypes.func.isRequired,
  updateSales: PropTypes.func.isRequired,
  deleteSales: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  getActiveandInactiveCustomer: PropTypes.object.isRequired,
  checkSalesitemisdeleted: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  invoiceId: state.saleData.sale.invoiceId,
  agentName: state.saleData.sale.agentName,
  customers: state.customerData.activeandInactiveCustomer,
  salesPerson: state.customerData.salesPerson,
  brands: state.brandsData.brands,
  brandBysearch: state.brandsData.brandBysearch,
  purchases: state.purchaseData.purchases,
  purchase: state.purchaseData.purchase,
});

export default connect(mapStateToProps, {
  getCustomers,
  getBrandBySearch,
  getLookupData,
  getPurchaseByBarcodeSale,
  updateSales,
  deleteSales,
  getActiveandInactiveCustomer,
  checkSalesitemisdeleted,
  //addPurchase,
})(UpdateSales);