import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAgent,
  getAgentsList,
  agentWhatsAppNotification,
} from "../../../../redux/actions/userAction";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Table,
  Tooltip,
  Popconfirm,
  Switch,
  Input,
  Button,
} from "antd";
import Column from "antd/lib/table/Column";
import AddAgent from "./AddAgent";
import UpdateAgent from "./UpdateAgent";
import InfoModal from "./InfoModal";

const AgentList = () => {
  const dispatch = useDispatch();
  const agents = useSelector((state) => state.userData.agentsList);

  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const getData = async (page = 1) => {
    await dispatch(getAgentsList(page));
  };

  useEffect(() => {
    getData(currentPage); 
  }, []);

  const agentDelete = async (_id) => {
    await dispatch(deleteAgent(_id)).then((respo) => {
      if (respo && respo.status === 200) {
        setCurrentPage(1); 
        getData(1);
      }
    });
  };

  const handleAgentWiseWhatsAppNotification = async (e, record) => {
    let obj = {
      _id: record._id,
      isMsgSent: e,
    };

    await dispatch(agentWhatsAppNotification(obj)).then((response) => {
      if (response && response.status === 200) {
        setCurrentPage(1); // Reset to page 1 after update
        getData(1);
      }
    });
  };

  // Reusable search method for column search and reset
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? <span>{text}</span> : text,
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  return (
    <>
      <div className="subCategory-dashboard">
        <div className="container-fluid category-list">
          <Row className="title-row">
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <div className="page-header">Agent List</div>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12} align="end">
              <AddAgent />
            </Col>
          </Row>
          <div className="card mt-4 final-purchases-list">
            <div className="card-body">
              <Table
                dataSource={agents.data || []}
                bordered={true}
                size="middle"
                pagination={{
                  pageSize: 25,
                  total: agents?.count,
                  current: currentPage, // Bind the current page state
                  onChange: async (page) => {
                    setCurrentPage(page); // Update current page when pagination changes
                    await dispatch(getAgentsList(page));
                  },
                }}
                scroll={{ y: 240 }}
              >
                <Column
                  title="SRN"
                  key="index"
                  render={(value, item, index) => index + 1}
                  width={60}
                />
                <Column
                  title="Name"
                  dataIndex="name"
                  key="name"
                  width={140}
                  {...getColumnSearchProps("name")}
                />
                <Column
                  title="Mobile Number"
                  dataIndex="mobileNumber"
                  key="mobileNumber"
                  width={140}
                  {...getColumnSearchProps("mobileNumber")}
                />
                <Column
                  title="Email"
                  dataIndex="email"
                  key="email"
                  width={140}
                  {...getColumnSearchProps("email")}
                />
                <Column
                  title="Action"
                  key="action"
                  width={150}
                  render={(text, record) => (
                    <div>
                      <Row gutter={[8, 0]}>
                      <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                          <InfoModal data={record?.userCount}/>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                          <UpdateAgent agentData={record} currentPage={currentPage}/>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                          <Popconfirm
                            title="Are you sure to delete this Agent?"
                            onConfirm={() => agentDelete(record._id)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <div className="btn">
                              <Tooltip title="Delete Agent">
                                <DeleteOutlined />
                              </Tooltip>
                            </div>
                          </Popconfirm>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                          <div className="btn">
                            <Tooltip title="WhatsApp Notification">
                              <Switch
                                size="small"
                                defaultChecked={
                                  text &&
                                  text.isMsgSent &&
                                  text?.isMsgSent === true
                                    ? true
                                    : false
                                }
                                onChange={(e) =>
                                  handleAgentWiseWhatsAppNotification(e, record)
                                }
                              />
                            </Tooltip>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
                />
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentList;