import { EyeOutlined } from '@ant-design/icons';
import { Tooltip, Modal } from 'antd';
import React, { useState } from 'react';

const ViewSalePDF = ({ pdfUrl }) => {
    const [open, setOpen] = useState(false);

    const handleModalOpen = () => {
        console.log("check it pdf", pdfUrl);
        setOpen(true);
    };

    const handleModalCancel = () => {
        setOpen(false);
    };

    return (
        <>
            <button
                className="btn"
                type="button"
                disabled={!pdfUrl}
                onClick={handleModalOpen}
            >
                <Tooltip title="View Bill">
                    <EyeOutlined />
                </Tooltip>
            </button>
            
            <Modal
                title="Sale Bill PDF"
                open={open}
                onCancel={handleModalCancel}
                footer={null}
                width={800}
            >
                {pdfUrl ? (
                    <iframe
                        src={pdfUrl}
                        title="Sale Bill PDF"
                        width="100%"
                        height="600px"
                    />
                ) : (
                    <p>No PDF available.</p>
                )}
            </Modal>
        </>
    );
};

export default ViewSalePDF;