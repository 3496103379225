import { Button, Modal, Form, Row, Col, Tooltip, Input } from "antd";
import React, { useState } from "react";
import { addAgent, getAgents, getAgentsList } from "../../../../redux/actions/userAction";
import { useDispatch } from "react-redux";

const AddAgent = () => {
  const [visible, setVisble] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    name: "",
    mobileNumber: "",
    email: "",
  });
  const dispatch = useDispatch();

  const showModal = () => {
    setVisble(true);
  };

  const onNameChange = (event) => {
    const name = event.target.value;
    setData((prevData) => ({
      ...prevData,
      name: name,
    }));
  };

  const onMobileChange = (event) => {
    const mobileNumber = event.target.value;
    setData((prevData) => ({
      ...prevData,
      mobileNumber: mobileNumber,
    }));
  };

  const onEmailChange = (event) => {
    const email = event.target.value;
    setData((prevData) => ({
      ...prevData,
      email: email,
    }));
  };

  const onSubmit = async () => {
    setLoading(false);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    const agentObj = {
      name: data.name,
      mobileNumber: data.mobileNumber,
      email: data.email,
    };
    await dispatch(addAgent(agentObj)).then(
        dispatch(getAgentsList(1))
    );
    handleModalCancel();
  };

  const handleModalCancel = () => {
    setVisble(false);
    setData({ name: "", email: "", mobileNumber: "" });
  };

  return (
    <>
      <Button
        className="btn-admin btn-subCategory"
        type="primary"
        onClick={showModal}
      >
        Add Agent
      </Button>

      <Modal
        open={visible}
        onCancel={handleModalCancel}
        title="Add Agent"
        style={{ top: 20 }}
        width={800}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        destroyOnClose={true}
      >
        <Form
          name="add-Agent"
          className="add-Agent"
          onFinish={onSubmit}
          initialValues={{
            remember: true,
          }}
        >
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label="Agent Name"
                name="name"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Agent Name !",
                  },
                ]}
              >
                <Input
                  placeholder="Agent Name"
                  name="name"
                  value={data.name}
                  onChange={onNameChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
                label="Mobile Number"
                name="mobileNumber"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                labelAlign="left"
                rules={[
                  {
                    required: false,
                  },
                  {
                    validator: (_, value) => {
                      if (value && value.toString().length != 10) {
                        return Promise.reject("Mobile must be 10-digits!");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  placeholder="Mobile Number"
                  type="number"
                  value={data.mobileNumber}
                  onChange={onMobileChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label="Email"
                name="email"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                labelAlign="left"
              >
                <Input
                type="email"
                  placeholder="Email"
                  name="email"
                  value={data.email}
                  onChange={onEmailChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="ok-modal"
                  loading={loading}
                  block
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default AddAgent;
