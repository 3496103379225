import React, { useState, useRef } from "react";
import { Row, Modal, Form, Button, Input, Col, Select } from "antd";
import {
  getStockByFilter,
  getStockSearchforEcom,
} from "../../../redux/actions/purchaseAction";
import { useDispatch, useSelector } from "react-redux";
import { getBrandBySearch } from "../../../redux/actions/brandAction";
const { Option } = Select;
const StockReportFilter = () => {
  const formRef = useRef();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [designNo, setDesignNo] = useState(""); // Initialize with an empty string
  const [brandName, setBrandName] = useState(""); // Initialize with an empty string
  const [style, setStyle] = useState(""); // Initialize with an empty string
  const [season, setseason] = useState(""); // Initialize with an empty string
  const [size, setSize] = useState(""); // Initialize with an empty string
  const dispatch = useDispatch();
  const brandData = useSelector((state) => state.brandsData.brandBysearch);
  const allDropdownData = useSelector(
    (state) => state.purchaseData.stockSalesApp
  );

  const showModal = () => {
    setVisible(true);
  };
  const handleModalCancel = () => {
    setBrandName("");
    setDesignNo("");
    setStyle("");
    setseason("");
    setSize("");
    formRef.current.resetFields([
      "Style",
      "designNo",
      "Size",
      "Season",
      "brand",
    ]);
    setVisible(false);
  };
  const onSubmit = async () => {
    setLoading(true);
    let obj = {
      brandId: [brandName],
      styleId: [style],
      seasonId: [season],
      size: [size],
      designNumber: [designNo],
    };
    await dispatch(getStockByFilter(obj)).finally(() => {
      setLoading(false);
      handleModalCancel()
    });
  };
  const onChange = async (name, value) => {
    if (name === "brand") {
      setDesignNo("");
      setStyle("");
      setseason("");
      setSize("");
      formRef.current.resetFields(["Style", "designNo", "Size", "Season"]);
      let obj = {
        Brand: [value],
        style: [],
        season: [],
        size: [],
        designNumber: [],
      };
      await dispatch(getStockSearchforEcom(obj));
      setBrandName(value);
    }
    if (name === "Style") {
      let obj = {
        Brand: [brandName],
        style: [value],
        season: [],
        size: [],
        designNumber: [],
      };
      await dispatch(getStockSearchforEcom(obj));
      setStyle(value);
    }
    if (name === "Season") {
      let obj = {
        Brand: [brandName],
        style: [style],
        season: [value],
        size: [],
        designNumber: [],
      };
      await dispatch(getStockSearchforEcom(obj));
      setseason(value);
    }
    if (name === "Size") {
      let obj = {
        Brand: [brandName],
        style: [style],
        season: [season],
        size: [value],
        designNumber: [],
      };
      await dispatch(getStockSearchforEcom(obj));
      setSize(value);
    }
    if (name === "designNo") {
      let obj = {
        Brand: [brandName],
        style: [style],
        season: [season],
        size: [size],
        designNumber: [value],
      };
      await dispatch(getStockSearchforEcom(obj));
      setDesignNo(value);
    }
  };

  const getBrandData = () => {
    dispatch(getBrandBySearch());
  };

  const brandOptions = brandData?.data?.map((brand) => {
    return (
      <Option name={brand.name} value={brand._id}>
        {brand.name}
      </Option>
    );
  });

  const styleOptions = allDropdownData?.uniqueStyles?.map((style) => {
    return (
      <Option name={style.style} value={style.styleId}>
        {style.style}
      </Option>
    );
  });

  const seasonOptions = allDropdownData?.uniqueSeasons?.map((season) => {
    return (
      <Option name={season.season} value={season.seasonId}>
        {season.season}
      </Option>
    );
  });

  const sizeOptions = allDropdownData?.uniqueSizes?.map((size) => {
    return (
      <Option name={size.size} value={size.size}>
        {size.size}
      </Option>
    );
  });

  const designNoOptions = allDropdownData?.uniqueDesignNumbers?.map(
    (designNumber) => {
      return (
        <Option
          name={designNumber.designNumber}
          value={designNumber.designNumber}
        >
          {designNumber.designNumber}
        </Option>
      );
    }
  );
  return (
    <>
      <Button
        className="btn-admin btn-subCategory"
        type="primary"
        onClick={() => showModal()}
      >
        Search
      </Button>
      <Row>
        <Col span={24} align="center">
          <div className="add-button">
            <Modal
              visible={visible}
              onCancel={() => handleModalCancel()}
              title="Search"
              style={{ top: 20 }}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              destroyOnClose={true}
            >
              <Form
                ref={formRef}
                name="design and Brand"
                className="add-brand"
                onFinish={onSubmit}
                initialValues={{
                  remember: true,
                }}
              >
                <Form.Item
                  name="brand"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Brand Name !",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select Brand"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    onChange={(e) => onChange("brand", e)}
                    onClick={() => getBrandData("brand")}
                    onInputKeyDown={() => getBrandData("brand")}
                  >
                    {brandOptions}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="Style"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Style!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    disabled={style ? true : false}
                    placeholder="Select Style"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    onChange={(e) => onChange("Style", e)}
                    // onClick={() => getBrandData("brand")}
                    // onInputKeyDown={() => getBrandData("brand")}
                  >
                    {styleOptions}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="Season"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Season!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    disabled={season ? true : false}
                    placeholder="Select Season"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    onChange={(e) => onChange("Season", e)}
                    // onClick={() => getBrandData("Season")}
                    // onInputKeyDown={() => getBrandData("Season")}
                  >
                    {seasonOptions}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="Size"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Size!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    disabled={size ? true : false}
                    placeholder="Select Size"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    onChange={(e) => onChange("Size", e)}
                  >
                    {sizeOptions}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="designNo"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Design Number !",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    disabled={designNo ? true : false}
                    placeholder="Select Design No"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    onChange={(e) => onChange("designNo", e)}
                  >
                    {designNoOptions}
                  </Select>
                </Form.Item>
                <Form.Item className="float-right">
                  <Button
                    className="close-modal mr-3"
                    onClick={() => handleModalCancel()}
                  >
                    Close
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="ok-modal"
                    loading={loading}
                  >
                    Search
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default StockReportFilter;
