import React, { useEffect, useState } from "react";
import { Row, Col, Popconfirm, Table, Checkbox, message, Button } from "antd";
import UploadBannerModal from "./UploadBannerModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getBannerList,
  deleteEcomBanner,
  sendBannerImages,
  getLatestBannerList,
} from "../../../redux/actions/purchaseAction";
import { DeleteOutlined } from "@ant-design/icons";
import PreviewBanner from "./PreviewBanner";

const { Column } = Table;
const UploaBanner = () => {
  const [imageData, setImageData] = useState([]);
  const [disable, setDisable] = useState(true);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const bannersList = useSelector((state) => state.purchaseData.bannerList);
  const latestBannerList = useSelector((state) => state.purchaseData.latestBannerList);

  const getBannerData = async () => {
    await dispatch(getBannerList());
  };

  useEffect(() => {
    getBannerData();
  }, []);

  const getLatestBannerData = async () => {
    await dispatch(getLatestBannerList());
  };

  useEffect(() => {
    getLatestBannerData();
  }, []);

  useEffect(() => {
    if (
      bannersList &&
      bannersList.data &&
      bannersList?.data?.oldImages?.length > 0
    ) {
      let newList = bannersList?.data?.oldImages.map((obj) => {
        return { imageUrl: obj, status: false };
      });
      setImageData(newList);
    } else {
      setImageData([]);
    }
  }, [bannersList]);

  const deleteImage = async (data) => {
    await dispatch(
      deleteEcomBanner({
        bannerImages: data.imageUrl,
        _id: bannersList?.data?._id,
      })
    ).then((respo) => {
      if (respo && respo.status == 200) {
        getBannerData();
      }
    });
  };

  const handleCheckboxChange = (e, value) => {
    const newData = imageData.map((item) => {
      if (item.imageUrl == value.imageUrl) {
        let count = 0;
        for (let index = 0; index < imageData.length; index++) {
          if (imageData[index].status) {
            count++;
          }
        }
        if (count == 4 && !item.status) {
          message.warn("You can select only 4 images");
          return item;
        } else {
          if (count == 3 && !item.status) {
            setDisable(false);
          } else if (count == 4 && item.status) {
            setDisable(true);
          }
          return {
            ...item,
            status: !item.status,
          };
        }
      } else {
        return item;
      }
    });
    setImageData(newData);
  };

  const handleSave = async () => {
    setLoader(true);
    let newImages = [];
    imageData.flatMap((image) =>
      image.status ? newImages.push(image.imageUrl) : []
    );
    await dispatch(sendBannerImages({ bannerImages: newImages })).then(
      (res) => {
        if (res?.status == 200) {
          message.success(res?.data?.message);
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          message.error("Something Went Wrong!");
        }
      }
    );
    setLoader(false);
  };

  return (
    <>
      <div className="subCategory-dashboard">
        <div className="container-fluid category-list">
          <Row className="title-row">
            <Col
              xs={18}
              sm={18}
              md={18}
              lg={18}
              xl={18}
            >
              <div className="page-header">Upload Banner</div>
            </Col>
            <Col
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              align="end"
            >
              <PreviewBanner images={latestBannerList?.data} />
            </Col>
            <Col
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              align="end"
            >
              <UploadBannerModal />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Table
                className="cartTable"
                dataSource={imageData || []}
                pagination={false}
                rowKey="id"
              >
                <Column
                  title="SRN"
                  key="index"
                  render={(value, item, index) => index + 1}
                  width={60}
                />
                <Column
                  title="Image"
                  dataIndex="imageUrl"
                  key="imageUrl"
                  width={200}
                  render={(image, record) => (
                    <img
                      className="img-fluid cartProductImage"
                      src={record.imageUrl}
                      alt={"ll"}
                    />
                  )}
                />
                <Column
                  title="Select"
                  key="status"
                  render={(value) => (
                    <Checkbox
                      checked={value.status}
                      onChange={(e) => handleCheckboxChange(e, value)}
                    />
                  )}
                  width={60}
                />
                <Column
                  title="Delete"
                  key="index"
                  render={(value, record) => (
                    <Popconfirm
                      title="Are you sure to Delete This Image?"
                      onConfirm={() => deleteImage(record)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <DeleteOutlined />
                    </Popconfirm>
                  )}
                  width={60}
                />
              </Table>
            </Col>
          </Row>
          {!disable && (
            <Row className="mt-4">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Button
                  loading={loader}
                  disabled={disable}
                  type="primary"
                  htmlType="submit"
                  onClick={handleSave}
                  className="ok-modal"
                  block
                >
                  Save
                </Button>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </>
  );
};

export default UploaBanner;