import React, { Component } from "react";
import {
  Table,
  Popconfirm,
  Tooltip,
  Pagination,
  Button,
  Space,
  Input,
} from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import UpdateStyle from "./UpdateStyle";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { deleteStyle, getAllStyles } from "../../../../redux/actions/styleAction";
import TopBarProgress from "react-topbar-progress-indicator";
import Highlighter from "react-highlight-words";
const { Column } = Table;
class StyleList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      options: [],
    };
  }
  componentDidMount() {
    this.props.getAllStyles();
  }
  onDeleteClick = (id) => {
    this.props.deleteStyle(id);
    // this.props.deleteCategory(id);
  };
  onChangePage = () => {
    // this.setState({ page: page });
    //this.props.getCategories(page);
  };

  
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };
  render() {
    return (
      <div className="card mt-4">
        {this.props.progressBarStatus && <TopBarProgress />}
        <div className="card-body">
          <Table
            dataSource={this.props.styles}
            bordered="true"
            size="middle"
            pagination={false}
          >
             <Column
              title="Category Name"
              dataIndex="categoryId"
              key="categoryId"
              render={(record, Item, value)=> Item?.categoryId?.name}
            />
            <Column
              title="Style Name"
              dataIndex="name"
              key="name"
              {...this.getColumnSearchProps("name")}
            />

            <Column
              title="Action"
              key="action"
              render={(text, record) => (
                <div>
                  <a>
                    <UpdateStyle styles={text} />
                  </a>

                  <Popconfirm
                    title="Are you sure to delete this Style?"
                    onConfirm={() => this.onDeleteClick(text._id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <div className="btn">
                      <Tooltip title="Delete Style">
                        <DeleteOutlined />
                      </Tooltip>
                    </div>
                  </Popconfirm>
                </div>
              )}
            />
          </Table>{" "}
          {/**<div className="float-right mt-3">
            <Pagination
              defaultCurrent
              defaultPageSize
              total="2"
              //   total={totalPages}
              onChange={this.onChangePage}
              showLessItems="true"
            />
          </div> */}
        </div>
      </div>
    );
  }
}
StyleList.propTypes = {
  getAllStyles: PropTypes.func.isRequired,
  deleteStyle: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => (
  {
    errors: state.errors,
    styles: state.styleData.styles,
    progressBarStatus: state.progressBarReducer.showProgressBar
  }
);

export default connect(mapStateToProps, { getAllStyles, deleteStyle })(StyleList);
