import React from "react";
import { NavLink } from "react-router-dom";
import { Breadcrumbs } from "react-breadcrumbs-dynamic";
import { CaretRightOutlined } from "@ant-design/icons";

const Breadcrumb = () => {
  return (
    <div className="breadcrumb-area py-4 bg-skyBlue">
      <div className="container p-0">
        <div className="breadcrumb-content mx-2">
          <Breadcrumbs
            separator={<CaretRightOutlined color="#343538" className="mb-1" style={{color: '#343538', fontSize: '20px'}} />}
            item={NavLink}
            finalItem={"span"}
            className="d-flex"
          />
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;