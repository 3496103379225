import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, Select, Button, Tooltip, Row, Tag } from "antd";
import { CloseOutlined, SwapOutlined } from "@ant-design/icons";
import { changeAgent, getAgents, getUserByAgent, convertCategeory, convertStyle } from "../../../redux/actions/userAction";
import { getCategories } from "../../../redux/actions/categoryAction";
import { getAllStyles, getStyles } from "../../../redux/actions/styleAction";

const { Option } = Select;


const ChangeAll = () => {
  const [form] = Form.useForm();
  const [selectType, setSelectType] = useState([
    "Agent",
    "Category", 
    "Style"
  ]);
  const agentData = useSelector((state) => state.userData.agents);
  const categoryData = useSelector((state) => state.categoryData.categories);
  const StyleData = useSelector((state) => state.styleData.styles)
  const [mockData, setMockData] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [prevCatStyleList, setPrevCatStyleList] = useState([])
  const [changeCatStyleList, setChangeCatStyleList] = useState([])

  const [data, setData] = useState({ previousAgent: "", ChangeAgent: "", previousAgentName: "", ChangeAgentName: "", todo: [], ChangeCategory: "", previousCategory: "", selectedTpe: "", previousStyle: "", changeStyle: "", PrevCatName: "", ChangeCatName: "" })
  const dispatch = useDispatch();

  const handleTransfer = (name) => {
    if (name === "Left") {
      const updatedMockData = [...mockData, ...targetKeys];
      setMockData(updatedMockData);
      setTargetKeys([]);
    }

    if (name === "Right") {
      const updatedTargetData = [...mockData, ...targetKeys];
      setMockData([]);
      setTargetKeys(updatedTargetData);
    }
  }

  const handleCategoryChange = async (name, value) => {
    setData((prevData) => ({ ...prevData, [name]: value }));

    if (name === "previousCategory" && value) {
      const catName = categoryData.find((text, index) => text?._id == value)
      console.log("catName", catName);

      setData((prevData) => ({ ...prevData, PrevCatName: catName?.name }));
      let response = await dispatch(getStyles(value))
      if (response && response.data) {
        setPrevCatStyleList(response.data)
      }
    }
    if (name === "ChangeCategory" && value) {
      const catName = categoryData.find((text, index) => text?._id == value)
      setData((prevData) => ({ ...prevData, ChangeCatName: catName?.name }));
      let response = await dispatch(getStyles(value))
      if (response && response.data) {
        setChangeCatStyleList(response.data)
      }
    }
  }

  const handleAgentChange = async (name, value) => {
    const agentName = agentData.find((text, index) => text?._id == value)
    setData((prevData) => ({ ...prevData, [name]: value }));

    if (name === "previousAgent" && value) {

      setData((prevData) => ({ ...prevData, previousAgentName: agentName?.name }));
      let obj = { agentId: value };

      let response = await dispatch(getUserByAgent(obj));

      if (response && response.data && response.data.userlist) {

        setTargetKeys(response.data.userlist)
      }
    }
    if (name === "ChangeAgent" && value) {

      setData((prevData) => ({ ...prevData, ChangeAgentName: agentName?.name }));
      let obj = { agentId: value };

      let response = await dispatch(getUserByAgent(obj));

      if (response && response.data && response.data.userlist) {

        setMockData(response.data.userlist)
      }
    }
  };

  const onDragStart = (event, todo, source) => {
    event.dataTransfer.setData("text/plain", JSON.stringify({ todo, source }));
  };

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const onDrop = (event, target) => {
    event.preventDefault();
    const droppedData = JSON.parse(event.dataTransfer.getData("text/plain"));
    const { todo, source } = droppedData;

    if (source === "targetKeys") {
      const updatedMockData = [...mockData, todo];
      setMockData(updatedMockData);
      setTargetKeys(targetKeys.filter(task => task._id !== todo._id));
    } else if (source === "mockData") {
      const updatedTargetKeys = [...targetKeys, todo];
      setTargetKeys(updatedTargetKeys);
      setMockData(mockData.filter(task => task._id !== todo._id));
    }
  };

  const handleSubmit = () => {

    if (data.selectedTpe === "Agent") {
      const previousAgentData = mockData.map((item) => {
        return {
          ...item,
          agentId: data.ChangeAgent
        };
      });
      const changeAgentData = targetKeys.map((item) => {
        return {
          ...item,
          agentId: data.previousAgent
        };
      });

      const obj = {
        // mainAgentId: form.getFieldValue("previousAgent"),
        // toChangeAgentId: form.getFieldValue("ChangeAgent"),
        userList: [...previousAgentData, ...changeAgentData]
      };
      if (data.selectedTpe === "Agent" && obj?.userList?.length > 0) {
        dispatch(changeAgent(obj));
        handleClear()
      }
    }

    if (data.selectedTpe === "Category") {
      let obj = {
        previousCatId: data.previousCategory,
        changeCatId: data.ChangeCategory
      }
      dispatch(convertCategeory(obj));
      handleClear()
    }
    if (data.selectedTpe === "Style") {
      let obj = {
        previousStyleId: data.previousStyle,
        changeStyleId: data.changeStyle
      }
      dispatch(convertStyle(obj));
      handleClear()
    }
  }
  const handleSelectChange = (name, value) => {

    form.setFieldsValue({
      previousCategory: undefined,
      ChangeAgentName: undefined,
      ChangeCategory: undefined,
      previousCategory: undefined,
      previousStyle: undefined,
      changeStyle: undefined
    })
    setData((prevData) => ({ ...prevData, previousAgent: "", ChangeAgent: "", previousAgentName: "", ChangeAgentName: "", todo: [], ChangeCategory: "", previousCategory: "", selectedTpe: "", changeStyle: "", previousStyle: "" }));
    setTargetKeys([])
    setMockData([])

    setData((prevData) => ({ ...prevData, [name]: value }));
    if (name == "selectedTpe" && value === "Agent") {
      dispatch(getAgents("reset"))
      dispatch(getAgents());
    } else if (name == "selectedTpe" && value === "Style") {
      dispatch(getAllStyles("reset"))
      dispatch(getAllStyles());
    } else if (name == "selectedTpe" && value === "Category") {
      console.log("Category", value);
      dispatch(getCategories("reset"))
    }

  };


  const handleClear = () => {
    form.resetFields();
    setData({
      previousAgent: "", ChangeAgent: "", previousAgentName: "", ChangeAgentName: "", todo: [], ChangeCategory: "", previousCategory: "", selectedTpe: "", changeStyle: "", previousStyle: ""
    })

    setTargetKeys([])
    setMockData([])
  };

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Row gutter={[16, 16]}>
        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
          <Form.Item name="type" className="select-option">
            <Tooltip title="Select Type">
              <Select
                placeholder="Select Type"
                onChange={(value) => handleSelectChange("selectedTpe", value)}
                style={{ width: "100%" }}
              >
                {selectType.map((type) => (
                  <Option key={type} value={type}>
                    {type}
                  </Option>
                ))}
              </Select>
            </Tooltip>
          </Form.Item>
        </Col>

        {data.selectedTpe === "Agent" && (
          <>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <Form.Item name="previousAgent" className="select-option">
                <Tooltip title="Select Agent Name">
                  <Select
                    placeholder="Select Agent Name"
                    onSelect={(e) => handleAgentChange("previousAgent", e)}
                    style={{ width: "100%" }}
                    showSearch
                    //  defaultValue={data.selectType}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }

                  >
                    {agentData?.map((agent) => (
                      <Option key={agent?._id} value={agent?._id}>
                        {agent?.name}
                      </Option>
                    ))}
                  </Select>
                </Tooltip>
              </Form.Item>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <Form.Item name="ChangeAgent" className="select-option">
                <Tooltip title="Select Agent Name">

                  <Select
                    placeholder="Change Agent Name"
                    onSelect={(e) => handleAgentChange("ChangeAgent", e)}
                    style={{ width: "100%" }}
                    showSearch
                    //  defaultValue={data.selectType}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }

                  >

                    {agentData.map((agent) => (

                      <Option key={agent?._id} value={agent?._id}>
                        {agent?.name}
                      </Option>
                    ))}
                  </Select>
                </Tooltip>
              </Form.Item>
            </Col>
          </>
        )}



        {data?.selectedTpe === "Category" && (
          <>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} className="mr-10">
              <Form.Item name="previousCategory" className="select-option">
                <Select
                  placeholder="Select Current Category"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>

                    option.children.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>

                    optionA.children.props.title.toLowerCase().localeCompare(optionB.children.props.title.toLowerCase())
                  }
                  onChange={(e) => handleCategoryChange("previousCategory", e)}
                >

                  {categoryData
                    ?.filter(val => data.ChangeCategory !== val._id)
                    .map(val =>
                      <Option value={val._id}>
                        <Tooltip title={val.name}>
                          <span className="filter-list-tooltip">{val.name}</span>
                        </Tooltip>
                      </Option>
                    )
                  }
                </Select>
              </Form.Item>
            </Col>

            <Col xs={6} sm={6} md={6} lg={6} xl={6} className="mr-10">
              <Form.Item name="ChangeCategory" className="select-option">
                <Select
                  showSearch
                  placeholder="Select New Category"
                  filterOption={(input, option) =>
                    option.children.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children.props.title.toLowerCase().localeCompare(optionB.children.props.title.toLowerCase())
                  }
                  onChange={(e) => handleCategoryChange("ChangeCategory", e)}
                >


                  {categoryData?.filter(val => data.previousCategory !== val._id).map(data => {
                    return <Option value={data?._id}>
                      <Tooltip title={data?.name}>
                        <span className="filter-list-tooltip"
                        >{data?.name}</span>
                      </Tooltip>
                    </Option>
                  })
                  }
                </Select>
              </Form.Item>
            </Col>

          </>
        )}

        {data?.selectedTpe === "Style" && (
          <>
            <Col xs={6} sm={6} md={6} lg={6} xl={6} className="mr-10">
              <Form.Item name="previousStyle" className="select-option">
                <Select
                  placeholder="Select Current Style"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>

                    option.children.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children.props.title.toLowerCase().localeCompare(optionB.children.props.title.toLowerCase())
                  }
                  onChange={(e) => handleCategoryChange("previousStyle", e)}
                >
                  {StyleData?.filter(val => data.changeStyle !== val._id).map(data => {
                    return <Option value={data?._id}>
                      <Tooltip title={data?.name}>
                        <span className="filter-list-tooltip"
                        >{data?.name}</span>
                        {data?.categoryId?.name && (
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Tag style={{ fontSize: '8px', width: 'fit-content', border: 'none', background: 'none' }} color="success">
                              {data?.categoryId?.name}
                            </Tag>
                          </div>
                        )}
                      </Tooltip>
                    </Option>
                  })
                  }
                </Select>
              </Form.Item>
            </Col>

            <Col xs={6} sm={6} md={6} lg={6} xl={6} className="mr-10">
              <Form.Item name="changeStyle" className="select-option">
                <Select
                  showSearch
                  placeholder="Select New Style"
                  filterOption={(input, option) =>
                    option.children.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children.props.title.toLowerCase().localeCompare(optionB.children.props.title.toLowerCase())
                  }
                  onChange={(e) => handleCategoryChange("changeStyle", e)}
                >

                  {StyleData?.filter(val => data.previousStyle !== val._id).map(data => {
                    return <Option value={data?._id}>
                      <Tooltip title={data?.name}>
                        <span className="filter-list-tooltip"
                        >{data?.name}</span>
                        {data?.categoryId?.name && (
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Tag style={{ fontSize: '8px', width: 'fit-content', border: 'none', background: 'none' }} color="success">
                              {data?.categoryId?.name}
                            </Tag>
                          </div>
                        )}
                      </Tooltip>
                    </Option>
                  })
                  }

                </Select>
              </Form.Item>
            </Col>

          </>
        )}
        <Col xs={1} sm={1} md={1} lg={1} xl={1}>
          <Tooltip title="Clear">
            <Button
              type="primary"
              shape="circle"
              onClick={handleClear}
              icon={<CloseOutlined />}
            />
          </Tooltip>
        </Col>

      </Row >

      {data?.selectedTpe === "Category" && <Row gutter={16}>
        <Col xs={0} sm={0} md={2} lg={2} xl={2}></Col>

        <Col xs={22} sm={22} md={9} lg={9} xl={9}>
          {data?.selectedTpe === "Category" && data?.previousCategory &&
            <>
              <div
                className="drag-drop"
              >
                <div className="trasfer-top">

                  <label>
                    <Tooltip title={data?.PrevCatName.toUpperCase()}>
                      {data?.PrevCatName.toUpperCase()}
                    </Tooltip>
                  </label>
                </div>
                {prevCatStyleList?.map((style) => (
                  <div
                    key={style?._id}

                    className="drag-drop-list-1"
                  >
                    {style?.name}
                  </div>
                ))}
              </div>
            </>
          }

        </Col>
        <Col xs={24} sm={24} md={2} lg={2} xl={2} justify="center" align="center" className="agent-icon">
          {data?.selectedTpe === "Category" && data?.previousCategory !== "" && data?.ChangeCategory !== "" &&

            <SwapOutlined style={{ fontSize: "40px" }} />
          }

        </Col>
        <Col xs={22} sm={22} md={9} lg={9} xl={9}>
          {data?.selectedTpe === "Category" && data?.ChangeCategory &&
            <>
              <div
                className="drag-drop"
              >
                <div className="trasfer-top">

                  <label>
                    <Tooltip title={data?.ChangeCatName.toUpperCase()}>

                      {data?.ChangeCatName.toUpperCase()}
                    </Tooltip>
                  </label>

                </div>

                {changeCatStyleList?.map((style) => (
                  <div
                    key={style?._id}
                    className="drag-drop-list-1"
                  >
                    {style?.name}
                  </div>
                ))}
              </div>
            </>
          }
        </Col>

      </Row>
      }
      <Row gutter={16} >
        <Col xs={0} sm={0} md={2} lg={2} xl={2}></Col>

        <Col xs={22} sm={22} md={9} lg={9} xl={9}>

          {data?.selectedTpe === "Agent" && data?.previousAgent !== "" &&

            <div
              onDrop={(event) => onDrop(event, "targetKeys")}
              onDragOver={(event) => onDragOver(event)}
              className="drag-drop"
            >
              <div className="trasfer-top">

                <label>
                  <Tooltip title={data?.previousAgentName.toUpperCase()}>
                    {data?.previousAgentName.toUpperCase()}
                  </Tooltip>
                </label>
                {data?.previousAgent !== "" && data?.ChangeAgent !== "" &&
                  <Button style={{ background: "rgb(175, 231, 252)", float: "inline-end" }} onClick={() => handleTransfer("Left")} >Transfer All</Button>
                }
              </div>
              {targetKeys?.map((todo) => (
                <div
                  key={todo?._id}
                  draggable
                  onDragStart={(event) => onDragStart(event, todo, "targetKeys")}
                  className="drag-drop-list"
                >
                  {todo?.name}
                </div>
              ))}
            </div>
          }
        </Col>

        <Col xs={24} sm={24} md={2} lg={2} xl={2} justify="center" align="center" className="agent-icon">
          {data?.selectedTpe === "Agent" && data?.previousAgent !== "" && data?.ChangeAgent !== "" &&

            <SwapOutlined style={{ fontSize: "40px" }} />
          }

        </Col>
        <Col xs={22} sm={22} md={9} lg={9} xl={9}>
          {data?.selectedTpe === "Agent" && data?.ChangeAgent !== "" &&

            <div
              onDrop={(event) => onDrop(event, "mockData")}
              onDragOver={(event) => onDragOver(event)}
              className="drag-drop"
            >
              <div className="trasfer-top">

                <label>
                  <Tooltip title={data?.ChangeAgentName.toUpperCase()}>

                    {data?.ChangeAgentName.toUpperCase()}
                  </Tooltip>
                </label>
                {data?.ChangeAgent !== "" && data.previousAgent !== "" &&
                  <Button style={{ background: "rgb(175, 231, 252)", float: "inline-end" }} onClick={() => handleTransfer("Right")} >Transfer All</Button>
                }
              </div>

              {mockData?.map((todo) => (
                <div
                  key={todo?._id}
                  draggable
                  onDragStart={(event) => onDragStart(event, todo, "mockData")}
                  className="drag-drop-list"
                >
                  {todo?.name}
                </div>
              ))}
            </div>
          }
        </Col>
        <Col xs={0} sm={0} md={2} lg={2} xl={2}></Col>
      </Row>

      {(data?.ChangeCategory && data?.previousCategory || data?.ChangeAgentName && data?.previousAgent || data?.previousStyle && data?.changeStyle) &&
        <Row gutter={16} style={{ marginRight: "20px", marginTop: "20px" }} justify="center" align="center">
          <Button
            style={{ borderRadius: "50px" }}
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Row>
      }
    </Form>
  );
};

export default ChangeAll;