import React, { useState, useEffect, useRef } from "react";
import {
  getFloors,
  floorGoodsReplace,
} from "../../../redux/actions/floorAction";
import { Form, Button, Col, Row, Select, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getfloorSectionBrandSearchReplace } from "../../../redux/actions/reportsActions";
import { SwapOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";

const { Option } = Select;

const FloorGoodsTransfer = () => {
  const dispatch = useDispatch();
  const formRefOne = useRef();
  const formRefTwo = useRef();
  const [fromData, setFromData] = useState({
    floor: "",
    floorId: "",
    section: "",
    sectionId: "",
    brand: [],
    brandId: "",
  });

  const [toData, setToData] = useState({
    floor: "",
    floorId: "",
    section: "",
    sectionId: "",
  });

  const brandsData = useSelector((state) => state.brandsData.brands);
  const sectionData = useSelector((state) => state.sectionData.sections);
  const floorData = useSelector((state) => state.floorData.floors);
  const sectionBySearchFrom = useSelector(
    (state) => state.reportData.floorSectionBrandSearchFrom?.data
  );
  const brandBySearchFrom = useSelector(
    (state) => state.reportData.floorSectionBrandSearchFrom?.data
  );
  const sectionBySearchTo = useSelector(
    (state) => state.reportData.floorSectionBrandSearchTo?.data
  );
  const brandBySearchTo = useSelector(
    (state) => state.reportData.floorSectionBrandSearchTo?.data
  );

  useEffect(() => {
    console.log("fromData", fromData);
  }, [fromData]);

  const handleFloorChangeFrom = (key, value) => {
    console.log("value", value);
    setFromData((prevData) => ({
      ...prevData,
      floor: value.name,
      floorId: value.value,
      section: "",
      sectionId: "",
      brand: "",
      brandId: "",
    }));
    dispatch(
      getfloorSectionBrandSearchReplace({ floor: [value.value], type: "from" })
    );
    formRefOne.current.resetFields(["section", "brand"]);
  };

  const handleSectionChangeFrom = (key, value) => {
    setFromData((prevData) => ({
      ...prevData,
      section: value.name,
      sectionId: value.value,
      brand: "",
      brandId: "",
    }));
    dispatch(
      getfloorSectionBrandSearchReplace({
        section: [value.value],
        floor: [fromData.floorId],
        type: "from",
      })
    );
    formRefOne.current.resetFields(["brand"]);
  };

  const handleBrandChangeFrom = (key, value) => {
    setFromData((prevData) => ({
      ...prevData,
      brandId: value.map((brandId) => ({
        brandId: brandId.value,
        brandName: brandId.name,
      })),
      // brand: value.map((brand)=> brand.name)
    }));
  };

  const handleFloorChangeTo = (key, value) => {
    setToData((prevData) => ({
      ...prevData,
      floor: value.name,
      floorId: value.value,
      sectionId: "",
      section: "",
    }));
    dispatch(
      getfloorSectionBrandSearchReplace({ floor: [value.value], type: "to" })
    );
    formRefTwo.current.resetFields(["section"]);
  };

  const handleSectionChangeTo = (key, value) => {
    setToData((prevData) => ({
      ...prevData,
      sectionId: value.value,
      section: value.name,
    }));
    dispatch(
      getfloorSectionBrandSearchReplace({
        section: [value.value],
        floor: [toData.floorId],
        type: "to",
      })
    );
  };

  let floorOptions = floorData?.map((floor) => {
    return (
      <Option name={floor.name} value={floor._id}>
        {floor.name}
      </Option>
    );
  });

  let sectionOptions = sectionData?.map((section) => {
    return (
      <Option name={section.name} value={section._id}>
        {section.name}
      </Option>
    );
  });

  let sectionBySearchOptionsFrom = sectionBySearchFrom?.section?.map(
    (section) => {
      return (
        <Option name={section.section} value={section.sectionId}>
          {section.section}
        </Option>
      );
    }
  );

  let sectionBySearchOptionsTo = sectionBySearchTo?.section?.map((section) => {
    return (
      <Option name={section.section} value={section.sectionId}>
        {section.section}
      </Option>
    );
  });

  let brandOptions = brandsData?.map((brand) => {
    return (
      <Option name={brand.name} value={brand._id}>
        {brand.name}
      </Option>
    );
  });

  let brandBySearchOptionsFrom = brandBySearchFrom?.brand?.map((brand) => {
    return (
      <Option name={brand.brand} value={brand.brandId}>
        {brand.brand}
      </Option>
    );
  });

  const reset = () => {
    setFromData({
      floor: "",
      floorId: "",
      section: "",
      sectionId: "",
      brand: "",
      brandId: "",
    });

    setToData({
      floor: "",
      floorId: "",
      section: "",
      sectionId: "",
    });
    formRefOne.current.resetFields();
    formRefTwo.current.resetFields();
  };
  const onTransfer = async () => {
    let req = {
      mainFloorId: fromData?.floorId,
      mainFloor: fromData?.floor,
      mainSectionId: fromData?.sectionId,
      mainSection: fromData?.section,
      mainBrandId: fromData?.brandId,
      // mainBrand: fromData?.brand,
      changeFloorId: toData?.floorId,
      changeFloor: toData?.floor,
      changeSectionId: toData?.sectionId,
      changeSection: toData?.section,
    };

    const response = await dispatch(floorGoodsReplace(req));
    if (response?.status === 200) {
      reset();
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: `Floor Updated Successfully`,
        customClass: {
          popup: "my-swal-popup",
          icon: "my-swal-icon",
          confirmButton: "my-swal-button",
          cancelButton: "my-swal-button",
        },
      });
    } else {
      Swal.fire("Something went Wrong!", "error");
    }
    console.log("Transfer Data:", req);
  };

  return (
    <div className="subCategory-dashboard">
      <Row className="title-row" gutter={[8, 0]} align="center">
        <Col xs={8} sm={8} md={8} lg={8} xl={8} align="center">
          <div className="page-header">Floor Goods Transfer</div>
        </Col>
      </Row>
      <div className="container-fluid category-list">
        <Row
          style={{ marginTop: "30px" }}
          justify="space-between"
          align="middle"
        >
          {/* From Form */}
          <Col span={8}>
            <Row justify={"center"} style={{ fontSize: "20px" }}>
              From
            </Row>
            <Form
              name="from-form"
              className="from-form"
              style={{ marginTop: "15px" }}
              ref={formRefOne}
            >
              {/* Floor Selection */}
              <Row>
                <Col span={24}>
                  <Tooltip title="Select Floor">
                    <Form.Item name="floor">
                      <Select
                        placeholder="Select Floor"
                        value={fromData?.floor}
                        onClick={() => dispatch(getFloors())}
                        onChange={handleFloorChangeFrom}
                      >
                        {floorOptions}
                      </Select>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
              {/* Section Selection */}
              <Row>
                <Col span={24}>
                  <Tooltip title="Select Section">
                    <Form.Item name="section">
                      <Select
                        disabled={!fromData.floorId}
                        placeholder="Select Section"
                        value={fromData?.section}
                        onClick={() =>
                          fromData?.floor &&
                          dispatch(
                            getfloorSectionBrandSearchReplace({
                              floor: [fromData?.floorId],
                              type: "from",
                            })
                          )
                        }
                        onChange={handleSectionChangeFrom}
                      >
                        {sectionBySearchOptionsFrom
                          ? sectionBySearchOptionsFrom
                          : sectionOptions}
                      </Select>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>

              {/* Brand Selection */}
              <Row>
                <Col span={24}>
                  <Tooltip title="Select Brand">
                    <Form.Item name="brand">
                      <Select
                        mode="multiple"
                        disabled={!fromData.floorId || !fromData.sectionId}
                        value={fromData?.brand}
                        placeholder="Select Brand"
                        labelInValue
                        onChange={handleBrandChangeFrom}
                        onClick={() => {
                          fromData.floor &&
                            fromData.section &&
                            dispatch(
                              getfloorSectionBrandSearchReplace({
                                section: [fromData.sectionId],
                                floor: [fromData.floorId],
                                type: "from",
                              })
                            );
                        }}
                      >
                        {brandBySearchOptionsFrom
                          ? brandBySearchOptionsFrom
                          : brandOptions}
                      </Select>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
            </Form>
          </Col>

          <Col span={8} align="center">
            <SwapOutlined style={{ fontSize: "60px" }} />
          </Col>

          {/* To Form */}
          <Col span={8}>
            <Row justify={"center"} style={{ fontSize: "20px" }}>
              To
            </Row>
            <Form
              name="to-form"
              className="to-form"
              style={{ marginTop: "15px" }}
              ref={formRefTwo}
            >
              {/* Floor Selection */}
              <Row>
                <Col span={24}>
                  <Tooltip title="Select Floor">
                    <Form.Item name="floor">
                      <Select
                        placeholder="Select Floor"
                        disabled={
                          !fromData.floorId ||
                          !fromData.sectionId ||
                          !fromData.brandId
                        }
                        value={toData?.floor}
                        onClick={() => dispatch(getFloors())}
                        onChange={handleFloorChangeTo}
                      >
                        {floorOptions}
                      </Select>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>

              {/* Section Selection */}
              <Row>
                <Col span={24}>
                  <Tooltip title="Select Section">
                    <Form.Item name="section">
                      <Select
                        disabled={
                          !fromData.floorId ||
                          !fromData.sectionId ||
                          !fromData.brandId ||
                          !toData.floorId
                        }
                        placeholder="Select Section"
                        value={toData?.section}
                        onClick={() =>
                          toData.floor &&
                          dispatch(
                            getfloorSectionBrandSearchReplace({
                              floor: [toData.floorId],
                              type: "to",
                            })
                          )
                        }
                        onChange={handleSectionChangeTo}
                      >
                        {sectionBySearchOptionsTo
                          ? sectionBySearchOptionsTo
                          : sectionOptions}
                      </Select>
                    </Form.Item>
                  </Tooltip>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Row justify="center">
          <Button
            type="primary"
            disabled={
              !fromData.floorId ||
              !fromData.sectionId ||
              !fromData.brandId ||
              !toData.floorId ||
              !toData.sectionId
            }
            onClick={onTransfer}
          >
            Transfer
          </Button>
        </Row>
      </div>
    </div>
  );
};

export default FloorGoodsTransfer;
