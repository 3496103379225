import React, { Component } from "react";
import { Modal, Button, Form, Input, Upload, message, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { EditOutlined } from "@ant-design/icons";
import { updateDealer } from "../../../../redux/actions/dealerAction";

class UpdateDealer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      name: "",
      gst: "",
      alias: "",
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onSubmit(event) {
    const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (!gstRegex.test(this.state.gst)) {
      message.error("GST number must be like 22AAAAA0000A1ZR.");
      return;
    }
    let updateDealer = {
      name: this.state.name.trim(),
      gst: this.state.gst.trim(),
      alias: this.state.alias.trim(),
      _id: this.props.dealers._id,
    };
    this.props.updateDealer(updateDealer);

    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 2000);
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleModalCancel = () => {
    this.setState({ visible: false });
  };
  shouldComponentUpdate(nextProps, prevState) {
    if (nextProps.dealers._id != prevState._id) {
      this.setState({
        name: nextProps.dealers.name,
        _id: nextProps.dealers._id,
        gst: nextProps.dealers.gst,
        alias: nextProps.dealers.alias,
      });
      return true;
    }
    return true;
  }
  render() {
    const { visible, loading } = this.state;

    return (
      <>
        <button
          className="btn"
          type="button"
          data-original-title=""
          onClick={this.showModal}
        >
          <Tooltip title="Update Dealer">
            <EditOutlined />
          </Tooltip>
        </button>

        <Modal
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleModalCancel}
          title="Update Dealer"
          style={{ top: 20 }}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
        >
          <Form
            name="add-Dealer"
            className="add-Dealer"
            onFinish={this.onSubmit}
            initialValues={{}}
          >
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please Enter Dealer Name!",
                },
              ]}
              initialValue={this.state.name}
            >
              <Input
                autoFocus
                placeholder="Dealer Name"
                name="name"
                value={this.state.name}
                onChange={this.onChange}
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please Enter Dealer GST Number!",
                },
              ]}
            >
              <Input
                placeholder="Dealer GST Number"
                name="gst"
                value={this.state.gst}
                onChange={this.onChange}
              />
            </Form.Item>
            <Form.Item initialValue={this.state.alias}>
              <Input
                placeholder="Dealer Alias"
                name="alias"
                value={this.state.alias}
                onChange={this.onChange}
              />
            </Form.Item>
            <Form.Item className="float-right">
              <Button
                className="close-modal mr-3"
                onClick={this.handleModalCancel}
              >
                Close
              </Button>

              {(() => {
                if (this.state.name == "" || this.state.gst == "") {
                  return (
                    <Button disabled type="primary" className="ok-modal">
                      Save
                    </Button>
                  );
                } else {
                  return (
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="ok-modal"
                      loading={loading}
                    >
                      Save
                    </Button>
                  );
                }
              })()}
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}
UpdateDealer.propTypes = {
  updateDealer: PropTypes.func.isRequired,
};

export default connect(null, { updateDealer })(UpdateDealer);
