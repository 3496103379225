import React, { Component } from "react";
import { Table } from "antd";
import moment from "moment";

const { Column } = Table;

export default class ShowPurchaseReturnDetails extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    console.log(
      "#this.props.purchaseReturnBarcodes",
      this.props?.purchaseReturnBarcodes
    );
    return (
      <div className="purchase-list">
        <Table
          dataSource={this.props?.purchaseReturnBarcodes}
          bordered="true"
          size="middle"
          pagination={false}
          scroll={{ y: 240 }}
        >
          <Column
            title="SRN"
            key="index"
            render={(value, item, index) => index + 1}
            width={60}
          />
          <Column
            title="Purchase Id"
            dataIndex="purchaseId"
            key="purchaseId"
            width={120}
          />
          <Column
            title="Dealer Name"
            dataIndex="dealerName"
            key="dealerName"
            width={140}
          />
          <Column
            title="Barcode"
            dataIndex="barcode"
            key="barcode"
            width={115}
          />
          <Column
            title="Design No."
            dataIndex="designNumber"
            key="designNumber"
            width={115}
          />
          <Column
            title="Brand"
            dataIndex="brand"
            key="brand"
            width={115}
          />
          <Column
            title="Size"
            dataIndex="size"
            key="size"
            width={115}
          />
          <Column
            title="Color"
            dataIndex="color"
            key="color"
            width={115}
          />
          <Column
            title="MOQ"
            dataIndex="MOQ"
            key="MOQ"
            width={115}
          />
          <Column
            title="Qnty"
            dataIndex="totalPurchaseValue"
            key="totalPurchaseValue"
            width={120}
          />
          <Column
            title="Purcahse Price"
            dataIndex="purchasePrice"
            key="purchasePrice"
            width={150}
          />
          <Column
            title="Sale Price"
            dataIndex="wsp"
            key="wsp"
            width={150}
          />
          <Column
            title="Amount"
            dataIndex="Amount"
            key="Amount"
            width={150}
            render={(value, item, index) => item.totalPurchaseValue ? Number(item.totalPurchaseValue * item.purchasePrice).toFixed() : ""}
          />
        </Table>
      </div>
    );
  }
}
