import React, { useState } from "react";
import {
  Modal,
  Table,
  Button,
  message,
  Card,
  Row,
  Col,
  Tooltip,
  Alert,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getPurchaseByBarcodeSale } from "../../../redux/actions/purchaseAction";
import { PlusOutlined, CloudOutlined } from "@ant-design/icons";
import "./salesCard.css";
import { getdesignAndBrandComboByBarcode } from "../../../redux/actions/purchaseAction";
import gifLoader from "../../../assets/images/giphy.gif";

const { Column } = Table;

const NewLookUpModalSales = ({
  modalStatus,
  title,
  addDataonList,
  myPurchaseData,
  data,
}) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const designAndBrandCombo = useSelector(
    (state) => state.purchaseData.brandanddesigncombobyBarcode
  );

  const handleSuggestedModal = async () => {
    setLoading(true);
    dispatch(getdesignAndBrandComboByBarcode({ barcode: data?.barcode }))
      .then((response) => {})
      .finally(() => setLoading(false));
    setVisible(true);
  };
  const handleSuggestedModalCancel = () => {
    dispatch(getdesignAndBrandComboByBarcode("reset"));
    setVisible(false);
  };

  return (
    <>
      {/* <CloudOutlined onClick={() => handleSuggestedModal()} />
       */}
      <Button
        style={{
          backgroundColor: "#d0ebff",
          padding: "2px 0px",
          textAlign: "center",
          width: "100%",
        }}
        onClick={() => handleSuggestedModal()}
      >
        <span>Suggestions</span>
        <CloudOutlined style={{ marginLeft: "10px" }} />
      </Button>
      <Modal
        open={visible}
        // width={500}
        title="Suggested Barcodes"
        onCancel={handleSuggestedModalCancel}
        style={{ top: 20 }}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        destroyOnClose={true}
      >
        {!loading ? (
          <>
            <div
              style={{
                maxHeight: "400px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
              className="suggestionCard"
            >
              <Row gutter={16}>
                {designAndBrandCombo
                  ?.filter(
                    (item) =>
                      !myPurchaseData?.some(
                        (purchase) => purchase.barcode === item.barcode
                      )
                  )
                  ?.map((item) => (
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={6}
                      className="mb-4"
                      key={item.barcode}
                    >
                      <Card className="sales-card shadow">
                        <div className="card-content">
                          <div className="left-section">
                            <img
                              src={item.images[0]}
                              alt="Product"
                              className="product-image"
                            />
                            <div className="barcode">{item.barcode}</div>
                          </div>
                          <div className="right-section">
                            <div className="sub-left">
                              <Tooltip title={`D No.: ${item.designNumber}`}>
                                <div className="info-row">
                                  <span>D No.</span>
                                  <span>{item.designNumber}</span>
                                </div>
                              </Tooltip>
                              <Tooltip title={`Size: ${item.size}`}>
                                <div className="info-row">
                                  <span>Size</span>
                                  <span>{item.size}</span>
                                </div>
                              </Tooltip>
                              <Tooltip title={`Color: ${item.color}`}>
                                <div className="info-row">
                                  <span>Color</span>
                                  <span>{item.color}</span>
                                </div>
                              </Tooltip>
                              <Tooltip title={`MOQ: ${item.MOQ}`}>
                                <div
                                  className="info-row"
                                  style={{ color: "grey" }}
                                >
                                  <span>MOQ</span>
                                  <span>{item.MOQ}</span>
                                </div>
                              </Tooltip>
                            </div>
                            <div className="sub-right">
                              <div>
                                <span className="info-right">
                                  91{item.wsp * item.quantity}
                                </span>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  type="primary"
                                  className="sle-btn"
                                  // prefix={PlusOutlined}
                                  onClick={async () => {
                                    let findBarcode = await myPurchaseData.find(
                                      (data) => data.barcode === item.barcode
                                    );
                                    if (findBarcode === undefined) {
                                      const response = await dispatch(
                                        getPurchaseByBarcodeSale({
                                          barcode: item.barcode,
                                        })
                                      );

                                      if (
                                        response &&
                                        response.status === 200 &&
                                        response.data
                                      ) {
                                        if (
                                          response?.data?.totalPurchaseValue > 0
                                        ) {
                                          addDataonList(item, response);
                                        } else {
                                          message.error("Out Of Stock");
                                        }
                                      }
                                    } else {
                                      if (title === "Update Bill") {
                                        const response = await dispatch(
                                          getPurchaseByBarcodeSale({
                                            barcode: item.barcode,
                                          })
                                        );
                                        if (
                                          response &&
                                          response.status === 200 &&
                                          response.data
                                        ) {
                                          if (
                                            (response?.data
                                              ?.totalPurchaseValue > 0 &&
                                              response?.data
                                                ?.totalPurchaseValue >
                                                findBarcode?.stock &&
                                              findBarcode?.stock > 0) ||
                                            (findBarcode.stock <
                                              findBarcode.saleQty &&
                                              findBarcode?.stock > 0) ||
                                            (findBarcode.stock >=
                                              findBarcode.saleQty &&
                                              findBarcode?.stock > 0)
                                          ) {
                                            addDataonList(item, response);
                                          } else {
                                            message.error(
                                              "Max Quantity Reached"
                                            );
                                          }
                                        }
                                      } else {
                                        const response = await dispatch(
                                          getPurchaseByBarcodeSale({
                                            barcode: item.barcode,
                                          })
                                        );
                                        if (
                                          response &&
                                          response.status === 200 &&
                                          response.data
                                        ) {
                                          if (
                                            response?.data?.totalPurchaseValue >
                                              0 &&
                                            response?.data?.totalPurchaseValue >
                                              findBarcode?.saleQty
                                          ) {
                                            addDataonList(
                                              findBarcode,
                                              response
                                            );
                                          } else {
                                            message.error(
                                              "Max Quantity Reached"
                                            );
                                          }
                                        }
                                      }
                                    }
                                  }}
                                >
                                  <PlusOutlined />
                                  Add
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  ))}
                {!designAndBrandCombo?.filter(
                  (item) =>
                    !myPurchaseData?.some(
                      (purchase) => purchase.barcode === item.barcode
                    )
                )?.length && (
                  <Alert
                    message="All Barcodes Added!"
                    description="Barcodes With This Combinations is Already Added"
                    type="info"
                    showIcon
                  />
                )}
              </Row>
            </div>
          </>
        ) : (
          <>
            {" "}
            <div className="d-flex justify-content-center align-items-center">
              <img src={gifLoader} width={"300px"} height={"300px"} />
            </div>{" "}
          </>
        )}
      </Modal>
    </>
  );
};

export default NewLookUpModalSales;
