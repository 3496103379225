import React , { useEffect} from "react";
import { Row, Col, Input, Button, Card, Tooltip, Modal, message } from "antd";
import StockReportFilter from "./StockReportFilter";
import { CaretRightOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import {
  getStockByFilter,
  getStockSearchforEcom,
} from "../../../redux/actions/purchaseAction";
const StockSalesApp = () => {
  const dispatch = useDispatch()
  const stockDataData = useSelector(
    (state) => state.purchaseData.stockDataSalesApp
  );

  useEffect(() => {
    return () => {
      dispatch(getStockByFilter("reset"))
      dispatch(getStockSearchforEcom("reset"))
    }
}, [])

  return (
    <>
      <div className="card">
        <div className="card-body">
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <h4
                className="page-header"
                style={{ fontWeight: 600, fontSize: "22px", margin: "0px" }}
              >
                <span style={{ color: "#52c41a" }}> Stock Data</span>
              </h4>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} align="end">
              <StockReportFilter />
            </Col>
          </Row>
          <Row gutter={24} className="mt-4 mb-4 grand-totall billHeadingContainer">
            <Col sm={24} md={24} lg={24}>
              <span className=" billsTopHeading">
                Total Amount
                <CaretRightOutlined className="mr-2" /> {stockDataData?.[0]?.totalValue || "00"}
              </span>
            </Col>
          </Row>
          <Row gutter={24} className="mb-4 grand-totall billHeadingContainer">
            <Col sm={24} md={24} lg={24}>
              <span className=" billsTopHeading">
                Total Quantity
                <CaretRightOutlined className="mr-2" /> {stockDataData?.[0]?.totalQuantity || "00"}
              </span>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default StockSalesApp;
