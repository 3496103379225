import React, { Component } from "react";
import { Row, Col, Breadcrumb, AutoComplete } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import StyleList from "./StyleList";
import AddStyle from "./AddStyle";

export default class Style extends Component {
  render() {
    return (
      <div className="subCategory-dashboard">
        <div className="container-fluid category-list">
          <Row className="title-row">
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="page-header">Style</div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <AddStyle />
            </Col>
          </Row>

          <StyleList />
        </div>
      </div>
    );
  }
}
