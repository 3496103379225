import React from 'react'
import { Button } from 'antd'
import { BackwardOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

function BackButton() {

    const history = useHistory()

    const handleClick = () => {
        history.push('/dagaImpex/report');
    };

    return (
        <Button  style={{ float: 'right',  background: '#afe7fc' }}
            onClick={handleClick}
        >
            <BackwardOutlined /> Back
        </Button>
    )
}

export default BackButton