import React, { useState, useEffect, useRef } from "react";
import { Table, Button, Space, Input, Row, Col, Modal, Tooltip, Popconfirm } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getSalesListBydate,
  saleBySearchValue,
  salesBillDetails,
  sendSalesBilltoMultipleCustomer,
  storePDFinSalesBill,
  uploadImage,
} from "../../../redux/actions/saleAction.js";
import moment from "moment";
import ShowBillProducts from "./ShowBillProducts.js";
import Search from "../../../util/Search.js";
import TopBarProgress from "react-topbar-progress-indicator";
import DateWiseSearch from "../../../util/DateWiseSearch.js";
import { useHistory } from "react-router-dom";
import logo from "../../../assets/images/Daga_Impex_Logo.png";
import QR from "../../../assets/images/QR.png";
import Swal from "sweetalert2";
import jsPDF from "jspdf";
import { FileTextOutlined } from "@ant-design/icons";
import ViewSalePDF from "./ViewSalePDF.js";

const { Column } = Table;
const searchOptions = ["Barcode", "SalesId", "CustomerName", "agentName"];

const SalesListPdf = () => {
  const [searchText, setSearchText] = useState("");
  const [dateObj, setDateObj] = useState(null);
  const [searchObj, setSearchObj] = useState(null);
  const [loading,setLoading] = useState(false)
  const [ids, setIds] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  // Selecting state from Redux store
  const saleslistbydate = useSelector(
    (state) => state.saleData.saleslistbydate
  );
  const searchData = useSelector((state) => state.saleData.saleSearchData);
  const progressBarStatus = useSelector(
    (state) => state.progressBarReducer.showProgressBar
  );
  const salesOrders = useSelector((state) => state.saleData.salesOrders);

  const initialDateSearch = () => {
    if (history.location.state) {
      const localStartDate = localStorage.getItem("startDate");
      const localEnddate = localStorage.getItem("endDate");

      if (localStartDate && localEnddate) {
        const today = moment(new Date()).format("YYYY-MM-DD");
        const endTime =
          today === localEnddate
            ? moment().format("HH:mm:ss")
            : moment(localEnddate).endOf("day").format("HH:mm:ss");

        const reportObj = {
          startDate: localStartDate,
          endDate: `${localEnddate} ${endTime}`,
        };
        setDateObj(reportObj);
        dispatch(getSalesListBydate(reportObj));
        localStorage.removeItem("startDate");
        localStorage.removeItem("endDate");
      } else {
        const reportObj = {
          startDate: moment(new Date()).format("YYYY-MM-DD"),
          endDate: `${moment(new Date()).format(
            "YYYY-MM-DD"
          )} ${moment().format("HH:mm:ss")}`,
        };

        dispatch(getSalesListBydate(reportObj));
      }
    } else {
      const reportObj = {
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: `${moment(new Date()).format("YYYY-MM-DD")} ${moment().format(
          "HH:mm:ss"
        )}`,
      };

      localStorage.setItem("startDate", reportObj.startDate);
      localStorage.setItem("endDate", reportObj.endDate);
      setDateObj(reportObj);
      dispatch(getSalesListBydate(reportObj));
    }
  };
  useEffect(() => {
    initialDateSearch();
  }, [history, dispatch]);

  const listData = () => {
    if (searchText) {
      return (
        searchData?.details?.map((obj) => ({
          ...obj,
          c_name: obj?.userId?.name || "",
        })) || []
      );
    } else {
      return (
        saleslistbydate?.details?.map((obj) => ({
          ...obj,
          c_name: obj?.userId?.name || "",
        })) || []
      );
    }
  };

  const generateBill = async (record) => {
    const invoiceId = record.invoiceId;
    const data = await dispatch(salesBillDetails({ invoiceId: invoiceId }));

    const doc = new jsPDF({
      compress: true,
    });
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(14);
    doc.text("JAY SHREE KRISHNA", doc.internal.pageSize.getWidth() / 2, 25, {
      align: "center",
    });

    doc.setFont("helvetica", "bold");
    doc.text("Estimate", doc.internal.pageSize.getWidth() / 2, 33, {
      align: "center",
    });

    doc.addImage(logo, "PNG", 12, 10, 25, 25);
    doc.setFont("helvetica", "normal");
    doc.setLineWidth(0.5);
    doc.line(10, 38, doc.internal.pageSize.getWidth() - 10, 38);

    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text(`${record?.userId?.name || ""}`, 12, 45, {
      align: "left",
    });
    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Date:", 12, 53, {
      align: "left",
    });
    doc.setFont("helvetica", "normal");
    doc.text(`${moment(record?.date || "").format("DD/MM/YY H:mm")}`, 25, 53, {
      align: "left",
    });

    doc.setFont("helvetica", "bold");
    doc.text("Agent Name:", 12, 58, {
      align: "left",
    });
    doc.setFont("helvetica", "normal");
    doc.text(`${record?.userId?.agentId?.name || ""}`, 40, 58, {
      align: "left",
    });

    doc.setFont("helvetica", "bold");
    doc.text("Invoice Number:", 12, 63, {
      align: "left",
    });
    doc.setFont("helvetica", "normal");
    doc.text(`${invoiceId || ""}`, 45, 63, {
      align: "left",
    });

    doc.setFont("helvetica", "bold");
    doc.text("Remark:", 12, 68, {
      align: "left",
    });
    doc.setFont("helvetica", "normal");
    doc.text(`${record?.remark || ""}`, 30, 68, {
      align: "left",
    });

    let tQuant = 0;
    let tAmount = 0;

    const tableData = data?.data?.salesItems?.map((item, index) => {
      const quantity = item?.quantity || 0;
      const wsp = item?.wsp || 0;
      const amount = quantity * wsp;

      tQuant += quantity;
      tAmount += amount;

      return [
        index + 1,
        item?.brand || "",
        item?.designNumber || "",
        item?.color || "",
        Number(item?.quantity / item?.MOQ) || "",
        item?.MOQ || "",
        item?.size || "",
        quantity,
        wsp,
        amount,
      ];
    });

    const tableData2 = [
      ...tableData,
      ["", "Total:", "", "", "", "", "", tQuant, "", tAmount],
    ];

    doc.autoTable({
      head: [
        [
          "Sl No.",
          "Brand",
          "D-Number",
          "Color",
          "Set",
          "MOQ",
          "Size",
          "Quantity",
          "Rate",
          "Amount",
        ],
      ],
      body: tableData2,
      didParseCell: function (data) {
        if (data.row.index === tableData2.length - 1) {
          console.log(data.cell);
          data.cell.styles.fontStyle = "bold";
        }
      },
      theme: "grid",
      startY: 77,
      styles: {
        fontSize: 8,
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        halign: "center",
        cellPadding: 1,
      },
      headStyles: {
        fontSize: 9,
        textColor: [0, 0, 0],
        lineWidth: 0.05,
        lineColor: [0, 0, 0],
        fillColor: [200, 200, 200],
        halign: "center",
      },
      columnStyles: {
        0: { columnWidth: 10 },
        1: { columnWidth: 30 },
        2: { columnWidth: 19 },
        3: { columnWidth: 19 },
        4: { columnWidth: 15 },
        5: { columnWidth: 15 },
        6: { columnWidth: 19 },
        7: { columnWidth: 19 },
        8: { columnWidth: 19 },
        9: { columnWidth: 19 },
      },
    });

    const finalY = doc.autoTable.previous.finalY;
    console.log("data", data?.data?.billDetails);
    const grAmount = data?.data?.billDetails?.grAmountUsed;
    const discount = data?.data?.billDetails?.discount;
    const totalAmount = data?.data?.billDetails?.totalAmount;
    const discountedAmount = (totalAmount * discount) / 100;
    const tax = data?.data?.billDetails?.tax;
    const taxAmount = ((totalAmount - discountedAmount) * tax) / 100;
    const pAndF = data?.data?.billDetails?.pAndf;
    const frieght = data?.data?.billDetails?.frieght || 0;
    const labour = data?.data?.billDetails?.labour;
    const netAmount = data?.data?.billDetails?.netAmount;

    let summaryTableData =
      Math.ceil(discountedAmount) > 0
        ? [
            ["GR Amount", grAmount],
            ["Discount", Math.ceil(discountedAmount)],
            ["TAX", Math.ceil(taxAmount)],
            ["P&F", pAndF],
            ["Frieght", frieght],
            ["Labour", labour],
            ["Net Amount", netAmount],
          ]
        : [
            ["GR Amount", grAmount],
            ["TAX", Math.ceil(taxAmount)],
            ["P&F", pAndF],
            ["Frieght", frieght],
            ["Labour", labour],
            ["Net Amount", netAmount],
          ];

    doc.autoTable({
      body: summaryTableData,
      theme: "grid",
      startY: finalY,
      margin: { left: 160 },
      styles: {
        fontSize: 8,
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        halign: "center",
        cellPadding: 1,
      },
      columnStyles: {
        0: { columnWidth: 19, fillColor: [200, 200, 200], fontStyle: "bold" },
        1: { columnWidth: 19 },
      },
    });

    const pageWidth = doc.internal.pageSize.getWidth();
    const halfPageWidth = pageWidth / 2 - 5;

    doc.setFontSize(12);
    doc.setTextColor(90, 90, 90);
    doc.text("* Terms and conditions", 12, finalY + 8, {
      maxWidth: halfPageWidth,
    });
    doc.setTextColor(128, 128, 128);
    doc.setFontSize(9);
    doc.text("1. Goods once sold will not be taken back", 12, finalY + 13, {
      maxWidth: halfPageWidth,
    });
    doc.text("2. No Claim No Guarantee", 12, finalY + 18, {
      maxWidth: halfPageWidth,
    });
    doc.text(
      "3. Any disputes arising from this transaction will be resolved through Jurisdiction in Indore.",
      12,
      finalY + 23,
      {
        maxWidth: halfPageWidth,
      }
    );
    doc.text(
      "4. A late payment fee of 10% will be applied to overdue balances after 30 days.",
      12,
      finalY + 32,
      {
        maxWidth: halfPageWidth,
      }
    );

    const qrX = halfPageWidth + 23;

    // Add the QR image
    doc.addImage(QR, "PNG", qrX, finalY + 3, 33, 33);

    doc.setFontSize(14);
    doc.setTextColor(0, 0, 0);
    doc.text(
      "|| Thank You Visit Again ||",
      doc.internal.pageSize.getWidth() / 2,
      finalY + 80,
      {
        align: "center",
      }
    );

    const pdfBlob = doc.output("blob");
    const formData = new FormData();
    formData.append("image", pdfBlob, invoiceId);
    formData.append("invoiceId", invoiceId);
    const response = await dispatch(uploadImage(formData))
      .then(async (response) => {
        console.log("response", response);
        const res = await dispatch(
          storePDFinSalesBill({
            pdfUrl: response?.data?.filePath,
            id: record._id,
          })
        );
        console.log("res", res);
        if (res.status == 200) {
          if (searchObj) {
            dispatch(saleBySearchValue(searchObj)).then(() => {
              listData();
            });
          } else {
            dispatch(getSalesListBydate(dateObj)).then(() => {
              listData();
            });
          }
          Swal.fire("Success!", "Bill Generated!", "success");
        } else {
          Swal.fire("Something went Wrong!", "error");
        }
      })
      .catch((error) => {
        Swal.fire(
          "Error!",
          "An error occurred while generating the bill.",
          "error"
        );
      });
  };

  const handleCheckboxChange = (id) => {
    setIds((prevSelected) => {
      if (prevSelected.includes(id)) {
        // If already selected, remove it
        return prevSelected.filter((_id) => _id !== id);
      } else {
        // If not selected, add it
        return [...prevSelected, id];
      }
    });
  };

  const handleSendPdfs = async () => {
    setLoading(true);
    await dispatch(sendSalesBilltoMultipleCustomer({ ids: ids })).then(
      (res) => {
        if (res?.status == 200) {
          if (searchObj) {
            dispatch(saleBySearchValue(searchObj)).then(() => {
              listData();
            });
          } else {
            dispatch(getSalesListBydate(dateObj)).then(() => {
              listData();
            });
          }
          Swal.fire("Success!", "Bills Sent!", "success");
        }
      }
    ).catch(()=>{
        Swal.fire("Something went Wrong!", "error");
    }).finally(()=>{
      setLoading(false);
    })
  };

  return (
    <div className="subCategory-dashboard">
      {progressBarStatus && <TopBarProgress />}
      <div className="container-fluid category-list">
        <Row className="title-row">
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <div className="page-header">Sales PDF</div>
          </Col>
        </Row>
        <Row gutter={16} className="mt-4">
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Search
              title="Sales PDF"
              placeholder="Sale Return Id, Barcode, Agent Name or CustomerName"
              searchopt={searchOptions}
              changeFunction={setSearchText}
              cancelState={() => {
                setSearchText("");
                setSearchObj(null);
              }}
              setSearchObj={setSearchObj}
              location={history.location}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <DateWiseSearch
              keyId={"Sales PDF"}
              propsHistory={history}
              dateObj={setDateObj}
            />
          </Col>
        </Row>
        <div className="card">
          <div className="card-body">
            <Table
              dataSource={listData()}
              bordered
              size="middle"
              pagination={{
                pageSize: 100,
                total:
                  searchData?.details?.length ||
                  saleslistbydate?.details?.length ||
                  0,
              }}
              scroll={{ y: 400 }}
              summary={() => (
                <Table.Summary fixed={"bottom"}>
                  <Table.Summary.Row>
                    <Table.Summary.Cell
                      className="grand-Total-summary"
                      index={1}
                      colSpan={2}
                    >
                      Grand Total
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} colSpan={1}>
                      {searchData?.details?.length ||
                        saleslistbydate?.details?.length ||
                        ""}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} colSpan={1}>
                      {searchData?.sum?.totalBoxes ||
                        saleslistbydate?.sum?.totalBoxes ||
                        ""}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} colSpan={1}>
                      {searchData?.sum?.quantity ||
                        saleslistbydate?.sum?.quantity ||
                        ""}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} colSpan={1}>
                      {Number(
                        searchData?.sum?.totalAmount ||
                          saleslistbydate?.sum?.totalAmount ||
                          0
                      ).toFixed()}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} colSpan={1}>
                      {Number(
                        searchData?.sum?.netAmount ||
                          saleslistbydate?.sum?.netAmount ||
                          0
                      ).toFixed()}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )}
            >
              <Column
                key="select"
                fixed="left"
                render={(text, record) => (
                  <input
                    disabled={record.pdfUrl == ""}
                    type="checkbox"
                    checked={ids.includes(record._id)}
                    onChange={() => handleCheckboxChange(record._id)}
                  />
                )}
                width={80}
              />
              <Column
                title="SRN"
                key="index"
                render={(value, item, index) => index + 1}
                width={60}
              />
              <Column
                title="Bill Date"
                dataIndex="date"
                key="date"
                width={210}
                render={(value, item) =>
                  `${moment(item.date).format("YYYY-MM-DD")} (${moment(
                    item.date
                  ).format("HH:mm:ss")})`
                }
              />
              <Column
                title="Invoice No."
                dataIndex="invoiceId"
                key="invoiceId"
                width={150}
                render={(value, item) =>
                  item?.invoiceId ? `S-${item.invoiceId}` : ""
                }
              />
              <Column
                title="Customer Name"
                dataIndex="c_name"
                key="c_name"
                width={190}
                render={(value, item) => item?.c_name || ""}
              />
              <Column
                title="Agent Name"
                dataIndex="userId"
                key="userId"
                render={(value, item) => item?.userId?.agentId?.name || ""}
                width={170}
              />
              <Column
                title="Total Boxes"
                dataIndex="totalBoxes"
                key="totalBoxes"
                width={120}
              />
              <Column
                title="Total Qnty"
                dataIndex="totalQuantity"
                key="totalQuantity"
                width={120}
              />
              <Column
                title="Total Amt"
                dataIndex="totalAmount"
                key="totalAmount"
                render={(value, item) => parseInt(item.totalAmount).toFixed()}
                width={100}
              />
              <Column
                title="Gr Amt"
                dataIndex="grAmountUsed"
                key="grAmountUsed"
                render={(value, item) => Number(item.grAmountUsed).toFixed()}
                width={100}
              />
              <Column
                title="Net Amt"
                dataIndex="netAmount"
                key="netAmount"
                width={120}
                render={(value, item) => parseInt(item.netAmount).toFixed()}
              />
              <Column
                title="Remark"
                dataIndex="remark"
                key="remark"
                width={120}
              />
              <Column
                title="Verified"
                dataIndex="isVerified"
                key="isVerified"
                width={120}
                render={(value, item) => `${item.isVerified}`}
              />
              <Column
                title="Updated Date"
                dataIndex="updatedAt"
                key="updatedAt"
                width={210}
                render={(value, item) =>
                  item?.updatedAt
                    ? `${moment(item.updatedAt).format("YYYY-MM-DD")} (${moment(
                        item.updatedAt
                      ).format("HH:mm:ss")})`
                    : ""
                }
              />
              <Column
                title="Action"
                key="action"
                width={240}
                fixed="right"
                render={(text, record) =>
                  (
                    <Row gutter={40}>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <a>
                          <ViewSalePDF pdfUrl={record?.pdfUrl}/>
                        </a>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Tooltip title="Generate Bill">
                          <Popconfirm
                            title="Are you sure you want to generate the bill?"
                            onConfirm={() => generateBill(record)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <button className="btn">
                              <FileTextOutlined />
                            </button>
                          </Popconfirm>
                        </Tooltip>
                      </Col>
                    </Row>
                  )
                }
              />
            </Table>
            {(() => {
              if (ids.length > 0) {
                return (
                  <>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="save-modal mt-4"
                      block
                      onClick={handleSendPdfs}
                      loading={loading}
                    >
                      {loading ? "Sending..." : "Send Bill PDF"}
                    </Button>
                  </>
                );
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesListPdf;