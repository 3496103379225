import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Button, Form, Select, DatePicker, Input, Tooltip, Checkbox, message, Card, Modal, } from 'antd';
import { ArrowLeftOutlined, StopOutlined, PlusOutlined, ScanOutlined } from '@ant-design/icons';

import { getActiveandInactiveCustomer, getCustomers } from '../../../redux/actions/customerAction';
import { useDispatch, useSelector } from 'react-redux';
import { getBrandBySearch } from "../../../redux/actions/brandAction";
import {
  getPurchaseByBarcodeSale,
  getdesignAndBrandComboByBarcode
} from "../../../redux/actions/purchaseAction";
//  import BarcodeReader from 'react-barcode-reader'
import moment from 'moment/moment';
import { createSales } from "../../../redux/actions/saleAction";
import ViewGenerateBill from "./ViewGenerateBill";
import BarcodeScanner from "react-qr-barcode-scanner";
import ShowNewSalesDetails from './ShowNewSalesDetails';
import NewLookUpModalSales from './NewLookUpModalSales';
const { Option } = Select;

const currentDate = moment().format("YYYY-MM-DD");
const currentTime = moment().format("HH:mm:ss");

const AddNewSales = ({ history }) => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const InactiveCustomer = useSelector((state) => state.customerData.activeandInactiveCustomer);
  const [isCustomerSelect, setIsCustomerSelect] = useState(false)
  const [isSalesPersonSelect, setIsSalesPersonSelect] = useState(false)
  const [isbarcodeEnter, setIsbarcodeEnter] = useState(false)

  const SalePersonName = useSelector((state) => state.customerData.salesPerson);
  const brandNameSearch = useSelector((state) => state.brandsData.brandBysearch)
  const [stopStream, setStopStream] = useState(false)
  const [isBarcodeScanner, setIsBarcodeScanner] = useState(true)
  const [data, setData] = useState({
    myPurchaseData: [],
    invoiceNumber: "",
    saleDate: currentDate,
    saleTime: currentTime,
    saleBillDate: moment(`${currentDate} ${currentTime}`, 'YYYY-MM-DD HH:mm:ss').toDate(),
    customerName: "",
    customerId: "",
    agentId: "",
    agent: "",
    salesPersonName: "",
    lrNumber: "",
    lrDate: "",
    remark: "",
    transport: "",
    grAmount: 0,
    barcode: "",
    designNumber: "",
    boxes: "",
    totalBoxes: 0,
    totalQuantity: 0,
    totalAmount: 0,
    brand: "",
    brandId: "",
    selectedData: "",
    salesPersonId: "",
    saleQty: 0,
    discount: 0,
    tax: 5,
    pf: 0,
    labour: 0,
    billedGR: false,
    netAmount: 0,
    finalDiscAmt: 0,
    taxAmt: 0,
    taxedAmount: 0,
    discountAmt: 0,
    diabledStatus: false,
    responseData: []
  });
  const [loading, setLoading] = useState(false);
  const [modalStatus, setModalStatus] = useState(false)
  const onError = (error) => {
    console.error('Barcode scanner error:', error);
  };


  // done
  const onUpdateScreen = async (err, result) => {

    if (result) {
      setData((prevData) => ({
        ...prevData,
        barcode: result.text,
      }));
      message.success("barcode Scan Successfully")
      setIsBarcodeScanner(true)
      let barcode = result.text
      if (barcode.length === 9) {
        const response = await dispatch(getdesignAndBrandComboByBarcode({ barcode: barcode }))
        if (response && response?.status == 200 && response?.data && response?.data?.data.length > 0) {
          setData((prevData) => ({
            ...prevData,
            barcode: result.text,
            designNumber: response?.data?.data[0].designNumber,
            brand: response?.data?.data[0].brand,
            brandId: response?.data?.data[0].brandId,
            responseData: response?.data?.data
          }));

          formRef.current.setFieldsValue({
            brand: response?.data?.data[0].brand
          })
          setModalStatus(true)
          await handleModalBarcode(result.text, response?.data?.data)
        }
        else {
          setData((prevData) => ({
            ...prevData,
            responseData: [],
            barcode: "",
            designNumber: "",
            brand: "",
            brandId: "",
          }));
          setIsBarcodeScanner(true)
          message.success("No data Found")

          formRef.current.setFieldsValue({
            brand: undefined
          })
        }
      }
    }

  };


  const handleGrUsedCheck = (e) => {
    if (e.target.checked === false) {
      const discountAmt = data.totalAmount * (data.discount / 100);
      // const finalDiscAmt = prevData.totalAmount - discountAmt;
      let finalDiscAmt = data.totalAmount - discountAmt;
      const taxAmt = finalDiscAmt * (data.tax / 100);
      const taxedAmount = finalDiscAmt + taxAmt;
      const NetAmount = data.pf + data.labour + taxedAmount;

      setData((prevData) => ({
        ...prevData,
        billedGR: e.target.checked,
        discountAmt: discountAmt,
        finalDiscAmt: finalDiscAmt,
        taxAmt: taxAmt,
        taxedAmount: taxedAmount,
        netAmount: NetAmount
      }));


    } else {
      const discountAmt = (data.totalAmount - data.grAmount) * (data.discount / 100);
      const finalDiscAmt = data.totalAmount - discountAmt - data.grAmount;
      const taxAmt = finalDiscAmt * (data.tax / 100);
      const taxedAmount = finalDiscAmt + taxAmt;
      const NetAmount = data.pf + data.labour + taxedAmount;

      setData((prevData) => ({
        ...prevData,
        billedGR: e.target.checked,
        discountAmt: discountAmt,
        finalDiscAmt: finalDiscAmt,
        taxAmt: taxAmt,
        taxedAmount: taxedAmount,
        netAmount: NetAmount
      }));
    }
  }

  // done
  const handleModalStatus = async () => {
    let barcodeMatchIndex = data?.myPurchaseData?.findIndex((text) => Number(data.barcode) === Number(text.barcode));
    let matchBarcode = data?.responseData?.find((text) => Number(data?.barcode) === Number(text?.barcode));
    const response = await dispatch(getPurchaseByBarcodeSale({ barcode: data?.barcode }));
    if (response && response?.status === 200 && response?.data && response?.data?.totalPurchaseValue > 0) {
      if (barcodeMatchIndex !== -1) {
        if (response?.data?.totalPurchaseValue > data?.myPurchaseData[barcodeMatchIndex]?.saleQty) {
          setData((prevData) => {
            const updatedMyPurchaseData = prevData?.myPurchaseData?.map((item, index) =>
              Number(item?.barcode) === Number(matchBarcode?.barcode) ? {
                ...item,
                // saleQty: item?.saleQty + matchBarcode?.MOQ,
                // boxes: (item?.saleQty + matchBarcode?.MOQ) / item?.MOQ,
                // finalAmount: (item?.saleQty + matchBarcode?.MOQ) * item?.wsp,
                saleQty: response?.data?.totalPurchaseValue == item?.saleQty ? item?.saleQty : item?.saleQty + matchBarcode?.MOQ,
                boxes: response?.data?.totalPurchaseValue == item?.saleQty ? item?.boxes : (item.saleQty + matchBarcode.MOQ) / item.MOQ,
                finalAmount: response?.data?.totalPurchaseValue == item?.saleQty ? item?.finalAmount : (item.saleQty + matchBarcode.MOQ) * item.wsp,
                salesPersonId: data?.salesPersonId,
                salesPersonName: data?.salesPersonName
              } : item
            );

            const totalQuantity = response?.data?.totalPurchaseValue == prevData.myPurchaseData[barcodeMatchIndex].saleQty ? prevData.totalQuantity : prevData.totalQuantity + matchBarcode.MOQ;
            const totalBoxes = response?.data?.totalPurchaseValue == prevData.myPurchaseData[barcodeMatchIndex].saleQty ? prevData.totalBoxes : prevData.totalBoxes + 1;
            const totalAmount = response?.data?.totalPurchaseValue == prevData.myPurchaseData[barcodeMatchIndex].saleQty ? prevData.totalAmount : prevData.totalAmount + (prevData.myPurchaseData[barcodeMatchIndex].MOQ * prevData.myPurchaseData[barcodeMatchIndex].wsp);

            return {
              ...prevData,
              myPurchaseData: updatedMyPurchaseData,
              totalQuantity: totalQuantity,
              totalBoxes: totalBoxes,
              totalAmount: totalAmount,
              diabledStatus: false,
              // brand : "",
              // designNumber : "",
              // barcode : "",
              // brandId : ""
            };
          });
          // formRef.current.setFieldsValue({
          //   brand : undefined
          // })
        }
        else {
          message.error("Out Of Stock")
        }

      } else {
        setData((prevData) => {
          const updatedMyPurchaseData = [
            ...prevData.myPurchaseData,
            {
              ...matchBarcode,
              saleQty: matchBarcode?.MOQ,
              boxes: matchBarcode?.MOQ / matchBarcode?.MOQ,
              finalAmount: matchBarcode?.MOQ * matchBarcode?.wsp,
              salesPersonId: data?.salesPersonId,
              salesPersonName: data?.salesPersonName
            }
          ];

          const totalQuantity = prevData.totalQuantity + matchBarcode?.MOQ;
          const totalBoxes = prevData.totalBoxes + matchBarcode?.MOQ / matchBarcode?.MOQ;
          const totalAmount = prevData.totalAmount + (matchBarcode?.MOQ * matchBarcode?.wsp);
          return {
            ...prevData,
            myPurchaseData: updatedMyPurchaseData,
            totalQuantity: totalQuantity,
            totalBoxes: totalBoxes,
            totalAmount: totalAmount,
            diabledStatus: false,
            // brand : "",
            // designNumber : "",
            // barcode : "",
            // brandId : ""
          };
        });
        // formRef.current.setFieldsValue({
        //   brand : undefined
        // })
      }




      setData((prevData) => {

        let discountAmt      // const finalDiscAmt = prevData.totalAmount - discountAmt;
        let finalDiscAmt
        if (prevData?.billedGR === false) {
          discountAmt = prevData?.totalAmount * (prevData?.discount / 100);
          finalDiscAmt = prevData?.totalAmount - discountAmt;
        }
        else {
          discountAmt = (prevData.totalAmount - prevData.grAmount) * (prevData.discount / 100);
          finalDiscAmt = prevData?.totalAmount - discountAmt - prevData?.grAmount;
        }
        const taxAmt = finalDiscAmt * (prevData.tax / 100);
        const taxedAmount = finalDiscAmt + taxAmt;
        const NetAmount = prevData.pf + prevData.labour + taxedAmount;

        return {
          ...prevData,
          discountAmt: discountAmt,
          finalDiscAmt: finalDiscAmt,
          taxAmt: taxAmt,
          taxedAmount: taxedAmount,
          netAmount: NetAmount
        };
      });
    }
    else {
      message.error("Out of Stock")
    }
    setModalStatus(true)
  }


  const handleModalBarcode = async (barcode, result) => {


    let barcodeMatchIndex = data?.myPurchaseData?.findIndex(
      (text) => Number(barcode) == Number(text.barcode)
    );

    let matchBarcode = result?.find(
      (text) => Number(barcode) == Number(text?.barcode)
    );

    const response = await dispatch(
      getPurchaseByBarcodeSale({ barcode: Number(barcode) })
    );

    // Check if the response is valid and has stock
    if (
      response &&
      response?.status === 200 &&
      response?.data &&
      response?.data?.totalPurchaseValue > 0
    ) {
      if (barcodeMatchIndex !== -1 && barcodeMatchIndex !== undefined) {

        setData((prevData) => {
          const updatedMyPurchaseData = prevData?.myPurchaseData?.map((item, index) =>
            Number(item?.barcode) === Number(matchBarcode?.barcode)
              ? {
                ...item,
                // saleQty: item?.saleQty + matchBarcode?.MOQ,
                // boxes: (item?.saleQty + matchBarcode?.MOQ) / item?.MOQ,
                // finalAmount: (item?.saleQty + matchBarcode?.MOQ) * item?.wsp,
                saleQty: response?.data?.totalPurchaseValue == item?.saleQty ? item?.saleQty : item?.saleQty + matchBarcode?.MOQ,
                boxes: response?.data?.totalPurchaseValue == item?.saleQty ? item?.boxes : (item.saleQty + matchBarcode.MOQ) / item.MOQ,
                finalAmount: response?.data?.totalPurchaseValue == item?.saleQty ? item?.finalAmount : (item.saleQty + matchBarcode.MOQ) * item.wsp,
                salesPersonId: data?.salesPersonId,
                salesPersonName: data?.salesPersonName,
              }
              : item
          );

          const totalQuantity = prevData.totalQuantity + matchBarcode.MOQ;
          const totalBoxes = prevData.totalBoxes + 1;
          const totalAmount =
            prevData.totalAmount +
            matchBarcode.MOQ * matchBarcode.wsp;

          return {
            ...prevData,
            myPurchaseData: updatedMyPurchaseData,
            totalQuantity: totalQuantity,
            totalBoxes: totalBoxes,
            totalAmount: totalAmount,
            disabledStatus: false,

          };
        });

        formRef.current.setFieldsValue({
          brand: undefined,
        });
      } else {

        setData((prevData) => {
          const updatedMyPurchaseData = [
            ...prevData.myPurchaseData,
            {
              ...matchBarcode,
              saleQty: matchBarcode?.MOQ,
              boxes: matchBarcode?.MOQ / matchBarcode?.MOQ,
              finalAmount: matchBarcode?.MOQ * matchBarcode?.wsp,
              salesPersonId: data?.salesPersonId,
              salesPersonName: data?.salesPersonName,
            },
          ];

          const totalQuantity = prevData.totalQuantity + matchBarcode?.MOQ;
          const totalBoxes = prevData.totalBoxes + 1;
          const totalAmount = prevData.totalAmount + matchBarcode?.MOQ * matchBarcode?.wsp;

          return {
            ...prevData,
            myPurchaseData: updatedMyPurchaseData,
            totalQuantity: totalQuantity,
            totalBoxes: totalBoxes,
            totalAmount: totalAmount,
            disabledStatus: false,
          };
        });


      }

      // Update additional calculations based on myPurchaseData changes
      setData((prevData) => {
        // const discountAmt = prevData?.totalAmount * (prevData?.discount / 100);
        let finalDiscAmt;
        let discountAmt;
        if (prevData?.billedGR === false) {
          discountAmt = prevData?.totalAmount * (prevData?.discount / 100);

          finalDiscAmt = prevData?.totalAmount - discountAmt;
        }
        else {
          discountAmt = (prevData.totalAmount - prevData.grAmount) * (prevData.discount / 100);
          finalDiscAmt = prevData?.totalAmount - discountAmt - prevData?.grAmount;
        }
        const taxAmt = finalDiscAmt * (prevData.tax / 100);
        const taxedAmount = finalDiscAmt + taxAmt;
        const NetAmount =
          prevData.pf + prevData.labour + taxedAmount;

        return {
          ...prevData,
          discountAmt: discountAmt,
          finalDiscAmt: finalDiscAmt,
          taxAmt: taxAmt,
          taxedAmount: taxedAmount,
          netAmount: NetAmount,
        };
      });
    } else {
      setData(prevData => ({
        ...prevData,
        brand: "",
        designNumber: "",
        barcode: "",
        brandId: ""
      }));
      formRef.current.setFieldsValue({
        brand: undefined
      })

      // No stock found in the response
      message.error("Out of Stock");
    }
  };


  const onAddingBoxes = async (record) => {
    let findBarcode = data.myPurchaseData.find((data) => data.barcode === record.barcode)

    if (findBarcode !== undefined) {
      const response = await dispatch(getPurchaseByBarcodeSale({ barcode: record.barcode }));
      if (response && response.status === 200 && response.data) {
        if (response?.data?.totalPurchaseValue > 0 && response?.data?.totalPurchaseValue > findBarcode?.saleQty) {

          let barcodeMatchIndex = data?.myPurchaseData?.findIndex((text) => record?.barcode === text?.barcode);

          setData((prevData) => {
            const updatedMyPurchaseData = prevData?.myPurchaseData?.map((item, index) =>
              item?.barcode === record?.barcode ? {
                ...item,
                // saleQty: response?.data?.totalPurchaseValue == item?.saleQty ?  item?.saleQty : item?.saleQty + record?.MOQ ,
                // boxes: (item?.saleQty + record?.MOQ) / item?.MOQ,
                // finalAmount: (item?.saleQty + record?.MOQ) * item?.wsp,
                saleQty: response?.data?.totalPurchaseValue == item?.saleQty ? item?.saleQty : item?.saleQty + record?.MOQ,
                boxes: response?.data?.totalPurchaseValue == item?.saleQty ? item?.boxes : (item.saleQty + record.MOQ) / item.MOQ,
                finalAmount: response?.data?.totalPurchaseValue == item?.saleQty ? item?.finalAmount : (item.saleQty + record.MOQ) * item.wsp,
                salesPersonId: data?.salesPersonId,
                salesPersonName: data?.salesPersonName
              } : item
            );

            const totalQuantity = response?.data?.totalPurchaseValue == prevData.myPurchaseData[barcodeMatchIndex].saleQty ? prevData.totalQuantity : prevData.totalQuantity + record.MOQ;
            const totalBoxes = response?.data?.totalPurchaseValue == prevData.myPurchaseData[barcodeMatchIndex].saleQty ? prevData.totalBoxes : prevData.totalBoxes + 1;
            const totalAmount = response?.data?.totalPurchaseValue == prevData.myPurchaseData[barcodeMatchIndex].saleQty ? prevData.totalAmount : prevData.totalAmount + (prevData.myPurchaseData[barcodeMatchIndex].MOQ * prevData.myPurchaseData[barcodeMatchIndex].wsp);

            return {
              ...prevData,
              myPurchaseData: updatedMyPurchaseData,
              totalQuantity: totalQuantity,
              totalBoxes: totalBoxes,
              totalAmount: totalAmount,
            };

          });

          setData((prevData) => {
            //  const discountAmt = prevData.totalAmount * (prevData.discount / 100);
            let discountAmt;
            let finalDiscAmt
            if (prevData?.billedGR === false) {
              discountAmt = prevData?.totalAmount * (prevData?.discount / 100);

              finalDiscAmt = prevData?.totalAmount - discountAmt;
            }
            else {
              discountAmt = (prevData.totalAmount - prevData.grAmount) * (prevData.discount / 100);
              finalDiscAmt = prevData?.totalAmount - discountAmt - prevData?.grAmount;
            }
            const taxAmt = finalDiscAmt * (prevData.tax / 100);
            const taxedAmount = finalDiscAmt + taxAmt;
            const NetAmount = prevData.pf + prevData.labour + taxedAmount;
            return {
              ...prevData,
              discountAmt: discountAmt,
              finalDiscAmt: finalDiscAmt,
              taxAmt: taxAmt,
              taxedAmount: taxedAmount,
              netAmount: NetAmount
            };
          });
        }
        else {
          message.error("Max Quantity Reached");
        }
      }
    }

  }


  const onDeleteClick = (record) => {

    const filteredPurchaseData = data?.myPurchaseData?.filter(item => (item?.barcode !== record?.barcode));
    const deletedRecord = data?.myPurchaseData?.find(item => item?.barcode === record?.barcode);

    if (deletedRecord) {
      setData(prevData => {
        const updatedData = {
          ...prevData,
          myPurchaseData: filteredPurchaseData,
          totalBoxes: prevData.totalBoxes - deletedRecord?.boxes,
          totalQuantity: prevData.totalQuantity - deletedRecord?.saleQty,
          totalAmount: prevData.totalAmount - (deletedRecord?.saleQty * deletedRecord?.wsp)
        };

        return recalculateAmounts(updatedData);
      });
    }
  };


  const recalculateAmounts = (prevData) => {
    //  const discountAmt = prevData.totalAmount * (prevData.discount / 100);
    // const finalDiscAmt = prevData.totalAmount - discountAmt;
    // let finalDiscAmt
    // if (prevData.billedGR === false) {
    //   finalDiscAmt = prevData.totalAmount - discountAmt;
    // }
    // else {
    //   finalDiscAmt = prevData.totalAmount - discountAmt - prevData.grAmount;
    // }
    let discountAmt;
    let finalDiscAmt
    if (prevData?.billedGR === false) {
      discountAmt = prevData?.totalAmount * (prevData?.discount / 100);

      finalDiscAmt = prevData?.totalAmount - discountAmt;
    }
    else {
      discountAmt = (prevData.totalAmount - prevData.grAmount) * (prevData.discount / 100);
      finalDiscAmt = prevData?.totalAmount - discountAmt - prevData?.grAmount;
    }
    const taxAmt = finalDiscAmt * (prevData.tax / 100);
    const taxedAmount = finalDiscAmt + taxAmt;
    const NetAmount = prevData.pf + prevData.labour + taxedAmount;

    return {
      ...prevData,
      discountAmt: discountAmt,
      finalDiscAmt: finalDiscAmt,
      taxAmt: taxAmt,
      taxedAmount: taxedAmount,
      netAmount: NetAmount
    };
  };


  const onRemoveBoxes = (record) => {


    let barcodeMatchIndex = data?.myPurchaseData?.findIndex((text) => record?.barcode === text?.barcode);

    setData((prevData) => {
      const updatedMyPurchaseData = prevData?.myPurchaseData?.map((item, index) =>
        item?.barcode === record?.barcode ? {
          ...item,
          saleQty: record?.MOQ < item?.saleQty ? item?.saleQty - record?.MOQ : record?.MOQ,
          boxes: record?.MOQ < item.saleQty ? (item?.saleQty - record?.MOQ) / item?.MOQ : record?.MOQ / item?.MOQ,
          finalAmount: record?.MOQ < item?.saleQty ? (item?.saleQty - record?.MOQ) * item?.wsp : (item?.saleQty) * item?.wsp,
        } : item
      );

      if (record.MOQ < record.saleQty) {
        const totalQuantity = prevData.totalQuantity - record.MOQ;
        const totalBoxes = prevData.totalBoxes - 1;
        const totalAmount = prevData.totalAmount - (prevData.myPurchaseData[barcodeMatchIndex].MOQ * prevData.myPurchaseData[barcodeMatchIndex].wsp);

        return {
          ...prevData,
          myPurchaseData: updatedMyPurchaseData,
          totalQuantity: totalQuantity,
          totalBoxes: totalBoxes,
          totalAmount: totalAmount,
        };
      }
      else {
        const totalQuantity = prevData.totalQuantity
        const totalBoxes = prevData.totalBoxes
        const totalAmount = prevData.totalAmount

        return {
          ...prevData,
          myPurchaseData: updatedMyPurchaseData,
          totalQuantity: totalQuantity,
          totalBoxes: totalBoxes,
          totalAmount: totalAmount,
        };

      }
    });


    setData((prevData) => {
      let discountAmt;
      let finalDiscAmt
      if (prevData?.billedGR === false) {
        discountAmt = prevData?.totalAmount * (prevData?.discount / 100);

        finalDiscAmt = prevData?.totalAmount - discountAmt;
      }
      else {
        discountAmt = (prevData.totalAmount - prevData.grAmount) * (prevData.discount / 100);
        finalDiscAmt = prevData?.totalAmount - discountAmt - prevData?.grAmount;
      }
      //const finalDiscAmt = prevData.totalAmount - discountAmt;
      const taxAmt = finalDiscAmt * (prevData.tax / 100);
      const taxedAmount = finalDiscAmt + taxAmt;
      const NetAmount = prevData.pf + prevData.labour + taxedAmount;

      return {
        ...prevData,
        discountAmt: discountAmt,
        finalDiscAmt: finalDiscAmt,
        taxAmt: taxAmt,
        taxedAmount: taxedAmount,
        netAmount: NetAmount
      };
    });

  };

  const handleTaxChange = (e) => {
    const tax = e.target.value;
    // let discountAmt = data.totalAmount * (data.discount / 100)
    // let finalDiscAmt = data.totalAmount - discountAmt
    let discountAmt;
    let finalDiscAmt
    if (data?.billedGR === false) {
      discountAmt = data?.totalAmount * (data?.discount / 100);

      finalDiscAmt = data?.totalAmount - discountAmt;
    }
    else {
      discountAmt = (data.totalAmount - data.grAmount) * (data.discount / 100);
      finalDiscAmt = data?.totalAmount - discountAmt - data?.grAmount;
    }
    let taxAmt = finalDiscAmt * (tax / 100)
    let taxedAmount = finalDiscAmt + taxAmt
    let NetAmount = data.pf + data.labour + taxedAmount

    setData(prevData => ({
      ...prevData,
      tax: tax,
    }));


    if (data?.myPurchaseData?.length > 0) {
      setData(prevData => ({
        ...prevData,
        tax: tax,
        taxAmt: taxAmt,
        taxedAmount: taxedAmount,
        netAmount: NetAmount
      }));
    }
  }


  const handlePFChange = (e) => {
    let PF = Number(e.target.value);
    let NetAmount = Number(data.netAmount) - Number(data.pf) + PF;
    setData(prevData => ({
      ...prevData,
      pf: PF,
    }));
    if (data?.myPurchaseData?.length > 0) {
      setData(prevData => ({
        ...prevData,
        netAmount: NetAmount
      }));
    }
  }

  const handleLabourAmtChange = (e) => {
    let labourAmt = Number(e.target.value);
    let NetAmount = Number(data.netAmount) - Number(data.labour) + labourAmt;
    setData(prevData => ({
      ...prevData,
      labour: labourAmt,
    }));

    if (data?.myPurchaseData?.length > 0) {
      setData(prevData => ({
        ...prevData,
        netAmount: NetAmount
      }));
    }
  }


  const handlediscountChange = (e) => {
    let discount = e.target.value
    // let discountAmt = data.totalAmount * (discount / 100)
    // let finalDiscAmt = data.totalAmount - discountAmt
    let discountAmt;
    let finalDiscAmt
    if (data?.billedGR === false) {
      discountAmt = data?.totalAmount * (discount / 100);

      finalDiscAmt = data?.totalAmount - discountAmt;
    }
    else {
      console.log("data.totalAmount", data.totalAmount, data.grAmount);
      discountAmt = (data.totalAmount - data.grAmount) * (discount / 100);
      finalDiscAmt = data?.totalAmount - discountAmt - data?.grAmount;
    }
    let taxAmt = finalDiscAmt * (data.tax / 100)
    let taxedAmount = finalDiscAmt + taxAmt
    let NetAmount = data.pf + data.labour + taxedAmount

    setData((prevData) => ({
      ...prevData,
      discount: e.target.value,
    }));

    if (data?.myPurchaseData?.length > 0) {
      setData((prevData) => ({
        ...prevData,
        taxAmt: taxAmt,
        taxedAmount: taxedAmount,
        discount: e.target.value,
        discountAmt: discountAmt,
        finalDiscAmt: finalDiscAmt,
        netAmount: NetAmount
      }));
    }
  }

  const handleSaleOrder = async () => {
    let sales = []
    data.myPurchaseData.map((value) => {
      sales.push({ salesPersonName: value.salesPersonName, salesPersonId: value.salesPersonId, quantity: value.saleQty, MOQ: value.MOQ, barcode: value.barcode })
    })
    if (data?.myPurchaseData?.length > 0) {
      let obj = {
        totalQuantity: Number(data.totalQuantity),
        salesOrder: true,
        remark: data.remark,
        pAndf: Number(data.pf),
        labour: Number(data.labour),
        tax: Number(data.tax),
        discount: Number(data.discount),
        userId: data.customerId,
        netAmount: Number(data.netAmount),
        totalAmount: Number(data.totalAmount),
        totalBoxes: Number(data.totalBoxes),
        date: data.saleBillDate,
        isGrAmount: data.billedGR,
        sales: sales,
        srAmount: Number(data.grAmount),
      }
      let response = await dispatch(createSales(obj, history))
      if (response) {
        setData((prevData) => ({
          ...prevData,
          invoiceNumber: response.invoiceId,
          diabledStatus: true,
          myPurchaseData: [],
          saleDate: currentDate,
          saleTime: currentTime,
          saleBillDate: moment(`${currentDate} ${currentTime}`, 'DD/MM/YYYY HH:mm:ss').toDate(),
          customerName: "",
          customerId: "",
          agentId: "",
          agent: "",
          lrNumber: "",
          lrDate: "",
          remark: "",
          transport: "",
          grAmount: 0,
          barcode: "",
          designNumber: "",
          boxes: 0,
          totalBoxes: 0,
          totalQuantity: 0,
          totalAmount: 0,
          brand: "",
          brandId: "",

          selectedData: "",
          salesPersonId: "",
          salesPersonName: "",
          saleQty: 0,
          discount: 0,
          tax: 5,
          pf: 0,
          h: 0,
          billedGR: false,
          netAmount: 0,
          finalDiscAmt: 0,
          taxAmt: 0,
          taxedAmount: 0,
          discountAmt: 0,
          responseData: []
        }))

        formRef.current.setFieldsValue({
          salePerson: undefined,
          customer: undefined
        });
      }
      setModalStatus(false)
    }

  }

  const handleSubmit = async () => {
    let sales = []
    data.myPurchaseData.map((value) => {
      sales.push({ salesPersonName: value.salesPersonName, salesPersonId: value.salesPersonId, quantity: value.saleQty, MOQ: value.MOQ, barcode: value.barcode })
    })
    if (data?.myPurchaseData?.length > 0) {
      let obj = {
        totalQuantity: Number(data.totalQuantity),
        salesOrder: false,
        remark: data.remark,
        pAndf: Number(data.pf),
        labour: Number(data.labour),
        tax: Number(data.tax),
        discount: Number(data.discount),
        userId: data.customerId,
        netAmount: Number(data.netAmount),
        totalAmount: Number(data.totalAmount),
        totalBoxes: Number(data.totalBoxes),
        date: data.saleBillDate,
        isGrAmount: data.billedGR,
        sales: sales,
        srAmount: Number(data.grAmount),
      }
      let response = await dispatch(createSales(obj, history))
      if (response) {
        setData((prevData) => ({
          ...prevData,
          invoiceNumber: response.invoiceId,
          diabledStatus: true,
          myPurchaseData: [],
          saleDate: currentDate,
          saleTime: currentTime,
          saleBillDate: moment(`${currentDate} ${currentTime}`, 'YYYY-MM-DD HH:mm:ss'),
          customerName: "",
          customerId: "",
          agentId: "",
          agent: "",
          lrNumber: "",
          lrDate: "",
          remark: "",
          transport: "",
          grAmount: 0,
          barcode: "",
          designNumber: "",
          boxes: 0,
          totalBoxes: 0,
          totalQuantity: 0,
          totalAmount: 0,
          brand: "",
          brandId: "",

          selectedData: "",
          salesPersonId: "",
          salesPersonName: "",
          saleQty: 0,
          discount: 0,
          tax: 5,
          pf: 0,
          h: 0,
          billedGR: false,
          netAmount: 0,
          finalDiscAmt: 0,
          taxAmt: 0,
          taxedAmount: 0,
          discountAmt: 0,
          responseData: []
        }))

        formRef.current.setFieldsValue({
          salePerson: undefined,
          customer: undefined
        });
      }
      setModalStatus(false)
    }

  }


  // done
  const addDataonList = (record, response) => {
    let barcodeMatchIndex = data?.myPurchaseData?.findIndex((text) => record?.barcode === text?.barcode);
    if (barcodeMatchIndex !== -1) {
      setData((prevData) => {
        const updatedMyPurchaseData = prevData.myPurchaseData.map((item, index) =>
          index === barcodeMatchIndex ? {
            ...item,
            // saleQty: item.saleQty + record.MOQ,
            saleQty: response?.data?.totalPurchaseValue == item?.saleQty ? item?.saleQty : item?.saleQty + record?.MOQ,
            boxes: response?.data?.totalPurchaseValue == item?.saleQty ? item?.boxes : (item.saleQty + record.MOQ) / item.MOQ,
            finalAmount: response?.data?.totalPurchaseValue == item?.saleQty ? item?.finalAmount : (item.saleQty + record.MOQ) * item.wsp,
            salesPersonId: data.salesPersonId,
            salesPersonName: data.salesPersonName
          } : item
        );

        const totalQuantity = response?.data?.totalPurchaseValue == prevData.myPurchaseData[barcodeMatchIndex].saleQty ? prevData.totalQuantity : prevData.totalQuantity + record.MOQ;
        const totalBoxes = response?.data?.totalPurchaseValue == prevData.myPurchaseData[barcodeMatchIndex].saleQty ? prevData.totalBoxes : prevData.totalBoxes + 1;
        const totalAmount = response?.data?.totalPurchaseValue == prevData.myPurchaseData[barcodeMatchIndex].saleQty ? prevData.totalAmount : prevData.totalAmount + (prevData.myPurchaseData[barcodeMatchIndex].MOQ * prevData.myPurchaseData[barcodeMatchIndex].wsp);

        return {
          ...prevData,
          myPurchaseData: updatedMyPurchaseData,
          totalQuantity: totalQuantity,
          totalBoxes: totalBoxes,
          totalAmount: totalAmount,
          diabledStatus: false
        };
      });
    } else {
      setData((prevData) => {
        const updatedMyPurchaseData = [
          ...prevData.myPurchaseData,
          {
            ...record,
            saleQty: record.MOQ,
            boxes: record.MOQ / record.MOQ,
            finalAmount: record.MOQ * record.wsp,
            salesPersonId: data.salesPersonId,
            salesPersonName: data.salesPersonName
          }
        ];

        const totalQuantity = prevData.totalQuantity + record.MOQ;
        const totalBoxes = prevData.totalBoxes + record.MOQ / record.MOQ;
        const totalAmount = prevData.totalAmount + (record.MOQ * record.wsp);
        return {
          ...prevData,
          myPurchaseData: updatedMyPurchaseData,
          totalQuantity: totalQuantity,
          totalBoxes: totalBoxes,
          totalAmount: totalAmount,
          diabledStatus: false
        };
      });
    }

    setData((prevData) => {

      // const discountAmt = prevData.totalAmount * (prevData.discount / 100);
      // // const finalDiscAmt = prevData.totalAmount - discountAmt;
      // let finalDiscAmt
      // if (prevData.billedGR === false) {
      //   finalDiscAmt = prevData.totalAmount - discountAmt;
      // }
      // else {
      //   finalDiscAmt = prevData.totalAmount - discountAmt - prevData.grAmount;
      // }
      let discountAmt;
      let finalDiscAmt
      if (prevData?.billedGR === false) {
        discountAmt = prevData?.totalAmount * (prevData?.discount / 100);

        finalDiscAmt = prevData?.totalAmount - discountAmt;
      }
      else {
        discountAmt = (prevData.totalAmount - prevData.grAmount) * (prevData.discount / 100);
        finalDiscAmt = prevData?.totalAmount - discountAmt - prevData?.grAmount;
      }
      const taxAmt = finalDiscAmt * (prevData.tax / 100);
      const taxedAmount = finalDiscAmt + taxAmt;
      const NetAmount = prevData.pf + prevData.labour + taxedAmount;

      return {
        ...prevData,
        discountAmt: discountAmt,
        finalDiscAmt: finalDiscAmt,
        taxAmt: taxAmt,
        taxedAmount: taxedAmount,
        netAmount: NetAmount
      };
    });
  };

  //Done
  const handleChangeCustomer = (value, option) => {
    if (option) {
      setLoading(true);
      const getMatchCustomer = InactiveCustomer?.data?.find((data) => option.value === data._id.toString());
      if (getMatchCustomer) {
        if (getMatchCustomer.grAmount > 0) {
          //   const discountAmt =  (data.totalAmount - data.grAmount) * (data.discount / 100);
          // const finalDiscAmt = data.totalAmount - discountAmt - getMatchCustomer.grAmount;
          const discountAmt = (data.totalAmount - getMatchCustomer.grAmount) * (data.discount / 100);
          const finalDiscAmt = data?.totalAmount - discountAmt - getMatchCustomer?.grAmount;
          const taxAmt = finalDiscAmt * (data.tax / 100);
          const taxedAmount = finalDiscAmt + taxAmt;
          const NetAmount = data.pf + data.labour + taxedAmount;
          setIsCustomerSelect(true)
          setData((prevData) => ({
            ...prevData,
            customerId: option.value,
            customerName: option.children,
            agentId: getMatchCustomer?.agentId?._id,
            agent: getMatchCustomer?.agentId?.name,
            grAmount: getMatchCustomer?.grAmount,
            billedGR: true,
            discountAmt: discountAmt,
            taxAmt: taxAmt,
            taxedAmount: taxedAmount,
            netAmount: NetAmount,
            finalDiscAmt: finalDiscAmt
          }));
        }
        else {
          const discountAmt = data.totalAmount * (data.discount / 100);
          const finalDiscAmt = data.totalAmount - discountAmt
          const taxAmt = finalDiscAmt * (data.tax / 100);
          const taxedAmount = finalDiscAmt + taxAmt;
          const NetAmount = data.pf + data.labour + taxedAmount;

          setData((prevData) => ({
            ...prevData,
            customerId: option.value,
            customerName: option.children,
            agentId: getMatchCustomer?.agentId?._id,
            agent: getMatchCustomer?.agentId?.name,
            grAmount: getMatchCustomer?.grAmount,
            billedGR: false,
            discountAmt: discountAmt,
            taxAmt: taxAmt,
            taxedAmount: taxedAmount,
            netAmount: NetAmount,
            finalDiscAmt: finalDiscAmt
          }));
        }
        setIsCustomerSelect(false)
      }
      setLoading(false);
    }
  };

  const handleBrandChange = (value, option) => {
    const brandName = option.children;
    const brandId = option.value;
    setData((prevData) => ({
      ...prevData,
      brand: brandName,
      brandId: brandId,
    }));
  };

  // const handleScan = async (data) => {
  //   setData(prevData => ({ ...prevData, barcode: data }));
  //    if(data.length === 9) {
  //    const response = await dispatch(getdesignAndBrandComboByBarcode({ barcode: data }))
  //    if (response && response?.status == 200 && response?.data && response?.data?.data.length > 0) {
  //      setData((prevData) => ({
  //        ...prevData,
  //        designNumber: response?.data?.data[0].designNumber,
  //        brand: response?.data?.data[0].brand,
  //        brandId: response?.data?.data[0].brandId,
  //        responseData : response?.data?.data
  //      }));

  //      formRef.current.setFieldsValue({
  //        brand  : response?.data?.data[0].brand
  //      })
  //    }
  //    else {
  //      setData((prevData) => ({
  //        ...prevData,
  //        designNumber: "",
  //        brand: "",
  //        brandId: "",
  //        responseData : []
  //      }));

  //      formRef.current.setFieldsValue({
  //        brand  : undefined
  //      })
  //    }
  //  }
  // }


  const handleModalCancel = () => {
    setModalStatus(false)
  }
  const handleBarcodeModalStatus = () => {
    setIsBarcodeScanner(true)

  }

  const handleSalePerson = (value) => {
    if (value) {
      setLoading(true);
      const matchedSalesPerson = SalePersonName?.find(person => person._id === value);
      setData((prevData) => ({
        ...prevData,
        salesPersonId: value,
        salesPersonName: matchedSalesPerson ? matchedSalesPerson.name : ''
      }));
      setIsSalesPersonSelect(false)
      setLoading(false);
    }
  };


  //all done
  const handleBarcodeChange = async (e) => {
    let barcode = e.target.value
    setData((prevData) => ({
      ...prevData,
      barcode: barcode,
    }));

    setIsbarcodeEnter(false)
    if (barcode.length === 9) {
      const response = await dispatch(getdesignAndBrandComboByBarcode({ barcode: e.target.value }))
      if (response && response?.status == 200 && response?.data && response?.data?.data.length > 0) {
        setData((prevData) => ({
          ...prevData,
          designNumber: response?.data?.data[0].designNumber,
          brand: response?.data?.data[0].brand,
          brandId: response?.data?.data[0].brandId,
          responseData: response?.data?.data
        }));

        formRef.current.setFieldsValue({
          brand: response?.data?.data[0].brand
        })
      }
      else {
        setData((prevData) => ({
          ...prevData,
          designNumber: "",
          brand: "",
          brandId: "",
          responseData: []
        }));

        formRef.current.setFieldsValue({
          brand: undefined
        })
      }
    }
  }


  const handleDatePicker = (date, dateString, field) => {
    setData((prevData) => ({
      ...prevData,
      [field]: dateString,
    }));

  };

  const handleSaleDateChange = (date, dateString) => {
    let currentTime = moment().format('HH:mm:ss');
    let d = moment(dateString + ' ' + currentTime, 'DD/MM/YYYY HH:mm:ss').toDate();

    setData((prevData) => ({
      ...prevData,
      saleDate: dateString, saleTime: currentTime,
      saleBillDate: d
    }));
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="add-Sales">
      <div className="container-fluid">
        <Row className="title-row">
          <Col xs={18} sm={18} md={18} lg={20} xl={22}>
            <div className="page-header">Add New Sales</div>
          </Col>
          <Col xs={6} sm={4} md={4} lg={4} xl={2}>
            <Button
              type="primary"
              shape="round"

              className="save-modal text-white"
              onClick={() => {
                history.push('/dagaImpex/salesList', true);
              }}
            >
              <ArrowLeftOutlined /> Back
            </Button>
          </Col>
        </Row>
        <div className="card mt-4">
          <div className="sale-card-body">
            <Form onFinish={handleModalStatus} ref={formRef}>
              <Card title="Add Details" style={{ marginTop: "10px" }}>
                <Row gutter={16}>
                  <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                    <Form.Item name="InvoiceNumber" >
                      <Input name="InvoiceNumber" disabled placeholder='Invoice Number' value={data?.invoiceNumber} onChange={handleInputChange} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                    <Tooltip title="Select Bill Data">

                      <Form.Item name="billDate">
                        <DatePicker defaultValue={moment(data.saleBillDate)} placeholder='Select Bill Date' onChange={(date, dateString) => handleSaleDateChange(date, dateString, 'billDate')}
                        // format= "YYYY-MM-DD"
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                    <Tooltip title="Select Customer">
                      <Form.Item name="customer" rules={[{ required: true, message: 'Please Select Customer' }]} hasFeedback={!isCustomerSelect ? false : true}
                        validateStatus={!isCustomerSelect ? "" : "error"}
                        help={!isCustomerSelect ? "" : "Please Select Customer"}>
                        <Select
                          showSearch

                          placeholder="Select Customer"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }

                          onClick={() => { dispatch(getActiveandInactiveCustomer()) }}

                          onChange={handleChangeCustomer}
                          loading={loading}
                        >
                          {InactiveCustomer?.data?.map((customer) => (
                            <Option key={customer._id} value={customer._id}>
                              {customer.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                    <Form.Item >
                      <Input
                        id="agent"
                        name="agentName"
                        placeholder="Agent Name"
                        value={data?.agent}
                        defaultValue={data?.agent}
                        // onChange={handleChangeCustomer}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={8} xl={8}>

                    <Form.Item name="transport" >
                      <Input name="transport" disabled placeholder="Transport" value={data?.transport} onChange={handleInputChange} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                    <Tooltip title="Enter Remark">

                      <Form.Item>
                        <Input name="remark" placeholder='Enter Remark' value={data.remark} onChange={handleInputChange} />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                    <Tooltip title="Enter LR Number">

                      <Form.Item>
                        <Input name="lrNumber" placeholder='Enter LR Number' value={data.lrNumber} onChange={handleInputChange} />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                    <Tooltip title="Enter LR Date">

                      <Form.Item name="lrDate">
                        <DatePicker name="lrDate" placeholder='Select LR Date' onChange={(date, dateString) => handleDatePicker(date, dateString, 'lrDate')} />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                    <Tooltip title="Select Sale Person">

                      <Form.Item name="salePerson" rules={[{ required: true, message: 'Please Select Person' }]} hasFeedback={!isSalesPersonSelect ? false : true}
                        validateStatus={!isSalesPersonSelect ? "" : "error"}
                        help={!isSalesPersonSelect ? "" : "Please Select Person"}>
                        <Select name="salePerson" showSearch filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          } placeholder="Select Sale Person" onChange={handleSalePerson} onClick={() => { dispatch(getCustomers("SALES")) }} loading={loading}>
                          {SalePersonName?.map((saleperson) => (
                            <Option key={saleperson._id} value={saleperson._id}>
                              {saleperson.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                    <Tooltip title="Enter Barcode">
                      <Form.Item
                        name="barcode"
                        rules={[
                          {
                            required: data.barcode.length === 0 ? true : false,
                            message: 'Please Enter Barcode',
                          },
                        ]}
                        hasFeedback={!isbarcodeEnter ? false : true}
                        validateStatus={!isbarcodeEnter ? "" : "error"}
                        help={!isbarcodeEnter ? "" : "Please Enter Barcode"}
                      >
                        <Input
                          name="barcode"
                          placeholder="Enter Barcode"
                          value={data.barcode}
                          onChange={handleBarcodeChange}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={24} sm={11} md={11} lg={7} xl={8}>
                    <Tooltip title="Enter Design Number">
                      <Form.Item rules={[{ required: true, message: 'Please Enter Design Number' }]}>
                        <Input name="designNumber" value={data.designNumber} placeholder='Enter Design Number' onChange={handleInputChange} />
                      </Form.Item>
                    </Tooltip>
                  </Col>

                  <Col xs={24} sm={11} md={11} lg={7} xl={7}>
                    <Tooltip title="Select Brand">
                      <Form.Item name="brand" >
                        <Select
                          placeholder="Select Brand"
                          showSearch
                          name="brand"
                          //   defaultValue={data.brand}
                          //   value={data.brand}

                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                          onChange={handleBrandChange}
                          onClick={() => {
                            dispatch(getBrandBySearch())
                          }}
                        >

                          {brandNameSearch?.data?.map((brand) => (
                            <Option key={brand._id} name={brand._id} value={brand._id} >
                              {brand.name}
                            </Option>
                          ))
                          }

                        </Select>
                      </Form.Item>

                    </Tooltip>
                  </Col>

                  <Col xs={1} sm={1} md={1} lg={1} xl={1}>

                    <Button style={{ width: "40px", height: "32px" }} type='danger' icon={<PlusOutlined style={{ marginTop: "5px" }} />} disabled={data.diabledStatus} htmlType='submit'
                      className="save-button" onClick={() => {
                        if (data.salesPersonName === "") {
                          setIsSalesPersonSelect(true)
                        }
                        if (data.customerName === "") {
                          setIsCustomerSelect(true)
                        }
                        if (data.barcode === "") {
                          setIsbarcodeEnter(true)
                        }
                      }} onSubmit={handleModalStatus}></Button>
                  </Col>

                  <Col xs={24} sm={24} md={24} lg={24} xl={24} align="center" justify="center">
                    <Tooltip title="Barcode Scanner">
                      <Button onClick={() => {
                        if (data.salesPersonName === "") {
                          setIsSalesPersonSelect(true)
                        }

                        if (data.customerName === "") {
                          setIsCustomerSelect(true)
                        }

                        if (!data.salesPersonName == "" && !data.customerName == "") {
                          setIsBarcodeScanner(false)
                        }
                      }
                      } size='large'

                        style={{ borderRadius: "15px", alignItems: "center", justifyItems: "center", justifyContent: "center", width: "100px", height: "70px" }}> <ScanOutlined style={{
                          fontSize: '50px', display: "inline-flex", alignContent: "center",
                          justifyContent: "center",
                          alignItems: "center"
                        }} /> </Button>
                    </Tooltip>
                    {!isBarcodeScanner &&
                      <Modal open={!isBarcodeScanner}
                        width={500}


                        title="Scan Barcode"
                        onCancel={handleBarcodeModalStatus}
                        style={{ top: 20 }}
                        okButtonProps={{ hidden: true }}
                        cancelButtonProps={{ hidden: true }}
                        destroyOnClose={true}>
                        <div className='barcode-scanner'>

                          <BarcodeScanner
                            onError={onError}
                            height={300}
                            stopStream={stopStream}
                            onUpdate={onUpdateScreen}
                          />
                        </div>
                      </Modal >}
                  </Col>
                </Row>

              </Card>
              {modalStatus &&
                <Card title="Suggestion" style={{ marginTop: "20px" }}>
                  <Row gutter={16} >
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}> <NewLookUpModalSales myPurchaseData={data.myPurchaseData} modalStatus={modalStatus} addDataonList={addDataonList} />
                    </Col>
                  </Row>


                </Card>
              }
              <Card title="Products" style={{ marginTop: "20px" }}>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <ShowNewSalesDetails data={data.myPurchaseData} onAddingBoxes={onAddingBoxes} buttonDisable={data.diabledStatus} onRemoveBoxes={onRemoveBoxes} onDeleteClick={onDeleteClick} />
                  </Col>

                </Row>
              </Card>
              <Card title="Calcuation" style={{ marginTop: "20px" }}>
                <Row gutter={16} >

                  <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                    <Tooltip title="Total Boxes">
                      <Form.Item >
                        <Input
                          type='number'
                          placeholder="Total Boxes"
                          name="totalBoxes"
                          value={data.totalBoxes}
                          disabled
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                    <Tooltip title="Total Quantity">
                      <Form.Item >
                        <Input

                          placeholder="Total Quantity"
                          type="number"
                          name="totalQuantity"
                          value={data.totalQuantity}
                          disabled
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>


                  <Col xs={23} sm={11} md={10} lg={5} xl={5}>
                    <Tooltip title="GR Amount">
                      <Form.Item >
                        <Input
                          placeholder="GR Amount"
                          type="number"

                          name="grAmount"
                          value={data?.grAmount.toFixed(2)}
                          defaultValue={data?.grAmount.toFixed(2)}
                          disabled
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={1} sm={1} md={2} lg={1} xl={1}>
                    <Tooltip title="GR Amount Used Check">
                      <Form.Item>
                        <Checkbox
                          onChange={handleGrUsedCheck}
                          key={data.billedGR}
                          defaultChecked={data.billedGR}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                    <Tooltip title="Total Amount">
                      <Form.Item >
                        <Input
                          placeholder="Total Amount"
                          type="number"

                          name="totalAmount"
                          value={data?.totalAmount.toFixed(2)}
                          disabled
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                </Row>


                <Row gutter={16}>

                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Tooltip title="Enter Discount %">
                      <Form.Item >
                        <Input
                          placeholder="Enter Discount %"
                          name="discount"
                          type='number'
                          // formatter={(value) => `${value}%`}
                          value={data.discount}
                          //  defaultValue={data.discount}
                          onChange={handlediscountChange}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>


                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Tooltip title="Discount Amount">
                      <Form.Item >
                        <Input
                          type='number'
                          placeholder="Discount Amount"
                          name="DiscountAmt"
                          value={data?.discountAmt.toFixed(2)}
                          disabled

                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>


                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Tooltip title="Total Discounted Amount">
                      <Form.Item >
                        <Input
                          type='number'
                          placeholder="Total Discounted Amount"
                          name="finalDiscAmt"
                          value={data?.finalDiscAmt.toFixed(2)}
                          disabled
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                </Row>


                <Row gutter={16}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Tooltip title="Enter Tax %">
                      <Form.Item >
                        <Input
                          placeholder="Enter Tax %"
                          name="tax"
                          value={data?.tax}
                          type='number'
                          defaultValue={data?.tax}
                          onChange={handleTaxChange}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>


                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Tooltip title="Tax Amount">
                      <Form.Item >
                        <Input
                          type='number'
                          placeholder="Tax Amount"
                          name="taxAmt"
                          disabled
                          value={data?.taxAmt.toFixed(2)}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>


                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Tooltip title="Taxed Amount">
                      <Form.Item >
                        <Input
                          type='number'
                          placeholder="Taxed Amount"
                          name="finalAmt"
                          value={data?.taxedAmount.toFixed(2)}
                          disabled
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                </Row>
                <Row gutter={16}>

                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Tooltip title="P&F Amount">
                      <Form.Item >
                        <Input
                          type='number'
                          placeholder="P&F Amount"
                          name="P&F"
                          defaultValue={data?.pf.toFixed()}
                          onChange={(e) => { handlePFChange(e) }}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Tooltip title="Labour Amount">
                      <Form.Item >
                        <Input
                          type='number'
                          placeholder="Labour Amount"
                          name="labour"
                          defaultValue={data?.labour.toFixed()}
                          onChange={(e) => { handleLabourAmtChange(e) }}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Tooltip title="Net Amount">
                      <Form.Item >
                        <Input
                          type='number'
                          placeholder="Net Amount"
                          name="NetAmt"
                          disabled
                          value={data?.netAmount.toFixed(2)}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>

                </Row>
              </Card>
              <Row gutter={16} style={{ marginTop: "15px" }} align="center" justify="center">
                <Col >
                  <Form.Item>
                    {data.netAmount == "" || data.customerId == "" || data.netAmount <= 0
                      ? (
                        <Button
                          disabled
                          size='large'
                          type="primary"
                          className="save-button"
                          style={{ color: "#fff", borderRadius: "25px" }}
                          onClick={handleSubmit}
                        >
                          Save Bill <StopOutlined />
                        </Button>
                      ) : (
                        <Button
                          disabled={data.diabledStatus}
                          size='large'
                          type="primary"
                          className="save-button"
                          style={{ color: "#fff", borderRadius: "25px" }}
                          onClick={handleSubmit}
                        >
                          Save Bill
                        </Button>
                      )
                    }
                  </Form.Item>
                </Col>
                <Col >
                  <Form.Item>

                    {data.invoiceNumber ? (
                      <ViewGenerateBill invoiceId={data.invoiceNumber} buttonType="Add" />
                    ) :
                      (
                        <Button
                          className='generate-button'
                          block
                          disabled
                          size='large'
                          style={{ color: "#fff", background: "#fa5d25", borderRadius: "25px" }}

                        >
                          Generate Bill <StopOutlined />
                        </Button>
                      )
                    }
                  </Form.Item>
                </Col>
                <Col >
                  <Form.Item>
                    {data.netAmount == "" || data.customerId == "" || data.netAmount <= 0
                      ? (
                        <Button
                          disabled
                          size='large'
                          className="close-modal"

                          style={{ borderRadius: "25px" }}
                          onClick={handleSaleOrder}
                        >
                          Create Sale Order <StopOutlined />
                        </Button>
                      ) : (
                        <Button
                          disabled={data.diabledStatus}
                          size='large'
                          className="close-modal"

                          style={{ borderRadius: "25px" }}
                          onClick={handleSaleOrder}
                        >
                          Create Sale Order
                        </Button>
                      )
                    }
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>

      </div>
    </div>

  );
};

export default AddNewSales;
