import React, { Component } from "react";
import { Modal, Button, Form, Input, Upload, Select, Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { EditOutlined } from "@ant-design/icons";
import { updateLocation } from "../../../../redux/actions/locationAction";

class UpdateLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      name: "",
      locationName: "",
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onlocationTypeChange = this.onlocationTypeChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onSubmit(event) {
    let updateLocation = {
      name: this.state.name.trim(),
      _id: this.props.locations._id,
      locationType: this.state.locationType
    };

    this.props.updateLocation(updateLocation);

    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 2000);
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  onlocationTypeChange(Value) {
    if(Value == 1){
      this.setState({locationName: "Warehouse"})
    }
    else{
      this.setState({locationName: "Store"})
    }
    this.setState({ locationType: Value });
  }

  handleModalCancel = () => {
    this.setState({ visible: false });
  };
  shouldComponentUpdate(nextProps, prevState) {
    if (nextProps.locations._id != prevState._id) {
      this.setState({
        name: nextProps.locations.name,
        locationName:nextProps.locations.locationType == 1 ? "Warehouse" : "Store" ,
        _id: nextProps.locations._id,
      });
      return true;
    }
    return true;
  }
  render() {
    const { visible, loading } = this.state;

    return (
      <>
        <button
          className="btn"
          type="button"
          data-original-title=""
          onClick={this.showModal}
        >
          <Tooltip title="Update Location">
            <EditOutlined />
          </Tooltip>
        </button>

        <Modal
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleModalCancel}
          title="Update Location"
          style={{ top: 20 }}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
        >
          <Form
            name="add-Location"
            className="add-Location"
            onFinish={this.onSubmit}
            initialValues={{
              remember: true,
            }}
          >
            <Form.Item
              // name="name"
              rules={[
                {
                  required: true,
                  message: "Please Enter Location Name !",
                },
              ]}
              initialValue={this.state.name}
            >
              <Input
                autoFocus
                placeholder="Location Name"
                name="name"
                value={this.state.name}
                onChange={this.onChange}
              />
            </Form.Item>


            <Form.Item
              // name="LoactionType"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please Select Location Type!",
            //   },
            // ]}
            >  <Select
              name="LoactionType"
              placeholder="Location Type"
              value={this.state.locationName}
              onSelect={this.onlocationTypeChange} // Handle onChange event
            >
                <Select.Option value={1}>Warehouse</Select.Option>
                <Select.Option value={0}>Store</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item className="float-right">
              <Button
                className="close-modal mr-3"
                onClick={this.handleModalCancel}
              >
                Close
              </Button>
              {(() => {
                if (this.state.name == "") {
                  return (
                    <Button disabled type="primary" className="ok-modal">
                      Save
                    </Button>
                  );
                } else {
                  return (
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="ok-modal"
                      loading={loading}
                    >
                      Save
                    </Button>
                  );
                }
              })()}
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}
UpdateLocation.propTypes = {
  updateLocation: PropTypes.func.isRequired,
};

export default connect(null, { updateLocation })(UpdateLocation);