import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Table,
  Space,
  Input,
  Tag,
  Tooltip,
  Popconfirm,
  Switch,
} from "antd";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  DeleteOutlined,
  SearchOutlined,
  EditOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
  getAllUsers,
  deleteUser,
  userByNameOrUsername,
  activeAndInactiveCustomer,
} from "../../../redux/actions/userAction";
import UpdateUser from "./UpdateUser";
import Search from "../../../util/Search";
import TopBarProgress from "react-topbar-progress-indicator";
import ViewUserData from "./ViewUserData";
const { Column } = Table;
const searchOptions = ["Name", "UserName"];
class UsersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      options: [],
      searchValue: "",
    };
  }
  componentDidMount() {
    this.props.getAllUsers(1);
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  this.setState({
                    searchText: selectedKeys[0],
                    searchedColumn: dataIndex,
                  });
                }}
              >
                Filter
              </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  onActiveInactiveChange = (data, status) => {
    let requestObj = {
      userId: data._id,
      userStatus: data.status === "1" ? false : true,
    };
    this.props.activeAndInactiveCustomer(requestObj);
    setTimeout(() => {
      this.props.getAllUsers(1);
    }, 500);
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };
  onDeleteClick = async (id) => {
    await this.props.deleteUser(id);
    await this.props.getAllUsers(1);
  };
  onChange = (event) => {
    this.setState({ searchValue: event });
  };
  cancelState = () => {
    this.setState({ searchValue: "" });
  };
  handleSearchButtonClick = (value) => {
    let obj = {
      searchValue: this.state.searchValue,
      searchType: "USERLIST",
    };
    this.props.userByNameOrUsername(obj);

    // this.props.userByNameOrUsername(obj)
  };

  filterFun = (role) => {
    const { users, userByName } = this.props;
    const searchData = userByName || [];
    if (role === "ADMIN") {
      if (searchData && searchData.length > 0) {
        return userByName || [];
      } else {
        return users?.data;
      }
    }
    if (role === "SALES_ADMIN") {
      if (searchData && searchData.length > 0) {
        let searBarDataForSalesAdmin = searchData?.filter(
          (role) =>
            role?.role?.roleName !== "ADMIN" &&
            role?.role?.roleName !== "PURCHASE"
        );
        return searBarDataForSalesAdmin;
      } else {
        let userDataForSalesAdmin = users?.data?.filter(
          (role) =>
            role?.role?.roleName !== "ADMIN" &&
            role?.role?.roleName !== "PURCHASE"
        );
        return userDataForSalesAdmin;
      }
    }
    if (role === "SALES") {
      if (searchData && searchData.length > 0) {
        let searBarDataSALES = searchData?.filter(
          (role) =>
            role?.role?.roleName !== "ADMIN" &&
            role?.role?.roleName !== "PURCHASE"
        );
        return searBarDataSALES;
      } else {
        let userDataSALES = users?.data?.filter(
          (role) =>
            role?.role?.roleName !== "ADMIN" &&
            role?.role?.roleName !== "PURCHASE"
        );
        return userDataSALES;
      }
    }
    if (role === "PURCHASE_ADMIN") {
      if (searchData && searchData.length > 0) {
        let searBarDataPurchaseAdmin = searchData?.filter(
          (role) => role?.role?.roleName === "PURCHASE_ADMIN"
        );
        return searBarDataPurchaseAdmin;
      } else {
        let userDataPurchaseAdmin = users?.data?.filter(
          (role) => role?.role?.roleName === "PURCHASE_ADMIN"
        );
        return userDataPurchaseAdmin;
      }
    }
  };

  render() {
    const { users, userByName } = this.props;
    const roleName = localStorage.getItem("roleName");
    let myData = this.filterFun(roleName);

    return (
      <div className="subCategory-dashboard">
        {this.props.progressBarStatus && <TopBarProgress />}
        <div className="container-fluid category-list">
          <Row className="title-row">
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <div className="page-header">Users List</div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Search
              title="Users List"
              placeholder="Name or User Name"
              searchopt={searchOptions}
              cancelState={this.cancelState}
              changeFunction={this.onChange}
            />
          </Row>
          <div className="card mt-4 final-purchases-list">
            <div className="card-body">
              <Table
                // dataSource={searchValue ? searchData : users.data}
                dataSource={myData || []}
                bordered="true"
                size="middle"
                pagination={{
                  pageSize: 30,
                  total:
                    userByName && userByName.length > 0
                      ? 1
                      : this.props?.users?.count,
                  onChange: (page) => {
                    this.props.getAllUsers(page);
                  },
                }}
                onRow={(record, rowIndex) => ({
                  onClick: () => {},
                })}
                scroll={{ y: 240 }}
              >
                <Column
                  title="SRN"
                  key="index"
                  render={(value, item, index) => index + 1}
                  width={60}
                />
                <Column
                  title="Name"
                  dataIndex="name"
                  key="name"
                  width={140}
                  {...this.getColumnSearchProps("name")}
                />
                <Column
                  title="Username"
                  dataIndex="username"
                  key="username"
                  width={140}
                />
                <Column
                  title="Email"
                  dataIndex="email"
                  key="email"
                  {...this.getColumnSearchProps("email")}
                  width={140}
                />
                <Column
                  title="Role"
                  dataIndex="role"
                  key="role"
                  width={160}
                  // {...this.getColumnSearchProps("role")}
                  render={(role) => (
                    <>
                      {(() => {
                        if (role?.roleName === "ADMIN") {
                          return (
                            <Tag color="blue" key={role?.roleName}>
                              {role?.roleName}
                            </Tag>
                          );
                        } else if (role?.roleName === "PURCHASE_ADMIN") {
                          return (
                            <Tag color="geekblue" key={role?.roleName}>
                              {role?.roleName}
                            </Tag>
                          );
                        } else if (role?.roleName === "PURCHASE") {
                          return (
                            <Tag color="purple" key={role?.roleName}>
                              {role?.roleName}
                            </Tag>
                          );
                        } else if (role?.roleName === "SALES_ADMIN") {
                          return (
                            <Tag color="red" key={role?.roleName}>
                              {role?.roleName}
                            </Tag>
                          );
                        } else if (role?.roleName === "SALES") {
                          return (
                            <Tag color="volcano" key={role?.roleName}>
                              {role?.roleName}
                            </Tag>
                          );
                        } else if (role?.roleName === "USER") {
                          return (
                            <Tag color="green" key={role?.roleName}>
                              {role?.roleName}
                            </Tag>
                          );
                        } else if (role?.roleName === "STORE_USER") {
                          return (
                            <Tag color="gold" key={role?.roleName}>
                              {role?.roleName}
                            </Tag>
                          );
                        } else if (role?.roleName === "ACCOUNTS") {
                          return (
                            <Tag color="magenta" key={role?.roleName}>
                              {role?.roleName}
                            </Tag>
                          );
                        } else if (role?.roleName === "ECOMMERCE_SALES") {
                          return (
                            <Tag color="cyan" key={role?.roleName}>
                              {role?.roleName}
                            </Tag>
                          );
                        } else if (role?.roleName === "PURCHASER") {
                          return (
                            <Tag color="orange" key={role?.roleName}>
                              {role?.roleName}
                            </Tag>
                          );
                        } else if (role?.roleName === "SALES_PERSON") {
                          return (
                            <Tag color="yellow" key={role?.roleName}>
                              {role?.roleName}
                            </Tag>
                          );
                        } else if (role?.roleName === "DME") {
                          return (
                            <Tag color="lime" key={role?.roleName}>
                              {role?.roleName}
                            </Tag>
                          );
                        } else if (role?.roleName === "RECEPTION") {
                          return (
                            <Tag color="pink" key={role?.roleName}>
                              {role?.roleName}
                            </Tag>
                          );
                        } else if (role?.roleName === "CRM") {
                          return (
                            <Tag color="wheat" key={role?.roleName}>
                              {role?.roleName}
                            </Tag>
                          );
                        }
                        else if (role?.roleName === "MIS") {
                          return (
                            <Tag color="skyblue" key={role?.roleName}>
                              {role?.roleName}
                            </Tag>
                          );
                        }
                      })()}
                    </>
                  )}
                />
                <Column
                  title="Action"
                  key="action"
                  width={150}
                  render={(text, record) => (
                    <div>
                      <Row gutter={[8, 0]}>
                        {/* <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                          <a>
                            <ViewUserData user={text} />
                          </a>
                        </Col> */}
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                          <a>
                            <UpdateUser userData={text} />
                          </a>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                          <Popconfirm
                            title="Are you sure to delete this User?"
                            onConfirm={() => this.onDeleteClick(text._id)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <div className="btn">
                              <Tooltip title="Delete User">
                                <DeleteOutlined />
                              </Tooltip>
                            </div>
                          </Popconfirm>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                          {(() => {
                            if (text?.role?.roleName == "STORE_USER") {
                              return (
                                <div className="btn">
                                  <Tooltip title="Active/Inactive User">
                                    <Switch
                                      size="small"
                                      defaultChecked={
                                        text?.status == "1" ? true : false
                                      }
                                      onChange={() =>
                                        this.onActiveInactiveChange(
                                          text,
                                          text?.status == "1" ? 3 : 1
                                        )
                                      }
                                    />
                                  </Tooltip>
                                </div>
                              );
                            } else if (text?.role?.roleName == "USER") {
                              return (
                                <div className="btn">
                                  <Tooltip title="Active/Inactive User">
                                    <Switch
                                      size="small"
                                      defaultChecked={
                                        text?.status == "1" ? true : false
                                      }
                                      onChange={() =>
                                        this.onActiveInactiveChange(
                                          text,
                                          text?.status == "1" ? 3 : 1
                                        )
                                      }
                                    />
                                  </Tooltip>
                                </div>
                              );
                            }
                          })()}
                        </Col>
                      </Row>
                    </div>
                  )}
                />
              </Table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
UsersList.propTypes = {
  getAllUsers: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  userByNameOrUsername: PropTypes.func.isRequired,
  activeAndInactiveCustomer: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  users: state.userData.users,
  userByName: state.userData.userByName,
  progressBarStatus: state.progressBarReducer.showProgressBar,
});

export default connect(mapStateToProps, {
  getAllUsers,
  deleteUser,
  userByNameOrUsername,
  activeAndInactiveCustomer,
})(UsersList);
