import React from "react";
import ReactApexChart from "react-apexcharts";
import { Typography } from "antd";
import { MinusOutlined } from "@ant-design/icons";
// import LineChart from "./configs/lineChart";

function LineChart({ data }) {
  const { Title, Paragraph } = Typography;

  const categories = data?.salesData?.salesBillsData?.map(item => item._id);
  const seriesDataBoxes = data?.salesData?.salesBillsData?.map(item => item.totalBoxes);
  const seriesDataQuantity = data?.salesData?.salesBillsData?.map(item => item.totalQuantity);
  const seriesDataNetAmount = data?.salesData?.salesBillsData?.map(item => item.totalNetAmount);


  const LineChartData = {
    series: [
      {
        name: "Quantity",
        data: seriesDataQuantity,
        offsetY: 0,
      },
      {
        name: "Boxes",
        data: seriesDataBoxes,
        offsetY: 0,
      },
      // {
      //   name: "Net Amount",
      //   data: seriesDataNetAmount,
      //   offsetY: 0,
      // },
    ],

    options: {
      chart: {
        width: "100%",
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
      },

      legend: {
        show: false,
      },

      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },

      yaxis: {
        labels: {
          style: {
            fontSize: "14px",
            fontWeight: 600,
            colors: ["#8c8c8c"],
          },
        },
      },

      xaxis: {
        labels: {
          style: {
            fontSize: "14px",
            fontWeight: 600,
            colors: [
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
            ],
          },
        },
        categories: categories && categories.length > 0 ? categories : []
      },

      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    },
  };


  return (
    <>
      <div className="linechart">
        <div>
          <Title level={5}>Sales (Last 30 Days!)</Title>
          <Paragraph className="lastweek">
            than last week <span className="bnb2">+30%</span>
          </Paragraph>
        </div>
        <div className="sales">
          <ul>
            <li>{<MinusOutlined />} Quantity</li>
            <li>{<MinusOutlined />} Boxes</li>
            <li>{<MinusOutlined />} Net Amount</li>
          </ul>
        </div>
      </div>
      <div className="apex-linechart">
        <ReactApexChart
          className="full-width"
          options={LineChartData.options}
          series={LineChartData.series}
          type="area"
          height={350}
          width={"100%"}
        />
      </div>
    </>
  );
}

export default LineChart;