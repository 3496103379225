import React, { Component } from "react";
import { Row, Col, Tooltip, Form, Select, DatePicker, Button } from "antd";
import SalesCustomerReportList from "./SalesCustomerReportList";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { getCustomers } from "../../../../redux/actions/customerAction";
import { getCustomerSaleReports } from "../../../../redux/actions/reportsActions";
import TopBarProgress from "react-topbar-progress-indicator";
import { debounce } from "lodash";
import BackButton from "../../Report/Button";

const dateFormat = "YYYY-MM-DD";
const { Option } = Select;
class SalesCustomerReport extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      //   myPurchaseData: [],
      endDate: moment(new Date()).format("YYYY-MM-DD"),
      startDate: "",
      customerName: "",
      customerId: "",
      roleName: "",
      reportAllObject: {}
    };
    this.debouncedGetData = debounce(this.getData, 500);
  }
  getData = (name) => {
    if (name == "customer") this.props.getCustomers(this.state.roleName);
  };

  handleChangeCustomer = (key, value) => {
    let customerId = value.value;
    let customerName = value.name.name;
    this.setState({
      customerId,
      customerName,
    });
    if (this.state.roleName) {
      this.debouncedGetData("customer");
    }
  };
  onStartDateChange = (date, dateString) => {
    console.log("dateString", dateString);
    this.setState({ startDate: dateString });
  };
  onEndDateChange = (date, dateString) => {
    this.setState({ endDate: dateString });
  };
  showDataOnList = async () => {
    try {
      let today = moment(new Date()).format("YYYY-MM-DD");
      let reportObj = {};
      if (today == this.state.endDate) {
        reportObj = {
          userId: this.state.customerId,
          startDate: this.state.startDate,
          endDate: this.state.endDate + " " + moment().format("HH:mm:ss"),
          limit: 1,
          perPage: 30,
          type: "perpage",
        };
      } else {
        const dateMoment = moment(this.state.endDate, "YYYY-MM-DD");
        const endTime = dateMoment.endOf("day").format("HH:mm:ss");
        const endDateWithEndTime = `${this.state.endDate} ${endTime}`;
        reportObj = {
          userId: this.state.customerId,
          startDate: this.state.startDate,
          endDate: endDateWithEndTime,
          limit: 1,
          perPage: 30,
          type: "perpage",
        };
      }
      const initialReport = await this.props.getCustomerSaleReports(reportObj);
      console.log("Initial report data:", initialReport);
      console.log("reportobj", reportObj.endDate)
      const reportObjAll = {
        userId: this.state.customerId,
        startDate: this.state.startDate,
        endDate: reportObj.endDate,
        limit: 1,
        perPage: initialReport?.count,
        type: "all",
      };

      this.setState({ reportAllObject: reportObjAll })
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  handleCustomerRole = (key, value) => {
    this.setState({ roleName: value.value });
  };
  render() {
    let customerOptions = this.props.customers.map((customer) => {
      return (
        <Option name={customer} value={customer._id}>
          {customer.name}
        </Option>
      );
    });

    return (
      <div className="subCategory-dashboard">
        {console.log(
          "check moht progressbar data",
          this.props.progressBarStatus
        )}
        {this.props.progressBarStatus && <TopBarProgress />}
        <div className="container-fluid category-list">
          <Row className="title-row">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row gutter={24}>
                <Col span={12}>
                  <div className="page-header">Sales Customer Report</div>
                </Col>
                <Col span={12}>
                  <BackButton />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form
                ref={this.formRef}
                name="add-Purchase"
                className="add-Purchase"
                onFinish={this.showDataOnList}
                initialValues={{ remember: true }}
              >
                <Row gutter={[16, 0]} className="mt-4">
                  <Col xs={12} sm={12} md={5} lg={4} xl={4}>
                    <Select
                      style={{ width: "100%" }}
                      showSearch
                      placeholder="Select User"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      onChange={this.handleCustomerRole}
                      onClick={() => this.getData("customer")}
                      onInputKeyDown={() => this.getData("customer")}
                    >
                      {/* <Option value="USER">USER</Option> */}
                      <Option value="STORE_USER">STORE_USER</Option>
                    </Select>
                  </Col>
                  <Col xs={12} sm={12} md={5} lg={4} xl={4}>
                    <Tooltip title="Select Customer">
                      <Form.Item
                        name="customerName"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Customer Name !",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Select Customer"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                          onChange={this.handleChangeCustomer}
                          onClick={() => this.debouncedGetData("customer")}
                          onInputKeyDown={() =>
                            this.debouncedGetData("customer")
                          }
                        >
                          {customerOptions}
                        </Select>
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={12} sm={12} md={5} lg={4} xl={4}>
                    <Tooltip title="Select Start Date">
                      <Form.Item
                        name="startDate"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Start Date !",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          onChange={this.onStartDateChange}
                          placeholder="Select Start Date"
                          format={dateFormat}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={12} sm={12} md={5} lg={4} xl={4}>
                    <Tooltip title="Select End Date">
                      <Form.Item name="endDate">
                        <DatePicker
                          style={{ width: "100%" }}
                          onChange={this.onEndDateChange}
                          placeholder="Select End Date"
                          format={dateFormat}
                          defaultValue={moment()}
                          disabledDate={(current) => {
                            return current && current > Date.now();
                          }}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>

                  <Col xs={24} sm={24} md={4} lg={2} xl={2}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="close-modal"
                        block
                      >
                        Search
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <SalesCustomerReportList
            customerSaleReports={this.props.customerSaleReports}
            allcustomerSaleReports={this.props.allcustomerSaleReports}
            customerName={this.state.customerName}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            reportObjAll={this.state.reportAllObject}
            reportObj={{
              userId: this.state.customerId,
              startDate: this.state.startDate,
              endDate: this.state.endDate + " " + moment().format("HH:mm:ss"),
            }}
            getCustomerSaleReports={this.props.getCustomerSaleReports}
          />
        </div>
      </div>
    );
  }
}
SalesCustomerReport.propTypes = {
  getCustomers: PropTypes.func.isRequired,
  getCustomerSaleReports: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  customers: state.customerData.customers,
  customerSaleReports: state.reportData.customerSaleReports,
  allcustomerSaleReports: state.reportData.allcustomerSaleReports,
  progressBarStatus: state.progressBarReducer.showProgressBar,
});

export default connect(mapStateToProps, {
  getCustomers,
  getCustomerSaleReports,
})(SalesCustomerReport);