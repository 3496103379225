import React, { Component } from "react";
import { Row, Col, Tooltip, Form, Select, DatePicker, Button } from "antd";
import PurchaseReportList from "./PurchaseReportList";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import {
  getPurchaseReports,
  clearData,
} from "../../../../redux/actions/reportsActions";
import TopBarProgress from "react-topbar-progress-indicator";
import BackButton from "../../Report/Button";

const dateFormat = "YYYY-MM-DD";

class PurchaseReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      endDate: moment(new Date()).format("YYYY-MM-DD"),
      startDate: moment(new Date()).format("YYYY-MM-DD"),
      reportAllObject: {}
    };
  }

  componentDidMount = () => {
    this.showDataOnList();
  };
  onStartDateChange = (date, dateString) => {
    console.log("dateString", dateString);
    this.setState({ startDate: dateString });
  };
  onEndDateChange = (date, dateString) => {
    this.setState({ endDate: dateString });
  };

  showDataOnList = async () => {
    try {
      let today = moment(new Date()).format("YYYY-MM-DD");
      let reportObj = {};
      const limit = 1;

      if (today === this.state.endDate) {
        reportObj = {
          startDate: this.state.startDate,
          endDate: this.state.endDate + " " + moment().format("HH:mm:ss"),
          limit: limit,
          perPage: 30,
          type: "perpage",
        };
      } else {
        const dateMoment = moment(this.state.endDate, "YYYY-MM-DD");
        const endTime = dateMoment.endOf("day").format("HH:mm:ss");
        const endDateWithEndTime = `${this.state.endDate} ${endTime}`;
        reportObj = {
          userId: this.state.customerId,
          startDate: this.state.startDate,
          endDate: endDateWithEndTime,
          limit: limit,
          perPage: 30,
          type: "perpage",
        };
      }

      const initialReport = await this.props.getPurchaseReports(reportObj);
      console.log("Initial report data:", initialReport);

      const reportObjAll = {
        userId: this.state.customerId,
        startDate: this.state.startDate,
        endDate: reportObj.endDate,
        limit: limit,
        perPage: initialReport?.count,
        type: "all",
      };
      this.setState({reportAllObject : reportObjAll})
      // await this.props.getPurchaseReports(reportObjAll);
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  getQuarterlyDates = () => {
    var quarter = moment("2022-07-01").quarter();
    const startDate = moment()
      .quarter(quarter)
      .startOf("quarter")
      .format(dateFormat);
    const endDate = moment()
      .quarter(quarter)
      .endOf("quarter")
      .format(dateFormat);
    console.log("###getQuarterlyDates", startDate, endDate);
  };

  componentWillUnmount() {
    this.props.clearData(); // Dispatch clearData action when leaving the component
  }

  getFullYearDates = () => {
    var year = moment("2021-04-01").year();
    const startDate = moment().year(year).startOf("year").format(dateFormat);
    const endDate = moment().year(year).endOf("year").format(dateFormat);
    console.log("###getFullYearDates", startDate, endDate);
  };

  getHalfYearlyDates = () => {
    //issue only takes current yr
    var quarter = moment("2022-04-01").quarter();
    console.log("###quarter", quarter);
    if (quarter === 1 || quarter === 3) {
      let startDate = moment()
        .quarter(quarter)
        .startOf("quarter")
        .format(dateFormat);
      let endDate = moment()
        .quarter(quarter)
        .add(1, "quarter")
        .endOf("quarter")
        .format(dateFormat);

      console.log("###getHalfYearlyDates", startDate, endDate);
    } else if (quarter === 2 || quarter === 4) {
      let startDate = moment()
        .quarter(quarter)
        .subtract(1, "quarter")
        .startOf("quarter")
        .format(dateFormat);
      let endDate = moment()
        .quarter(quarter)
        .endOf("quarter")
        .format(dateFormat);

      console.log("###getHalfYearlyDates", startDate, endDate);
    }
  };
  render() {
    this.getQuarterlyDates();
    this.getFullYearDates();
    this.getHalfYearlyDates();
    return (
      <div className="subCategory-dashboard">
        {this.props.progressBarStatus && <TopBarProgress />}
        <div className="container-fluid category-list">
          <Row className="title-row">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row gutter={24}>
                <Col span={12}>
                  <div className="page-header">Purchase Bill Report</div>
                </Col>
                <Col span={12}>
                  <BackButton />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form
                name="add-Purchase"
                className="add-Purchase"
                onFinish={this.showDataOnList}
                initialValues={{ remember: true }}
              >
                <Row gutter={[16, 0]} className="mt-4">
                  <Col xs={12} sm={12} md={10} lg={6} xl={6}>
                    <Tooltip title="Select Start Date">
                      <Form.Item
                        name="startDate"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Start Date !",
                          },
                        ]}
                      >
                        <DatePicker
                          defaultValue={moment()}
                          style={{ width: "100%" }}
                          onChange={this.onStartDateChange}
                          placeholder="Select Start Date"
                          format={dateFormat}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={12} sm={12} md={10} lg={6} xl={6}>
                    <Tooltip title="Select End Date">
                      <Form.Item name="endDate">
                        <DatePicker
                          defaultValue={moment()}
                          disabledDate={(current) => {
                            return current && current > Date.now();
                          }}
                          style={{ width: "100%" }}
                          onChange={this.onEndDateChange}
                          placeholder="Select End Date"
                          format={dateFormat}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>

                  <Col xs={24} sm={24} md={4} lg={3} xl={3}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="close-modal"
                        block
                      >
                        Search
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <PurchaseReportList
            reports={this.props.reports}
            allreports={this.props.allreports}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            reportObj={{
              startDate: this.state.startDate,
              endDate: this.state.endDate,
            }}
            getPurchaseReports={this.props.getPurchaseReports}
            reportObjAll = {this.state.reportAllObject}
          />
        </div>
      </div>
    );
  }
}
PurchaseReport.propTypes = {
  getPurchaseReports: PropTypes.func.isRequired,
  clearData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  reports: state.reportData.reports,
  allreports: state.reportData.allreports,
  progressBarStatus: state.progressBarReducer.showProgressBar,
});

export default connect(mapStateToProps, {
  getPurchaseReports,
  clearData,
})(PurchaseReport);