import React from 'react'
import SampleBarcodeList from './SampleBarcodeList';
const SampleBarcode = () => {
  return (
    <div className="subCategory-dashboard">
      <div className="container-fluid category-list">
        <SampleBarcodeList />
      </div>
    </div>
  );
}

export default SampleBarcode