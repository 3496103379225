import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getStyleByCategory,
  getProductsByStyle,
} from "../../../redux/actions/styleAction";
import { fetchProductsByFiltereing, fetchAllProductsSidebarList, fetchFilterProductSidebarList } from "../../../redux/actions/productActions";

class MobileNavMenu extends Component {
  componentDidMount() {
    this.props.getStyleByCategory();
  }

  getProductByCategory = async (id) => {

    let obj = {
      category: [id],
      style: [],
      brand: [],
      color: [],
      size: []
    }


    let response = await this.props.fetchAllProductsSidebarList()
    console.log("response", response);
    let categories;
    if (response && response?.data && response?.data?.categoryDetails) {
      categories = response;
    }
    console.log("categories", categories);
    const params = new URLSearchParams(this.props.location.search);
    params.set('All', true);
    params.set('limit', 1);
    params.set('category', id);
    // Remove all other parameters
    params.delete('brand');
    params.delete('style');
    params.delete('color');
    params.delete('size');
    this.props.history.push({
      pathname: `/shop-grid-standard`,
      search: params.toString(),
      state: {
        stateData: "Category",
        selectTypeData: categories,
        loading: true
      }
    });

    await this.props.fetchProductsByFiltereing(obj)
    await this.props.fetchFilterProductSidebarList(obj)

  };

  getProductsByStyle = async (catId, styleId) => {

    let obj = {
      category: [catId],
      style: [styleId],
      brand: [],
      color: [],
      size: []
    }

    let response = await this.props.fetchFilterProductSidebarList({ category: obj.category })

    let styles;
    if (response && response?.data && response?.data?.data?.styleDetails) {
      styles = response?.data;
    }

    const params = new URLSearchParams(this.props.location.search);
    params.set('All', true);
    params.set('limit', 1);
    params.set('category', catId);
    params.set('style', styleId);
    // Remove all other parameters
    params.delete('brand');

    params.delete('color');
    params.delete('size');
    this.props.history.push({
      pathname: `/shop-grid-standard`,
      search: params.toString(),
      state: {
        stateData: "Style",
        selectTypeData: styles,
        loading: true
      }
    });
    await this.props.fetchProductsByFiltereing(obj)
    await this.props.fetchFilterProductSidebarList(obj)

  };

  render() {
    return (
      <nav className="offcanvas-navigation" id="offcanvas-navigation">
        <ul>
          <li>
            <Link to={process.env.PUBLIC_URL + "/"}>{"Home"}</Link>
          </li>
          {this.props.categStyles.map((category) => {
            if (category.status === "ON")
              return (
                <li className="menu-item-has-children" key={category._id}>
                  <Link
                    to={process.env.PUBLIC_URL + "/shop-grid-standard"}
                    onClick={() => this.getProductByCategory(category._id)}
                  >
                    {category.name}
                  </Link>
                  <ul className="sub-menu">
                    {category.styles?.map((style) => (
                      <li key={style._id}>
                        <ul>
                          <li className="mega-menu-title">
                            <Link
                              to={process.env.PUBLIC_URL + "/shop-grid-standard"}
                              onClick={() => this.getProductsByStyle(category._id, style._id)}
                            >
                              {style.name}
                            </Link>
                          </li>
                        </ul>
                      </li>
                    ))}
                  </ul>
                </li>
              );
          })}
          <li>
            <a data-scroll-nav="0" href="/dagaBrands">
              {"Brands"}
            </a>
          </li>
          <li>
            <a href="#contact">{"Contact Us"}</a>
          </li>
        </ul>
      </nav>
    );
  }
}

MobileNavMenu.propTypes = {
  getStyleByCategory: PropTypes.func.isRequired,
  fetchProductsByFiltereing: PropTypes.func.isRequired,
  categStyles: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  categStyles: state.styleData.categorystyles,
});

export default connect(mapStateToProps, {
  getStyleByCategory,
  fetchProductsByFiltereing,
  fetchAllProductsSidebarList,
  fetchFilterProductSidebarList
})(withRouter(MobileNavMenu));