import React, { Component } from "react";
import {
  Table,
  Button,
  Space,
  Input,
  Row,
  Col,
  Popconfirm,
  Tooltip,
  DatePicker,
  Form,
  message,
  Modal,
} from "antd";
import {
  FileDoneOutlined,
  SearchOutlined,
  EditOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getSalesList,
  getSaleUsingInvoiceId,
  getSalesListBydate,
  deleteSaleBill,
  getSaleProductByInvoiceId,
} from "../../../redux/actions/saleAction.js";
import Highlighter from "react-highlight-words";
import moment from "moment";
import ShowBillProducts from "./ShowBillProducts.js";
import Search from "../../../util/Search.js";
import TopBarProgress from "react-topbar-progress-indicator";
import DateWiseSearch from "../../../util/DateWiseSearch.js";
import ViewGenerateBill from "./ViewGenerateBill.js";
import VerifyBillProduct from "./VerifyBillProduct.js";
import { withRouter } from "react-router-dom";
import DownloadSalePDF from "./DownloadSalePDF.js";
import Papa from "papaparse";
const dateFormat = "YYYY-MM-DD";
const { Column } = Table;
const searchOptions = ["Barcode", "SalesId", "CustomerName", "agentName"];
class PurchaseList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      options: [],
      searchValue: "",
      startDate: moment(new Date()).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),
      totalCount: 0,
    };
  }
  componentDidMount() {
    const data = this.props.history;
    if (this.props.history.location.state) {
      let localStartDate = localStorage.getItem("startDate");
      let localEnddate = localStorage.getItem("endDate");
      if (localStartDate && localEnddate) {
        let today = moment(new Date()).format("YYYY-MM-DD");
        if (today == localEnddate) {
          let reportObj = {
            startDate: localStartDate,
            endDate: localEnddate + " " + moment().format("HH:mm:ss"),
          };
          this.props.getSalesListBydate(reportObj);
          localStorage.removeItem("startDate");
          localStorage.removeItem("endDate");
        } else {
          const dateMoment = moment(localEnddate, "YYYY-MM-DD");
          const endTime = dateMoment.endOf("day").format("HH:mm:ss");
          const endDateWithEndTime = `${localEnddate} ${endTime}`;
          let reportObj = {
            startDate: localStartDate,
            endDate: endDateWithEndTime,
          };
          this.props.getSalesListBydate(reportObj);
          localStorage.removeItem("startDate");
          localStorage.removeItem("endDate");
        }
      } else {
        let startDate = moment(new Date()).format("YYYY-MM-DD");
        let endDate = moment(new Date()).format("YYYY-MM-DD");
        let reportObj = {
          startDate: startDate,
          endDate: endDate + " " + moment().format("HH:mm:ss"),
        };
        this.props.getSalesListBydate(reportObj);
      }
    } else {
      localStorage.setItem(
        "startDate",
        moment(new Date()).format("YYYY-MM-DD")
      );
      localStorage.setItem("endDate", moment(new Date()).format("YYYY-MM-DD"));
      this.setState({
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
      });
      let startDate = moment(new Date()).format("YYYY-MM-DD");
      let endDate = moment(new Date()).format("YYYY-MM-DD");
      let reportObj = {
        startDate: startDate,
        endDate: endDate + " " + moment().format("HH:mm:ss"),
      };
      this.props.getSalesListBydate(reportObj);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.saleslistbydate.data !== this.props.saleslistbydate.data) {
      this.setState({ totalCount: this.props.saleslistbydate.count });
    }
  }

  componentWillUnmount() {
    this.setState({
      totalCount: 0,
      startDate: moment(new Date()).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),
    });
  }

  onStartDateChange = (date, dateString) => {
    this.setState({ startDate: dateString });
  };
  onEndDateChange = (date, dateString) => {
    this.setState({ endDate: dateString });
  };

  showDataOnList = () => {
    let reportObj = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
    };
    this.props.getSalesListBydate(1, reportObj);
  };

  success = () => {
    Modal.success({
      content: "All Products Are Verified Already!",
    });
  };

  headers = [
    { label: "Bill Date", key: "date" },
    { label: "Invoice No", key: "invoiceId" },
    { label: "Customer Name", key: "customer" },
    { label: "Agent Name", key: "agent" },
    { label: "Total Boxes", key: "totalBoxes" },
    { label: "Total Quantity", key: "totalQuantity" },
    { label: "Gr Amt", key: "grAmount" },
    { label: "Net Amount", key: "netAmount" },
    { label: "Remark", key: "remark" },
    { label: "Verified", key: "isVerified" },
    { label: "Updated Date", key: "updatedAt" },
  ];

  handleDownloadButtonClick = async () => {
    this.setState({ loading: true });

    try {
      // Call your API here
      let localStartDate = localStorage.getItem("startDate");
      let localEnddate = localStorage.getItem("endDate");
      let reportObj = {
        startDate: localStartDate,
        endDate: localEnddate + " " + moment().format("HH:mm:ss"),
      };
      const response = this.listData();

      const csvData = response;
      // Generate CSV content using PapaParse
      const csv = Papa.unparse({
        fields: this.headers?.map((header) => header.label),
        data: csvData?.map((row) =>
          this.headers?.map((header) => {
            if (header.key == "date") {
              return moment(row.date).format("YYYY-MM-DD");
            }
            if (header.key == "customer") {
              return row.userId?.name;
            }
            if (header.key == "agent") {
              return row.userId?.agentId?.name;
            }
            if (header.key == "grAmount") {
              return row.userId?.grAmount;
            }
            if (header.key == "updatedAt") {
              return moment(row.updatedAt).format("YYYY-MM-DD");
            }

            return row[header.key];
          })
        ),
      });
      // Create Blob and initiate file download
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${this.state?.startDate} to ${this.state?.endDate}.csv`;
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },

    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  resetfuntion = () => {
    let reportObj = {};
    this.props.getSalesListBydate(1, reportObj, "reset");
    this.setState({
      totalCount: 0,
      startDate: "",
      endDate: moment(new Date()).format("YYYY-MM-DD"),
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };

  updateBill = async (text) => {
    await this.props.getSaleUsingInvoiceId(text.invoiceId);
    // localStorage.setItem('startDate', this.state.state1);
    // localStorage.setItem('endDate', this.state.state2);
    this.props.history.push({
      // pathname: "/dagaImpex/updateNewSales",
      pathname: "/dagaImpex/updateSales",
      state: {
        id: text._id,
        saleObj: [text, this.props.salesOrders],
        updateBill: true,
        salesOrderChangeStatus: false,
      },
    });
  };

  // viewBill = async (text) => {
  // const response =  await this.props.getSaleUsingInvoiceId(text.invoiceId);
  //   if(response && response.status === 200) {
  //   return <ViewGenerateBill />
  //   }
  //   else {
  //     message.error("Data Not Found")
  //   }
  // }
  onChange = (event) => {
    this.setState({ searchValue: event });
  };
  cancelState = () => {
    this.setState({ searchValue: "" });
  };

  listData = () => {
    const { salesList, saleslistbydate, searchData } = this.props;
    const value =
      saleslistbydate && saleslistbydate.length > 0 ? saleslistbydate[0] : [];
    // if(value.message == "Empty" ){
    //   return([])
    // }else if(this.state.searchValue){
    //   return(searchData.data)
    // }else{
    //   return(saleslistbydate.data)
    // }
    if (this.state.searchValue) {
      let newFilterData = searchData?.details?.map((obj) => {
        return { ...obj, c_name: obj?.userId?.name ? obj.userId.name : "" };
      });
      const sortedArray = newFilterData?.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
      return sortedArray;
    } else {
      let newFilterData = saleslistbydate?.details?.map((obj) => {
        return { ...obj, c_name: obj?.userId?.name ? obj.userId.name : "" };
      });
      return newFilterData;
    }
  };

  // verfyProducts = (invoiceId) => {
  //   this.props.getSaleProductByInvoiceId(invoiceId)

  //   this.props.history.push({
  //     pathname: "/dagaImpex/verifyProducts"
  //   });

  // }

  deleteBill = (text) => {
    let obj = {
      invoiceId: text.invoiceId,
    };
    this.props
      .deleteSaleBill(obj)
      .then(() => {
        let localStartDate = localStorage.getItem("startDate");
        let localEnddate = localStorage.getItem("endDate");
        let reportObj = {
          startDate: localStartDate,
          endDate: localEnddate + " " + moment().format("HH:mm:ss"),
        };
        this.props.getSalesListBydate(reportObj);
      })
      .catch((error) => {
        console.error("Error deleting bill:", error);
      });
  };

  deleteBill = (text) => {
    let obj = {
      invoiceId: text.invoiceId,
    };
    this.props
      .deleteSaleBill(obj)
      .then(() => {
        let localStartDate = localStorage.getItem("startDate");
        let localEnddate = localStorage.getItem("endDate");
        let reportObj = {
          startDate: localStartDate,
          endDate: localEnddate + " " + moment().format("HH:mm:ss"),
        };
        this.props.getSalesListBydate(reportObj);
      })
      .catch((error) => {
        console.error("Error deleting bill:", error);
      });
  };

  render() {
    const { searchValue } = this.state;
    const { salesList, searchData, saleslistbydate } = this.props;
    const search = searchData || [];
    const Id = localStorage.getItem("roleName");

    return (
      <div className="subCategory-dashboard">
        {this.props.progressBarStatus && <TopBarProgress />}
        <div className="container-fluid category-list">
          {/* ==============Search Bar and Date Filter================= */}
          <Row className="title-row">
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <div className="page-header">Sales Bills</div>
            </Col>
          </Row>
          <Row gutter={16} className="mt-4">
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <Search
                title="Sales Bills"
                placeholder="Sale Return Id, Barcode, Agent Name or CustomerName"
                searchopt={searchOptions}
                changeFunction={this.onChange}
                cancelState={this.cancelState}
                location={this.props.history.location}
              />
              {/* <Col xs={2} sm={2} md={2} lg={2} xl={2}><Button>Detail Search</Button> </Col> */}
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <DateWiseSearch
                keyId={"Sales Bills"}
                propsHistory={this.props.history}
              />
            </Col>
          </Row>
          {/* ======================================== */}
          <div className="card">
            <div className="card-body">
              <Table
                dataSource={this.listData()}
                bordered="true"
                size="middle"
                // pagination={false}
                pagination={{
                  pageSize: 100,
                  total:
                    searchData && searchData.details
                      ? searchData.details.length
                      : saleslistbydate && saleslistbydate.details
                      ? saleslistbydate.details.length
                      : 0,
                  // onChange: (page) => {
                  //   this.props.getSalesList(page);
                  // },
                }}
                scroll={{ y: 400 }}
                summary={() => (
                  <Table.Summary fixed={"bottom"}>
                    <Table.Summary.Row>
                      <Table.Summary.Cell
                        className="grand-Total-summary"
                        index={1}
                        colSpan={2}
                      >
                        Grand Total
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4} colSpan={1}>
                        {searchData && searchData.details
                          ? searchData.details.length
                          : saleslistbydate && saleslistbydate.details
                          ? saleslistbydate.details.length
                          : ""}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4} colSpan={2}>
                        {}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4} colSpan={1}>
                        {" "}
                        {searchData &&
                        searchData.sum &&
                        searchData.sum.totalBoxes
                          ? searchData.sum.totalBoxes
                          : saleslistbydate &&
                            saleslistbydate.sum &&
                            saleslistbydate.sum.totalBoxes
                          ? saleslistbydate.sum.totalBoxes
                          : ""}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4} colSpan={1}>
                        {" "}
                        {searchData && searchData.sum && searchData.sum.quantity
                          ? searchData.sum.quantity
                          : saleslistbydate &&
                            saleslistbydate.sum &&
                            saleslistbydate.sum.quantity
                          ? saleslistbydate.sum.quantity
                          : ""}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4} colSpan={1}>
                        {searchData &&
                        searchData.sum &&
                        searchData.sum.totalAmount
                          ? Number(searchData.sum.totalAmount).toFixed()
                          : saleslistbydate &&
                            saleslistbydate.sum &&
                            saleslistbydate.sum.totalAmount
                          ? Number(saleslistbydate.sum.totalAmount).toFixed()
                          : ""}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4} colSpan={1}>
                        {}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={4} colSpan={1}>
                        {searchData &&
                        searchData.sum &&
                        searchData.sum.netAmount
                          ? Number(searchData.sum.netAmount).toFixed()
                          : saleslistbydate &&
                            saleslistbydate.sum &&
                            saleslistbydate.sum.netAmount
                          ? Number(saleslistbydate.sum.netAmount).toFixed()
                          : ""}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                )}
                // antd site header height
              >
                <Column
                  title="SRN"
                  key="index"
                  render={(value, item, index) => index + 1}
                  width={60}
                />
                <Column
                  title="Bill Date"
                  dataIndex="date"
                  key="date"
                  width={210}
                  render={(value, item, index) =>
                    `${moment(item.date).format("YYYY-MM-DD")} (${moment(
                      item.date
                    ).format("HH:mm:ss")} )`
                  }
                />

                <Column
                  title="Invoice No."
                  dataIndex="invoiceId"
                  key="invoiceId"
                  width={150}
                  // {...this.getColumnSearchProps("invoiceId")}
                  render={(value, item, index) => {
                    if (item && item.invoiceId) {
                      return `S-${item.invoiceId}`;
                    }
                  }}
                />
                <Column
                  title="Customer Name"
                  dataIndex="c_name"
                  key="c_name"
                  {...this.getColumnSearchProps("c_name")}
                  // render={(value, item, index) => `${item && item.userId && item.userId.name ? item.userId.name : ""}`}
                  width={190}
                />
                <Column
                  title="Agent Name"
                  dataIndex="userId"
                  key="userId"
                  render={(value, item, index) =>
                    `${
                      item?.userId?.agentId ? item?.userId?.agentId?.name : ""
                    }`
                  }
                  width={170}
                />
                <Column
                  title="Total Boxes"
                  dataIndex="totalBoxes"
                  key="totalBoxes"
                  width={120}
                />
                <Column
                  title="Total Qnty"
                  dataIndex="totalQuantity"
                  key="totalQuantity"
                  width={120}
                />
                <Column
                  title="Total Amt"
                  dataIndex="totalAmount"
                  key="totalAmount"
                  render={(value, item, index) =>
                    parseInt(item.totalAmount).toFixed()
                  }
                  width={100}
                />
                <Column
                  title="Gr Amt"
                  dataIndex="grAmountUsed"
                  key="grAmountUsed"
                  render={(value, item, index) =>
                    Number(item.grAmountUsed).toFixed()
                  }
                  // render={(value, item, index) => `${item.userId.grAmount.toFixed()}`}
                  width={100}
                />
                <Column
                  title="Net Amt"
                  dataIndex="netAmount"
                  key="netAmount"
                  width={120}
                  render={(value, item, index) =>
                    parseInt(item.netAmount).toFixed()
                  }
                />
                <Column
                  title="Remark"
                  dataIndex="remark"
                  key="remark"
                  width={120}
                />
                <Column
                  title="Verified"
                  dataIndex="isVerified"
                  key="isVerified"
                  width={120}
                  render={(value, item, index) => {
                    return `${item.isVerified}`;
                  }}
                />
                <Column
                  title="Updated Date"
                  dataIndex="updatedAt"
                  key="updatedAt"
                  width={210}
                  render={(value, item, index) => {
                    if (item && item.updatedAt) {
                      return `${moment(item.updatedAt).format(
                        "YYYY-MM-DD"
                      )} (${moment(item.updatedAt).format("HH:mm:ss")} )`;
                    }
                  }}
                />
                <Column
                  title="Action"
                  key="action"
                  width={240}
                  fixed="right"
                  render={(text, record) => {
                    if (
                      Id === "PURCHASE_ADMIN" ||
                      Id === "ACCOUNTS" ||
                      Id === "RECEPTION" ||
                      Id === "SALES_PERSON" ||
                      Id === "DME"
                    ) {
                      return (
                        <Row>
                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <a>
                              <ShowBillProducts
                                invoiceId={record?.invoiceId}
                                data={record}
                              />
                            </a>
                          </Col>
                        </Row>
                      );
                    } else if (Id === "CRM") {
                      return (
                        <Row>
                          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <a>
                              <ShowBillProducts
                                invoiceId={record?.invoiceId}
                                data={record}
                              />
                            </a>
                          </Col>
                          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <a>
                              <ViewGenerateBill
                                text={text}
                                record={record}
                                saleObj={this.props.salesOrders}
                              />
                            </a>
                          </Col>
                        </Row>
                      );
                    } else if (Id === "ADMIN" || Id === "SALES_ADMIN") {
                      return (
                        <Row gutter={40}>
                          <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                            <a>
                              <ShowBillProducts
                                invoiceId={record.invoiceId}
                                data={record}
                              />
                            </a>
                          </Col>
                          <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                            <ViewGenerateBill
                              text={text}
                              record={record}
                              saleObj={this.props.salesOrders}
                            />
                          </Col>
                          <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                            <Popconfirm
                              title="Are you sure to Edit this bill?"
                              onConfirm={() => this.updateBill(text)}
                              okText="Yes"
                              cancelText="No"
                              disabled={
                                record?.remark === "Bill Deleted."
                                  ? true
                                  : false
                              }
                            >
                              <div className="btn">
                                <Tooltip title="Edit Bill">
                                  <EditOutlined />
                                </Tooltip>
                              </div>
                            </Popconfirm>
                          </Col>
                          <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                            <a>
                              <VerifyBillProduct
                                invoiceData={[{ invoiceId: record.invoiceId }]}
                                record={record}
                                alreadyVerify={this.success}
                              />
                            </a>
                          </Col>

                          <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                            <Popconfirm
                              title="Are you sure to delete this bill?"
                              onConfirm={() => this.deleteBill(text)}
                              okText="Yes"
                              cancelText="No"
                              disabled={
                                record?.remark === "Bill Deleted."
                                  ? true
                                  : false
                              }
                            >
                              <div className="btn">
                                <Tooltip title="delete Bill">
                                  <DeleteOutlined />
                                </Tooltip>
                              </div>
                            </Popconfirm>
                          </Col>
                        </Row>
                      );
                    } else {
                      return (
                        <Row>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <a>
                              <ShowBillProducts
                                invoiceId={record.invoiceId}
                                data={record}
                              />
                            </a>
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <ViewGenerateBill
                              text={text}
                              record={record}
                              saleObj={this.props.salesOrders}
                            />
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Popconfirm
                              title="Are you sure to Edit this bill?"
                              onConfirm={() => this.updateBill(text)}
                              okText="Yes"
                              cancelText="No"
                              disabled={
                                record?.remark === "Bill Deleted."
                                  ? true
                                  : false
                              }
                            >
                              <div className="btn">
                                <Tooltip title="Edit Bill">
                                  <EditOutlined />
                                </Tooltip>
                              </div>
                            </Popconfirm>
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <a>
                              <VerifyBillProduct
                                invoiceData={[{ invoiceId: record.invoiceId }]}
                                record={record}
                                alreadyVerify={this.success}
                              />
                            </a>
                          </Col>
                        </Row>
                      );
                    }
                  }}
                />
                {(Id === "ADMIN" || Id === "CRM") && (
                  <Column
                    title="Send PDF"
                    key="updatedAt"
                    width={210}
                    fixed="right"
                    render={(text, record) => {
                      return (
                        <Row>
                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <DownloadSalePDF
                              invoiceId={record.invoiceId}
                              record={record}
                              disabled={
                                record?.remark === "Bill Deleted."
                                  ? true
                                  : false
                              }
                            />
                          </Col>
                        </Row>
                      );
                    }}
                  />
                )}
              </Table>
            </div>
          </div>
        </div>
        {(() => {
          if (this.props?.saleslistbydate?.details?.length > 0) {
            return (
              <>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="save-modal mt-4"
                  block
                  onClick={this.handleDownloadButtonClick}
                >
                  {this.state.loading ? "Loading..." : "Download CSV"}
                </Button>
              </>
            );
          }
        })()}
      </div>
    );
  }
}

PurchaseList.propTypes = {
  getSalesList: PropTypes.func.isRequired,
  getSalesListBydate: PropTypes.func.isRequired,
  getSaleUsingInvoiceId: PropTypes.func.isRequired,
  getSaleProductByInvoiceId: PropTypes.func.isRequired,
  deleteSaleBill: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  salesList: state.saleData.salesList,
  saleslistbydate: state.saleData.saleslistbydate,
  salesOrders: state.saleData.salesOrders,
  searchData: state.saleData.saleSearchData,
  progressBarStatus: state.progressBarReducer.showProgressBar,
});

export default withRouter(
  connect(mapStateToProps, {
    getSalesList,
    getSalesListBydate,
    getSaleUsingInvoiceId,
    getSaleProductByInvoiceId,
    deleteSaleBill,
  })(PurchaseList)
);
