/* /////////////////////////////////////// */
/* 
Date              created by               Modifications

06-07-2023        Mohit                    onSaleChange function modified,  Api Response Change so make my own response done all changes
*/
/* /////////////////////////////////////// */



import React, { Component } from "react";
import {
  Table,
  Switch,
  Pagination,
  Button,
  Space,
  Input,
  Row,
  Col,
  Tooltip,
  Tag,
  Result,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getUniquePurchases,
  ecomSales,
  addVisiblityOnEcom,
  getBrandAndDesignCombination
} from "../../../redux/actions/purchaseAction.js";
import Highlighter from "react-highlight-words";
import AddImage from "./AddImage.js";
import Search from "../../../util/Search.js";
import TopBarProgress from "react-topbar-progress-indicator";
import ShowProducts from "./ShowProducts.js";
import { getBrands } from "../../../redux/actions/brandAction"

// const { Search } = Input
const { Column } = Table;
const searchOptions = ["Brand", "Barcode"]
class EcomProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      options: [],
      searchValue: "",
      changetogale: true
    };
  }
  componentDidMount() {
    this.props.getBrandAndDesignCombination(1)
    this.props.getBrands()
    // this.props.getUniquePurchases(1, "productList");
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };

  onChange = (event) => {
    this.setState({ searchValue: event });
  };
  cancelState = () => {
    this.setState({ searchValue: "" });
  }

  onSaleChange = (barcode, text, tableData) => {
    let matchingArray = text.filter(obj => obj[0].barcode == barcode)
    if (tableData.ecommProductVisible == 'ON') {
      let objarray = matchingArray[0].map(obj => ({ _id: obj._id, barcode: obj.barcode, status: "OFF" }))
      // let saleStatusChange = {

      //   barcode,
      //   status: "OFF",
      // };
      // console.log("off", saleStatusChange)
      this.props.addVisiblityOnEcom({ toggleData: objarray })
      this.props.getUniquePurchases(1, "productList");
    } else {
      let objarray = matchingArray[0].map(obj => ({ _id: obj._id, barcode: obj.barcode, status: "ON" }))
      // let saleStatusChange = {

      //   barcode,
      //   status: "ON",

      // };
      // console.log("on", saleStatusChange)
      this.props.addVisiblityOnEcom({ toggleData: objarray })
      this.props.getUniquePurchases(1, "productList");
    }
    // this.props.getUniquePurchases(1, "productList");
    this.setState({ changetogale: !this.state.changetogale })
  }
  render() {
    let brands = this.props?.brands?.map(obj => obj.name)

    // const { searchValue } = this.state;
    // const { ecomSalesPurchases, searchData } = this.props
    // const newsalesArray = ecomSalesPurchases ? ecomSalesPurchases.map(item => item.data[0]).sort((a, b) => parseInt(a.barcode) - parseInt(b.barcode)) : []
    // const newToggalArray = ecomSalesPurchases ? ecomSalesPurchases.map(item => item.data) : []
    // const search = searchData || []


    const { searchValue } = this.state;
    const { ecomSalesPurchases, searchData } = this.props
    const newsalesArray = []
    const newToggalArray = []
    const search = searchData || []

    return (
      <div >
        {console.log("check brands", brands)}
        {this.props.progressBarStatus && <TopBarProgress />}
        <Row className="title-row">
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <div className="page-header">E-COM PRODUCTS</div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Search title="E-COM PRODUCTS"
            placeholder="Enter Design No"
            searchopt={brands}
            cancelState={this.cancelState}
            changeFunction={this.onChange} />
        </Row>
        <div className="card mt-4 final-purchases-list">
          <div className="card-body">
            <Table
              // dataSource={searchValue ? search : newsalesArray}
              dataSource={this.props?.brandanddesignNo?.data}
              bordered="true"
              size="middle"
              scroll={{ y: 240 }}
              pagination={{
                pageSize: 30,
                total: this.props?.brandanddesignNo?.totalCount,
                onChange: (page) => {
                  this.props.getBrandAndDesignCombination(page)
                },
                showSizeChanger: false
              }}
            >
              <Column
                title="SRN"
                key="index"
                render={(value, item, index) => index + 1}
                width={60}
              />
              <Column
                title="Design No."
                dataIndex="_id"
                key="_id"
                width={150}
                render={(value, item, index) => item._id.designNumber}
              // {...this.getColumnSearchProps("designNumber")}
              />
              <Column
                title="Brand"
                dataIndex="_id"
                key="_id"
                render={(value, item, index) => item._id.brand}
                // {...this.getColumnSearchProps("brand")}
                width={120}
              />
              {/* <Column
                title="Size"
                dataIndex="size"
                key="size"
                width={120}
              />
              <Column
                title="Color"
                dataIndex="color"
                key="color"
                width={120}
              /> */}
              {/* <Column
                title="Style"
                dataIndex="style"
                key="style"
                width={120}
              />
              <Column
                title="Barcode"
                dataIndex="barcode"
                key="barcode"
                {...this.getColumnSearchProps("barcode")}
                width={120}
              /> */}
              {/* <Column
                title="Action"
                key="action"
                width={90}
                render={(text, record) => (
                  
                  <>
                    {(() => {
                      return (
                        <Row gutter={[8, 0]}>
                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                           <AddImage details={text}/>
                          </Col>
                        </Row>)


                    })()}
                  </>
                )
              }
              /> */}
              <Column
                title="Action"
                key="action"
                width={90}
                render={(text, record) => (
                  <Row gutter={[8, 0]} style={{ placeContent: "center" }}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <ShowProducts obj={text._id} />
                    </Col>

                    {/* {(() => {
                      return (
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <div className="btn">
                            <Tooltip title="Change Status">
                              <Switch
                                size="large"
                                checked={text.ecommProductVisible == "OFF" ? false : true}
                                onChange={() => this.onSaleChange(text.barcode, newToggalArray, text)}
                              />
                            </Tooltip>
                          </div>
                        </Col>
                      );
                    })()} */}


                  </Row>
                )}
              />
            </Table>{" "}

          </div>
        </div>
      </div>
    );
  }
}

EcomProductList.propTypes = {
  getUniquePurchases: PropTypes.func.isRequired,
  ecomSales: PropTypes.func.isRequired,
  addVisiblityOnEcom: PropTypes.func.isRequired,
  getBrands: PropTypes.func.isRequired,
  getBrandAndDesignCombination: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  brands: state.brandsData.brands,

  searchData: state.purchaseData.purchaseSearchData,
  brandanddesignNo: state.purchaseData.brandanddesigncombination,
  progressBarStatus: state.progressBarReducer.showProgressBar
});

export default connect(mapStateToProps, { getUniquePurchases, ecomSales, addVisiblityOnEcom, getBrands, getBrandAndDesignCombination })(
  EcomProductList
);
