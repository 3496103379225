import React, { Component } from "react";
import { Row, Col, Button } from "antd";
import { NavLink } from "react-router-dom";
import WarehouseList from "./WarehouseList";

export default class Warehouse extends Component {
  render() {
    return (
      <div className="subCategory-dashboard">
        <div className="container-fluid category-list">
          <Row className="title-row">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="page-header">Warehouse</div>
            </Col>
          </Row>
          <WarehouseList/>
        </div>
      </div>
    );
  }
}
