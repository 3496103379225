import React, { Component } from "react";
import { Row, Col, Tooltip, Form, Select, DatePicker, Button, Input } from "antd";
import Stockagelist from "./Stockagelist";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { getStockAgeingReports, getStockAgeingReportsBySearch } from "../../../../redux/actions/reportsActions";
import DateWiseSearch from "../../../../util/DateWiseSearch";
import TopBarProgress from "react-topbar-progress-indicator";
import debounce from 'lodash.debounce';
import { getSections } from "../../../../redux/actions/sectionAction";
import { getFloors } from "../../../../redux/actions/floorAction";
import BackButton from "../../Report/Button";
const { Option } = Select;

class Stockage extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      endDay: "",
      startDay: "",
      floorName: "",
      sectionName: "",
      reportAllObject: {}
    };
    this.debouncedGetData = debounce(this.getData, 500);
  }

  componentDidMount = () => {
    this.props.getStockAgeingReports()
    this.props.getSections()
    this.props.getFloors()

  }

  searchFilter = async () => {
    try{
    let obj = {
      startDay: this.state.startDay,
      endDay: this.state.endDay,
      floorName: this.state.floorName,
      section: this.state.sectionName,
      limit:1,
      perPage:30,
      type:"perpage"
    }
    
    const initialReport = await this.props.getStockAgeingReportsBySearch(obj)
    console.log("Initial report data:", initialReport);

    const reportObjAll = {
      startDay: this.state.startDay,
      endDay: this.state.endDay,
      floorName: this.state.floorName,
      section: this.state.sectionName,
      limit:1,
      perPage:initialReport?.count,
      type:"all"
    };
    this.setState({reportAllObject : reportObjAll})
    // await this.props.getStockAgeingReportsBySearch(reportObjAll);
  }catch (error) {
    console.error("API Error:", error);
  }
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  resetFun = () => {
    this.setState({
      endDay: "",
      startDay: "",
      floorName: "",
      sectionName: ""
    })
    this.formRef.current.setFieldsValue({
      floorName: undefined,
      sectionName: undefined,
    });
    this.props.getStockAgeingReportsBySearch("reset")
  }

  handleChangeFloor = (key, value) => {
    this.setState({ floorName: value.name })
  };

  handleChangeSection = (key, value) => {
    this.setState({ sectionName: value.name })
  }

  getData = (name) => {
    if (name == "section") this.props.getSections();
    if (name == "floor") this.props.getFloors();
  };

  //   onStartDateChange = (date, dateString) => {
  //     console.log("dateString", dateString);
  //     this.setState({ startDate: dateString });
  //   };
  //   onEndDateChange = (date, dateString) => {
  //     this.setState({ endDate: dateString });
  //   };

  //   showDataOnList = () => {
  //     let reportObj = {
  //       startDate: this.state.startDate,
  //       endDate: this.state.endDate,
  //     };
  //     console.log("#reportObj", reportObj);
  //     this.props.getPurchaseReports(reportObj);
  //   };

  //   getQuarterlyDates = () => {
  //     var quarter = moment("2022-07-01").quarter();
  //     const startDate = moment()
  //       .quarter(quarter)
  //       .startOf("quarter")
  //       .format(dateFormat);
  //     const endDate = moment()
  //       .quarter(quarter)
  //       .endOf("quarter")
  //       .format(dateFormat);
  //     console.log("###getQuarterlyDates", startDate, endDate);
  //   };

  //   getFullYearDates = () => {
  //     var year = moment("2021-04-01").year();
  //     const startDate = moment().year(year).startOf("year").format(dateFormat);
  //     const endDate = moment().year(year).endOf("year").format(dateFormat);
  //     console.log("###getFullYearDates", startDate, endDate);
  //   };

  //   getHalfYearlyDates = () => {
  //     //issue only takes current yr
  //     var quarter = moment("2022-04-01").quarter();
  //     console.log("###quarter", quarter);
  //     if (quarter === 1 || quarter === 3) {
  //       let startDate = moment()
  //         .quarter(quarter)
  //         .startOf("quarter")
  //         .format(dateFormat);
  //       let endDate = moment()
  //         .quarter(quarter
  //         .add(1, "quarter")
  //         .endOf("quarter")
  //         .format(dateFormat);

  //       console.log("###getHalfYearlyDates", startDate, endDate);
  //     } else if (quarter === 2 || quarter === 4) {
  //       let startDate = moment()
  //         .quarter(quarter)
  //         .subtract(1, "quarter")
  //         .startOf("quarter")
  //         .format(dateFormat);
  //       let endDate = moment()
  //         .quarter(quarter)
  //         .endOf("quarter")
  //         .format(dateFormat);

  //       console.log("###getHalfYearlyDates", startDate, endDate);
  //     }
  //   };

  render() {
    let floorOptions = this.props?.floors?.map((floor) => {
      return (
        <Option name={floor.name} value={floor._id}>
          {floor.name}
        </Option>
      );
    });
    let sectionOptions = this.props?.sections?.map((section) => {
      return (
        <Option name={section.name} value={section._id}>
          {section.name}
        </Option>
      );
    });
    return (
      <div className="subCategory-dashboard">
        {this.props.progressBarStatus && <TopBarProgress />}
        <div className="container-fluid category-list">
          {/* ==============Search Bar and Date Filter================= */}
          <Row className="title-row">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row gutter={24}>
                <Col span={12}>
              <div className="page-header">Stock Ageing Report</div>
                </Col>
                <Col span={12}>
                  <BackButton />
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <Row className="mt-4">
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <DateWiseSearch />
            </Col>
          </Row> */}
          {/* ======================================== */}
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form
                name="add-Purchase"
                className="add-Purchase"
                onFinish={this.searchFilter}
                initialValues={{ remember: true }}
                ref={this.formRef}
              >
                <Row gutter={[16, 0]} className="mt-4">
                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Tooltip title="Enter Start Days">
                      <Form.Item
                      >
                        <Input
                          placeholder="Enter Start Days"
                          name="startDay"
                          value={this.state.startDay}
                          onChange={this.onChange}
                          required
                          maxLength={15}
                          type="number"
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Tooltip title="Enter End Days">
                      <Form.Item
                      >
                        <Input
                          placeholder="Enter End Days"
                          name="endDay"
                          value={this.state.endDay}
                          onChange={this.onChange}
                          required
                          maxLength={15}
                          type="number"
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Form.Item
                      name="floorName"
                      rules={[
                        {
                          required: false,
                          message: "Please Select Floor Name !",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        // name="floorName"
                        placeholder="Select Floor"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(
                              optionB.children.toLowerCase()
                            )
                        }
                        onChange={this.handleChangeFloor}
                      // onClick={() => this.debouncedGetData("floor")}
                      // onInputKeyDown={() => this.debouncedGetData("floor")}
                      >
                        {floorOptions}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Form.Item
                      name="sectionName"
                      rules={[
                        {
                          required: false,
                          message: "Please Select Section Name !",
                        },
                      ]}
                    >
                      <Select
                        // name="sectionName"
                        showSearch
                        placeholder="Select Section"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(
                              optionB.children.toLowerCase()
                            )
                        }
                        onChange={this.handleChangeSection}
                      // onClick={() => this.debouncedGetData("section")}
                      // onInputKeyDown={() => this.debouncedGetData("section")}
                      >
                        {sectionOptions}
                      </Select>
                    </Form.Item>

                  </Col>
                  <Col xs={12} sm={12} md={4} lg={2} xl={2}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="close-modal"
                        block
                      >
                        Search
                      </Button>
                    </Form.Item>
                  </Col>

                  <Col xs={12} sm={12} md={4} lg={2} xl={2}>
                    <Form.Item>
                      <Button
                        type="primary"
                        className="btn-stock-report-search"
                        block
                        onClick={() => this.resetFun()}
                      >
                        Reset
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Stockagelist
            reports={this.props?.dayWiseData?.data?.length > 0 ? this.props.dayWiseData : this.props.reports}
            allreports={this.props?.allData}
            startDay={this.state.startDay}
            endDay={this.state.endDay}
            obj = {{
              startDay: this.state.startDay,
              endDay: this.state.endDay,
              floorName: this.state.floorName,
              section: this.state.sectionName,
            }}
            reportObjAll = {this.state.reportAllObject}
            getStockAgeingReportsBySearch ={this.state.getStockAgeingReportsBySearch}
          />
        </div>
      </div>
    );
  }
}
Stockage.propTypes = {
  getStockAgeingReports: PropTypes.func.isRequired,
  getStockAgeingReportsBySearch: PropTypes.func.isRequired,
  getSections: PropTypes.func.isRequired,
  getFloors: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  reports: state.reportData.stockageReport,
  dayWiseData: state.reportData.stockAgeReportByDate,
  allData: state.reportData.allstockAgeReportByDate,
  progressBarStatus: state.progressBarReducer.showProgressBar,
  sections: state.sectionData.sections,
  floors: state.floorData.floors,

});

export default connect(mapStateToProps, {
  getStockAgeingReports, getStockAgeingReportsBySearch, getSections, getFloors
})(Stockage);