import React, { useState, useEffect } from "react";
import {
  Modal,
  Table,
  Checkbox,
  Alert,
  Select,
  Button,
  Col,
  Row,
  Tooltip,
} from "antd";
import { FileDoneOutlined } from "@ant-design/icons";
import TopBarProgress from "react-topbar-progress-indicator";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import GenerateBillForMerge from "../../sales/merge bill/GenerateBillForMerge";
import VerifyBillProduct from "../../sales/VerifyBillProduct";
import { getActiveandInactiveCustomer } from "../../../../redux/actions/customerAction";
import { ecomSalesBillByCustomerId } from "../../../../redux/actions/ecomSaleAction";
import GenerateEcomBillForMerge from "./GenerateEcomBillForMerge";
import VerifyEcomBillProduct from "./VerifyEcomBillProduct";

const { Column } = Table;
const { Option } = Select;

const MergeBillModal = () => {
  const dispatch = useDispatch();
  const slaesbillByCustomerId = useSelector(
    (state) => state.ecomSaleData.ecomSlaesbillByCustomerId
  );
  const progressBarStatus = useSelector(
    (state) => state.progressBarReducer.showProgressBar
  );
  const customers = useSelector(
    (state) => state.customerData.activeandInactiveCustomer
  );

  const [visible, setVisible] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [ecomSalesIds, setEcomSalesIds] = useState([]);

  useEffect(() => {
    if (visible && customerId) {
      dispatch(ecomSalesBillByCustomerId({ customerId }));
    }
  }, [dispatch, customerId, visible]);

  const handleCheckboxChange = (e, record) => {
    const newEcomSalesIds = e.target.checked
      ? [...ecomSalesIds, record.ecomsalesId]
      : ecomSalesIds.filter((id) => id !== record.ecomsalesId);

    setEcomSalesIds(newEcomSalesIds);
  };

  const handleChangeCustomer = (customerId) => {
    setCustomerId(customerId);
  };

  const handleModalOpen = () => {
    dispatch(getActiveandInactiveCustomer());
    setVisible(true);
  };

  const handleModalClose = () => {
    setVisible(false);
  };

  const handleSearch = () => {
    // Perform any additional actions when searching
    if (customerId) {
      dispatch(ecomSalesBillByCustomerId({ customerId }));
    }
  };

  return (
    <div>
        <Button className="btn-admin btn-subCategory add-button" type="primary" onClick={handleModalOpen}>
          Merge Bills
        </Button>

      <Modal
        title="Merge Ecom Bills"
        visible={visible}
        onCancel={handleModalClose}
        footer={null}
        width="100%"
        style={{ maxWidth: "1000px" }}
      >
        {progressBarStatus && <TopBarProgress />}
        <div className="container-fluid category-list">
          <Row gutter={[16, 16]}>
            <Col xs={24} md={16}>
              <Select
                showSearch
                placeholder="Select Customer"
                optionFilterProp="children"
                onChange={handleChangeCustomer}
                style={{ width: "100%" }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {customers?.data?.map((customer) => (
                  <Option key={customer._id} value={customer._id}>
                    {customer.name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} md={8}>
              <Button
                type="primary"
                onClick={handleSearch}
                style={{ width: "100%" }}
              >
                Search
              </Button>
            </Col>
          </Row>
          <Table
            dataSource={slaesbillByCustomerId}
            bordered
            size="middle"
            pagination={{ pageSize: 5 }}
            scroll={{ y: 400 }}
            style={{ marginTop: "16px" }}
          >
            <Column
              title="Bill Date"
              dataIndex="date"
              key="date"
              width={150}
              render={(value, item) =>
                `${moment(item?.date).format("YYYY-MM-DD")} (${moment(
                  item?.date
                ).format("HH:mm:ss")} )`
              }
            />
            <Column
              title="Customer Name"
              dataIndex="dealerName"
              key="dealerName"
              width={150}
              render={(value, item) => `${item.userId.name}`}
            />
            <Column
              title="Ecom Sales Id"
              dataIndex="esId"
              key="esId"
              render={(value, item) => item.ecomsalesId}
              width={115}
            />
            <Column
              title="Total Qty"
              dataIndex="totalQuantity"
              key="totalQuantity"
              width={120}
            />
            <Column
              title="Total Boxes"
              dataIndex="totalBoxes"
              key="totalBoxes"
              width={120}
            />
            <Column
              title="Total Amount"
              dataIndex="totalAmount"
              key="totalAmount"
              width={120}
            />
            <Column
              title="Net Amt"
              dataIndex="netAmount"
              key="netAmount"
              width={110}
            />
            <Column
              title="Action"
              key="action"
              dataIndex="ecomsalesId"
              width={80}
              render={(ecomsalesId, record) => (
                <Checkbox
                  checked={ecomSalesIds.includes(record.ecomsalesId)}
                  onChange={(e) => handleCheckboxChange(e, record)}
                />
              )}
            />
            <Column
              title="Verified"
              key="verified"
              dataIndex="isVerified"
              width={150}
              render={(isVerified) => (
                <Alert
                  message={isVerified ? "Verified" : "Not Verified"}
                  type={isVerified ? "success" : "error"}
                  showIcon
                />
              )}
            />
          </Table>
          <Row className="mt-4" justify="center" gutter={[16, 16]}>
            <Col xs={24} md={10}>
              <GenerateEcomBillForMerge invoiceData={ecomSalesIds} />
            </Col>
            <Col xs={24} md={10}>
              <VerifyEcomBillProduct
                title="mergebill"
                invoiceData={ecomSalesIds}
                onVerify={handleSearch}
              />
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default MergeBillModal;
