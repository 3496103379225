import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Modal, Button, Form, Input, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { message } from "antd";
import { addStyle, getAllStyles } from "../../../../redux/actions/styleAction";
import { getCategories } from "../../../../redux/actions/categoryAction";

const { Option } = Select;
class AddStyle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      name: "",
      categoryId: "",
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleModalCancel = () => {
    this.setState({ visible: false });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errors !== this.props.errors) {
      //Perform some operation here

      this.setState({ errors: this.props.errors });
      message.error(this.props.errors.message);
    }
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  onCategoryChange = (event) => {
    this.setState({
      categoryId: event,
    });
  };

  onSubmit = async (event) => {
    // event.preventDefault();
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 1000);
    let styleObj = {
      name: this.state.name.trim(),
      categoryId: this.state.categoryId,
    };
    let res = await this.props.addStyle(styleObj);
    if (res && res.status === 200) {
      await this.props.getAllStyles()
      setTimeout(() => {
        this.setState({ fileList: [] });
      }, 1000);
    }
  }
  componentDidMount() {
    this.props.getCategories();
  }
  render() {
    const { visible, loading } = this.state;

    return (
      <Row>
        <Col span={24}>
          <div className="add-button">
            <Button
              className="btn-admin btn-subCategory"
              type="primary"
              onClick={this.showModal}
            >
              Add Style
            </Button>
            <Modal
              visible={visible}
              onOk={this.handleOk}
              onCancel={this.handleModalCancel}
              title="Add Style"
              style={{ top: 20 }}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              destroyOnClose={true}
            >
              <Form
                name="add-Style"
                className="add-Style"
                onFinish={this.onSubmit}
                initialValues={{
                  remember: true,
                }}
              >
                <Form.Item
                  name="categoryId"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Category Name!",
                    },
                  ]}
                >
                  <Select
                    name="categoryId"
                    placeholder="Select a Category"
                    id="categoryId"
                    onChange={this.onCategoryChange}
                    value={this.state.categoryId}
                  >
                    {this.props.categories.map((category) => (
                      <Option value={category._id}>{category.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Style Name !",
                    },
                  ]}
                >
                  <Input
                    autoFocus
                    placeholder="Style Name"
                    name="name"
                    value={this.state.name}
                    onChange={this.onChange}
                  />
                </Form.Item>

                <Form.Item className="float-right">
                  <Button
                    className="close-modal mr-3"
                    onClick={this.handleModalCancel}
                  >
                    Close
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="ok-modal"
                    loading={loading}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
          </div>
        </Col>
      </Row>
    );
  }
}

AddStyle.propTypes = {
  addStyle: PropTypes.func.isRequired,
  getAllStyles: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  categories: state.categoryData.categories,
});

export default connect(mapStateToProps, { addStyle, getCategories, getAllStyles })(AddStyle);
