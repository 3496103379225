//Create By Aaditya-
import React, { Component } from "react";
import { Table, Button, Tag, Row, Col, Input, Space, Tooltip } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  inventoryList,
  whEmptyStockInventory,
  deleteEmptyStockData
} from "../../../redux/actions/warehouseActions";
import WhSearch from "../../../util/WhSearch";
import Papa from "papaparse";
import Swal from "sweetalert2";

const { Column } = Table;
const searchOptions = ["Barcode"];
class Inventories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      options: [],
      searchValue: "",
      data: "",
      stateReceived: false,
    };
  }

  componentDidMount() {
    this.logState();
    // this.props.inventoryList(this.props.location.state)
    this.props.inventoryList(1, 50);
    this.props.whEmptyStockInventory();
  }

  logState() {
    //stateData contains id so to send further dynamically in api
    const { location } = this.props;
    const stateData = location.state;

    if (stateData) {
      console.log("State data:", stateData);
      this.setState({ stateReceived: true });
    } else {
      console.log("State is undefined");
    }
  }

  cancelState = () => {
    this.setState({ searchValue: "" });
  };

  onChange = (event) => {
    this.setState({ searchValue: event });
  };

  downloadCsv = async () => {
    const response = await this.props.whEmptyStockInventory();
  
    const headers = [
      { label: "Dealer Name", key: "dealerName" },
      { label: "Barcode", key: "barcode" },
      { label: "Design No", key: "designNumber" },
      { label: "Brand", key: "brand" },
      { label: "Size", key: "size" },
      { label: "Color", key: "color" },
      { label: "Category", key: "category" },
      { label: "Style", key: "style" },
      { label: "Floor", key: "floorName" },  
      { label: "Section", key: "section" },
      { label: "MOQ", key: "MOQ" },
      { label: "Stock", key: "quantity" },  
      { label: "Purchase Price", key: "purchasePrice" },
      { label: "WSP", key: "wsp" },  
      { label: "Total Purchase", key: "purchasePrice" },
      { label: "Total Sale", key: "" },  
    ];
  
    console.log("response",response)
  
    const csv = Papa.unparse({
      fields: headers.map((header) => header.label),
      data: response?.map((row) =>
        headers?.map((header) => {
          if(header.key=="purchasePrice"){
            return (row.MOQ * row.purchasePrice).toFixed();
          }
          return row[header.key]})
      ),
      
    });
  
    const csvBlob = new Blob([csv], { type: "text/csv" });
    const csvUrl = URL.createObjectURL(csvBlob);
    const csvLink = document.createElement("a");
    csvLink.href = csvUrl;
    csvLink.download = "inventory.csv";
    csvLink.click();
  };

  deleteEmptyStock = async () => {
    await this.props.deleteEmptyStockData();
    this.props.whEmptyStockInventory();
    this.props.inventoryList(1, 50);
  }

  // redirection for back buttons
  redirectBackButtonFun = () => {
    this.props.history.push(
      `${this.state.lastPath || "/dagaImpex/warehouse"}`,
      true
    );
  };
  render() {
    // for table data-
    const InventoryList = this.props.InventoryList;
    const { searchValue } = this.state;
    // console.log("data", data);
    const { inventoryBysearch } = this.props;
    console.log("inventoryBysearch", inventoryBysearch);
    return (
      <div>
        <div className="subCategory-dashboard">
          {console.log("this.state.emptyStock", this.props.emptyStock)}
          <div className="container-fluid category-list">
            <Row className="title-row">
              <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                <div className="page-header">Inventory List</div>
              </Col>
              <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                <Button
                  type="primary"
                  className="save-modal text-white"
                  onClick={() => this.redirectBackButtonFun()}
                >
                  <ArrowLeftOutlined /> Back
                </Button>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <WhSearch
                  title="whInventory"
                  placeholder="Barcode"
                  searchopt={searchOptions}
                  cancelState={this.cancelState}
                  changeFunction={this.onChange}
                />
              </Col>
            </Row>
            {console.log("props", this.props)}
          </div>
        </div>

        <div className="card mt-4 final-purchases-list">
          <div className="card-body">
            <Table
              dataSource={
                searchValue
                  ? inventoryBysearch
                  : InventoryList?.data
                    ? InventoryList?.data
                    : []
              }
              bordered={true}
              size="middle"
              pagination={{
                defaultPageSize: 100,
                total:
                  searchValue && inventoryBysearch?.length > 0
                    ? inventoryBysearch?.length
                    : InventoryList?.Count,
                onChange: (page, pageSize) => {
                  this.props.inventoryList(page, pageSize);
                },
              }}
              scroll={{ y: 240 }}
            >
              <Column
                title="SRN"
                key="index"
                render={(value, item, index) => index + 1}
                width={60}
              />
              <Column
                title="Barcode"
                dataIndex="barcode"
                key="barcode"
                width={100}
              />
              <Column
                title="Design No."
                dataIndex="designNumber"
                key="designNumber"
                width={150}
              />
              <Column title="Brand" dataIndex="brand" key="brand" width={120} />
              <Column title="Size" dataIndex="size" key="size" width={80} />
              <Column title="Color" dataIndex="color" key="color" width={80} />
              <Column
                title="Purchase Price"
                dataIndex="purchasePrice"
                key="purchasePrice"
                width={120}
              />
              <Column
                title="Sale Price"
                dataIndex="wsp"
                key="wsp"
                width={120}
              />
              <Column
                title="Stock"
                dataIndex="quantity"
                key="quantity"
                width={90}
                render={(quantity) => (
                  <>
                    {(() => {
                      if (quantity <= 15) {
                        return (
                          <Tag color="volcano" key={quantity}>
                            {quantity}
                          </Tag>
                        );
                      } else {
                        return quantity;
                      }
                    })()}
                  </>
                )}
              />
            </Table>

            <Row gutter={32}>
              {this.props.emptyStock.length > 0 && (
                <>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                    <Button
                      type="primary"
                      className="close-modal-save-button"
                      style={{ width: "100%", margin: "2px" }}
                      onClick={() => {
                        Swal.fire({
                          title:
                            "Download CSV file?",
                          text: "This will include all inventory data with quantity 0",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Yes",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            this.downloadCsv();
                          }
                        });
                      }}
                    >
                      Download
                    </Button>
                  </Col>

                  {/* <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Button
                      type="primary"
                      className="close-modal-save-button"
                      style={{ width: "100%", margin: "2px" }}
                      onClick={() => {
                        Swal.fire({
                          title:
                            "Confirm",
                          text: "This will delete all inventory data with quantity 0",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Yes",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            this.deleteEmptyStock();
                          }
                        });
                      }}
                    >
                      Delete
                    </Button>
                  </Col> */}
                </>
              )}
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

Inventories.propTypes = {
  inventoryList: PropTypes.func.isRequired,
  whEmptyStockInventory: PropTypes.func.isRequired,
  deleteEmptyStockData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  InventoryList: state.warehouseData.warehouseInventoryLists,
  inventoryBysearch: state.warehouseData.whbarcodebySearch,
  emptyStock: state.warehouseData.zeroStock,
});

export default withRouter(
  connect(mapStateToProps, { inventoryList, whEmptyStockInventory, deleteEmptyStockData })(
    Inventories
  )
);