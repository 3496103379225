import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Modal, Button, Form, Input } from "antd";
import { message } from "antd";
import { addTexture, getTexture } from "../../../../redux/actions/textureAction";
import { useDispatch } from "react-redux";

const AddTexture = () => {
    const dispatch = useDispatch()
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");

    const showModal = () => {
        setVisible(true);
    };

    const handleModalCancel = () => {
        setVisible(false);
    };

    const onChange = (event) => {
        setName(event.target.value);
    };

    const onSubmit = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setVisible(false);
        }, 1000);

        const textureObj = {
            textureName: name.trim(),
        };
        console.log("TExture Obj", textureObj)
        dispatch(addTexture(textureObj));
        setTimeout(() => {
            dispatch(getTexture())
            setName("");

        }, 1000);
    };

    return (
        <Row>
            <Col span={24}>
                <div className="add-button w-100">
                    <Button
                        className="btn-admin btn-subCategory w-100"
                        type="primary"
                        onClick={showModal}
                    >
                        Add Texture
                    </Button>
                    <Modal
                        visible={visible}
                        onCancel={handleModalCancel}
                        title="Add Texture"
                        style={{ top: 20 }}
                        okButtonProps={{ hidden: true }}
                        cancelButtonProps={{ hidden: true }}
                        destroyOnClose={true}
                    >
                        <Form
                            name="add-texture"
                            className="add-texture"
                            onFinish={onSubmit}
                            initialValues={{
                                remember: true,
                            }}
                        >
                            <Form.Item
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Texture Name !",
                                    },
                                ]}
                            >
                                <Input
                                    autoFocus
                                    placeholder="Texture Name"
                                    value={name}
                                    onChange={onChange}
                                />
                            </Form.Item>

                            <Form.Item className="float-right">
                                <Button
                                    className="close-modal mr-3"
                                    onClick={handleModalCancel}
                                >
                                    Close
                                </Button>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="ok-modal"
                                    loading={loading}
                                >
                                    Save
                                </Button>
                            </Form.Item>
                        </Form>
                    </Modal>
                </div>
            </Col>
        </Row>
    );
};


export default AddTexture;
