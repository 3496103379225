import React, { Component } from "react";
import { Row, Col, Tooltip, Form, Select, DatePicker, Button } from "antd";
import DealerPurchaseReportList from "./DealerPurchaseReportList";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { getBrandBySearch } from "../../../../redux/actions/brandAction";
import { getDealers } from "../../../../redux/actions/dealerAction";
import debounce from "lodash.debounce";
import {
  getDealerPurchsesReports,
  clearData,
} from "../../../../redux/actions/reportsActions";
import TopBarProgress from "react-topbar-progress-indicator";
import BackButton from "../../Report/Button";

const dateFormat = "YYYY-MM-DD";
const { Option } = Select;

class DealerPurchaseReport extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      endDate: moment(new Date()).format("YYYY-MM-DD"),
      startDate: "",
      dealerName: "",
      dealerId: "",
      brandName: "",
      brandId: "",
      reportAllObject: {}
    };
    this.debouncedGetData = debounce(this.getData, 500);
  }
  componentDidMount() {
    this.props.getDealers();
  }
  getData = (name, value) => {
    // if (name == "dealer") this.props.getDealers();
    if (name == "brand") this.props.getBrandBySearch(value);
  };
  handleChangeDealer = (key, value) => {
    let dealerId = value.value;
    let dealerName = value.name;
    this.setState({ dealerId, dealerName });
  };

  componentWillUnmount() {
    this.props.clearData(); // Dispatch clearData action when leaving the component
  }

  handleChangeBrand = (key, value) => {
    let brandId = value.value;
    let brandName = value.name;
    this.setState({ brandId, brandName });
    this.debouncedGetData("brand");
  };

  onStartDateChange = (date, dateString) => {
    this.setState({ startDate: dateString });
  };
  onEndDateChange = (date, dateString) => {
    this.setState({ endDate: dateString });
  };

  showDataOnList = async () => {
    try {
      let today = moment(new Date()).format("YYYY-MM-DD");
      const limit = 1;
      let reportObj = {};
      if (today === this.state.endDate) {
        reportObj = {
          dealerId: this.state.dealerId,
          brandId: this.state.brandId,
          startDate: this.state.startDate,
          endDate: this.state.endDate + " " + moment().format("HH:mm:ss"),
          limit: limit,
          perPage: 30,
          type: "perpage",
        };
      } else {
        const dateMoment = moment(this.state.endDate, "YYYY-MM-DD");
        const endTime = dateMoment.endOf("day").format("HH:mm:ss");
        const endDateWithEndTime = `${this.state.endDate} ${endTime}`;
        reportObj = {
          dealerId: this.state.dealerId,
          brandId: this.state.brandId,
          startDate: this.state.startDate,
          endDate: endDateWithEndTime,
          limit: limit,
          perPage: 30,
          type: "perpage",
        };
      }

      const initialReport = await this.props.getDealerPurchsesReports(
        reportObj
      );
      console.log("Initial report data:", initialReport);

      const reportObjAll = {
        dealerId: this.state.dealerId,
        brandId: this.state.brandId,
        startDate: this.state.startDate,
        endDate: reportObj.endDate,
        limit: limit,
        perPage: initialReport?.count,
        type: "all",
      };
      this.setState({reportAllObject : reportObjAll})
      // await this.props.getDealerPurchsesReports(reportObjAll);
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  render() {
    let dealerOptions = this.props.dealers.map((dealer) => {
      return (
        <Option name={dealer.name} value={dealer._id}>
          {dealer.name}
        </Option>
      );
    });
    let brandOptions = this.props?.brandBysearch?.data?.map((brand) => {
      return (
        <Option name={brand.name} value={brand._id}>
          {brand.name}
        </Option>
      );
    });

    return (
      <div className="subCategory-dashboard">
        {this.props.progressBarStatus && <TopBarProgress />}
        <div className="container-fluid category-list">
          <Row className="title-row">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row gutter={24}>
                <Col span={12}>
                  <div className="page-header">Dealer Purchase Report</div>
                </Col>
                <Col span={12}>
                  <BackButton />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form
                ref={this.formRef}
                name="add-Purchase"
                className="add-Purchase"
                onFinish={this.showDataOnList}
                initialValues={{ remember: true }}
              >
                <Row gutter={[16, 0]} className="mt-4">
                  <Col xs={12} sm={12} md={5} lg={4} xl={4}>
                    <Form.Item name="dealerName">
                      <Select
                        showSearch
                        placeholder="Select Dealer"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                        onChange={this.handleChangeDealer}
                        // onClick={() => this.getData("dealer")}
                        // onInputKeyDown={() => this.getData("dealer")}
                      >
                        {dealerOptions}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={12} sm={12} md={5} lg={4} xl={4}>
                    <Tooltip title="Select Brand">
                      <Form.Item name="brand">
                        <Select
                          name="brand"
                          id="brand"
                          showSearch
                          placeholder="Select Brand"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                          onChange={this.handleChangeBrand}
                          // onClick={() => this.getData("brand")}
                          onSelect={this.showLookup}
                          onClick={() => this.debouncedGetData("brand")}
                          onInputKeyDown={() => this.debouncedGetData("brand")}
                        >
                          {brandOptions}
                        </Select>
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={12} sm={12} md={5} lg={4} xl={4}>
                    <Tooltip title="Select Start Date">
                      <Form.Item
                        name="startDate"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Start Date !",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          onChange={this.onStartDateChange}
                          placeholder="Select Start Date"
                          format={dateFormat}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={12} sm={12} md={5} lg={4} xl={4}>
                    <Tooltip title="Select End Date">
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please Select End Date !",
                          },
                        ]}
                        name="endDate"
                        initialValue={moment()}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          onChange={this.onEndDateChange}
                          placeholder="Select End Date"
                          format={dateFormat}
                          disabledDate={(current) => {
                            return current && current > Date.now();
                          }}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>

                  <Col xs={24} sm={24} md={4} lg={2} xl={2}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="close-modal"
                        block
                      >
                        Search
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <DealerPurchaseReportList
            reports={this.props.reports}
            allreports={this.props.allreports}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            dealerName={this.state.dealerName}
            brandName={this.state.brandName}
            reportObj={{
              dealerId: this.state.dealerId,
              brandId: this.state.brandId,
              startDate: this.state.startDate,
              endDate: this.state.endDate + " " + moment().format("HH:mm:ss"),
            }}
            getDealerPurchsesReports={this.props.getDealerPurchsesReports}
            reportObjAll = {this.state.reportAllObject}
          />
        </div>
      </div>
    );
  }
}
DealerPurchaseReport.propTypes = {
  getDealers: PropTypes.func.isRequired,
  getBrandBySearch: PropTypes.func.isRequired,
  getDealerPurchsesReports: PropTypes.func.isRequired,
  clearData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dealers: state.dealersData.dealers,
  brands: state.brandsData.brands,
  brandBysearch: state.brandsData.brandBysearch,
  reports: state.reportData.dealerPurchasereports,
  allreports: state.reportData.allDealerPurchasereports,
  progressBarStatus: state.progressBarReducer.showProgressBar,
});

export default connect(mapStateToProps, {
  getDealers,
  getBrandBySearch,
  getDealerPurchsesReports,
  clearData,
})(DealerPurchaseReport);