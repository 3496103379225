import React, { Component } from "react";
import { Row, Col, Tooltip, Form, Input, DatePicker, Button } from "antd";
import CreditandDebitReportList from "./CreditandDebitReportList";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { getCreditandDebitReports } from "../../../../redux/actions/reportsActions";
import BarcodeReader from "react-barcode-reader";
import { getPurchaseByBarcode } from "../../../../redux/actions/purchaseAction";
import TopBarProgress from "react-topbar-progress-indicator";
import BackButton from "../../Report/Button";
const dateFormat = "YYYY-MM-DD";

class CreditandDebitReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      endDate: moment(new Date()).format("YYYY-MM-DD"),
      startDate:  moment(new Date()).format("YYYY-MM-DD"),
      barcode: "",
    };
    this.handleScan = this.handleScan.bind(this);
  }

  onStartDateChange = (date, dateString) => {
    console.log("dateString", dateString);
    this.setState({ startDate: dateString });
  };
  onEndDateChange = (date, dateString) => {
    this.setState({ endDate: dateString });
  };
  showDataOnList = () => {
    
    let today = moment(new Date()).format("YYYY-MM-DD")
    if (today == this.state.endDate) {
      let reportObj = {
        barcode: this.state.barcode,
        startDate: this.state.startDate,
        endDate: this.state.endDate + " " + moment().format("HH:mm:ss"),
      };
      this.props.getCreditandDebitReports(reportObj);
    } else {
      const dateMoment = moment(this.state.endDate, 'YYYY-MM-DD');
      const endTime = dateMoment.endOf('day').format('HH:mm:ss');
      const endDateWithEndTime = `${this.state.endDate} ${endTime}`;
      let reportObj = {
        barcode: this.state.barcode,
        startDate: this.state.startDate,
        endDate: endDateWithEndTime,
      };
      this.props.getCreditandDebitReports(reportObj);
    }
  };
  onBarcodeChange = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  async handleScan(data) {
    if (data.length == 9 || data.length == 7) {
      this.setState({
        barcode: data,
      });
    }
  }
  render() {
    return (
      <div className="subCategory-dashboard">
        {this.props.progressBarStatus && <TopBarProgress />}
        <div className="container-fluid category-list">
          <Row className="title-row">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row gutter={24}>
                <Col span={12}>
              <div className="page-header">Credit and Debit Report</div>
                </Col>
                <Col span={12}>
                  <BackButton />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form
                name="add-Purchase"
                className="add-Purchase"
                onFinish={this.showDataOnList}
                initialValues={{ remember: true }}
              >
                <Row gutter={[16, 0]} className="mt-4">
                  <Col xs={8} sm={8} md={6} lg={6} xl={6}>
                    <Tooltip title="Barcode">
                      <Form.Item
                        //name="barcode"
                        label="Barcode"
                        rules={[
                          {
                            required: true,
                            message: "Please Enter Barcode Number !",
                          },
                        ]}
                      >
                        <BarcodeReader
                          onError={this.handleError}
                          onScan={this.handleScan}
                        />
                        <Input
                          placeholder="Enter Barcode Number"
                          name="barcode"
                          value={this.state.barcode}
                          onChange={this.onBarcodeChange}
                          required
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={8} sm={8} md={7} lg={5} xl={5}>
                    <Tooltip title="Select Start Date">
                      <Form.Item
                        name="startDate"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Start Date !",
                          },
                        ]}
                        initialValue={moment()}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          onChange={this.onStartDateChange}
                          placeholder="Select Start Date"
                          format={dateFormat}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={8} sm={8} md={7} lg={5} xl={5}>
                    <Tooltip title="Select End Date">
                      <Form.Item
                        name="endDate"
                        rules={[
                          {
                            required: true,
                            message: "Please Select End Date !",
                          },
                        ]}
                        initialValue={moment()}
                      >
                        <DatePicker
                          disabledDate={(current) => {
                            return current && current > Date.now();
                          }}
                          style={{ width: "100%" }}
                          onChange={this.onEndDateChange}
                          placeholder="Select End Date"
                          format={dateFormat}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>

                  <Col xs={24} sm={24} md={4} lg={2} xl={2}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="close-modal"
                        block
                      >
                        Search
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <CreditandDebitReportList
            reports={this.props?.reports}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
          />
        </div>
      </div>
    );
  }
}
CreditandDebitReport.propTypes = {
  getCreditandDebitReports: PropTypes.func.isRequired,
  getPurchaseByBarcode: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  reports: state.reportData.creditanddebitreport,
  purchase: state.purchaseData.purchase,
  progressBarStatus : state.progressBarReducer.showProgressBar
});

export default connect(mapStateToProps, {
  getCreditandDebitReports,
  getPurchaseByBarcode,
})(CreditandDebitReport);
