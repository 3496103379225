/* /////////////////////////////////////// */
/* 
Date              created by               Modifications
  
24-07-2023        Mohit                    create this function handlePdfGeneration foer downloading pdf                   


*/
/* /////////////////////////////////////// */




import React, { Component } from "react";
import { Modal, Table, Button, Typography, Col, Row } from "antd";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { createSales } from "../../../redux/actions/saleAction"
import { connect } from "react-redux";
import PropTypes from "prop-types";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { Spin } from 'antd';

// import "../../../assets/css/generateBill.css";

class GenerateBill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      // billDate: "",
      // saleDate: moment(new Date()),
    };
  }
  // getDate = () => {
  //   // this.setState({ billDate: moment(new Date()).format("DD/MM/YYYY") });
  //   console.log("samay date", new Date().format("DD/MM/YYYY"));
  // };
  handleModalCancel = () => {
    window.location.reload()
    this.setState({ visible: false });
  };
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handlePdfGeneration = () => {
    const element = document.getElementById("pdf-content");
    if (!element) {
      return;
    }

    element.style.width = "400px";
    element.style.margin = "0 auto";

    const opt = {
      margin: [10, 10, 10, 10],
      filename: "bill.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      // css: customPdfStyles
    };


    html2pdf().from(element).set(opt).save().then(() => {
      element.style.width = null;
      element.style.margin = null;
    });
  };




  render() {
    const { visible } = this.state;
    const { myPurchaseData } = this.props
    console.log("console", this.props.myPurchaseData);
    let track = {};
    let brands = [];
    let e = 0;
    let f = this.props.myPurchaseData.length;
    while (e < f) {
      if (this.props.myPurchaseData[e].brand in track) {
        track[this.props.myPurchaseData[e].brand].push(e);
      } else {
        track[this.props.myPurchaseData[e].brand] = [e];
        brands.push(this.props.myPurchaseData[e].brand);
      }
      ++e;
    }
    console.log(track);
    console.log(brands);
    let data = brands?.sort().map((purchaseName) => {
      let indexes = track[purchaseName];
      let insideData = [];
      let ee = 0;
      let ff = indexes.length;
      while (ee < ff) {
        let purchase = this.props.myPurchaseData[indexes[ee]];
        insideData.push(
          <li>
            <div class="page-break">
              <Row className="mt-n2">
                <Col className="mr-1" style={{ width: "108px" }}>
                  <p style={{ fontWeight: 800 }}>
                    {indexes[ee] + 1} || {purchase.designNumber}
                  </p>
                </Col>
                <Col className="mr-1" style={{ width: "80px" }}>
                  <p style={{ fontWeight: 800 }}>{purchase.size}</p>
                </Col>
                <Col className="mr-2" style={{ width: "50px" }}>
                  <p className="mb-n2" style={{ fontWeight: 800 }}>
                    {purchase.saleQty}
                  </p>
                </Col>
                <Col className="mr-1" style={{ width: "50px" }}>
                  <p className="mb-n2" style={{ fontWeight: 800 }}>
                    {purchase.wsp}
                  </p>
                </Col>
                {/*<Col className="mr-1" style={{ width: "70px" }}>
                <p className="mb-n2" style={{ fontWeight: 800 }}>
                  {purchase.color}
                </p>
        </Col>*/}
                <Col className="mr-1" style={{ width: "66px" }}>
                  <p className="mb-n2" style={{ fontWeight: 800 }}>
                    {Math.round(purchase.saleQty * purchase.wsp)}
                  </p>
                </Col>
              </Row>
            </div>
          </li>
        );
        ++ee;
      }
      return (
        <li>
          <h5>
            <u>
              <strong style={{ fontSize: "18px" }}>{purchaseName}</strong>
            </u>
          </h5>
          <ul>{insideData}</ul>
        </li>
      );
    });
    return (
      <div className="generate-bill">
        <Button
          type="primary"
          onClick={this.showModal}
          className="close-modal"
          block
        >
          Generate Bill
        </Button>
        <ReactToPrint content={() => this.componentRef}>
          <Modal
            visible={visible}
            onOk={this.handleOk}
            onCancel={this.handleModalCancel}
            title="Generate Bill"
            style={{ top: 20 }}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose={true}
            width={1500}
          >
            <div className="card generate-bill-card" id="pdf-content"  >
              <div className="card-body generate-bill-font" style={{ padding: "0.6rem" }} ref={(el) => (this.componentRef = el)}>
                {
                  myPurchaseData && myPurchaseData.length > 0 && data && data.length > 0 ? <>
                    <div className="generate-bill-font-test7">
                      <Row>
                        <Col span={8} style={{ textAlign: "center" }}>
                          <strong>
                            <p
                              className="mb-n2 font-weight-bold"
                              style={{ width: "380px" }}
                            >
                              || Jai Shree Krishna ||
                            </p>
                          </strong>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          span={8}
                          className="mr-2"
                          style={{ textAlign: "center" }}
                        >
                          <h4 className="mb-n1 mt-2" style={{ width: "380px" }}>
                            Estimate
                          </h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p className="mb-n1" style={{ width: "350px" }}>
                            <strong>Invoice Id : </strong>{this.props.invoiceId}
                          </p>
                          <p style={{ width: "350px" }}>
                            <strong>Customer :
                              {this.props.customerName}
                            </strong>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p style={{ width: "350px" }}>
                            <strong>Agent :
                              {this.props.agentName}
                            </strong>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mr-5">
                          <p className="mb-n2" style={{ width: "350px" }}>
                            <strong>Date :
                              {
                                (() => {
                                  const momentDate = moment(this.props.saleDate);
                                  const formattedDate = momentDate.format("DD/MM/YYYY");
                                  const formattedTime = momentDate.format("HH:mm:ss");
                                  return (
                                    <span>{formattedDate} &nbsp;&nbsp; {formattedTime}</span>
                                  )
                                })()
                              }
                            </strong>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <strong> <p className="mb-n2">Note : {this.props.remark}</p></strong>
                        </Col>
                      </Row>
                      <div style={{ width: "380px", display: "block", }}>
                        <Row className="mt-3">
                          <Col className="mr-1" style={{ width: "114px" }}>
                            <strong>  <p class="font-weight-bold">Particulars</p></strong>
                          </Col>
                          <Col className="mr-1" style={{ width: "80px" }}>
                            <strong><p class="font-weight-bold">Size</p></strong>
                          </Col>
                          <Col className="mr-1" style={{ width: "50px" }}>
                            <strong>  <p class="font-weight-bold">Qty</p></strong>
                          </Col>
                          <Col className="mr-1" style={{ width: "50px" }}>
                            <strong> <p class="font-weight-bold">Rate</p></strong>
                          </Col>
                          {/*<Col className="mr-1" style={{ width: "70px" }}>
                    <p class="font-weight-bold">Color</p>
    </Col>*/}
                          <Col className="mr-1" style={{ width: "60px" }}>
                            <p class="font-weight-bold">Amt</p>
                          </Col>
                        </Row>
                        <hr />
                        <ul>{data}</ul>
                        <hr />
                        <Row className="mt-2">
                          <Col className="mr-2" style={{ width: "120px", textAlign: "left" }}>
                            <p>Total Qty</p>
                          </Col>
                          <Col
                            className="mr-2"
                            style={{ textAlign: "center", width: "120px" }}
                          >
                            <p style={{ fontWeight: 800 }}>
                              {this.props.totalQuantity}
                            </p>
                          </Col>
                          <Col
                            className="mr-2"
                            style={{ textAlign: "end", width: "110px" }}
                          >
                            <p style={{ fontWeight: 800 }}>
                              {Number(this.props.totalAmount)}
                              {/* {
                          (()=>{
                            let gramount = this.props.grAmount ? this.props.grAmount : 0
                            return this.props.netAmount + gramount -
                            Math.round(this.props.taxedAmount) +
                            Math.round(this.props.discountedAmount)
                          })()
                        } */}
                              {/*                         
                        {this.props.netAmount + this.props.grAmount -
                          Math.round(this.props.taxedAmount) +
                          Math.round(this.props.discountedAmount)} */}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mr-2" style={{ width: "120px", textAlign: "left" }}>
                            <p>GR Amount</p>
                          </Col>
                          <Col className="mr-2" style={{ textAlign: "center", width: "120px" }}>
                          </Col>
                          <Col
                            className="generate-bill"
                            style={{ textAlign: "end", width: "110px" }}
                          >
                            <p style={{ fontWeight: 800 }}>{this.props.grAmount ? this.props.grAmount : 0}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mr-2" style={{ width: "120px", textAlign: "left" }}>
                            <p>Discount</p>
                          </Col>
                          <Col
                            className="mr-2"
                            style={{ textAlign: "center", width: "120px" }}
                          >
                            <p style={{ fontWeight: 800 }}>{this.props.discount}%</p>
                          </Col>
                          <Col
                            className="mr-2"
                            style={{ textAlign: "end", width: "110px" }}
                          >
                            <p style={{ fontWeight: 800 }}>
                              {parseInt(this.props.discountedAmount)}
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col className="mr-2" style={{ width: "120px", textAlign: "left" }}>
                            <p>VAT/CST</p>
                          </Col>
                          <Col
                            className="mr-2"
                            style={{ textAlign: "center", width: "120px" }}
                          >
                            <p style={{ fontWeight: 800 }}>{this.props.tax}%</p>
                          </Col>
                          <Col
                            className="mr-2"
                            style={{ textAlign: "end", width: "110px" }}
                          >
                            <p style={{ fontWeight: 800 }}>{parseInt(this.props.taxedAmount)}</p>
                          </Col>
                        </Row>

                        <Row>
                          <Col className="mr-2" style={{ width: "120px", textAlign: "left" }}>
                            <p>P. & F.</p>
                          </Col>
                          <Col style={{ textAlign: "center", width: "120px" }}>
                          </Col>
                          <Col
                            className="generate-bill"
                            style={{ textAlign: "end", width: "120px" }}
                          >
                            <p style={{ fontWeight: 800 }}>{this.props.pAndf}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="mr-2" style={{ width: "120px", textAlign: "left" }}>
                            <p>Frieght</p>
                          </Col>
                          <Col className="mr-2" style={{ textAlign: "center", width: "120px" }}>
                          </Col>
                          <Col
                            className="generate-bill"
                            style={{ textAlign: "end", width: "110px" }}
                          >
                            <p style={{ fontWeight: 800 }}>0</p>
                          </Col>
                        </Row>
                        
                        <Row>
                          <Col className="mr-2" style={{ width: "120px", textAlign: "left" }}>
                            <p>Net Amount</p>
                          </Col>
                          <Col className="mr-2" style={{ textAlign: "center", width: "120px" }}>
                          </Col>
                          <Col
                            className="generate-bill"
                            style={{ textAlign: "end", width: "110px" }}
                          >
                            <p style={{ fontWeight: 800 }}>{Number(this.props.netAmount).toFixed()}</p>
                          </Col>
                        </Row>
                      </div>
                      <div className="mx-2">
                        <Row>
                          <Col style={{ width: "175px", textAlign: "left" }}>
                            <p className="mb-n2">E.D.E.</p>
                          </Col>
                          <Col style={{ textAlign: "end", width: "175px" }}>
                            <p className="mb-n2">Authorised Signatory</p>
                          </Col>
                        </Row>
                        <p className="mb-n2">Terms & Cond.</p>
                        <p className="mb-n2">
                          1. Goods once sold will not be taken back
                        </p>
                        <p>2. No Claim No Guarantee</p>
                      </div>
                      <Row>
                        <Col span={8} style={{ textAlign: "center" }}>
                          <p className="font-weight-bold" style={{ width: "350px" }}>
                            || Thank You Visit Again ||
                          </p>
                        </Col>
                      </Row>
                    </div>

                  </> : <>
                    <div className="spin-ViewBill">
                      <Spin />
                    </div>
                  </>
                }

              </div>
            </div>
            <Row className="generate-pdf-download-row">
              <Col>
                <PrintContextConsumer>
                  {({ handlePrint }) => (
                    <Button
                      style={{ marginLeft: "45%" }}
                      type="primary"
                      className="close-modal mt-4"
                      // block
                      onClick={() => {
                        // this.handlePdfGeneration()
                        this.props.clearState();
                        this.props.createSales({}, {}, "blank");
                        handlePrint();
                        // window.location.reload(true)
                        // this.props.createSales(false);
                      }}
                    >
                      Generate Bill
                    </Button>
                  )}
                </PrintContextConsumer>
              </Col>
              <Col style={{ marginLeft: "1rem" }}>
                <Button
                  style={{ marginLeft: "45%" }}
                  type="primary"
                  className="save-modal mt-4"
                  // block
                  onClick={() => {
                    this.handlePdfGeneration()
                  }}
                >
                  Download PDF
                </Button>
              </Col>
            </Row>
          </Modal>
        </ReactToPrint>
      </div>
    );
  }
}


GenerateBill.propTypes = {
  createSales: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  invoiceId: state.saleData.sale.invoiceId,
});

export default connect(mapStateToProps, {
  createSales,
})(GenerateBill);
