import React, { Component } from "react";
import { Table, Tag, Row, Button, Col, Modal, Input, Space } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ArrowLeftOutlined } from "@ant-design/icons";
import TopBarProgress from "react-topbar-progress-indicator";
import AddSalesWarehouse from "./AddSalesWarehouse";
import ViewWarehouseGenerateBill from "./ViewWarehouseGenerateBill";
import { WarehouseSaleList } from "../../../redux/actions/warehouseActions";
import ViewProducts from "./ViewProducts";
import moment from "moment";
import WhSearch from "../../../util/WhSearch";

const searchOptions = ["Barcode", "warehousesaleId", "storename"]

const { Column } = Table;

class SalesOut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addSalesModalVisible: false,
      viewProductsModalVisible: false,
      selectedSale: null,
      generateBillModalVisible: false,
      totalCount: 0
    };
  }
  componentDidMount() {
    this.logState();
    // console.log("check Aadi",this.props?.history?.location?.state)
    this.props.WarehouseSaleList(1);
  }

  cancelState = () => {
    this.setState({ searchValue: "" });
  }
  onChange = (event) => {
    this.setState({ searchValue: event });
  };

  // componentDidUpdate(prevProps) {
  //   if (prevProps.saleList !== this.props.saleList) {
  //     this.setState({ totalCount: this.props.saleList.count })
  //   }
  // }

  logState() {
    const stateData = this.props?.history?.location?.state;

    if (stateData) {
      console.log("State data:", stateData);
      this.setState({ stateReceived: true });
    } else {
      console.log("State is undefined");
    }
  }




  redirectBackButtonFun = () => {
    this.props.history.push(
      `${this.state.lastPath || "/dagaImpex/warehouse"}`,
      true
    );
  };
  render() {
    const saleList = this.props.saleList;

    const { searchValue } = this.state;
    console.log("searchValue", searchValue);
    const { whOutBillBySearch } = this.props
    console.log("whPurchaseBysearch", whOutBillBySearch);

    return (
      <div>
        <Row className="title-row" justify="space-between">
          <Col>
            <div className="page-header">Sales Out List</div>
          </Col>
          <Col>
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* <AddSalesWarehouse /> */}
              <Button
                type="primary"
                className="save-modal text-white"
                onClick={() => this.redirectBackButtonFun()}
                style={{ marginLeft: "5px" }}
              >
                <ArrowLeftOutlined /> Back
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <WhSearch title="Wairehouse Sales Bills" placeholder="Sale Return Id, Barcode, Agent Name or CustomerName" searchopt={searchOptions} changeFunction={this.onChange} cancelState={this.cancelState} location={this.props.history.location} />

        </Row>

        <div className="card mt-4 final-purchases-list">
          {this.props.progressBarStatus && <TopBarProgress />}
          <div className="card-body">
            <Table
              dataSource={searchValue ? whOutBillBySearch : saleList?.data ? saleList?.data : []}
              bordered="true"
              size="middle"

              pagination={{
                //  defaultPageSize: 10,
                total:
                  searchValue && whOutBillBySearch?.length > 0
                    ? whOutBillBySearch?.length
                    : saleList?.Count,
                onChange: (page) => {
                  this.props.WarehouseSaleList(page);
                },
              }}
              scroll={{ y: 240 }}
            >
              <Column
                title="SRN"
                key="index"
                render={(value, item, index) => index + 1}
                width={60}
              />
              <Column
                title="Date"
                dataIndex="date"
                key="date"
                width={180}
                render={(value, item, index) =>
                  moment(item.date).format("YYYY-MM-DD")
                }
              />
              <Column
                title="Sale Id"
                key="warehousesaleId"
                dataIndex="warehousesaleId"
                width={100}
              />
              <Column
                title="Store Name"
                dataIndex="storename"
                key="storename"
                width={180}
              />
              <Column
                title="Quantity"
                dataIndex="quantity"
                key="quantity"
                width={100}
              />
              {/* <Column
                title="Total Amount"
                dataIndex="totalAmount"
                key="totalAmount"
                width={100}
              /> */}
              <Column
                title="Remark"
                dataIndex="remark"
                key="remark"
                width={100}
              />
              <Column
                title="Action"
                key="action"
                width={80}
                align="center"
                render={(record) => {
                  console.log("record", record.warehousesaleId);
                  return (
                    <Row gutter={[8, 8]}>
                      <Col span={12}>
                        <a>
                          <ViewProducts id={record.warehousesaleId} />
                        </a>
                      </Col>
                      <Col span={12}>
                        <a>
                          <ViewWarehouseGenerateBill
                            invoiceId={record.warehousesaleId}
                          /></a>
                      </Col>
                    </Row>
                  );
                }}
              />
            </Table>
          </div>
        </div>
      </div>
    );
  }
}

SalesOut.propTypes = {
  progressBarStatus: PropTypes.bool.isRequired,
  WarehouseSaleList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  progressBarStatus: state.progressBarReducer.showProgressBar,
  saleList: state.warehouseData.warehouseSaleLists,
  whOutBillBySearch: state.warehouseData.whOutBillBySearch
});

export default connect(mapStateToProps, {
  WarehouseSaleList,
})(SalesOut);