import React, { Component } from "react";
import { Table, Popconfirm, Tooltip, Row, Col, Button } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
const { Column } = Table;

export default class ShowWHSalesDetails extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      selectedRowId: null,
      purchaseData: [],
    };
  }


  componentDidMount() {
    this.scrollToBottom();
    window.addEventListener('keydown', this.onKeyDown);
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.myPurchaseData?.length !== this.props?.myPurchaseData?.length) {
      this.scrollToBottom();
    }
  }

  scrollToBottom = () => {
    if (this.tableRef.current) {
      const tableBody = this.tableRef.current.querySelector('.ant-table-body');
      if (tableBody) {
        const rows = tableBody.querySelectorAll('.ant-table-row');
        if (rows.length > 0) {
          rows[rows.length - 1].scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
      }
    }
  };



  onKeyDown = (event) => {
    const { selectedRowId } = this.state;
    const currentIndex = this.props.myPurchaseData.findIndex((item) => item._id === selectedRowId);
    let nextIndex;
    if (event.key === 'ArrowDown') {
      // Move to the next row
      nextIndex = (currentIndex + 1) % this.props.myPurchaseData.length;
    } else if (event.key === 'ArrowUp') {
      // Move to the previous row
      nextIndex = (currentIndex - 1 + this.props.myPurchaseData.length) % this.props.myPurchaseData.length;
    } else {
      return; // Do nothing if a different key is pressed
    }

    this.setState({
      selectedRowId: this.props.myPurchaseData[nextIndex]._id,
    });
  };

  onRowClick = (record) => {
    this.setState({
      selectedRowId: record._id
    })
  }



  onDeleteClick = (id) => {
    this.props.deleteSale(id);
  };
  onRemoveBoxes = (obj) => {
    this.props.onRemoveBoxes(obj);
  };
  onAddBoxes = (obj) => {
    this.props.onAddBoxes(obj);
  };

  render() {
    return (
      <div className="purchase-list">
        <Table
        ref={this.tableRef}
          dataSource={this.props.myPurchaseData.sort((a, b) => a.order - b.order)}
          bordered="true"
          size="middle"
          onRow={(record) => ({
            onClick: () => this.onRowClick(record),
          })}
          rowClassName={(record, index) => {
            if (index === this.props.myPurchaseData.length - 1) {
              return record._id === this.state.selectedRowId && index === this.props.myPurchaseData.length - 1 ? "selected-row" : "table-row-dark"
            }
            if (record._id === this.state.selectedRowId) {
              return record._id === this.state.selectedRowId && index === this.props.myPurchaseData.length - 1 ? "table-row-dark" :  "selected-row"
            }
            if (record.updateQty) {
              return record.updateQty && record._id === this.state.selectedRowId ?  "selected-row" :   "table-row-dark-update"
            }
            return "";
          }}
            // record.updateQty == true ? "table-row-dark" : ""
          
          pagination={false}
          scroll={{ y: 800 }}
        >
          <Column
            title="SRN"
            key="index"
            render={(value, item, index) => index + 1}
            width={60}
          />
          <Column
            title="Barcode"
            dataIndex="barcode"
            key="barcode"
            width={90}
          />
          <Column
            title="Design No."
            dataIndex="designNumber"
            key="designNumber"
            width={120}
          />
          <Column title="Brand" dataIndex="brand" key="brand" width={110} />
          <Column title="Size" dataIndex="size" key="size" width={80} />
          <Column title="Color" dataIndex="color" key="color" width={80} />
          <Column title="MOQ" dataIndex="MOQ" key="MOQ" width={80} />
          <Column title="Boxes" dataIndex="boxes" key="boxes" width={80} />
          <Column title="Qty" dataIndex="saleQty" key="saleQty" width={80} />
          <Column title="Price" dataIndex="purchasePrice" key="purchasePrice" width={80} />
          <Column
            title="Amt"
            dataIndex="finalAmount"
            key="finalAmount"
            width={100}
          />

          <Column
            title="Action"
            key="action"
            width={100}
            render={(text, record) => (
              <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <Popconfirm
                    title="Are you sure to delete this Record?"
                    onConfirm={() => this.onDeleteClick(text._id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip title="Delete Record">
                      <DeleteOutlined />
                    </Tooltip>
                  </Popconfirm>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <Tooltip title="Add Boxes">
                    <a type="button" onClick={() => this.onAddBoxes(text)}>
                      <PlusCircleOutlined />
                    </a>
                  </Tooltip>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  {(() => {
                    if (text.boxes <= 1) {
                      return (
                        <Tooltip title="Remove Boxes">
                          <a type="button" aria-disabled>
                            <MinusCircleOutlined />
                          </a>
                        </Tooltip>
                      );
                    } else {
                      return (
                        <Tooltip
                          title="Remove Boxes"
                          onClick={() => this.onRemoveBoxes(text)}
                        >
                          <a type="button">
                            <MinusCircleOutlined />
                          </a>
                        </Tooltip>
                      );
                    }
                  })()}
                </Col>
              </Row>
            )}
          />
        </Table>
      </div>
    );
  }
}
