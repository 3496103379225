//created by aaditya-
import React, { Component } from "react";
import { Table, Tooltip, Button, Modal, Row, Col } from "antd";
import {
  EyeOutlined
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { warehousePurchaseBill } from "../../../redux/actions/warehouseActions";
import Highlighter from "react-highlight-words";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import AddImages from "../Add Details/Purchase/Purchase Bills/AddImages";
import "../../../assets/css/barcode.css";
import gifLoader from "../../../assets/images/giphy.gif";
var Barcode = require("react-barcode");

const { Column } = Table;

class Show_WH_BillPurchases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowId: null,
      page: "",
      current: 1,
      search: "",
      visible: false,
      visibleB: false,
      barcodes: [],
    };
  }
  componentDidMount = () => {
    // this.props.warehousePurchaseBill();
    window.addEventListener("keydown", this.onKeyDown);
  };
  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (event) => {
    const { selectedRowId } = this.state;
    const currentIndex = this.props.warehouseBill.findIndex(
      (item) => item._id === selectedRowId
    );
    let nextIndex;
    if (event.key === "ArrowDown") {
      // Move to the next row
      nextIndex = (currentIndex + 1) % this.props.warehouseBill.length;
    } else if (event.key === "ArrowUp") {
      // Move to the previous row
      nextIndex =
        (currentIndex - 1 + this.props.warehouseBill.length) %
        this.props.warehouseBill.length;
    } else {
      return; // Do nothing if a different key is pressed
    }

    this.setState({
      selectedRowId: this.props.warehouseBill[nextIndex]._id,
    });
  };

  onRowClick = (record) => {
    this.setState({
      selectedRowId: record._id,
    });
  };

  showModal = () => {
    this.props.warehousePurchaseBill({
      warehousepurchaseId: this.props.warehousepurchaseId,
    });
    this.setState({
      visible: true,
    });
  };
  showModalBarcode = () => {
    this.setState({
      visibleB: true,
      barcodes: this.props.warehouseBill,
    });
  };

  handleModalCancel = () => {
    this.setState({ visible: false });
  };

  handleModalCancelBarcode = () => {
    this.setState({ visibleB: false });
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };

  render() {
    const { visible, visibleB } = this.state;
    let count = 0;
    // let d = new Date();
    // const num = d.getFullYear();
    // const last2Str = String(num).slice(-2);
    // let month = "" + (d.getMonth() + 1);
    // if (month.length < 2) month = "0" + month;
    // let out = month + last2Str;
    const createdAt =  this.state?.barcodes && this.state?.barcodes.length > 0 && this.state.barcodes?.[0]?.created_at;
    const date = new Date(createdAt);
    let month = date.getMonth() + 1;
    const year = date.getFullYear();
    if (month < 10) month = "0" + month;
    const last2Str = String(year).slice(-2);
    const out = month + last2Str;


    let barcodes = this.state.barcodes.map((barcode) => {
      let barcodeData = [];

      let data = (
        <Col sm={24} md={12} lg={12} xl={12} className="">
          <div
            className="box-barcode"
            style={{
              // width: "6cm",
              marginRight: "85px",
              marginLeft: "15px",
              marginTop: "-15px",
              flexGrow: 3,
            }}
          >
            <h3 style={{ marginBottom: "0px" }}> {barcode.brand}</h3>
            {/* =============== */}
            {(() => {
              return (
                <Row className="code-row-one" style={{ width: "300px" }}>
                  <Col sm={24} md={24} lg={24} xl={24}>
                    <p style={{ color: "black" }}>
                      D.No. -{barcode.designNumber}{" "}
                      <span style={{ marginLeft: "5px" }}>{barcode.MOQ}</span>
                    </p>
                  </Col>
                </Row>
              );
            })()}
            <Row
              className="code-row"
              style={{ width: "300px", overflow: "hidden" }}
            >
              <Col sm={24} md={24} lg={24} xl={24}>
                <p style={{ color: "black", whiteSpace: "nowrap" }}>
                  Size. - {barcode.size}{" "}
                  <span style={{ marginLeft: "5px" }}>{barcode.color}</span>{" "}
                </p>
              </Col>
            </Row>
            <h4 className="code" style={{ marginLeft: "30%" }}>
              91{barcode.wsp}
            </h4>
            <Barcode
              height={30}
              width={1.5}
              marginTop={-4}
              marginBottom={-4}
              marginLeft={16}
              displayValue={false}
              value={barcode.barcode}
              className="barcode-strip"
            />
            <h5 className="barcode-number mb-2" style={{ marginLeft: "7.5%" }}>
              {Math.floor(1000 + Math.random() * 9000)}
              {barcode.barcode}
              {out}
            </h5>
          </div>
        </Col>
      );
      let totalBoxesTimes = Math.ceil(Number(barcode.totalBox));
      barcodeData = Array(totalBoxesTimes).fill(data);
      count = count + totalBoxesTimes;
      return barcodeData;
    });
    return (
      <div>
        <button
          className="btn"
          type="button"
          data-original-title=""
          onClick={this.showModal}
        >
          <Tooltip title="View Purchases">
            <EyeOutlined />
          </Tooltip>
        </button>
        {console.log("hello", this.props?.warehouseBill)}
        <Modal
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleModalCancel}
          title="View Purchases"
          style={{ top: 20 }}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          destroyOnClose={true}
          width={1500}
        >
          {this.props?.warehouseBill ? (
            <>
              <Table
                autoFocus
                dataSource={
                  this.props && this.props.warehouseBill
                    ? this.props.warehouseBill
                    : []
                }
                bordered="true"
                onRow={(record) => ({
                  onClick: () => this.onRowClick(record),
                })}
                rowClassName={(record) =>
                  record._id === this.state.selectedRowId ? "selected-row" : ""
                }
                size="middle"
                // pagination={{pageSize: 10,
                //   total: this.props.warehouseBill && this.props.warehouseBill.count ? this.props.warehouseBill.count : "",
                //   onChange: (page) => {
                //     this.props.warehousePurchaseBill(page);
                //   }}}
                pagination={false}
                scroll={{ y: 500 }}
              >
                <Column
                  title="SRN"
                  key="index"
                  render={(value, item, index) => index + 1}
                  width={60}
                />

                <Column
                  title="Barcode"
                  dataIndex="barcode"
                  key="barcode"
                  width={100}
                />
                <Column
                  title="Design No."
                  dataIndex="designNumber"
                  key="designNumber"
                  width={150}
                />
                <Column
                  title="Brand"
                  dataIndex="brand"
                  key="brand"
                  width={120}
                />
                <Column title="Size" dataIndex="size" key="size" width={80} />
                <Column
                  title="Color"
                  dataIndex="color"
                  key="color"
                  width={80}
                />
                <Column title="MOQ" dataIndex="MOQ" key="MOQ" width={60} />
                <Column
                  title="Qnty"
                  dataIndex="quantity"
                  key="quantity"
                  width={60}
                />
                <Column
                  title="Price"
                  dataIndex="purchasePrice"
                  key="purchasePrice"
                  width={90}
                />
                {/* <Column
                  title="Action"
                  key="action"
                  width={80}
                  render={(text, record) => (
                    <Row gutter={[8, 0]}>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <AddImages data={text} />
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <UpdatePurchase updateData={text} />
                  </Col>
                    </Row>
                  )}
                /> */}
              </Table>
              {/* <div className="mt-4">
            <div className="col-md-12 ml-auto">
              <div className="grand-totall" style={{ padding: "18px 17px 4px" }}>
                <div className="title-wrap">
                  <h4 className="cart-bottom-title section-bg-gary-cart">
                    Purchase Total
                  </h4>
                </div>
                <h5>
                  Total Bills{" "}
                  <span>
                    {this.props && this.props.warehouseBill ? this.props.warehouseBill.length : 0}
                  </span>
                </h5>
                <h5>
                  Total Boxes{" "}
                  <span>
                    {(() => {
                      let allBoxes = 0
                      this.props.warehouseBill.map(obj => {
                        allBoxes += obj.quantity / obj.MOQ
                      })
                      return allBoxes
                    })()}
                  </span>
                </h5>
                <h5>
                  Total Quantity{" "}
                  <span>
                    {this.props.data.quantity}
                  </span>
                </h5>
                <h5>
                  Total Amount{" "}
                  <span>
                    {this.props.data.totalAmount}
                  </span>
                </h5>
                <h4 className="grand-totall-title">
                  Net Amount{" "}
                  <span>
                    {this.props.data.netAmount}
                  </span>
                </h4>
              </div>
            </div>
          </div>  */}
              {/* <Button
            className="mt-2 mr-3 float-right"
            type="primary"
            data-original-title=""
            onClick={this.showModalBarcode}
          >
            Generate Barcode
          </Button> */}
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center align-items-center">
                <img src={gifLoader} width={"300px"} height={"300px"} />
              </div>
            </>
          )}

          <ReactToPrint content={() => this.componentRef}>
            <Modal
              visible={visibleB}
              onOk={this.handleOk}
              onCancel={this.handleModalCancelBarcode}
              title="Generate Barcode"
              style={{ top: 20 }}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              destroyOnClose={true}
              width={1300}
            >
              <h5 className="d-flex justify-content-center">
                Total Barcodes : {count}
              </h5>
              <PrintContextConsumer>
                {({ handlePrint }) => (
                  <Button
                    style={{ margin: " 0 0 0 45%" }}
                    type="primary"
                    className="ok-modal"
                    onClick={handlePrint}
                  >
                    Print this out!
                  </Button>
                )}
              </PrintContextConsumer>

              <div className="card barcode-card">
                <div
                  ref={(el) => (this.componentRef = el)}
                  className="card-body"
                >
                  <Row gutter={[8, 0]} className=" my-barcodes">
                    {barcodes}
                  </Row>
                </div>
              </div>
            </Modal>
          </ReactToPrint>
        </Modal>
      </div>
    );
  }
}

Show_WH_BillPurchases.propTypes = {
  warehousePurchaseBill: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => (
  console.log("id wala state", state),
  {
    warehouseBill: state.warehouseData.warehousePurchaseBill,
  }
);

export default connect(mapStateToProps, { warehousePurchaseBill })(
  Show_WH_BillPurchases
);
