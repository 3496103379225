import React, { Component } from "react";
import { Table, Button, Space, Input, Tag } from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import { getStockAgeingReports, getStockAgeingReportsBySearch } from "../../../../redux/actions/reportsActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Highlighter from "react-highlight-words";
import Papa from "papaparse";
import moment from "moment";

const { Column } = Table;
const data = [];

export class Stockagelist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      data: [],
      loading: false,
      csvData: [],
    };
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  this.setState({
                    searchText: selectedKeys[0],
                    searchedColumn: dataIndex,
                  });
                }}
              >
                Filter
              </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };

  render() {
    const { reports } = this.props
    const headers = [
      { label: "Creation Date", key: "created_at" },
      { label: "Purchase Id", key: "purchaseId" },
      { label: "Barcode", key: "barcode" },
      { label: "Design Number", key: "designNumber" },
      { label: "Brand", key: "brand" },
      { label: "Size", key: "size" },
      { label: "Color", key: "color" },
      { label: "Category", key: "category" },
      { label: "Style", key: "style" },
      { label: "Season", key: "season" },
      { label: "Floor Name", key: "floorName" },
      { label: "Section", key: "section" },
      { label: "Purchaser Name", key: "purchasePersonName" },
      { label: "Remaining Boxes", key: "remainingBoxes" },
      { label: "Remaining Quantity", key: "remainingQuanitity" },
      { label: "WSP", key: "wsp" },
      { label: "Total Amount", key: "totalAmount" },
      { label: "Stock Age", key: "stockAge" },
      { label: "Dealer Name", key: "dealerName" }, 
    ];
    
    const handleDownloadButtonClick = async () => {
      this.setState({ loading: true });

      try {
        // Call your API here
        const response = await this.props.getStockAgeingReportsBySearch(
          this.props?.reportObjAll
        );
        console.log("check nagor response", response);

        const csvData = response?.data
        // Generate CSV content using PapaParse
        const csv = Papa.unparse({
          fields: headers?.map((header) => header.label),
          data: csvData?.map((row) => headers?.map((header) => row[header.key])),
        });

        // Create Blob and initiate file download
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${this.props?.startDay} Days to ${this.props?.endDay} Days.csv`;
        link.click();
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error(error);
      } finally {
        this.setState({ loading: false });
      }
    };
    return (
      <>
        <div className="card mt-4 final-purchases-list">
          <div className="card-body">
            <Table
              dataSource={reports && reports.data && reports.data.length > 0 ? reports.data : []}
              bordered="true"
              size="middle"
              scroll={{ y: 240 }}
              pagination={{
                pageSize: 30,
                total: reports?.count,
                onChange: (page) => {
                  this.props.getStockAgeingReportsBySearch({
                    ...this.props.obj,
                    endDate:this.props?.reportObjAll?.endDate,
                    limit: page,
                    perPage:30
                  });
                },
              }}
            >
              <Column
                title="SRN"
                key="index"
                render={(value, item, index) => index + 1}
                width={60}
              />
              <Column
                title="Creation Date"
                dataIndex="created_at"
                key="created_at"
                render={(text) => moment(text).format("DD-MM-YYYY")}
                width={130}
              />
              <Column
                title="Purchase Id"
                {...this.getColumnSearchProps("purchaseId")}
                dataIndex="purchaseId"
                key="purchaseId"
                width={130}
              />
              <Column
                title="Barcode"
                {...this.getColumnSearchProps("barcode")}
                dataIndex="barcode"
                key="barcode"
                width={130}
              />

              <Column
                title="Design No"
                dataIndex="designNumber"
                key="designNumber"
                width={140}
              />
              <Column title="Brand" dataIndex="brand" key="brand" width={100} />
              <Column title="Size" dataIndex="size" key="size" width={100} />
              <Column title="Color" dataIndex="color" key="color" width={100} />
              <Column title="Category" dataIndex="category" key="category" width={100} />
              <Column title="Style" dataIndex="style" key="style" width={100} />
              <Column title="Season" dataIndex="season" key="season" width={100} />
              <Column title="Floor Name" dataIndex="floorName" key="floorName" width={130} />
              <Column title="Section" dataIndex="section" key="section" width={100} />
              <Column title="Purchaser Name" dataIndex="purchasePersonName" key="purchasePersonName" width={140} />
              <Column
                title="Remaining Boxes"
                dataIndex="remainingBoxes"
                key="remainingBoxes"
                width={180}
              />
              <Column
                title="Remaining Quantity"
                dataIndex="remainingQuanitity"
                key="remainingQuanitity"
                width={200}
              />
              <Column title="WSP" dataIndex="wsp" key="wsp" width={100} />
              <Column
                title="Total Amount"
                dataIndex="stockAge"
                key="stockAge"
                width={140}
                render={(value, item, index) => Number(item?.quantity) * Number(item?.wsp)}
              />
              <Column
                title="Stock Age"
                dataIndex="stockAge"
                key="stockAge"
                width={140}
              />
              <Column
                title="Dealer Name"
                dataIndex="dealerName"
                key="dealerName"
                width={140}
              />
            </Table>
          </div>
        </div>
        {(() => {
          if (this.props?.reports && this.props?.reports?.data && this.props.reports?.count > 0) {
            return (
              <>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="save-modal mt-4"
                  block
                  onClick={handleDownloadButtonClick}
                >
                  {this.state.loading ? "Loading..." : "Download CSV"}
                </Button>
              </>
            );
          }
        })()}
      </>
    );
  }
}

Stockagelist.propTypes = {
  getStockAgeingReports: PropTypes.func.isRequired,
  getStockAgeingReportsBySearch: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
  stockagereport: state.reportData.stockageReport,
});


export default connect(mapStateToProps, {
  getStockAgeingReports, getStockAgeingReportsBySearch
})(Stockagelist);