import React, { Component } from "react";
import { Table, Button, Space, Input, Row, Col } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Highlighter from "react-highlight-words";
import moment from "moment";
import DateWiseSearch from "../../../../../util/DateWiseSearch";
import { getPurchaseReturns, purchaseReturnBydate } from "../../../../../redux/actions/purchaseAction";
import TopBarProgress from "react-topbar-progress-indicator";
const { Column } = Table;

class PurchaseReturns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      options: [],
    };
  }
  componentDidMount() {
    let startDate = moment(new Date()).format("YYYY-MM-DD")
    let endDate = moment(new Date()).format("YYYY-MM-DD")
    let reportObj = {
      startDate: startDate,
      endDate: endDate + " " + moment().format("HH:mm:ss"),
    };
    this.props.purchaseReturnBydate(reportObj)
    // this.props.getPurchaseReturns(1);
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };

  listData=()=>{
    const { purchasereturnByDate, purchaseReturns } = this.props
    const value  = purchasereturnByDate && purchasereturnByDate.length > 0 ? purchasereturnByDate[0] : [] 
    if(value.message == "Empty" ){
      return([])
    }else if(purchasereturnByDate.data){
      return(purchasereturnByDate.data)
    }else{
      return(purchaseReturns.data ? purchaseReturns.data : [] )
    }
    
  }


  render() {
    console.log("#this.props.purchaseReturns", this.props.purchaseReturns);
    const{purchasereturnByDate}= this.props
    return (
      <div className="subCategory-dashboard">
        {this.props.progressBarStatus && <TopBarProgress />}
        <div className="container-fluid category-list">
           {/* ==============Search Bar and Date Filter================= */}
           <Row className="title-row">
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <div className="page-header">Purchase Returns</div>
            </Col>
          </Row>
          <Row className="mt-4">  
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <DateWiseSearch keyId={"Purchase Returns"}/>
            </Col>
          </Row>
          {/* ======================================== */}

          <div className="card">
            <div className="card-body">
              <Table
               dataSource={this.listData()}
                // dataSource={purchasereturnByDate.data ? purchasereturnByDate.data : this.props.purchaseReturns && this.props.purchaseReturns.data ? this.props.purchaseReturns.data :[]}
                bordered="true"
                size="middle"
                pagination={{pageSize: 10,
                  total: this.props.purchaseReturns && this.props.purchaseReturns.count ? this.props.purchaseReturns.count : "",
                  onChange: (page) => {
                    this.props.getPurchaseReturns(page);
                  }}}
                scroll={{ y: 400 }}
              >
                <Column
                  title="SRN"
                  key="index"
                  render={(value, item, index) => index + 1}
                  width={60}
                />
                {/** <Column
                  title="Bill Date"
                  dataIndex="date"
                  key="date"
                  width={90}
                  render={(value, item, index) =>
                    moment(item.date).format("YYYY-MM-DD")
                  }
                /> */}
                <Column
                  title="Barcode"
                  dataIndex="barcode"
                  key="barcode"
                  width={100}
                  {...this.getColumnSearchProps("barcode")}
                />
                <Column
                  title="Purchase Id"
                  dataIndex="purchaseId"
                  {...this.getColumnSearchProps("purchaseId")}
                  key="purchaseId"
                  width={120}
                />
                <Column
                  title="Dealer Name"
                  {...this.getColumnSearchProps("dealerName")}
                  dataIndex="dealerName"
                  key="dealerName"
                  width={150}
                  render={(value, item, index) => `${item.dealerId.name}`}
                />

                <Column
                  title="Qnty"
                  dataIndex="quantity"
                  key="quantity"
                  width={100}
                />

                <Column
                  title="Price"
                  dataIndex="price"
                  key="price"
                  width={120}
                />
              </Table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PurchaseReturns.propTypes = {
  getPurchaseReturns: PropTypes.func.isRequired,
  purchaseReturnBydate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  purchaseReturns: state.purchaseData.purchaseReturns,
  purchasereturnByDate: state.purchaseData.purchasereturnByDate,
  progressBarStatus : state.progressBarReducer.showProgressBar
});

export default connect(mapStateToProps, {
  getPurchaseReturns,purchaseReturnBydate
})(PurchaseReturns);
