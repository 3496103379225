/* /////////////////////////////////////// */
/* 
Date              created by               Modifications

06-07-2023        Mohit                    onSubmit Function Modified              
*/
/* /////////////////////////////////////// */



import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Modal, Button, Form, Input, Upload, Tooltip } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { message } from "antd";
import { addEcomSale, getUniquePurchases } from "../../../redux/actions/purchaseAction";

class AddEcomSale extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      salesDiscount: 0,
      status: "ON",
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleModalCancel = () => {
    this.setState({ visible: false });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errors !== this.props.errors) {
      //Perform some operation here

      this.setState({ errors: this.props.errors });
      message.error(this.props.errors.message);
    }
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onSubmit(event) {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 1000);
    let objarray = this.props?.data?.map(obj => ({ _id: obj._id, barcode: obj.barcode, status: "ON", salesDiscount: this.state.salesDiscount }))
    this.props.addEcomSale({ toggleData: objarray });
    // this.props.getUniquePurchases(1, "salesList");
    // this.props.modalCancel()
    this.props.updateOffers()
    setTimeout(() => {
      this.setState({ fileList: [] });
    }, 1000);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errors !== this.props.errors) {
      //Perform some operation here
      this.setState({ errors: this.props.errors });
      message.error(this.props.errors.message);
    }
  }
  render() {
    const { visible, loading } = this.state;
    return (
      <Row>
        <Col span={24}>
          <div>
            <div className="btn" type="button" onClick={this.showModal}>
              <Tooltip title="Add E-com Offer">
                <Button>
                  Apply Offer
                </Button>
              </Tooltip>
            </div>
            <Modal
              visible={visible}
              onOk={this.handleOk}
              onCancel={this.handleModalCancel}
              title="Add Ecom Sale"
              style={{ top: 20 }}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              destroyOnClose={true}
            >
              <Form
                name="add-Location"
                className="add-Location"
                onFinish={this.onSubmit}
                initialValues={{
                  remember: true,
                }}
              >
                <Form.Item name="status">
                  <Input
                    placeholder="Sale Status"
                    defaultValue={this.state.status}
                    // onChange={this.onChange}
                    disabled
                  />
                </Form.Item>
                <Form.Item
                  name="salesDiscount"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Sales Discount !",
                    },
                  ]}
                >
                  <Input
                    name="salesDiscount"
                    type="number"
                    min="0"
                    step="1"
                    placeholder="Sales Discount"
                    value={this.state.salesDiscount}
                    onChange={this.onChange}
                  />
                </Form.Item>

                <Form.Item className="float-right">
                  <Button
                    className="close-modal mr-3"
                    onClick={this.handleModalCancel}
                  >
                    Close
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="ok-modal"
                    loading={loading}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
          </div>
        </Col>
      </Row>
    );
  }
}
AddEcomSale.propTypes = {
  addEcomSale: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  getUniquePurchases: PropTypes.func.isRequired,
};

export default connect(null, { addEcomSale, getUniquePurchases })(AddEcomSale);
