import React, { Component, useEffect, useState } from "react";
import {
  Table,
  Popconfirm,
  Tooltip,
  Row,
  Col,
  Button,
  Card,
  Input,
} from "antd";
import {
  CloseOutlined,
  CloudOutlined,
  DeleteOutlined,
  EditOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import NewLookUpModalSales from "./NewLookUpModalSales";
import "./salesCard.css";
const { Column } = Table;

const ShowNewSalesDetails = ({
  data,
  title,
  onAddingBoxes,
  onRemoveBoxes,
  onDeleteClick,
  buttondisable,
  myPurchaseData,
  addDataonList,
}) => {
  const [quantity, setQuantity] = useState(885);

  const handleIncrement = () => setQuantity(quantity + 1);
  const handleDecrement = () => setQuantity(quantity > 0 ? quantity - 1 : 0);

  const productData = data?.sort((a, b) => a.order - b.order);
  const openSuggestionModal = (item, myPurchaseData, addDataonList) => {
    // Render the modal
    return (
      <NewLookUpModalSales
        data={item}
        myPurchaseData={myPurchaseData}
        addDataonList={addDataonList}
      />
    );
  };
  console.log("Product Data", productData);

  const handleColourClass = (item) => {
    if (item.updateQty) {
      return { backgroundColor: "#FFCC81", fontWeight: "bold" };
    } else {
      return { backgroundColor: "#339af0", fontWeight: "bold" };
    }
  };
  return (
    <div className="list">
      {/* <Table
        dataSource={data?.sort((a, b) => a.order - b.order)}
        bordered="true"
        size="medium"
        pagination={false}
        scroll={{ y: 400 }}
        rowClassName={(record, index) => {
          // if (index === myPurchaseData.length - 1) {
          //   console.log("check inside 1")
          //   return index === myPurchaseData.length - 1 ? "selected-row" : "table-row-dark"
          // }
          console.log("check inside 3", record)

          if (record.updateQty) {
            return record.updateQty && "table-row-dark-update"
          }
          return "";
        }}
      >
        <Column
          title="SRN"
          key="index"
          render={(value, item, index) => index + 1}
          width={65}
        />
        <Column
          title="Suggestions"
          dataIndex=""
          key="salesPersonName"
          width={140}
          render={(record, text) => (
            <NewLookUpModalSales data={record} myPurchaseData={myPurchaseData} addDataonList={addDataonList} />
          )}
        />
        <Column
          title="Barcode"
          dataIndex="barcode"
          key="barcode"
          width={120}
        />
        <Column
          title="Design No."
          dataIndex="designNumber"
          key="designNumber"
          width={130}
        />
        <Column title="Brand" dataIndex="brand" key="brand" width={100} />
        <Column title="Size" dataIndex="size" key="size" width={100} />
        <Column title="Color" dataIndex="color" key="color" width={100} />
        <Column title="MOQ" dataIndex="MOQ" key="MOQ" width={80} />
        <Column title="Boxes" dataIndex="boxes" key="boxes" width={90} />
        <Column title="Qty" dataIndex="saleQty" key="saleQty" width={80} />
        <Column title="Remaining Qty" dataIndex="saleQty" key="saleQty" width={150} render={(record, text) => text.totalPurchaseValue - text.saleQty} />
        <Column title="WSP" dataIndex="wsp" key="wsp" width={80} />
        <Column
          title="Amount"
          dataIndex="finalAmount"
          key="finalAmount"
          width={100}
        />
        <Column title="Action" key="Action" width={180} align="center" render={(record, text) => (
          <Row gutter={16} align="center" justify="center" style={{ margin: "0 !important" }}>
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
              <Popconfirm

                className="sales-btn"
                title="Are you sure to delete this Record?"
                onConfirm={() => onDeleteClick(text)}
                okText="Yes"
                cancelText="No"
                disabled={buttondisable}
              >
                <Tooltip title="Delete Record">
                  <DeleteOutlined style={{ marginTop: "0px" }} />
                </Tooltip>
              </Popconfirm>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>

              <button
                className="sales-btn"
                type="button"
                disabled={buttondisable}
                onClick={() => onAddingBoxes(text)}>
                <Tooltip title="Add Boxes">
                  <PlusCircleOutlined />
                </Tooltip>
              </button>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} xl={8}>
              <button
                className="sales-btn"
                type="button"
                disabled={buttondisable}
                onClick={() => onRemoveBoxes(text)}>
                <Tooltip title="Remove Boxes">
                  <MinusCircleOutlined />
                </Tooltip>
              </button>
            </Col>
          </Row>
        )} />
        <Column
          title="Sales Person"
          dataIndex="salesPersonName"
          key="salesPersonName"
          width={150}
        />

      </Table> */}
      <div className="manageCardHieght">
        {productData?.map((item) => {
          return (
            <>
              <div
                className="border border-black mt-4 border-10"
                key={item._id}
              >
                <div
                  className="productCardHeading d-flex justify-content-between align-items-center px-3 py-2 text-white font-weight-bold"
                  style={{
                    ...handleColourClass(item),
                    borderRadius: "10px 10px 0px 0px",
                  }}
                >
                  <div>
                    {/* <span className="mr-2">Barcode:</span> */}
                    <span className="mr-2">{item.barcode}</span>
                    <span
                      className="mr-2"
                      style={{
                        color: "#fff",
                        backgroundColor: "red",
                        borderRadius: "5px",
                        fontSize:"12px",
                        padding : item.saleQty == item.totalPurchaseValue ? "0px 3px" : "0px"
                      }}
                    >
                      {item.saleQty == item.totalPurchaseValue
                        ? "All Qty Added"
                        : ""}
                    </span>
                  </div>
                  <Button
                    onClick={() => onDeleteClick(item)}
                    disabled={buttondisable}
                    style={{ backgroundColor: "#339af0" }}
                    className="border-0 text-white"
                  >
                    <CloseOutlined />
                  </Button>
                </div>

                <div className="productCardBody d-flex justify-content-center align-items-center">
                  <Row
                    gutter={24}
                    className="w-100 d-flex justify-content-between "
                  >
                    <Col sm={12} md={12} lg={12} align="left">
                      <div className="my-2">
                        <strong className="mr-2">D.No:</strong>
                        <span>{item.designNumber}</span>
                      </div>
                      <div className="my-2">
                        <strong className="mr-2">Size:</strong>
                        <span>{item.size}</span>
                      </div>
                      <div className="my-2">
                        <strong className="mr-2">WSP:</strong>
                        <span>{item.wsp}</span>
                      </div>
                    </Col>

                    <Col sm={12} md={12} lg={12} align="right">
                      <div className="my-2">
                        <strong className="mr-2">Brand:</strong>
                        <span>{item.brand}</span>
                      </div>
                      <div className="my-2">
                        <strong className="mr-2">Color:</strong>
                        <span>{item.color}</span>
                      </div>
                      <div className="my-2">
                        <strong className="mr-2">Remaining:</strong>
                        <span>{item.totalPurchaseValue - item.saleQty}</span>
                      </div>

                      {/* <div className="my-2">
                        <Row
                          justify="center"
                          align="middle"
                          style={{ marginTop: "15px" }}
                        ></Row>
                      </div> */}
                    </Col>
                  </Row>
                </div>

                <Row gutter={24} className="mt-2 mb-2" align="end">
                  <Col sm={12} md={12} lg={12} align="right">
                    <Button
                      disabled={buttondisable}
                      onClick={() => onRemoveBoxes(item)}
                      style={{ marginRight: "10px" }}
                    >
                      -
                    </Button>
                    <Input
                      value={item.saleQty}
                      readOnly
                      style={{ width: "60px", textAlign: "center" }}
                    />
                    <Button
                      disabled={buttondisable}
                      onClick={() => onAddingBoxes(item)}
                      style={{ marginLeft: "10px" }}
                    >
                      +
                    </Button>
                  </Col>
                </Row>

                <NewLookUpModalSales
                  data={item}
                  myPurchaseData={myPurchaseData}
                  addDataonList={addDataonList}
                />

                {/* <Button
                  style={{
                    backgroundColor: "#d0ebff",
                    padding: "2px 0px",
                    textAlign: "center",
                    width: "100%",
                  }}
                  onClick={() => 
                    return ( <NewLookUpModalSales
                      data={item}
                      myPurchaseData={myPurchaseData}
                      addDataonList={addDataonList}
                    />)
                  }
                >
                  <span>Suggestions</span>
                  <CloudOutlined style={{ marginLeft: "10px" }} />
                </Button> */}
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default ShowNewSalesDetails;
