import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPurchaseOrderByID, updatePO } from "../../../../../redux/actions/purchaseOrderAction";
import { Table, Button, Card, Row, Col, message, Tooltip, Form, Select, DatePicker, Input } from 'antd';
import { ArrowLeftOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import UpdatePurchaseOrder from "./UpdatePurchaseOrder";
import moment from 'moment';
import { getDealers } from "../../../../../redux/actions/dealerAction";
import { getCustomers } from "../../../../../redux/actions/customerAction";

const UpdatePurchaseOrderBill = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = location.state || {};
    const [tax, setTax] = useState()
    const [discount, setDiscount] = useState()
    const [totalAmount, setTotalAmount] = useState()
    const [netAmount, setNetAmount] = useState()

    const purchaseOrderData = useSelector((state) => state.purchaseOrderData.purchaseOrdersByID);




    const [dealerName, setDealerName] = useState()
    const [dealerId, setDealerId] = useState()
    const [billNo, setBillNo] = useState()
    const [date, setDate] = useState()
    const [poDate, setPoDate] = useState()
    const [purchasePersonId, setPurchasePersonId] = useState()
    const [purchasePersonName, setPurchasePersonName] = useState()
    const [quantity, setQuantity] = useState(0);
    const [purchaseOrders, setUpdatedPurchaseOrderData] = useState([]);
    const dealerList = useSelector((state) => state.dealersData.dealers)
    const customerList = useSelector((state) => state.customerData.salesPerson)
    let userId = localStorage.getItem("userId");

    const columns = [
        {
            title: "SRN",
            key: "index",
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Dealer Name',
            dataIndex: 'dealerName',
            key: 'dealerName',
        },
        {
            title: 'Design No.',
            dataIndex: 'designNumber',
            key: 'designNumber',
        },
        {
            title: 'Brand',
            dataIndex: 'brand',
            key: 'brand',
        },
        {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
        },
        {
            title: 'Color',
            dataIndex: 'color',
            key: 'color',
        },
        {
            title: 'MOQ',
            dataIndex: 'MOQ',
            key: 'MOQ',
        },
        {
            title: 'Qnty',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Price',
            dataIndex: 'purchasePrice',
            key: 'purchasePrice',
        },
        {
            title: 'WSP',
            dataIndex: 'wsp',
            key: 'wsp',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <>
                    <Row gutter={[8, 0]}>
                        <Col sm={24} md={24} lg={24}>
                            <Button className="" style={{ color: 'black' }} type="link" >
                                <UpdatePurchaseOrder data={record} updatedData={updatedData} />
                            </Button>
                        </Col>
                    </Row>
                </>
            ),
        },
    ];

    useEffect(() => {
        const obj = {
            poId: id
        };
        dispatch(getPurchaseOrderByID(obj));
    }, [id]);


    useEffect(() => {
        dispatch(getDealers())
        dispatch(getCustomers("PURCHASER"))
    }, []);


    const updatedData = (updatedItem) => {
        setUpdatedPurchaseOrderData((prevData) =>
            prevData.map((item) => {
                if (item._id === updatedItem._id) {
                    return { ...item, ...updatedItem };
                }
                return item;
            })
        );
    };


    useEffect(() => {
        setUpdatedPurchaseOrderData(purchaseOrderData?.purchaseOrder);

    }, [purchaseOrderData?.purchaseOrder]);

    useEffect(() => {
        const dealer = purchaseOrderData?.purchaseOrder[0].dealerName
        const purchase = purchaseOrderData?.purchaseOrder[0].purchasePersonName
        const bill = purchaseOrderData?.purchaseOrder[0].billNo
        setBillNo(bill)
        setDealerName(dealer)
        setPurchasePersonName(purchase)
        const updatedPurchaseOrderDataWithTotal = calculateTotalAmount(purchaseOrders);
        const calculateGrandTotal = (updatedPurchaseOrderDataWithTotal) => {
            return updatedPurchaseOrderDataWithTotal?.reduce((total, order) => {
                return total + order.totalAmount;
            }, 0);
        };

        const calculateTotalQuantity = (purchaseOrders) => {
            return updatedPurchaseOrderDataWithTotal?.reduce((total, order) => {
                return total + order.quantity;
            }, 0);
        };
        const Quantity = calculateTotalQuantity(purchaseOrders)
        const grandTotal = calculateGrandTotal(updatedPurchaseOrderDataWithTotal);
        const discountBill = Number(purchaseOrderData?.billDetails?.tax)
        const discountTax = Number(purchaseOrderData?.billDetails?.tax)
        const discountAmount = (grandTotal * discountBill) / 100
        const finalAmount = Number(grandTotal) - Number(discountAmount);
        const taxAmount = (finalAmount * discountBill) / 100
        const netTotalAmount = Number(taxAmount) + Number(finalAmount);
        setDiscount(purchaseOrderData?.billDetails?.tax)
        setTax(purchaseOrderData?.billDetails?.tax)
        setTotalAmount(grandTotal);
        setNetAmount(netTotalAmount)
        setQuantity(Quantity || 0)
    }, [purchaseOrders]);

    const calculateTotalAmount = (purchaseOrders) => {
        return purchaseOrders?.map(order => {
            const { quantity, purchasePrice, discount } = order;
            const baseAmount = quantity * purchasePrice;
            const discountAmount = (discount / 100) * baseAmount;
            const totalAmount = baseAmount - discountAmount;

            return {
                ...order,
                totalAmount
            };
        });
    };

    const handleDealerName = (value) => {
        const selectedDealer = dealerList.find((item) => item._id === value);

        setDealerName(selectedDealer.name)
        setDealerId(selectedDealer._id)
    }

    const handlePurchaser = (value) => {
        const selectedPurchase = customerList.find((item) => item._id === value);
        // console.log("selectedPurchaseselectedPurchase", selectedPurchase?._id)
        setPurchasePersonName(selectedPurchase?.name)
        setPurchasePersonId(selectedPurchase?._id)

    }

    useEffect(() => {
        if (purchaseOrderData?.billDetails?.poDate) {
            setPoDate(moment(purchaseOrderData.billDetails.poDate, "DD/MM/YYYY")); 
        }
        if (purchaseOrderData?.billDetails?.billDate) {
            setDate(moment(purchaseOrderData.billDetails.billDate, "DD/MM/YYYY")); 
        }
    }, [purchaseOrderData]);

    const handleDateChange = (date, dateString) => {
        setDate(date)
        const PODate = moment(date) > poDate  ? moment(date) : poDate
        setPoDate(PODate)
    };


    const handlePoDateChange = (date, dateString) => {
        setPoDate(date)
    };

    const handleBillNumber = (e) => {
        const value = e.target.value
        setBillNo(value)
    }

    const handleUpdate = () => {

        const validateDealerId = dealerId ? dealerId : purchaseOrderData?.billDetails?.dealerId
        const poIdData = purchaseOrderData?.billDetails?.poId
        const location = purchaseOrderData?.purchaseOrder?.[0]?.location
        const reqObj = {
            purchasePersonName,
            purchasePersonId,
            dealerName,
            dealerId: validateDealerId,
            isWarehouse: location === "WAREHOUSE D2" ? true : false,
            billNo,
            date: moment(date),
            poDate: moment(poDate),
            purchaseOrders,
            userId: userId,
            discount: discount,
            tax: tax,
            netAmount: netAmount,
            totalAmount: totalAmount,
            poId: poIdData,
            quantity
        }
        dispatch(updatePO(reqObj))
            .then((respo) => {
                if (respo.status === 200) {
                    message.success("PO Updated Successfully")
                } else {
                    message.error("Something Went Wrong")
                }
            })
            .catch((respo) => {
                message.error("Something Went Wrong")
            })
            .finally((respo) => {
                setTimeout(() => {
                    history.push("/dagaImpex/purchaseOrderList")
                    window.location.reload();
                }, 300);
            })
    }


    return (
        <>
            <Card>
                <Row>
                    <Col sm={24} md={16} lg={18}>
                        <h3 className="mb-4">Update Purchase Order</h3>
                    </Col>
                    <Col sm={24} md={8} lg={6} className="d-flex justify-content-end">
                        <Button onClick={() => {
                            history.push("/dagaImpex/purchaseOrderList")
                        }}>
                            <ArrowLeftOutlined className="mr-2 mb-1" /> Back
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col className='w-100 px-2' sm={12} md={6} lg={6}>
                        <Tooltip title="Dealer's Name">
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Enter Dealer!",
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    value={dealerName || undefined}
                                    placeholder="Select Dealer"
                                    onChange={handleDealerName}
                                    options={dealerList?.map(dealer => ({
                                        value: dealer._id,
                                        label: dealer.name,
                                    }))}
                                />
                            </Form.Item>
                        </Tooltip>
                    </Col>

                    <Col className='w-100 px-2' sm={12} md={6} lg={6}>
                        <Tooltip title="Enter Purchaser">
                            <Form.Item
                            >
                                <Select
                                    showSearch
                                    value={purchasePersonName || undefined}
                                    placeholder="Select Purchaser"
                                    onChange={(value) => (handlePurchaser(value))}
                                    options={customerList?.map(customer => ({
                                        value: customer._id,
                                        label: customer.name,
                                    }))}
                                    disabled
                                />
                            </Form.Item>
                        </Tooltip>
                    </Col>

                    <Col className='w-100 px-2' sm={12} md={6} lg={6}>
                        <Tooltip title="Enter Bill Number">
                            <Form.Item
                            >
                                <Input
                                    value={billNo || undefined}
                                    onChange={handleBillNumber}
                                    placeholder="Enter Bill Number"
                                />
                            </Form.Item>
                        </Tooltip>
                    </Col>



                    <Col className='w-100 px-2' sm={12} md={6} lg={3}>
                        <Tooltip title="Bill Date">
                            <Form.Item>
                                <DatePicker
                                    value={date}
                                    onChange={handleDateChange}
                                />
                            </Form.Item>
                        </Tooltip>
                    </Col>

                    <Col className='w-100 px-2' sm={12} md={6} lg={3}>
                        <Tooltip title="PO Date">
                            <Form.Item>
                                <DatePicker
                                    value={poDate}
                                    onChange={handlePoDateChange}
                                    disabledDate={(current) => current <= date}
                                />
                            </Form.Item>
                        </Tooltip>
                    </Col>

                </Row>

                <Table
                    dataSource={purchaseOrders}
                    columns={columns}
                    bordered="true"
                    size="middle"
                    pagination={false}
                />
                <Button className="mt-4 w-100" type="primary" onClick={handleUpdate}>Update</Button>
            </Card>
        </>
    );
};

export default UpdatePurchaseOrderBill;
