import React, { Component } from "react";
import { Table } from "antd";
import moment from "moment";

const { Column } = Table;

export default class BarcodeDetails extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    
    return (
      <div className="purchase-list">
        <Table
          dataSource={[this.props.barcodeDeatils]}
          bordered="true"
          size="middle"
          pagination={false}
          scroll={{ y: 240 }}
        >
          <Column
            title="SRN"
            key="index"
            render={(value, item, index) => index + 1}
            width={60}
          />
          <Column
            title="Purchase Id"
            dataIndex="purchaseId"
            key="purchaseId"
            width={120}
          />
          <Column
            title="Date"
            dataIndex="billDate"
            key="billDate"
            width={115}
          />
          <Column title="Brand" dataIndex="brand" key="brand" width={110} />
          <Column
            title="Design No."
            dataIndex="designNumber"
            key="designNumber"
            width={115}
          />
           <Column
            title="MOQ"
            dataIndex="MOQ"
            key="MOQ"
            width={115}
          />
          <Column
            title="Dealer Name"
            dataIndex="dealerName"
            key="dealerName"
            width={170}
          />
          <Column
            title="Stock"
            dataIndex="stock"
            key="stock"
            width={120}
          />
        </Table>
      </div>
    );
  }
}
