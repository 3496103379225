//created by aaditya-
import React, { Component } from "react";
import {
  Table,
  Row,
  Col,
  Select,
  Button,
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import moment from "moment";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import { WarehousepurchasebillList } from "../../../redux/actions/warehouseActions";
import Show_WH_BillPurchases from "./Show_WH_BillPurchases";
import WhSearch from "../../../util/WhSearch";

const searchOptions = ["Barcode", "warehousepurchaseId", "DealerName"]

const { Column } = Table;
const { Option } = Select;
const Id = localStorage.getItem("roleName")
class WarehousePurchaseBill extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      options: [],
      searchValue: "",
      searchAlias: "",
      totalCount: 0,
      checkDate: false
    };
  }
  componentDidMount() {
    this.props.WarehousepurchasebillList(1)
  }

  cancelState = () => {
    this.setState({ searchValue: "" });
  }
  onChange = (event) => {
    this.setState({ searchValue: event });
  };



  redirectBackButtonFun = () => {
    this.props.history.push(
      `${this.state.lastPath || "/dagaImpex/warehouse"}`,
      true
    );
  };
  render() {
    //Table data-
    const purchaseBillList = this.props.purchaseList;

    const { searchValue } = this.state;
    console.log("searchValue", searchValue);
    const { whPurchaseBysearch } = this.props
    console.log("whPurchaseBysearch", whPurchaseBysearch);
    return (
      <div className="subCategory-dashboard">
        <div className="container-fluid category-list">
          <Row className="title-row" justify="space-between">
            <Col>
              <div className="page-header">Warehouse Purchase Bill</div>
            </Col>
            <Col>
              <div style={{ display: "flex", alignItems: "center" }}>

                <Button
                  type="primary"
                  className="save-modal text-white"
                  onClick={() => this.redirectBackButtonFun()}
                  style={{ marginLeft: "5px" }}
                >
                  <ArrowLeftOutlined /> Back
                </Button>
              </div>
            </Col>
          </Row>

          <Row className="mt-4">
            {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}> */}
            <WhSearch title="Warehouse Purchase Bill" placeholder="Dealer Name, PurchaseId or Barcode" searchopt={searchOptions} changeFunction={this.onChange} cancelState={this.cancelState} location={this.props.location} />
            {/* </Col> */}

          </Row>

          <div className="card  final-purchases-list" style={{ marginTop: "15px" }}>
            <div className="card-body">
            <Table
                dataSource={searchValue ? whPurchaseBysearch : purchaseBillList?.data ? purchaseBillList?.data : []}
                bordered="true"
                size="middle"
                pagination={{
                  // defaultPageSize: 100,
                  total:
                    searchValue && whPurchaseBysearch?.length > 0
                      ? whPurchaseBysearch?.length
                      : purchaseBillList?.Count,
                  onChange: (page) => {
                    this.props.WarehousepurchasebillList(page);
                  },
                }}
                scroll={{ y: 440 }}
              //   summary={() => (

              //     <Table.Summary fixed={'bottom'}>
              //       <Table.Summary.Row>
              //         <Table.Summary.Cell className="grand-Total-summary" index={1} colSpan={2}>
              //           Grand Total
              //         </Table.Summary.Cell>
              //         <Table.Summary.Cell index={4} colSpan={1}>{searchData && searchData.details ? searchData.details.length : purchasesBillsBydate && purchasesBillsBydate.details ? purchasesBillsBydate.details.length : ""}</Table.Summary.Cell>
              //         <Table.Summary.Cell index={4} colSpan={2}>{ }</Table.Summary.Cell>
              //         <Table.Summary.Cell index={4} colSpan={1}> {searchData && searchData.sum && searchData.sum.totalBoxes ? searchData.sum.totalBoxes : purchasesBillsBydate && purchasesBillsBydate.sum && purchasesBillsBydate.sum.totalBoxes ? purchasesBillsBydate.sum.totalBoxes : ""}</Table.Summary.Cell>
              //         <Table.Summary.Cell index={4} colSpan={1}>   {searchData && searchData.sum && searchData.sum.quantity ? searchData.sum.quantity : purchasesBillsBydate && purchasesBillsBydate.sum && purchasesBillsBydate.sum.quantity ? purchasesBillsBydate.sum.quantity : ""}</Table.Summary.Cell>
              //         <Table.Summary.Cell index={4} colSpan={1}>{searchData && searchData.sum && searchData.sum.totalAmount ? Number(searchData.sum.totalAmount).toFixed() : purchasesBillsBydate && purchasesBillsBydate.sum && purchasesBillsBydate.sum.totalAmount ? Number(purchasesBillsBydate.sum.totalAmount).toFixed() : ""}</Table.Summary.Cell>
              //         <Table.Summary.Cell index={4} colSpan={1}>{ }</Table.Summary.Cell>
              //         <Table.Summary.Cell index={4} colSpan={1}>{ }</Table.Summary.Cell>
              //         <Table.Summary.Cell index={4} colSpan={1}>{searchData && searchData.sum && searchData.sum.netAmount ? Number(searchData.sum.netAmount).toFixed() : purchasesBillsBydate && purchasesBillsBydate.sum && purchasesBillsBydate.sum.netAmount ? Number(purchasesBillsBydate.sum.netAmount).toFixed() : ""}</Table.Summary.Cell>

              //       </Table.Summary.Row>
              //     </Table.Summary>
              //   )}
              >
                <Column
                  title="SRN"
                  key="index"
                  render={(value, item, index) => index + 1}
                  width={60}
                />
                <Column
                  title="Purchase Id"
                  dataIndex="warehousepurchaseId"
                  key="purchaseId"
                  width={150}
                />
                <Column
                  title="Dealer Name"
                  dataIndex="dealerName"
                  key="dealerName"
                  width={150}
                />
                <Column
                  title="Bill No"
                  dataIndex="billNo"
                  key="billNo"
                  width={120}
                />
                <Column
                  title="Date"
                  dataIndex="date"
                  key="date"
                  width={130}
                  render={(value, item, index) =>
                    moment(item.date).format("YYYY-MM-DD")
                  }
                />
                <Column
                  title="Total Qnty"
                  dataIndex="totalQuantity"
                  key="totalQuantity"
                  width={90}
                />
                <Column
                  title="Total Amt"
                  dataIndex="totalAmount"
                  key="totalAmount"
                  render={(value, item, index) =>
                    parseInt(item.totalAmount).toFixed()
                  }
                  width={120}
                />
                <Column
                  title="Action"
                  key="action"
                  width={120}
                  render={(text, record) => {

                    return (
                      <Row gutter={[8, 0]}>
                        <Col span={24}>
                          <a>
                            <Show_WH_BillPurchases warehousepurchaseId={record.warehousepurchaseId} />
                            {/* <ShowBillPurchases
                                purchaseId={record.purchaseId}
                                data={record}
                              /> */}
                          </a>
                        </Col>
                        {/* <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="btn">
                              <EditOutlined
                                // onClick={() =>
                                //   this.updatePurchaseBill(record.purchaseId)
                                // }
                              />
                            </div>
                          </Col> */}
                      </Row>
                    );
                  }}
                />
              </Table>{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

WarehousePurchaseBill.propTypes = {
  // getLocations: PropTypes.func.isRequired,
  //  inventoryList: PropTypes.func.isRequired,
  WarehousepurchasebillList: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  // locations: state.locationData.locations,
  purchaseList: state.warehouseData.warehousePurchaseBillList,
  whPurchaseBysearch: state.warehouseData.whPurchasebillbySearch
});

export default withRouter(
  connect(mapStateToProps, { WarehousepurchasebillList })(WarehousePurchaseBill)
);