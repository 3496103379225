// export const backend_uri_local = "http://localhost:5000";
// export const backend_uri_server = "https://daga-impex-backend.herokuapp.com";
// export const backend_uri_server = "http://localhost:5000";
// export const frontend_uri_server = "http://localhost:3000";
export const frontend_uri_server = "https://www.dagaimpex.in";


// export const backend_uri_server = "http://localhost:5000";
export const backend_uri_server = "https://api.dagaimpex.in";

export const others = 1

// "http://daga-env.eba-d8ebcmup.us-east-1.elasticbeanstalk.com";   