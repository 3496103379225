import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Button, Input, Space, Table, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerReports } from '../../../../redux/actions/reportsActions';
import TopBarProgress from 'react-topbar-progress-indicator';
import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import BackButton from '../../Report/Button';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const { Option } = Select;
const { Column } = Table;

const CustomerDummyReport = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 50 });
    const reportsData = useSelector((state) => state.reportData?.customerReports ?? []);
    const progressBarStatus = useSelector((state) => state.progressBarReducer?.showProgressBar ?? false);

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const [selectedColumns, setSelectedColumns] = useState([]);
    const [isCreditUserSelected, setIsCreditUserSelected] = useState(false);
    const keysToExclude = ['_id', 'otp', '__v', 'created_at', 'password', 'address', 'agentId'];

    const reportKeys = [...new Set(reportsData.flatMap(item => {
        const keys = Object.keys(item ?? {});
        return keys.flatMap(key => {
            if (Array.isArray(item[key])) {
                const nestedKeys = item[key].flatMap(subItem => Object.keys(subItem ?? {}));
                return nestedKeys.filter(nestedKey => !keysToExclude.includes(`${key}.${nestedKey}`));
            }
            return key;
        });
    }))].filter(key => !keysToExclude.includes(key));

    useEffect(() => {
        fetchData();
    }, [pagination.current]); 

    const fetchData = () => {
        setLoading(true);
        dispatch(getCustomerReports({
            page: pagination.current,
            pageSize: pagination.pageSize,
            searchText,
            searchedColumn,
            selectedColumns
        })).finally(() => setLoading(false));
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        setSearchText('');
        confirm();
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={dataIndex === 'agentId.name' ? 'Search Agent Name' : 'Search Customer'}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters, confirm)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) => {
            const dataIndexValue = dataIndex === 'agentId.name' ? record?.agentId?.name : record[dataIndex];
            return dataIndexValue
                ? dataIndexValue.toString().toLowerCase().includes(value.toLowerCase())
                : false;
        },
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleColumnSelectChange = (selectedValues) => {
        setSearchText('');
        setSelectedColumns(selectedValues);
        setIsCreditUserSelected(selectedValues.includes('isCreditUser'));

        // Reset pagination to fetch data from the first page
        setPagination({ ...pagination, current: 1 });

        fetchData();
    };

    const exportToExcel = () => {
        const defaultColumns = ['name', 'mobileNumber', 'agentId.name', 'state', 'totalNetAmount'];
        const columnsToExport = selectedColumns.length > 0 ? selectedColumns : defaultColumns;

        const data = reportsData.map(item => {
            const filteredItem = {};
            columnsToExport.forEach(key => {
                const keys = key.split('.');
                if (keys.length === 1) {
                    filteredItem[key] = item[key];
                } else {
                    let value = item;
                    for (const k of keys) {
                        value = value?.[k];
                        if (!value) break;
                    }
                    filteredItem[key] = value;
                }
            });
            return filteredItem;
        });

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Customer Reports');

        const maxWidths = columnsToExport.map(key => {
            return data.reduce((maxWidth, item) => {
                const value = item[key]?.toString() ?? '';
                return Math.max(maxWidth, value.length);
            }, key.length);
        });

        const wscols = maxWidths.map(width => ({ wch: width + 2 }));
        worksheet['!cols'] = wscols;

        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: EXCEL_TYPE });
        saveAs(dataBlob, 'customer_reports.xlsx');
    };

    return (
        <>
            <div className="subCategory-dashboard">
                {progressBarStatus && <TopBarProgress />}
                <div className="container-fluid category-list">
                    <Row className="title-row">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Row gutter={24}>
                                <Col span={12} style={{ paddingLeft: '0px' }}>
                                    <div className="page-header">Customer Report</div>
                                </Col>
                                <Col span={12}>
                                    <BackButton />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div style={{ marginBlock: '20px' }}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Select columns to display"
                                onChange={handleColumnSelectChange}
                                value={selectedColumns}
                                suffixIcon={
                                    <Button
                                        type="text"
                                        icon={<CloseCircleOutlined />}
                                        onClick={() => setSelectedColumns([])}
                                    />
                                }
                                allowClear
                            >
                                {reportKeys.map((key) => (
                                    <Option style={{width: '100%'}} key={key} value={key}>
                                        {key.toUpperCase()}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={4}>
                            <Button style={{ width: '100%' }} type="primary" onClick={exportToExcel}>Download Report</Button>
                        </Col>
                    </Row>

                </div>
                <div className="card final-purchases-list mt-4">
                    <Table
                        dataSource={isCreditUserSelected ? reportsData.filter(item => item.isCreditUser === true) : reportsData}
                        bordered={true}
                        size="large"
                        pagination={{ ...pagination, total: reportsData.length }}
                        onChange={(pagination) => setPagination(pagination)}
                        scroll={{ y: 400 }}
                        loading={loading}
                    >
                        <Column
                            title="SRN"
                            key="index"
                            render={(value, item, index) => index + 1}
                            width={60}
                        />
                        {selectedColumns.length > 0 ? (
                            selectedColumns.map((key) => (
                                <Column
                                    key={key}
                                    title={key.toUpperCase()}
                                    dataIndex={key}
                                    width={key === 'isCreditUser' ? 140 : 140}
                                    render={key === 'isCreditUser' ? (isCreditUser) => (isCreditUser ? 'True' : 'False') : undefined}
                                />
                            ))
                        ) : (
                            <>
                                <Column
                                    title="Customer Name"
                                    dataIndex="name"
                                    key="name"
                                    width={160}
                                    {...getColumnSearchProps('name')}
                                />
                                <Column
                                    title="Mobile No."
                                    dataIndex="mobileNumber"
                                    key="mobileNumber"
                                    width={140}
                                />
                                <Column
                                    title="Agent Name"
                                    dataIndex="agentId.name"
                                    key="agentId"
                                    width={140}
                                    {...getColumnSearchProps('agentId.name')}
                                    render={(text, record) => record?.agentId?.name ?? ''}
                                />
                                <Column
                                    title="State"
                                    key="state"
                                    dataIndex="state"
                                    width={100}
                                />
                                <Column
                                    title="Total Net Amount"
                                    dataIndex="totalNetAmount"
                                    key="totalNetAmount"
                                    width={140}
                                />
                                <Column
                                    title="Is Credit User"
                                    dataIndex="isCreditUser"
                                    key="isCreditUser"
                                    width={120}
                                    render={(isCreditUser) => (isCreditUser ? 'Yes' : 'No')}
                                />
                            </>
                        )}

                    </Table>
                </div>
            </div>
        </>
    );
};

export default CustomerDummyReport;
