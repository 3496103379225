import React from "react";
import ReactApexChart from "react-apexcharts";
import { Typography } from "antd";
import { MinusOutlined } from "@ant-design/icons";
// import LineChart from "./configs/lineChart";

function LineChartGross({ data }) {
  const { Title, Paragraph } = Typography;

  const categories = data?.map(item => item.barcode);
  const seriesDataTotalPurchase = data?.map(item => item.totalPurchaseValue);
  const seriesDataTotalSale = data?.map(item => item.totalSalesValue);
  const seriesDataNetAmount = data?.map(item => item.totalNetAmount);


  const LineChartData = {
    series: [
      {
        name: "Total Purchase Value",
        data: seriesDataTotalPurchase,
        offsetY: 0,
      },
      {
        name: "Total Sales Value",
        data: seriesDataTotalSale,
        offsetY: 0,
      },
      // {
      //   name: "Net Amount",
      //   data: seriesDataNetAmount,
      //   offsetY: 0,
      // },
    ],

    options: {
      chart: {
        width: "100%",
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
      },

      legend: {
        show: false,
      },

      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },

      yaxis: {
        labels: {
          style: {
            fontSize: "14px",
            fontWeight: 600,
            colors: ["#8c8c8c"],
          },
        },
      },

      xaxis: {
        labels: {
          style: {
            fontSize: "14px",
            fontWeight: 600,
            colors: [
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
              "#8c8c8c",
            ],
          },
        },
        categories: categories && categories.length > 0 ? categories : []
      },

      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
    },
  };


  return (
    <>
      <div className="apex-linechart">
        <ReactApexChart
          className="full-width"
          options={LineChartData.options}
          series={LineChartData.series}
          type="area"
          height={350}
          width={"100%"}
        />
      </div>
    </>
  );
}

export default LineChartGross;