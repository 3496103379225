import React, { Component } from "react";
import { Modal, Table, Button, Space, Input } from "antd";
import { connect } from "react-redux";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";

const { Column } = Table;

class LookupModalSales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      purchases: [],
      s: "",
    };
  }

  shouldComponentUpdate(nextProps, prevState) {
    console.log(nextProps, prevState);
    if (nextProps.purchases.length && nextProps.s != prevState.s) {
      this.setState({
        s: nextProps.s,
        purchases: nextProps.purchases,
        visible: true,
      });
      return true;
    }
    return true;
  }
  handleCancel = () => {
    this.setState({ visible: false });
  };
  fillDetails = (text) => {
    this.props.selectedData(text);
    this.setState({ visible: false });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };
  render() {
    return (
      <div>
        <Modal
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={() => this.handleCancel()}
          title="Lookup"
          style={{ top: 20 }}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          destroyOnClose={true}
          width={1500}
        >
          <Table
            dataSource={this.props.purchases}
            bordered="true"
            size="middle"
            pagination={false}
            scroll={{ y: 240 }}
          >
            <Column
              title="SRN"
              key="index"
              render={(value, item, index) => index + 1}
              width={60}
            />

            <Column
              title="Barcode"
              dataIndex="barcode"
              key="barcode"
              width={110}
              {...this.getColumnSearchProps("barcode")}
            />
            <Column
              title="Design No."
              dataIndex="designNumber"
              key="designNumber"
              width={140}
              {...this.getColumnSearchProps("designNumber")}
            />
            <Column
              title="Brand"
              dataIndex="brand"
              key="brand"
              width={120}
              {...this.getColumnSearchProps("brand")}
            />
            <Column
              title="Size"
              dataIndex="size"
              key="size"
              width={80}
              {...this.getColumnSearchProps("size")}
            />
            <Column title="Color" dataIndex="color" key="color" width={80} />
            <Column title="MOQ" dataIndex="MOQ" key="MOQ" width={60} />
            <Column
              title="Price"
              dataIndex="purchasePrice"
              key="purchasePrice"
              width={90}
            />
            <Column title="WSP" dataIndex="wsp" key="wsp" width={80} />
            <Column
              title="Action"
              key="action"
              width={90}
              render={(text, record) => (
                <Button type="primary" onClick={() => this.fillDetails(text)}>
                  Add
                </Button>
              )}
            />
          </Table>
        </Modal>
      </div>
    );
  }
}
// LookupModalSales.propTypes = {
//   getLookupData: PropTypes.func.isRequired,
//   errors: PropTypes.object.isRequired,
// };

const mapStateToProps = (state) => ({
  //   purchases: state.purchaseData.purchases,
  //   errors: state.errors,
});

export default connect(null, null)(LookupModalSales);
