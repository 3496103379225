import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Input, Tooltip, message } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { updateTexture } from "../../../../redux/actions/textureAction";

const UpdateTexture = ({ textures }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [name, setName] = useState(textures.name || "");
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setName(textures.name || "");
    }, [textures]);

    const onChange = (event) => {
        setName(event.target.value);
    };

    const onSubmit = () => {
        const updatedSection = {
            textureName: name.trim(),
            _id: textures._id,
        };

        dispatch(updateTexture(updatedSection))
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
            setVisible(false);
            window.location.reload();
        }, 1000);
    };

    const showModal = () => {
        setVisible(true);
    };

    const handleModalCancel = () => {
        setVisible(false);
    };

    return (
        <>
            <button
                className="btn"
                type="button"
                onClick={showModal}
            >
                <Tooltip title="Update Texture">
                    <EditOutlined />
                </Tooltip>
            </button>

            <Modal
                visible={visible}
                onCancel={handleModalCancel}
                title="Update Texture"
                style={{ top: 20, height: "200px" }}
                footer={null}
            >
                <Form
                    name="update-texture"
                    className="update-texture"
                    onFinish={onSubmit}
                    initialValues={{
                        remember: true,
                    }}
                >
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Please Enter Texture Name!",
                            },
                        ]}
                    >
                        <Input
                            autoFocus
                            placeholder="Texture Name"
                            name="name"
                            value={name}
                            onChange={onChange}
                        />
                    </Form.Item>

                    <Form.Item className="float-right">
                        <Button className="close-modal mr-3" onClick={handleModalCancel}>
                            Close
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="ok-modal"
                            loading={loading}
                            disabled={!name.trim()}
                        >
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};


export default UpdateTexture
