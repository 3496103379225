import React, { Component } from "react";
import { Row, Col, Button, Form, Input, Checkbox, Tooltip, Select } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCustomers } from "../../../redux/actions/customerAction";
import { changeGrAmount } from "../../../redux/actions/userAction";

const { Option } = Select;

class SettleGRAmount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      name: "",
      updatedGR: "",
      grAmount: "",
      grFlag: "",
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  oncheckChange = (e) => {
    if (e.target.checked && this.state.netAmount && this.state.grAmount) {
      let updatedNetAmount = this.state.netAmount - this.state.grAmount;
      this.setState({ netAmount: updatedNetAmount, grFlag: true });
    } else if (
      !e.target.checked &&
      this.state.netAmount &&
      this.state.grAmount
    ) {
      let updatedNetAmount = this.state.netAmount + this.state.grAmount;
      this.setState({ netAmount: updatedNetAmount, grFlag: false });
    } else {
      this.setState({ grFlag: e.target.checked });
    }
  };
  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onSubmit() {
    let userId  = localStorage.getItem("userId")
    this.setState({ loading: true });
    let updatedObj;
    if (this.state.grFlag) {
      updatedObj = {
        id: this.state.customerId,
        grAmount: this.state.grAmount,
        userId  :userId
      };
    } else {
      updatedObj = {
        id: this.state.customerId,
        grAmount: this.state.updatedGR,
        userId : userId
      };
    }
    this.props.changeGrAmount(updatedObj);
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
  }
  handleChangeCustomer = (key, value) => {
    let customerId = value.value;
    let grAmount = value.name[2];
    let grFlag = false;
    if (grAmount) {
      grFlag = true;
    }
    this.setState({
      customerId,
      grAmount,
      grFlag,
    });
  };
  getData = (name) => {
    if (name == "customer") this.props.getCustomers("STORE_USER");
  };

  render() {
    const { loading } = this.state;
    let customerOptions = this.props.customers.map((customer) => {
      return (
        <Option
          name={[
            customer.transport,
            customer.agentId,
            customer.grAmount,
            customer,
          ]}
          value={customer._id}
        >
          {customer.name}
        </Option>
      );
    });
    return (
      <div className="add-purchase ">
        <div className="container-fluid">
          <Row className="title-row">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="page-header">Settle GR Amount</div>
            </Col>
          </Row>
          <div className="card mt-4">
            <div className="card-body">
              <Form
                name="add-Agent"
                className="add-Agent"
                onFinish={this.onSubmit}
                initialValues={{
                  remember: true,
                }}
              >
                <Row gutter={[8, 0]}>
                  <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                    <Tooltip title="Select Customer">
                      <Form.Item
                        name="customerName"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Customer Name !",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Select Customer"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                          onChange={this.handleChangeCustomer}
                          onClick={() => this.getData("customer")}
                          onInputKeyDown={() => this.getData("customer")}
                        >
                          {customerOptions}
                        </Select>
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                    <Tooltip title="GR Amount">
                      <Form.Item>
                        <Input
                          placeholder="GR Amount"
                          name="grAmount"
                          value={this.state.grAmount}
                          onChange={this.onChange}
                          disabled
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                    <Tooltip title="Check">
                      <Form.Item>
                        <Checkbox
                          key={this.state.grFlag}
                          onChange={this.oncheckChange}
                          defaultChecked={this.state.grFlag}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                    <Tooltip title="Updated GR">
                      <Form.Item name="updatedGR">
                        <Input
                          placeholder="Enter Updated GR"
                          name="updatedGR"
                          value={this.state.updatedGR}
                          onChange={this.onChange}
                          disabled={this.state.grFlag ? true : false}
                          required={true}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                </Row>
                <Form.Item className="float-right">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="ok-modal"
                    loading={loading}
                    disabled={this.state.grAmount === 0 ? true : false}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
SettleGRAmount.propTypes = {
  getCustomers: PropTypes.func.isRequired,
  changeGrAmount: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  customers: state.customerData.customers,
});

export default connect(mapStateToProps, {
  getCustomers,
  changeGrAmount,
})(SettleGRAmount);
