import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { save, load } from "redux-localstorage-simple";
import { Provider } from "react-redux";
import { fetchAllProducts, fetchHomeProducts, fetchAllProductsSidebarList } from "./redux/actions/productActions";
import { fetchSalesProducts } from "./redux/actions/productActions";
import products from "./data/products.json";
import App from "./App";
import "./assets/scss/style.scss";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { composeWithDevTools } from "redux-devtools-extension";
import { getFullCart } from "./redux/actions/cartActions";
import {
  backend_uri_server,
  backend_uri_local,
  frontend_uri_local,
  frontend_uri_server,
} from "./util/constants";
import store from "./redux/store";



// fetch products from json file
//store.dispatch(fetchSalesProducts());

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response === undefined) {
      error = {};
      error.status = 500;
      return Promise.reject(error);
    } else if (
      error.response.status === 401 &&
      originalRequest.url === `${backend_uri_server}/api/v1/user/token`
    ) {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      // return <Redirect to="/" />;
      // window.location.href = `${frontend_uri_local}/login-register`;
      window.location.href = `${frontend_uri_server}/login-register`;
      return Promise.reject(error);
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem("refreshToken");
      return axios
        .post(`${backend_uri_server}/api/v1/user/token`, {
          refreshToken: refreshToken,
        })
        .then((res) => {
          if (res.status === 201) {
            localStorage.setItem("token", res.data.jwtToken);
            localStorage.setItem("refreshToken", res.data.refreshToken);
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + localStorage.getItem("token");
            return axios(originalRequest);
          }
        });
    }
    return Promise.reject(error);
  }
);



let rollName = localStorage.getItem("roleName")
if ((rollName === "STORE_USER") || (rollName == null || undefined)) {
  if (rollName === "STORE_USER") {
    store.dispatch(fetchHomeProducts());
    store.dispatch(fetchAllProducts(1))
    store.dispatch(fetchAllProductsSidebarList())
  } else {
    store.dispatch(fetchHomeProducts());
  }
} else {
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
