import React, { Component } from "react";
import { Table, Button, Modal, } from "antd";
import { EyeOutlined, } from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getSalesOrderByCustomer, getSaleUsingInvoiceId } from "../../../redux/actions/saleAction";
import gifLoader from "../../../assets/images/giphy.gif"
import { Link, useHistory, withRouter } from "react-router-dom";
import moment from "moment";
const { Column } = Table;

class ShowSalesOrderByCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRowId: null,
            page: "",
            current: 1,
            search: "",
        };
        this.handleSalesOrder = this.handleSalesOrder.bind(this);
    }

    handleModalCancel = () => {
        this.props.handleCancel()
    };


    handleSalesOrder = async (text) => {
        await this.props.getSaleUsingInvoiceId(text.invoiceId);
        this.props.history.push({
            pathname: "/dagaImpex/updateSales",
            state: { id: text._id, saleObj: [text, this.props.salesOrders], salesOrderChangeStatus: true },
        });
    };

    render() {
        const { visible } = this.props;
        const { saleOrderProducts } = this.props
        return (
            <div>
                <Modal
                    visible={visible}
                    onOk={this.handleOk}
                    onCancel={this.handleModalCancel}
                    title="Pending Sales Orders"
                    style={{ top: 20 }}
                    okButtonProps={{ hidden: true }}
                    cancelButtonProps={{ hidden: true }}
                    destroyOnClose={true}
                    width={1500}
                >
                    {
                        saleOrderProducts && saleOrderProducts?.data && saleOrderProducts?.data?.length > 0 ? <>
                            <Table
                                dataSource={saleOrderProducts && saleOrderProducts?.data || []}
                                bordered="true"
                                size="middle"
                                pagination={false}
                                scroll={{ y: 500 }}
                            >
                                <Column
                                    title="SRN"
                                    key="index"
                                    render={(value, item, index) => index + 1}
                                    width={60}
                                />
                                <Column
                                    title="Bill Date"
                                    dataIndex="date"
                                    key="date"
                                    width={210}
                                    render={(value, item, index) =>
                                        `${moment(item.date).format("YYYY-MM-DD")} (${moment(item.date).format("HH:mm:ss")} )`
                                    }
                                />
                                <Column
                                    title="Invoice No."
                                    dataIndex="invoiceId"
                                    key="invoiceId"
                                    render={(value, item, index) => `S-${item.invoiceId}`}
                                    width={150}
                                />
                                <Column
                                    title="Customer Name"
                                    dataIndex="dealerName"
                                    key="dealerName"
                                    width={200}
                                    render={(value, item, index) => `${item.userId.name}`}
                                />
                                <Column
                                    title="Agent Name"
                                    dataIndex="userId"
                                    key="userId"
                                    render={(value, item, index) => `${item?.userId?.agentId ? item?.userId?.agentId?.name : ""}`}
                                    width={170}
                                />
                                <Column
                                    title="Total Boxes"
                                    dataIndex="totalBoxes"
                                    key="totalBoxes"
                                    width={120}
                                />
                                <Column
                                    title="Total Qty"
                                    dataIndex="totalQuantity"
                                    key="totalQuantity"
                                    width={120}
                                />
                                <Column
                                    title="Total Amt"
                                    dataIndex="totalAmount"
                                    key="totalAmount"
                                    render={(value, item, index) => parseInt(item.totalAmount).toFixed()}
                                    width={100}
                                />
                                <Column
                                    title="Gr Amt"
                                    dataIndex="grAmountUsed"
                                    key="grAmountUsed"
                                    render={(value, item, index) => Number(item.grAmountUsed).toFixed()}
                                    width={100}
                                />
                                <Column
                                    title="Net Amt"
                                    dataIndex="netAmount"
                                    key="netAmount"
                                    width={110}
                                />
                                <Column
                                    title="Remark"
                                    dataIndex="remark"
                                    key="remark"
                                    width={120}
                                />
                                <Column
                                    title="Creat Bill"
                                    dataIndex="add"
                                    key="add"
                                    render={(value, item, index) => <Button type="primary" onClick={() => this.handleSalesOrder(item)}>Add</Button>}
                                    width={120}
                                    fixed= 'right'
                                />
                            </Table>

                        </> : <>
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={gifLoader} width={"300px"} height={"300px"} />
                            </div>
                        </>
                    }
                </Modal>
            </div>
        );
    }
}

ShowSalesOrderByCustomer.propTypes = {
    getSalesOrderByCustomer: PropTypes.func.isRequired,
    getSaleUsingInvoiceId: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    saleOrderProducts: state.saleData.salesOrderByCustomer,
    salesOrders: state.saleData.salesOrders,
});

export default withRouter(connect(mapStateToProps, { getSalesOrderByCustomer, getSaleUsingInvoiceId })(
    ShowSalesOrderByCustomer
));