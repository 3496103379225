import React, { useState, useEffect, useRef } from "react";
import {
    Table,
    Popconfirm,
    Tooltip,
    Button,
    Space,
    Input,
    Row,
    Col,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import TopBarProgress from "react-topbar-progress-indicator";
import moment from "moment";
import { getPurchaseOrderByID, getPurchaseOrders } from "../../../../redux/actions/purchaseOrderAction";
import UpdatePurchaseOrder from "./purchaseOrderBill/UpdatePurchaseOrder";
import { ArrowLeftOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import ShowPurchaseOrderBills from "./purchaseOrderBill/ShowPurchaseOrderBills";

const { Column } = Table;

const PurchaseOrderList = () => {
    const history = useHistory();
    const dispatch = useDispatch();


    const [current] = useState(1);
    const progressBarStatus = useSelector((state) => state.progressBarReducer.showProgressBar);
    const purchaseOrderBills = useSelector((state) => state.purchaseOrderData.purchaseOrders)

    useEffect(() => {
        dispatch(getPurchaseOrders());
        // const obj = {
        //     poId: 'PO-10003'
        // }
        // dispatch(getPurchaseOrderByID(obj))
    }, []);

    const onDeleteClick = (id) => {
        // dispatch(deletePurchase(id));
    };

    const updatePurchaseBill = (id) => {

        const dataToSend = {
            id: id,
        };

        history.push({
            pathname: "/dagaImpex/updatePurchaseOrderList",
            state: dataToSend,
        });
    };




    return (
        <div className="card final-purchases-list">
            {progressBarStatus && <TopBarProgress />}
            <Row>
                <Col sm={24} md={16} lg={18}>
                    <h3 className="m-4">Purchase Order List</h3>
                </Col>
            </Row>
            <div className="card-body">
                <Table
                    dataSource={purchaseOrderBills}
                    bordered
                    size="middle"
                    scroll={{ y: 500 }}
                >
                    <Column title="SRN" key="index" render={(value, item, index) => index + 1} width={60} />
                    <Column
                        title="Date"
                        dataIndex="date"
                        key="date"
                        width={100}
                        render={(date) => moment(date).format("YYYY-MM-DD")}
                    />
                    <Column title="PO ID" dataIndex="poId" key="poId" width={100} />
                    <Column title="Net Amount " dataIndex="netAmount" key="netAmount" width={150} />
                    <Column title="Total Amount" dataIndex="totalAmount" key="totalAmount" width={120} />
                    <Column title="Quantity" dataIndex="quantity" key="quantity" width={100} />
                    <Column title="Tax" dataIndex="tax" key="tax" width={100} />
                    <Column title="Discount" dataIndex="discount" key="discount" width={80} />
                    <Column
                        title="Action"
                        key="action"
                        width={180}
                        render={(text, record) => (
                            <Row gutter={24} className="">
                                <Col sm={6} md={6} lg={6}>
                                    < ShowPurchaseOrderBills id={record.poId} />
                                </Col>
                                <Col sm={10} md={10} lg={10}>
                                    {
                                        record?.purchaseBillGenerated
                                            ?
                                            <Button disabled={true}>
                                                Bill Generated
                                            </Button>
                                            :
                                            <Button className="mx-4" style={{ color: 'black' }} type="link" >
                                                <Popconfirm
                                                    title="Are you sure to Edit this bill?"
                                                    onConfirm={() => updatePurchaseBill(record.poId)}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <EditOutlined />
                                                </Popconfirm>
                                            </Button>
                                    }
                                </Col>
                            </Row>

                        )}
                    />
                </Table>
            </div>
        </div>
    );
};

export default PurchaseOrderList;
