import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, Menu, Dropdown, Avatar } from "antd";
import { NavLink } from "react-router-dom";
import logo from "../../../assets/images/Daga_Impex_Logo.png";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import SiderDrawerPhone from "../../admin/SiderDrawerPhone";
const { Header } = Layout;

export default class HeaderAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: false, placement: "left" };
    this.logout = this.logout.bind(this);
  }
  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("isCreditUser");
    localStorage.removeItem("userId");
    localStorage.removeItem("roleName");
    // localStorage.clear();
    window.location.reload();
  }

  render() {
    const menu = (
      <Menu>
        <Menu.Item key="1">
          <NavLink className="sider-links" to="/dagaImpex/updateProfile">
            <UserOutlined />
            Edit Profile
          </NavLink>
        </Menu.Item>

        <Menu.Item key="3" onClick={this.logout}>
          <LogoutOutlined /> Logout
        </Menu.Item>
      </Menu>
    );
    return (
      <Header className="site-layout-background" style={{ padding: 0 }}>
        {React.createElement(
          this.props.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          {
            className: "trigger",
            onClick: this.props.toggle,
          }
        )}
        <SiderDrawerPhone user={this.props.user} />
        {/** <Input placeholder="Search" prefix={<SearchOutlined />} /> */}
        {/* <div className="logo-admin">
        <img
          src={logo}
          alt="Logo"
          width={50}
          height={50}
          // style={{ margin: "65px 0 0px 25px" }}
        />
      </div> */}
        <Dropdown overlay={menu} placement="bottomRight">
          <Avatar
            style={{
              color: "#0060aa",
              backgroundColor: "#afe7fc",
              letterSpacing: "1px",
            }}
            // size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
            role="button"
            className="float-right mr-5 mt-2"
            onClick={(e) => e.preventDefault()}
            size={45}
          >
            {(this.props?.user?.name ?? "Unknown")
              .match(/(^\S\S?|\b\S)?/g)
              .join("")
              .match(/(^\S|\S$)?/g)
              .join("")
              .toUpperCase()}
          </Avatar>
        </Dropdown>
        <div className="float-right mr-5">
          <p className="profile-name-sider" title="  Daga Impex">
            {this.props?.user?.name}
          </p>
          <p className="profile-email-sider">{this.props?.user?.email}</p>
        </div>

        {/* <div className="float-right mr-5 mt-2" >
      <p className="profile-name-sider" title="  Daga Impex">
          {this.props.user.name}
        </p>
      </div>
       <div className="logo-admin" style={{ marginTop: "-4rem", marginLeft :"5rem"}}>
        <img
          src={logo}
          alt="Logo"
          width={70}
          height={50}
          // style={{ margin: "65px 0 0px 25px" }}
        />
      </div> */}
      </Header>
    );
  }
}
