/* /////////////////////////////////////// */
/* 
Date              created by               Modifications
  
25-07-2023        Mohit                    


*/
/* /////////////////////////////////////// */

import React, { Component } from "react";
import { Table, Button, Space, Input, Tag } from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { CSVLink } from "react-csv";
const { Column } = Table;
const data = [];

export default class AgentWiseReportList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      options: [],
    };
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };
  render() {
 
    return (
      <>
        <div className="card final-purchases-list">
          <div className="card-body">
            <Table
              dataSource={this.props && this.props.reports && this.props.reports.data ? this.props.reports.data : []}
              bordered="true"
              size="middle"
              pagination={false}
              scroll={{ y: 240 }}
            >
              <Column
                title="SRN"
                key="index"
                render={(value, item, index) => index + 1}
                width={60}
              />
               <Column
                title="Date"
                dataIndex="date"
                key="date"
                width={120}
                render={(value, item, index) =>
                  moment(item.date).format("YYYY-MM-DD")
                }
              />
              <Column
                title="Invoice Id"
                dataIndex="invoiceId"
                key="invoiceId"
                width={140}
                {...this.getColumnSearchProps("invoiceId")}
              />
              <Column
                title="Customer Name"
                // dataIndex="customerName"
                key="customerName"
                render={(record)=>
                  record?.userId?.name
                }
                width={170}
              />
              <Column
                title="Agent Name"
                // dataIndex="agentName"
                key="agentName"
                width={150}
                render={(record)=>
                  record?.userId?.agentId?.name
                }
              />
              <Column title="Total Quantity" dataIndex="totalQuantity" key="totalQuantity" width={140} />
              <Column title="Total Amount" dataIndex="totalAmount" key="totalAmount" width={140} />
              <Column title="GR" dataIndex="grAmountUsed" key="grAmountUsed" width={100} />
              <Column title="Disc" dataIndex="discount" key="discount" width={100} />
              <Column title="Tax" dataIndex="tax" key="tax" width={100} />
              <Column title="PF" dataIndex="pAndf" key="pAndf" width={100} />
              <Column title="Labour" dataIndex="labour" key="labour" width={100} />
              <Column title="Net Amount" dataIndex="netAmount" key="netAmount" width={140} />
            </Table>
          </div>
        </div>
      </>
    );
  }
}
