import React, { Component } from "react";
import {
  Row,
  Col,
  Tooltip,
  Form,
  Select,
  DatePicker,
  Button,
  message,
  Input,
  Typography,
} from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import debounce from "lodash.debounce";
import {
  getSalesReport,
  getStockReport,
} from "../../../../../redux/actions/reportsActions";
import DateWiseSearch from "../../../../../../src/util/DateWiseSearch";
import TopBarProgress from "react-topbar-progress-indicator";
// import StockReportList from "./StockReportList";
import { getBrandBySearch } from "../../../../../redux/actions/brandAction";
import { getCategories } from "../../../../../redux/actions/categoryAction";
import { getGroups } from "../../../../../redux/actions/groupAction";
import { getStyles } from "../../../../../redux/actions/styleAction";
import { getSeasons } from "../../../../../redux/actions/seasonAction";
import { getSections } from "../../../../../redux/actions/sectionAction";
import { getSizes } from "../../../../../redux/actions/sizeAction";
import { getFloors } from "../../../../../redux/actions/floorAction";
import { getGrossProfit } from "../../../../../redux/actions/dashboardActions";
import { MinusOutlined } from "@ant-design/icons";
import LineChartGross from "./LineChartGross";

const { Title, Text, Paragraph } = Typography;

const dateFormat = "YYYY-MM-DD";
const { Option } = Select;
class GrossprofitReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      styleName: "",
      styleId: "",
      categoryName: "",
      categoryId: "",
      groupName: "",
      groupId: "",
      seasonName: "",
      seasonId: "",
      sectionName: "",
      sectionId: "",
      brandName: "",
      brandId: "",
      floorName: "",
      floorId: "",
      sizeName: "",
      sizeId: "",
      designNumber: "",

      disableCat: false,
      disableStyle: false,
      disableGroup: false,
      disableSeason: false,
      disableSection: false,
      disableFloor: false,
      disableSize: false,
    };
    this.debouncedGetData = debounce(this.getData, 500);
  }
  componentDidMount = () => {
    let startDate = moment(new Date()).format("YYYY-MM-DD");
    let endDate = moment(new Date()).format("YYYY-MM-DD");
    let reportObj = {
      startDate: startDate,
      endDate: endDate + " " + moment().format("HH:mm:ss"),
    };
    this.props.getSalesReport(reportObj);
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChangeBrand = (key, value) => {
    console.log("check my brand name eee", value);
    this.setState({
      disableCat: true,
      disableStyle: true,
      disableGroup: true,
      disableSeason: true,
      disableSection: true,
      disableFloor: true,
      disableSize: true,
    });
    this.setState({ brandName: value.name });
    this.debouncedGetData("brand");
  };
  handleChangeCategory = (key, value) => {
    this.setState({
      disableStyle: true,
      disableBrand: true,
      disableFloor: true,
      disableGroup: true,
      disableSeason: true,
      disableSection: true,
      disableSize: true,
    });
    this.setState({ categoryId: value.value, categoryName: value.name });
    this.debouncedGetData("category");
  };
  handleChangeGroup = (key, value) => {
    this.setState({
      disableCat: true,
      disableStyle: true,
      disableBrand: true,
      disableSeason: true,
      disableSection: true,
      disableFloor: true,
      disableSize: true,
    });
    this.setState({ groupName: value.name, });
  };
  handleChangeStyle = (key, value) => {
    this.setState({ styleId: value.value, styleName: value.name });
  };
  handleChangeSeason = (key, value) => {
    this.setState({
      disableCat: true,
      disableStyle: true,
      disableBrand: true,
      disableSection: true,
      disableFloor: true,
      disableSize: true,
      disableGroup: true,
    });
    this.setState({ seasonName: value.name });
    this.debouncedGetData("season");
  };
  handleChangeSection = (key, value) => {
    this.setState({
      disableCat: true,
      disableStyle: true,
      disableBrand: true,
      disableFloor: true,
      disableSize: true,
      disableGroup: true,
      disableSeason: true,
    });
    this.setState({ sectionName: value.name });
  };
  handleChangeSize = (key, value) => {
    this.setState({
      disableCat: true,
      disableStyle: true,
      disableBrand: true,
      disableFloor: true,
      disableGroup: true,
      disableSeason: true,
      disableSection: true,
    });
    this.setState({ sizeName: value.name });
    this.debouncedGetData("size");
  };
  handleChangeFloor = (key, value) => {
    this.setState({
      disableCat: true,
      disableStyle: true,
      disableBrand: true,
      disableSize: true,
      disableGroup: true,
      disableSeason: true,
      disableSection: true,
    });
    this.setState({ floorName: value.name });
    this.debouncedGetData("floor");
  };

  getData = (name, value) => {
    if (name == "brand") this.props.getBrandBySearch({ searchValue: value });
    if (name == "category") this.props.getCategories();
    if (name == "group") this.props.getGroups();
    if (name == "style") {
      if (this.state.categoryId != "") {
        this.props.getStyles(this.state.categoryId);
      } else {
        message.warning("Please Select Category");
      }
    }
    if (name == "season") this.props.getSeasons();
    if (name == "section") this.props.getSections();
    if (name == "size") this.props.getSizes();
    if (name == "floor") this.props.getFloors();
  };

  // Function to generate the request body
  generateRequestBody = () => {
    let requestBody = {};
    if (this.state.brandName) {
      return (requestBody = {
        brand: this.state.brandName,
        type: "Brand",
      });
    }
    if (this.state.groupName) {
      return (requestBody = {
        group: this.state.groupName,
        type: "Group",
      });
    }
    if (this.state.seasonName) {
      return (requestBody = {
        season: this.state.seasonName,
        type: "Season",
      });
    }
    if (this.state.sectionName) {
      return (requestBody = {
        section: this.state.sectionName,
        type: "Section",
      });
    }
    if (this.state.floorName) {
      return (requestBody = {
        floor: this.state.floorName,
        type: "Floor",
      });
    }
    if (this.state.sizeName) {
      return (requestBody = {
        size: this.state.sizeName,
        type: "Size",
      });
    }
    if (this.state.categoryName) {
      return (requestBody = {
        category: this.state.categoryName,
        type: "Category",
      });
    }

    return requestBody;
  };

  submitForm = () => {
    const requestBody = this.generateRequestBody();
    console.log("got requestobj", requestBody);
    this.props.getGrossProfit(requestBody);
  };

  render() {
    let brandOptions = this.props?.brandBysearch?.data?.map((brand) => {
      return (
        <Option name={brand?.name} value={brand?._id}>
          {brand?.name}
        </Option>
      );
    });

    let categoryOptions = this.props?.categories?.map((category) => {
      return (
        <Option name={category?.name} value={category?._id}>
          {category?.name}
        </Option>
      );
    });
    let groupOptions = this.props?.groups?.map((group) => {
      return (
        <Option name={group?.name} value={group?._id}>
          {group?.name}
        </Option>
      );
    });
    let styleOptions = this.props?.styles?.map((style) => {
      return (
        <Option name={style?.name} value={style?._id}>
          {style?.name}
        </Option>
      );
    });
    let seasonOptions = this.props?.seasons?.map((season) => {
      return (
        <Option name={season?.name} value={season?._id}>
          {season?.name}
        </Option>
      );
    });
    let sectionOptions = this.props?.sections?.map((section) => {
      return (
        <Option name={section?.name} value={section?._id}>
          {section?.name}
        </Option>
      );
    });
    let sizeOptions = this.props?.sizes?.map((size) => {
      return (
        <Option name={size?.name} value={size?._id}>
          {size?.name}
        </Option>
      );
    });
    let floorOptions = this.props?.floors?.map((floor) => {
      return (
        <Option name={floor?.name} value={floor?._id}>
          {floor?.name}
        </Option>
      );
    });
    return (
      <div className="subCategory-dashboard">
        {this.props.progressBarStatus && <TopBarProgress />}
        <div className="container-fluid category-list">
          {/* ==============Search Bar and Date Filter================= */}
          <Row className="title-row">
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <div className="page-header">Gross Profit Report</div>
            </Col>
          </Row>
          <div className="card mt-2 final-purchases-list mt-4">
            <div className="card-body">
              <div className="mb-5 mt-2">
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form
                      ref={this.formRef}
                      name="stock-report"
                      className="add-Purchase"
                      onFinish={this.submitForm}
                      // initialValues={initialValues}
                    >
                      <Row gutter={[8, 0]}>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Tooltip title="Select Brand">
                            <Form.Item
                              name="brand"
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Please Select Brand Name !",
                              //   },
                              // ]}
                            >
                              <Select
                                // mode="tags"
                                disabled={this.state.disableBrand}
                                name="brand"
                                id="brand"
                                showSearch
                                placeholder="Select Brand"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                onChange={this.handleChangeBrand}
                                // onClick={() => this.getData("brand")}
                                // onSelect={this.showLookup}
                                onClick={() => {
                                  this.debouncedGetData("brand");
                                }}
                                onInputKeyDown={() => {
                                  this.debouncedGetData("brand");
                                }}
                                // options={brandOptions}
                              >
                                {/* {brandOptions?.map((brand) => (
                                  <Option key={brand?.value} value={brand?.value}>
                                    {brand?.label}
                                  </Option>
                                ))} */}
                                {brandOptions}
                              </Select>
                            </Form.Item>
                          </Tooltip>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Tooltip title="Select Category">
                            <Form.Item
                              name="category"
                              // rules={[
                              //     {
                              //         required: true,
                              //         message: "Please Select Category Name !",
                              //     },
                              // ]}
                            >
                              <Select
                                disabled={this.state.disableCat}
                                showSearch
                                placeholder="Select Category"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                onChange={this.handleChangeCategory}
                                onClick={() =>
                                  this.debouncedGetData("category")
                                }
                                onInputKeyDown={() =>
                                  this.debouncedGetData("category")
                                }
                              >
                                {categoryOptions}
                              </Select>
                            </Form.Item>
                          </Tooltip>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Tooltip title="Select Style">
                            <Form.Item
                              name="style"
                              // rules={[
                              //     {
                              //         required: true,
                              //         message: "Please Select Style Name !",
                              //     },
                              // ]}
                            >
                              <Select
                                disabled={this.state.disableStyle}
                                name="style1"
                                id="style1"
                                showSearch
                                placeholder="Select Style"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                onChange={this.handleChangeStyle}
                                onClick={() => this.debouncedGetData("style")}
                                onInputKeyDown={() =>
                                  this.debouncedGetData("style")
                                }
                              >
                                {styleOptions}
                              </Select>
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Tooltip title="Select Group">
                            <Form.Item
                              name="group"
                              // rules={[
                              //     {
                              //         required: true,
                              //         message: "Please Select Group Name !",
                              //     },
                              // ]}
                            >
                              <Select
                                // mode="tags"
                                disabled={this.state.disableGroup}
                                showSearch
                                placeholder="Select Group"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                onChange={this.handleChangeGroup}
                                onClick={() => this.debouncedGetData("group")}
                                onInputKeyDown={() =>
                                  this.debouncedGetData("group")
                                }
                                // options={groupOptions}
                              >
                                {groupOptions}
                              </Select>
                            </Form.Item>
                          </Tooltip>
                        </Col>
                      </Row>

                      <Row gutter={[8, 0]}>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Tooltip title="Select Season">
                            <Form.Item
                              name="season"
                              // rules={[
                              //     {
                              //         required: true,
                              //         message: "Please Select Season Name !",
                              //     },
                              // ]}
                            >
                              <Select
                                // mode="tags"
                                disabled={this.state.disableSeason}
                                showSearch
                                placeholder="Select Season"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                onChange={this.handleChangeSeason}
                                onClick={() => this.debouncedGetData("season")}
                                onInputKeyDown={() =>
                                  this.debouncedGetData("season")
                                }
                                // options={seasonOptions}
                              >
                                {seasonOptions}
                              </Select>
                            </Form.Item>
                          </Tooltip>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Tooltip title="Select Section">
                            <Form.Item
                              name="section"
                              // rules={[
                              //     {
                              //         required: true,
                              //         message: "Please Select Section Name !",
                              //     },
                              // ]}
                            >
                              <Select
                                // mode="tags"
                                disabled={this.state.disableSection}
                                showSearch
                                placeholder="Select Section"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                onChange={this.handleChangeSection}
                                onClick={() => this.debouncedGetData("section")}
                                onInputKeyDown={() =>
                                  this.debouncedGetData("section")
                                }
                                // options={sectionOptions}
                              >
                                {sectionOptions}
                              </Select>
                            </Form.Item>
                          </Tooltip>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Tooltip title="Select Floor">
                            <Form.Item
                              name="floor"
                              // rules={[
                              //     {
                              //         required: true,
                              //         message: "Please Select Floor Name !",
                              //     },
                              // ]}
                            >
                              <Select
                                // mode="tags"
                                disabled={this.state.disableFloor}
                                showSearch
                                placeholder="Select Floor"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                onChange={this.handleChangeFloor}
                                onClick={() => this.debouncedGetData("floor")}
                                onInputKeyDown={() =>
                                  this.debouncedGetData("floor")
                                }
                                // options={floorOptions}
                              >
                                {floorOptions}
                              </Select>
                            </Form.Item>
                          </Tooltip>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Tooltip title="Select Size">
                            <Form.Item
                            // {...field}
                            // fieldKey={[field.fieldKey, "size"]}
                            // name={[field.name, "size"]}
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: "Please Select Size !",
                            //     },
                            // ]}
                            >
                              <Select
                                // mode="tags"
                                disabled={this.state.disableSize}
                                // id="467396"
                                name="size"
                                showSearch
                                placeholder="Select Size"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                onChange={this.handleChangeSize}
                                onClick={() => this.debouncedGetData("size")}
                                onInputKeyDown={() =>
                                  this.debouncedGetData("size")
                                }
                                // options={sizeOptions}
                              >
                                {sizeOptions}
                              </Select>
                            </Form.Item>
                          </Tooltip>
                        </Col>
                      </Row>
                      <Row gutter={[8, 0]}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Button
                            type="submit"
                            className="btn-stock-report-search"
                            block
                            onClick={() => this.submitForm()}
                          >
                            Search
                          </Button>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Button
                            type="primary"
                            onClick={() => window.location.reload(true)}
                            className="close-modal"
                            block
                          >
                            Reset
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </div>

              <div className="linechart">
                <div>
                  <Title level={5}>Gross Profit</Title>
                </div>
                <div className="sales">
                  <ul>
                    <li>{<MinusOutlined />} Barcode</li>
                    <li>{<MinusOutlined />} Total Purchase Value</li>
                    <li>{<MinusOutlined />} Total Sale Value</li>
                  </ul>
                </div>
              </div>

              <LineChartGross data={this.props.grossReport} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
GrossprofitReport.propTypes = {
  getSalesReport: PropTypes.func.isRequired,
  getBrandBySearch: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  getGroups: PropTypes.func.isRequired,
  getStyles: PropTypes.func.isRequired,
  getSeasons: PropTypes.func.isRequired,
  getSections: PropTypes.func.isRequired,
  getSizes: PropTypes.func.isRequired,
  getFloors: PropTypes.func.isRequired,
  getStockReport: PropTypes.func.isRequired,
  getGrossProfit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  reports: state.reportData.stockReport,
  progressBarStatus: state.progressBarReducer.showProgressBar,
  brands: state.brandsData.brands,
  brandBysearch: state.brandsData.brandBysearch,
  sections: state.sectionData.sections,
  seasons: state.seasonData.seasons,
  categories: state.categoryData.categories,
  styles: state.styleData.styles,
  sizes: state.sizeData.sizes,
  floors: state.floorData.floors,
  groups: state.groupData.groups,
  grossReport: state.dashboardData.grossprofit,
});

export default connect(mapStateToProps, {
  getSalesReport,
  getBrandBySearch,
  getCategories,
  getGroups,
  getStyles,
  getSeasons,
  getSections,
  getSizes,
  getFloors,
  getStockReport,
  getGrossProfit,
})(GrossprofitReport);