import React, { useState } from 'react';
import { Modal, Tooltip, Form, Input, Select, Button, Row, Col, Radio } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import gifLoader from '../../../assets/images/giphy.gif';

const { Option } = Select
const ViewUserData = (user) => {

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true)
  };

  const handleModalCancel = () => {
    setVisible(false);
  };


  return (
    <div>
      <button className="btn" type="button" onClick={showModal}>
        <Tooltip title="View User Details">
          <EyeOutlined />
        </Tooltip>
      </button>

      <Modal
        open={user ? visible : false}
        footer={null}
        onCancel={handleModalCancel}
        title="View User Details"
        style={{ top: 20, maxWidth: "800px", minWidth: "800px" }}
        destroyOnClose={true}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        
      >
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            <img src={gifLoader} width={'300px'} height={'300px'} alt="Loading..." />
          </div>
        ) : (
          <Form
              
          layout={'vertical'}
          name="view-user"
       
          initialValues={{ layout: 'vertical' }}
          >
          


            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Mobile No. :"
                
                >
                  <Input
                    name="mobileNumber"
                      readOnly = {true}  
                    defaultValue={user?.user?.mobileNumber}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="User Type :"
                  name="isCreditUser"
                >
                  <Select
                    name="isCreditUser"
                    readOnly = {true}  
                    defaultValue={user?.user?.isCreditUser}
                  >
                    <Option value={true}>Credit User</Option>
                    <Option value={false}>Normal User</Option>
                  </Select>

                </Form.Item>
              </Col>
            </Row>


            <Row gutter={16}>

              <Col span={12}>
                <Form.Item
                  label="Credit Limit :"
                  name="creditLimit"
                >
                  <Input
                    name="creditLimit"
                    readOnly = {true}  
                    defaultValue={typeof user?.user?.creditLimit === 'number'
                      ? user?.user?.creditLimit.toFixed(2)
                      : Number(user?.user?.creditLimit?.$numberDecimal).toFixed(2)}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Credit Amount :">
                  <Input name='creditAmount'  readOnly = {true}   defaultValue={user?.user?.creditAmount.toFixed(2)} />
                </Form.Item>
              </Col>

            </Row>


            <Row gutter={16}>



              <Col span={12}>

                <Form.Item
                  label="Gr Amount :"
                >

                  <Input
                    name="grAmount"
                    readOnly = {true}  
                    defaultValue={user?.user?.grAmount.toFixed(2)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Agent Name :"
                  name="agentName"
                >
                  <Select
                    name="agentName"
                    readOnly = {true}  
                    defaultValue={user?.user?.agentId?.name}
                  >

                    <Option value={user?.user?.agentId?.name} >{user?.user?.agentId?.name}</Option>

                  </Select>
                </Form.Item>
              </Col>


            </Row>

<Row gutter={16}>
 <Col span={12}>
 <Form.Item
                  label="Aadhar No. :"
                >

                  <Input
                    name="aadharnumber"
                    readOnly = {true}  
                    defaultValue={user?.user?.aadharnumber}
                  />
                </Form.Item>
  
 </Col>

 <Col span={12}>
 <Form.Item
                  label="PAN No. :"
                >

                  <Input
                    name="pannumber"
                    readOnly = {true}  
                    defaultValue={user?.user?.pannumber}
                  />
                </Form.Item>
  
 </Col>
</Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="GST No. :"
                >

                  <Input
                    name="gst"
                    readOnly = {true}  
                    defaultValue={user?.user?.gst}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Address :"
                >

                  <Input
                    name="address"
                    readOnly = {true}  
                    defaultValue={user?.user?.address[0].address}
                  />
                </Form.Item>
              </Col>


            </Row>

            <Row gutter={16}>
            <Col span={12}>
                <Form.Item
                  label="City :"
                >
                  <Input
                    name="city"
                    readOnly = {true}  
                    defaultValue={user?.user?.address[0].city}
                  />
                </Form.Item>
              </Col>
           
              <Col span={12}>
                <Form.Item
                  label="State :"
                >

                  <Input
                    name="state"
                    readOnly = {true}  
                    defaultValue={user?.user?.address[0].state}
                  />
                </Form.Item>
              </Col>

           


            </Row>

            <Row gutter={16}>
            <Col span={12}>
                <Form.Item
                  label="Pin :"
                >

                  <Input
                    name="pin"
                    readOnly = {true}  
                    defaultValue={user?.user?.address[0].pincode}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Transport :"
                >

                  <Input
                    name="transport"
                    readOnly = {true}  
                    defaultValue={user?.user?.address[0].transport}
                  />
                </Form.Item>
              </Col>
            </Row> 


          </Form>
        )}
      </Modal>
    </div>
  )
}

export default ViewUserData