import React, { Component } from "react";
import { Row, Col, Tooltip, Form, Select, DatePicker, Button } from "antd";
import SalesPersonReportList from "./SalesPersonReportList";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { getSalesPersonReports, clearData } from "../../../../redux/actions/reportsActions";
import { getCustomers } from "../../../../redux/actions/customerAction";
import TopBarProgress from "react-topbar-progress-indicator";
import { debounce } from "lodash";
import BackButton from "../../Report/Button";
const dateFormat = "YYYY-MM-DD";
const { Option } = Select;

class SalesPersonReport extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      endDate: "",
      startDate: "",
      salesPersonId: "",
      salesPersonName: "",
    };
    this.debouncedGetData = debounce(this.getData, 500);
  }

  componentDidMount() {
    this.props.getCustomers("SALES");
  }

  getData = (name) => {
    if (name == "sales") this.props.getCustomers("SALES");
  };

  handleChangeSalesPerson = (value) => {
    console.log(`selected ${value}`);
    this.setState({ salesPersonId: value });
    this.debouncedGetData("sales");
  };

  componentWillUnmount() {
    this.props.clearData(); // Dispatch clearData action when leaving the component
  }

  onStartDateChange = (date, dateString) => {
    this.setState({ startDate: dateString });
  };
  onEndDateChange = (date, dateString) => {
    this.setState({ endDate: dateString });
  };
  showDataOnList = () => {
    let reportObj = {
      salesPersonId: this.state.salesPersonId,
      startDate: this.state.startDate,
      endDate: this.state.endDate + " " + moment().format("HH:mm:ss"),
    };
    this.props.getSalesPersonReports(reportObj);
  };
  getMonthlyDates = (date, dateString) => {
    const startDate = moment(dateString).startOf("month").format(dateFormat);
    const endDate = moment(dateString).endOf("month").format(dateFormat);
    this.setState({ startDate: startDate, endDate: endDate });
    console.log("###getQuarterlyDates", startDate, endDate);
  };
  getQuarterlyDates = (date, dateString) => {
    console.log("#getQuarterlyDates", dateString);
    var quarter = moment(dateString).quarter();
    const startDate = moment()
      .quarter(quarter)
      .startOf("quarter")
      .format(dateFormat);
    const endDate = moment()
      .quarter(quarter)
      .endOf("quarter")
      .format(dateFormat);
    this.setState({ startDate: startDate, endDate: endDate });
    console.log("###getQuarterlyDates", startDate, endDate);
  };

  getFullYearDates = (date, dateString) => {
    var year = moment(dateString).year();
    const startDate = moment().year(year).startOf("year").format(dateFormat);
    const endDate = moment().year(year).endOf("year").format(dateFormat);
    this.setState({ startDate: startDate, endDate: endDate });
    console.log("###getFullYearDates", startDate, endDate);
  };

  getHalfYearlyDates = (value) => {
    var year = new Date().getFullYear();
    if (value == 1) {
      let startDate = moment().year(year).startOf("year").format(dateFormat);
      let endDate = moment(startDate)
        .add(5, "months")
        .endOf("month")
        .format(dateFormat);
      this.setState({ startDate: startDate, endDate: endDate });
      console.log("###getHalfYearlyDates if", startDate, endDate);
    } else {
      let endDate = moment().year(year).endOf("year").format(dateFormat);
      let startDate = moment(endDate)
        .subtract(5, "months")
        .startOf("month")
        .format(dateFormat);
      this.setState({ startDate: startDate, endDate: endDate });
      console.log("###getHalfYearlyDates else", startDate, endDate);
    }
  };
  render() {
    let salesOptions = this.props.salesPerson.map((sales) => {
      return (
        <Option name={sales.name} value={sales._id}>
          {sales.name}
        </Option>
      );
    });

    return (
      <div className="subCategory-dashboard">
        {this.props.progressBarStatus && <TopBarProgress />}
        <div className="container-fluid category-list">
          <Row className="title-row">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row gutter={24}>
                <Col span={12}>
                  <div className="page-header">Sales Perosn Report</div>
                </Col>
                <Col span={12}>
                  <BackButton />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form
                ref={this.formRef}
                name="add-Purchase"
                className="add-Purchase"
                onFinish={this.showDataOnList}
                initialValues={{ remember: true }}
              >
                <Row gutter={[24, 0]} className="mt-4">
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Tooltip title="Select Sales Person">
                      <Form.Item
                        name="salesPersonName"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Sales Person !",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Select Sales Person"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                          onChange={this.handleChangeSalesPerson}
                        // onClick={() => this.debouncedGetData("sales")}
                        // onInputKeyDown={() => this.debouncedGetData("sales")}
                        >
                          {salesOptions}
                        </Select>
                      </Form.Item>
                    </Tooltip>
                  </Col>
                </Row>
                <Row gutter={[16, 0]}>
                  <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Tooltip title="Select Month">
                      <DatePicker
                        style={{ width: "100%" }}
                        onChange={this.getMonthlyDates}
                        picker="month"
                        placeholder="Select Month"
                      // format={dateFormat}
                      />
                    </Tooltip>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Tooltip title="Select Quarter">
                      <DatePicker
                        style={{ width: "100%" }}
                        onChange={this.getQuarterlyDates}
                        picker="quarter"
                        placeholder="Select Quarter"
                        format={dateFormat}
                      />
                    </Tooltip>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Tooltip title="Select Period Of Current Year">
                      <Select
                        style={{ width: "100%" }}
                        showSearch
                        placeholder="Select Period Of Current Year"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                        onChange={this.getHalfYearlyDates}
                      >
                        <Option value="1">First Half</Option>
                        <Option value="2">Second Half</Option>
                      </Select>
                    </Tooltip>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Tooltip title="Select Year">
                      <DatePicker
                        style={{ width: "100%" }}
                        onChange={this.getFullYearDates}
                        picker="year"
                        placeholder="Select Year"
                        format={dateFormat}
                      />
                    </Tooltip>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="close-modal mt-4"
                        block
                      >
                        Search
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <SalesPersonReportList
            reports={this.props.reports}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            dealerName={this.state.dealerName}
          />
        </div>
      </div>
    );
  }
}
SalesPersonReport.propTypes = {
  getCustomers: PropTypes.func.isRequired,
  getSalesPersonReports: PropTypes.func.isRequired,
  clearData: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  salesPerson: state.customerData.salesPerson,
  reports: state.reportData.salespersonreports,
  progressBarStatus: state.progressBarReducer.showProgressBar

});

export default connect(mapStateToProps, {
  getCustomers,
  getSalesPersonReports, clearData
})(SalesPersonReport);