import React, { Component } from "react";
import { Table, Button, Space, Input, Tag } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { CSVLink } from "react-csv";
import moment from "moment";
const { Column } = Table;

export default class SalesPersonReportList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      options: [],
    };
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  this.setState({
                    searchText: selectedKeys[0],
                    searchedColumn: dataIndex,
                  });
                }}
              >
                Filter
              </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };
  render() {
    const {reports}  =  this.props
    const headers = [
      { label: "Invoice ID", key: "invoiceId" },
      { label: "Barcode ", key: "barcode" },
      { label: "Brand ", key: "brand" },
      { label: "Category ", key: "category" },
      { label: "Style ", key: "style" },

      { label: "Quantity ", key: "quantity" },
      { label: "created_at", key: "created_at" },
    ];

    return (
      <>
        <div className="card mt-3 final-purchases-list">
          <div className="card-body">
            <Table
              dataSource={reports && reports.length > 0 ? reports : []}
              bordered="true"
              size="middle"
              pagination={false}
              scroll={{ y: 240 }}
            >
              <Column
                title="SRN"
                key="index"
                render={(value, item, index) => index + 1}
                width={60}
              />
              <Column
                title="Invoice Id"
                dataIndex="invoiceId"
                key="invoiceId"
                width={110}
              />
              <Column
                title="Barcode"
                dataIndex="barcode"
                key="barcode"
                width={110}
                {...this.getColumnSearchProps("barcode")}
              />
              <Column
                title="Brand"
                dataIndex="brand"
                key="brand"
                width={110}
                {...this.getColumnSearchProps("brand")}

              />
              <Column
                title="Category"
                dataIndex="category"
                key="category"
                width={110}
                {...this.getColumnSearchProps("category")}

              />
              <Column
                title="Style"
                dataIndex="style"
                key="style"
                width={110}
              />
              <Column
                title="Quantity"
                dataIndex="quantity"
                key="quantity"
                // {...this.getColumnSearchProps("quantityd")}
                width={120}
              />
              <Column
                title="Created Date"
                dataIndex="created_at"
                key="created_at"
                width={120}
                render={(value, item, index) =>
                  moment(item.created_at).format("YYYY-MM-DD")
                }
              />
             
            </Table>
          </div>
        </div>
        {(() => {
          if (this.props && this.props.reports && this.props.reports.length > 0) {
            return (
              <Button
                type="primary"
                htmlType="submit"
                className="save-modal mt-4"
                block
              >
                <CSVLink
                  filename={`${this.props.startDate} to ${this.props.endDate}.csv`}
                  data={this.props.reports}
                  headers={headers}
                >
                  Download CSV
                </CSVLink>
              </Button>
            );
          }
        })()}
      </>
    );
  }
}
