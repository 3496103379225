import React, { Component } from "react";
import { Table, Button, Space, Input, Tag, Row, Col } from "antd";
import { connect } from "react-redux";
import Highlighter from "react-highlight-words";
import TopBarProgress from "react-topbar-progress-indicator";
import { SearchOutlined } from "@ant-design/icons";
const { Column } = Table;

export class RecyclePurchase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: "",
            current: 1,
            search: "",
            data: [],
        };
    }

    componentDidMount = () => {
        // this.props.getSalesReport(reportObj)
    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    // placeholder="Search For Robot"
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        this.handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ marginBottom: 8, display: "block" }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                        className="search-name"
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => this.handleReset(clearFilters, confirm)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    {/**<Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  this.setState({
                    searchText: selectedKeys[0],
                    searchedColumn: dataIndex,
                  });
                }}
              >
                Filter
              </Button> */}
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : "",
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: (text) =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });


    render() {
        return (
            <>
                <div className="subCategory-dashboard">
                    {this.props.progressBarStatus && <TopBarProgress />}
                    <div className="container-fluid category-list">
                        <Row className="title-row mb-4">
                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                <div className="page-header">Recycle Purchase</div>
                            </Col>
                        </Row>
                        <div className="card mt-4 final-purchases-list">
                            <div className="card-body">
                                <Table
                                    dataSource={[]}
                                    bordered="true"
                                    size="middle"
                                    scroll={{ y: 240 }}
                                    pagination={false}
                                >
                                    <Column
                                        title="SRN"
                                        key="index"
                                        render={(value, item, index) => index + 1}
                                        width={60}
                                    />
                                    <Column
                                        title="Sale Id"
                                        {...this.getColumnSearchProps("invoiceId")}
                                        dataIndex="invoiceId"
                                        key="invoiceId"
                                        width={100}
                                    />
                                    <Column
                                        title="Barcode"
                                        {...this.getColumnSearchProps("barcode")}
                                        dataIndex="barcode"
                                        key="barcode"
                                        width={130}
                                    />
                                    <Column
                                        title="Design No"
                                        dataIndex="designNumber"
                                        key="designNumber"
                                        width={140}
                                    />
                                    <Column title="Customer Name" dataIndex="customerName" key="customerName" width={160} />
                                    <Column title="Brand" dataIndex="brand" key="brand" width={100} />
                                    <Column title="Size" dataIndex="size" key="size" width={100} />
                                    <Column title="Color" dataIndex="color" key="color" width={100} />
                                    <Column title="Category" dataIndex="category" key="category" width={100} />
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

RecyclePurchase.propTypes = {
    // getTopCustomerReports: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
    salesAll: state.reportData.salesAll,
});


export default connect(mapStateToProps, {
    // getTopCustomerReports,
})(RecyclePurchase);
