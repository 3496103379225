import React, { Component } from "react";
import { Table, Button, Space, Input, Tag } from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
// import { getTopCustomerReports } from "../../../../redux/actions/getTopCustomerReports";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { CSVLink } from "react-csv";
const { Column } = Table;
const data = [];

export class PurchaseAllReportList  extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      data: [],
    };
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  this.setState({
                    searchText: selectedKeys[0],
                    searchedColumn: dataIndex,
                  });
                }}
              >
                Filter
              </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };

  render() {
    const{purchaseAll} = this.props
    const headers = [
      { label: "Purchase/Creation Date", key: "date" },
      { label: "Bill No", key: "billNo" },
      { label: "Vendor Name", key: "dealerName" },
      { label: "Barcode", key: "barcode" },
      { label: "Design No", key: "designNumber" },
      { label: "Brand", key: "brand" },
      { label: "Size", key: "size" },
      { label: "Color", key: "color" },
      { label: "Category", key: "category" },
      { label: "Style", key: "style" },
      { label: "Floor", key: "floorName" },
      { label: "Section", key: "section" }, 
      { label: "HSN Code", key: "HSNCode" },
      { label: "Season", key: "season" },
      { label: "MOQ", key: "MOQ" },
      { label: "QTY", key: "quantity" },
      { label: "Purchase Price", key: "purchasePrice" },
      { label: "WSP", key: "wsp" },
      { label: "Total Amount", key: "totalPurchasePrice" },
      { label: "TAX(%)", key: "tax" },
      { label: "TAX(Rs.)", key: "taxAmount" },
      { label: "Discount(%)", key: "discount" },
      { label: "Net Amount", key: "netAmount" },
      { label: "Remark", key: "remark" }
    ];
    

    return (
      <>
        <div className="card mt-2 final-purchases-list">
        {console.log(" purchaseAll.data", purchaseAll?.data)}
          <div className="card-body">
            <Table
              dataSource={ purchaseAll &&  purchaseAll?.data ? purchaseAll?.data :  []}
              // dataSource={  []}
              bordered="true" 
              size="middle"
              scroll={{ y: 240 }}
              pagination={false}
            //   pagination={{
            //     pageSize: 10,
            //     total: this.props.reports.count,
            //     onChange: (page) => {
            //       this.props.getStockAgeingReports(page);
            //     },
            //   }}
            >
              <Column
                title="SRN"
                key="index"
                render={(value, item, index) => index + 1}
                width={60}
              />
              <Column
                title="Purchase/Creation Date"
                key="date"
                dataIndex="date"
                width={100}
              />
              <Column
                title="Bill No"
                {...this.getColumnSearchProps("billNo")}
                dataIndex="billNo"
                key="billNo"
                width={100}
              />
              <Column title="Vendor Name" dataIndex="dealerName" key="dealerName" width={150} />
               <Column
                title="Barcode"
                {...this.getColumnSearchProps("barcode")}
                dataIndex="barcode"
                key="barcode"
                width={130}
              />
              <Column
                title="Design No"
                dataIndex="designNumber"
                key="designNumber"
                width={120}
              />
              <Column title="Brand" dataIndex="brand" key="brand" width={100} />
              <Column title="Size" dataIndex="size" key="size" width={100} />
              <Column title="Color" dataIndex="color" key="color" width={130} />
              <Column title="category" dataIndex="category" key="category" width={100} />
              <Column title="Style" dataIndex="style" key="style" width={100} />
              <Column title="Floor" dataIndex="floorName" key="floorName" width={100} />
              <Column title="Section" dataIndex="section" key="section" width={100} />
              <Column title="HSN Code" dataIndex="HSNCode" key="HSNCode" width={100} />
              <Column title="Season" dataIndex="season" key="season" width={100} />
              <Column title="MOQ" dataIndex="MOQ" key="MOQ" width={100} />
              <Column title="QTY" dataIndex="quantity" key="quantity" width={100} />
              <Column title="Purchase Price" dataIndex="purchasePrice" key="purchasePrice" width={160} />
              <Column title="WSP" dataIndex="wsp" key="wsp" width={100} />
              <Column title="Total Amount" dataIndex="totalPurchasePrice" key="totalAmount" width={100} />
              <Column title="TAX(%)" dataIndex="tax" key="tax" width={100} />
              <Column title="TAX(Rs.)" dataIndex="taxAmount" key="taxAmount" width={100} />
              <Column title="Discount(%)" dataIndex="discount" key="discount" width={100} />
              <Column title="Net Amount" dataIndex="netAmount" key="netAmount" width={120} />
              <Column title="Remark" dataIndex="remark" key="remark" width={100} />
            </Table>
          </div>
        </div>
        {(() => {
          if (this.props?.purchaseAll?.data?.length > 0) {
            return (
              <Button
                type="primary"
                htmlType="submit"
                className="save-modal mt-4"
                block
              >
                <CSVLink
                  filename={`Purchase-Report.csv`}
                  data={purchaseAll.data}
                  headers={headers}
                >
                  Download CSV
                </CSVLink>
              </Button>
            );
          }
        })()}
      </>
    );
  }
}

PurchaseAllReportList.propTypes = {
    // getTopCustomerReports: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
  purchaseAll: state.reportData.purchaseAll,
});


export default connect(mapStateToProps, {
    // getTopCustomerReports,
})(PurchaseAllReportList);