import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import MenuCart from "./sub-components/MenuCart";
import { deleteFromCart } from "../../redux/actions/cartActions";
import SearchBar from "./SearchBar";
import { Avatar, Row, Col, Typography, Tooltip } from "antd";
import { ShoppingCartOutlined, UserOutlined } from "@ant-design/icons";
import jwt_decode from 'jwt-decode';
const { Title } = Typography;
const IconGroup = ({
  currency,
  cartData,
  wishlistData,
  compareData,
  deleteFromCart,
  iconWhiteClass,
}) => {
  var login = localStorage.getItem("token");
  const [ecomName, setEcomName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  const dropdownRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // Clicked outside the dropdown, close it
        setIsOpen(false);
        setIsOpen1(false)
        setIsOpen2(false)
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickSearch = () => {
    setIsOpen(!isOpen);
    setIsOpen1(false)
    setIsOpen2(false)
  };

  const handleClickAccount = () => {
    setIsOpen1(!isOpen1);
    setIsOpen(false)
    setIsOpen2(false)
  }

  const handleClickCart = () => {
    setIsOpen2(!isOpen2)
    setIsOpen(false)
    setIsOpen1(false)
  }
  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add("active");

  };
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("isCreditUser");
    localStorage.removeItem("roleName");
    history.push("/")
    window.location.reload();
  };
  const handleRegister = () => {
    //   localStorage.setItem({tabType : "register"})

    history.push({
      pathname: process.env.PUBLIC_URL + "/login-register",
      state: {
        tabType: "login"
      }
    });

    window.location.reload();

  }
  const handlelogin = () => {
    // localStorage.setItem({tabType : "login"})
    history.push({
      pathname: process.env.PUBLIC_URL + "/login-register",
      state: {
        tabType: "login"
      }
    });

    window.location.reload();

  }

  useEffect(() => {
    let decoded
    if (login) {
      decoded = jwt_decode(login);
      const Name = decoded?.name || ""
      setEcomName(Name)
    }
  }, [login])

  return (
    <div
      ref={dropdownRef}
      className={`header-right-wrap w-100 ${iconWhiteClass ? iconWhiteClass : ""}`}
    >
      <div className="same-style header-search searchBarNavbar w-100">
        {/* <button className="search-active" onClick={handleClickSearch}>
          <i className="pe-7s-search" />
        </button> */}
        <div >
          <SearchBar />
        </div>
      </div>

      <div className="same-style account-setting d-none d-block">
        <button
          className="account-setting-active"
          onClick={handleClickAccount}
        >
          {ecomName ?
            <>
              {/* {ecomName.charAt(0).toUpperCase()} */}
              <UserOutlined />
              <div className="profileHeader">Profile</div>
            </>
            :
            // <Avatar
            //   style={{ backgroundColor: '#1677ff', color: '#fff' }}
            //   icon={<UserOutlined />} />
            <>
              <UserOutlined />
              <div className="profileHeader">Profile</div>
            </>

          }
        </button>
        <div className={`account-dropdown ${isOpen1 ? "active" : ""}`}>
          <ul>
            {(() => {
              if (!login) {
                return (
                  <>
                    <li>
                      <Link to={{
                        pathname: process?.env?.PUBLIC_URL + "/login-register", state: {
                          tabType: "login"
                        }
                      }
                      } onClick={handlelogin}>
                        Login
                      </Link>
                    </li>
                    <li>
                      <Link to={{
                        pathname: process?.env?.PUBLIC_URL + "/login-register", state: {
                          tabType: "register"
                        }
                      }
                      } onClick={handleRegister} >
                        Register
                      </Link>
                    </li>
                  </>
                );
              } else {
                return (
                  <>
                    <Row align="middle" gutter={8} >
                      <Col>
                        <Tooltip title={ecomName.toUpperCase()}><div className="user-name-Ecom" > <UserOutlined style={{ fontSize: "13px", margin: "0px !important", padding: "0px !important" }} /> <span>{ecomName.toUpperCase()}</span></div> </Tooltip>
                      </Col>
                    </Row>
                    <hr className="hr-tag" />

                    <li>
                      <Link to={process?.env?.PUBLIC_URL + "/login-register"} onClick={logout}>Logout</Link>
                    </li>
                  </>
                );
              }
            })()}
            <li>
              <Link to={process?.env?.PUBLIC_URL + "/my-account"}>
                my account
              </Link>
            </li>

            {(() => {
              if (login) {
                return (
                  <>
                    <li>
                      <Link to={process?.env?.PUBLIC_URL + "/my-orders"}>
                        My Orders
                      </Link>
                    </li>

                  </>
                );
              }
            })()}
          </ul>
        </div>
      </div>

      {/**<div className="same-style header-wishlist">
        <Link to={process.env.PUBLIC_URL + "/wishlist"}>
          <i className="pe-7s-like" />
          <span className="count-style">
            {wishlistData && wishlistData.length ? wishlistData.length : 0}
          </span>
        </Link>
      </div> */}
      <div className="same-style cart-wrap d-none d-lg-block">
        <button className="icon-cart" onClick={handleClickCart}>
          <ShoppingCartOutlined />
          <div className="profileHeader">Bag</div>
          {
            cartData && cartData.length>0 ?
              <span className="count-style cartItemCount" >
                {cartData.length}
              </span>
              :
              <>
              </>
          }
          {/* <span className="count-style cartItemCount" >
            {cartData && cartData.length ? cartData.length : 0}
          </span> */}
        </button>
        {/* menu cart */}
        <MenuCart
          setIsOpen2={isOpen2}
          cartData={cartData}
          currency={currency}
          deleteFromCart={deleteFromCart}
        />
      </div>
      <div className="same-style cart-wrap d-block d-lg-none">
        <Link className="icon-cart" to={process?.env?.PUBLIC_URL + "/cart"}>
          <ShoppingCartOutlined />
          <div className="profileHeader">Bag</div>
          {
            cartData && cartData.length>0 ?
              <span className="count-style cartItemCount" >
                {cartData.length}
              </span>
              :
              <>
              </>
          }
          {/* <span className="count-style cartItemCount" >
            {cartData && cartData.length ? cartData.length : 0}
          </span> */}
        </Link>
      </div>
      <div className="same-style mobile-off-canvas d-block d-lg-none d-flex align-items-center">
        <button
          className="mobile-aside-button"
          onClick={() => triggerMobileMenu()}
        >
          <i className="pe-7s-menu" />
        </button>
      </div>
    </div>
  );
};

IconGroup.propTypes = {
  cartData: PropTypes.array,
  compareData: PropTypes.array,
  currency: PropTypes.object,
  iconWhiteClass: PropTypes.string,
  deleteFromCart: PropTypes.func,
  wishlistData: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    currency: state.currencyData,
    cartData: state.cartData,
    wishlistData: state.wishlistData,
    compareData: state.compareData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteFromCart: (item, addToast) => {
      dispatch(deleteFromCart(item, addToast));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IconGroup);