import React, { Component } from "react";
import { Table, Row, Col,Popconfirm, Tooltip } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
const { Column } = Table;

export default class POList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      returnData: [],
    };
  }
  shouldComponentUpdate(nextProps, prevState) {
    if (
      nextProps.data != undefined &&
      nextProps.data.length != prevState.returnData.length
    ) {
      this.setState({
        returnData: nextProps.data,
      });
    }

    return true;
  }
  onRemoveQnty = (obj, index) => {
    this.props.onRemoveQnty(obj, index);
  };
  onAddQnty = (obj, index) => {
    this.props.onAddQnty(obj, index);
  };
  onDeleteClick = (id) => {
    this.props.delete(id);
  };
  render() {
    let data;
    return (
      <div className="purchase-list mt-3">
        <Table
          dataSource={this.props.data}
          bordered="true"
          size="middle"
          pagination={false}
          scroll={{ y: 240 }}
        >
          <Column
            title="SRN"
            key="index"
            render={(value, item, index) => index + 1}
            width={60}
          />
          <Column
            title="Dealer"
            dataIndex="dealerName"
            key="dealerName"
            width={110}
          />
           <Column
            title="Brand"
            dataIndex="brandName"
            key="brand"
            width={110}
          />
          <Column
            title="Design Number"
            dataIndex="designNumber"
            key="designNumber"
            width={110}
          />
          <Column
            title="Size"
            dataIndex="sizeName"
            key="sizeName"
            width={110}
          />
          <Column
            title="Qnty"
            dataIndex="quantity"
            key="quantity"
            width={110}
          />
          <Column
            title="Action"
            key="action"
            width={75}
            render={(text, record, index) => (
              <Row gutter={[10, 0]}>
                {(() => {
                  data = JSON.parse(JSON.stringify(text));
                  data.index = index;
                  data.text= text;   
                })()}
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  {(() => {
                    if (text.quantity >= this.props.quantity) {
                      return (
                        <Tooltip title="Add Qnty">
                          <a type="button" onClick={() => this.onAddQnty(text, index)}>
                            <PlusCircleOutlined />
                          </a>
                        </Tooltip>
                      );
                    } else {
                      return (
                        <Tooltip title="Add Qnty">
                          <a type="button" onClick={() => this.onAddQnty(text)}>
                            <PlusCircleOutlined />
                          </a>
                        </Tooltip>
                      );
                    }
                  })()}
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  {(() => {
                    if (text.quantity <= 1) {
                      return (
                        <Tooltip title="Remove Qnty">
                          <a type="button" aria-disabled>
                            <MinusCircleOutlined />
                          </a>
                        </Tooltip>
                      );
                    } else {
                      return (
                        <Tooltip
                          title="Remove Qnty"
                          onClick={() => this.onRemoveQnty(text, index)}
                        >
                          <a type="button">
                            <MinusCircleOutlined />
                          </a>
                        </Tooltip>
                      );
                    }
                  })()}
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Popconfirm
                      title="Are you sure to delete this Record?"
                      onConfirm={() => this.onDeleteClick(index)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Tooltip title="Delete Record">
                        <DeleteOutlined />
                      </Tooltip>
                    </Popconfirm>
                  </Col>
              </Row>
            )}
          />
        </Table>
      </div>
    );
  }
}