/* /////////////////////////////////////// */
/* 
Date              created by               Modifications
  
04-08-2023        Mohit                    View Bill for sales list



*/
/* /////////////////////////////////////// */

import { FileDoneOutlined } from "@ant-design/icons";
import React, { Component } from "react";
import {
  Modal,
  Table,
  Button,
  Typography,
  Col,
  Row,
  Tooltip,
  message,
} from "antd";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import {
  createSales,
  getSaleUsingInvoiceId,
} from "../../../../redux/actions/saleAction";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { Spin } from "antd";
import { getEcomDetailsByinvoiceIds } from "../../../../redux/actions/ecomSaleAction";

// import "../../../assets/css/generateBill.css";

class GenerateEcomBillForMerge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      finalAmount: 0,
      // billDate: "",
      // saleDate: moment(new Date()),
    };
  }

  handleModalCancel = () => {
    this.props.getSaleUsingInvoiceId("reset");
    this.setState({ visible: false });
  };

  showModal = () => {
    this.getDetails();
  };

  getDetails = async () => {
    let data = this.props?.invoiceData?.map((obj) => obj);
    if (data.length > 0) {
      this.setState({
        visible: true,
      });
      let obj = {
        ecomsalesId: data,
      };
      this.props.getEcomDetailsByinvoiceIds(obj);
      let invoiceId = this.props?.invoiceId
        ? this.props?.invoiceId
        : this.props?.text?.invoiceId;
      await this.props.getSaleUsingInvoiceId(invoiceId);
    } else {
      message.warn("Please Select Bills!");
    }
  };

  handlePdfGeneration = () => {
    const element = document.getElementById("pdf-content");
    if (!element) {
      return;
    }

    element.style.width = "400px";
    element.style.margin = "0 auto";

    const momentDate = moment(this.props?.record?.date);
    const formattedDate = momentDate.format("DD/MM/YYYY");
    const formattedTime = momentDate.format("HH:mm:ss");

    const opt = {
      margin: [10, 10, 10, 10],
      filename: `${this.props?.ecomsalesOrders?.data?.[0]?.invoiceId}-${this.props?.text?.userId?.name}-${formattedDate}-${formattedTime}`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      // css: customPdfStyles
    };

    html2pdf()
      .from(element)
      .set(opt)
      .save()
      .then(() => {
        element.style.width = null;
        element.style.margin = null;
      });
  };

  // calculateNetAmount=(amount)=>{
  //   console.log("check mohit netmmmmm", amount)
  //   let total = Number(amount) + Number(this.state.finalAmount)
  //   console.log("check mohit netmmmmm total", total)

  //   this.setState({finalAmount : total})
  // }

  render() {
    const { visible } = this.state;
    const { ecomsalesOrders, text } = this.props;
    let myData = ecomsalesOrders?.data?.map((objData, index) => {
      const purchaseData = objData.salesDetails; // Assuming salesDetails is the object you showed

      let track = {};
      let brands = [];
      let e = 0;
      let f = Object.keys(purchaseData).length; // Use Object.keys to get the number of items in purchaseData

      while (e < f) {
        const key = e.toString(); // Convert e to string to access purchaseData[key]
        const purchase = purchaseData[key];

        if (purchase.brand in track) {
          track[purchase.brand].push(key);
        } else {
          track[purchase.brand] = [key];
          brands.push(purchase.brand);
        }
        ++e;
      }
      console.log("brands", brands);
      let data = brands.sort().map((purchaseName) => {
        let indexes = track[purchaseName];
        let insideData = indexes.map((key) => {
          let purchase = purchaseData[key];
          return (
            <li key={key}>
              <div className="page-break">
                <Row className="mt-n2">
                  <Col className="mr-1" style={{ width: "108px" }}>
                    <p style={{ fontWeight: 800 }}>
                      {parseInt(key) + 1} || {purchase.designNumber}
                    </p>
                  </Col>
                  <Col className="mr-1" style={{ width: "80px" }}>
                    <p style={{ fontWeight: 800 }}>{purchase.size}</p>
                  </Col>
                  <Col className="mr-2" style={{ width: "50px" }}>
                    <p className="mb-n2" style={{ fontWeight: 800 }}>
                      {purchase.quantity}
                    </p>
                  </Col>
                  <Col className="mr-1" style={{ width: "50px" }}>
                    <p className="mb-n2" style={{ fontWeight: 800 }}>
                      {purchase.wsp}
                    </p>
                  </Col>
                  <Col className="mr-1" style={{ width: "66px" }}>
                    <p className="mb-n2" style={{ fontWeight: 800 }}>
                      {Math.round(purchase.quantity * purchase.wsp)}
                    </p>
                  </Col>
                </Row>
              </div>
              <hr />
            </li>
          );
        });
        return (
          <li>
            <h5>
              <u>
                <strong style={{ fontSize: "18px" }}>{purchaseName}</strong>
              </u>
            </h5>
            <ul>{insideData}</ul>
          </li>
        );
      });
      return (
        <>
          <li>
            <h5>
              <u>
                <strong style={{ fontSize: "18px" }}>
                  Ecom Sales Id : {objData.ecomsalesId}
                </strong>
              </u>
            </h5>
            <h5>
              <u>
                <strong style={{ fontSize: "18px" }}>
                  Date : {moment(objData?.date).format('DD/MM/YYYY')}
                </strong>
              </u>
            </h5>
            
            <ul>{data}</ul>
          </li>
          <Row className="mt-2">
            <Col className="mr-2" style={{ width: "120px", textAlign: "left" }}>
              <p>Total Qty</p>
            </Col>
            <Col
              className="mr-2"
              style={{ textAlign: "center", width: "120px" }}
            >
              <p style={{ fontWeight: 800 }}>{objData.totalQuantity}</p>
            </Col>
            <Col className="mr-2" style={{ textAlign: "end", width: "110px" }}>
              <p style={{ fontWeight: 800 }}>
                {/* {this.props.netAmount +
     this.props.grAmount -
     Math.round(this.props.taxedAmount) +
     Math.round(this.props.discountedAmount)} */}
                {Number(objData.totalAmount).toFixed()}
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="mr-2" style={{ width: "120px", textAlign: "left" }}>
              <p>Discount</p>
            </Col>
            <Col
              className="mr-2"
              style={{ textAlign: "center", width: "120px" }}
            >
              <p style={{ fontWeight: 800 }}>{objData.discount}%</p>
            </Col>
            <Col className="mr-2" style={{ textAlign: "end", width: "110px" }}>
              <p style={{ fontWeight: 800 }}>
                {(Number(objData.totalAmount) * objData.discount) / 100}
              </p>
            </Col>
          </Row>

          <Row>
            <Col className="mr-2" style={{ width: "120px", textAlign: "left" }}>
              <p>VAT/CST</p>
            </Col>
            <Col
              className="mr-2"
              style={{ textAlign: "center", width: "120px" }}
            >
              <p style={{ fontWeight: 800 }}>{objData.tax}%</p>
            </Col>
            <Col className="mr-2" style={{ textAlign: "end", width: "110px" }}>
              <p style={{ fontWeight: 800 }}>
                {Number(
                  ((Number(objData.totalAmount) -
                    (Number(objData.totalAmount) * objData.discount) / 100) *
                    objData.tax) /
                    100
                )}
              </p>
            </Col>
          </Row>

          <Row>
            <Col className="mr-2" style={{ width: "120px", textAlign: "left" }}>
              <p>P. & F.</p>
            </Col>
            <Col style={{ textAlign: "center", width: "120px" }}></Col>
            <Col
              className="generate-bill"
              style={{ textAlign: "end", width: "120px" }}
            >
              <p style={{ fontWeight: 800 }}>{objData.pAndf}</p>
            </Col>
          </Row>
          <Row>
            <Col className="mr-2" style={{ width: "120px", textAlign: "left" }}>
              <p>Frieght</p>
            </Col>
            <Col
              className="mr-2"
              style={{ textAlign: "center", width: "120px" }}
            ></Col>
            <Col
              className="generate-bill"
              style={{ textAlign: "end", width: "110px" }}
            >
              <p style={{ fontWeight: 800 }}>0.00</p>
            </Col>
          </Row>

          <Row>
            <Col className="mr-2" style={{ width: "120px", textAlign: "left" }}>
              <p>Net Amount</p>
            </Col>
            <Col
              className="mr-2"
              style={{ textAlign: "center", width: "120px" }}
            ></Col>
            <Col
              className="generate-bill"
              style={{ textAlign: "end", width: "110px" }}
            >
              <p style={{ fontWeight: 800 }}>
                {Number(objData.netAmount).toFixed()}
              </p>
            </Col>
          </Row>
          <hr />
        </>
      );
    });

    return (
      <>
        <div className="generate-bill">
          {/* <Button
          type="primary"
          onClick={this.showModal}
          className="close-modal"
          block
        >
          Generate Bill
        </Button> */}
          {window.location.pathname == "/dagaImpex/salesList" ? (
            <>
              <Tooltip title="View Bill">
                <FileDoneOutlined onClick={this.showModal} />
              </Tooltip>
            </>
          ) : (
            <>
              <Button
                type="primary"
                onClick={this.showModal}
                className="close-modal"
                block
              >
                Generate Bill
              </Button>
            </>
          )}

          <ReactToPrint content={() => this.componentRef}>
            <Modal
              visible={visible}
              onOk={this.handleOk}
              onCancel={this.handleModalCancel}
              title="Generate Bill"
              style={{ top: 20 }}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              destroyOnClose={true}
              width={1500}
            >
              <div className="card generate-bill-card" id="pdf-content">
                <div
                  className="card-body generate-bill-font"
                  style={{ padding: "0.6rem" }}
                  ref={(el) => (this.componentRef = el)}
                >
                  {
                    // data && data.length
                    true ? (
                      <>
                        <div className="generate-bill-font-test7">
                          <Row>
                            <Col span={8} style={{ textAlign: "center" }}>
                              <strong>
                                <p
                                  className="mb-n2 font-weight-bold"
                                  style={{ width: "380px" }}
                                >
                                  || Jai Shree Krishna ||
                                </p>
                              </strong>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              span={8}
                              className="mr-2"
                              style={{ textAlign: "center" }}
                            >
                              <h4
                                className="mb-n1 mt-2"
                                style={{ width: "380px" }}
                              >
                                Estimate
                              </h4>
                            </Col>
                          </Row>
                          <Row className="mt-4">
                            <Col>
                              <p style={{ width: "350px" }}>
                                <strong>
                                  Customer :
                                  {(ecomsalesOrders &&
                                    ecomsalesOrders.data &&
                                    ecomsalesOrders?.data[0]?.userId?.name) ||
                                    ""}
                                </strong>
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p style={{ width: "350px" }}>
                                <strong>
                                  Agent :
                                  {ecomsalesOrders &&
                                  ecomsalesOrders.data &&
                                  ecomsalesOrders?.data[0]?.userId &&
                                  ecomsalesOrders?.data[0]?.userId?.agentId &&
                                  ecomsalesOrders?.data[0]?.userId?.agentId
                                    ?.name
                                    ? ecomsalesOrders?.data[0]?.userId?.agentId
                                        ?.name
                                    : ""}
                                </strong>
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="mr-5">
                              <p className="mb-n2" style={{ width: "350px" }}>
                                <strong>
                                  Date :
                                  {(() => {
                                    {/* const momentDate = moment(
                                      (ecomsalesOrders &&
                                        ecomsalesOrders.data &&
                                        ecomsalesOrders?.data[0]?.createdAt) ||
                                        ""
                                    ); */}
                                    const momentDate = moment();
                                    const formattedDate =
                                      momentDate.format("DD/MM/YYYY");
                                    const formattedTime =
                                      momentDate.format("HH:mm:ss");
                                    return (
                                      <span>
                                        {formattedDate} &nbsp;&nbsp;{" "}
                                        {formattedTime}
                                      </span>
                                    );
                                  })()}
                                </strong>
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={12}>
                              {/* <strong> <p className="mb-n2">Note : {ecomsalesOrders[0]?.salesBilldetails?.remark}</p></strong> */}
                            </Col>
                          </Row>
                          <div style={{ width: "380px", display: "block" }}>
                            <Row className="mt-3">
                              <Col className="mr-1" style={{ width: "114px" }}>
                                <strong>
                                  {" "}
                                  <p class="font-weight-bold">Particulars</p>
                                </strong>
                              </Col>
                              <Col className="mr-1" style={{ width: "80px" }}>
                                <strong>
                                  <p class="font-weight-bold">Size</p>
                                </strong>
                              </Col>
                              <Col className="mr-1" style={{ width: "50px" }}>
                                <strong>
                                  {" "}
                                  <p class="font-weight-bold">Qty</p>
                                </strong>
                              </Col>
                              <Col className="mr-1" style={{ width: "50px" }}>
                                <strong>
                                  {" "}
                                  <p class="font-weight-bold">Rate</p>
                                </strong>
                              </Col>
                              {/*<Col className="mr-1" style={{ width: "70px" }}>
                    <p class="font-weight-bold">Color</p>
    </Col>*/}
                              <Col className="mr-1" style={{ width: "60px" }}>
                                <p class="font-weight-bold">Amt</p>
                              </Col>
                            </Row>
                            <hr />
                            <ul>{myData}</ul>
                            {/* <hr /> */}
                            <Row>
                              <Col
                                className="mr-2"
                                style={{ width: "240px", textAlign: "left" }}
                              >
                                <p>Final Amount</p>
                              </Col>

                              <Col
                                className="generate-bill"
                                style={{ textAlign: "end", width: "110px" }}
                              >
                                <p style={{ fontWeight: 800 }}>
                                  {Number(
                                    ecomsalesOrders?.finalNetAmount
                                  ).toFixed()}
                                </p>
                              </Col>
                            </Row>
                            <hr />
                          </div>
                          <div className="mx-2">
                            <Row>
                              <Col
                                style={{ width: "175px", textAlign: "left" }}
                              >
                                <p className="mb-n2">E.D.E.</p>
                              </Col>
                              <Col style={{ textAlign: "end", width: "175px" }}>
                                <p className="mb-n2">Authorised Signatory</p>
                              </Col>
                            </Row>
                            <p className="mb-n2">Terms & Cond.</p>
                            <p className="mb-n2">
                              1. Goods once sold will not be taken back
                            </p>
                            <p>2. No Claim No Guarantee</p>
                          </div>
                          <Row>
                            <Col span={8} style={{ textAlign: "center" }}>
                              <p
                                className="font-weight-bold"
                                style={{ width: "350px" }}
                              >
                                || Thank You Visit Again ||
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="spin-ViewBill">
                          <Spin />
                        </div>
                      </>
                    )
                  }
                </div>
              </div>
              <Row className="generate-pdf-download-row">
                <Col>
                  <PrintContextConsumer>
                    {({ handlePrint }) => (
                      <Button
                        style={{ marginLeft: "45%" }}
                        type="primary"
                        className="close-modal mt-4"
                        // block
                        onClick={() => {
                          // this.handlePdfGeneration()
                          handlePrint();
                          // this.props.clearState(false)
                          // this.props.createSales({}, {}, "blank");
                          // this.props.createSales(false);
                        }}
                      >
                        Generate Bill
                      </Button>
                    )}
                  </PrintContextConsumer>
                </Col>
                <Col style={{ marginLeft: "1rem" }}>
                  <Button
                    style={{ marginLeft: "45%" }}
                    type="primary"
                    className="save-modal mt-4"
                    // block
                    onClick={() => {
                      this.handlePdfGeneration();
                    }}
                  >
                    Download PDF
                  </Button>
                </Col>
              </Row>
            </Modal>
          </ReactToPrint>
        </div>
      </>
    );
  }
}

GenerateEcomBillForMerge.propTypes = {
  createSales: PropTypes.func.isRequired,
  getSaleUsingInvoiceId: PropTypes.func.isRequired,
  getEcomDetailsByinvoiceIds: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  invoiceId: state.saleData.sale.invoiceId,
  ecomsalesOrders: state.ecomSaleData.ecomsalesDetailsbyInvoiceId,
});

export default connect(mapStateToProps, {
  createSales,
  getSaleUsingInvoiceId,
  getEcomDetailsByinvoiceIds,
})(GenerateEcomBillForMerge);
