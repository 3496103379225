import React, { Component } from "react";
import { Table, Button, Space, Input, Tag, Row, Col } from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getInventory, getNegativeInventory } from "../../../redux/actions/purchaseAction";
import Highlighter from "react-highlight-words";
import TopBarProgress from "react-topbar-progress-indicator";
import Search from "../../../util/Search";
import { CSVLink } from "react-csv";
import axios from "axios";

const searchOptions = ["Barcode"]
const { Column } = Table;

class InventoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      options: [],
      searchValue: "",
      // testDat:[]
    };
  }
  componentDidMount() {
    this.props.getInventory(1);
    this.props.getNegativeInventory()
    // this.getTestData()
  }

//   getTestData = async()=>{
//     console.log("check function work")
// let data = await axios.get("http://34.207.65.154:5000/api/v1/getlatestInventoryReport")
// this.setState({testDat :data })
//   }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };

  cancelState = () => {
    this.setState({ searchValue: "" });
  }
  onChange = (event) => {
    this.setState({ searchValue: event });
  };

  render() {
    const { searchValue } = this.state;
    const { inventory, inventoryBysearch } = this.props
    const search = inventoryBysearch.inventoryDetails || []
    console.log("this.props.inventory", inventory);
    const headers = [
      { label: "Purchase Id", key: "purchaseId" },
      { label: "Barcode", key: "barcode" },
      { label: "Design No", key: "designNumber" },
      { label: "Bill No", key: "billNo" },
      { label: "MOQ", key: "MOQ" },
      { label: "Brand", key: "brand" },
      { label: "Size", key: "size" },
      { label: "Color", key: "color" },
      { label: "WSP", key: "wsp" },
    ];

    // const head2 = [
    //   { label: "Dealer Name", key: "dealerName" },
    //   { label: "Design Number", key: "designNumber" },
    //   { label: "Brand", key: "brand" },
    //   { label: "Markup", key: "markup" },
    //   { label: "Category", key: "category" },
    //   { label: "Group", key: "group " },
    //   { label: "Style", key: "style" },
    //   { label: "Season", key: "season" },
    //   { label: "Section", key: "section" },
    //   { label: "Size", key: "size" },
    //   { label: "Floor Name", key: "floorName" },
    //   { label: "Location", key: "location" },
    //   { label: "Age Group Name", key: "ageGroupName" },
    //   { label: "HSN Code", key: "HSNCode" },
    //   { label: "Purchase Price", key: "purchasePrice" },
    //   { label: "Color", key: "color" },
    //   { label: "Quantity", key: "quantity" },
    //   { label: "WSP", key: "wsp" },
    //   { label: "Discount", key: "discount" },
    //   { label: "Total Box", key: "totalBox" },
    //   { label: "Barcode", key: "barcode" },
    //   { label: "Purchase Id", key: "purchaseId" },
    //   { label: "Stock", key: "stock" },
    // ]

    return (
      <div>
        {/* {console.log("check negative", this.state.testDat)} */}
        <Row  className="mt-4">
          <Search title="Inventory" placeholder="Barcode" searchopt={searchOptions} cancelState={this.cancelState} changeFunction={this.onChange} />
        </Row>
        <div className="card mt-4 final-purchases-list">
          {this.props.progressBarStatus && <TopBarProgress />}
          <div className="card-body">
            <Table
              dataSource={searchValue ? search : inventory && inventory.inventoryDetails ? inventory.inventoryDetails : []}
              bordered="true"
              size="middle"
              pagination={{
                pageSize: 100,
                total: searchValue && search.length > 0 ?  search.length :  inventory?.totalCount ,
                onChange: (page) => {
                  this.props.getInventory(page);
                },
              }}
              scroll={{ y: 240 }}
            >
              <Column
                title="SRN"
                key="index"
                render={(value, item, index) => index + 1}
                width={60}
              />
              <Column
                title="Barcode"
                dataIndex="barcode"
                key="barcode"
                width={100}
                {...this.getColumnSearchProps("barcode")}
              />
              <Column
                title="Design No."
                dataIndex="designNumber"
                key="designNumber"
                width={150}
                {...this.getColumnSearchProps("designNumber")}
              />
              <Column
                title="Brand"
                dataIndex="brand"
                key="brand"
                {...this.getColumnSearchProps("brand")}
                width={120}
              />
               <Column
                title="Category"
                dataIndex="category"
                key="category"
                {...this.getColumnSearchProps("category")}
                width={120}
              />
                <Column
                title="Style"
                dataIndex="style"
                key="style"
                {...this.getColumnSearchProps("style")}
                width={120}
              />
              <Column
                title="Size"
                dataIndex="size"
                key="size"
                width={80}
                {...this.getColumnSearchProps("size")}
              />
              <Column
                title="Color"
                dataIndex="color"
                key="color"
                width={80}
                {...this.getColumnSearchProps("color")}
              />
              <Column title="WSP" dataIndex="wsp" key="wsp" width={80} />
              <Column
                title="Stock"
                dataIndex="stock"
                key="stock"
                width={90}
                render={(stock) => (
                  <>
                    {(() => {
                      if (stock <= 15) {
                        return (
                          <Tag color="volcano" key={stock}>
                            {stock}
                          </Tag>
                        );
                      } else {
                        return stock;
                      }
                    })()}
                  </>
                )}
              />
            </Table>
            {(() => {
          if (this.props?.negativeInventory?.data?.length > 0) {
            return (
              <Button
                type="primary"
                htmlType="submit"
                className="save-modal mt-4"
                block
              >
                <CSVLink
                  filename={`NegativeInventoryList.csv`}
                  data={this.props.negativeInventory.data}
                  headers={headers}
                >
                  Download Negative Inventory List
                </CSVLink>
              </Button>
            );
          }
        })()}
      
          {/* <Button
                type="primary"
                htmlType="submit"
                className="save-modal mt-4"
                block
              >
                <CSVLink
                  filename={`purchase.csv`}
                  data={this.state && this.state.testDat && this.state.testDat.data ? this.state.testDat.data : [] }
                  headers={head2}
                >
                  Download 
                </CSVLink>
              </Button> */}

            {/* <div className=" mt-4">
            <div className="col-md-12 ml-auto">
              <div className="grand-totall">
                <div className="title-wrap">
                  <h4 className="cart-bottom-title section-bg-gary-cart">
                    Purchase Bill Total
                  </h4>
                </div>
                <h5>
                  Total Bills{" "}
                  <span>
                    {inventory && inventory.count ? inventory.count : ""}
                  </span>
                </h5>
                <h5>
                  Total Boxes{" "}
                  <span>
                    {inventory && inventory.allBoxes ? inventory.allBoxes : ""}
                  </span>
                </h5>
                <h5>
                  Total Quantity{" "}
                  <span>
                    {inventory && inventory.allQuantity ? inventory.allQuantity : ""}
                  </span>
                </h5>
                <h4 className="grand-totall-title">
                  Total Amount{" "}
                  <span>
                    {inventory && inventory.allAmount ? parseInt(inventory.allAmount).toFixed() : ""}
                  </span>
                </h4>
              </div>
            </div>
          </div> */}
          </div>
        </div>
      </div>
    );
  }
}

InventoryList.propTypes = {
  getInventory: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  getNegativeInventory : PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  inventory: state.purchaseData.inventory,
  inventoryBysearch : state.purchaseData.inventoryBysearch,
  progressBarStatus: state.progressBarReducer.showProgressBar,
  negativeInventory : state.purchaseData.negativeInventory
});

export default connect(mapStateToProps, { getInventory, getNegativeInventory })(InventoryList);