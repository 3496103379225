import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import MetaTags from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { Link, Redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Button, Result, Tabs } from "antd";
import { getUser, updateUserEmail, updateUserPassword, updateUserAddress } from "../../redux/actions/userAction";
import { connect } from 'react-redux';

class UpdateProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // userDataDetails: null,
      userData: "",
      email: this.props.userDataDetails && this.props.userDataDetails.email ? this.props.userDataDetails.email : "",
      isEditingEmail: false,
      tabPosition: 'left',
      // id :  this.props.userDataDetails &&  this.props.userDataDetails._id ? this.props.userDataDetails._id : ""
      _id: "",
      oldPassword: "",
      newPassword: "",
      address: "",
      city: "",
      state: "",
      editemail: false,
      pincode: "",
      isEditingAddress: false,
    };
  }

  componentDidMount = async () => {
    this.updateTabPosition();
    window.addEventListener('resize', this.updateTabPosition);
    const { getUser } = this.props;
    const userId = localStorage.getItem("userId");
    if (userId) {
      await getUser(userId)
      let obj = this.props.userDataDetails
      console.log("obj", obj)
      this.setState({
        email: obj.email,
        _id: obj._id,
        address: obj.address,
        state: obj.state,
        city: obj.city,
        pincode: obj.pincode
      })

    } else {
      console.log("ended...............")
    }
    if (!localStorage.getItem("token")) {
      this.setState({
        redirectTo: "/login-register"
      });
    } else {
      const storedToken = localStorage.getItem("token");
      let decToken = "";
      if (storedToken) {
        decToken = jwt_decode(storedToken);
        console.log("userID", userId)

      }
      if (
        decToken.role !== "622a47d2a0c03827d4f07294" &&
        decToken.role !== "622a47e017844801d2c839a4"
      ) {
        this.setState({
          result: (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Link to="/dagaImpex">
                  <Button type="primary">Back</Button>
                </Link>
              }
            />
          )
        });
      }

    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateTabPosition);
  }

  handleEditClick = (event) => {
    console.log("event", event)
    if (event.target.name === "editEmail") {
      this.setState(
        { isEditingEmail: true }
      )
    }
    if (event.target.name === "editAddress") {
      this.setState(
        { isEditingAddress: true }
      )
    }

  };

  handleEmailChange = (event) => {
    this.setState({ email: event.target.value })
  };

  handleEmailSubmit = async () => {
    this.setState({ isEditingEmail: false })
    let emaiObj = { _id: this.state._id, email: this.state.email }

    this.props.updateUserEmail(emaiObj)
  };
  handlePasswordChange = (event) => {
    if (event.target.name === "oldPassword") {
      this.setState({ oldPassword: event.target.value })
    }
    else {
      this.setState({ newPassword: event.target.value })
    }
  };
  handlePasswordSubmit = async () => {
    let passwordObj = {
      _id: this.state._id,
      password: this.state.oldPassword,
      newPassword: this.state.newPassword
    }

    this.props.updateUserPassword(passwordObj)
  };

  handleAddressChange = (event) => {
    if (event.target.name === "address") {
      this.setState({ address: event.target.value })
    }
    else if (event.target.name === "city") {
      this.setState({ city: event.target.value })
    }
    else if (event.target.name === "state") {
      this.setState({ state: event.target.value })
    }
    else if (event.target.name === "pincode") {
      this.setState({ pincode: event.target.value })
    }
  };
  handleAddressSubmit = async () => {
    let addressObj = {
      _id: this.state._id,
      address: this.state.address,
      city: this.state.city,
      state: this.state.state,
      pincode: this.state.pincode
    }
    this.props.updateUserAddress(addressObj)
  };


  updateTabPosition = () => {
    if (window.innerWidth < 640) {
      this.setState({ tabPosition: 'top' });
    } else {
      this.setState({ tabPosition: 'left' });
    }
  };

  render() {
    const { redirectTo, result, email, isEditingEmail, isEditingAddress } = this.state;
    const { location, userDataDetails } = this.props;

    // if (redirectTo) {
    //   return <Redirect to={{ pathname: redirectTo }} />;
    // }

    const { tabPosition } = this.state;

    return (
      <Fragment>
        <MetaTags>
          <title>Daga Impex | My Account</title>
          <meta
            name="description"
            content="Compare page of Daga Impex react minimalist eCommerce template."
          />
        </MetaTags>

        <div className="container">
          <div>
            <Tabs
              className="p-3 py-4 rounded"
              style={{ backgroundColor: 'aliceblue' }}
              size="large"
              tabPosition={tabPosition}
              items={[
                {
                  label: 'Personal Details',
                  key: '1',
                  children: (
                    <>
                      <div className="myaccount-info-wrapper">
                        <div className="account-info-wrapper">
                          {/* <h5>My Account Information</h5> */}
                          <h3>Personal Details</h3>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 col-md-6 col-sm-12">
                            <div className="billing-info">
                              <h5 className="mt-2">Name</h5>
                              <input type="text" value={userDataDetails.name} />
                            </div>
                          </div>


                          <div className="col-lg-12 col-md-6 col-sm-12">
                            <div className="billing-info">
                              <h5 className="mt-4">Mobile Number</h5>
                              <input type="text" value={userDataDetails.mobileNumber} />
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-6 col-sm-12">
                            {isEditingEmail ? (

                              <div className="billing-info">
                                <h5 className="mt-4">Email Address</h5>
                                <div className="d-flex">
                                  <input style={{ backgroundColor: "#e8f0fe" }} type="text" name="email" value={this.state.email} onChange={this.handleEmailChange} />
                                  <button name="editEmail" className="btn btn-primary w-25 ml-2" onClick={this.handleEditClick} >Edit Email</button>
                                </div>
                              </div>
                            ) : (
                              <div className="billing-info">
                                <h5 className="mt-4">Email Address</h5>
                                <div className="d-flex">
                                  <input disabled type="text" name="email" value={this.state.email} onChange={this.handleEmailChange} />
                                  <button name="editEmail" className="btn btn-primary w-25 ml-2" onClick={this.handleEditClick} >Edit Email</button>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="billing-back-btn mt-4">
                          <div className="billing-btn">
                            <button
                              type="submit"
                              className="bg-success text-white border-0 w-25 py-2"
                              onClick={this.handleEmailSubmit}
                              >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  ),
                },
                {
                  label: 'Change Password',
                  key: '2',
                  children: (
                    <>
                      <div className="myaccount-info-wrapper">
                        <div className="account-info-wrapper">
                          <h3>Change Password</h3>
                        </div>
                        <div className="col-lg-12 col-md-6 col-sm-12 pl-0">
                          <div className="billing-info">
                            <h5 className="mt-2">Old Password</h5>
                            <input type="password" name="oldPassword" value={this.state.oldPassword} onChange={this.handlePasswordChange} />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-6 col-sm-12 pl-0">
                          <div className="billing-info">
                            <h5 className="mt-4">New Password</h5>
                            <input type="password" name="newPassword" value={this.state.newPassword} onChange={this.handlePasswordChange} />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-6 col-sm-12 pl-0">
                          <div className="billing-info">

                            <h5 className="mt-4" dependencies={["newPassword"]} rules={[
                              {
                                required: true,
                                message: "Please confirm your password!",
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (
                                    !value ||
                                    getFieldValue("newPassword") === value
                                  ) {
                                    return Promise.resolve();
                                  }

                                  return Promise.reject(
                                    new Error("Passwords do not match!")
                                  );
                                },
                              }),
                            ]}
                              hasFeedback
                            >Password Confirm</h5>
                            <input type="password" />
                          </div>
                        </div>
                        <div className="billing-back-btn mt-4">
                          <div className="billing-btn">
                            <button
                              type="submit"
                              className="bg-success text-white border-0 w-25 py-2"
                              onClick={this.handlePasswordSubmit}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  ),
                },
              ]}
            />
          </div>
        </div>


      </Fragment>
    );
  }
}

UpdateProfile.propTypes = {
  location: PropTypes.object,
  getUser: PropTypes.func.isRequired,
  updateUserEmail: PropTypes.func.isRequired,
  updateUserPassword: PropTypes.func.isRequired,
  updateUserAddress: PropTypes.func.isRequired

};

const mapStateToProps = state => ({
  errors: state.errorData.error,
  userDataDetails: state.userData.userDataDetails
});

export default connect(mapStateToProps, { getUser, updateUserEmail, updateUserPassword, updateUserAddress })(UpdateProfile);