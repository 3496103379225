import React, { Component } from "react";
import { Table, Button, Space, Input, Tag } from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { CSVLink } from "react-csv";
const { Column } = Table;
const data = [];

export default class NonMovingItemsReportList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      options: [],
    };
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  this.setState({
                    searchText: selectedKeys[0],
                    searchedColumn: dataIndex,
                  });
                }}
              >
                Filter
              </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };
  render() {
    const {reports}  =  this.props  
    const headers = [
      { label: "Brand", key: "brand" },
      { label: "Design Number", key: "designNumber" },
      { label: "Category", key: "category" },
      { label: "Style", key: "style" },
      { label: "Purchase Date", key: "created_at" },
      { label: "Last Sale Date", key: "updated_at" },
      { label: "Barcode", key: "barcode" },
      { label: "Total Quantity Sold", key: "totalSold" },
    ];
    console.log("#propspss", this.props.reports);
    return (
      <>
        <div className="card  final-purchases-list">
          <div className="card-body">
            <Table
              dataSource={reports && reports.length > 0 ? reports.reverse() : []}
              bordered="true"
              size="middle"
              pagination={false}
              scroll={{ y: 240 }}
            >
              <Column
                title="SRN"
                key="index"
                render={(value, item, index) => index + 1}
                width={60}
              />
              <Column title="Brand" dataIndex="brand" key="brand" width={120} />
              <Column title="Design Number" dataIndex="designNumber" key="designNumber" width={120} />
              <Column title="Category" dataIndex="category" key="category" width={120} />
              <Column
                title="Style"
                dataIndex="style"
                key="style"
                width={120}
              />
              {/* <Column
                title="Size"
                dataIndex="size"
                key="size"
                width={60}
              />
              <Column
                title="Color"
                dataIndex="color"
                key="color"
                width={60}
              /> */}
              <Column
                title="Purchase Date"
                dataIndex="created_at"
                key="created_at"
                width={120}
                render={(value, item, index) =>
                  moment(item.created_at).format("YYYY-MM-DD")
                }
              />
              <Column
                title="Last Sale Date"
                dataIndex="updated_at"
                key="updated_at"
                width={120}
                render={(value, item, index) =>
                  moment(item.updated_at).format("YYYY-MM-DD")
                }
              />
              <Column
                title="Barcode"
                dataIndex="barcode"
                key="barcode"
                width={80}
              />
              

             
              <Column
                title="Total Quantity Sold"
                dataIndex="totalSold"
                key="totalSold"
                width={120}
              />{" "}
            </Table>
          </div>
        </div>
        {(() => {
          if (this.props && this.props.reports &&  this.props.reports.length > 0) {
            return (
              <Button
                type="primary"
                htmlType="submit"
                className="save-modal mt-4"
                block
              >
                <CSVLink
                  filename={`${this.props.startDate} to ${this.props.endDate}.csv`}
                  data={this.props.reports}
                  headers={headers}
                >
                  Download CSV
                </CSVLink>
              </Button>
            );
          }
        })()}
      </>
    );
  }
}
