import React, { Component } from "react";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import { getDashboardDetails } from "../../../redux/actions/dashboardActions";
import {ShoppingCartOutlined, UserOutlined, SkinOutlined, UsergroupAddOutlined} from "@ant-design/icons"

class Cards extends Component {
  // componentDidMount() {
  //   this.props.getDashboardDetails();
  // }
  render() {
    const { totalCategory, totalDealer, totalBrand, totalUsers } =
      this.props.dashboardData;

    return (
      <Row gutter={[32, 16]} className="mt-4">
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <div className="card text-white bg-warning mb-3 widget-card">
            <div className="card-body">
              <Row gutter={[48, 0]}>
                <Col span={8} className="icons-widgets">
                  {/* <i className="fas fa-shopping-cart card-icon-cat"></i> */}
                  <ShoppingCartOutlined/>
                </Col>
                <Col span={16} className="media-body">
                  <span
                    style={{
                      letterSpacing: "2px",
                    }}
                  >
                    Categories
                  </span>
                  <h3>{totalCategory}</h3>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <div className="card text-white bg-info mb-3 widget-card">
            <div className="card-body">
              <Row gutter={[48, 0]}>
                <Col span={8} className="icons-widgets">
                  {/* <i className="fas fa-truck  card-icon-subcat"></i> */}
                  <UserOutlined/>
                </Col>
                <Col span={16} className="media-body">
                  <span
                    style={{
                      letterSpacing: "2px",
                    }}
                  >
                    Dealers
                  </span>
                  <h3>{totalDealer}</h3>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <div className="card text-white bg-danger mb-3 widget-card">
            <div className="card-body">
              <Row gutter={[48, 0]}>
                <Col span={8} className="icons-widgets">
                  {/* <i className="fas fa-tshirt  card-icon-prod"></i> */}
                  <SkinOutlined/>
                </Col>
                <Col span={16} className="media-body">
                  <span
                    style={{
                      letterSpacing: "2px",
                    }}
                  >
                    Brands
                  </span>
                  <h3>{totalBrand}</h3>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col lg={{ span: 12 }} xs={{ span: 24 }}>
          <div className="card text-white bg-secondary mb-3 widget-card">
            <div className="card-body">
              <Row gutter={[48, 0]}>
                <Col span={8} className="icons-widgets">
                  {/* <i className="fas fa-user-alt  card-icon-user"></i> */}
                  <UsergroupAddOutlined />
                </Col>
                <Col span={16} className="media-body">
                  <span
                    style={{
                      letterSpacing: "2px",
                    }}
                  >
                    Users
                  </span>
                  <h3 className="text-dark">{totalUsers}</h3>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = (state) => ({
  dashboardData: state.dashboardData.dashboardData,
});
export default connect(mapStateToProps, { getDashboardDetails })(Cards);
