// created by aaditya
import React, { Component } from "react";
import { Table, Modal } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { warehouseSaleListbybillId } from "../../../redux/actions/warehouseActions";

const { Column } = Table;
class ViewProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewProductsModalVisible: false,
      page: "",
      current: 1,
      search: "",
      options: [],
      searchValue: "",
      data: "",
      stateReceived: false,
    };
  }

  // to open prduvt view modal
  toggleViewProductsModal = () => {
    this.setState((prevState) => ({
      viewProductsModalVisible: !prevState.viewProductsModalVisible,
    }));
    if (!this.state.viewProductsModalVisible) {
      this.props.warehouseSaleListbybillId({ warehousesaleId: this.props.id });
    }
  };

  render() {
    const { viewProductsModalVisible } = this.state;
    return (
      <>
        <EyeOutlined
          style={{ color: "black" }}
          onClick={this.toggleViewProductsModal}
        />

        <Modal
          title="View Products"
          visible={viewProductsModalVisible}
          onCancel={this.toggleViewProductsModal}
          footer={null}
          width={1500}
        >
          {console.log("this.pops",this.props)}
          <Table
            dataSource={this.props.billIdList?.details}
            rowClassName={(record) =>
              record._id === this.state.selectedRowId ? "selected-row" : ""
            }
            bordered="true"
            size="middle"
            pagination={false}
            scroll={{ y: 500 }}
          >
            <Column
              title="SRN"
              key="index"
              render={(value, item, index) => index + 1}
              width={60}
            />

            <Column
              title="Barcode"
              dataIndex="barcode"
              key="barcode"
              width={100}
              // {...this.getColumnSearchProps("barcode")}
            />
            <Column
              title="Design No."
              dataIndex="designNumber"
              // {...this.getColumnSearchProps("designNumber")}
              key="designNumber"
              // render={}
              width={115}
            />
            <Column
              title="Brand"
              // {...this.getColumnSearchProps("brand")}
              dataIndex="brand"
              key="brand"
              // render={}
              width={120}
            />
            <Column
              title="Size"
              dataIndex="size"
              key="size"
              width={80}
              // render={}
            />
            <Column
              title="Color"
              dataIndex="color"
              key="color"
              width={90}
              // render={}
            />
            <Column
              title="Qnty"
              dataIndex="quantity"
              key="quantity"
              width={60}
            />

            <Column
              title="Price"
              dataIndex="purchasePrice"
              key="purchasePrice"
              // render={}
              width={90}
            />
              <Column
              title="WSP"
              dataIndex="wsp"
              key="wsp"
              // render={}
              width={90}
            />
          </Table>

          {/* <div className="mt-4">
            <div className="col-md-12 ml-auto">
              <div
                className="grand-totall"
                style={{ padding: "18px 17px 4px" }}
              >
                <div className="title-wrap">
                  <h4 className="cart-bottom-title section-bg-gary-cart">
                    Sales Total
                  </h4>
                </div>
                <h5>
                  Total Counts{" "}
                  <span>
                    {this.props.billIdList?.details &&
                    this.props.billIdList?.details.length > 0
                      ? this.props.billIdList?.details.length
                      : 0}
                  </span>
                </h5>
                <h5>
                  Total Boxes{" "}
                  <span>
                    {this.props.billIdList?.whSalebilldata?.totalBoxes}
                  </span>
                </h5>
                <h5>
                  Total Quantity{" "}
                  <span>
                    {this.props.billIdList?.whSalebilldata?.quantity}
                  </span>
                </h5>
                <h4 className="grand-totall-title">
                  Total Amount{" "}
                  <span>
                    {Number(
                      this.props.billIdList?.whSalebilldata?.totalAmount
                    ).toFixed()}
                  </span>
                </h4>
              </div>
            </div>
          </div> */}
        </Modal>
      </>
    );
  }
}

ViewProducts.propTypes = {
  warehouseSaleListbybillId: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  billIdList: state.warehouseData.warehouseSaleListbybillId,
});

export default withRouter(
  connect(mapStateToProps, { warehouseSaleListbybillId })(ViewProducts)
);
