import axios from "axios";
import {
  GET_CUSTOMERS,
  GET_ERRORS,
  GET_SALES,
  ACTIVE_AND_INACTIVE_CUSTOMERS,
} from "./type";
import { message } from "antd";
import { backend_uri_server, backend_uri_local } from "../../util/constants";

export const getCustomers = (roleName) => async (dispatch) => {
  let response = await axios.get(`${backend_uri_server}/api/v1/user`, {
    params: { roleName },
  });

  if (roleName == "STORE_USER" || roleName == "USER") {
    try {
      dispatch({
        type: GET_CUSTOMERS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    }
  } else if (roleName == "SALES" || roleName == "PURCHASER") {
    try {
      dispatch({
        type: GET_SALES,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    }
  }
};

//for getting Active and Inactive Customer List
export const getActiveandInactiveCustomer =
  () => async (dispatch, getState) => {
    let response = await axios.get(
      `${backend_uri_server}/api/v1/activeCustomerList`
    );
    try {
      dispatch({
        type: ACTIVE_AND_INACTIVE_CUSTOMERS,
        payload: response.data,
      });
      return response;
    } catch (error) {
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    }
  };
