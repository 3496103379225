import React, { Component } from "react";
import { Row, Col, Tooltip, Form, Select, DatePicker, Button } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { getSalesReport } from "../../../../redux/actions/reportsActions";
import DateWiseSearch from "../../../../util/DateWiseSearch";
import TopBarProgress from "react-topbar-progress-indicator";
import SalesReportList from "./SalesReportList";
import BackButton from "../../Report/Button";
const dateFormat = "YYYY-MM-DD";

class SalesReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate:  moment(new Date()).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD") + " " + moment().format("HH:mm:ss"),
    };
  }
  componentDidMount = async () => {
    let startDate = moment(new Date()).format("YYYY-MM-DD");
    let endDate = moment(new Date()).format("YYYY-MM-DD");
    let today = moment(new Date()).format("YYYY-MM-DD");
    let reportObj = {};
    if (today === endDate) {
     reportObj = {
      startDate: startDate,
      endDate: endDate + " " + moment().format("HH:mm:ss"),
      limit: 1,
      perPage: 30,
      type: "perPage",
    }}
    else{
      const dateMoment = moment(endDate, "YYYY-MM-DD");
      const endTime = dateMoment.endOf("day").format("HH:mm:ss");
       const endDateWithEndTime = `${endDate} ${endTime}`;
      reportObj = {
        startDate: startDate,
        endDate: endDateWithEndTime,
        limit: 1,
        perPage: 30,
        type: "perPage",
      }
    }
    const initialReport = await this.props.getSalesReport(reportObj);
    
      console.log("Initial report data:", initialReport);

      const reportObjAll = {
        startDate: reportObj?.startDate,
        endDate: reportObj?.endDate,
        limit: 1,
        perPage: initialReport?.count,
        type: "all",
      };

      // await this.props.getSalesReport(reportObjAll);
      this.setState({reportAllObject : reportObjAll})
  };

  render() {
    const setFun = (startDate, endDate) => {
      this.state.startDate = startDate;
      this.state.endDate = endDate;
    };

    const setReportObj = (reportObjAll) => {
      this.setState({reportAllObject : reportObjAll})
    };

    return (
      <div className="subCategory-dashboard">
        {this.props.progressBarStatus && <TopBarProgress />}
        <div className="container-fluid category-list">
          {/* ==============Search Bar and Date Filter================= */}
          <Row className="title-row">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row gutter={24}>
                <Col span={12}>
                  <div className="page-header">Sales Report</div>
                </Col>
                <Col span={12}>
                  <BackButton />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <DateWiseSearch keyId={"Sales Report"} setFun={setFun} setReportObj={setReportObj} />
            </Col>
          </Row>
          <SalesReportList
            reports={this.props.reports}
            allreports={this.props.allreports}
            reportObj={{
              startDate: this.state.startDate,
              endDate: this.state.endDate,
            }}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            getSalesReport={this.props.getSalesReport}
            reportObjAll = {this.state.reportAllObject}
          />
        </div>
      </div>
    );
  }
}
SalesReport.propTypes = {
  getSalesReport: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  reports: state.reportData.salesAll,
  allreports: state.reportData.allsales,
  progressBarStatus: state.progressBarReducer.showProgressBar,
});

export default connect(mapStateToProps, {
  getSalesReport,
})(SalesReport);