import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Modal, Button, Form, Upload, Tooltip, message } from "antd";
import { PlusCircleOutlined, PlusOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import { Spin } from 'antd';
import { addBrandImage, deleteBrandImage, getBrands,  } from "../../../../redux/actions/brandAction";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class AddBrandImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      name: "",
      previewVisible: false,
      previewImage: "",
      previewTitle: "",
      fileList: [],
      image: "",
      errors: {},
      spinStatus: false
    };
    // this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleModalCancel = () => {
    this.setState({ visible: false });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errors !== this.props.errors) {
      //Perform some operation here

      this.setState({ errors: this.props.errors });
      message.error(this.props.errors.message);
    }
  }

  // onChange(event) {
  //   this.setState({ [event.target.name]: event.target.value });
  // }

  // onSubmit(event) {
  //   // event.preventDefault();
  //   this.setState({ loading: true });
  //   setTimeout(() => {
  //     this.setState({ loading: false, visible: false });
  //   }, 1000);
  //   if (this.state?.fileList[0]?.thumbUrl) {
  //     this.props.addBrandImage(
  //       this.props.data._id,
  //       this.state.fileList[0].thumbUrl
  //     );
  //   }
  //   console.log("#submot this.state.fileList : ", this.state.fileList);
  //   //   const dealerObj = {
  //   //     name: this.state.name,
  //   //     gst: this.state.gst,
  //   //     alias: this.state.alias,
  //   //   };
  //   //   this.props.addDealer(dealerObj);

  //   setTimeout(() => {
  //     this.setState({ fileList: [] });
  //   }, 1000);
  // }
  // onSubmit(event) {
  //   // event.preventDefault();
  //   this.setState({ loading: true });
  //   // setTimeout(() => {
  //   //   this.setState({ loading: false, visible: false });
  //   // }, 1000);
  //   if (this.state?.fileList[0]) {
  //     let reponse = this.props.addBrandImage(
  //       this.props.data._id,
  //       this.state.fileList[0].originFileObj
  //     );


  //     let data = reponse.then(obj => {
  //       if (obj.status == 200) {
  //         message.success(" Imaged added Successfully");
  //         this.setState({ loading: false, visible: false, fileList: [] });
  //       }
  //     }).catch(error => {
  //       message.error("Something went wrong try again!")
  //       this.setState({ loading: false, visible: false, fileList: [] });
  //     })




  //   }
  //   // Rest of the code...
  // }

  onSubmit(event) {
    // event.preventDefault();
    this.setState({ loading: true });
    // setTimeout(() => {
    //   this.setState({ loading: false, visible: false });
    // }, 1000);
    if (this.state?.fileList[0]) {
      let reponse = this.props.addBrandImage(
        this.props.data._id,
        this.state.fileList[0].originFileObj
      );


      let data = reponse.then(obj => {
        if (obj.status == 200) {
          this.props.getBrands();
          message.success(" Imaged added Successfully");
          this.setState({ loading: false, visible: false, fileList: [] });
        }
      }).catch(error => {
        message.error("Something went wrong try again!")
        this.setState({ loading: false, visible: false, fileList: [] });
      })
   

    }
  
    // Rest of the code...
  }
  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  handleDefaultPreview = () => {
    console.log("check new function for preview")
    this.setState({
      previewImage: this.props.data.image,
      previewVisible: true,
      previewTitle: this.props.data.name,
    });
  }

  handleDelete = async () => {
    this.setState({ spinStatus: true })
    const { data } = this.props
    let response = await this.props.deleteBrandImage({ imageName: data.image, _id : data._id})
    if (response.status == 200) {
      this.props.getBrands()
      this.setState({ visible: false, spinStatus : false });
    }
  }

  handleChange = ({ fileList }) => this.setState({ fileList: [...fileList] });


  beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };


  render() {
    const { visible, loading } = this.state;
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload Images</div>
      </div>
    );
    return (
      <Row>
        <Col span={24}>
          <div className="btn">
            <Tooltip title="Add Image">
              <PlusCircleOutlined onClick={this.showModal} />
            </Tooltip>
          </div>
          <Modal
            visible={visible}
            onOk={this.handleOk}
            onCancel={this.handleModalCancel}
            title="Add Images"
            style={{ top: 50 }}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose={true}
          >

            {
              this.state.spinStatus ? <>
                <div className="example-brand-image-spin">
                  <Spin />
                </div>
              </> : <>
            <Form
              name="add-Images"
              className="add-Images"
              onFinish={this.onSubmit}
              initialValues={{
                remember: true,
              }}
            >
              <Form.Item
                name="upload"
                valuePropName="fileList"
              // getValueFromEvent={normFile}
              >
                
                   {
                  this.props.data.image ? <>
                    <div className="brand-image-show">
                      <img
                        alt="example"
                        style={{ width: "50%" }}
                        src={this.props.data.image}
                      />
                   <div className="brand-image-show-button-1">
                        <div onClick={() => this.handleDefaultPreview()} style={{ cursor: "pointer" }}>
                          <EyeOutlined className="brand-image-show-icon" />
                          <p>SHOW</p>
                        </div>
                        <div onClick={() => this.handleDelete()} style={{ cursor: "pointer" }}>
                          <DeleteOutlined className="brand-image-show-icon" />
                          <p>DELETE</p>
                        </div>
                      </div>
                    </div>
                  </> : <>
                    <Upload
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      accept=".png, .jpg"
                      listType="picture-card"
                      fileList={fileList}

                      onPreview={this.handlePreview}
                      onChange={this.handleChange}
                      beforeUpload={this.beforeUpload}
                    >
                      {fileList.length === 1 ? null : uploadButton}
                    </Upload>
                  </>
                }
                  
                
                {/* {
                  this.props.data.image ? <>
                    <div className="brand-image-show">
                      <img
                        alt="example"
                        style={{ width: "50%" }}
                        src={this.props.data.image}
                      />
                      <div className="brand-image-show-button">
                        <div onClick={() => this.handleDefaultPreview()} style={{ cursor: "pointer" }}>
                          <EyeOutlined className="brand-image-show-icon" />
                          <p>SHOW</p>
                        </div>
                        <div onClick={() => this.handleDelete()} style={{ cursor: "pointer" }}>
                          <DeleteOutlined className="brand-image-show-icon" />
                          <p>DELETE</p>
                        </div>
                      </div>
                    </div>
                  </> : <>
                    <Upload
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      accept=".png, .jpg"
                      listType="picture-card"
                      fileList={fileList}

                      onPreview={this.handlePreview}
                      onChange={this.handleChange}
                      beforeUpload={this.beforeUpload}
                    >
                      {fileList.length === 1 ? null : uploadButton}
                    </Upload>
                  </>
                } */}
                <Modal
                  visible={previewVisible}
                  title={previewTitle}
                  footer={null}
                  onCancel={this.handleCancel}
                >
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewImage}
                  />
                </Modal>
              </Form.Item>

              <Form.Item className="float-right">
                <Button
                  className="close-modal mr-3"
                  onClick={this.handleModalCancel}
                >
                  Close
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="ok-modal"
                  loading={loading}
                >
                  Save
                </Button>
              </Form.Item>
            </Form>
            </>}
        </Modal>
      </Col>
      </Row >
    );
  }
}

AddBrandImages.propTypes = {
  addBrandImage: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  deleteBrandImage: PropTypes.object.isRequired,
  getBrands: PropTypes.object.isRequired,
};

export default connect(null, { addBrandImage, deleteBrandImage, getBrands })(AddBrandImages);