import React, { useState, useRef } from 'react';
import { Table, Input, Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import TopBarProgress from "react-topbar-progress-indicator";
import { useSelector } from 'react-redux';

const { Column } = Table;

const BestSellingItemReportList = ({ reportData, handlePageChange }) => {
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchText, setSearchText] = useState('');
  const searchInput = useRef(null);
  const progressBarStatus = useSelector((state) => state.progressBarReducer.showProgressBar)

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    setSearchText(selectedKeys[0] || '');
    setSearchedColumn(dataIndex);
    confirm();
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText('');
    setSearchedColumn('');
    confirm();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  return (
    <div className="card final-purchases-list mt-4 mb-4">
      {progressBarStatus && <TopBarProgress />}
      <div className="card-body">
        <Table
          dataSource={reportData && reportData.length > 0 ? reportData : []}
          bordered="true"
          size="middle"
          pagination={false}
          scroll={{ y: 240 }}
        >
          <Column
            title="SRN"
            key="index"
            render={(value, item, index) => index + 1}
            width={60}
          />
          <Column
            title="Dealer Name"
            dataIndex="dealerName"
            key="dealerName"
            width={160}
          // {...this.getColumnSearchProps("dealerName")}
          />
          <Column
            title="Brand"
            dataIndex="brand"
            key="brand"
            width={140}
          // {...this.getColumnSearchProps("brand")}
          />
          <Column
            title="Design No."
            dataIndex="designNumber"
            key="designNumber"
            width={140}
          // {...this.getColumnSearchProps("designNumber")}
          />
          <Column title="Size" dataIndex="size" key="size" width={120} />
          <Column title="Total Quantity" dataIndex="sumOfQuantity" key="sumOfQuantity" width={120} />
          <Column title="Total Amount" dataIndex="sumOfAmount" key="sumOfAmount" width={120} />
        </Table>
      </div>
    </div>
  );
};

export default BestSellingItemReportList;