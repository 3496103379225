import React, { useEffect, useState } from 'react';
import BestSellingItemReportList from './BestSellingItemReportList';
import BackButton from "../../Report/Button";
import TopBarProgress from "react-topbar-progress-indicator";
import { Row, Col, Tooltip, Form, Select, DatePicker, Button, Checkbox } from "antd";
import { getbestSellingReports } from '../../../../redux/actions/reportsActions';
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';

const dateFormat = "YYYY-MM-DD";
const { Option } = Select;

const BestSellingItemReport = () => {
  const [formData, setFormData] = useState({
    startDate: moment().format(dateFormat), 
    endDate: moment().format(dateFormat) + " " + moment().format("HH:mm:ss"), 
    searchType: "",
    type: "",
  });
  const [formRef] = Form.useForm();

  const dispatch = useDispatch();
const reportData = useSelector((state)=>  state.reportData.getBestSellingList)
  const handleStartDate = (date, dateString) => {
    // console.log("dateString", dateString);

    setFormData((prevData) => ({
      ...prevData,
      startDate: dateString,
    }));
  };

  
   useEffect(()=> {   
    dispatch(getbestSellingReports("reset"));
   }, [dispatch])

   const handleReset = () => {
    dispatch(getbestSellingReports("reset"));
    formRef.resetFields();
    setFormData((prevData) => ({
      ...prevData,
      startDate: moment().format(dateFormat), 
    endDate: moment().format(dateFormat) + " " + moment().format("HH:mm:ss"), 
    searchType: "",
    type: "",
    }));
   }

  const handleEndDate = (date, dateString) => {
    dispatch(getbestSellingReports("reset"));
    setFormData((prevData) => ({
      ...prevData,
      endDate: dateString,
    }));
  };

  const handleSelectChange  = (value) => {
    
    setFormData((prevData) => ({
      ...prevData,
      searchType: value,
    }));
  };

  const onCheckBoxChange = (e) => {
    
    setFormData((prevData) => ({
      ...prevData,
      type: e.target.checked ? "Brand" : "",
    }));
  };

  const handleSearch = () => {
    const obj = {
      searchType: formData.searchType,
      type: formData.type,
      startDate: formData.startDate,
      endDate: formData.endDate,
    };
    dispatch(getbestSellingReports(obj));
  };

  // const handlePageChange = (pageNo) => {  
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     pageSize: 25,
  //     pageNo: pageNo
  //   }));
  //   const obj = {
  //     searchType: formData.searchType,
  //     type: formData.type,
  //     startDate: formData.startDate,
  //     endDate: formData.endDate,
  //     pageSize: 25,
  //     pageNo: pageNo
  //   };
  //   dispatch(getbestSellingReports(obj));
  // }

  return (
    <div className="subCategory-dashboard">
      {/* {progressBarStatus && <TopBarProgress />} */}
      <div className="container-fluid category-list">
        <Row className="title-row">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Row gutter={24}>
              <Col span={12}>
                <div className="page-header">Best Selling Item Report</div>
              </Col>
              <Col span={12}>
                <BackButton />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form
            form={formRef}
              name="add-Purchase"
              className="add-Purchase"
              onFinish={handleSearch}
              initialValues={{ remember: true }}
            >
              <Row gutter={[16, 0]} className="mt-4">
                <Col xs={24} sm={7} md={7} lg={4} xl={4}>
                  <Tooltip title="Search Type">
                    <Form.Item
                    name="SearchType"
                      rules={[
                        {
                          required: true,
                          message: "Please Select the Search Type!",
                        },
                      ]}
                    >
                      <Select
                      name="SearchType"
                        onSelect={handleSelectChange}
                        placeholder="Select Search Type"
                      >
                        <Option value="amount">Amount</Option>
                        <Option value="quantity">Quantity</Option>
                      </Select>
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col xs={24} sm={7} md={7} lg={4} xl={4}>
                  <Tooltip title="Select Start Date">
                    <Form.Item
                      name="startDate"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Start Date!",
                        },
                      ]}
                      initialValue={moment()}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        onChange={handleStartDate}
                        placeholder="Select Start Date"
                        format={dateFormat}
                      />
                    </Form.Item>
                  </Tooltip>
                </Col>
                <Col xs={24} sm={7} md={7} lg={4} xl={4}>
                  <Tooltip title="Select End Date">
                    <Form.Item
                      name="endDate"
                      rules={[
                        {
                          required: true,
                          message: "Please Select End Date!",
                        },
                      ]}
                      initialValue={moment()}
                    >
                      <DatePicker
                        disabledDate={(current) => current && current > Date.now()}
                        style={{ width: "100%" }}
                        onChange={handleEndDate}
                        placeholder="Select End Date"
                        format={dateFormat}
                      />
                    </Form.Item>
                  </Tooltip>
                </Col>
                {/* <Col xs={24} sm={7} md={7} lg={4} xl={3}>
                
                  <Tooltip title="Top Brand">
                    <Form.Item name="Brand">
                    <Button  block>
                      <Checkbox onChange={onCheckBoxChange}>Top Brand</Checkbox>
                  </Button>
                    </Form.Item>
                  
                  </Tooltip>
                </Col> */}
                <Col xs={24} sm={7} md={7} lg={4} xl={2}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="close-modal"
                      block
                    >
                      Search
                    </Button>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={7} md={7} lg={4} xl={2}>
                  <Form.Item>
                    <Button
                    type="primary" block
                    onClick={handleReset}
                    >
                      Reset
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        {/* {console.log("reportData-123", reportData)} */}
        
        <BestSellingItemReportList
          reportData={reportData}
        
        />
      </div>
    </div>
  );
};

export default BestSellingItemReport;