import React, { Component } from "react";
import { Table, Row, Col, Tooltip, Popconfirm } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
const { Column } = Table;

export default class AddedListReturNewnDefective extends Component {
  constructor(props) {
    super(props);
    this.state = {
      returnData: [],
    };
  }
  shouldComponentUpdate(nextProps, prevState) {
    if (
      nextProps.data != undefined &&
      nextProps.data.length != prevState.returnData.length
    ) {
      this.setState({
        returnData: nextProps.data,
      });
    }
    return true;
  }
  onDeleteClick = (id) => {
    this.props.deleteSale(id);
    this.props.deleteDefSale(id)
  };
  onRemoveQnty = (obj) => {
    this.props.onRemoveQnty(obj);
  };
  onAddQnty = (obj) => {
    this.props.onAddQnty(obj);
  };
  onDeleteClick2 = (_id) => {
    this.props.deletesaleReturnDefected(_id);
  };

  onEditClick = (obj) => {
    this.props.editDefectiveReturn(obj)
    // this.props.deleteSale(obj);
  }

  render() {
    let data;
    return (
      <div className="purchase-list mt-3">
        <Table
          dataSource={this.props.data}
          bordered="true"
          size="middle"
          pagination={false}
          scroll={{ y: 240 }}
        >
          <Column
            title="SRN"
            key="index"
            render={(value, item, index) => index + 1}
            width={60}
          />
          <Column
            title="Name"
            dataIndex="customerName"
            key="customerName"
            width={110}
          />
          <Column
            title="Brand"
            dataIndex="brand"
            key="brand"
            width={110}
          />
          <Column
            title="Qnty"
            dataIndex="quantity"
            key="quantity"
            width={100}

            onClick={this.props.quantity}
          />
          <Column
            title="Amount"
            dataIndex="amount"
            key="amount"
            width={100}
          />
          <Column
            title="Total Amount"
            dataIndex="netamount"
            key="amount"
            width={100}
            render={(value, item, index) => Number(item.quantity) * Number(item.amount).toFixed()}

          />

          <Column
            title="Action"
            key="action"
            width={75}
            render={(text, record, index) => (
              <Row gutter={[8, 0]}>
                {(() => {
                  data = JSON.parse(JSON.stringify(text));
                  data.index = index;
                  data.text = text
                })()}

                {
                  this.props.title == "update" ? <>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Tooltip title="Edit">
                        <a type="button" onClick={() => this.onEditClick(text)}>
                          <EditOutlined />
                        </a>
                      </Tooltip>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Popconfirm
                        title="Are you sure to delete this Record?"
                        onConfirm={() => this.onDeleteClick(text)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Tooltip title="Delete Record">
                          <DeleteOutlined />
                        </Tooltip>
                      </Popconfirm>
                    </Col>
                  </> : <>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      {(() => {
                        if (text.id) {
                          return (
                            <Popconfirm
                              title="Are you sure to delete this Record?"
                              onConfirm={() => this.onDeleteClick(text)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Tooltip title="Delete Record">
                                <DeleteOutlined />
                              </Tooltip>
                            </Popconfirm>
                          );

                        } else {
                          return (
                            <Popconfirm
                              title="Are you sure to delete this Record?"
                              onConfirm={() => this.onDeleteClick2(text)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Tooltip title="Delete Record">
                                <DeleteOutlined />
                              </Tooltip>
                            </Popconfirm>
                          );
                        }
                      })()}
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Tooltip title="Add Qnty">
                        <a type="button" onClick={() => this.onEditClick(text)}>
                          <EditOutlined />
                        </a>
                      </Tooltip>
                    </Col>
                  </>
                }
              </Row>
            )}
          />
        </Table>
      </div>
    );
  }
}
