import React, { Component } from "react";
import { Table, Popconfirm, Tooltip, Row, Col } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import UpdatePurchase from "../../UpdatePurchase";
const { Column } = Table;

export default class ShowupdatePurchase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      purchaseData: [],
    };
  }


 
  onDeleteClick = (id) => {
    this.props.deletePurchase(id);
  };
  updateRecord = (record) => {
    this.props.updatePurchase(record);
  };
  render() {
    const {roleId} = this.props
    return (
      <div className="purchase-list">
        <Table
          dataSource={this.props.myPurchaseData}
          // dataSource={this.props.myPurchaseData}
          bordered="true"
          size="middle"
          pagination={false}
          scroll={{ y: 540 }}
        >
          {" "}
          <Column
              title="SRN"
              key="index"
              render={(value, item, index) => index + 1}
              width={60}
            />
            <Column
              title="Dealer Name"
              dataIndex="dealerName"
              key="dealerName"
              width={140}
            />

            <Column
              title="Barcode"
              dataIndex="barcode"
              key="barcode"
              width={100}
            />
            <Column
              title="Design No."
              dataIndex="designNumber"
              key="designNumber"
              width={150}
            />
            <Column
              title="Brand"
              dataIndex="brand"
              key="brand"
              width={120}
            />
            <Column
              title="Size"
              dataIndex="size"
              key="size"
              width={80}
            />
            <Column title="Color" dataIndex="color" key="color" width={80} />
            <Column title="MOQ" dataIndex="MOQ" key="MOQ" width={60} />
            <Column
              title="Qnty"
              dataIndex="quantity"
              key="quantity"
              width={60}
            />
            <Column
              title="Price"
              dataIndex="purchasePrice"
              key="purchasePrice"
              width={90}
            />
            <Column title="WSP" dataIndex="wsp" key="wsp" width={80} />
          <Column
            title="Action"
            key="action"
            width={80}
            render={(text, record) => (
              console.log("text ", text),
              console.log("record ", record),
              (
                <Row gutter={[8, 0]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <UpdatePurchase updateData={text}  updateObj={this.props.updateObj} gstNo={this.props.gstNo}
                  roleId={roleId}
                  />
                  </Col>
                </Row>
              )
            )}
          />
        </Table>
      </div>
    );
  }
}
