import React, { Component } from "react";
import {
  Table,
  Popconfirm,
  Tooltip,
  Pagination,
  Button,
  Space,
  Input,
  Row,
  Col,
  Select,
  Form,
  Divider,
  Radio,
  Checkbox,
  Alert,
} from "antd";
import {
  DeleteOutlined,
  FileDoneOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getSaleOrderBills,
  getSaleUsingInvoiceId,
  getSaleOrderBillsBydate,
  salesBillByCustomerId,
} from "../../../../redux/actions/saleAction";
import {
  getCustomers,
  getActiveandInactiveCustomer,
} from "../../../../redux/actions/customerAction";
import Highlighter from "react-highlight-words";
import debounce from "lodash.debounce";
// import DateWiseSearch from "../../../util/DateWiseSearch.js";
import TopBarProgress from "react-topbar-progress-indicator";
import moment from "moment";
import GenerateBillForMerge from "./GenerateBillForMerge";
import VerifySalesBillProduct from "./VerifySalesBillProduct";
import MergeBillWhatsapp from "./MergeBillWhatsapp";
import MB_AgentWhatsapp from "./MB_AgentWhatsapp";
const { Column } = Table;
const { Option } = Select;

class MergeBill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      options: [],
      customerId: "",
      selectedRows: [],
      selectedRowKeys: [],
      apiData: [],
      invoiceIds: [],
    };
    this.debouncedGetData = debounce(this.getData, 500);

    // this.rowSelection = {
    //     type: 'checkbox',
    //     onChange: this.onRowSelectionChange,
    //   };
  }

  componentDidUpdate = (preveProp, preState) => {
    if (preveProp.salesDataByCustomerId != this.props.salesDataByCustomerId) {
      const dataWithCheckbox = this.props?.salesDataByCustomerId?.map(
        (item) => {
          let findData = this.state.invoiceIds.find((obj) => {
            return obj == item.invoiceId;
          });
          if (findData) {
            return {
              ...item,
              checkboxStatus: true, // Initial checkbox status
            };
          } else {
            return {
              ...item,
              checkboxStatus: false, // Initial checkbox status
            };
          }
        }
      );
      this.setState({ apiData: dataWithCheckbox });
    }

    if (preState.apiData != this.state.apiData) {
      let data = this.state.apiData;
      // let data = this.checkExistingCheck()
      this.setState({ apiData: data });
      // const dataWithCheckbox = this.state?.apiData.map(item => {return item});
      // this.setState({ apiData: dataWithCheckbox });
    }
  };

  handleCheckboxChange = (e, record) => {
    const newData = this.state.apiData.map((item) => {
      if (item.invoiceId == record.invoiceId) {
        return {
          ...item,
          checkboxStatus: !item.checkboxStatus,
        };
      } else {
        return item;
      }
    });
    this.setState({ apiData: newData });
    if (e.target.checked) {
      this.setState({ invoiceIds: [record, ...this.state.invoiceIds] });
    } else {
      let id = record._id;
      let selectedInvoiceId = this.state.invoiceIds.filter((obj) => {
        if (obj._id != id) return obj;
      });
      this.setState({ invoiceIds: selectedInvoiceId });
    }
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };
  generateBill = async (text) => {
    await this.props.getSaleUsingInvoiceId(text.invoiceId);
    this.props.history.push({
      pathname: "/dagaImpex/addSales",
      state: { id: text._id, saleObj: [text, this.props.salesOrders] },
    });
  };
  listData = () => {
    const { salesorderBydaterange } = this.props;
    const value =
      salesorderBydaterange && salesorderBydaterange.length > 0
        ? salesorderBydaterange[0]
        : [];
    if (value.message == "Empty") {
      return [];
    } else if (salesorderBydaterange.data) {
      return salesorderBydaterange.data;
    } else {
      return this.props.salesOrderBills;
    }
  };
  handleChangeCustomer = (key, value) => {
    this.setState({ customerId: value.value });
    // this.debouncedGetData("customer");
  };

  componentDidMount() {
    this.props.getActiveandInactiveCustomer();
  }

  getData = (name) => {
    if (name == "customer") this.props.getActiveandInactiveCustomer();
  };

  showDataOnList = () => {
    this.setState({invoiceIds:[]})
    let obj = {
      customerId: this.state.customerId,
      startDate: moment(new Date()).format("YYYY-MM-DD"),
      endDate:
        moment(new Date()).format("YYYY-MM-DD") +
        " " +
        moment().format("HH:mm:ss"),
    };
    this.props.salesBillByCustomerId(obj);
  };

  render() {
    const roleName = localStorage.getItem("roleName");
    let customerOptions = this.props.customers?.data?.map((customer) => {
      return (
        <Option
          name={[
            customer.transport,
            customer.agentId,
            customer.grAmount,
            customer,
          ]}
          value={customer._id}
        >
          {customer.name}
        </Option>
      );
    });
    return (
      <div className="subCategory-dashboard">
        {this.props.progressBarStatus && <TopBarProgress />}
        <div className="container-fluid category-list">
          <Row className="title-row">
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <div className="page-header">Merge Sales Bills</div>
            </Col>
          </Row>
          <Form
            // ref={this.formRef}
            name="mergebill"
            className="add-Purchase"
            onFinish={this.showDataOnList}
            // initialValues={{ remember: true }}
          >
            <Row className="mt-4">
              <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                {/* <DateWiseSearch  keyId={"Sales Orders"}/> */}
                <Tooltip title="Select Customer">
                  <Form.Item
                    name="customerName"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Customer Name !",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Customer"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      onChange={this.handleChangeCustomer}
                      // onClick={() => this.debouncedGetData("customer")}
                      // onInputKeyDown={() => this.debouncedGetData("customer")}
                    >
                      {customerOptions}
                    </Select>
                  </Form.Item>
                </Tooltip>
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4} className="ml-4">
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="close-modal"
                    block
                  >
                    Search
                  </Button>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={4} lg={4} xl={4} className="ml-4">
                <Form.Item>
                  <Button
                    type="primary"
                    block
                    onClick={() => window.location.reload(true)}
                  >
                    Reset
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <div className="card ">
            <div className="card-body">
              <Table
                dataSource={this.state?.apiData || []}
                bordered="true"
                size="middle"
                pagination={{
                  pageSize: 10,
                  total:
                    this.props.salesOrderBills &&
                    this.props.salesOrderBills.count
                      ? this.props.salesOrderBills.count
                      : "",
                  onChange: (page) => {
                    this.props.getSaleOrderBills(page);
                  },
                }}
                scroll={{ y: 400 }}
              >
                <Column
                  title="SRN"
                  key="index"
                  render={(value, item, index) => index + 1}
                  width={60}
                />
                <Column
                  title="Bill Date"
                  key="date"
                  dataIndex="date"
                  width={150}
                  render={(value, item, index) =>
                    `${moment(item?.date).format("YYYY-MM-DD")} (${moment(
                      item?.date
                    ).format("HH:mm:ss")} )`
                  }
                />
                <Column
                  title="Customer Name"
                  {...this.getColumnSearchProps("dealerName")}
                  dataIndex="dealerName"
                  key="dealerName"
                  width={180}
                  render={(value, item, index) => `${item.userId.name}`}
                  // ellipsis={true}
                />
                <Column
                  title="Invoice No."
                  {...this.getColumnSearchProps("invoiceId")}
                  dataIndex="invoiceId"
                  key="invoiceId"
                  render={(value, item, index) => `S-${item.invoiceId}`}
                  width={145}
                />
                <Column
                  title="Total Qty"
                  dataIndex="totalQuantity"
                  key="totalQuantity"
                  width={120}
                />
                <Column
                  title="Total Boxes"
                  dataIndex="totalBoxes"
                  key="totalBoxes"
                  width={120}
                />
                <Column
                  title="Total Amount"
                  dataIndex="totalAmount"
                  key="totalAmount"
                  width={150}
                />
                <Column
                  title="Net Amt"
                  dataIndex="netAmount"
                  key="netAmount"
                  width={110}
                />
                <Column
                  title="Action"
                  key="action"
                  dataIndex="checkboxStatus"
                  width={80}
                  fixed="right"
                  render={(checkboxStatus, record) => (
                    <div>
                      <Checkbox
                        checked={checkboxStatus}
                        onChange={(e) => this.handleCheckboxChange(e, record)}
                        // onChange={(e) => this.onCheckChange(e, text)}
                      />
                    </div>
                  )}
                />
                <Column
                  title="Verified"
                  key="action"
                  dataIndex="checkboxStatus"
                  width={200}
                  fixed="right"
                  render={(checkboxStatus, record) => (
                    <div>
                      {record.isVerified ? (
                        <Alert message="Verified" type="success" showIcon />
                      ) : (
                        <Alert message="Not Verified" type="error" showIcon />
                      )}
                    </div>
                  )}
                />
              </Table>
              {this.props?.salesDataByCustomerId[0]?.userId?.agentId ? (
                <Row className="mt-4" align="center">
                  <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    <GenerateBillForMerge invoiceData={this.state.invoiceIds} />
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                    {roleName !== "CRM" && (
                      <MergeBillWhatsapp invoiceData={this.state.invoiceIds} />
                    )}
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6} xl={6} align="center">
                    {roleName !== "CRM" && <MB_AgentWhatsapp invoiceData={this.state.invoiceIds} agent={this.props?.salesDataByCustomerId[0]?.userId?.agentId} />
                      }
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    {roleName !== "CRM" && (
                      <VerifySalesBillProduct
                        title="mergebill"
                        invoiceData={this.state.invoiceIds}
                        showDataOnList={this.showDataOnList}
                      />
                    )}
                  </Col>
                </Row>
              ) : (
                <Row className="mt-4" align="center">
                  <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                    <GenerateBillForMerge invoiceData={this.state.invoiceIds} />
                  </Col>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} align="center">
                    {roleName !== "CRM" && (
                      <MergeBillWhatsapp invoiceData={this.state.invoiceIds} />
                    )}
                  </Col>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                    {roleName !== "CRM" && (
                      <VerifySalesBillProduct
                        title="mergebill"
                        invoiceData={this.state.invoiceIds}
                        showDataOnList={this.showDataOnList}
                      />
                    )}
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MergeBill.propTypes = {
  getSaleOrderBills: PropTypes.func.isRequired,
  getSaleUsingInvoiceId: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  getSaleOrderBillsBydate: PropTypes.object.isRequired,
  getCustomers: PropTypes.object.isRequired,
  salesBillByCustomerId: PropTypes.object.isRequired,
  getActiveandInactiveCustomer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  salesOrderBills: state.saleData.salesOrderBills,
  salesOrders: state.saleData.salesOrders,
  salesorderBydaterange: state.saleData.salesorderBydaterange,
  progressBarStatus: state.progressBarReducer.showProgressBar,
  customers: state.customerData.activeandInactiveCustomer,
  salesDataByCustomerId: state.saleData.slaesbillByCustomerId,
});

export default connect(mapStateToProps, {
  getSaleOrderBills,
  getSaleUsingInvoiceId,
  getSaleOrderBillsBydate,
  getCustomers,
  salesBillByCustomerId,
  getActiveandInactiveCustomer,
})(MergeBill);