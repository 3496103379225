import React, { useEffect, useState, useRef } from 'react'
import { Row, Col, Button, Input, Space, Table } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { getPOList, getPOdatabyId } from "../../../redux/actions/poOrderAction";
import TopBarProgress from "react-topbar-progress-indicator";
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import ViewPoProducts from './ViewPoProducts';


import VerifyPoBill from './VerifyPoBill';

const { Column } = Table;
const POTableList = () => {
  const dispatch = useDispatch()
  const poCredentialData = useSelector((state) => state.poData.polist);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const progressBarStatus = useSelector((state) => state.progressBarReducer.showProgressBar)
  const getList = () => {
    dispatch(getPOList())
  }

  useEffect(() => {
    getList()
  }, [])

  
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText('');
    confirm();
  };


  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>

        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  return (
    <>
      <div className="subCategory-dashboard">
        {progressBarStatus && <TopBarProgress />}
        <div className="container-fluid category-list">
          <Row className="title-row">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="page-header">PO Credentials List</div>
            </Col>
          </Row>
        </div>

        <div className="card final-purchases-list mt-4">
          <Table
            dataSource={poCredentialData && poCredentialData?.data && poCredentialData?.data?.length > 0 ? poCredentialData?.data : []}
            bordered={true}
            size="large"
            total={poCredentialData?.data?.length}
            pagination={{ pageSize: 100 }} // Set the pageSize to 100
            scroll={{ y: 400 }}
          >

            <Column
              title="SRN"
              key="index"
              render={(value, item, index) => index + 1}
              width={60}
            />


            <Column
              title="poId"
              dataIndex="poId"
              key="poId"
              width={160}
              {...getColumnSearchProps('poId')}
            />

            <Column
              title="Dealer Name"
              dataIndex="dealerName"
              key="dealerName"
              width={160}
              {...getColumnSearchProps('dealerName')}
            />


            <Column
              title="Total Quantity"
              dataIndex="totalQuantity"
              key="totalQuantity"
              width={140}
            />

            <Column
              title="Action"
              key="action"
              width={100}
              render={(text, record) => (
                <Row >
                   <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                    <ViewPoProducts data={text} />
                  </Col>
                  <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                   <VerifyPoBill text={text} record={record}/>
                  </Col>
                </Row>
              )}
            />

          </Table>
        </div>
      </div>
    </>
  )
}

export default POTableList