/* /////////////////////////////////////// */
/* 
Date              created by               Modifications
  
28-08-2023        Mohit                    Add componentDidupdate to sole same index issue on second page   

*/
/* /////////////////////////////////////// */

import React, { Component } from "react";
import { Table, Button, Space, Input, Checkbox, Row } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getGeneratedBarcodes } from "../../../redux/actions/purchaseAction";
import AddGenerateBarcode from "./AddGenerateBarcode";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import TopBarProgress from "react-topbar-progress-indicator";
import Search from "../../../util/Search";
const searchOptions = ["Barcode"]
const { Column } = Table;
const data = [
  {
    key: "1",
    barcode: 10004,
    designNumber: 67685765,
    brand: "Adidas",
    status: "Hogabc",
  },
];
class GenerateBarcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      options: [],
      barcodes: [],
      searchValue: "",
      apiData: [],
      searchApiadata : []
    };
  }
  componentDidMount() {
    this.props.getGeneratedBarcodes(1);
  }
  componentDidUpdate = (preveProp, preState) => {
    if (preveProp.purchases.data != this.props.purchases.data) {
      const dataWithCheckbox = this.props?.purchases?.data.map(item => {
        let findData = this.state.barcodes.find(obj=>{
          return obj.barcode == item.barcode
        })
        if(findData){
          return {
            ...item,
            checkboxStatus: true, // Initial checkbox status
          }
        }else{
          return {
            ...item,
            checkboxStatus: false, // Initial checkbox status
          }
        }
      }
      );
      this.setState({ apiData: dataWithCheckbox });
    }
    if(preveProp.generatebarcodeBysearch?.data != this.props.generatebarcodeBysearch.data){
      if(this.props && this.props.generatebarcodeBysearch && this.props.generatebarcodeBysearch.data && 
        this.props.generatebarcodeBysearch.data.length > 0){
        const dataWithCheckbox = this.props?.generatebarcodeBysearch?.data.map(item => {
          let findData = this.state.barcodes.find(obj=>{
            return obj.barcode == item.barcode
          })
          if(findData){
            return {
              ...item,
              checkboxStatus: true, // Initial checkbox status
            }
          }else{
            return {
              ...item,
              checkboxStatus: false, // Initial checkbox status
            }
          }
        }
        );
        this.setState({ apiData: dataWithCheckbox });
      } 
    }
    if (preState.apiData != this.state.apiData) {
      let data = this.state.apiData
      // let data = this.checkExistingCheck()
      this.setState({ apiData: data })
      // const dataWithCheckbox = this.state?.apiData.map(item => {return item});
      // this.setState({ apiData: dataWithCheckbox });
    }
  }

  
  onCheckChange = (e, text) => {
    if (e.target.checked) {
      this.setState({ barcodes: [text, ...this.state.barcodes] });
    } else {
      let id = text._id;
      let selectedBarcodes = this.state.barcodes.filter((barcode) => {
        if (barcode._id != id) return barcode;
      });
      this.setState({ barcodes: selectedBarcodes });
    }
    console.log(`checked = ${e.target.checked}`);
    console.log("text : ", text);
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };

  cancelState = () => {
    this.setState({ searchValue: "" });
  }
  onChange = (event) => {
    this.setState({ searchValue: event });
  };
  clearState = () => {
    this.setState({ barcodes: [] })
    this.props.getGeneratedBarcodes(1);
  }

  handleCheckboxChange = (e, record) => {
    const newData = this.state.apiData.map(item => {
      if (item.barcode == record.barcode) {
        return {
          ...item,
          checkboxStatus: !item.checkboxStatus,
        };
      } else {
        return item
      }
    });
    console.log("check my apoii", newData)
    this.setState({ apiData: newData });
    if (e.target.checked) {
      this.setState({ barcodes: [record, ...this.state.barcodes] });
    } else {
      let id = record._id;
      let selectedBarcodes = this.state.barcodes.filter((barcode) => {
        if (barcode._id != id) return barcode;
      });
      this.setState({ barcodes: selectedBarcodes });
    }
  }
  render() {
    const { searchValue } = this.state;
    const search = this.props.generatebarcodeBysearch.data || []
    return (
      <div>
        <Row className="mt-4">
          <Search title="Generate Barcode" placeholder="Barcode" searchopt={searchOptions} cancelState={this.cancelState} changeFunction={this.onChange} />
        </Row>
        <div className="card mt-4 generate-barcode-list">
          {this.props.progressBarStatus && <TopBarProgress />}
          <div className="card-body">
            <Table
              dataSource={searchValue ? this.state?.apiData : this.state?.apiData}
              bordered="true"
              size="middle"
              scroll={{ y: 240 }}
              pagination={{
                pageSize: 30,
                total: searchValue ? 1 : this.props?.purchases?.count,
                onChange: (page) => {
                  this.props.getGeneratedBarcodes(page);
                },
              }}
            >
              <Column
                title="SRN"
                key="index"
                render={(value, item, index) => index + 1}
                width={60}
              />

              <Column
                title="Barcode"
                dataIndex="barcode"
                key="barcode"
                width={100}
                {...this.getColumnSearchProps("barcode")}
              />
              <Column
                title="Design No."
                dataIndex="designNumber"
                key="designNumber"
                width={150}
                {...this.getColumnSearchProps("designNumber")}
              />
              <Column
                title="Brand"
                dataIndex="brand"
                key="brand"
                width={120}
                {...this.getColumnSearchProps("brand")}
              />
              <Column
                title="Size"
                dataIndex="size"
                key="size"
                width={80}
                {...this.getColumnSearchProps("size")}
              />
              <Column title="Color" dataIndex="color" key="color" width={80} />
              <Column title="WSP" dataIndex="wsp" key="wsp" width={80} />
              <Column title="MOQ" dataIndex="MOQ" key="MOQ" width={80} />
              <Column
                title="Action"
                key="action"
                dataIndex="checkboxStatus"
                width={80}
                render={(checkboxStatus, record) => (
                  <div>
                    <Checkbox checked={checkboxStatus}
                      onChange={(e) => this.handleCheckboxChange(e, record)}
                    // onChange={(e) => this.onCheckChange(e, text)}
                    />
                  </div>
                )}
              />
            </Table>
            <div>
              <AddGenerateBarcode barcodes={this.state.barcodes} clearState={this.clearState} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
GenerateBarcode.propTypes = {
  getGeneratedBarcodes: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => (
  {
    errors: state.errors,
    purchases: state.purchaseData.purchases,
    progressBarStatus: state.progressBarReducer.showProgressBar,
    generatebarcodeBysearch: state.purchaseData.generatebarcodeBysearch
  }
);

export default connect(mapStateToProps, {
  getGeneratedBarcodes,
})(GenerateBarcode);
