import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import debounce from "lodash.debounce";
import {
  Row,
  Col,
  DatePicker,
  Button,
  Form,
  Input,
  Select,
  notification,
  Tooltip,
  message,
} from "antd";
import moment from "moment";
import { PlusOutlined, StopOutlined } from "@ant-design/icons";
import { getBrandBySearch } from "../../../redux/actions/brandAction";
import ShowWHSalesDetails from "./ShowWHSalesDetails";
import {
  get_WH_PurchaseByBarcodeSale,
  warehouseLookup,
} from "../../../redux/actions/warehouseActions";
import { createSales, deleteSales } from "../../../redux/actions/saleAction";
import LookupModalSales from "../sales/LookupModalSales";
import { uuid } from "uuidv4";
import BarcodeReader from "react-barcode-reader";
import {
  addWarehouseSale,
  WarehouseSaleList,
} from "../../../redux/actions/warehouseActions";
import { getLocations } from "../../../redux/actions/locationAction";

let order = 1;
const dateFormat = "DD/MM/YYYY";
const { Option } = Select;

class AddSalesWarehouse extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    const currentDate = moment().format("DD/MM/YYYY");
    const currentTime = moment().format("HH:mm:ss");
    this.state = {
      myPurchaseData: [],
      invoiceNumber: "",
      saleDate: currentDate,
      saleTime: currentTime,
      saleBillDate: moment(
        `${currentDate} ${currentTime}`,
        "DD/MM/YYYY HH:mm:ss"
      ).toDate(),
      storeName: "",
      storeId: "",
      salesPersonName: "",
      lrNumber: "",
      lrDate: "",
      remark: "",
      transport: "",
      grAmount: 0,
      barcode: "",
      designNumber: "",
      boxes: "",
      totalBoxes: "",
      totalQuantity: 0,
      totalAmount: 0,
      brandName: "",
      brandId: "",
      s: "",
      selectedData: "",
      salesPersonId: "",
      salesPersonName: "",
      saleQty: 0,
      discount: 0,
      tax: 5,
      pf: 0,
      h: 0,
      billedGR: false,
      netAmount: 0,
      searchValue: "",
      locationName: "",
      locationId: "",
      isWarehouse: "",
    };
    this.handleScan = this.handleScan.bind(this);
    this.debouncedGetData = debounce(this.getData, 500);
  }

  handleChangeLocation = (key, value) => {
    const selectedLocation = this.props.locations?.find(
      (location) => location._id === value.value
    );

    this.setState(
      (prevState) => ({
        ...prevState,
        locationId: value.value,
        locationName: value.name,
        isWarehouse:
          selectedLocation?.locationType !== undefined
            ? selectedLocation.locationType === 1
            : false,
      }),
      () => {
        // This callback is called after the state has been updated
      }
    );

    this.debouncedGetData("location");
  };

  handleChangeStore = (key, value) => {
    let storeId = value.value;

    let storeName = value.children;

    this.setState({
      storeName: storeName,
      storeId,
    });

    this.debouncedGetData("store");
  };

  handleChangeBrand = async (key, value) => {
    let brandName = value.name;
    let brandId = value.value;
    this.setState({ brandName, brandId });
    if (this.state.designNumber) {
      await this.props.warehouseLookup({
        designNumber: this.state.designNumber,
        brandName,
      });
      this.setState({ s: uuid() });
    }
    this.debouncedGetData("brand");
  };

  handleBlur = (e) => {
    if (e.currentTarget.value === "0") e.currentTarget.value = "1";
  };
  preventScroll = (e) => {
    if (document.activeElement === e.target) {
      e.preventDefault();
    }
  };

  clearState = () => {
    this.setState({
      myPurchaseData: [],
      saleDate: "",
      saleBillDate: "",
      saleTime: "",
      storeName: "",
      remark: "",
      discount: "",
      tax: "",
      pf: "",
      finalAmount: "",
      netAmount: 0,
      MOQ: "",
      totalQuantity: "",
      updateBill: "",
      grAmount: "",
      grFlag: false,
      totalAmount: 0,
      designNumber: "",
      brand: "",
    });
  };

  componentWillUnmount = () => {
    this.clearState();
    this.props.createSales({}, {}, "blank");
  };
  onChange = (event) => {
    if (event.target.name === "discount" && this.state.totalAmount) {
      let value = event.target.value;
      let finalAmount;
      let discountedAmount = 0;
      let taxedAmount = 0;

      if (this.state.grFlag) {
        let total = this.state.totalAmount - this.state.grAmount;
        discountedAmount = (total * value) / 100;
      } else {
        discountedAmount = (this.state.totalAmount * value) / 100;
      }

      if (this.state.tax) {
        if (this.state.grFlag) {
          let total =
            this.state.totalAmount - this.state.grAmount - discountedAmount;
          taxedAmount = (total * this.state.tax) / 100;
        } else {
          taxedAmount =
            ((this.state.totalAmount - discountedAmount) * this.state.tax) /
            100;
        }
      }

      if (this.state.grFlag) {
        let total = this.state.totalAmount - this.state.grAmount;
        finalAmount = total - discountedAmount + taxedAmount;
      } else {
        finalAmount = this.state.totalAmount - discountedAmount + taxedAmount;
      }

      finalAmount = Math.round(finalAmount);

      let updatedNetAmount =
        finalAmount + Number(this.state.pf) + Number(this.state.h);

      // if (updatedNetAmount > this.state.grAmount && this.state.grFlag) {
      //   updatedNetAmount -= this.state.grAmount;
      // }

      let finalDiscountedAmount;

      if (this.state.grFlag) {
        let total = this.state.totalAmount - this.state.grAmount;
        finalDiscountedAmount = total - discountedAmount;
      } else {
        finalDiscountedAmount = this.state.totalAmount - discountedAmount;
      }

      return this.setState({
        [event.target.name]: event.target.value,
        finalAmount,
        discountedAmount,
        taxedAmount,
        finalDiscountedAmount,
        netAmount: updatedNetAmount,
      });
    } else if (event.target.name === "tax" && this.state.totalAmount) {
      let value = event.target.value;
      let finalAmount;
      let discountedAmount = 0;
      let taxedAmount = 0;
      if (this.state.discount) {
        if (this.state.grFlag) {
          let total = this.state.totalAmount - this.state.grAmount;
          discountedAmount = (total * this.state.discount) / 100;
        } else {
          discountedAmount =
            (this.state.totalAmount * this.state.discount) / 100;
        }
      }

      if (this.state.grFlag) {
        let total =
          this.state.totalAmount - this.state.grAmount - discountedAmount;
        taxedAmount = (total * value) / 100;
      } else {
        taxedAmount = (this.state.finalDiscountedAmount * value) / 100;
      }

      if (this.state.grFlag) {
        let total = this.state.totalAmount - this.state.grAmount;
        total = total - discountedAmount;
        finalAmount = total + taxedAmount;
      } else {
        finalAmount = this.state.totalAmount - discountedAmount + taxedAmount;
      }

      finalAmount = Math.round(finalAmount);

      let updatedNetAmount =
        finalAmount + Number(this.state.pf) + Number(this.state.h);

      let finalDiscountedAmount;

      if (this.state.grFlag) {
        let total = this.state.totalAmount - this.state.grAmount;
        finalDiscountedAmount = total - discountedAmount;
      } else {
        finalDiscountedAmount = this.state.totalAmount - discountedAmount;
      }

      return this.setState({
        [event.target.name]: value,
        finalAmount,
        taxedAmount,
        discountedAmount,

        finalDiscountedAmount,
        netAmount: updatedNetAmount,
      });
    } else if (event.target.name === "pf" && this.state.finalAmount) {
      let value = event.target.value;

      let netAmount;

      netAmount = Number(this.state.finalAmount) + Number(value);

      if (this.state.h) {
        netAmount =
          Number(this.state.finalAmount) + Number(value) + Number(this.state.h);
      }

      return this.setState({
        [event.target.name]: event.target.value,
        netAmount,
      });
    } else if (event.target.name === "h" && this.state.finalAmount) {
      let value = event.target.value;

      let netAmount;
      netAmount = Number(this.state.finalAmount) + Number(value);
      if (this.state.pf) {
        netAmount =
          Number(this.state.finalAmount) +
          Number(value) +
          Number(this.state.pf);
      }

      return this.setState({
        [event.target.name]: event.target.value,
        netAmount,
      });
    }
    this.setState({ [event.target.name]: event.target.value });
  };

  //function triggered on change in value in Enter Barcode barcode
  onBarcodeChange = async (event) => {
    if (!this.state.storeId && !this.state.locationId) {
      message.error("Please Select Store and Warehouse");
      return;
    }
    else if (!this.state.storeId) {
      message.error("Please Select Store");
      return;
    }
    else if (!this.state.locationId) {
      message.error("Please Select Warehouse");
      return;
    }
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.value.length >= 9) {
      await this.props
        .get_WH_PurchaseByBarcodeSale({
          barcode: Number(event.target.value),
        })
        .then((response) => {
          if (response && response.data) {
            if (response.data?.quantity <= 0) {
              message.warn("Out of stock");
            } else {
              //if got proper response in this.props.barcode then only below line will be executed-
              this.selectedData(response.data, "barcode", "scan");
            }
          } else {
            message.error("No Data Found")
            this.formRef.current.setFieldsValue({
              brand: undefined,
            });
            this.setState({
              designNumber: "",
              brand: "",
            });
          }
        });
    }
  };

  onSaleDateChange = (date, dateString) => {
    let currentTime = moment().format("HH:mm:ss");
    this.setState({ saleDate: dateString, saleTime: currentTime });
    let d = moment(
      dateString + " " + currentTime,
      "DD/MM/YYYY HH:mm:ss"
    ).toDate();
    this.setState({ saleBillDate: d });
  };

  onLRDateChange = (date, dateString) => {
    this.setState({ lrDate: dateString });
  };

  selectedData = (data, name = undefined, scan = undefined) => {
    data.boxes = 1;
    if (name == "barcode") {
      this.setState({
        selectedData: data,
        //boxes: data.quantity,
        designNumber: data.designNumber,
        brandName: data.brand,
      });
      this.formRef.current.setFieldsValue({
        brand: data.brand,
      });
      if (scan) {
        this.showDataOnList(scan);
      }
    }
    if (this.state.designNumber && this.state.brandName) {
      this.setState({
        selectedData: data,
        //boxes: data.quantity,
        barcode: data.barcode,
      });
    }
  };

  checkBarcodeExists = (barcode) => {
    let exist = this.state.myPurchaseData.findIndex(
      (element) => element.barcode === barcode
    );
    return exist;
  };

  async handleScan(data) {
    if (data.length >= 9) {
      await this.props
        .get_WH_PurchaseByBarcodeSale({
          barcode: Number(data),
        })
        .then((response) => {
          if (response && response.data) {
            if (response.data?.quantity <= 0) {
              message.warn("Out of stock");
            } else {
              //if got proper response in this.props.barcode then only below line will be executed-
              this.selectedData(response.data, "barcode", "scan");
            }
          } else {
            message.error("No Data Found")
            this.formRef.current.setFieldsValue({
              brand: undefined,
            });
            this.setState({
              designNumber: "",
              brand: "",
            });
          }
        });
    }
  }

  //function called when form is submited from plus icon-
  showDataOnList = (scan) => {
    if (this.state.selectedData) {
      let index = this.checkBarcodeExists(this.state.selectedData.barcode);

      let temp = [];
      temp = this.state.myPurchaseData.map((ele) => {
        return { ...ele, updateQty: false };
      });
      if (index != -1) {
        if (temp[index].saleQty >= temp[index].quantity) {
          message.warn("You can not add more then the purchase quantity");
        } else if (temp[index].quantity <= 0) {
          message.warn("Out of stock");
        } else {
          temp[index].boxes += 1;
          delete temp[index]["saleQty"];
          temp[index]["saleQty"] = temp[index].boxes * temp[index].MOQ;
          temp[index]["xyz"] = temp[index].boxes * temp[index].MOQ;
          temp[index]["updateQty"] = true;
          temp[index]["finalAmount"] =
            temp[index].saleQty * this.state.selectedData.purchasePrice;
          temp[index].key = uuid();

          this.setState({ myPurchaseData: temp });
        }
      } else {
        // if (scan && !this.state.barcode) {
        //   message.error("Barcode Not Found")
        //   return;
        // }
        this.state.selectedData["salesPersonId"] = this.state.salesPersonId;
        this.state.selectedData["salesPersonName"] = this.state.salesPersonName;
        this.state.selectedData["finalAmount"] =
          this.state.selectedData.MOQ * this.state.selectedData.purchasePrice;
        this.state.selectedData["order"] = order;
        temp = [this.state.selectedData, ...this.state.myPurchaseData];
        order = order + 1;
      }

      let e = 0;
      let v = temp.length;
      let totalAmount = 0;
      let totalQuantity = 0;
      let totalBoxes = 0;
      let finalAmount = 0;
      let discountedAmount = 0;
      let taxedAmount = 0;
      let netAmount = 0;
      while (e < v) {
        totalBoxes += temp[e].boxes;
        temp[e].saleQty = temp[e].boxes * temp[e].MOQ;
        totalQuantity += temp[e].saleQty;
        totalAmount += temp[e].purchasePrice * temp[e].saleQty;

        ++e;
      }

      if (this.state.discount) {
        if (this.state.grFlag && totalAmount) {
          let total = totalAmount - this.state.grAmount;
          discountedAmount = (total * this.state.discount) / 100;
        } else {
          discountedAmount = (totalAmount * this.state.discount) / 100;
        }
      }

      if (this.state.tax) {
        if (this.state.grFlag && totalAmount) {
          let total = totalAmount - this.state.grAmount;
          taxedAmount = ((total - discountedAmount) * this.state.tax) / 100;
        } else {
          taxedAmount =
            ((totalAmount - discountedAmount) * this.state.tax) / 100;
        }
      }

      if (this.state.grFlag) {
        let total = totalAmount - this.state.grAmount;
        finalAmount = total - discountedAmount + taxedAmount;
      } else {
        finalAmount = totalAmount - discountedAmount + taxedAmount;
      }

      finalAmount = Math.round(finalAmount);
      if (this.state.pf != undefined && this.state.h != undefined) {
        netAmount =
          Number(finalAmount) + Number(this.state.pf) + Number(this.state.h);
      } else if (this.state.pf != undefined) {
        netAmount = Number(finalAmount) + Number(this.state.pf);
      } else if (this.state.h != undefined) {
        netAmount = Number(finalAmount) + Number(this.state.h);
      }

      let finalDiscountedAmount;
      if (this.state.grFlag) {
        let total = totalAmount - this.state.grAmount;
        finalDiscountedAmount = total - discountedAmount;
      } else {
        finalDiscountedAmount = totalAmount - discountedAmount;
      }

      this.setState({
        myPurchaseData: temp,
        selectedData: undefined,
        barcode: "",
        boxes: "",
        designNumber: "",
        brandName: "",
        totalAmount,
        totalQuantity,
        totalBoxes,
        finalAmount,
        taxedAmount,
        discountedAmount,
        netAmount,

        finalDiscountedAmount,
      });

      this.formRef.current.setFieldsValue({
        brand: undefined,
      });
    }
  };

  getData = (name, value) => {
    if (name == "store") this.props.getLocations();
    if (name == "sales") this.props.getCustomers("SALES");
    if (name == "brand") this.props.getBrandBySearch(value);
    if (name == "location") this.props.getLocations();
  };

  createSales = (salesOrder) => {
    if (this.state.myPurchaseData.length) {
      let salesOrderObj = {
        totalQuantity: this.state.totalQuantity,
        warehouseSale: salesOrder,
        tax: this.state.tax,
        discount: this.state.discount,
        storeid: this.state.storeId,
        storename: this.state.storeName,
        netAmount: this.state.netAmount,
        totalAmount: this.state.totalAmount,
        totalBoxes: this.state.totalBoxes,
        locationName: this.state.locationName,
        locationId: this.state.locationId,
        isWarehouse: this.state.isWarehouse,
        remark: this.state.remark
      };

      if (this.state.grFlag) {
        salesOrderObj["srAmount"] = this.state.grAmount;
      }

      let sales = [];
      for (let i = 0; i < this.state.myPurchaseData.length; ++i) {
        let newObj = {
          // salesPersonName: this.state.myPurchaseData[i].salesPersonName,
          // salesPersonId: this.state.myPurchaseData[i].salesPersonId,
          quantity: this.state.myPurchaseData[i].saleQty,
          barcode: this.state.myPurchaseData[i].barcode,
          MOQ: this.state.myPurchaseData[i].MOQ,
        };
        if (this.state.myPurchaseData[i].salesOrderId) {
          newObj["_id"] = this.state.myPurchaseData[i].salesOrderId;
        }
        sales.push(newObj);
      }
      salesOrderObj["warehouseSale"] = sales;

      if (this.state.invoiceNumber) {
        salesOrderObj["invoiceNumber"] = this.state.invoiceNumber;
      }
      console.log("salesOrderObj", salesOrderObj)
      if (salesOrderObj.storeid && salesOrderObj.locationId) {
        this.props.addWarehouseSale(salesOrderObj).then((respo) => {
          if (respo.status === 200) {
            message.success("Sale added successfully");
            this.props.WarehouseSaleList();
            setTimeout(() => {
              window.location.reload(true);
            }, 1000);
          }
        });
      } else {
        if (!salesOrderObj.storeid && !salesOrderObj.locationId) {
          message.error("Please Select Store and Warehouse");
        }
        else if (!salesOrderObj.storeid) {
          message.error("Please Select Store");
        }
        else {
          message.error("Please Select Warehouse");
        }
        return
      }

      this.setState({
        invoiceNumber: "",
        storeId: "",
        agentId: "",
        agent: "",
        salesPersonName: "",
        lrNumber: "",
        transport: "",
        h: "",
        boxes: "",
        totalBoxes: "",
        agentId: "",
        selectedData: "",
        salesPersonId: "",
        salesPersonName: "",
        isWarehouse: "",
        locationName: "",
        locationId: "",
        billedGR: false,
      });

      this.formRef.current.setFieldsValue({
        salesPersonName: undefined,
        storeName: undefined,
        locationName: undefined,
      });
    }
  };
  //checkbox in addsale to get amount(including any previous due)
  oncheckChange = (e) => {
    if (e.target.checked && this.state.netAmount && this.state.grAmount) {
      let total = this.state.totalAmount - this.state.grAmount;
      let discountedAmount = (total * this.state.discount) / 100;
      let finalDiscountedAmount = total - discountedAmount;
      let taxedAmount = (finalDiscountedAmount * this.state.tax) / 100;
      let finalAmount = finalDiscountedAmount + taxedAmount;
      let updatedNetAmount =
        Number(finalAmount) + Number(this.state.h) + Number(this.state.pf);
      this.setState({
        netAmount: updatedNetAmount,
        grFlag: true,
        discountedAmount: discountedAmount,
        finalDiscountedAmount: finalDiscountedAmount,
        taxedAmount: taxedAmount,
        finalAmount: finalAmount,
      });
      this.setState({ billedGR: true });
    } else if (
      !e.target.checked &&
      this.state.netAmount &&
      this.state.grAmount
    ) {
      let total = this.state.totalAmount;
      let discountedAmount = (total * this.state.discount) / 100;
      let finalDiscountedAmount = total - discountedAmount;
      let taxedAmount = (finalDiscountedAmount * this.state.tax) / 100;
      let finalAmount = finalDiscountedAmount + taxedAmount;

      let updatedNetAmount =
        Number(finalAmount) + Number(this.state.h) + Number(this.state.pf);
      this.setState({
        netAmount: updatedNetAmount,
        grFlag: false,
        discountedAmount: discountedAmount,
        finalDiscountedAmount: finalDiscountedAmount,
        taxedAmount: taxedAmount,
        finalAmount: finalAmount,
      });
      this.setState({ billedGR: false });
    } else {
      this.setState({ grFlag: e.target.checked });
    }
  };
  deleteSale = (id) => {
    let toDelete;
    let purchases = this.state.myPurchaseData.filter((purchase) => {
      if (purchase._id != id) return purchase;
      toDelete = purchase;
    });
    if (toDelete.salesOrderId) {
      this.props.deleteSales(toDelete.salesOrderId);
      let updatedQuantity = this.state.totalQuantity - toDelete.saleQty;
      let updatedBoxes = this.state.totalBoxes - toDelete.boxes;
      let updatedTotalAmount =
        this.state.totalAmount - toDelete.purchasePrice * toDelete.saleQty;
      let updatedFinalAmount = 0;
      let updatedNetAmount = 0;

      let total,
        discountedAmount,
        finalDiscountedAmount,
        taxedAmount,
        finalAmount,
        netAmount;

      if (this.state.grFlag) {
        total = updatedTotalAmount - this.state.grAmount;
      } else {
        total = updatedTotalAmount;
      }

      discountedAmount = (total * this.state.discount) / 100;
      finalDiscountedAmount = total - discountedAmount;
      taxedAmount = (finalDiscountedAmount * this.state.tax) / 100;
      finalAmount = finalDiscountedAmount + taxedAmount;
      netAmount =
        Number(finalAmount) + Number(this.state.h) + Number(this.state.pf);

      this.setState({
        totalQuantity: updatedQuantity,
        totalAmount: updatedTotalAmount,
        totalBoxes: updatedBoxes,
        myPurchaseData: purchases,
        finalAmount,
        netAmount,
        taxedAmount,
        discountedAmount,

        finalDiscountedAmount,
      });
    } else {
      let updatedQuantity = this.state.totalQuantity - toDelete.saleQty;
      let updatedBoxes = this.state.totalBoxes - toDelete.boxes;
      let updatedTotalAmount =
        this.state.totalAmount - toDelete.purchasePrice * toDelete.saleQty;

      let total,
        discountedAmount,
        finalDiscountedAmount,
        taxedAmount,
        finalAmount,
        netAmount;

      if (this.state.grFlag) {
        total = updatedTotalAmount - this.state.grAmount;
      } else {
        total = updatedTotalAmount;
      }

      discountedAmount = (total * this.state.discount) / 100;
      finalDiscountedAmount = total - discountedAmount;
      taxedAmount = (finalDiscountedAmount * this.state.tax) / 100;
      finalAmount = finalDiscountedAmount + taxedAmount;
      netAmount =
        Number(finalAmount) + Number(this.state.h) + Number(this.state.pf);

      this.setState({
        totalQuantity: updatedQuantity,
        totalAmount: updatedTotalAmount,
        totalBoxes: updatedBoxes,
        myPurchaseData: purchases,
        finalAmount,
        netAmount,
        taxedAmount,
        discountedAmount,
        finalDiscountedAmount,
      });
    }
  };
  onRemoveBoxes = (obj) => {
    let index = this.checkBarcodeExists(obj.barcode);
    let temp = [];
    temp = this.state.myPurchaseData;
    temp[index].boxes -= 1;
    temp[index].saleQty = obj.MOQ * temp[index].boxes;

    temp[index]["finalAmount"] =
      temp[index].saleQty * temp[index].purchasePrice;
    temp[index].key = uuid();
    this.setState({ myPurchaseData: temp });

    let e = 0;
    let v = temp.length;
    let totalAmount = 0;
    let totalQuantity = 0;
    let totalBoxes = 0;
    while (e < v) {
      totalQuantity += temp[e].saleQty;
      totalAmount += temp[e].purchasePrice * temp[e].saleQty;
      totalBoxes += temp[e].boxes;
      ++e;
    }

    let discountedAmount,
      finalDiscountedAmount,
      taxedAmount,
      finalAmount,
      netAmount;

    if (this.state.grFlag) {
      let total = totalAmount - this.state.grAmount;
      discountedAmount = (total * this.state.discount) / 100;
      finalDiscountedAmount = total - discountedAmount;
      taxedAmount = (finalDiscountedAmount * this.state.tax) / 100;
      finalAmount = finalDiscountedAmount + taxedAmount;
      netAmount =
        Number(finalAmount) + Number(this.state.h) + Number(this.state.pf);
    } else {
      let total = totalAmount;
      discountedAmount = (total * this.state.discount) / 100;
      finalDiscountedAmount = total - discountedAmount;
      taxedAmount = (finalDiscountedAmount * this.state.tax) / 100;
      finalAmount = finalDiscountedAmount + taxedAmount;
      netAmount =
        Number(finalAmount) + Number(this.state.h) + Number(this.state.pf);
    }

    this.setState({
      totalAmount,
      totalQuantity,
      totalBoxes,
      taxedAmount,
      discountedAmount,
      finalDiscountedAmount,
      finalAmount,
      netAmount,
    });
  };
  onAddBoxes = (obj) => {
    let index = this.checkBarcodeExists(obj.barcode);
    let temp = [];
    temp = this.state.myPurchaseData;
    let netMoq = temp[index].MOQ;
    let netTotalPurchaseValue =
      temp[index].netTotalPurchaseValue ||
        temp[index].netTotalPurchaseValue == 0
        ? temp[index].netTotalPurchaseValue
        : temp[index].quantity - temp[index].MOQ;
    if (
      temp[index].saleQty < temp[index].quantity
    ) {
      temp[index].boxes += 1;
      temp[index].saleQty = obj.MOQ * temp[index].boxes;
      temp[index].netTotalPurchaseValue = netTotalPurchaseValue - netMoq;
    } else {
      message.warn("You can not add more then the purchase quantity");
    }

    temp[index]["finalAmount"] =
      temp[index].saleQty * temp[index].purchasePrice;
    temp[index].key = uuid();
    this.setState({ myPurchaseData: temp });
    let e = 0;
    let v = temp.length;
    let totalAmount = 0;
    let totalQuantity = 0;
    let totalBoxes = 0;
    while (e < v) {
      if (temp[e].saleQty <= temp[e].totalPurchaseValue) {
        totalQuantity += temp[e].saleQty;
        totalAmount += temp[e].purchasePrice * temp[e].saleQty;
        totalBoxes += temp[e].boxes;
      } else {
        totalQuantity += temp[e].saleQty;
        totalAmount += temp[e].purchasePrice * temp[e].saleQty;
        totalBoxes += temp[e].boxes;
      }
      ++e;
    }

    let discountedAmount,
      finalDiscountedAmount,
      taxedAmount,
      finalAmount,
      netAmount;

    if (this.state.grFlag) {
      let total = totalAmount - this.state.grAmount;
      discountedAmount = (total * this.state.discount) / 100;
      finalDiscountedAmount = total - discountedAmount;
      taxedAmount = (finalDiscountedAmount * this.state.tax) / 100;
      finalAmount = finalDiscountedAmount + taxedAmount;
      netAmount =
        Number(finalAmount) + Number(this.state.h) + Number(this.state.pf);
    } else {
      let total = totalAmount;
      discountedAmount = (total * this.state.discount) / 100;
      finalDiscountedAmount = total - discountedAmount;
      taxedAmount = (finalDiscountedAmount * this.state.tax) / 100;
      finalAmount = finalDiscountedAmount + taxedAmount;
      netAmount =
        Number(finalAmount) + Number(this.state.h) + Number(this.state.pf);
    }

    this.setState({
      totalAmount,
      totalQuantity,
      totalBoxes,
      taxedAmount,
      discountedAmount,
      finalDiscountedAmount,
      finalAmount,
      netAmount,
    });
  };

  componentDidMount() { }

  render() {
    let locationOptions = this.props.locations
      .filter((location) => location.locationType === 1)
      .map((location) => (
        <Option key={location._id} name={location.name} value={location._id}>
          {location.name}
        </Option>
      ));

    { console.log("check m,ohit hello", this.props.locations) }

    let storeOptions = this.props.locations
      .filter((location) => location.locationType === 0)
      .map((store) => {
        return (
          <Option name={[store.name]} value={store._id}>
            {store.name}
          </Option>
        );
      });

    let brandOptions = this.props?.brandBysearch?.data?.map((brand) => {
      return (
        <Option name={brand.name} value={brand._id}>
          {brand.name}
        </Option>
      );
    });

    var disabled = false;
    if (this.state.orderedSale) {
      disabled = true;
    }

    return (
      <>
        <Row className="title-row" justify="space-between">
          <Col>
            <div className="page-header">Add Warehouse Sale</div>
          </Col>
        </Row>

        <div className="card mt-4 final-purchases-list">
          <div className="card-body">
            <div className="add-purchase">
              <div className="container-fluid">
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form
                      ref={this.formRef}
                      name="add-Purchase"
                      className="add-Purchase"
                      onFinish={this.showDataOnList}
                      initialValues={{ remember: true }}
                    >
                      <Row gutter={[8, 0]}>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Tooltip title="Invoice No.">
                            <Form.Item>
                              <Input
                                disabled
                                placeholder="Enter Invoice Number"
                                name="invoiceNumber"
                                value={this.state.invoiceNumber}
                                onChange={this.onChange}
                              />
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <div className="datePicker-hideCross">
                            <Tooltip title="Select Sale Date">
                              <Form.Item name="saleDate">
                                <DatePicker
                                  onChange={this.onSaleDateChange}
                                  placeholder="Select Sale Date"
                                  value={moment(
                                    this.state.saleDate,
                                    dateFormat
                                  )}
                                  format={dateFormat}
                                />
                                <DatePicker
                                  style={{ display: "none" }}
                                  value={moment(
                                    this.state.saleDate,
                                    dateFormat
                                  )}
                                  placeholder="Select Sale Date"
                                  onChange={this.onSaleDateChange}
                                  format={dateFormat}
                                />
                              </Form.Item>
                            </Tooltip>
                          </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Tooltip title="Select Store">
                            <Form.Item
                              name="storeName"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Select Store Name !",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder="Select Store"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                onChange={this.handleChangeStore}
                                onClick={() => this.debouncedGetData("store")}
                                onInputKeyDown={() =>
                                  this.debouncedGetData("store")
                                }
                              >
                                {storeOptions}
                              </Select>
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          <Tooltip title="Remark">
                            <Form.Item>
                              <Input
                                placeholder="Enter Remark"
                                name="remark"
                                value={this.state.remark}
                                onChange={this.onChange}
                              />
                            </Form.Item>
                          </Tooltip>
                        </Col>
                      </Row>

                      <Row gutter={[8, 0]}>
                        <Col xs={8} sm={8} md={8} lg={5} xl={5}>
                          <Tooltip title="Select Warehouse">
                            <Form.Item
                              name="location"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Select Warehouse !",
                                },
                              ]}
                            >
                              <Select
                                //defaultValue="214, Tilak Path "
                                disabled={disabled}
                                showSearch
                                placeholder="Select Warehouse"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                onChange={this.handleChangeLocation}
                                onClick={() =>
                                  this.debouncedGetData("location")
                                }
                                onInputKeyDown={() =>
                                  this.debouncedGetData("location")
                                }
                              >
                                {locationOptions}
                              </Select>
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                          <Tooltip title="Barcode">
                            <Form.Item
                              //name="barcode"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter Barcode Number !",
                                },
                              ]}
                            >
                              <BarcodeReader
                                onError={this.handleError}
                                onScan={this.handleScan}
                              // onScan={this.showDataOnList}
                              />
                              <Input
                                placeholder="Enter Barcode Number"
                                name="barcode"
                                value={this.state.barcode}
                                onChange={this.onBarcodeChange}
                                required
                              />
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                          <Tooltip title="Design Number">
                            <Form.Item
                              //name="designNumber"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter Design Number !",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter Design Number"
                                name="designNumber"
                                value={this.state.designNumber}
                                onChange={this.onChange}
                                required
                              />
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                          <Tooltip title="Select Brand">
                            <Form.Item
                              name="brand"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Select Brand Name !",
                                },
                              ]}
                            >
                              <Select
                                // name="brand"
                                // id="brand"
                                showSearch
                                placeholder="Select Brand"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                  optionA.children
                                    .toLowerCase()
                                    .localeCompare(
                                      optionB.children.toLowerCase()
                                    )
                                }
                                onChange={this.handleChangeBrand}
                                onClick={() => this.debouncedGetData("brand")}
                                onInputKeyDown={() =>
                                  this.debouncedGetData("brand")
                                }
                              >
                                {brandOptions}
                              </Select>
                            </Form.Item>
                          </Tooltip>
                        </Col>

                        <LookupModalSales
                          purchases={this.props.purchases}
                          s={this.state.s}
                          selectedData={this.selectedData}
                        />
                        <Col xs={2} sm={2} md={2} lg={1} xl={1}>
                          <Tooltip title="Add ">
                            <Form.Item>
                              {this.props.invoiceId ? (
                                <>
                                  <Button
                                    type="primary"
                                    className="close-modal"
                                    disabled
                                  >
                                    <PlusOutlined />
                                  </Button>
                                </>
                              ) : (
                                <>
                                  {this.props.barcode &&
                                    this.props.barcode.quantity <= 0 || (this.state.selectedData && this.state.selectedData.quantity <= 0) ? (
                                    <>
                                      <Button
                                        type="primary"
                                        onClick={() =>
                                          message.warn("Out of Stock")
                                        }
                                        className="close-modal"
                                      >
                                        <PlusOutlined />
                                      </Button>
                                    </>
                                  ) : (
                                    <>
                                      <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="close-modal"
                                      >
                                        <PlusOutlined />
                                      </Button>
                                    </>
                                  )}
                                </>
                              )}
                            </Form.Item>
                          </Tooltip>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <ShowWHSalesDetails
                      myPurchaseData={this.state.myPurchaseData}
                      deleteSale={this.deleteSale}
                      onAddBoxes={this.onAddBoxes}
                      onRemoveBoxes={this.onRemoveBoxes}
                    />
                  </Col>
                </Row>

                <Row span={16} className="mt-3" justify="center">
                  <Col span={7} style={{ marginRight: "16px" }}>
                    <Tooltip title="Total Boxes">
                      <Form.Item>
                        <Input
                          placeholder="Total Boxes"
                          name="totalBoxes"
                          value={this.state.totalBoxes}
                          onChange={this.onChange}
                          disabled
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col
                    span={7}
                    style={{ marginLeft: "8px", marginRight: "8px" }}
                  >
                    <Tooltip title="Total Quantity">
                      <Form.Item>
                        <Input
                          placeholder="Total Quantity"
                          type="number"
                          name="totalQuantity"
                          value={parseInt(this.state.totalQuantity).toFixed()}
                          onChange={this.onChange}
                          disabled
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>

                  <Col span={7} style={{ marginLeft: "16px" }}>
                    <Tooltip title="Total Amount">
                      <Form.Item>
                        <Input
                          type="number"
                          placeholder="Total Amount"
                          name="totalAmount"
                          value={this.state.totalAmount}
                          onChange={this.onChange}
                          disabled
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                </Row>

                {(() => {
                  if (this.state.updateBill) {
                    return (
                      <Row gutter={[8, 0]}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Form.Item>
                            {/**Prateek Logic to submit when all the fields are filled*/}
                            {(() => {
                              if (
                                this.state.netAmount == "" ||
                                this.state.customerId == ""
                              ) {
                                return (
                                  <Button
                                    type="primary"
                                    className="close-modal-save-button"
                                    block
                                    disabled
                                  >
                                    Save Bill <StopOutlined />
                                  </Button>
                                );
                              } else {
                                let gr = 0;
                                if (this.state.billedGR) {
                                  gr = this.state.grAmount;
                                }
                                return (
                                  <Button
                                    type="primary"
                                    className="close-modal-save-button"
                                    block
                                    onClick={() => this.createSales(false)}
                                  >
                                    Save Bill
                                  </Button>
                                );
                              }
                            })()}
                          </Form.Item>
                        </Col>
                      </Row>
                    );
                  } else {
                    return (
                      <Row gutter={[8, 0]}>
                        <Col span={24}>
                          <Form.Item>
                            {/**Prateek Logic to submit when all the fields are filled*/}
                            {(() => {
                              if (
                                this.state.netAmount == "" ||
                                this.state.customerId == ""
                              ) {
                                return (
                                  <Button
                                    type="primary"
                                    className="close-modal-save-button"
                                    block
                                    disabled
                                  // onClick={() => this.createSales()}
                                  >
                                    Save Bill <StopOutlined />
                                  </Button>
                                );
                              } else {
                                let gr = 0;
                                if (this.state.billedGR) {
                                  gr = this.state.grAmount;
                                }
                                return (
                                  <Button
                                    type="primary"
                                    className="close-modal-save-button"
                                    block
                                    onClick={() => this.createSales(false)}
                                  >
                                    Save Bill
                                  </Button>
                                );
                              }
                            })()}
                          </Form.Item>
                        </Col>
                      </Row>
                    );
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

AddSalesWarehouse.propTypes = {
  //addPurchase: PropTypes.func.isRequired,
  getBrandBySearch: PropTypes.func.isRequired,
  warehouseLookup: PropTypes.func.isRequired,
  get_WH_PurchaseByBarcodeSale: PropTypes.func.isRequired,
  createSales: PropTypes.func.isRequired,
  deleteSales: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  addWarehouseSale: PropTypes.func.isRequired,
  WarehouseSaleList: PropTypes.func.isRequired,
  getLocations: PropTypes.func.isRequired,
  barcode: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  invoiceId: state.saleData.sale.invoiceId,
  agentName: state.saleData.sale.agentName,
  salesPerson: state.customerData.salesPerson,
  brands: state.brandsData.brands,
  brandBysearch: state.brandsData.brandBysearch,
  // purchases: state.purchaseData.purchases,
  purchases: state.warehouseData.warhouseLookup,
  purchase: state.purchaseData.purchase,
  barcode: state.warehouseData.warhouseBarcode,
  locations: state.locationData.locations,
});

export default connect(mapStateToProps, {
  getBrandBySearch,
  warehouseLookup,
  get_WH_PurchaseByBarcodeSale,
  createSales,
  deleteSales,
  addWarehouseSale,
  WarehouseSaleList,
  getLocations,
})(AddSalesWarehouse);