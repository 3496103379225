import React, { Component } from "react";
import { Row, Col, Tooltip, Form, Select, DatePicker, Button } from "antd";
import SalesSummaryReportList from "./SalesSummaryReportList";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { getSalesSummaryReports } from "../../../../redux/actions/reportsActions";
import TopBarProgress from "react-topbar-progress-indicator";
import BackButton from "../../Report/Button";

const dateFormat = "YYYY-MM-DD";

class SalesSummaryReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      endDate: moment(new Date()).format("YYYY-MM-DD"),
      startDate: moment(new Date()).format("YYYY-MM-DD"),
      reportAllObject: {}
    };
  }

  componentDidMount = () => {
    this.showDataOnList();
  };

  onStartDateChange = (date, dateString) => {
    this.setState({ startDate: dateString });
  };
  onEndDateChange = (date, dateString) => {
    this.setState({ endDate: dateString });
  };
  showDataOnList = async () => {
    try {
      let today = moment(new Date()).format("YYYY-MM-DD");
      const limit = 1;
      let reportObj={}
      if (today == this.state.endDate) {
        reportObj = {
          startDate: this.state.startDate,
          endDate: this.state.endDate + " " + moment().format("HH:mm:ss"),
          limit: limit,
          perPage:30,
          type:"perpage"
        };
      } else {
        const dateMoment = moment(this.state.endDate, "YYYY-MM-DD");
        const endTime = dateMoment.endOf("day").format("HH:mm:ss");
        const endDateWithEndTime = `${this.state.endDate} ${endTime}`;
        reportObj = {
          startDate: this.state.startDate,
          endDate: endDateWithEndTime,
          limit: limit,
          perPage:30,
          type:"perpage"
        };
      }
    
      const initialReport = await this.props.getSalesSummaryReports(reportObj);
      console.log("Initial report data:", initialReport);

      const reportObjAll = {
        startDate: this.state.startDate,
        endDate: reportObj.endDate,
        limit: limit,
        perPage: initialReport?.count,
        type: "all",
      };
      this.setState({reportAllObject : reportObjAll})
      // await this.props.getSalesSummaryReports(reportObjAll);
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  render() {
    return (
      <div className="subCategory-dashboard">
        {this.props.progressBarStatus && <TopBarProgress />}
        <div className="container-fluid category-list">
          <Row className="title-row">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row gutter={24}>
                <Col span={12}>
                  <div className="page-header">Sales Summary Report</div>
                </Col>
                <Col span={12}>
                  <BackButton />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form
                name="add-SalesSummary"
                className="add-SalesSummary"
                onFinish={this.showDataOnList}
                initialValues={{ remember: true }}
              >
                <Row gutter={[16, 0]} className="mt-4">
                  <Col xs={12} sm={12} md={10} lg={6} xl={6}>
                    <Tooltip title="Select Start Date">
                      <Form.Item
                        name="startDate"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Start Date !",
                          },
                        ]}
                        initialValue={moment()}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          onChange={this.onStartDateChange}
                          placeholder="Select Start Date"
                          format={dateFormat}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={12} sm={12} md={10} lg={6} xl={6}>
                    <Tooltip title="Select End Date">
                      <Form.Item
                        name="endDate"
                        rules={[
                          {
                            required: true,
                            message: "Please Select End Date !",
                          },
                        ]}
                        initialValue={moment()}
                      >
                        <DatePicker
                          disabledDate={(current) => {
                            return current && current > Date.now();
                          }}
                          style={{ width: "100%" }}
                          onChange={this.onEndDateChange}
                          placeholder="Select End Date"
                          format={dateFormat}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>

                  <Col xs={24} sm={24} md={4} lg={3} xl={3}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="close-modal"
                        block
                      >
                        Search
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <SalesSummaryReportList
            reports={this.props.reports}
            allreports={this.props.allreports}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            reportObj={{
              startDate: this.state.startDate,
              endDate: this.state.endDate,
            }}
            getSalesSummaryReports={this.props.getSalesSummaryReports}
            reportObjAll = {this.state.reportAllObject}
          />
        </div>
      </div>
    );
  }
}
SalesSummaryReport.propTypes = {
  getSalesSummaryReports: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  reports: state.reportData.salesSummaryreports,
  allreports: state.reportData.allsalesSummaryreports,
  progressBarStatus: state.progressBarReducer.showProgressBar,
});

export default connect(mapStateToProps, {
  getSalesSummaryReports,
})(SalesSummaryReport);