import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  fetchAllProductsSidebarList,
  fetchFilterProductSidebarList,
  fetchProductsByFiltereing,
} from "../../redux/actions/productActions";

const BrandsColumn = ({ brand }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleBrandClick = async (brandId) => {
    const obj = {
      category: [],
      style: [],
      brand: [brandId],
      color: [],
      size: [],
    };
    const params = new URLSearchParams();
    params.set("All", true);
    params.set("limit", 1);
    params.set("brand", brandId);
    params.delete("style");
    params.delete("color");
    params.delete("size");
    params.delete("category");

    let response = await dispatch(fetchAllProductsSidebarList());
    let brands = await response;
    if (brands) {
      await dispatch(fetchProductsByFiltereing(obj));
      await dispatch(fetchFilterProductSidebarList(obj));
      history.push({
        pathname: "/shop-grid-standard",
        search: params.toString(),
        state: {
          stateData: "Brand",
          selectTypeData: brands,
          loading: false,
          // selectedBrands: singlePost
        },
      });
    }
  };
  return (
    <Fragment>
      <div className="col-xl-15">
        <div className="blog-wrap-2 mb-30">
          <div className="blog-img-2">
            <div onClick={() => handleBrandClick(brand._id)}>
              <img
                // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2cz_ed5QM0comdAn2NqxYYA74vdUxp0BTIg&usqp=CAU"
                src={brand.image}
                alt=""
              />
            </div>
          </div>
          <div className="blog-content-2">
            <h4>
              <div onClick={() => handleBrandClick(brand._id)}>
                {brand.name}
              </div>
            </h4>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BrandsColumn;
