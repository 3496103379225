import React, { useEffect } from 'react'
import { Tooltip, message} from "antd";
import { useDispatch, useSelector } from 'react-redux';
import {  getPOdatabyId } from "../../../redux/actions/poOrderAction";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from "../../../assets/images/Daga_Impex_Logo.png"
import { FileDoneOutlined } from "@ant-design/icons";
import axios from 'axios';
import { backend_uri_server } from '../../../util/constants';
const VerifyPoBill = ({record, text}) => {
 

const createPdf = async () => {
  try {
      const response = await axios.post(
          `${backend_uri_server}/api/v1/poDetailsBypoid`,
          { poId: record.poId }
      );

      const poBillData = response.data;


      if (poBillData && poBillData.data) {
          const doc = new jsPDF();

          const logoUrl = logo; 

          const img = new Image();
          img.src = logoUrl;
          img.onload = function() {
              const imgWidth = 25;
              const imgHeight = 25;
              const imgX = doc.internal.pageSize.getWidth() - imgWidth - 10; 
              const imgY = 8;

              doc.addImage(img, 'PNG', imgX, imgY, imgWidth, imgHeight);

              doc.setFontSize(16);
              doc.setFont("helvetica", "bold");
              var text = "Purchase Order Details";

              var pageWidth = doc.internal.pageSize.getWidth();

              doc.text(text, pageWidth / 2, 21, { align: 'center' });

              var textWidth = doc.getTextWidth(text);

              var startX = (pageWidth - textWidth) / 2;
              var endX = startX + textWidth;
              var y = 23; 

              doc.line(startX, y, endX, y);

              doc.setFontSize(12);

              doc.setFont("helvetica", "bold");
              doc.text("Dealer Name : ", 15, 35);
              doc.text("PO Number : ", 15, 45);

              doc.setFont("helvetica", "normal");
              doc.text(`${poBillData.data[0].dealerName}`, 47, 35);
              doc.text(`${poBillData.data[0].poId}`, 44, 45);

              let finalArray = [];
              poBillData.data.forEach(item => {
                  finalArray.push([item.designNumber, item.brandName, item.sizeName, item.quantity]);
              });

              doc.autoTable({
                  startY: 55,
                  head: [['Design Number', 'Brand Name', 'Size Name', 'Quantity']],
                  body: finalArray,
                  theme: 'striped',
                  styles: {
                      fontSize: 10,
                      cellPadding: 2,
                      overflow: 'ellipsize',
                      halign: "center",
                      halign: 'center',
                      lineWidth: 0.1,
                      lineColor: [0, 0, 0]
                  },
                  columnStyles: {
                      0: { halign: 'center', overflow: 'ellipsize' },
                      1: { halign: 'center', overflow: 'ellipsize' },
                  },
              });

              doc.save(`${poBillData?.data[0]?.poId}.pdf`);
          };
      }
  } catch (error) {
   
    console.error("Error fetching PO data:", error);
     
  }
};

  return (
   <>
     <button className="btn" type="button" onClick={createPdf}>
      <Tooltip title="Download PO Products Bill">
        <FileDoneOutlined />
      </Tooltip>
    </button>
   </>
  )
}

export default VerifyPoBill