import React, { Component } from "react";
import { Row, Col, Breadcrumb, AutoComplete, Button } from "antd";
import DefectedProductBillList from "./defectedProductBillList";
import DateWiseSearch from "../../../../../util/DateWiseSearch";
export default class defectedProductBill extends Component {
  render() {
    return (
      <div className="subCategory-dashboard">
        <div className="container-fluid category-list">
          {/* ==============Search Bar and Date Filter================= */}
          {/* <Row className="title-row">
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <div className="page-header">Defected Product Bill</div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Search title="Defective Return Bill" placeholder="Customer Name, Sale Return Id, Barcode" 
            searchopt={searchOptions} 
            changeFunction={this.onChange} 
            cancelState={this.cancelState} 
            location={this.props.location} />
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <DateWiseSearch propsHistory={this.props.history} keyId={"Defected Product Bill"} />
            </Col>
          </Row> */}
          {/* ======================================== */}
          <DefectedProductBillList />
        </div>
      </div>
    );
  }
}
