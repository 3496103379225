import axios from "axios";
import { uuid } from "uuidv4";
import React from "react";
import { backend_uri_server, backend_uri_local } from "../../util/constants";
export const ADD_TO_CART = "ADD_TO_CART";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const INCREASE_QUANTITY = "INCREASE_QUANTITY";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const DELETE_ALL_FROM_CART = "DELETE_ALL_FROM_CART";
export const GET_CART = "GET_CART";


//add to cart
export const addToCart = ({ item, addToast, quantityCount, selectedProductColor, selectedProductSize, selectedProductMOQ, wsp, salesDiscount, selectedProductId }) =>

  async (dispatch) => {
    let variation = item.variation;
    let f = variation.length;
    let e = 0;
    let productId;
    const userIdUser = localStorage.getItem("userId")

    while (e < f) {
      if (variation[e].color === selectedProductColor) {
        let size = variation[e].size;
        productId = size.findIndex(
          (element) => element.name == selectedProductSize
        );
        productId = size[productId]._id;
        break;
      }
      ++e;
    }

    const cartItemId = productId


    let response = await axios.patch(`${backend_uri_server}/api/v1/cart`, {
      cartItemId: cartItemId,
      // userId: "624b5c5c84da52201ccfefc8",
      userId: userIdUser,
      productId,
      quantityCount,
    });


    if (addToast) {
      const customToast = (
        
        <div style={{ display: "flex", alignItems: "center" }}>
         {console.log("item", item)}
          <img
            src={process?.env?.PUBLIC_URL + item?.image[0]}
            alt="Your Image"
            style={{ width: "80px", height: "80px", marginRight: "10px" }}
          />
         
         <div>
            <p>Added To Cart</p>
              <span>design No. : {item?.name}</span>
              <br/>
              <span>brand : {item?.brand}</span>
              <br/>
              <span>Price : {item?.price}</span>
            </div>    
        </div>
      );

      addToast(customToast, {
        appearance: "success",
        autoDismiss: true,
        // placement: "top-center" // Change toast placement here
      });

      // addToast("Added To Cart11", { appearance: "success", autoDismiss: true });
    }
    dispatch({
      type: ADD_TO_CART,
      payload: {
        ...item,
        cartItemId: cartItemId,
        quantity: quantityCount,
        selectedProductColor: selectedProductColor
          ? selectedProductColor
          : item.selectedProductColor
            ? item.selectedProductColor
            : null,
        _id: productId ? productId : "",
        selectedProductSize: selectedProductSize
          ? selectedProductSize
          : item.selectedProductSize
            ? item.selectedProductSize
            : null,
        selectedProductMOQ: selectedProductMOQ ? selectedProductMOQ : item.selectedProductMOQ ? item.selectedProductMOQ : null,
        wsp: wsp,
        salesDiscount: salesDiscount,
        selectedProductId: selectedProductId
      },
    });
    return response
  };



//get all cart data
export const getFullCart = (user, history) => async (dispatch) => {
  let response = await axios.get(`${backend_uri_server}/api/v1/cart`);
  if (Array.isArray(response.data)) {
    dispatch({
      type: GET_CART,
      payload: response.data,
    });
  } else {
    dispatch({
      type: GET_CART,
      payload: [],
    });
  }
};


//decrease from cart
export const decreaseQuantity = (item, addToast) => {
  return (dispatch) => {
    // if (addToast) {
    //   addToast("Item Decremented From Cart", {
    //     appearance: "warning",
    //     autoDismiss: true,
    //   });
    // }
    dispatch({ type: DECREASE_QUANTITY, payload: item });
  };
};

//increase from cart
export const increaseQuantity = (item, addToast) => {
  return (dispatch) => {
    // if (addToast) {
    //   addToast("Item Incremented From Cart", {
    //     appearance: "warning",
    //     autoDismiss: true,
    //   });
    // }
    dispatch({ type: INCREASE_QUANTITY, payload: item });
  };
};

//delete from cart
export const deleteFromCart = (item, addToast) => {


  return async (dispatch) => {
    await axios.delete(`${backend_uri_server}/api/v1/cart/${item._id}`);
    // if (addToast) {
    //   addToast("Removed From Cart", { appearance: "error", autoDismiss: true });
    // }
    dispatch({ type: DELETE_FROM_CART, payload: item });

  };
};
//delete all from cart
export const deleteAllFromCart = (addToast) => {
  return async (dispatch) => {
    await axios.delete(`${backend_uri_server}/api/v1/cart`);
    if (addToast) {
      addToast("Removed All From Cart", {
        appearance: "error",
        autoDismiss: true,
      });
    }
    dispatch({ type: DELETE_ALL_FROM_CART });
  };
};

// get stock of cart item
export const cartItemStock = (item, color, size) => {
  if (item.stock) {
    return item.stock;
  }
  else {
    return item.variation
      .filter((single) => single.color === color)[0]
      .size.filter((single) => single.name === size)[0].stock;
  }
  return 1;
};
