import React, { Component } from "react";
import { Row, Col, Tooltip, Form, Select, DatePicker, Button } from "antd";
import PurchaseReturnReportList from "./PurchaseReturnReportList";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { getBrands } from "../../../../redux/actions/brandAction";
import { getDealers } from "../../../../redux/actions/dealerAction";
import { getPurchaseReturnReports } from "../../../../redux/actions/reportsActions";
import TopBarProgress from "react-topbar-progress-indicator";
import { debounce } from "lodash";
import BackButton from "../../Report/Button";
const dateFormat = "YYYY-MM-DD";
const { Option } = Select;

class PurchaseReturnReport extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      endDate: moment(new Date()).format("YYYY-MM-DD"),
      startDate: "",
      dealerName: "",
      dealerId: "",
      reportAllObject: {}
    };
    this.debouncedGetData = debounce(this.getData, 500);
  }
  componentDidMount() {
    this.props.getDealers();
  }

  getData = (name) => {
    if (name == "dealer") this.props.getDealers();
  };
  handleChangeDealer = (key, value) => {
    let dealerId = value.value;
    let dealerName = value.name;
    this.setState({ dealerId, dealerName });
    // this.debouncedGetData("dealer");
  };

  onStartDateChange = (date, dateString) => {
    this.setState({ startDate: dateString });
  };
  onEndDateChange = (date, dateString) => {
    this.setState({ endDate: dateString });
  };
  showDataOnList = async () => {
    try {
      let today = moment(new Date()).format("YYYY-MM-DD");
      let reportObj = {};
      if (today == this.state.endDate) {
        reportObj = {
          dealerId: this.state.dealerId,
          startDate: this.state.startDate,
          endDate: this.state.endDate + " " + moment().format("HH:mm:ss"),
          limit: 1,
          perPage: 30,
          type: "perpage",
        };
      } else {
        const dateMoment = moment(this.state.endDate, "YYYY-MM-DD");
        const endTime = dateMoment.endOf("day").format("HH:mm:ss");
        const endDateWithEndTime = `${this.state.endDate} ${endTime}`;
        reportObj = {
          dealerId: this.state.dealerId,
          startDate: this.state.startDate,
          endDate: endDateWithEndTime,
          perPage: 30,
          limit: 1,
          type: "perpage",
        };
      }
      
      const initialReport = await this.props.getPurchaseReturnReports(
        reportObj
      );
      console.log("Initial report data:", initialReport);

      const reportObjAll = {
        dealerId: this.state.dealerId,
        startDate: this.state.startDate,
        endDate: reportObj.endDate,
        limit: 1,
        perPage: initialReport?.count,
        type: "all",
      };
      this.setState({reportAllObject : reportObjAll})
      // await this.props.getPurchaseReturnReports(reportObjAll);
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  render() {
    let dealerOptions = this.props.dealers.map((dealer) => {
      return (
        <Option name={dealer.name} value={dealer._id}>
          {dealer.name}
        </Option>
      );
    });

    return (
      <div className="subCategory-dashboard">
        {this.props.progressBarStatus && <TopBarProgress />}
        <div className="container-fluid category-list">
          <Row className="title-row">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row gutter={24}>
                <Col span={12}>
                  <div className="page-header">Purchase Return Report</div>
                </Col>
                <Col span={12}>
                  <BackButton />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form
                ref={this.formRef}
                name="add-Purchase"
                className="add-Purchase"
                onFinish={this.showDataOnList}
                initialValues={{ remember: true }}
              >
                <Row gutter={[16, 0]} className="mt-4">
                  <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Form.Item name="dealerName">
                      <Select
                        showSearch
                        placeholder="Select Dealer"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                        onChange={this.handleChangeDealer}
                        // onClick={() => this.debouncedGetData("dealer")}
                        // onInputKeyDown={() => this.debouncedGetData("dealer")}
                      >
                        {dealerOptions}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                    <Tooltip title="Select Start Date">
                      <Form.Item
                        name="startDate"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Start Date !",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          onChange={this.onStartDateChange}
                          placeholder="Select Start Date"
                          format={dateFormat}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                    <Tooltip title="Select End Date">
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Please Select End Date !",
                          },
                        ]}
                        name="endDate"
                        initialValue={moment()}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          onChange={this.onEndDateChange}
                          placeholder="Select End Date"
                          format={dateFormat}
                          disabledDate={(current) => {
                            return current && current > Date.now();
                          }}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>

                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="close-modal"
                        block
                      >
                        Search
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <PurchaseReturnReportList
            reports={this.props.reports}
            allreports={this.props.allreports}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            dealerName={this.state.dealerName}
            reportObj={{
              dealerId: this.state.dealerId,
              startDate: this.state.startDate,
              endDate: this.state.endDate + " " + moment().format("HH:mm:ss"),
            }}
            getPurchaseReturnReports={this.props.getPurchaseReturnReports}
            reportObjAll = {this.state.reportAllObject}
          />
        </div>
      </div>
    );
  }
}
PurchaseReturnReport.propTypes = {
  getDealers: PropTypes.func.isRequired,
  getPurchaseReturnReports: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dealers: state.dealersData.dealers,
  reports: state.reportData.purchaseReturnreports,
  allreports: state.reportData.allpurchaseReturnreports,
  progressBarStatus: state.progressBarReducer.showProgressBar,
});

export default connect(mapStateToProps, {
  getDealers,
  getBrands,
  getPurchaseReturnReports,
})(PurchaseReturnReport);