import React, { Component } from "react";
import { Table, Button, Space, Input, Tag } from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";
import Papa from "papaparse";
import { CSVLink } from "react-csv";
const { Column } = Table;
const data = [];

export default class DealerPurchaseReportList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      options: [],
      loading: false,
      csvData: [],
    };
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  this.setState({
                    searchText: selectedKeys[0],
                    searchedColumn: dataIndex,
                  });
                }}
              >
                Filter
              </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };

  render() {
    const { reports } = this.props;
    const headers = [
      { label: "Bill No", key: "billNo" },
      { label: "Bill Date", key: "billDate" },
      { label: "Purchase ID", key: "purchaseId" },
      { label: "Creation Date", key: "created_at" },
      { label: "Dealer", key: "dealerName" },
      { label: "Qty", key: "quantity" },
      { label: "Total Amount", key: "totalAmount" },
      { label: "Discount", key: "discount" },
      { label: "Tax", key: "tax" },
      { label: "Net Amount", key: "netAmount" },
    ];
    
    const handleDownloadButtonClick = async () => {
      this.setState({ loading: true });

      try {
        // Call your API here
        const response = await this.props.getDealerPurchsesReports(
          this.props?.reportObjAll
        );
        const csvData = response?.details;
        // Generate CSV content using PapaParse
        const csv = Papa.unparse({
          fields: headers?.map((header) => header.label),
          data: csvData?.map((row) =>
            headers?.map((header) => {
              if(header.key=="created_at"){
                return moment(row.created_at).format('YYYY-MM-DD');
              }
              return row[header.key]})
          ),
        });

        // Create Blob and initiate file download
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${this.props?.startDate} to ${this.props?.endDate}.csv`;
        link.click();
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error(error);
      } finally {
        this.setState({ loading: false });
      }
    };

    return (
      <>
        <div className="card final-purchases-list">
          <div className="card-body">
            <Table
              dataSource={
                reports && reports.details?.length > 0
                  ? reports.details.reverse()
                  : []
              }
              bordered="true"
              size="middle"
              pagination={{
                pageSize: 30,
                total: reports?.count,
                onChange: (page) => {
                  this.props.getDealerPurchsesReports({
                    ...this.props.reportObj,
                    endDate: this.props?.reportObjAll?.endDate,
                    limit: page,
                    perPage: 30,
                  });
                },
              }}
              scroll={{ y: 240 }}
            >
              <Column
                title="Sr. No."
                key="index"
                render={(value, item, index) => index + 1}
                width={80}
              />
              <Column
                title="Bill No"
                dataIndex="billNo"
                key="billNo"
                width={110}
              />
              <Column
                title="Bill Date"
                dataIndex="billDate"
                key="billDate"
                width={110}
              />
              <Column
                title="Purchase ID"
                dataIndex="purchaseId"
                key="purchaseId"
                width={130}
              />
              <Column
                title="Creation Date"
                dataIndex="created_at"
                key="created_at"
                render={(text) => moment(text).format("DD-MM-YYYY")}
                width={140}
              />
              <Column
                title="Dealer"
                dataIndex="dealerName"
                key="dealerName"
                width={150}
                {...this.getColumnSearchProps("dealerName")}
              />
              <Column
                title="Qty"
                dataIndex="quantity"
                key="quantity"
                width={100}
              />
              <Column
                title="Total Amount"
                dataIndex="totalAmount"
                key="totalAmount"
                width={160}
              />
              <Column
                title="Discount"
                dataIndex="discount"
                key="discount"
                width={100}
              />
              <Column title="Tax" dataIndex="tax" key="tax" width={100} />
              <Column
                title="Net Amount"
                dataIndex="netAmount"
                key="netAmount"
                width={120}
              />
            </Table>
          </div>
        </div>
        {(() => {
          if (this.props?.reports?.count > 0) {
            return (
              <>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="save-modal mt-4"
                  block
                  onClick={handleDownloadButtonClick}
                >
                  {this.state.loading ? "Loading..." : "Download CSV"}
                </Button>
              </>
            );
          }
        })()}
      </>
    );
  }
}
