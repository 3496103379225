//created by aaditya-
import React, { Component } from "react";
import { Table, Button, Space} from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TopBarProgress from "react-topbar-progress-indicator";
import { withRouter } from "react-router-dom";
import { getLocations } from "../../../redux/actions/locationAction";
import { inventoryList } from "../../../redux/actions/warehouseActions";
const { Column } = Table;

class WarehouseList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      options: [],
      searchValue: "",
    };
  }
  componentDidMount() {
    this.props.getLocations();
  }

  //  redirection to warehouse inventory list
  handleInvetoriesClickRouting = (id) => {
    this.props.history.push({
      pathname: "/dagaImpex/warehouse/inventories",
      state: id,
    });
  };

  //  redirection to warehouse sales out list
  handleSalesOutClickRouting = (id) => {
    this.props.history.push({
      pathname: "/dagaImpex/warehouse/sales-out",
      state: id,
    });
  }

  //  redirection to warehouse purchase bill
    handle_W_H_PurchaseBillRouting = (id) => {
    this.props.history.push({
      pathname: "/dagaImpex/warehouse/warehouse-purchase-bill",
      state: id,
    });
  };

  render() {
    const { locations } = this.props;

    //To filter locations only of warehouses
    const filteredLocations = locations?.filter(
      (location) => location?.locationType === 1
    );

    return (
      <div>
        <div className="card mt-4 final-purchases-list">
          {this.props.progressBarStatus && <TopBarProgress />}
          <div className="card-body">
            <Table
              dataSource={filteredLocations}
              bordered="true"
              size="middle"
              //   pagination={{
              //     pageSize: 100,
              //     total: searchValue && search.length > 0 ?  search.length :  inventory?.totalCount ,
              //     onChange: (page) => {
              //       this.props.getInventory(page);
              //     },
              //   }}
              scroll={{ y: 240 }}
            >
              <Column
                title="SRN"
                key="index"
                render={(value, item, index) => index + 1}
                width={60}
              />
              <Column
                title="Warehouse"
                dataIndex="name"
                key="warehouse"
                width={500}
                a
              />
              <Column
                title="Actions"
                key="actions"
                width={500}
                render={(text, record) => (
                  <Space size="middle">
                    
                    <Button
                      type="primary"
                      onClick={() => {
                        this.handle_W_H_PurchaseBillRouting(record._id);
                        console.log("rec", record._id);
                      }}
                      className="close-modal"
                    >
                      Purchase Bill
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => {
                        this.handleInvetoriesClickRouting(record._id);
                      }}
                    >
                      Inventory List
                    </Button>

                    <Button
                      type="primary"
                      onClick={() => {
                        this.handleSalesOutClickRouting(record._id);
                        console.log("rec", record._id);
                      }}
                    >
                      Sales Out List
                    </Button>
                  </Space>
                )}
              />
            </Table>
          </div>
        </div>
      </div>
    );
  }
}

WarehouseList.propTypes = {
  getLocations: PropTypes.func.isRequired,
  inventoryList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  locations: state.locationData.locations,
});

export default withRouter(
  connect(mapStateToProps, { getLocations, inventoryList })(WarehouseList)
);
