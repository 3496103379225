import React, { Component } from "react";
import { Table, Button, Space, Input, Tag, Row, Col } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { CSVLink } from "react-csv";
import ShowsalesPersonReport from "./ShowsalesPersonReport";
import moment from "moment";
const { Column } = Table;

export default class SalesPersonComparision extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      options: [],
    };
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  this.setState({
                    searchText: selectedKeys[0],
                    searchedColumn: dataIndex,
                  });
                }}
              >
                Filter
              </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };
  
  render() {
    const { reports } = this.props
    const reportsWithSerialNo =  reports && reports.length>0 ? reports.map((report, index) => ({
      ...report,
      serialNo: index + 1, // Adding 1 to index since serial numbers typically start from 1
    })) : []
    const headers = [
      { label: "SRN", key: "serialNo" },
      { label: "Sales Person Name	", key: "nameUser" },
      { label: "Date", key: "dateOfSale" },
      { label: "Quantity", key: "quantity" },
      { label: "Amount", key: "amount" },
      { label: "Boxes", key: "boxes" },
    ];

    return (
      <>
        <div className="card mt-3 final-purchases-list">
          {console.log("check final data", this.props.reports)}
          <div className="card-body">
            <Table
              dataSource={reports && reports.length > 0 ? reports.reverse() : []}
              bordered="true"
              size="middle"
              pagination={false}
              scroll={{ y: 240 }}
            >
               <Column
                title="SRN"
                key="index"
                render={(value, item, index) => index + 1}
                width={60}
              />
              <Column
                title="Date"
                dataIndex="dateOfSale"
                key="dateOfSale"
                width={120}
                render={(value, item, index) =>
                  moment(item.created_at).format("YYYY-MM-DD")
                }
              />
              <Column title="Sales Person Name" dataIndex="nameUser" key="nameUser" width={100} />
              <Column title="Quantity" dataIndex="quantity" key="quantity" width={100} />

              <Column
                title="Amount"
                dataIndex="amount"
                key="amount"
                width={110}
                {...this.getColumnSearchProps("amount")}
              />
              <Column
                title="Boxes"
                dataIndex="boxes"
                key="boxes"
                {...this.getColumnSearchProps("boxes")}
                width={120}
              />

              <Column
                title="Action"
                key="action"
                width={80}
                render={(text, record) => (
                  <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <ShowsalesPersonReport
                        salesPersonId={text.salesPersonId}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                      />
                    </Col>
                  </Row>
                )}
              />


            </Table>
          </div>
        </div>
        {(() => {
          if (this.props?.reports?.length > 0) {
            return (
              <Button
                type="primary"
                htmlType="submit"
                className="save-modal mt-4"
                block
              >
                <CSVLink
                  filename={`${this.props.startDate} to ${this.props.endDate}.csv`}
                  data={reportsWithSerialNo}
                  headers={headers}
                >
                  Download CSV
                </CSVLink>
              </Button>
            );
          }
        })()}
      </>
    );
  }
}
