import React, { useState, useEffect, useRef } from "react";
import { Table, Popconfirm, Tooltip, Button, Input, Space, message } from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Highlighter from "react-highlight-words";
import TopBarProgress from "react-topbar-progress-indicator";
import { deleteTexture, getTexture } from "../../../../redux/actions/textureAction";
import { useDispatch, useSelector } from "react-redux";
import UpdateTexture from "./UpdateTexture";

const { Column } = Table;
const TextureList = () => {
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);

    let texturesData = useSelector((state) => state.textureData?.textures?.data || []);

    if (!Array.isArray(texturesData)) {
        texturesData = [texturesData];
    }

    useEffect(() => {
        dispatch(getTexture());
    }, [dispatch]);

    const onDeleteClick = (id) => {
        const obj = {
            textureId: id,
        };
        dispatch(deleteTexture(obj)).then(() => {
            dispatch(getTexture());
        }); 
    };

    return (
        <div className="card mt-4">
            {/* {<TopBarProgress />} */}
            <div className="card-body">
                <Table dataSource={texturesData} bordered="true" size="middle" pagination={false}>
                    <Column title="Name" dataIndex="name" key="name" />
                    <Column
                        title="Action"
                        key="action"
                        render={(text, record) => (
                            <div>
                                <a>
                                    <UpdateTexture textures={text} />
                                </a>

                                <Popconfirm
                                    title="Are you sure to delete this Texture?"
                                    onConfirm={() => onDeleteClick(text._id)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <div className="btn">
                                        <Tooltip title="Delete Texture">
                                            <DeleteOutlined />
                                        </Tooltip>
                                    </div>
                                </Popconfirm>
                            </div>
                        )}
                    />
                </Table>
            </div>
        </div>
    );
};



export default TextureList;

