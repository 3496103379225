import React, { Component } from "react";
import { Table, Button, Space, Input, Tag } from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
// import { getTopCustomerReports } from "../../../../redux/actions/getTopCustomerReports";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { CSVLink } from "react-csv";
const { Column } = Table;
const data = [];

export class StockReportList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      data: [],
    };
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  this.setState({
                    searchText: selectedKeys[0],
                    searchedColumn: dataIndex,
                  });
                }}
              >
                Filter
              </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };

  render() {
    const { reports } = this.props;
    const reportsArr = reports?.data;
    const headers = [
      { label: "Dealer Name", key: "dealerName" },
      { label: "Barcode", key: "barcode" },
      { label: "Design No", key: "designNumber" },
      { label: "Brand", key: "brand" },
      { label: "Size", key: "size" },
      { label: "Color", key: "color" },
      { label: "Category", key: "category" },
      { label: "Style", key: "style" },
      { label: "Floor", key: "floorName" },
      { label: "Section", key: "section" },
      { label: "Season", key: "season" },
      { label: "MOQ", key: "MOQ" },
      { label: "Stock(QTY)", key: "stock" },
      { label: "Purchase Price", key: "purchasePrice" },
      { label: "WSP", key: "wsp" },
      { label: "Total Purchase", key: "totalPurchase" },
      { label: "Total Sale", key: "tatalSale" },
    ];

    return (
      <>
        <div>
          <div>
            <Table
              dataSource={reports?.data}
              // dataSource={  []}
              bordered="true"
              size="middle"
              scroll={{ y: 240 }}
              pagination={false}
              //   pagination={{
              //     pageSize: 10,
              //     total: this.props.reports.count,
              //     onChange: (page) => {
              //       this.props.getStockAgeingReports(page);
              //     },
              //   }}
            >
              <Column
                title="SRN"
                key="index"
                render={(value, item, index) => index + 1}
                width={60}
              />
              <Column
                title="Dealer Name"
                {...this.getColumnSearchProps("dealerName")}
                dataIndex="dealerName"
                key="dealerName"
                width={160}
              />
              <Column
                title="Barcode"
                {...this.getColumnSearchProps("barcode")}
                dataIndex="barcode"
                key="barcode"
                width={130}
              />
              <Column
                title="Design No"
                dataIndex="designNumber"
                key="designNumber"
                width={140}
              />
              <Column title="Brand" dataIndex="brand" key="brand" width={100} />
              <Column title="Size" dataIndex="size" key="size" width={100} />
              <Column title="Color" dataIndex="color" key="color" width={100} />
              <Column
                title="Category"
                dataIndex="category"
                key="category"
                width={120}
              />
              <Column title="Style" dataIndex="style" key="style" width={100} />
              <Column
                title="Floor"
                dataIndex="floorName"
                key="floorName"
                width={100}
              />
              <Column
                title="Section"
                dataIndex="section"
                key="section"
                width={120}
              />
              <Column
                title="Season"
                dataIndex="season"
                key="season"
                width={120}
              />
              <Column title="MOQ" dataIndex="MOQ" key="MOQ" width={100} />
              <Column
                title="Stock(QTY)"
                dataIndex="stock"
                key="stock"
                width={100}
              />
              <Column
                title="Purchase Price"
                dataIndex="purchasePrice"
                key="purchasePrice"
                width={150}
              />
              <Column title="WSP" dataIndex="wsp" key="wsp" width={140} />
              <Column
                title="Total Purchase"
                dataIndex="totalPurchase"
                key="totalPurchase"
                width={150}
              />
              <Column
                title="Total Sale"
                dataIndex="tatalSale"
                key="tatalSale"
                width={140}
              />
              {/* <Column
                title="Bill No"
                dataIndex="billNo"
                key="billNo"
                width={140}
              />

              <Column title="Total Amount" dataIndex="wsp" key="brandId" width={140}
                render={(value, item, index) => Number(item?.wsp) * Number(item?.stock)}
              /> */}
            </Table>
          </div>
        </div>
        {(() => {
          if (reports?.data?.length > 0) {
            return (
              <Button
                type="primary"
                htmlType="submit"
                className="save-modal mt-4"
                block
              >
                <CSVLink
                  filename={`stockReport.csv`}
                  data={reportsArr}
                  headers={headers}
                >
                  Download CSV
                </CSVLink>
              </Button>
            );
          }
        })()}
      </>
    );
  }
}

StockReportList.propTypes = {
  // getTopCustomerReports: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  salesAll: state.reportData.salesAll,
});

export default connect(mapStateToProps, {
  // getTopCustomerReports,
})(StockReportList);
