import React from "react";
import {
  Table,
  Pagination,
  Row,
  Col,
  Switch,
  Select,
  Input,
  Button,
  Tooltip,
  DatePicker,
  Form,
  Popconfirm,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getEcomSalebillList,
  updateOrderStatus,
  creditPaymentBySearchValue,
  creditPaymentByDateRange,
} from "../../../redux/actions/ecomSaleAction";
import moment from "moment";
import ShowEcomSaleProducts from "./ShowEcomSaleProducts";
import ShowCreditEdit from "./ShowCreditEdit";
import MergeBillModal from "./Merge Bill Ecom/MergeBillEcom";
import EcomCreditQuantityEdit from "./EcomCreditQuantityEdit";
import DownloadSalePDF from "../sales/DownloadSalePDF";
import DownloadEcomPDF from "./DownloadEcomPDF";
import { message } from "antd";
import {
  CloseOutlined,
  SearchOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Option } from "antd/lib/mentions";
import Swal from "sweetalert2";
const { Column } = Table;
const searchOptions = ["Barcode", "SalesId", "CustomerName", "agentName"];

const dateFormat = "YYYY-MM-DD";

const EcomCreditPaymentList = () => {
  const dispatch = useDispatch();
  const ecomData = useSelector((state) => state.ecomSaleData.ecomSalebill);
  const ecomData2 = useSelector(
    (state) => state.ecomSaleData.creditPaymentBySearchValue
  );
  const ecomData3 = useSelector(
    (state) => state.ecomSaleData.creditPaymentByDateRange
  );
  const [responseData, setResponseData] = useState(null);
  const [selectedOption, setSelectedOption] = useState("Barcode");
  const [inputValue, setInputValue] = useState("");
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [tableData, setTableData] = useState();
  const [currentFilter, setCurrentFilter] = useState("dateRange");

  useEffect(() => {
    if (currentFilter === "dateRange" && ecomData3?.details?.length > 0) {
      setTableData(ecomData3.details);
    } else if (currentFilter === "search" && ecomData2?.details?.length > 0) {
      setTableData(ecomData2.details);
    }else {
      setTableData([]);
    }
  }, [ ecomData2, ecomData3, currentFilter]);

  const handleChange = (value) => {
    setSelectedOption(value);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSearch = () => {
    setCurrentFilter("search");
    setTableData("");
    let obj = {
      type: selectedOption,
      searchValue: inputValue,
      paymentMethod: 'Credit User'
    };
    dispatch(creditPaymentBySearchValue(obj));
  };

  const handleDateRangeSearch = () => {

    if (startDate && endDate) {
      setCurrentFilter("dateRange");
      let today = moment(new Date()).format("YYYY-MM-DD")
      let reportObj
      if (today == endDate) {
        reportObj = {
          startDate: startDate,
          endDate: endDate + " " + moment().format("HH:mm:ss"),
          paymentMethod: 'Credit User'
        };
      } else {
        const dateMoment = moment(endDate, 'YYYY-MM-DD');
        const endTime = dateMoment.endOf('day').format('HH:mm:ss');
        const endDateWithEndTime = `${endDate} ${endTime}`;
        reportObj = {
          startDate: startDate,
          endDate: endDateWithEndTime,
          paymentMethod: 'Credit User'
        };
      }
      dispatch(creditPaymentByDateRange(reportObj)).then(obj => {
        console.log("check mohit obj", obj)
      })
    } else {
      message.error("Please Select Start & End Date!")
    }
  };

  const handleReset = () => {
    setCurrentFilter("dateRange");
    setSelectedOption("Barcode");
    setInputValue("");
    setStartDate(moment().format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));
    setTableData(ecomData3?.data || []);
  };

  const onStartDateChange = (date, dateString) => {
    setStartDate(dateString);
  };

  const onEndDateChange = (date, dateString) => {
    setEndDate(dateString);
  };

  const resetFunction = async () => {
    let startDate = moment(new Date()).format("YYYY-MM-DD");
    let endDate = moment(new Date()).format("YYYY-MM-DD");
    setStartDate(startDate);
    setEndDate(endDate);
    setTableData(ecomData3?.data || []);
  };

  const [data, setData] = useState({
    billType: "Credit User",
  });
  const [pagination, setPagination] = useState({ current: 1 });

  const getList = (limit) => {
    let obj = {
      billType: "Credit User",
      limit: limit,
      perPage: 10,
      paymentMethod: 'Credit User'
    };
    dispatch(getEcomSalebillList(obj));
  };

  useEffect(() => {
      setCurrentFilter("dateRange");
      let today = moment(new Date()).format("YYYY-MM-DD")
      let reportObj
      if (today == endDate) {
        reportObj = {
          startDate: startDate,
          endDate: endDate + " " + moment().format("HH:mm:ss"),
          paymentMethod: 'Credit User'
        };
      } else {
        const dateMoment = moment(endDate, 'YYYY-MM-DD');
        const endTime = dateMoment.endOf('day').format('HH:mm:ss');
        const endDateWithEndTime = `${endDate} ${endTime}`;
        reportObj = {
          startDate: startDate,
          endDate: endDateWithEndTime,
          paymentMethod: 'Credit User'
        };
      }
      dispatch(creditPaymentByDateRange(reportObj)).then(obj => {
        console.log("check mohit obj", obj)
      })
  }, []);

  const handlePaginationChange = (page) => {
    setPagination({ current: page });
  };

  const onStatusChange = (e, value) => {
    if (value == true) {
      Swal.fire({
        title:
          "Warning",
        text: "If Order is delivered then it cannot be changed.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(() => {

        dispatch(updateOrderStatus({ ecomsalesId: e.ecomsalesId }));

        let billListObj = {
          billType: data.billType,
          limit: pagination.current,
          perPage: 10,
        };
        dispatch(getEcomSalebillList(billListObj));
      });
    }
  };

  useEffect(() => {
    return () => {
      dispatch(getEcomSalebillList("reset"));
    };
  }, []);

  return (
    <>
      <Row className="title-row">
        <Col sm={12} md={12} lg={12} className="w-50">
          <div className="page-header">Credit Payment</div>
        </Col>
        <Col sm={12} md={12} lg={12} className="w-50" align="end">
          <MergeBillModal />
        </Col>
      </Row>

      <Row gutter={24} className="mt-3">
        <Col sm={24} md={24} lg={12}>
          <Row gutter={24}>
            <Col sm={24} md={6} lg={8} className="w-100 my-2">
              <Select
                value={selectedOption}
                onChange={handleChange}
                className="w-100"
              >
                {searchOptions.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col sm={24} md={10} lg={12} className="w-100 my-2">
              <Input
                placeholder="Sale Return Id, Barcode, Agent Name or CustomerName"
                value={inputValue}
                onChange={handleInputChange}
                onPressEnter={handleSearch}
              />
            </Col>
            <Col sm={12} md={4} lg={2} className="w-50 my-2">
              <Button
                type="primary"
                className="d-flex justify-content-center w-100"
                onClick={handleSearch}
              >
                <SearchOutlined style={{ fontSize: "20px" }} />
              </Button>
            </Col>
            <Col sm={12} md={4} lg={2} className="w-50 my-2">
              <Button
                type="primary"
                className="d-flex justify-content-center w-100"
                onClick={handleReset}
              >
                <CloseOutlined style={{ fontSize: "20px" }} />
              </Button>
            </Col>
          </Row>
        </Col>

        <Col sm={24} md={24} lg={12} align="end" className="mt-2">
          <Row gutter={24}>
            <Col sm={12} md={6} lg={6} className=" w-50">
              <Tooltip title="Select Start Date">
                <Form.Item
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    onChange={onStartDateChange}
                    placeholder="Select Start Date"
                    value={moment(startDate, dateFormat)}
                    format={dateFormat}
                    showClose={false}
                    name="startDate"

                  />
                </Form.Item>
              </Tooltip>
            </Col>
            <Col sm={12} md={6} lg={6} className=" w-50">
              <Tooltip title="Select End Date">
                <Form.Item
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    onChange={onEndDateChange}
                    placeholder="Select End Date"
                    value={moment(endDate, dateFormat)}
                    format={dateFormat}
                    name="endDate"

                  />
                </Form.Item>
              </Tooltip>
            </Col>
            <Col sm={12} md={6} lg={6} className=" w-50">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="close-modal"
                  block
                  onClick={handleDateRangeSearch}
                >
                  Search
                </Button>
              </Form.Item>
            </Col>
            <Col sm={12} md={6} lg={6} className=" w-50">
              <Form.Item>
                <Button type="primary" block onClick={resetFunction}>
                  Reset
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="card mt-2 final-purchases-list">
        <div className="card-body">
          <Table
            dataSource={tableData}
            bordered="true"
            size="middle"
            scroll={{ y: 300 }}
          // pagination={{
          //     pageSize: 10,
          //     total: ecomData ? ecomData?.count : 1,
          //     onChange: (page) => {
          //         console.log("page", page);
          //         handlePaginationChange(page)
          //     }
          // }}
          >
            <Column
              title="SRN"
              key="index"
              render={(value, item, index) => index + 1}
              width={60}
            />
            <Column
              title="Created Date"
              dataIndex="date"
              key="date"
              render={(value, item, index) =>
                moment(item.date).format("DD-MM-YYYY HH:mm:ss")
              }
              width={180}
            />
            <Column
              title="E-com sale Id"
              dataIndex="ecomsalesId"
              key="ecomsalesId"
              width={120}
              render={(value, item, index) => item.ecomsalesId}
            />
            <Column
              title="Store User Name"
              dataIndex="userId"
              key="userId"
              render={(value, item, index) => item.userId?.name}
              width={180}
            />

            <Column
              title="Net Amount"
              dataIndex="netAmount"
              key="netAmount"
              width={100}
              render={(value, item, index) => Number(item.netAmount).toFixed()}
            />
            <Column
              title="Gr Amount"
              dataIndex="grAmount"
              key="grAmount"
              render={(value, item, index) => item.userId?.grAmount}
              width={180}
            />
            <Column
              title="Total Amount"
              dataIndex="totalAmount"
              key="totalAmount"
              render={(value, item, index) =>
                Number(item.totalAmount).toFixed()
              }
              width={100}
            />
            <Column
              title="Total Quantity"
              dataIndex="totalQuantity"
              key="totalQuantity"
              width={100}
              render={(value, item, index) => item.totalQuantity}
            />
            <Column
              title="Total Boxes"
              dataIndex="totalBoxes"
              key="totalBoxes"
              width={100}
              render={(value, item, index) => item.totalBoxes}
            />
            <Column
              title="Address"
              dataIndex="address"
              key="address"
              render={(value, item, index) =>
                `${item.address[0].address}, ${item.address[0].city}(${item.address[0].pincode}), ${item.address[0].state}`
              }
              width={180}
            />
            <Column
              title="Payment Done"
              dataIndex="isPaymentDone"
              key="isPaymentDone"
              render={(value, item, index) =>
                item.isPaymentDone === true ? "Yes" : "No"
              }
              width={120}
            />
            <Column
              title="Order Status"
              dataIndex="orderStatus"
              key="orderStatus"
              render={(value, item, index) => {
                if (item.orderStatus === 0) {
                  return "Created";
                } else if (item.orderStatus === 1) {
                  return "Cancel";
                } else if (item.orderStatus === 2) {
                  return "Deliver";
                }
              }}
              width={120}
            />
            <Column
              title="Action"
              key="action"
              width={180}
              render={(text, record) => (
                <Row gutter={[8, 0]} style={{ placeContent: "center" }}>
                  <Col sm={8} md={8} lg={8}>
                    <ShowEcomSaleProducts ecomsalesId={text.ecomsalesId} />
                  </Col>
                  <Col sm={8} md={8} lg={8}>
                    <ShowCreditEdit
                      ecomsalesId={text.ecomsalesId}
                      userId={text.userId}
                      limit={pagination.current}
                    />
                  </Col>
                  <Col sm={8} md={8} lg={8}>
                    <EcomCreditQuantityEdit ecomsalesId={text.ecomsalesId} />
                  </Col>
                  {/* <Col sm={8} md={8} lg={6}>
                    <Popconfirm
                      title="Are you sure to delete this bill?"
                      onConfirm={() => console.log("Delete")}
                      okText="Yes"
                      cancelText="No"
                      disabled={
                        record?.remark === "Bill Deleted." ? true : false
                      }
                    >
                      <div className="btn">
                        <Tooltip title="delete Bill">
                          <DeleteOutlined />
                        </Tooltip>
                      </div>
                    </Popconfirm>
                  </Col> */}
                </Row>
              )}
            />
            <Column
              title="Order Deliver"
              key="Edit"
              align="center"
              width={150}
              render={(text, record) => (
                <Row gutter={[8, 0]} style={{ placeContent: "center" }}>
                  {record.orderStatus == 1 || (
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      {/* <UpdateOrderStatus records={text} billType = {data.billType} limit = {pagination.current} /> */}
                      <Switch
                        checked={record.orderStatus == 2 ? true : false}
                        onChange={(value) => onStatusChange(record, value)}
                      />
                    </Col>
                  )}
                </Row>
              )}
            />
            <Column
              title="Send PDF"
              key="updatedAt"
              width={210}
              render={(text, record) => {
                return (
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <DownloadEcomPDF
                        ecomsalesId={record.ecomsalesId}
                        record={record}
                        disabled={
                          record?.remark === "Bill Deleted." ? true : false
                        }
                      />
                    </Col>
                  </Row>
                );
              }}
            />
          </Table>{" "}
        </div>
      </div>
    </>
  );
};

export default EcomCreditPaymentList;