import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ecomOrderApprove, getEcomSaleListbybillId } from '../../../redux/actions/ecomSaleAction';
import { Table, Modal, Tooltip, Button, message } from 'antd';
import { PlusCircleOutlined, MinusCircleOutlined, EditOutlined } from '@ant-design/icons';
import gifLoader from '../../../assets/images/giphy.gif';

const { Column } = Table;

const EcomCreditQuantityEdit = (ecomsalesId) => {
    const dispatch = useDispatch();
    const ecomSaleData = useSelector((state) => state.ecomSaleData.ecomSaleData);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [quantities, setQuantities] = useState({});
    const [maxQuantities, setMaxQuantities] = useState({});
    const [moqs, setMoqs] = useState({});
    const [totalAmount, setTotalAmount] = useState()
    const [taxAmount, setTaxAmount] = useState()
    const [netAmount, setNetAmount] = useState()
    const [buttonLoading, setButtonLoading] = useState(false)

    useEffect(() => {
        if (ecomSaleData && ecomSaleData.data) {
            const updatedQuantities = {};
            const updatedMaxQuantities = {};
            const updatedMoqs = {};
            let calculatedTotalAmount = 0;

            ecomSaleData.data.forEach((item) => {
                updatedQuantities[item._id] = item.quantity;
                updatedMaxQuantities[item._id] = item.maxQuantity || item.quantity;
                updatedMoqs[item._id] = item.MOQ || 1;

                const itemPrice = Number(item?.wsp) - Number(item?.wsp) * (Number(item?.salesDiscount) / 100);
                const itemTotal = updatedQuantities[item._id] * itemPrice;
                calculatedTotalAmount += itemTotal;
            });
            const calTaxedAmount = calculatedTotalAmount.toFixed() * 5 / 100;
            let calNetAmount = calTaxedAmount + calculatedTotalAmount
            if(calculatedTotalAmount < 10000 && calculatedTotalAmount > 0){
                calNetAmount += 100
            }

            setQuantities(updatedQuantities);
            setMaxQuantities(updatedMaxQuantities);
            setMoqs(updatedMoqs);
            setTotalAmount(calculatedTotalAmount.toFixed());
            setTaxAmount(calTaxedAmount.toFixed())
            setNetAmount(calNetAmount.toFixed())
        }
    }, [ecomSaleData]);

    const showModal = () => {
        dispatch(getEcomSaleListbybillId(ecomsalesId));
        setVisible(true);
    };

    const handleModalCancel = () => {
        dispatch(getEcomSaleListbybillId("reset"));
        setVisible(false);
    };

    const onAddingQuantity = (item) => {
        setQuantities((prevQuantities) => {
            const currentQuantity = prevQuantities[item._id] || item.quantity;
            const maxQuantity = maxQuantities[item._id] || item.quantity;
            const moq = moqs[item._id] || Number(item.MOQ);

            if (currentQuantity < maxQuantity) {
                const newQuantity = Math.min(currentQuantity + moq, maxQuantity);
                const itemPrice = Number(item?.wsp) - Number(item?.wsp) * (Number(item?.salesDiscount) / 100);

                const updatedTotalAmount = Math.round(Number(totalAmount) + itemPrice * moq);
                const updatedTaxAmount = Math.round((updatedTotalAmount * 5) / 100);
                const updatedNetAmount = Math.round(updatedTotalAmount + updatedTaxAmount);

                setTotalAmount(updatedTotalAmount);
                setTaxAmount(updatedTaxAmount);
                setNetAmount(updatedNetAmount);

                return {
                    ...prevQuantities,
                    [item._id]: newQuantity,
                };
            }
            message.warning("Maximum Quantity Reached");
            return prevQuantities;
        });
    };


    const onRemoveQuantity = (item) => {
        setQuantities((prevQuantities) => {
            const currentQuantity = prevQuantities[item._id] || item.quantity;
            const moq = moqs[item._id] || Number(item.MOQ);

            if (currentQuantity > 0) {
                const newQuantity = Math.max(currentQuantity - moq, 0);
                const itemPrice = Number(item?.wsp) - Number(item?.wsp) * (Number(item?.salesDiscount) / 100);

                const updatedTotalAmount = Math.max(Number(totalAmount) - itemPrice * moq, 0);
                const updatedTaxAmount = Math.max((updatedTotalAmount * 5) / 100, 0);
                const updatedNetAmount = Math.max(updatedTotalAmount + updatedTaxAmount, 0);

                setTotalAmount(updatedTotalAmount.toFixed());
                setTaxAmount(updatedTaxAmount.toFixed());
                setNetAmount(updatedNetAmount.toFixed());

                return {
                    ...prevQuantities,
                    [item._id]: newQuantity,
                };
            }
            message.warning("Minimum Quantity Reached");
            return prevQuantities;
        });
    };

    const preparedData = ecomSaleData && ecomSaleData.data ? ecomSaleData.data.map((item) => ({
        ...item,
        quantity: quantities[item._id] !== undefined ? quantities[item._id] : item.quantity,
    })) : [];


    // console.log("kkkkkkkk", preparedData);

    const totalQuantity = preparedData.reduce((sum, item) => {
        return sum + (quantities[item._id] || 0); 
    }, 0);
    
    const totalBoxes = preparedData.reduce((sum, item) => {
        return sum + (item.totalBox || 0); 
    }, 0);

    
    const submitQuantity = () => {
        let calculatedTotalAmount = 0;
        let calculatedTaxAmount = 0;
        let calculatedNetAmount = 0;
    
        preparedData.forEach((item) => {
            const quantity = quantities[item._id] || item.quantity;
            const itemPrice = Number(item?.wsp) - Number(item?.wsp) * (Number(item?.salesDiscount) / 100);
            const itemTotal = quantity * itemPrice;
            calculatedTotalAmount += itemTotal;
        });
    
        calculatedTaxAmount = calculatedTotalAmount * 5 / 100;
        calculatedNetAmount = calculatedTotalAmount + calculatedTaxAmount;
    
        if (calculatedTotalAmount < 10000 && calculatedTotalAmount > 0) {
            calculatedNetAmount += 100;
        }
    
        const reqBody = {
            ecomsalesId: ecomsalesId.ecomsalesId,
            totalAmount: calculatedTotalAmount.toFixed(),
            netAmount: calculatedNetAmount.toFixed(),
            totalQuantity: totalQuantity, 
            totalBoxes: totalBoxes, 
            updatedQuantities: preparedData.map((item) => ({
                ...item,
                quantity: quantities[item?._id],
            })),
        };
    
        console.log("Updated request body:", reqBody);
        dispatch(ecomOrderApprove(reqBody))
            .then((response) => {
                message.success('Quantity Updated successfully!');

            })
            .catch((error) => {
                message.error('Failed to Update Quantity. Please try again.');
                console.error('Error approving order:', error);
            });
    
        setButtonLoading(true);
        setTimeout(() => {
            setButtonLoading(false);
            handleModalCancel();
            window.location.reload();
        }, 1000);
    };


    return (
        <div>
            <button className="btn" type="button" onClick={showModal}>
                <Tooltip title="Edit Product Quantity">
                    <EditOutlined />
                </Tooltip>
            </button>
            <Modal
                open={ecomSaleData && ecomSaleData.data && ecomSaleData.data.length > 0 ? visible : false}
                footer={null}
                onCancel={handleModalCancel}
                title="View Sales Products"
                style={{ top: 20 }}
                destroyOnClose={true}
                okButtonProps={{ hidden: true }}
                cancelButtonProps={{ hidden: true }}
                width={1500}
            >
                {loading ? (
                    <div className="d-flex justify-content-center align-items-center">
                        <img src={gifLoader} width={'300px'} height={'300px'} alt="Loading..." />
                    </div>
                ) : (
                    <>
                        <div className='credit-payment-edit py-2'>
                            <div className=''>
                                <span className='' style={{ fontSize: '18px', fontWeight: 500 }}>Total Amount:</span>
                                <span style={{ fontSize: '18px', fontWeight: 500 }} className="fw-semibold mr-3"> {totalAmount}</span>
                            </div>
                            <div className=''>
                                <span className='' style={{ fontSize: '18px', fontWeight: 500 }}>Tax:</span>
                                <span style={{ fontSize: '18px', fontWeight: 500 }} className="fw-semibold mr-3"> {taxAmount}</span>
                            </div>
                            <div className=''>
                                <span className='' style={{ fontSize: '18px', fontWeight: 500 }}>Net Amount:</span>
                                <span style={{ fontSize: '18px', fontWeight: 500 }} className="fw-semibold text-success mr-3"> &#8377;{netAmount}</span>
                            </div>
                        </div>
                        <Table
                            dataSource={preparedData}
                            bordered
                            size="middle"
                            pagination={false}
                            scroll={{ y: 240 }}
                        >
                            <Column
                                title="SRN"
                                key="index"
                                render={(value, item, index) => index + 1}
                                width={60}
                            />
                            <Column
                                title="Barcode"
                                dataIndex="barcode"
                                key="barcode"
                                width={120}
                                render={(value) => value}
                            />
                            <Column
                                title="Design Number"
                                dataIndex="designNumber"
                                key="designNumber"
                                render={(value) => value}
                                width={180}
                            />
                            <Column
                                title="Brand"
                                dataIndex="brand"
                                key="brand"
                                width={100}
                                render={(value) => value}
                            />
                            <Column
                                title="Size"
                                dataIndex="size"
                                key="size"
                                render={(value) => value}
                                width={100}
                            />
                            <Column
                                title="Color"
                                dataIndex="color"
                                key="color"
                                width={100}
                                render={(value) => value}
                            />
                            <Column
                                title="Quantity"
                                dataIndex="quantity"
                                key="quantity"
                                render={(value, item) => (
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                                        <button
                                            className="sales-btn"
                                            type="button"
                                            onClick={() => onRemoveQuantity(item)}
                                        >
                                            <Tooltip title="Remove Quantity">
                                                <MinusCircleOutlined />
                                            </Tooltip>
                                        </button>
                                        <span style={{ margin: '0 10px' }}>{quantities[item._id]}</span>
                                        <button
                                            className="sales-btn"
                                            type="button"
                                            onClick={() => onAddingQuantity(item)}
                                        >
                                            <Tooltip title="Add Quantity">
                                                <PlusCircleOutlined />
                                            </Tooltip>
                                        </button>
                                    </div>
                                )}
                                width={120}
                            />
                            <Column
                                title="Price"
                                key="wsp"
                                render={(value, item) => {
                                    const finalPrice = Number(item?.wsp) - Number(item?.wsp) * (Number(item?.salesDiscount) / 100);
                                    return finalPrice.toFixed();
                                }}
                                width={120}
                            />
                        </Table>

                        <div className='d-flex justify-content-center mt-4'>
                            <Button className='' loading={buttonLoading} type='primary' onClick={submitQuantity}>Submit</Button>
                        </div>
                    </>
                )}
            </Modal>
        </div>
    );
};

export default EcomCreditQuantityEdit;