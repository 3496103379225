import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPOdatabyId } from '../../../redux/actions/poOrderAction';
import { Table, Modal, message, Tooltip } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import gifLoader from '../../../assets/images/giphy.gif';

const { Column } = Table;
const ViewPoProducts = (data) => {
  
  const dispatch = useDispatch();
  const poBillData = useSelector((state) => state.poData.poDatabyId);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    dispatch(getPOdatabyId({poId : data.data.poId}));
    setVisible(true)
  };

  const handleModalCancel =  () => {
    dispatch(getPOdatabyId("reset"));
    setVisible(false);
  };

  
  return (
    <div>
    <button className="btn" type="button" onClick={showModal}>
      <Tooltip title="View Sales Products">
        <EyeOutlined />
      </Tooltip>
    </button>
   
    <Modal
      open={poBillData && poBillData.data && poBillData.data.length > 0 ? visible : false}
      footer={null}
      onCancel={handleModalCancel}
      title="View Sales Products"
      style={{ top: 20 }}
      destroyOnClose={true}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      width={1500}
    >
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <img src={gifLoader} width={'300px'} height={'300px'} alt="Loading..." />
        </div>
      ) : (
        <Table
          dataSource={poBillData && poBillData?.data && poBillData?.data?.length > 0 ? poBillData?.data : []}
          bordered
          size="middle"
         
          scroll={{ y: 240 }}
        >
          <Column
            title="SRN"
            key="index"
            render={(value, item, index) => index + 1}
            width={60}
          />
       
          <Column
            title="Design Number"
            dataIndex="designNumber"
            key="designNumber"
            width={100}
          
          />
            <Column
            title="Brand Number"
            dataIndex="brandName"
            key="brandName"
            width={100}
          
          />
            <Column
            title="Size Name"
            dataIndex="sizeName"
            key="sizeName"
            width={100}
          
          />
          <Column
            title="Quantity"
            dataIndex="quantity"
            key="quantity"
          
            width={100}
          />
          
        </Table>
      )}
    </Modal>
  </div>
  )
}

export default ViewPoProducts