/*
Date              created by               Modifications 
17-05-2024        Ayush                    Create the Page to Update the User Credit Amount

*/ 

import React from "react";
import {
   Modal, message, Tooltip, 
  Form, Input, Button,
} from "antd";
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {editEcomUserCredit, getEcomSalebillList} from "../../../redux/actions/ecomSaleAction"
import { CreditCardOutlined } from '@ant-design/icons';


const ShowCreditEdit = ({ecomsalesId, userId, limit}) => {
//  console.log("userId", userId);
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const [data, setData] = useState({amount : 0})
  
  // to open the Modal for User
  const showModal = () => {
    setVisible(true)
  };

  // to Close the Moal and Reset all the filled
  const handleModalCancel =  () => {
    setVisible(false);
    setData({amount : 0})
    setLoading(false)
    setFormErrors({})
  };

  // When Click on Save button the function will be Call 
  const onSubmit = () => {
    console.log("userId", userId);
  //  let checkCreditLimit = (+userId.creditLimit) - (+userId.creditAmount)
  //  console.log("(+userId.creditLimit), (+userId.creditAmount)", (+userId.creditLimit), (+userId.creditAmount));
  // console.log('checkCreditLimit', checkCreditLimit);

  // Check based on CreditAmount if Credit Amount is Greater than or equal to Amount the send the AMount  
    if(+(userId.creditAmount) >= +(data.amount) ) {
   let obj = {
      _id : userId._id,
      amount : +(data.amount),
      ecomsalesId : ecomsalesId
    }
    dispatch(editEcomUserCredit(obj));
  
    setData({amount : 0})
 
    let billListObj = {
      billType : "Credit User",
      limit: limit,
      perPage : 10,
     }

     // After some time call the EcomSale bill List api to get the Updated bill List
     setTimeout(() => {
      dispatch(getEcomSalebillList(billListObj))
      setVisible(false) 
    }, 1000);
     setFormErrors({})
    }
else {
 // If AMount is Greate then Credit AMount then show the Message that you can add that amount
  setFormErrors((prevErrors) => ({ ...prevErrors, creditLimit: `You Can Add Upto ₹${userId.creditAmount.toFixed(2)}` }));
}
      }

      // on  change the AMount Remove the Error and Reset the New AMount
  const onChange = (event) => {
    
    setFormErrors({})
    setData({ [event.target.name]: event.target.value });
  }

  return (
    <div>
    <button className="btn" type="button" onClick={showModal}>
      <Tooltip title="Settle Credit Limit">
      <CreditCardOutlined />
      </Tooltip>
    </button>
    <Modal
      open={visible}
      footer={null}
      onCancel={handleModalCancel}
      title="Settle Credit Limit"
      style={{ top: 20, height : 600 }}
      okButtonProps={{ hidden: true }}
      destroyOnClose={true}
      cancelButtonProps={{ hidden: true }}
    >
      <Form   name="Settle-CreditLimit"
                onFinish={onSubmit}
               >
      
      <Form.Item
                  name="amount"
                  help={formErrors.creditLimit}
                  validateStatus={formErrors.creditLimit ? 'error' : ''}
                >
                  <Input
                    autoFocus
                    placeholder="Settle Credit Limit"
                    name="amount"
                    value={data.amount}
                    onChange={onChange}
                    
                  />
                </Form.Item>

                <Form.Item className="">
                  <Button
                    className="close-modal mr-3"
                    onClick={handleModalCancel}
                  >
                    Close
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="ok-modal"
                    loading={loading}
                  >
                    Save
                  </Button>
                </Form.Item>
      </Form>
      </Modal>
      </div>
  )
}

export default ShowCreditEdit