import React, { Component } from "react";
import {
  Table,
  Popconfirm,
  Tooltip,
  Pagination,
  Button,
  Space,
  Input,
} from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import UpdateColor from "./UpdateColor";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { deleteColor, getColors } from "../../../../redux/actions/colorAction";
import TopBarProgress from "react-topbar-progress-indicator";
import Highlighter from "react-highlight-words";
const { Column } = Table;

class ColorList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      options: [],
    };
  }
  componentDidMount() {
    this.props.getColors();
  }
  onDeleteClick = (id) => {
    this.props.deleteColor(id);
  };
  onChangePage = () => {
    // this.setState({ page: page });
    // this.props.getCategories(page);
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };
  render() {
    return (
      <div className="card mt-4">
        {this.props.progressBarStatus && <TopBarProgress />}
        <div className="card-body">
          <Table
            dataSource={this.props.colors}
            bordered="true"
            size="middle"
            pagination={false}
          >
            <Column
              title="Name"
              dataIndex="name"
              key="name"
              {...this.getColumnSearchProps("name")}
            />

            <Column title="Code" dataIndex="code" key="code" />
            <Column
              title="Action"
              key="action"
              render={(text, record) => (
                <div>
                  <a>
                    <UpdateColor colors={text} />
                  </a>

                  <Popconfirm
                    title="Are you sure to delete this Color?"
                    onConfirm={() => this.onDeleteClick(text._id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <div className="btn">
                      <Tooltip title="Delete Color">
                        <DeleteOutlined />
                      </Tooltip>
                    </div>
                  </Popconfirm>
                </div>
              )}
            />
          </Table>{" "}
          {/**<div className="float-right mt-3">
            <Pagination
              defaultCurrent
              defaultPageSize
              total="2"
              //   total={totalPages}
              onChange={this.onChangePage}
              showLessItems="true"
            />
          </div> */}
        </div>
      </div>
    );
  }
}
ColorList.propTypes = {
  getColors: PropTypes.func.isRequired,
  deleteColor: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => (
  {
    errors: state.errors,
    colors: state.colorData.colors,
    progressBarStatus: state.progressBarReducer.showProgressBar
  }
);

export default connect(mapStateToProps, { getColors, deleteColor })(ColorList);
