import React, { Component } from "react";
import { Table, Button, Space, Input, Tag } from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
// import { getTopCustomerReports } from "../../../../redux/actions/getTopCustomerReports";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { CSVLink } from "react-csv";
import Papa from "papaparse";

const { Column } = Table;
const data = [];

export class SalesReportList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      data: [],
      loading: false,
      csvData: [],
    };
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  this.setState({
                    searchText: selectedKeys[0],
                    searchedColumn: dataIndex,
                  });
                }}
              >
                Filter
              </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };

  render() {
    const { salesAll } = this.props;
    const headers = [
      { label: "Sale Id", key: "invoiceId" },
      { label: "Date", key: "date" },
      { label: "Barcode", key: "barcode" },
      { label: "Design No", key: "designNumber" },
      { label: "Customer Name", key: "customerName" },
      { label: "Brand", key: "brand" },
      { label: "Size", key: "size" },
      { label: "Color", key: "color" },
      { label: "Category", key: "category" },
      { label: "Style", key: "style" },
      { label: "Floor", key: "floorName" },
      { label: "HSN Code", key: "HSNCode" },
      { label: "Employee Name", key: "employeeName" },
      { label: "Season", key: "season" },
      { label: "P & F", key: "pAndf" },
      { label: "QTY", key: "quantity" },
      { label: "Purchase Price", key: "purchasePrice" },
      { label: "WSP", key: "wsp" },
      { label: "TAX(%)", key: "tax" },
      { label: "TAX(Rs.)", key: "taxAmount" },
      { label: "Discount", key: "discountAmount" },
      { label: "Net Amount", key: "netAmount" },
      { label: "GR Amount", key: "grAmount" },
      { label: "Agent Name", key: "agentName" },
      { label: "Remark", key: "remark" },
    ];
    const handleDownloadButtonClick = async () => {
      this.setState({ loading: true });

      try {
        // Call your API here
        const response = await this.props.getSalesReport(
          this.props?.reportObjAll
        );
        console.log("check nagor response", response);

        const csvData = response?.data;
        // Generate CSV content using PapaParse
        const csv = Papa.unparse({
          fields: headers?.map((header) => header.label),
          data: csvData?.map((row) =>
            headers?.map((header) => row[header.key])
          ),
        });

        // Create Blob and initiate file download
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${this.props?.startDate} to ${this.props?.endDate}.csv`;
        link.click();
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error(error);
      } finally {
        this.setState({ loading: false });
      }
    };
    return (
      <>
        <div className="card mt-2 final-purchases-list">
          <div className="card-body">
            <Table
              dataSource={salesAll && salesAll.data ? salesAll.data : []}
              // dataSource={  []}
              bordered="true"
              size="middle"
              scroll={{ y: 240 }}
              // pagination={false}
              pagination={{
                pageSize: 30,
                total: salesAll?.count,
                onChange: (page) => {
                  this.props.getSalesReport({
                    ...this.props.reportObj,
                    endDate: this.props?.reportObjAll?.endDate,
                    limit: page,
                    perPage: 30,
                  });
                },
              }}
            >
              <Column
                title="SRN"
                key="index"
                render={(value, item, index) => index + 1}
                width={60}
              />
              <Column
                title="Date"
                dataIndex="date"
                key="date"
                width={120}
                render={(value, item, index) =>
                  moment(item.date).format("YYYY-MM-DD")
                }
              />
              <Column
                title="Sale Id"
                {...this.getColumnSearchProps("invoiceId")}
                dataIndex="invoiceId"
                key="invoiceId"
                width={100}
              />
              <Column
                title="Barcode"
                {...this.getColumnSearchProps("barcode")}
                dataIndex="barcode"
                key="barcode"
                width={130}
              />
              <Column
                title="Design No"
                dataIndex="designNumber"
                key="designNumber"
                width={140}
              />
              <Column
                title="Customer Name"
                dataIndex="customerName"
                key="customerName"
                width={160}
              />
              <Column title="Brand" dataIndex="brand" key="brand" width={100} />
              <Column title="Size" dataIndex="size" key="size" width={100} />
              <Column title="Color" dataIndex="color" key="color" width={100} />
              <Column
                title="Category"
                dataIndex="category"
                key="category"
                width={100}
              />
              <Column title="Style" dataIndex="style" key="style" width={100} />
              <Column
                title="Floor"
                dataIndex="floorName"
                key="floorName"
                width={100}
              />
              <Column
                title="HSN Code"
                dataIndex="HSNCode"
                key="HSNCode"
                width={100}
              />
              <Column
                title="Employee Name"
                dataIndex="employeeName"
                key="employeeName"
                width={170}
              />
              <Column
                title="Season"
                dataIndex="season"
                key="season"
                width={100}
              />
              <Column title="P & F" dataIndex="pAndf" key="pAndf" width={100} />
              <Column
                title="QTY"
                dataIndex="quantity"
                key="quantity"
                width={100}
              />
              <Column
                title="Purchase Price"
                dataIndex="purchasePrice"
                key="purchasePrice"
                width={160}
              />
              <Column title="WSP" dataIndex="wsp" key="wsp" width={100} />
              <Column title="TAX(%)" dataIndex="tax" key="tax" width={100} />
              <Column
                title="TAX(Rs.)"
                dataIndex="taxAmount"
                key="taxAmount"
                width={100}
              />
              <Column
                title="Discount"
                dataIndex="discountAmount"
                key="discountAmount"
                width={100}
              />
              <Column
                title="Net Amount"
                dataIndex="netAmount"
                key="netAmount"
                width={120}
              />
              <Column
                title="GR Amount"
                dataIndex="grAmount"
                key="grAmount"
                width={120}
              />
              <Column
                title="Agent Name"
                dataIndex="agentName"
                key="agentName"
                width={140}
              />
              <Column
                title="Remark"
                dataIndex="remark"
                key="remark"
                width={100}
              />
            </Table>
          </div>
        </div>
        {(() => {
          if (this.props?.reports?.count > 0) {
            return (
              <>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="save-modal mt-4"
                  block
                  onClick={handleDownloadButtonClick}
                >
                  {this.state.loading ? "Loading..." : "Download CSV"}
                </Button>
              </>
            );
          }
        })()}
      </>
    );
  }
}

SalesReportList.propTypes = {
  // getTopCustomerReports: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  salesAll: state.reportData.salesAll,
  allsales: state.reportData.allsales,
});

export default connect(mapStateToProps, {
  // getTopCustomerReports,
})(SalesReportList);
