import React, { useEffect, useState, useRef } from 'react'
import { Row, Col, Button, Input, Space, Table } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerReports } from "../../../../redux/actions/reportsActions";
import TopBarProgress from "react-topbar-progress-indicator";
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import BackButton from '../../Report/Button';

const { Column } = Table;
const CustomerReport = () => {
    const dispatch = useDispatch()
    const reportsData = useSelector((state) => state.reportData.customerReports);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const progressBarStatus = useSelector((state) => state.progressBarReducer.showProgressBar)
    const getList = () => {
        dispatch(getCustomerReports())
    }

    useEffect(() => {
        getList()
    }, [])


    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        setSearchText('');
        confirm();
    };


    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={dataIndex === "agentId.name" ? `Search Agent Name` : `Search Customer`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, confirm)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) => {
            console.log("record?.agentId?.name", record?.agentId?.name);
            const dataIndexValue = dataIndex === 'agentId.name' ? record?.agentId?.name : record[dataIndex];
            return dataIndexValue ? dataIndexValue.toString().toLowerCase().includes(value.toLowerCase()) : false;
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    return (
        <>
            <div className="subCategory-dashboard">
                {progressBarStatus && <TopBarProgress />}
                <div className="container-fluid category-list">
                    <Row className="title-row">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <div className="page-header">Customer Report</div>
                                </Col>
                                <Col span={12}>
                                    <BackButton />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className="card final-purchases-list mt-4">
                    <Table
                        dataSource={reportsData && reportsData?.length > 0 ? reportsData : []}
                        bordered={true}
                        size="large"
                        total={reportsData?.length}
                        pagination={{ pageSize: 100 }} // Set the pageSize to 100
                        scroll={{ y: 400 }}
                    >
                        <Column
                            title="SRN"
                            key="index"
                            render={(value, item, index) => index + 1}
                            width={60}
                        />
                        <Column
                            title="Customer Name"
                            dataIndex="name"
                            key="name"
                            width={160}
                            {...getColumnSearchProps('name')}
                        />
                        <Column
                            title="Mobile No."
                            dataIndex="mobileNumber"
                            key="mobileNumber"
                            width={140}
                        />
                        <Column
                            title="Agent Name"
                            dataIndex="agentId.name"
                            key="agentId"
                            width={140}
                            {...getColumnSearchProps("agentId.name")}
                            render={(text, record) => record?.agentId?.name}
                        />
                        <Column
                            title="State"
                            key="state"
                            dataIndex="state"
                            width={100}
                        />
                        <Column
                            title="Total Net Amount"
                            dataIndex="totalNetAmount"
                            key="totalNetAmount"
                            width={140}
                        />


                    </Table>
                </div>
            </div>
        </>
    )
}

export default CustomerReport