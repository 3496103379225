import React, { useState, useEffect, useRef } from "react";
import { Table, Tooltip, Button, Space, Input, Modal, Row, Col } from "antd";
import { EyeOutlined, PlusCircleOutlined, SearchOutlined, CaretRightOutlined } from "@ant-design/icons";
import { getPurchaseOrderByID } from "../../../../../redux/actions/purchaseOrderAction";
import { useDispatch, useSelector } from "react-redux";

const { Column } = Table;

const columns = [
    {
        title: "SRN",
        key: "index",
        render: (text, record, index) => index + 1,
    },
    // {
    //     title: 'Dealer Name',
    //     dataIndex: 'dealerName',
    //     key: 'dealerName',
    // },
    {
        title: 'Design No.',
        dataIndex: 'designNumber',
        key: 'designNumber',
    },
    {
        title: 'Brand',
        dataIndex: 'brand',
        key: 'brand',
    },
    {
        title: 'Size',
        dataIndex: 'size',
        key: 'size',
    },
    {
        title: 'Color',
        dataIndex: 'color',
        key: 'color',
    },
    {
        title: 'MOQ',
        dataIndex: 'MOQ',
        key: 'MOQ',
    },
    {
        title: 'Qnty',
        dataIndex: 'quantity',
        key: 'quantity',
    },
    {
        title: 'Price',
        dataIndex: 'purchasePrice',
        key: 'purchasePrice',
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: (text, record) => {
            return record.quantity * record.purchasePrice
        }
    },
    {
        title: 'WSP',
        dataIndex: 'wsp',
        key: 'wsp',
    }
];

const ShowPurchaseOrderBills = ({ id }) => {
    const dispatch = useDispatch()
    const [visible, setVisible] = useState(false);
    const purchaseOrderData22 = useSelector((state) => (state.purchaseOrderData?.purchaseOrdersByID));


    useEffect(() => {
        const obj = {
            poId: id
        };
        dispatch(getPurchaseOrderByID(obj));
    }, [visible]);

    const showModal = () => {
        setVisible(true);
    };


    const handleModalCancel = () => {
        setVisible(false);
    };



    return (
        <div>
            <Button className="" style={{ color: 'black' }} type="link" onClick={showModal}>
                <Tooltip title="View Purchases Orders">
                    <EyeOutlined />
                </Tooltip>
            </Button>
            <Modal
                visible={visible}
                onOk={handleModalCancel}
                onCancel={handleModalCancel}
                title="View Purchases Orders"
                style={{ top: 20 }}
                okButtonProps={{ hidden: true }}
                cancelButtonProps={{ hidden: true }}
                destroyOnClose={true}
                width={1500}
            >
                <div>
                    <Row gutter={24} className="mb-4 grand-totall billHeadingContainer" >
                        <Col sm={24} md={24} lg={24}>
                            {/* <span className=" billsTopHeading">Bill Date<CaretRightOutlined className="mr-2" />{`${moment(this.props.data.billDate).format("YYYY-MM-DD")} (${moment(this.props.data.billDate).format("HH:mm:ss")} )`}</span> */}
                            <div className="d-flex">

                                <div className=" billsTopHeading ml-4 d-flex align-item-center">Bill Date <span style={{ display: 'flex', alignItems: 'center' }}> <CaretRightOutlined className=" mr-2 " /> {purchaseOrderData22?.billDetails?.billDate} </span>  </div>

                                <div className=" billsTopHeading ml-4 d-flex align-item-center">PO Id <span style={{ display: 'flex', alignItems: 'center' }}> <CaretRightOutlined className=" mr-2 " />  {purchaseOrderData22?.billDetails?.poId} </span> </div>

                                <div className=" billsTopHeading ml-4 d-flex align-item-center">Dealer Name <span style={{ display: 'flex', alignItems: 'center' }}> <CaretRightOutlined className=" mr-2 " /> {purchaseOrderData22?.billDetails?.dealerName} </span>  </div>
                            </div>

                        </Col>

                    </Row>

                    <Table
                        dataSource={purchaseOrderData22?.purchaseOrder}
                        columns={columns}
                        bordered="true"
                        size="middle"
                        pagination={false}
                    />

                    <div className="mt-4">
                        <div className="col-md-12 ml-auto">
                            <div className="grand-totall" style={{ padding: "18px 17px 4px" }}>
                                <div className="title-wrap">
                                    <h4 className="cart-bottom-title section-bg-gary-cart">
                                        Purchase Total
                                    </h4>
                                </div>
                                <h5>
                                    Total Boxes{" "}
                                    <span>
                                        {
                                            (() => {
                                                const totalBoxes = purchaseOrderData22?.purchaseOrder?.reduce((accumulator, obj) => {
                                                    const boxes = obj.MOQ ? obj.quantity / obj.MOQ : 0;
                                                    return accumulator + boxes;
                                                }, 0);
                                                return totalBoxes;
                                            })()
                                        }
                                    </span>
                                </h5>
                                <h5>
                                    Total Quantity{" "}
                                    <span>
                                        {
                                            (() => {
                                                const totalQuantity = purchaseOrderData22?.purchaseOrder?.reduce((accumulator, obj) => {
                                                    const quantity = obj.quantity;
                                                    return accumulator + quantity;
                                                }, 0);
                                                return totalQuantity;
                                            })()
                                        }
                                    </span>
                                </h5>
                                <h5>
                                    Total Amount{" "}
                                    <span>
                                        {purchaseOrderData22?.billDetails?.totalAmount}
                                    </span>
                                </h5>
                                <h4 className="grand-totall-title">
                                    Net Amount{" "}
                                    <span>
                                        {purchaseOrderData22?.billDetails?.netAmount}
                                    </span>
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ShowPurchaseOrderBills