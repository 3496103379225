import React, { Component } from "react";
import { Result, Button } from "antd";
import { Redirect } from "react-router-dom";
export default class ServerOff500 extends Component {
  constructor(props) {
    super(props);
    let IsLoggedIn = false;
    if (localStorage.length > 0) {
      IsLoggedIn = true;
    }
    this.state = {
      IsLoggedIn,
    };
  }
  goForward() {
    window.history.forward();
  }
  goBack() {
    window.history.back();
  }
  clear = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("roleName");
  };
  render() {
    // if (this.state.IsLoggedIn === true) {
    //   this.goForward();
    //   this.goBack();
    //   window.location.reload(false);
    // }
    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={
          <Button type="primary" href="/home" onClick={this.clear}>
            Back Home
          </Button>
        }
      />
    );
  }
}
