import React, { Component } from "react";
import { Row, Col, Tooltip, Form, Select, DatePicker, Button } from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { getPurchaseAllReports } from "../../../../redux/actions/reportsActions";
import DateWiseSearch from "../../../../util/DateWiseSearch";
import TopBarProgress from "react-topbar-progress-indicator";
import PurchaseAllReportList from "./PurchaseAllReportList";
import BackButton from "../../Report/Button";
const dateFormat = "YYYY-MM-DD";

class PurchaseAllReport extends Component {
    constructor(props) {
        super(props);

    }
    componentDidMount = () => {
        let startDate = moment(new Date()).format("YYYY-MM-DD")
        let endDate = moment(new Date()).format("YYYY-MM-DD")
        let reportObj = {
            startDate: startDate,
            endDate: endDate + " " + moment().format("HH:mm:ss"),
        };
        this.props.getPurchaseAllReports(reportObj)
    }

    render() {

        return (
            <div className="subCategory-dashboard">
                {this.props.progressBarStatus && <TopBarProgress />}
                <div className="container-fluid category-list">
                    {/* ==============Search Bar and Date Filter================= */}
                    <Row className="title-row">
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <div className="page-header">Purchase Report</div>
                                </Col>
                                <Col span={12}>
                                    <BackButton />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <DateWiseSearch keyId={"Purchase Report"} />
                        </Col>
                    </Row>
                    <PurchaseAllReportList
                        reports={this.props.reports}
                    />
                </div>
            </div>
        );
    }
}
PurchaseAllReport.propTypes = {
    getPurchaseAllReports: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    reports: state.reportData.purchaseAll,
    progressBarStatus: state.progressBarReducer.showProgressBar
});

export default connect(mapStateToProps, {
    getPurchaseAllReports,
})(PurchaseAllReport);
