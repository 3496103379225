import { GET_CUSTOMERS, GET_ERRORS, GET_SALES, ACTIVE_AND_INACTIVE_CUSTOMERS } from "./../actions/type";
const initialState = {
  customers: [],
  customer: {},
  salesPerson: [],
  activeandInactiveCustomer: []
};
export default function (state = initialState, action) {
  switch (action.type) {
    // case GET_ERRORS:
    //return action.payload;
    case GET_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
      };
    case GET_SALES:
      return {
        ...state,
        salesPerson: action.payload,
      };
    case ACTIVE_AND_INACTIVE_CUSTOMERS:
      return {
        ...state,
        activeandInactiveCustomer: action.payload,
      };
    default:
      return state;
  }
}
