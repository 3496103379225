import React from "react";
import ReactApexChart from "react-apexcharts";
import { Typography } from "antd";
import { MinusOutlined } from "@ant-design/icons";
// import LineChart from "./configs/lineChart";

function StockAgeLineChart({ options, seriesData }) {
  
    return (
        <>
            <div className="apex-linechart">
                <ReactApexChart
                    className="full-width"
                    options={options}
                    series={seriesData}
                    type="area"
                    height={350}
                    width={"100%"}
                />
            </div>
        </>
    );
}

export default StockAgeLineChart;