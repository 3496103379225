import React, { Component } from "react";
import {
    Table,
    Button,
    Space,
    Input,
    Row,
    Col,
    Popconfirm,
    Tooltip,
    DatePicker,
    Form,
    message
} from "antd";
import { FileDoneOutlined, SearchOutlined, DeleteOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
    getSalesList,
    getSaleUsingInvoiceId,
    getSalesListBydate,
    getDeleteSalesBill
} from "../../../redux/actions/saleAction";
import Highlighter from "react-highlight-words";
import moment from "moment";
// import ShowBillProducts from "./ShowBillProducts.js";
import Search from "../../../util/Search";
import TopBarProgress from "react-topbar-progress-indicator";
import DateWiseSearch from "../../../util/DateWiseSearch.js";
const dateFormat = "YYYY-MM-DD";
const { Column } = Table;
const searchOptions = ["Barcode", "SalesId", "CustomerName"]
class DeleteSales extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: "",
            current: 1,
            search: "",
            options: [],
            searchValue: "",
            startDate: moment(new Date()).format("YYYY-MM-DD"),
            endDate: moment(new Date()).format("YYYY-MM-DD"),
            totalCount: 0,
        };
    }
    componentDidMount() {


        let localStartDate = localStorage.getItem("startDate");
        let localEnddate = localStorage.getItem("endDate");
        if (localStartDate && localEnddate) {
            let today = moment(new Date()).format("YYYY-MM-DD")
            if (today == localEnddate) {
                let reportObj = {
                    startDate: localStartDate,
                    endDate: localEnddate + " " + moment().format("HH:mm:ss"),
                };
                this.props.getSalesListBydate(reportObj);
                localStorage.removeItem("startDate");
                localStorage.removeItem("endDate");
            } else {
                const dateMoment = moment(localEnddate, 'YYYY-MM-DD');
                const endTime = dateMoment.endOf('day').format('HH:mm:ss');
                const endDateWithEndTime = `${localEnddate} ${endTime}`;
                let reportObj = {
                    startDate: localStartDate,
                    endDate: endDateWithEndTime,
                };
                this.props.getSalesListBydate(reportObj);
                localStorage.removeItem("startDate");
                localStorage.removeItem("endDate");
            }
        } else {
            let startDate = moment(new Date()).format("YYYY-MM-DD")
            let endDate = moment(new Date()).format("YYYY-MM-DD")
            let reportObj = {
                startDate: startDate,
                endDate: endDate + " " + moment().format("HH:mm:ss"),
            };
            this.props.getSalesListBydate(reportObj);
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.saleslistbydate.data !== this.props.saleslistbydate.data) {
            this.setState({ totalCount: this.props.saleslistbydate.count })
        }
    }

    componentWillUnmount() {
        this.setState({
            totalCount: 0,
            startDate: moment(new Date()).format("YYYY-MM-DD"),
            endDate: moment(new Date()).format("YYYY-MM-DD")
        })
    }

    deletSales = async (obj) => {
        let reponse = await this.props.getDeleteSalesBill(obj)
            // if (reponse.status == 200) {
            //     let reportObj = {
            //         startDate: this.state.startDate,
            //         endDate: this.state.endDate + " " + moment().format("HH:mm:ss"),
            //     };
            //     this.props.getPurchaseBillsBydate(reportObj)
            // }
    }

    onStartDateChange = (date, dateString) => {
        this.setState({ startDate: dateString });
    };
    onEndDateChange = (date, dateString) => {
        this.setState({ endDate: dateString });
    };

    showDataOnList = () => {
        let reportObj = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
        };
        console.log("#reportObj-mohit", reportObj);
        this.props.getSalesListBydate(1, reportObj);
    };




    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    // placeholder="Search For Robot"
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        this.handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ marginBottom: 8, display: "block" }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                        className="search-name"
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => this.handleReset(clearFilters, confirm)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    {/**<Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button> */}
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : "",
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: (text) =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    resetfuntion = () => {
        console.log("inside reset function")
        let reportObj = {};
        this.props.getSalesListBydate(1, reportObj, "reset");
        this.setState({
            totalCount: 0,
            startDate: "",
            endDate: moment(new Date()).format("YYYY-MM-DD")
        })
    }

    handleReset = (clearFilters, confirm) => {
        clearFilters();
        confirm();
        this.setState({ searchText: "" });
    };
    updateBill = async (text) => {
        await this.props.getSaleUsingInvoiceId(text.invoiceId);
        // localStorage.setItem('startDate', this.state.state1);
        // localStorage.setItem('endDate', this.state.state2);
        this.props.history.push({
            pathname: "/dagaImpex/addSales",
            state: {
                id: text._id,
                saleObj: [text, this.props.salesOrders],
                updateBill: true,
            },
        });
    };
    onChange = (event) => {
        this.setState({ searchValue: event });
    };
    cancelState = () => {
        this.setState({ searchValue: "" });
    }

    listData = () => {
        const { salesList, saleslistbydate, searchData } = this.props
        const value = saleslistbydate && saleslistbydate.length > 0 ? saleslistbydate[0] : []
        // if(value.message == "Empty" ){
        //   return([])
        // }else if(this.state.searchValue){
        //   return(searchData.data)
        // }else{
        //   return(saleslistbydate.data)
        // }
        if (this.state.searchValue) {
            return (searchData.data)
        } else {
            return (saleslistbydate.data)
        }

    }


    render() {
        console.log("#this.props.salesList", this.props.salesList);
        const { searchValue } = this.state;
        const { salesList, searchData, saleslistbydate } = this.props
        const search = searchData || []

        return (
            <div className="subCategory-dashboard">
                {this.props.progressBarStatus && <TopBarProgress />}
                <div className="container-fluid category-list">
                    {/* ==============Search Bar and Date Filter================= */}
                    <Row className="title-row">
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <div className="page-header">Delete Sales Bills</div>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}> */}
                        <Search title="Sales Bills" placeholder="Sale Return Id, Barcode or CustomerName" searchopt={searchOptions} changeFunction={this.onChange} cancelState={this.cancelState} />
                        {/* </Col> */}
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <DateWiseSearch keyId={"Sales Bills"} propsHistory={this.props.history} />
                        </Col>
                    </Row>
                    {/* ======================================== */}
                    <div className="card">
                        <div className="card-body">
                            <Table
                                dataSource={this.listData()}
                                // dataSource={searchValue ? search : saleslistbydate.data ? saleslistbydate.data : salesList.data ? salesList.data : []}
                                //dataSource={this.props.salesList && this.props.salesList.data ? this.props.salesList.data :[]}
                                bordered="true"
                                size="middle"

                                pagination={{
                                    pageSize: 100,
                                    total: this.state.totalCount ? this.state.totalCount : salesList.count,
                                    onChange: (page) => {
                                        this.props.getSalesList(page);
                                    },
                                }}
                                scroll={{ y: 400 }}
                            >
                                <Column
                                    title="SRN"
                                    key="index"
                                    render={(value, item, index) => index + 1}
                                    width={60}
                                />
                                <Column
                                    title="Bill Date"
                                    dataIndex="date"
                                    key="date"
                                    width={150}
                                    render={(value, item, index) =>
                                        moment(item.date).format("YYYY-MM-DD")
                                    }
                                />
                                <Column
                                    title="Updated Date"
                                    dataIndex="updatedAt"
                                    key="updatedAt"
                                    width={150}
                                    render={(value, item, index) => {
                                        if (item && item.updatedAt) {
                                            return moment(item.updatedAt).format("YYYY-MM-DD")
                                        }
                                    }
                                    }
                                />
                                <Column
                                    title="Invoice No."
                                    {...this.getColumnSearchProps("invoiceId")}
                                    dataIndex="invoiceId"
                                    key="invoiceId"
                                    render={(value, item, index) => `S-${item.invoiceId}`}
                                    width={115}
                                />
                                <Column
                                    title="Customer Name"
                                    // {...this.getColumnSearchProps("name")}
                                    dataIndex="name"
                                    key="name"
                                    render={(value, item, index) => `${item.userId.name}`}
                                    width={150}
                                />
                                <Column
                                    title="Total Boxes"
                                    dataIndex="totalBoxes"
                                    key="totalBoxes"
                                    width={100}
                                />
                                <Column
                                    title="Total Qnty"
                                    dataIndex="totalQuantity"
                                    key="totalQuantity"
                                    width={100}
                                />
                                <Column
                                    title="Total Amt"
                                    dataIndex="totalAmount"
                                    key="totalAmount"
                                    render={(value, item, index) => parseInt(item.totalAmount).toFixed()}
                                    width={100}
                                />
                                <Column
                                    title="Gr Amt"
                                    dataIndex="grAmountUsed"
                                    key="grAmountUsed"
                                    // render={(value, item, index) => `${item.userId.grAmount.toFixed()}`}
                                    width={100}
                                />
                                <Column
                                    title="Net Amt"
                                    dataIndex="netAmount"
                                    key="netAmount"
                                    width={120}
                                    render={(value, item, index) => parseInt(item.netAmount).toFixed()}

                                />

                                <Column
                                    title="Action"
                                    key="action"
                                    width={80}
                                    render={(text, record) => (
                                        <Row gutter={[8, 0]}>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                <Popconfirm
                                                    title="Are you sure to Delete this Bill?"
                                                    onConfirm={() => this.deletSales({ salesId: record.invoiceId })}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <div className="btn">
                                                        <Tooltip title=" Delete Bill">
                                                            <DeleteOutlined />
                                                        </Tooltip>
                                                    </div>
                                                </Popconfirm>
                                            </Col>
                                        </Row>
                                    )}
                                />
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

DeleteSales.propTypes = {
    getSalesList: PropTypes.func.isRequired,
    getSalesListBydate: PropTypes.func.isRequired,
    getSaleUsingInvoiceId: PropTypes.func.isRequired,
    getDeleteSalesBill:PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    salesList: state.saleData.salesList,
    saleslistbydate: state.saleData.saleslistbydate,
    salesOrders: state.saleData.salesOrders,
    searchData: state.saleData.saleSearchData,
    progressBarStatus: state.progressBarReducer.showProgressBar
});

export default connect(mapStateToProps, {
    getSalesList,
    getSalesListBydate,
    getSaleUsingInvoiceId,
    getDeleteSalesBill
})(DeleteSales);
