import React, { Component } from "react";
import {
  Table,
  Button,
  Space,
  Input,
  Tag,
  Row,
  Col,
  Popconfirm,
  message,
} from "antd";
import { connect } from "react-redux";
import Highlighter from "react-highlight-words";
import TopBarProgress from "react-topbar-progress-indicator";
import { SearchOutlined, UndoOutlined } from "@ant-design/icons";
import {
  getDltPurchaseReturn,
  restorePurchaseReturn,
} from "../../../../redux/actions/recycleBinAction";
const { Column } = Table;

export class RecyclePurchaseReturn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      data: [],
    };
  }

  componentDidMount = () => {
    this.props.getDltPurchaseReturn();
  };

  handleRestore = (record) => {
    const obj = {
      _id: record._id,
      purchasereturnid: record.purchasereturnid,
    };
    this.props.restorePurchaseReturn(obj).then(() => {
      message.success("Deleted Successfully");
      this.props.getDltPurchaseReturn();
    });
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  this.setState({
                    searchText: selectedKeys[0],
                    searchedColumn: dataIndex,
                  });
                }}
              >
                Filter
              </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  render() {
    const purchaseReturnList = this.props.purchaseReturnList;
    return (
      <>
        <div className="subCategory-dashboard">
          {console.log(
            "this.props.purchaseReturnList",
            this.props.purchaseReturnList
          )}
          {this.props.progressBarStatus && <TopBarProgress />}
          <div className="container-fluid category-list">
            <Row className="title-row mb-4">
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <div className="page-header">Recycle Purchase Return</div>
              </Col>
            </Row>
            <div className="card mt-4 final-purchases-list">
              <div className="card-body">
                <Table
                  dataSource={purchaseReturnList}
                  bordered
                  size="middle"
                  scroll={{ y: 240 }}
                  pagination={false}
                >
                  <Column
                    title="SRN"
                    key="index"
                    render={(value, item, index) => index + 1}
                    width={60}
                  />
                  <Column
                    title="Purchase Return Bill ID"
                    dataIndex="purchaseReturnBillId"
                    key="purchaseReturnBillId"
                    width={150}
                  />
                  <Column
                    title="Purchase ID"
                    dataIndex="purchaseId"
                    key="purchaseId"
                    width={100}
                  />
                  <Column
                    title="Barcode"
                    dataIndex="barcode"
                    key="barcode"
                    width={100}
                  />
                  <Column
                    title="Quantity"
                    dataIndex="quantity"
                    key="quantity"
                    width={100}
                  />
                  <Column
                    title="Dealer Name"
                    dataIndex="dealerId"
                    key="dealerId"
                    width={150}
                    render={(dealerId) => dealerId.name}
                  />
                  <Column
                    title="Price"
                    dataIndex="price"
                    key="price"
                    width={100}
                  />
                  <Column title="MOQ" dataIndex="MOQ" key="MOQ" width={150} />
                  <Column
                    title="Bill ID"
                    dataIndex="purchaseReturnBillId"
                    key="purchaseReturnBillId"
                    width={180}
                  />
                  <Column
                    title="Action"
                    key="action"
                    render={(text, record) => (
                      <Space size="middle">
                        <Popconfirm
                          title="Are you sure you want to restore this item?"
                          onConfirm={() => this.handleRestore(record)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <UndoOutlined />
                        </Popconfirm>
                      </Space>
                    )}
                    width={100}
                  />
                </Table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

RecyclePurchaseReturn.propTypes = {
  // getTopCustomerReports: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  purchaseReturnList: state.recycleBin.purchaseReturnList,
});

export default connect(mapStateToProps, {
  getDltPurchaseReturn,
  restorePurchaseReturn,
})(RecyclePurchaseReturn);