import React, { Component } from "react";
import { Row, Col, Tooltip, Form, Select, DatePicker, Button } from "antd";
import FastMovingItemsReportList from "./FastMovingItemsReportList";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { getFastMovingItemsReports } from "../../../../redux/actions/reportsActions";
import TopBarProgress from "react-topbar-progress-indicator";
import DateWiseSearch from "../../../../util/DateWiseSearch";
import BackButton from "../../Report/Button";
const dateFormat = "YYYY-MM-DD";

class FastMovingItemsReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      purchaseDate: moment(new Date()).format("YYYY-MM-DD"),
      sellingDate: "",
    };
  }

  onSellingDateChange = (date, dateString) => {
    this.setState({ sellingDate: dateString });
  };
  onPurchaseDateChange = (date, dateString) => {
    this.setState({ purchaseDate: dateString });
  };
  componentDidMount() {
    this.props.getFastMovingItemsReports();
  }
  // showDataOnList = () => {
  //   let reportObj = {
  //     sellingDate: this.state.sellingDate,
  //     purchaseDate: this.state.purchaseDate,
  //   };
  //   this.props.getFastMovingItemsReports(reportObj);
  // };

  render() {
    return (
      <div className="subCategory-dashboard">
        {this.props.progressBarStatus && <TopBarProgress />}
        <div className="container-fluid category-list">
          {/* ==============Search Bar and Date Filter================= */}
          <Row className="title-row">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row gutter={24}>
                <Col span={12}>
              <div className="page-header">Fast Moving Items Report</div>
                </Col>
                <Col span={12}>
                  <BackButton />
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <Row className="mt-4">
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <DateWiseSearch />
            </Col>
          </Row> */}
          {/* ======================================== */}
          {/**<Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form
                name="add-FastMovingItems"
                className="add-FastMovingItems"
                onFinish={this.showDataOnList}
                initialValues={{ remember: true }}
              >
                <Row gutter={[16, 0]} className="mt-4">
                  <Col xs={12} sm={12} md={10} lg={10} xl={10}>
                    <Tooltip title="Select Selling Date">
                      <Form.Item
                        name="sellingDate"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Selling Date !",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          onChange={this.onSellingDateChange}
                          placeholder="Select Selling Date"
                          format={dateFormat}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={12} sm={12} md={10} lg={10} xl={10}>
                    <Tooltip title="Select Purchase Date">
                      <Form.Item
                        name="purchaseDate"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Purchase Date !",
                          },
                        ]}
                        initialValue={moment()}
                      >
                        <DatePicker
                          disabledDate={(current) => {
                            return current && current > Date.now();
                          }}
                          style={{ width: "100%" }}
                          onChange={this.onPurchaseDateChange}
                          placeholder="Select Purchase Date"
                          format={dateFormat}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>

                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="close-modal"
                        block
                      >
                        Search
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row> */}
          <FastMovingItemsReportList
            reports={this.props.reports}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
          />
        </div>
      </div>
    );
  }
}
FastMovingItemsReport.propTypes = {
  getFastMovingItemsReports: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  reports: state.reportData.fastMovingReport,
  progressBarStatus : state.progressBarReducer.showProgressBar
});

export default connect(mapStateToProps, {
  getFastMovingItemsReports,
})(FastMovingItemsReport);
