import React, { useState } from "react";
import { Modal, Tooltip,Button } from "antd";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const PreviewBanner = ({ images }) => {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleModalCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Button className="close-modal" onClick={showModal}>
        <Tooltip title="Preview">Preview</Tooltip>
      </Button>
      <Modal
        open={visible}
        onCancel={handleModalCancel}
        title="Preview"
        style={{ top: 20 }}
        width={1500}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        destroyOnClose={true}
      >
        {/* Add a container element to wrap the Carousel component */}
        <div className="carousel-container">
          <Carousel
            autoPlay
            infiniteLoop
            interval={1500}
          >
            {images?.map((image, index) => (
              <div key={index}>
                <img src={image} alt={`Slide ${index + 1}`} />
              </div>
            ))}
          </Carousel>
        </div>
      </Modal>
    </>
  );
};

export default PreviewBanner;