import React, { useState, useEffect } from "react";
import { Button, Form, Row, Col, Tooltip, DatePicker, Select, Input, Table, Popconfirm } from "antd";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import { FileDoneOutlined, EditOutlined, CheckCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { salesBillBySalesPersonId, salesBySearchValuebySalesPersonId, getSalesListBydate } from "../../../redux/actions/saleAction";
import Column from "antd/lib/table/Column";
import ShowBillProducts from "./ShowBillProducts";
import ViewGenerateBill from "./ViewGenerateBill";
import VerifyBillProduct from "./VerifyBillProduct";

const { Option } = Select;

const dateFormat = "YYYY-MM-DD";

const storedToken = localStorage.getItem("token");
const AddNewSalesBillMobile = () => {
    const Id = localStorage.getItem("roleName")
    const dispatch = useDispatch()
    const [startDate, setStartDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [selectedOption, setSelectedOption] = useState("Sales Bills");
    const searchOptions = ["Barcode", "SalesId", "CustomerName", "agentName"]
    const [searchValue, setSearchValue] = useState('');
    const [userData, setUserData] = useState()
    const [data, setData] = useState({
        myPurchaseData: [],
        invoiceNumber: "",
        customerName: "",
        customerId: "",
        agentId: "",
        agent: "",
        salesPersonName: userData?.name || "",
        lrNumber: "",
        lrDate: "",
        remark: "",
        transport: "",
        grAmount: 0,
        barcode: "",
        designNumber: "",
        boxes: "",
        totalBoxes: 0,
        totalQuantity: 0,
        totalAmount: 0,
        brand: "",
        brandId: "",
        selectedData: "",
        saleQty: 0,
        discount: 0,
        tax: 5,
        pf: 0,
        labour: 0,
        billedGR: false,
        netAmount: 0,
        finalDiscAmt: 0,
        taxAmt: 0,
        taxedAmount: 0,
        discountAmt: 0,
        diabledStatus: false,
        responseData: [],
    });


    useEffect(() => {
        if (storedToken) {
            let decToken = jwt_decode(storedToken);
            setUserData(decToken)
            setData({ ...data, salesPersonName: decToken?.name })
        }
    }, [storedToken])

    const response = useSelector((state) => state.saleData.saleslistbydate)
    const onStartDateChange = (date, dateString) => {
        setStartDate(dateString);
    };

    const onEndDateChange = (date, dateString) => {
        setEndDate(dateString);
    };

    const showSearchData = async () => {
        const reportObj = {
            salePersonId: localStorage.getItem('userId'),
            type: selectedOption,
            searchValue
        };

        dispatch(salesBySearchValuebySalesPersonId(reportObj))
    };

    useEffect(() => {
        showDataOnList()
    }, [])

    const showDataOnList = async () => {
        let today = moment(new Date()).format("YYYY-MM-DD")
        let reportObj
        if (today == endDate) {
            reportObj = {
                startDate: startDate,
                endDate: endDate + " " + moment().format("HH:mm:ss"),
            };
        } else {
            const dateMoment = moment(endDate, 'YYYY-MM-DD');
            const endTime = dateMoment.endOf('day').format('HH:mm:ss');
            const endDateWithEndTime = `${endDate} ${endTime}`;
            reportObj = {
                startDate: startDate,
                endDate: endDateWithEndTime,
            };
        }
        dispatch(getSalesListBydate(reportObj))
    };

    const resetFunction = async () => {
        let startDate = moment(new Date()).format("YYYY-MM-DD")
        let endDate = moment(new Date()).format("YYYY-MM-DD")
        let reportObj = {
            startDate: startDate,
            endDate: endDate + " " + moment().format("HH:mm:ss"),
        };
        await dispatch(getSalesListBydate(reportObj))
        setStartDate(startDate);
        setEndDate(endDate);
    };


    const handleDropdownChange = (value) => {
        console.log('Selected value:', value);
        setSelectedOption(value)
    };

    const handleInputChange = (event) => {
        const newValue = event.target.value;
        setSearchValue(newValue);

    };




    return (
        <>

            <div className="card">
                <div className="card-body">
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <h4
                                className="page-header"
                                style={{ fontWeight: 600, fontSize: "22px", margin: "0px" }}
                            >
                                <span style={{ color: "#52c41a" }}>
                                    {" "}
                                    Sales Bills
                                </span>
                            </h4>
                        </Col>
                    </Row>
                    {/* <Form
                    // onFinish={showSearchData}
                    >
                        <Row gutter={16} className="mt-4">
                            <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                                <Form.Item
                                    name="type"
                                >

                                    <Select
                                        showSearch
                                        className="custom-select-option"
                                        defaultValue={searchOptions[0]}
                                        style={{ width: '100%' }}
                                        onChange={handleDropdownChange}
                                        placeholder="Select Brand"
                                    >
                                        {searchOptions.map(option => (
                                            <Option key={option} value={option}>
                                                {option}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={9} lg={9} xl={8}  >
                                <Input
                                    placeholder="Sale Bill Id, Barcode, Agent Name or CustomerName"
                                    value={searchValue}
                                    onChange={handleInputChange}
                                />
                            </Col>
                            <Col xs={6} sm={6} md={1} lg={1} xl={2} className="mt-4"  >
                                <Button type="primary" className="serach-button w-100 globalSearchButtons" icon={<CloseOutlined />} />
                            </Col>
                            <Col xs={6} sm={6} md={1} lg={1} xl={2} className="mt-4" >
                                <Button onClick={showSearchData} type="primary" className="serach-button w-100 globalSearchButtons" icon={<SearchOutlined />} />
                            </Col>
                        </Row>
                    </Form> */}
                    <Form
                        name="add-Purchase"
                        className="add-Purchase mt-4"
                        onFinish={showDataOnList}
                        initialValues={{ remember: true }}
                    >
                        <div className="datePicker-hideCross">
                            <Row gutter={[16, 0]}>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Tooltip title="Select Start Date">
                                        <Form.Item
                                            name="startDate"
                                        >
                                            {console.log("")}
                                            <DatePicker
                                                style={{ width: "100%" }}
                                                onChange={onStartDateChange}
                                                placeholder="Select Start Date"
                                                value={moment(startDate, dateFormat)}
                                                format={dateFormat}
                                                showClose={false}
                                            />
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Tooltip title="Select End Date">
                                        <Form.Item name="endDate">
                                            {console.log("")}
                                            <DatePicker
                                                style={{ width: "100%" }}
                                                onChange={onEndDateChange}
                                                placeholder="Select End Date"
                                                value={moment(endDate, dateFormat)}
                                                format={dateFormat}
                                            />
                                        </Form.Item>
                                    </Tooltip>
                                </Col>

                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Form.Item>
                                        <Button type="primary" block onClick={resetFunction}>
                                            Reset
                                        </Button>
                                    </Form.Item>
                                </Col>

                                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="close-modal"
                                            block
                                        >
                                            Search
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </div>
            </div>

            <div className="card my-5">
                <div className="card-body">
                    <Table
                        dataSource={response?.details || []}
                        bordered="true"
                        size="middle"
                        scroll={{ y: 400 }}

                    >
                        <Column
                            title="SRN"
                            key="index"
                            render={(value, item, index) => index + 1}
                            width={60}
                        />
                        <Column
                            title="Bill Date"
                            dataIndex="date"
                            key="date"
                            width={210}
                            render={(value, item, index) =>
                                `${moment(item.date).format("YYYY-MM-DD")} (${moment(item.date).format("HH:mm:ss")} )`
                            }
                        />

                        <Column
                            title="Invoice No."
                            dataIndex="invoiceId"
                            key="invoiceId"
                            width={150}
                            render={(value, item, index) => {
                                if (item && item.invoiceId) {
                                    return `S-${item.invoiceId}`
                                }
                            }}
                        />
                        <Column
                            title="Customer Name"
                            dataIndex="c_name"
                            key="c_name"
                            render={(value, item, index) => `${item && item.userId && item.userId.name ? item.userId.name : ""}`}
                            width={190}
                        />
                        <Column
                            title="Agent Name"
                            dataIndex="userId"
                            key="userId"
                            render={(value, item, index) => `${item?.userId?.agentId ? item?.userId?.agentId?.name : ""}`}
                            width={170}
                        />
                        <Column
                            title="Total Boxes"
                            dataIndex="totalBoxes"
                            key="totalBoxes"
                            width={120}
                        />
                        <Column
                            title="Total Qnty"
                            dataIndex="totalQuantity"
                            key="totalQuantity"
                            width={120}
                        />
                        <Column
                            title="Total Amt"
                            dataIndex="totalAmount"
                            key="totalAmount"
                            render={(value, item, index) => parseInt(item.totalAmount).toFixed()}
                            width={100}
                        />
                        <Column
                            title="Gr Amt"
                            dataIndex="grAmountUsed"
                            key="grAmountUsed"
                            render={(value, item, index) => Number(item.grAmountUsed).toFixed()}
                            // render={(value, item, index) => `${item.userId.grAmount.toFixed()}`}   
                            width={100}
                        />
                        <Column
                            title="Net Amt"
                            dataIndex="netAmount"
                            key="netAmount"
                            width={120}
                            render={(value, item, index) => parseInt(item.netAmount).toFixed()}
                        />
                        <Column
                            title="Remark"
                            dataIndex="remark"
                            key="remark"
                            width={120}
                        />
                        <Column
                            title="Verified"
                            dataIndex="isVerified"
                            key="isVerified"
                            width={120}
                            render={(value, item, index) => {
                                return `${item.isVerified}`
                            }}
                        />
                        <Column
                            title="Action"
                            key="action"
                            width={240}
                            render={(text, record) => {
                                if (Id === "PURCHASE_ADMIN" || Id === "ACCOUNTS" || Id === "RECEPTION" || Id === "SALES_PERSON") {
                                    return (
                                        <Row >
                                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                <a>
                                                    <ShowBillProducts invoiceId={record?.invoiceId} data={record} />
                                                </a>
                                            </Col>
                                        </Row>
                                    )
                                } else if (Id === "ADMIN" || Id === "SALES_ADMIN") {
                                    return (
                                        <Row gutter={40}>
                                            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                                <a>
                                                    <ShowBillProducts invoiceId={record.invoiceId} data={record} />
                                                </a>
                                            </Col>
                                            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                                <ViewGenerateBill text={text} record={record} saleObj={this.props.salesOrders} />
                                            </Col>
                                            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                                <Popconfirm
                                                    title="Are you sure to Edit this bill?"
                                                    onConfirm={() => this.updateBill(text)}
                                                    okText="Yes"
                                                    cancelText="No"
                                                    disabled={record?.remark === "Bill Deleted." ? true : false}
                                                >
                                                    <div className="btn">
                                                        <Tooltip title="Edit Bill">
                                                            <EditOutlined />
                                                        </Tooltip>
                                                    </div>
                                                </Popconfirm>
                                            </Col>
                                            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                                <a>
                                                    <VerifyBillProduct invoiceData={[{ invoiceId: record.invoiceId }]} record={record} alreadyVerify={this.success} />
                                                </a>
                                            </Col>

                                            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                                <Popconfirm
                                                    title="Are you sure to delete this bill?"
                                                    onConfirm={() => this.deleteBill(text)}
                                                    okText="Yes"
                                                    cancelText="No"
                                                    disabled={record?.remark === "Bill Deleted." ? true : false}
                                                >
                                                    <div className="btn">
                                                        <Tooltip title="delete Bill">
                                                            <DeleteOutlined />
                                                        </Tooltip>
                                                    </div>
                                                </Popconfirm>
                                            </Col>
                                        </Row>
                                    )
                                }
                                else {
                                    return (
                                        <Row >
                                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                <a>
                                                    <ShowBillProducts invoiceId={record.invoiceId} data={record} />
                                                </a>
                                            </Col>
                                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                <ViewGenerateBill text={text} record={record} saleObj={this.props.salesOrders} />
                                            </Col>
                                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                <Popconfirm
                                                    title="Are you sure to Edit this bill?"
                                                    onConfirm={() => this.updateBill(text)}
                                                    okText="Yes"
                                                    cancelText="No"
                                                    disabled={record?.remark === "Bill Deleted." ? true : false}
                                                >
                                                    <div className="btn">
                                                        <Tooltip title="Edit Bill">
                                                            <EditOutlined />
                                                        </Tooltip>
                                                    </div>
                                                </Popconfirm>
                                            </Col>
                                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                                <a>
                                                    <VerifyBillProduct invoiceData={[{ invoiceId: record.invoiceId }]} record={record} alreadyVerify={this.success} />
                                                </a>
                                            </Col>

                                        </Row>
                                    )
                                }
                            }
                            }
                        />
                        <Column
                            title="Updated Date"
                            dataIndex="updatedAt"
                            key="updatedAt"
                            width={210}
                            render={(value, item, index) => {
                                if (item && item.updatedAt) {
                                    return `${moment(item.updatedAt).format("YYYY-MM-DD")} (${moment(item.updatedAt).format("HH:mm:ss")} )`
                                }
                            }
                            }
                        />
                    </Table>
                </div>
            </div>
        </>
    );
};

export default AddNewSalesBillMobile;