import React, { Component } from "react";
import {
    Table,
    Popconfirm,
    Tooltip,
    Pagination,
    Button,
    Space,
    Input,
    Row,
    Col,
    Select
} from "antd";
import { SearchOutlined, DeleteOutlined } from "@ant-design/icons";
import Search from "../../../util/Search"
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPurchaseBills, getPurchaseBillsBydate, getDeletePurchaseBill } from "../../../redux/actions/purchaseAction";
import Highlighter from "react-highlight-words";
import moment from "moment";
// import ShowBillPurchases from "./ShowBillPurchases";
import DateWiseSearch from "../../../util/DateWiseSearch";
import TopBarProgress from "react-topbar-progress-indicator";
const { Column } = Table;
const { Option } = Select;
const searchOptions = ["Barcode", "PurchaseId", "DealerName", "Alias"]
class DeletePurchase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: "",
            current: 1,
            search: "",
            options: [],
            searchValue: "",
            searchAlias: "",
            totalCount: 0,
            checkDate: false
        };
    }
    componentDidMount() {
        let startDate = moment(new Date()).format("YYYY-MM-DD")
        let endDate = moment(new Date()).format("YYYY-MM-DD")
        let reportObj = {
            startDate: startDate,
            endDate: endDate + " " + moment().format("HH:mm:ss"),
        };
        // this.props.getPurchaseBills(1);
        this.props.getPurchaseBillsBydate(reportObj)
    }

    // componentDidUpdate(prevProps) {
    //   if (prevProps.searchData.data !== this.props.searchData.data) {
    //     this.setState({ totalCount: this.props.saleslistbydate.count })
    //   }
    // }

    handleDropdownChange = (e) => {
    }

    deletPurchase = async (obj) => {
        let reponse = await this.props.getDeletePurchaseBill(obj)
        // if (reponse.status == 200) {
        //     let reportObj = {
        //         startDate: this.state.startDate,
        //         endDate: this.state.endDate + " " + moment().format("HH:mm:ss"),
        //     };
        //     this.props.getPurchaseBillsBydate(reportObj)
        // }
    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        this.handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ marginBottom: 8, display: "block" }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                        className="search-name"
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => this.handleReset(clearFilters, confirm)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : "",
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: (text) =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = (clearFilters, confirm) => {
        clearFilters();
        confirm();
        this.setState({ searchText: "" });
    };
    onChange = (event) => {
        console.log("child component is work", event)
        this.setState({ searchValue: event });
    };
    cancelState = () => {
        this.setState({ searchValue: "" });
    }
    updateDatestatus = () => {
        this.setState({ checkDate: true });
    }
    handleSearchButtonClick = (value) => {
        let obj = {
            searchValue: this.state.searchValue
        }
        this.props.purchaseBySearchValue(obj)
        console.log("obj--------- numberrr", obj)

        // this.props.userByNameOrUsername(obj)
    }

    listData = () => {
        const { purchaseBills, purchasesBillsBydate, searchData } = this.props
        const value = purchasesBillsBydate && purchasesBillsBydate.length > 0 ? purchasesBillsBydate[0] : []

        // if (value.message == "Empty") {
        //   return ([])
        // } else if (this.state.searchValue) {
        //   console.log("check mohit inside if conditionnnn")
        //   console.log("check mohit inside if conditionnnn search data", searchData.data )
        //   return (searchData.data)
        // } else {
        //   return (purchasesBillsBydate.data)
        // }

        if (this.state.searchValue) {
            return (searchData.data)
        } else {
            return (purchasesBillsBydate.data)
        }

    }

    render() {
        const { searchValue } = this.state;
        const { purchaseBills, searchData, purchasesBillsBydate } = this.props
        const search = searchData || []
        return (
            <div  >
                {this.props.progressBarStatus && <TopBarProgress />}
                <Row className="title-row">
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <div className="page-header">Delete Purchase Bill</div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}> */}
                    <Search title="Purchase Bill" placeholder="Dealer Name, PurchaseId or Barcode" searchopt={searchOptions} changeFunction={this.onChange} cancelState={this.cancelState} />
                    {/* </Col> */}
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <DateWiseSearch keyId={"Purchase Bill"} updateDatestatus={this.updateDatestatus} />
                    </Col>
                </Row>
                <div className="card  final-purchases-list">
                    <div className="card-body">
                        <Table
                            dataSource={this.listData()}
                            // dataSource={ searchValue ? search : purchasesBillsBydate.data ? purchasesBillsBydate.data : purchaseBills.data}
                            //dataSource={this.props.purchaseBills && this.props.purchaseBills.data ? this.props.purchaseBills.data: []}
                            bordered="true"
                            size="middle"
                            pagination={{
                                pageSize: 10,
                                total: this.props.purchaseBills && this.props.purchaseBills.count ? this.props.purchaseBills.count : "",
                                onChange: (page) => {
                                    this.props.getPurchaseBills(page);
                                }
                            }}
                            scroll={{ y: 240 }}
                        >
                            <Column
                                title="SRN"
                                key="index"
                                render={(value, item, index) => index + 1}
                                width={60}
                            />
                            <Column
                                title="Purchase Id"
                                dataIndex="purchaseId"
                                key="purchaseId"
                                width={150}
                                {...this.getColumnSearchProps("purchaseId")}
                            />
                            <Column
                                title="Dealer Name"
                                dataIndex="dealerName"
                                key="dealerName"
                                width={150}
                                {...this.getColumnSearchProps("dealerName")}
                            />
                            <Column
                                title="Bill No"
                                dataIndex="billNo"
                                key="billNo"
                                width={120}
                                {...this.getColumnSearchProps("billNo")}
                            />
                            <Column
                                title="C_Date"
                                dataIndex="created_at"
                                key="created_at"
                                width={130}
                                render={(value, item, index) =>
                                    moment(item.created_at).format("YYYY-MM-DD")
                                }
                            />
                            <Column
                                title="B_Date"
                                dataIndex="date"
                                key="date"
                                width={130}
                                render={(value, item, index) =>
                                    moment(item.date).format("YYYY-MM-DD")
                                }
                            />
                            <Column
                                title="Qnty"
                                dataIndex="quantity"
                                key="quantity"
                                width={90}
                            />
                            <Column
                                title="Total Amt"
                                dataIndex="totalAmount"
                                key="totalAmount"
                                render={(value, item, index) =>
                                    parseInt(item.totalAmount).toFixed()
                                }
                                width={100}
                            />
                            <Column
                                title="Disc"
                                dataIndex="discount"
                                key="discount"
                                width={90}
                            />
                            <Column title="Tax" dataIndex="tax" key="tax" width={90} />
                            <Column
                                title="Net Amt"
                                dataIndex="netAmount"
                                key="netAmount"
                                render={(value, item, index) =>
                                    parseInt(item.netAmount).toFixed()
                                }
                                width={120}
                            />
                            <Column
                                title="Action"
                                key="action"
                                width={80}
                                render={(text, record) => (
                                    <Row gutter={[8, 0]}>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Popconfirm
                                                title="Are you sure to Delete this Bill?"
                                                onConfirm={() => this.deletPurchase({ purchaseId: record.purchaseId })}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <div className="btn">
                                                    <Tooltip title=" Delete Bill">
                                                        <DeleteOutlined />
                                                    </Tooltip>
                                                </div>
                                            </Popconfirm>
                                        </Col>
                                    </Row>
                                )}
                            />
                        </Table>{" "}
                    </div>
                </div>
            </div>
        );
    }
}

DeletePurchase.propTypes = {
    getPurchaseBills: PropTypes.func.isRequired,
    getPurchaseBillsBydate: PropTypes.func.isRequired,
    getDeletePurchaseBill: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    purchaseBills: state.purchaseData.purchaseBills,
    searchData: state.purchaseData.purchaseSearchData,
    purchasesBillsBydate: state.purchaseData.purchasesBillsBydate,
    progressBarStatus: state.progressBarReducer.showProgressBar
});

export default connect(mapStateToProps, { getPurchaseBills, getPurchaseBillsBydate, getDeletePurchaseBill })(DeletePurchase);
