
/* /////////////////////////////////////// */
/* 
Date              created by               Modifications
  
14-07-2023        Mohit                    Got some error on barcodechange, so i commit the line no 88



*/
/* /////////////////////////////////////// */


import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { uuid } from "uuidv4";
import debounce from 'lodash.debounce';
import {
  Row,
  Col,
  Checkbox,
  DatePicker,
  Button,
  Form,
  Input,
  Select,
  notification,
  Tooltip,
  Alert,
  message,
  Spin
} from "antd";
import BarcodeReader from "react-barcode-reader";
import { getDealers } from "../../../../../redux/actions/dealerAction";
import {
  getPuchaseUsingBarcodeAndDealer,
  purchaseReturn,
  deletePurchaseReturn
} from "../../../../../redux/actions/purchaseAction";
import { getBrandBySearch } from "../../../../../redux/actions/brandAction";
import { getLookupData } from "../../../../../redux/actions/purchaseAction";
import ShowPurchaseReturnDetails from "./ShowPurchaseReturnDetails";
import { StopOutlined } from "@ant-design/icons";
import LookupModalSales from "../../../sales/LookupModalSales";
import AddedReturnsList from "./AddedReturnsList";
import { ArrowLeftOutlined, LoadingOutlined, } from "@ant-design/icons";

const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;
class AddPurchaseReturn extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      imidiateUpdate: false,
      id: "",
      purchaseReturnData: [],
      showReturnListData: [],
      barcode: "",
      dealerName: "",
      dealerId: "",
      designNumber: "",
      brandName: "",
      brandId: "",
      s: "",
      pd: "",
      selectedData: "",
      disableField: false,
      errors: {},
      maxReturnQty: "",
      isUpdated: false,
      purchaseReturnBID: "",
      remainingQnty: "",
      tax: "",
      discount: "",
      purchasePrice: ""
    };
    this.handleScan = this.handleScan.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.debouncedGetData = debounce(this.getData, 500);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errors !== this.props.errors) {
      this.setState({ errors: "errors" });
      message.error(this.props.errors);
    }
    if (prevProps.purchaseReturnBarcodes !== this.props.purchaseReturnBarcodes) {

      this.setState({
        // quantity: this.props?.purchaseReturnBarcodes?.quantity,
        id: this.props?.purchaseReturnBarcodes?._id,
        purchaseId: this.props?.purchaseReturnBarcodes?.purchaseId,
        MOQ: this.props?.purchaseReturnBarcodes?.MOQ,
        maxReturnQty: Number(this.props?.purchaseReturnBarcodes?.quantity + this.state.quantity || 0),
        // dealerId: this.props?.purchaseReturnBarcodes?.dealerId,
        remainingQnty: this.props?.purchaseReturnBarcodes?.maxReturnQuantity,
        designNumber: this.props?.purchaseReturnBarcodes?.designNumber,
        // brandName : this.props?.purchaseReturnBarcodes?.brand
        tax: this.props?.purchaseReturnBarcodes?.tax,
        discount: this.props?.purchaseReturnBarcodes?.discount,
        purchasePrice: this.props?.purchaseReturnBarcodes?.purchasePrice
      });
    }
  }

  componentWillUnmount = () => {
    this.setState({ imidiateUpdate: false })
    this.props.getPuchaseUsingBarcodeAndDealer(
      "reset",
      "reset"
    );
  }

  handleBlur(e) {
    if (e.currentTarget.value === "0") e.currentTarget.value = "1";
  }
  onChange(event) {
    if (event.target.name === "quantity" && this.state.quantity) {
      if (event.target.value > this.props?.purchaseReturnBarcodes?.maxReturnQuantity) {
        message.warn("Can not input quantity greater then purchased quantity")
        return this.setState({
          quantity: this.props?.purchaseReturnBarcodes?.quantity,
        });
      }
    }
    this.setState({ [event.target.name]: event.target.value });
  }
  getData = (name, value) => {
    if (name == "dealer") this.props.getDealers();
    if (name == "brand") this.props.getBrandBySearch(value);
  };


  onBarcodeChange = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
    // this.barCodeDataFatch(event)
    if (this.state.dealerId !== "") {
      if (event.target.value.length == 9) {
        await this.props.getPuchaseUsingBarcodeAndDealer(
          event.target.value,
          this.state.dealerId
        );
        this.addPurchaseReturn()
      }
      // 
      // this.setState({
      //   quantity: this.props?.purchaseReturnBarcodes?.quantity,
      //   id: this.props?.purchaseReturnBarcodes?._id,
      //   purchaseId: this.props?.purchaseReturnBarcodes?.purchaseId,
      //   MOQ: this.props?.purchaseReturnBarcodes?.MOQ,
      //   maxReturnQty: this.props?.purchaseReturnBarcodes?.quantity,
      //   dealerId: this.props?.purchaseReturnBarcodes?.dealerId,
      //   remainingQnty:  this.props?.purchaseReturnBarcodes?.maxReturnQuantity
      // });
    } else {
      return alert("Select Dealer");
    }
  };


  async handleScan(data) {
    if (data.length == 9 || data.length == 7) {
      await this.props.getPuchaseUsingBarcodeAndDealer(
        data,
        this.state.dealerId
      );
      this.addPurchaseReturn()
    }
  }
  handleChangeDealer = (key, value) => {
    let dealerId = value.value;
    let dealerName = value.name;

    this.setState({
      dealerId: dealerId,
      dealerName: dealerName,
    });
  };

  handleChangeBrand = async (key, value) => {
    let brandName = value.name;
    let brandId = value.value;
    this.setState({ brandName, brandId });
    this.debouncedGetData("brand")
    if (this.state.designNumber) {
      await this.props.getLookupData({
        designNumber: this.state.designNumber,
        brandName,
      });
      this.setState({ s: uuid() });
    }
  };
  createPurchaseReturn = () => {
    this.setState({ imidiateUpdate: true })
    let userId = localStorage.getItem("userId")
    let obj = {
      returnData: this.state.showReturnListData,
      isUpdated: this.state.isUpdated,
      purchaseReturnBID: this.state.purchaseReturnBID,
      userId: userId
    }
    this.props.purchaseReturn(obj).then(response => {
      if (response.status === 200) {
        message.success(response.data)
      }
    }).catch(error => {
      message.error("Something Went Wrong")
    }).finally(() => {
      setTimeout(() => {
        window.location.reload(0)
        // this.setState({ imidiateUpdate: false, });
      }, 1000);
    })
    // [...this.state.purchaseReturnData, this.state.isUpdated]dfef
    // this.props.purchaseReturn(obj);
  };

  addPurchaseReturn = () => {
    this.setState({
      quantity: this.props?.purchaseReturnBarcodes?.MOQ,
      barcode: this.props?.purchaseReturnBarcodes?.barcode,
      maxReturnQty: this.props?.purchaseReturnBarcodes?.maxReturnQuantity
    })

    if (this.state.quantity % this.props?.purchaseReturnBarcodes?.MOQ != 0 || this.state.quantity > this.props?.purchaseReturnBarcodes?.maxReturnQuantity) {
      if (this.state.quantity % this.props?.purchaseReturnBarcodes?.MOQ != 0) {
        message.warn("MOQ Not Matched, Please Check Dealer!");
      }
      else {
        message.warn("Quantity is greater then stock quantity");

      }
      return this.setState({
        quantity: this.props?.purchaseReturnBarcodes?.quantity,
      });
    }
    else {
      this.setState({ disableField: true });
      let returnDefectedObj = {
        quantity: this.state.quantity,
        id: this.state.id,
        MOQ: this.state.MOQ,
        maxReturnQty: this.state.maxReturnQty,
        dealerId: this.state.dealerId

      };
      // this.state.purchaseReturnData.push(returnDefectedObj);
      this.setState({
        purchaseReturnData: [returnDefectedObj, ...this.state.purchaseReturnData],
      });
      //TABLE

      // var newdata =  this.state.showReturnListData.find(obj=>obj.purchaseId == this.state.purchaseId)

      // let showReturnDataDefctiveObj = {
      //   quantity: this.state.quantity,
      //   purchaseId: this.state.purchaseId,
      //   id: this.state.id,
      //   MOQ: this.state.MOQ,
      // };

      // this.setState({
      //   showReturnListData: [
      //     showReturnDataDefctiveObj,
      //     ...this.state.showReturnListData,
      //   ],
      // });
      //
      // ------------------------
      const existingObjIndex = this.state.showReturnListData.findIndex(obj => obj.barcode === this.state.barcode);
      if (existingObjIndex >= 0) {
        const existingObj = this.state.showReturnListData[existingObjIndex];
        if (Number(this.state.quantity) + Number(existingObj.quantity) <= this.state.maxReturnQty) {

          const updatedObj = { ...existingObj, quantity: Number(existingObj.quantity) + Number(this.state.quantity) };
          const updatedListData = [...this.state.showReturnListData];
          updatedListData[existingObjIndex] = updatedObj;

          this.setState({
            showReturnListData: updatedListData,
            purchaseReturnData: updatedListData
          });
        } else {
          message.warn("Cannot Add More Then Maximum Quantity")
        }

      } else {

        const newObj = {
          quantity: Number(this.state.quantity),
          purchaseId: this.state.purchaseId,
          id: this.state.id,
          MOQ: this.state.MOQ,
          barcode: this.state.barcode,
          maxReturnQty: this.state.maxReturnQty,
          dealerId: this.state.dealerId,
          tax: this.state.tax,
          discount: this.state.discount,
          purchasePrice: this.state.purchasePrice
        };

        this.setState({
          showReturnListData: [
            newObj,
            ...this.state.showReturnListData,
          ],
          // purchaseReturnData:[
          //   newObj,
          //   ...this.state.purchaseReturnData,
          // ]
        });
      }

      // ------------------------

      this.setState({
        // quantity: "",
        purchaseId: "",
        brandName: "",
        designNumber: "",
        amount: "",
        barcode: "",
        maxReturnQty: "",
        // dealerId:"",

      });
      this.formRef.current.setFieldsValue({
        brandName: undefined,
      });

    }
  };


  checkBarcodeExists = (barcode) => {
    let exist = this.state.showReturnListData.findIndex(
      (element) => element.barcode === barcode
    );
    return exist;
  };

  selectedData = async (data) => {
    if (this.state.designNumber && this.state.brandName) {
      this.setState({
        selectedData: data,
        barcode: data.barcode,
        quantity: data.quantity,
        purchaseId: data.purchaseId,
        MOQ: data.MOQ,
        id: data._id,
        maxReturnQty: data.quantity,
      });
      if (this.state.dealerName) {
        await this.props.getPuchaseUsingBarcodeAndDealer(
          data.barcode,
          this.state.dealerId
        );
        this.setState({ MOQ: this.props.purchaseReturnBarcodes.MOQ })
      } else {
        alert("Select Dealer");
      }
    }
  };
  onRemoveQnty = (obj) => {
    let index = this.checkBarcodeExists(obj.barcode);
    let temp = this.state.showReturnListData;
    let returnTemp = this.state.purchaseReturnData;
    if (Number(temp[index].quantity) > Number(temp[index].MOQ)) {
      temp[index].quantity = Number(temp[index].quantity) - Number(temp[index].MOQ);
      this.setState({
        showReturnListData: temp,
        purchaseReturnData: returnTemp,
        pd: uuid(),
      });
    } else {
      message.warn("Minimum Return Quantity Reached")
    }
    // returnTemp[index].quantity = Number(returnTemp[index].quantity) - Number(returnTemp[index].MOQ);
  };


  onAddQnty = async (obj) => {
    await this.props.getPuchaseUsingBarcodeAndDealer(obj.barcode, obj.dealerId)
    if (this.state.isUpdated) {

      this.setState({ maxReturnQty: this.props?.purchaseReturnBarcodes?.maxReturnQuantity + (obj.totalQuantity > 0 ? obj.totalQuantity : 0) })
    } else {
      this.setState({ maxReturnQty: this.props?.purchaseReturnBarcodes?.maxReturnQuantity })
    }

    if (obj.purchaseReturnBillId) {
      let index = this.checkBarcodeExists(obj.barcode);
      let temp = this.state.showReturnListData;
      let returnTemp = this.state.purchaseReturnData;
      //check to add not more then purchase quantity
      if (Number(temp[index].quantity) + 1 <= Number(this.state.maxReturnQty)) {

        temp[index].quantity =
          Number(temp[index].quantity) + Number(temp[index].MOQ) - Number(temp[index].MOQ);
        returnTemp[index].quantity =
          Number(returnTemp[index].quantity) + Number(temp[index].MOQ);
        this.setState({
          showReturnListData: temp,
          purchaseReturnData: returnTemp,
          pd: uuid(),
        });
      } else {
        message.warn("Can not return more then stock quantityyy")
      }

    } else {
      let index = this.checkBarcodeExists(obj.barcode);
      let temp = this.state.showReturnListData;
      let returnTemp = this.state.purchaseReturnData;
      //check to add not more then purchase quantity
      if (Number(temp[index].quantity) + 1 <= Number(this.state.maxReturnQty)) {
        temp[index].quantity = Number(temp[index].quantity) + Number(temp[index].MOQ)
        // returnTemp[index].quantity = Number(returnTemp[index].quantity) + Number(returnTemp[index].MOQ);
        this.setState({
          showReturnListData: temp,
          purchaseReturnData: returnTemp,
          pd: uuid(),
        });
      } else {
        message.warn("Can not return more then stock quantity")
      }
    }
  };

  // ------------------------------
  deletePurchase = (id) => {
    let temp = this.state.showReturnListData
    let returnTemp = this.state.purchaseReturnData
    temp = temp.filter(obj => obj.id !== id)
    returnTemp = returnTemp.filter(obj => obj.id !== id)
    this.setState({
      showReturnListData: temp,
      purchaseReturnData: returnTemp,
      // pd: uuid(),
    });
  };
  deletePurchaseReturn = (_id) => {
    this.props.deletePurchaseReturn(_id)

    let temp = this.state.showReturnListData

    temp = temp.filter(obj => obj._id !== _id)
    let returnTemp = this.state.purchaseReturnData
    returnTemp = returnTemp.filter(obj => obj._id !== _id)

    this.setState({
      showReturnListData: temp,
      purchaseReturnData: returnTemp,
      // pd: uuid(),
    });
  };
  // -------------------------------

  componentDidMount() {
    if (
      this.props.history.location.state == undefined ||
      this.props.history.location.state == null
    )
      return null;
    if (
      this.props.history.location.state != undefined ||
      this.props.history.location.state != null
    ) {

      this.setState({
        updateBill: this.props.history.location.state.updateBill,
      });
      let purchaseReturnObj = this.props.history.location.state.purchaseReturnObj;
      if (purchaseReturnObj) {
        this.setState({ isUpdated: true });
        this.setState({ update: true })

      }


      let data = purchaseReturnObj[1];
      let billData = purchaseReturnObj[0];

      let e = 0;
      let f = data.length;
      let finalSaleData = [];
      while (e < f) {
        data[e].details.quantity = data[e].quantity;
        data[e].details.MOQ = data[e].MOQ;
        data[e].details.totalQuantity = data[e].quantity
        data[e].details.purchasePrice = data[e].purchaseDetails.purchasePrice
        data[e].details.tax = data[e].purchaseBillDetails.tax
        data[e].details.discount = data[e].purchaseDetails.discount


        //no of boxes have to be changed   
        //data[e].details.boxes = data[e].quantity / data[e].MOQ;
        data[e].details.purchaseId = data[e].purchaseId;
        //  data[e].details.maxReturnQty = this.state.maxReturnQty;

        // data[e].details.finalAmount = data[e].details.MOQ * data[e].details.wsp;
        data[e].details.dealerId = data[e].dealerId;
        data[e].details._id = data[e]._id;
        //  data[e].details.purchaseRBID = billData._id;

        data[e].details.barcode = data[e].barcode;

        finalSaleData.push(data[e].details);
        ++e;
      }

      this.setState({
        purchaseReturnData: finalSaleData,
        showReturnListData: finalSaleData,
        MOQ: billData.MOQ,
        purchaseReturnBID: billData._id,
        totalBoxes: billData.totalBoxes,
        totalQuantity: billData.totalQuantity,
        totalAmount: billData.totalPrice,

        purchaseReturnBillId: billData.purchaseReturnBillId,
        dealerName: billData.dealerName,
        dealerId: billData.dealerId,

        purchaseId: billData.purchaseId,
        isUpdated: true,

        pd: uuid(),
        // orderedSale: true,

      });
      this.formRef.current.setFieldsValue({
        dealerName: billData.dealerName,
        purchaseId: billData.purchaseId
      });


      return this.props.history.replace({ state: null });
    }
  }

  //  New Fun To Calculatre  - Total Boxes, Quantity, Net Amount
  calculateFinalData = () => {

    let totalBoxes = 0
    let totalQty = 0
    let totalNetAmt = 0
    let totalAmt = 0

    const calCulateNetAmt = (amount, tax, discount, quantity) => {
      let discountedAmt = amount * discount / 100
      let netAmt = amount - discountedAmt
      let taxedAmt = netAmt * tax / 100
      netAmt = netAmt + taxedAmt
      return netAmt * quantity
    }



    for (let index = 0; index < this.state?.showReturnListData?.length; index++) {
      totalBoxes = totalBoxes + Number((this.state.showReturnListData[index].quantity / this.state.showReturnListData[index].MOQ))
      totalQty = totalQty + Number(this.state.showReturnListData[index].quantity)
      totalNetAmt = totalNetAmt + calCulateNetAmt(Number(this.state.showReturnListData[index].purchasePrice), Number(this.state.showReturnListData[index].tax), Number(this.state.showReturnListData[index].discount), Number(this.state.showReturnListData[index].quantity))
      totalAmt = totalAmt + Number(this.state.showReturnListData[index].purchasePrice * this.state.showReturnListData[index].quantity)
    }
    return {
      totalBoxes, totalQty, totalNetAmt, totalAmt
    }
  }


  render() {
    let calculateData = this.calculateFinalData()
    let dealerOptions = this.props.dealers.map((dealer) => {
      return (
        <Option name={dealer.name} value={dealer._id}>
          {dealer.name}
        </Option>
      );
    });

    let brandOptions = this.props?.brandBysearch?.data?.map((brand) => {
      return (
        <Option name={brand.name} value={brand._id}>
          {brand.name}
        </Option>
      );
    });
    var disabled = false;
    if (this.state.disableField) {
      disabled = true;
    }
    return (
      <div className="add-purchase ">
        {this.state.imidiateUpdate ? (
          <Spin indicator={antIcon} />
        ) : (
          <>
            <div className="container-fluid">
              <Row className="title-row">
                <Col xs={22} sm={22} md={22} lg={22} xl={22}>
                  <div className="page-header">Add Purchase Return</div>
                </Col>
                <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                  <Button
                    type="primary"
                    className="save-modal text-white"
                    onClick={() => this.props.history.push("/dagaImpex/purchaseReturnBillList", true)}
                  >
                    <ArrowLeftOutlined /> Back
                  </Button>
                </Col>
              </Row>{" "}
              <div className="card mt-4">
                <div className="card-body">
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form
                        ref={this.formRef}
                        name="add-return-sale"
                        className="add-return-sale"
                        onFinish={this.showDataOnList}
                        initialValues={{ remember: true }}
                      >
                        <Row gutter={[16, 0]}>
                          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Tooltip title="Select Dealer">
                              <Form.Item
                                name="dealerName"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Select Dealer Name !",
                                  },
                                ]}
                              >
                                <Select
                                  disabled={disabled}
                                  showSearch
                                  placeholder="Select Dealer"
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  filterSort={(optionA, optionB) =>
                                    optionA.children
                                      .toLowerCase()
                                      .localeCompare(optionB.children.toLowerCase())
                                  }
                                  onChange={this.handleChangeDealer}
                                  onClick={() => this.getData("dealer")}
                                  onInputKeyDown={() => this.getData("dealer")}
                                >
                                  {dealerOptions}
                                </Select>
                              </Form.Item>
                            </Tooltip>
                          </Col>
                          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Tooltip title="Barcode">
                              <Form.Item
                                //name="barcode"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Barcode Number !",
                                  },
                                ]}
                              >
                                <BarcodeReader
                                  onError={this.handleError}
                                  onScan={this.handleScan}
                                />
                                <Input
                                  placeholder="Enter Barcode Number"
                                  name="barcode"
                                  value={this.state.barcode}
                                  onChange={this.onBarcodeChange}
                                  required
                                />
                              </Form.Item>
                            </Tooltip>
                          </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Tooltip title="Design Number">
                              <Form.Item
                                //name="designNumber"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Enter Design Number !",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Enter Design Number"
                                  name="designNumber"
                                  value={this.state.designNumber}
                                  onChange={this.onChange}
                                  required
                                />
                              </Form.Item>
                            </Tooltip>
                          </Col>
                          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Tooltip title="Select Brand">
                              <Form.Item
                                name="brandName"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Select Brand Name !",
                                  },
                                ]}
                              >
                                <Select
                                  showSearch
                                  placeholder="Select Brand"
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  filterSort={(optionA, optionB) =>
                                    optionA.children
                                      .toLowerCase()
                                      .localeCompare(optionB.children.toLowerCase())
                                  }
                                  onChange={this.handleChangeBrand}
                                  onClick={() => this.debouncedGetData("brand")}
                                  onInputKeyDown={() => this.debouncedGetData("brand")}
                                >
                                  {brandOptions}
                                </Select>
                              </Form.Item>
                            </Tooltip>
                          </Col>

                          <LookupModalSales
                            purchases={this.props.purchases}
                            s={this.state.s}
                            selectedData={this.selectedData}
                          />
                        </Row>
                      </Form>
                      <ShowPurchaseReturnDetails
                        purchaseReturnBarcodes={[this.props.purchaseReturnBarcodes]}
                      />
                      {/* <Row  className="mt-4">
                    <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                      <Tooltip title=" Purchase Id">
                        <Form.Item>
                          <Input
                            placeholder=" Purchase Id"
                            value={this.state.purchaseId}
                            disabled
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Tooltip title="Quantity">
                        <Form.Item>
                          <Input
                            onWheel={(e) => e.target.blur()}
                            placeholder="Enter Quantity Number"
                            name="quantity"
                            type="number"
                            //  value={this.state.quantity}
                            onChange={this.onChange}
                            onBlur={this.handleBlur}
                            min={this.props?.purchaseReturnBarcodes?.MOQ}
                            step="1"
                            max={this.props?.purchaseReturnBarcodes?.maxReturnQuantity}
                            required
                          />
                        </Form.Item>
                      </Tooltip>
                    </Col>
                  </Row>
                  <Row gutter={[8, 0]} className="mt-3">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="close-modal"
                        block
                        onClick={this.addPurchaseReturn}
                      >
                        Add Return
                      </Button>
                    </Col>
                  </Row> */}
                      <AddedReturnsList
                        onAddQnty={this.onAddQnty}
                        onRemoveQnty={this.onRemoveQnty}
                        quantity={this.state.quantity}
                        data={this.state.showReturnListData}
                        key={this.state.pd}
                        deletePurchase={this.deletePurchase}
                        deletePurchaseReturn={this.deletePurchaseReturn}
                      />
                      {this.state?.showReturnListData?.length > 0 &&
                        <Row gutter={[16, 0]} className="mt-4">
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Tooltip title="Total Quantity">
                              <Input
                                placeholder="Total Quantity"
                                name="barcode"
                                value={calculateData?.totalQty}
                                readOnly
                              />
                            </Tooltip>
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Tooltip title="Total Boxes">
                              <Input
                                placeholder="Total Boxes"
                                name="barcode"
                                value={calculateData?.totalBoxes}
                                readOnly
                              />
                            </Tooltip>
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Tooltip title="Total Amount">
                              <Input
                                placeholder="Total Amount"
                                name="barcode"
                                value={Number(calculateData?.totalAmt).toFixed()}
                                readOnly
                              />
                            </Tooltip>
                          </Col>
                          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Tooltip title="Total Net Amount">
                              <Input
                                placeholder="Total Net Amount"
                                name="barcode"
                                value={Number(calculateData?.totalNetAmt).toFixed()}
                                readOnly
                              />
                            </Tooltip>
                          </Col>
                        </Row>
                      }
                      <Row gutter={[8, 0]} className="mt-3">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          {(() => {
                            if (this.state.purchaseReturnData.length === 0) {
                              return (

                                <Button
                                  type="primary"
                                  className="save-modal text-white"
                                  disabled
                                  block
                                >
                                  Create Purchase Return <StopOutlined />
                                </Button>


                              );
                            } else {
                              if (this.state.update) {
                                return (
                                  <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="save-modal"
                                    block
                                    onClick={this.createPurchaseReturn}
                                  >
                                    Update Purchase Return
                                  </Button>

                                );
                              } else {
                                return (
                                  <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="save-modal"
                                    block
                                    disabled={this.state.imidiateUpdate}
                                    onClick={this.createPurchaseReturn}
                                  >
                                    Create Purchase Return
                                  </Button>
                                );
                              }
                            }
                          })()}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </>
        )}

      </div>
    );
  }
}
AddPurchaseReturn.propTypes = {
  getDealers: PropTypes.func.isRequired,
  getPuchaseUsingBarcodeAndDealer: PropTypes.func.isRequired,
  getBrandBySearch: PropTypes.func.isRequired,
  getLookupData: PropTypes.func.isRequired,
  purchaseReturn: PropTypes.func.isRequired,
  deletePurchaseReturn: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => (
  {
    dealers: state.dealersData.dealers,
    purchaseReturnBarcodes: state.purchaseData.purchaseReturnBarcodes,
    brands: state.brandsData.brands,
    brandBysearch: state.brandsData.brandBysearch,
    purchases: state.purchaseData.purchases,
    errors: state.errorData.error,
  }
);

export default connect(mapStateToProps, {
  getDealers,
  getPuchaseUsingBarcodeAndDealer,
  getBrandBySearch,
  getLookupData,
  purchaseReturn,
  deletePurchaseReturn
})(AddPurchaseReturn);
