import React, { Component } from "react";
import {
  Table,
  Popconfirm,
  Tooltip,
  Pagination,
  Button,
  Space,
  Input,
  Row,
  Col,
  Switch,
  Tag,
  message,
} from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import UpdateBrand from "./UpdateBrand";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Search from "../../../../util/Search";
import {
  deleteBrand,
  getBrands,
  changeBrandStatus,
  activeAndInactiveBrandStatus,
  getPaginatedList,
  getBrandBySearch
} from "../../../../redux/actions/brandAction";
import Highlighter from "react-highlight-words";
import AddBrandImages from "./AddBrandImages";
import TopBarProgress from "react-topbar-progress-indicator";
import gifLoader from "../../../../assets/images/giphy.gif"

const searchOptions = ["Brands"]
const { Column } = Table;

class BrandList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      options: [],
      searchValue: "",
    };
    this.onStatusChange = this.onStatusChange.bind(this);
  }
  componentDidMount() {
    // this.props.getPaginatedList(1);
    this.props.getBrands();
  }
  onDeleteClick = (id) => {
    this.props.deleteBrand(id);
  };

  handlePaginationChange = (page) => {
    this.setState({ current: page });
    this.props.getPaginatedList(page);
  };

  onStatusChange = async (id, status) => {
    var count = 0;

    if (status == "ON") {
      let newStatus = {
        _id: id,
        status: "OFF",
      };
      await this.props.changeBrandStatus(newStatus);
    } else {
      let newStatus = {
        _id: id,
        status: "ON",
      };

      await this.props.changeBrandStatus(newStatus);
    }
    await this.props.getBrands()
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };

  cancelState = () => {
    this.setState({ searchValue: "" });
  }
  onChange = (event) => {
    this.setState({ searchValue: event });
  };

  onActiveInactiveChange = async (data) => {
    let requestObj = {
      id: data._id,
      brandStatus: !data.brandStatus
    };

    await this.props.activeAndInactiveBrandStatus(requestObj);
    await this.props.getBrands();

    setTimeout(() => {
      this.handlePaginationChange(this.state.current)
    }, 500);
  };

  render() {
    const { searchValue } = this.state;
    const { paginatedList, brandBysearch } = this.props;
    const search = brandBysearch.data || []
    const brands = this.props.brands?.count;
    const brandSearchCount = brandBysearch?.data?.length;

    return (
      <div>
        {/* <Row  className="mt-4">
          <Search title="Brands" placeholder="Brands" searchopt={searchOptions} cancelState={this.cancelState} changeFunction={this.onChange} />
        </Row> */}
        <div className="card mt-4">
          {/* {this.props.progressBarStatus && <TopBarProgress />} */}
          {this.props && this.props?.brands && this.props?.brands.length > 0 ? <>
            <div className="card-body">
              <Table
                dataSource={this.props?.brands}
                bordered="true"
                size="middle"
                // pagination={{
                //   pageSize: 100,
                //   total: searchValue ? brandSearchCount : brands,
                //   onChange: (page) => {
                //     this.handlePaginationChange(page)
                //   },
                // }}
                pagination={{
                  pageSize: 100,
                  total: this.props?.brands.length,
                }}
                scroll={{ y: 400 }}
              >
                <Column
                  title="Name"
                  dataIndex="name"
                  key="name"
                  width={180}
                  {...this.getColumnSearchProps("name")}
                />

                <Column title="Markup" dataIndex="markup" width={150} key="markup" />
                <Column
                  title="Active/Inactive Status"
                  dataIndex="brandStatus"
                  key="brandStatus"
                  width={150}
                  render={(brandStatus) => (
                    <>
                      {(() => {
                        if (brandStatus == true) {
                          return (
                            <Tag color="green" key={brandStatus}>
                              ON
                            </Tag>
                          );
                        } else {
                          return (
                            <Tag color="volcano" key={brandStatus}>
                              OFF
                            </Tag>
                          );
                        }
                      })()}
                    </>
                  )}
                />
                <Column
                  title="Action"
                  key="action"
                  // {...this.getPaginatedList()}
                  width={275}
                  render={(text, record) => (
                    <Row gutter={[8, 0]} style={{}} align="center">
                      <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                        <UpdateBrand brands={text} />
                      </Col>
                      <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                        <Popconfirm
                          title="Are you sure to delete this Brand?"
                          onConfirm={() => this.onDeleteClick(text._id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <div className="btn">
                            <Tooltip title="Delete Brand">
                              <DeleteOutlined />
                            </Tooltip>
                          </div>
                        </Popconfirm>
                      </Col>
                      <Col xs={4} sm={6} md={4} lg={4} xl={4}>
                        <Tooltip title="Active/Inactive Brand">
                          <div className="btn">
                            <Switch
                              size="small"
                              defaultChecked={text.brandStatus == true ? true : false}
                              onChange={(page) => (
                                this.onActiveInactiveChange(text, this.state.current)
                              )
                              }
                            />
                          </div>
                        </Tooltip>
                      </Col>

                      <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                        <AddBrandImages data={text} />
                      </Col>
                      {(() => {
                        if (text.image) {
                          return (
                            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                              <div className="btn">
                                <Tooltip title="E-COM Visible">
                                  <Switch
                                    size="small"
                                    checked={
                                      text.status == "ON" ? true : false
                                    }
                                    onChange={() =>
                                      this.onStatusChange(text._id, text.status)
                                    }
                                  />
                                </Tooltip>
                              </div>
                            </Col>
                          );
                        }
                      })()}

                    </Row>
                  )}
                />
              </Table>{" "}
              {/**<div className="float-right mt-3">
            <Pagination
              defaultCurrent
              defaultPageSize
              total="2"
              //   total={totalPages}
              onChange={this.onChangePage}
              showLessItems="true"
            />
          </div> */}
            </div>
          </> : <>
            <div className="d-flex justify-content-center align-items-center">
              <img src={gifLoader} width={"300px"} height={"300px"} />
            </div>
          </>}
        </div>
      </div>
    );
  }
}

BrandList.propTypes = {
  getBrands: PropTypes.func.isRequired,
  getPaginatedList: PropTypes.func.isRequired,
  getBrandBySearch: PropTypes.func.isRequired,
  deleteBrand: PropTypes.func.isRequired,
  changeBrandStatus: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  activeAndInactiveBrandStatus: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  brands: state.brandsData.brands,
  paginatedList: state.brandsData.paginatedList,
  brandBysearch: state.brandsData.brandBysearch,
  progressBarStatus: state.progressBarReducer.showProgressBar
});

export default connect(mapStateToProps, {
  getBrands,
  deleteBrand,
  changeBrandStatus,
  activeAndInactiveBrandStatus,
  getPaginatedList,
  getBrandBySearch
})(BrandList);