import { Select, Tag, Button } from 'antd';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation, useHistory, withRouter } from 'react-router-dom';
import {
  getBrands,
} from '../../redux/actions/brandAction';
import {
  getAllStyles
} from "../../redux/actions/styleAction";
import {
  fetchProductsByFiltereing,
  fetchFilterProductSidebarList,
  fetchAllProductsSidebarList
} from '../../redux/actions/productActions';
import { SearchOutlined } from '@ant-design/icons';
import { connect } from "react-redux";
import TopBarProgress from "react-topbar-progress-indicator";

const { Option } = Select;

const SearchBar = ({ brands, styles }) => {
  const locationHook = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedStyle, setSelectedStyle] = useState(null);
  const [selectedBrands, setSelectedBrand] = useState(null)
  const [searchBarWidth, setSearchBarWidth] = useState(250)
  const [selectedValue, setSelectedValue] = useState(null)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    dispatch(getBrands());
    dispatch(getAllStyles());
    // if (history.location?.state?.selectedBrands) {
    //   setSelectedValue(history.location?.state?.selectedBrands?.name)
    // }
    // if (history.location?.state?.selectedBrands) {
    //   setSelectedValue(history.location?.state?.selectedStyles?.name)
    // }
  }, [dispatch]);


  useEffect(() => {
    if (history.location?.state?.selectedBrands) {
      setSelectedBrand(history.location?.state?.selectedBrands?.name)
      // setSelectedValue(history.location?.state?.selectedBrands?.name)
    }
    if (history.location?.state?.selectedStyles) {
      setSelectedStyle(history.location?.state?.selectedStyles?._id)
      setSelectedCategory(history.location?.state?.selectedStyles?.categoryId._id)
      // setSelectedValue(history.location?.state?.selectedStyles?.name)
    }

  }, [styles, brands]);


  const handleSearch = (value) => {
    setOptions(value ? searchResult(value) : []);
  };

  const handleSelect = async (value, option) => {
    setLoader(true)
    let obj = {};
    const combinedArray = [...brands, ...styles]
    const selectedItem = combinedArray.find((val) => val?._id == value)
    if (selectedItem?.categoryId?._id) {
      setSelectedCategory(selectedItem?.categoryId?._id);
      setSelectedStyle(selectedItem?._id);
      setSelectedValue(selectedItem?.name)
      obj = {
        category: [selectedItem?.categoryId._id],
        style: [selectedItem?._id],
        brand: [],
        color: [],
        size: [],
      };
      const params = new URLSearchParams(locationHook.search);
      params.set('All', true);
      params.set('limit', 1);
      params.set('category', selectedItem?.categoryId._id);
      params.set('style', selectedItem?._id);
      params.delete('brand');
      params.delete('color');
      params.delete('size');

      // history.push({
      //   pathname: '/shop-grid-standard',
      //   search: params.toString(),
      //   state: {
      //     // stateData: 'Style',
      //     // selectTypeData: styles,
      //     loading: true,
      //     selectedStyles : selectedItem
      //   },
      // });

      const response = await dispatch(fetchFilterProductSidebarList({
        category: obj.category, style: [],
        brand: [],
        color: [],
        size: [],
      }));
      const styles = await response?.data;

      if (styles) {
        history.push({
          pathname: '/shop-grid-standard',
          search: params.toString(),
          state: {
            stateData: 'Style',
            selectTypeData: styles,
            loading: true,
            selectedStyles: selectedItem
          },
        });
        await dispatch(fetchProductsByFiltereing(obj));
        await dispatch(fetchFilterProductSidebarList(obj));

        console.log("styles-123", styles, selectedItem, params.toString());
        history.push({
          pathname: '/shop-grid-standard',
          search: params.toString(),
          state: {
            stateData: 'Style',
            selectTypeData: styles,
            loading: false,
            selectedStyles: selectedItem
          },
        });


      }

    } else {
      obj = {
        category: [],
        style: [],
        brand: [selectedItem?._id],
        color: [],
        size: [],
      };
      setSelectedValue(selectedItem?.name)

      const params = new URLSearchParams(locationHook.search);
      params.set('All', true);
      params.set('limit', 1);
      params.set('brand', selectedItem?._id);
      params.delete('style');
      params.delete('color');
      params.delete('size');
      params.delete('category');
      // history.push({
      //   pathname: '/shop-grid-standard',
      //   search: params.toString(),
      //   state: {
      //     // stateData: 'Brand',
      //     // selectTypeData: brands,
      //     loading: true,
      //     selectedBrands : selectedItem
      //   },
      // });


      let response = await dispatch(fetchAllProductsSidebarList());
      let brands = await response;
      if (brands) {
        //  console.log("brands", brands);
        history.push({
          pathname: '/shop-grid-standard',
          search: params.toString(),
          state: {
            stateData: 'Brand',
            selectTypeData: brands,
            loading: true,
            selectedBrands: selectedItem
          },
        });
        await dispatch(fetchProductsByFiltereing(obj));
        await dispatch(fetchFilterProductSidebarList(obj));
        console.log("params", params);
        history.push({
          pathname: '/shop-grid-standard',
          search: params.toString(),
          state: {
            stateData: 'Brand',
            selectTypeData: brands,
            loading: false,
            selectedBrands: selectedItem
          },
        });

      }

    }
    setLoader(false)
  };

  const searchResult = (query) => {
    let items = [];
    if (brands && styles) {
      const newBrands = brands.map((data) => ({
        _id: data._id,
        name: data.name,
        type: 'brand'
      }));
      const newStyles = styles.map((data) => ({
        _id: data._id,
        name: data.name,
        categoryName: data?.categoryId?.name,
        categoryId: data?.categoryId?._id,
        type: 'style'
      }));

      items = [...newBrands, ...newStyles];
    }

    return items
      .filter(item => item.name.toLowerCase().startsWith(query.toLowerCase()))
      .map(item => ({
        value: item._id,
        label: (
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {item.name}
            </div>
            {item.categoryName && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Tag style={{ fontSize: '8px', width: 'fit-content', border: 'none', background: 'none' }} color="success">
                  {item.categoryName}
                </Tag>
              </div>
            )}
          </div>
        )
      }));
  };


  return (
    <>
      {loader && <TopBarProgress />}
      <Select
        showSearch
        // style={{ width: 200 }}
       className='select-brands rounded-4 w-100'
        placeholder="Enter Brand Or Style"
        onSearch={handleSearch}
        onSelect={handleSelect}
        filterOption={false}
        value={selectedValue}
        suffixIcon={<Button type="" className='search-brands rounded-3' icon={<SearchOutlined className='' />} />}
        options={options}
      />
    </>

  );
};

SearchBar.propTypes = {
  brands: PropTypes.array.isRequired,
  styles: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  brands: state.brandsData.brands,
  styles: state.styleData.styles,
});

export default
  connect(mapStateToProps, {
    fetchProductsByFiltereing,
    fetchAllProductsSidebarList,
    fetchFilterProductSidebarList
  })(withRouter(SearchBar));