import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Modal, Button, Form, Input, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { message } from "antd";
import { addFloor } from "../../../../redux/actions/floorAction";

class AddFloor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      name: "",
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleModalCancel = () => {
    this.setState({ visible: false });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.errors !== this.props.errors) {
      //Perform some operation here

      this.setState({ errors: this.props.errors });
      message.error(this.props.errors.message);
    }
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onSubmit(event) {
    // event.preventDefault();
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 1000);

    let floorObj = {
      name: this.state.name.trim(),
    };
    this.props.addFloor(floorObj);

    setTimeout(() => {
      this.setState({ fileList: [] });
    }, 1000);
  }

  render() {
    const { visible, loading } = this.state;

    return (
      <Row>
        <Col span={24}>
          <div className="add-button">
            <Button
              className="btn-admin btn-subCategory"
              type="primary"
              onClick={this.showModal}
            >
              Add Floor
            </Button>
            <Modal
              visible={visible}
              onOk={this.handleOk}
              onCancel={this.handleModalCancel}
              title="Add Floor"
              style={{ top: 20 }}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              destroyOnClose={true}
            >
              <Form
                name="add-Floor"
                className="add-Floor"
                onFinish={this.onSubmit}
                initialValues={{
                  remember: true,
                }}
              >
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Floor Name !",
                    },
                  ]}
                >
                  <Input
                    autoFocus
                    placeholder="Floor Name"
                    name="name"
                    value={this.state.name}
                    onChange={this.onChange}
                  />
                </Form.Item>

                <Form.Item className="float-right">
                  <Button
                    className="close-modal mr-3"
                    onClick={this.handleModalCancel}
                  >
                    Close
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="ok-modal"
                    loading={loading}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
          </div>
        </Col>
      </Row>
    );
  }
}

AddFloor.propTypes = {
  addFloor: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export default connect(null, { addFloor })(AddFloor);
