import React, { Component } from "react";
import { Table, Button, Space, Input, Tag } from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
// import { getTopCustomerReports } from "../../../../redux/actions/getTopCustomerReports";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { CSVLink } from "react-csv";
const { Column } = Table;
const data = [];

export class InactiveCustomerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      data: [],
    };
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  this.setState({
                    searchText: selectedKeys[0],
                    searchedColumn: dataIndex,
                  });
                }}
              >
                Filter
              </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };

  render() {
    console.log("#this.props.reports", this.props.reports);
    const { inActiveCustomerReport } = this.props
    const headers = [
      { label: "Bill Date", key: "date" },
      { label: "Purchase Id", key: "purchaseId" },
      { label: "Discount", key: "discount" },
      { label: "Tax", key: "tax" },
      { label: "Qnty", key: "quantity" },
      { label: "Total Amount", key: "totalAmount" },
      { label: "Net Amount", key: "netAmount" },
    ];

    return (
      <>
        <div className="card mt-2 final-purchases-list">
          <div className="card-body">
            <Table
              dataSource={inActiveCustomerReport && inActiveCustomerReport.data ? inActiveCustomerReport.data : []}
              bordered="true"
              size="middle"
              scroll={{ y: 240 }}
              pagination={false}
            //   pagination={{
            //     pageSize: 10,
            //     total: this.props.reports.count,
            //     onChange: (page) => {
            //       this.props.getStockAgeingReports(page);
            //     },
            //   }}
            >
              <Column
                title="SRN"
                key="index"
                render={(value, item, index) => index + 1}
                width={60}
              />
              <Column
                title="Created Date"
                dataIndex="created_at"
                key="created_at"
                width={90}
                render={(value, item, index) =>
                  moment(item.created_at).format("YYYY-MM-DD")
                }
              />
              <Column
                title="Name"
                {...this.getColumnSearchProps("name")}
                dataIndex="name"
                key="name"
                width={100}
              />
              <Column
                title="User Name"
                {...this.getColumnSearchProps("username")}
                dataIndex="username"
                key="username"
                width={130}
              />
              <Column
                title="Email"
                dataIndex="email"
                key="email"
                width={100}
              />
              <Column
                title="Agent Name"
                key="agentId"
                width={100}
                render={(value, item, index) => {
                  return item?.agentId && item?.agentId?.name ? item.agentId.name : "";
                }}
              />
              <Column title="Mobile Number" dataIndex="mobileNumber" key="mobileNumber" width={100} />

            </Table>
          </div>
        </div>
        {/* {(() => {
          if (this.props.reports.length > 0) {
            return (
              <Button
                type="primary"
                htmlType="submit"
                className="save-modal mt-4"
                block
              >
                <CSVLink
                  filename={`${this.props.startDate} to ${this.props.endDate}.csv`}
                  data={this.props.reports}
                  headers={headers}
                >
                  Download CSV
                </CSVLink>
              </Button>
            );
          }
        })()} */}
      </>
    );
  }
}

InactiveCustomerList.propTypes = {
  // getTopCustomerReports: PropTypes.func.isRequired,
};


const mapStateToProps = (state) => ({
  inActiveCustomerReport: state.reportData.inActiveCustomerReport,
});


export default connect(mapStateToProps, {
  // getTopCustomerReports,
})(InactiveCustomerList);
