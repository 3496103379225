import React, { Component } from "react";
import { Row, Col, Tooltip, Form, Select, DatePicker, Button } from "antd";
import NonMovingItemsReportList from "./NonMovingItemsReportList";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { getNonMovingItemsReports } from "../../../../redux/actions/reportsActions";
import TopBarProgress from "react-topbar-progress-indicator";
import BackButton from "../../Report/Button";
const dateFormat = "YYYY-MM-DD";

class NonMovingItemsReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment(new Date()).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),
    };
  }

  componentDidMount = () => {
    this.showDataOnList()
  }

  onSellingDateChange = (date, dateString) => {
    this.setState({ startDate: dateString });
  };
  onPurchaseDateChange = (date, dateString) => {
    this.setState({ endDate: dateString });
  };
  showDataOnList = () => {
    let today = moment(new Date()).format("YYYY-MM-DD")
    if (today == this.state.endDate) {
      let reportObj = {
        startDate: this.state.startDate,
        endDate: this.state.endDate + " " + moment().format("HH:mm:ss"),
      };
      this.props.getNonMovingItemsReports(reportObj);
    } else {
      const dateMoment = moment(this.state.endDate, 'YYYY-MM-DD');
      const endTime = dateMoment.endOf('day').format('HH:mm:ss');
      const endDateWithEndTime = `${this.state.endDate} ${endTime}`;
      let reportObj = {
        startDate: this.state.startDate,
        endDate: endDateWithEndTime,
      };
      this.props.getNonMovingItemsReports(reportObj);
    }
  };

  render() {
    return (
      <div className="subCategory-dashboard">
        {this.props.progressBarStatus && <TopBarProgress />}
        <div className="container-fluid category-list">
          <Row className="title-row">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row gutter={24}>
                <Col span={12}>
                  <div className="page-header">Non Moving Items Report</div>
                </Col>
                <Col span={12}>
                  <BackButton />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form
                name="add-NonMovingItems"
                className="add-NonMovingItems"
                onFinish={this.showDataOnList}
                initialValues={{ remember: true }}
              >
                <Row gutter={[16, 0]} className="mt-4">
                  <Col xs={12} sm={12} md={10} lg={10} xl={10}>
                    <Tooltip title="Select Selling Date">
                      <Form.Item
                        name="sellingDate"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Selling Date !",
                          },
                        ]}
                        initialValue={moment()}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          onChange={this.onSellingDateChange}
                          placeholder="Select Selling Date"
                          format={dateFormat}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={12} sm={12} md={10} lg={10} xl={10}>
                    <Tooltip title="Select Purchase Date">
                      <Form.Item
                        name="purchaseDate"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Purchase Date !",
                          },
                        ]}
                        initialValue={moment()}
                      >
                        <DatePicker
                          disabledDate={(current) => {
                            return current && current > Date.now();
                          }}
                          style={{ width: "100%" }}
                          onChange={this.onPurchaseDateChange}
                          placeholder="Select Purchase Date"
                          format={dateFormat}
                        />
                      </Form.Item>
                    </Tooltip>
                  </Col>
                  <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="close-modal"
                        block
                      >
                        Search
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <NonMovingItemsReportList
            reports={this.props.reports}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
          />
        </div>
      </div>
    );
  }
}
NonMovingItemsReport.propTypes = {
  getNonMovingItemsReports: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  reports: state.reportData.nonmovingReport,
  progressBarStatus: state.progressBarReducer.showProgressBar
});

export default connect(mapStateToProps, {
  getNonMovingItemsReports,
})(NonMovingItemsReport);
