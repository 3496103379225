import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Tooltip,
  Input,
  Popconfirm,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  addAgent,
  getAgents,
  getAgentsList,
  updateAgent,
} from "../../../../redux/actions/userAction";
import { useDispatch } from "react-redux";
import { EditOutlined } from "@ant-design/icons";

const UpdateAgent = ({ agentData, currentPage }) => {
  const [visible, setVisble] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    name: "",
    mobileNumber: "",
    email: "",
  });
  const dispatch = useDispatch();

  const showModal = () => {
    setData({
      name: agentData?.name,
      mobileNumber: agentData?.mobileNumber,
      email: agentData?.email,
    });
    setVisble(true);
  };

  const onNameChange = (event) => {
    const name = event.target.value;
    setData((prevData) => ({
      ...prevData,
      name: name,
    }));
  };

  const onMobileChange = (event) => {
    const mobileNumber = event.target.value;
    setData((prevData) => ({
      ...prevData,
      mobileNumber: mobileNumber,
    }));
  };

  const onEmailChange = (event) => {
    const email = event.target.value;
    setData((prevData) => ({
      ...prevData,
      email: email,
    }));
  };

  const onSubmit = async () => {
    setLoading(false);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    const agentObj = {
      _id: agentData?._id,
      name: data.name,
      mobileNumber: data.mobileNumber,
      email: data.email,
    };
    await dispatch(updateAgent(agentObj)).then((respo) => {
      if (respo && respo.status === 200) {
        dispatch(getAgentsList(currentPage));
      }
    });
    handleModalCancel();
  };

  const handleModalCancel = () => {
    setVisble(false);
    setData({ name: "", email: "", mobileNumber: "" });
  };

  return (
    <>
      <Popconfirm
        title="Are you sure to Update this Agent?"
        onConfirm={() => {
          showModal();
        }}
        okText="Yes"
        cancelText="No"
      >
        <div className="btn">
          <Tooltip title="Edit Agent">
            <EditOutlined />
          </Tooltip>
        </div>
      </Popconfirm>

      <Modal
        open={visible}
        onCancel={handleModalCancel}
        title="Update Agent"
        style={{ top: 20 }}
        width={800}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        destroyOnClose={true}
      >
        <Form
          name="update-Agent"
          className="add-Agent"
          onFinish={onSubmit}
          initialValues={data}
        >
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label="Agent Name"
                name="name"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Agent Name !",
                  },
                ]}
              >
                <Input
                  placeholder="Agent Name"
                  name="name"
                  value={data.name}
                  onChange={onNameChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label="Mobile Number"
                name="mobileNumber"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                labelAlign="left"
                rules={[
                  {
                    required: false,
                  },
                  {
                    validator: (_, value) => {
                      if (value && value.toString().length != 10) {
                        return Promise.reject("Mobile must be 10-digits!");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  placeholder="Mobile Number"
                  type="number"
                  value={data.mobileNumber}
                  onChange={onMobileChange}
                />
              </Form.Item>
            </Col>
          </Row>
          {console.log("data", data)}
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label="Email"
                name="email"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                labelAlign="left"
              >
                <Input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={data.email}
                  onChange={onEmailChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="ok-modal"
                  loading={loading}
                  block
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateAgent;
