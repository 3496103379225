import React, { Component } from "react";
import { Row, Col, Breadcrumb, AutoComplete, Button } from "antd";
import PurchaseList from "./PurchaseList";
import { NavLink } from "react-router-dom";
import DateWiseSearch from "../../../../util/DateWiseSearch";

export default class Purchase extends Component {
  render() {
    return (
      <div className="subCategory-dashboard">
        <div className="container-fluid category-list">
          <Row className="title-row">
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="page-header">Purchases</div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <NavLink to="/dagaImpex/addPurchase">
                <Button
                  className="btn-admin btn-subCategory add-button"
                  type="primary"
                >
                  Add Purchase
                </Button>
              </NavLink>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <DateWiseSearch keyId={"Purchases"} />
            </Col>
          </Row>
          <PurchaseList />
        </div>
      </div>
    );
  }
}
