import React, { Component } from "react";
import {
  Table,
  Popconfirm,
  Tooltip,
  Tag,
  Button,
  Space,
  Input,
  Col,
  Row,
  Switch,
  message,
} from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import UpdateCategory from "./UpdateCategory";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  deleteCategory,
  getCategories,
  changeCategoryStatus,
} from "../../../../redux/actions/categoryAction";
import Highlighter from "react-highlight-words";
import TopBarProgress from "react-topbar-progress-indicator";
const { Column } = Table;

class CategoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      options: [],
    };
  }
  onDeleteClick = (id) => {
    this.props.deleteCategory(id);
  };
  onChangePage = () => {
    // this.setState({ page: page });
    // this.props.getCategories(page);
  };
  componentDidMount() {
    this.props.getCategories();
  }
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };
  onStatusChange(id, status) {
    var count = 0;

    if (status == "ON") {
      let newStatus = {
        _id: id,
        status: "OFF",
      };
      this.props.changeCategoryStatus(newStatus);
    } else {
      for (var i = 0; i < this.props.categories.length; i++) {
        if (this.props.categories[i].status === "ON") {
          count++;
        }
      }
      if (count === 6) {
        message.error("Higlight limit exceed max can be 6");
        return setTimeout(() => {
          window.location.reload(0);
        }, 1000);
      }
      let newStatus = {
        _id: id,
        status: "ON",
      };

      this.props.changeCategoryStatus(newStatus);
    }
  }
  render() {
    return (
      <div className="card mt-4">
        {this.props.progressBarStatus && <TopBarProgress />}
        <div className="card-body">
          <Table
            dataSource={this.props.categories}
            bordered="true"
            size="middle"
            pagination={false}
            scroll={{ y: 240 }}
          >
            <Column
              title="Name"
              dataIndex="name"
              key="name"
              width={180}
              {...this.getColumnSearchProps("name")}
            />
            <Column
              title="Status"
              dataIndex="status"
              key="status"
              width={90}
              render={(status) => (
                <>
                  {(() => {
                    if (status == "OFF") {
                      return (
                        <Tag color="volcano" key={status}>
                          {status}
                        </Tag>
                      );
                    } else {
                      return (
                        <Tag color="green" key={status}>
                          {status}
                        </Tag>
                      );
                    }
                  })()}
                </>
              )}
            />
            <Column
              title="Action"
              key="action"
              width={160}
              render={(text, record) => (
                <Row gutter={8}>
                  <Col span={8}>
                    <UpdateCategory categories={text} />
                  </Col>
                  <Col span={8}>
                    <Popconfirm
                      title="Are you sure to delete this Category?"
                      onConfirm={() => this.onDeleteClick(text._id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <div className="btn">
                        <Tooltip title="Delete Category">
                          <DeleteOutlined />
                        </Tooltip>
                      </div>
                    </Popconfirm>
                  </Col>
                  <Col span={8}>
                    <div className="btn">
                      <Tooltip title="Change Status">
                        <Switch
                          size="small"
                          defaultChecked={text.status == "ON" ? true : false}
                          onChange={() =>
                            this.onStatusChange(text._id, text.status)
                          }
                        />
                      </Tooltip>
                    </div>
                  </Col>
                </Row>
              )}
            />
          </Table>{" "}
          {/**<div className="float-right mt-3">
            <Pagination
              defaultCurrent
              defaultPageSize
              total="2"
              //   total={totalPages}
              onChange={this.onChangePage}
              showLessItems="true"
            />
          </div> */}
        </div>
      </div>
    );
  }
}

CategoryList.propTypes = {
  getCategories: PropTypes.func.isRequired,
  deleteCategory: PropTypes.func.isRequired,
  changeCategoryStatus: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  categories: state.categoryData.categories,
  progressBarStatus: state.progressBarReducer.showProgressBar
});

export default connect(mapStateToProps, {
  getCategories,
  deleteCategory,
  changeCategoryStatus,
})(CategoryList);
