import React, { Component } from "react";
import { Table, Tooltip, Button, Space, Input, Modal, Row, Col, Popconfirm } from "antd";
import {
  EyeOutlined,
  PlusCircleOutlined,
  SearchOutlined, MinusCircleOutlined, DeleteOutlined
} from "@ant-design/icons";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { getSalesPersonReports } from "../../../../redux/actions/reportsActions";
import Highlighter from "react-highlight-words";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
// import AddImages from "./AddImages"; 
// import UpdatePurchase from "../UpdatePurchase";
// import "../../../../../assets/css/barcode.css";
var Barcode = require("react-barcode");

const { Column } = Table;

class ShowsalesPersonReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      open: false,
      visibleB: false,
      barcodes: [],
    };
  }

  showModal = () => {
    let reportObj = {
        salesPersonId: this.props.salesPersonId,
        startDate: this.props.startDate,
        endDate: this.props.endDate,
      };
    this.props.getSalesPersonReports(reportObj)
    this.setState({
      open: true,
    });
  };

  // showModalBarcode = () => {
  //   this.setState({
  //     visibleB: true,
  //     barcodes: this.props.purchases,
  //   });
  // };
  handleModalCancel = () => {
    this.setState({ open: false });
  };
  handleModalCancelBarcode = () => {
    this.setState({ visibleB: false });
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };
 

  render() {
    const { visible, visibleB } = this.state;
      
    return (
      <div>
         <button
          className="btn"
          type="button"
          data-original-title=""
          onClick={this.showModal}
        >
          <Tooltip title="Sales Person Report">
            <EyeOutlined />
          </Tooltip>
        </button>
        <Modal 
animation={false}
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleModalCancel}
          title="Sales Person Report"
          style={{ top: 20 }}
          okButtonProps={{ hidden: true }}
          cancelButtonProps={{ hidden: true }}
          destroyOnClose={true}
          width={1500}
        > {console.log("this.props.reports",this.props.reports)}
         <Table
              dataSource={this.props.reports ? this.props.reports : []}
              bordered="true"
              size="middle"
              pagination={false}
              scroll={{ y: 240 }}
            >
              <Column
                title="SRN"
                key="index"
                render={(value, item, index) => index + 1}
                width={60}
              />
              <Column
                title="Invoice Id"
                dataIndex="invoiceId"
                key="invoiceId"
                width={110}
              />
              <Column
                title="Barcode"
                dataIndex="barcode"
                key="barcode"
                width={110}
                {...this.getColumnSearchProps("barcode")}
              />
              <Column
                title="Quantity"
                dataIndex="quantity"
                key="quantity"
                {...this.getColumnSearchProps("branquantityd")}
                width={120}
              />
              <Column
                title="Brand"
                dataIndex="brand"
                key="brand"
                // {...this.getColumnSearchProps("brand")}
                width={120}
              />
              <Column
                title="Style"
                dataIndex="style"
                key="style"
                // {...this.getColumnSearchProps("style")}
                width={120}
              />
              <Column
                title="Category"
                dataIndex="category"
                key="category"
                // {...this.getColumnSearchProps("category")}
                width={120}
              />
              <Column
                title="Created Date"
                dataIndex="created_at"
                key="created_at"
                width={120}
                render={(value, item, index) =>
                  moment(item.created_at).format("YYYY-MM-DD")
                }
              />
             
            </Table>
         
        </Modal>
      </div>
    );
  }
}

ShowsalesPersonReport.propTypes = {
  getSalesPersonReports: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => (
  {
    reports: state.reportData.salespersonreports,
  }
);

export default connect(mapStateToProps, { getSalesPersonReports })(
    ShowsalesPersonReport
);
