import React from 'react';
import ReactApexChart from "react-apexcharts";
import { Row, Col, Typography } from "antd";
// import ChartData from "../Mydashboard/config/ChartData";
const { Title, Paragraph } = Typography;


const EChart = ({ data }) => {
  const items = [

    {
      Title: data?.salesData?.totalOfSalesBill[0]?.totalBoxes,
      user: "Boxes",
    },
    {
      Title: data?.salesData?.totalOfSalesBill[0]?.totalCount,
      user: "Count",
    },
    {
      Title: data?.salesData?.totalOfSalesBill[0]?.totalQuantity,
      user: "Quantity",
    },
    {
      Title: data?.salesData?.totalOfSalesBill[0]?.totalNetAmount.toFixed(),
      user: "Net-Amount",
    },
  ];

  const categories = data?.inventoryData?.inventoryData?.map(item => item._id);
  const seriesDataAmount = data?.inventoryData?.inventoryData?.map(item => item.totalNetAmount);
  const seriesDataBoxes = data?.inventoryData?.inventoryData?.map(item => item.totalBoxes);
  const seriesDataQuantity = data?.inventoryData?.inventoryData?.map(item => item.totalQuantity);



  const ChartData = {
    series: [
      // {
      //   name: "Net Amount",
      //   data: seriesDataAmount,
      //   color: "#fff",
      // },
      {
        name: "Boxes",
        data: seriesDataBoxes,
        color: "#fff",
      },
      {
        name: "Quantity",
        data: seriesDataQuantity,
        color: "#fff",
      },
    ],

    options: {
      chart: {
        type: "bar",
        width: "100%",
        height: "auto",

        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          borderRadius: 5,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["transparent"],
      },
      grid: {
        show: true,
        borderColor: "#ccc",
        strokeDashArray: 2,
      },
      xaxis: {
        categories: categories && categories.length > 0 ? categories : []  ,

        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 200,
          style: {
            colors: [
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
            ],
          },
        },
      },
      yaxis: {
        labels: {
          show: true,
          align: "right",
          minWidth: 0,
          maxWidth: 200,
          style: {
            colors: [
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
              "#fff",
            ],
          },
        },
      },

      tooltip: {
        y: {
          formatter: function (val) {
            return  val;
          },
        },
      },
    },
  };

  return (
    <>
      <div>
        <div id="chart" className='echart-scroll'>
          <ReactApexChart
            className="bar-chart"
            options={ChartData.options}
            series={ChartData.series}
            type="bar"
            height={220}
          />
        </div>
        <div className="chart-vistior">
          <Title level={5}>Inventory (Last 30 Days!)</Title>
          <Paragraph className="lastweek">
            than last week <span className="bnb2">+30%</span>
          </Paragraph>
          <Paragraph className="lastweek">
            Inventory Data of Previous 15 Days!
          </Paragraph>
          <Row gutter>
            {items.map((v, index) => (
              <Col xs={6} xl={6} sm={6} md={6} key={index}>
                <div className="chart-visitor-count">
                  <Title level={4}>{v.Title}</Title>
                  <span>{v.user}</span>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </>
  );
}

export default EChart;
