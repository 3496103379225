import React, { Component } from "react";
import { Table, Button, Space, Input, Tag, Row, Col, Tooltip, Form } from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { CSVLink } from "react-csv";
const { Column } = Table;
const data = [];

export default class CreditandDebitReportList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: "",
            current: 1,
            search: "",
            options: [],
        };
    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    // placeholder="Search For Robot"
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        this.handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ marginBottom: 8, display: "block" }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                        className="search-name"
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => this.handleReset(clearFilters, confirm)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    {/**<Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  this.setState({
                    searchText: selectedKeys[0],
                    searchedColumn: dataIndex,
                  });
                }}
              >
                Filter
              </Button> */}
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : "",
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: (text) =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = (clearFilters, confirm) => {
        clearFilters();
        confirm();
        this.setState({ searchText: "" });
    };
    listData = () => {
        const { reports } = this.props
        if (reports && reports.length > 0) {
            let newFilterData = reports.map(obj => {
                return { ...obj, c_name: obj.userId.name, agentName: obj.userId.agentId ? obj.userId.agentId.name : "" }
            })
            return (newFilterData)
        } else {
            return ([])
        }
    }

    render() {
        // const headers = [
        //   { label: "Bill Date", key: "date" },
        //   { label: "Invoice Id", key: "invoiceId" },
        //   { label: "Customer Name", key: "userId.name" },
        //   { label: "Agent Name", key: "userId.agentId.name" },
        //   { label: "Discount", key: "discount" },
        //   { label: "Labour", key: "labour" },
        //   { label: "P&F", key: "pAndf" },
        //   { label: "Tax", key: "tax" },
        //   { label: "Total Amount", key: "totalAmount" },
        //   { label: "Total Boxes", key: "totalBoxes" },
        //   { label: "Total Qnty", key: "totalQuantity" },
        //   { label: "Net Amount", key: "netAmount" },
        //   { label: "Remark", key: "remark" },
        // ];

        return (
            <>

                <Form
                    name="add-Purchase"
                    className="add-Purchase"
                    // onFinish={this.showDataOnList}
                    initialValues={{ remember: true }}
                >
                    <Row gutter={[16, 0]}>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Tooltip title="Quantity Purchase Before Start Date">
                                <Form.Item
                                >
                                    <div className="page-header" style={{ fontSize: "1.1rem" }}>
                                        Inventory Befor {moment(this.props.startDate).format("DD/MM/YYYY")}
                                    </div>
                                    <br />
                                    <Input
                                        placeholder="Quantity Purchase Before Start Date"
                                        value={this.props?.reports?.quantityPurchaseBeforeStartDate}
                                        disabled
                                    />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Tooltip title="Quantity Purchase Before End Date">
                                <Form.Item
                                //name="barcode"
                                // label="Inventory at  25/08/2023"
                                >
                                    <div className="page-header" style={{ fontSize: "1.1rem" }}>
                                        Inventory at {moment(this.props.endDate).format("DD/MM/YYYY")}
                                    </div>
                                    <br />
                                    <Input
                                        placeholder="Quantity Purchase Before End Date"
                                        value={this.props?.reports?.quantityPurchaseBeforeEndDate}
                                        // onChange={this.onBarcodeChange}
                                        // required
                                        disabled
                                    />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                    </Row>
                </Form>
                {console.log("check mohit helo check data", this.props?.reports?.purchaseSalesData)}
                <div className="card final-purchases-list mt-4 mb-4">
                    <div className="card-body">
                        <Table
                            dataSource={this.props?.reports?.purchaseSalesData}
                            bordered="true"
                            size="middle"
                            pagination={false}
                            scroll={{ y: 240 }}
                        >
                            <Column
                                title="SRN"
                                key="index"
                                render={(value, item, index) => index + 1}
                                width={60}
                            />
                            <Column
                                title="Bill Date"
                                dataIndex="date"
                                key="date"
                                width={120}
                                render={(value, item, index) => item.invoiceId ? moment(item.createdAt).format("YYYY-MM-DD") : item.billDate
                                }
                            />
                            <Column
                                title="Purchase Id"
                                dataIndex="purchaseId"
                                key="purchaseId"
                                render={(value, item, index) => `P-${item.purchaseId}`}
                                width={150}
                                {...this.getColumnSearchProps("purchaseId   ")}
                            />
                            <Column
                                title="Invoice No."
                                dataIndex="invoiceId"
                                key="invoiceId"
                                render={(value, item, index) => `S-${item.invoiceId}`}
                                width={130}
                                {...this.getColumnSearchProps("invoiceId")}
                            />
                            <Column
                                title="Customer Name"
                                dataIndex="customerName"
                                key="customerName"
                                width={180}
                                {...this.getColumnSearchProps("customerName")}
                            />
                            <Column
                                title="Dealer Name"
                                dataIndex="dealerName"
                                key="dealerName"
                                width={180}
                                {...this.getColumnSearchProps("dealerName")}
                            />
                            <Column
                                title="Brand"
                                dataIndex="dealerName"
                                key="dealerName"
                                width={180}
                            />
                            <Column
                                title="Design Number"
                                dataIndex="designNumber"
                                key="designNumber"
                                width={180}
                            />
                            <Column
                                title="Purchase Price"
                                dataIndex="purchasePrice"
                                key="purchasePrice"
                                width={180}
                            />
                            <Column
                                title="Quantity"
                                dataIndex="quantity"
                                key="quantity"
                                width={180}
                            />
                            <Column
                                title="Wsp"
                                dataIndex="wsp"
                                key="wsp"
                                width={180}
                            />

                        </Table>
                    </div>
                </div>
                <Row gutter={[16, 0]} className="mt-4">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Tooltip title="Quantity Sale Before Start Date">
                            <div className="page-header" style={{ fontSize: "1.1rem" }}>
                                Inventory Befor {moment(this.props.startDate).format("DD/MM/YYYY")}
                            </div>
                            <br />
                            <Input
                                placeholder="Quantity Sale Before Start Date"
                                value={this.props?.reports?.quantitySalesBeforeStartDate}
                                // onChange={this.onBarcodeChange}
                                // required
                                disabled
                            />
                        </Tooltip>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Tooltip title="Quantity Sale Before End Date">
                            <div className="page-header" style={{ fontSize: "1.1rem" }}>
                                Inventory Befor {moment(this.props.endDate).format("DD/MM/YYYY")}
                            </div>
                            <br />
                            <Input
                                placeholder="Quantity Sale Before End Date"
                                value={this.props?.reports?.quantitySalesBeforeEndDate}
                                // onChange={this.onBarcodeChange}
                                // required
                                disabled
                            />
                        </Tooltip>
                    </Col>
                </Row>

                {/* {(() => {
          if (this.props && this.props.reports && this.props.reports.length > 0) {
            return (
              <Button
                type="primary"
                htmlType="submit"
                className="save-modal mt-4"
                block
              >
                <CSVLink
                  filename={`${this.props.startDate} to ${this.props.endDate}.csv`}
                  data={this.props.reports}
                  headers={headers}
                >
                  Download CSV
                </CSVLink>
              </Button>
            );
          }
        })()} */}
            </>
        );
    }
}
