import React, { useState } from "react";
import { Row, Modal, Form, Button, Input, Col, Select } from "antd";
import { getDatabydesignandBrandforInventory } from "../../../redux/actions/purchaseAction";
import { useDispatch, useSelector } from "react-redux";
import { getBrandBySearch } from "../../../redux/actions/brandAction";
const { Option } = Select;
const DesignandBrandWiseSearchMobile = () => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [designNo, setDesignNo] = useState(""); // Initialize with an empty string
  const [brandName, setBrandName] = useState(""); // Initialize with an empty string
  const dispatch = useDispatch();
  const brandData = useSelector((state) => state.brandsData.brandBysearch);
  const showModal = () => {
    setVisible(true);
  };
  const handleModalCancel = () => {
    setVisible(false);
  };
  const onSubmit = () => {
    setLoading(true);
    let obj = {
      designNumber: designNo,
      brand: brandName,
    };
    dispatch(getDatabydesignandBrandforInventory(obj)).finally(() => {
      setLoading(false);
      setVisible(false);
    });
  };
  const onChange = (name, value) => {
    if (name === "brand") {
      setBrandName(value);
    }
    if (name === "designNo") {
      setDesignNo(value);
    }
  };

  const getBrandData = () => {
    dispatch(getBrandBySearch());
  };

  const brandOptions = brandData?.data?.map((brand) => {
    return (
      <Option name={brand.name} value={brand.name}>
        {brand.name}
      </Option>
    );
  });
  return (
    <>
      <Button
        className="btn-admin btn-subCategory"
        type="primary"
        onClick={() => showModal()}
      >
        Design No & Brand Search
      </Button>
      <Row>
        <Col span={24} align="center">
          <div className="add-button">
            <Modal
              visible={visible}
              onCancel={() => handleModalCancel()}
              title="Search By Design & Brand"
              style={{ top: 20 }}
              okButtonProps={{ hidden: true }}
              cancelButtonProps={{ hidden: true }}
              destroyOnClose={true}
            >
              <Form
                name="design and Brand"
                className="add-brand"
                onFinish={onSubmit}
                initialValues={{
                  remember: true,
                }}
              >
                {/* <Form.Item
                  name="brand"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Brand Name!",
                    },
                  ]}
                >
                  <Input
                    autoFocus
                    placeholder="Enter Brand"
                    name="brand"
                    value={brandName}
                    onChange={(e) => onChange("brand", e.target.value)}
                  />
                </Form.Item> */}

                <Form.Item
                  name="brand"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Brand Name !",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select Brand"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    onChange={(e) => onChange("brand", e)}
                    onClick={() => getBrandData("brand")}
                    onInputKeyDown={() => getBrandData("brand")}
                  >
                    {brandOptions}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="designNo"
                  rules={[
                    {
                      required: true,
                      message: "Please Design No !",
                    },
                  ]}
                >
                  <Input
                    placeholder="Design No"
                    name="designNo"
                    value={designNo}
                    onChange={(e) => onChange("designNo", e.target.value)}
                  />
                </Form.Item>

                <Form.Item className="float-right">
                  <Button
                    className="close-modal mr-3"
                    onClick={() => handleModalCancel()}
                  >
                    Close
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="ok-modal"
                    loading={loading}
                  >
                    Search
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default DesignandBrandWiseSearchMobile;
