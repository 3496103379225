import React, { Component } from "react";
import {
  Table,
  Popconfirm,
  Tooltip,
  Pagination,
  Button,
  Space,
  Input,
  Row,
  Col,
} from "antd";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  deletePurchase,
  getPurchases,
  getPurchasesBydate
} from "../../../../redux/actions/purchaseAction";
import Highlighter from "react-highlight-words";
import jwt_decode from "jwt-decode";
import TopBarProgress from "react-topbar-progress-indicator";
import moment from "moment";

const { Column } = Table;

class PurchaseList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      current: 1,
      search: "",
      options: [],
    };
  }
  componentDidMount() {
    let startDate = moment(new Date()).format("YYYY-MM-DD")
    let endDate = moment(new Date()).format("YYYY-MM-DD")
    let reportObj = {
      startDate: startDate,
      endDate: endDate + " " + moment().format("HH:mm:ss"),
    };
    this.props.getPurchasesBydate(reportObj)
    // this.props.getPurchases(1);
  }
  onDeleteClick = (id) => {
    this.props.deletePurchase(id);
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          // placeholder="Search For Robot"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/**<Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    this.setState({ searchText: "" });
  };

  listData = () => {
    const { purchasesBydate } = this.props
    const value = purchasesBydate && purchasesBydate.length > 0 ? purchasesBydate[0] : []
    if (value.message == "Empty") {
      return ([])
    } else if (purchasesBydate.data) {
      return (purchasesBydate.data)
    } else {
      return (this.props.purchases.data)
    }

  }

  render() {
    const storedToken = localStorage.getItem("token");
    const { purchasesBydate } = this.props
    let decToken = "";
    if (storedToken) {
      decToken = jwt_decode(storedToken);
    }
    return (
      <div className="card final-purchases-list">
        {this.props.progressBarStatus && <TopBarProgress />}
        <div className="card-body">
          <Table
            dataSource={this.listData()}
            // dataSource={purchasesBydate.data ? purchasesBydate.data :  this.props.purchases && this.props.purchases.data ? this.props.purchases.data : []}
            bordered="true"
            size="middle"
            pagination={{
              pageSize: 100,
              total: purchasesBydate?.data?.length,
            }}
            scroll={{ y: 240 }}
          >
            <Column
              title="SRN"
              key="index"
              render={(value, item, index) => index + 1}
              width={60}
            />

            <Column
              title="Barcode"
              dataIndex="barcode"
              key="barcode"
              width={100}
              {...this.getColumnSearchProps("barcode")}
            />
            <Column
              title="Design No."
              dataIndex="designNumber"
              key="designNumber"
              width={150}
              {...this.getColumnSearchProps("designNumber")}
            />
            <Column
              title="Brand"
              dataIndex="brand"
              key="brand"
              {...this.getColumnSearchProps("brand")}
              width={120}
            />
               <Column
              title="Category"
              dataIndex="category"
              key="category"
              {...this.getColumnSearchProps("category")}
              width={120}
            />
               <Column
              title="Style"
              dataIndex="style"
              key="style"
              {...this.getColumnSearchProps("style")}
              width={140}
            />
            <Column
              title="Size"
              dataIndex="size"
              key="size"
              width={80}
              {...this.getColumnSearchProps("size")}
            />
            <Column title="Color" dataIndex="color" key="color" width={80} />
            <Column title="MOQ" dataIndex="MOQ" key="MOQ" width={60} />
            <Column
              title="Price"
              dataIndex="purchasePrice"
              key="purchasePrice"
              width={90}
            />
            <Column title="WSP" dataIndex="wsp" key="wsp" width={80} />
            {/* {(() => {
              if (
                decToken.role === "6227b38275361cd819e16067" ||
                decToken.role === "629346b53e2dcbf2f6d53244"
              ) {
                return (
                  <Column
                    title="Action"
                    key="action"
                    width={90}
                    render={(text, record) => (
                      <Row gutter={[8, 0]}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <Popconfirm
                            title="Are you sure to delete this Purchase?"
                            onConfirm={() => this.onDeleteClick(text._id)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <div className="btn">
                              <Tooltip title="Delete Purchase">
                                <DeleteOutlined />
                              </Tooltip>
                            </div>
                          </Popconfirm>
                        </Col>
                      </Row>
                    )}
                  />
                );
              }
            })()} */}
          </Table>{" "}

        </div>
      </div>
    );
  }
}

PurchaseList.propTypes = {
  getPurchases: PropTypes.func.isRequired,
  deletePurchase: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  getPurchasesBydate : PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  purchases: state.purchaseData.purchases,
  purchasesBydate: state.purchaseData.purchasesBydate,
  progressBarStatus: state.progressBarReducer.showProgressBar
});

export default connect(mapStateToProps, { deletePurchase, getPurchases, getPurchasesBydate })(
  PurchaseList
);
