import React, { useState, useEffect, useRef } from "react";
import { Table, Button, Space, Input, Row, Col, Popconfirm } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import Highlighter from "react-highlight-words";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  getPurchaseBillsBydate,
  getPurchasesById,
} from "../../../../redux/actions/purchaseAction";
import Search from "../../../../util/Search";
import DateWiseSearch from "../../../../util/DateWiseSearch";
import TopBarProgress from "react-topbar-progress-indicator";
import { useHistory, withRouter } from "react-router-dom";
import ShowSampleBarcodePurchase from "./ShowSampleBarcodePurchase";
import GenerateSampleBarcode from "./GenerateSampleBarcode";
import WhSearch from "../../../../util/WhSearch";

const { Column } = Table;
const searchOptions = ["Barcode", "PurchaseId", "DealerName", "Alias"];

const SampleBarcodeList = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [progressBarStatus, setProgressBarStatus] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const data =
    useSelector((state) => state.purchaseData.purchaseSearchData.details) ||
    null;
  const searchInput = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const { searchData, purchasesBillsBydate } = useSelector((state) => ({
    searchData: state.purchaseData.purchaseSearchData,
    purchasesBillsBydate: state.purchaseData.purchasesBillsBydate,
  }));

  useEffect(() => {
    const fetchData = async () => {
      setProgressBarStatus(true);
      const localStartDate = localStorage.getItem("startDate");
      const localEndDate = localStorage.getItem("endDate");

      let startDate, endDate;

      if (history.location.state) {
        if (localStartDate && localEndDate) {
          startDate = localStartDate;
          endDate = localEndDate;
        } else {
          startDate = moment(new Date()).format("YYYY-MM-DD");
          endDate = moment(new Date()).format("YYYY-MM-DD");
        }
      } else {
        startDate = moment(new Date()).format("YYYY-MM-DD");
        endDate = moment(new Date()).format("YYYY-MM-DD");
        localStorage.setItem("startDate", startDate);
        localStorage.setItem("endDate", endDate);
      }

      const reportObj = {
        startDate: startDate,
        endDate: endDate + " " + moment().format("HH:mm:ss"),
      };

      await dispatch(getPurchaseBillsBydate(reportObj));
      setProgressBarStatus(false); // Hide progress bar
    };

    fetchData();
  }, [history.location.state, dispatch]);

  const updatePurchaseBill = async (purchaseId) => {
    await dispatch(getPurchasesById(purchaseId));
    history.push("/dagaImpex/upDatePurchase", { id: "value1" });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            className="search-name"
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };

  const listData = () => {
    if (searchText) {
      return purchasesBillsBydate.details?.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
    } else if (searchValue) {
      return data;
    } else {
      return purchasesBillsBydate.details;
    }
  };

  const updateDatestatus = () => {
    // Implement your updateDatestatus logic if needed
  };

  const onChange = (event) => {
    setSearchValue(event);
  };

  const cancelState = () => {
    setSearchValue("");
  };

  const Id = localStorage.getItem("roleName");

  return (
    <div>
      {progressBarStatus && <TopBarProgress />}
      <Row className="title-row">
        <Col span={24}>
          <div className="page-header">Sample Barcode</div>
        </Col>
      </Row>
      <Row className="mt-4" justify="space-between" align="middle">
        <Col
          xs={24}
          sm={12}
          md={12}
          lg={12}
          xl={8}
          style={{ marginBottom: 16 }}
        >
          <WhSearch
            title="Sample Barcode"
            placeholder="Dealer Name, PurchaseId or Barcode"
            searchopt={searchOptions}
            changeFunction={onChange}
            cancelState={cancelState}
            location={history.location}
          />
        </Col>
        <Col
          xs={24}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginBottom: 16 }}
          align="end"
        >
          <DateWiseSearch
            keyId={"Purchase Bill"}
            updateDatestatus={updateDatestatus}
            historyProps={history}
          />
        </Col>
      </Row>

      <div className="card final-purchases-list">
        <div className="card-body">
          <Table
            dataSource={listData()}
            bordered={true}
            size="middle"
            pagination={{
              pageSize: 100,
              total: searchData
                ? searchData?.details?.length
                : purchasesBillsBydate?.details?.length,
            }}
            scroll={{ y: 440 }}
          >
            <Column
              title="SRN"
              key="index"
              render={(value, item, index) => index + 1}
              width={60}
            />
            <Column
              title="Purchase Id"
              dataIndex="purchaseId"
              key="purchaseId"
              width={150}
              {...getColumnSearchProps("purchaseId")}
            />
            <Column
              title="Dealer Name"
              dataIndex="dealerName"
              key="dealerName"
              width={150}
              {...getColumnSearchProps("dealerName")}
            />
            <Column
              title="Location"
              dataIndex="location"
              key="location"
              width={150}
            />
            <Column
              title="Bill No"
              dataIndex="billNo"
              key="billNo"
              width={120}
              {...getColumnSearchProps("billNo")}
            />
            <Column
              title="C_Date"
              dataIndex="created_at"
              key="created_at"
              width={130}
              render={(value, item, index) =>
                moment(item.created_at).format("YYYY-MM-DD")
              }
            />
            <Column
              title="B_Date"
              dataIndex="date"
              key="date"
              width={130}
              render={(value, item, index) =>
                moment(item.date).format("YYYY-MM-DD")
              }
            />
            <Column
              title="Qnty"
              dataIndex="quantity"
              key="quantity"
              width={90}
            />
            <Column
              title="Total Amt"
              dataIndex="totalAmount"
              key="totalAmount"
              render={(value, item, index) =>
                parseInt(item.totalAmount).toFixed()
              }
              width={120}
            />
            <Column
              title="Disc"
              dataIndex="discount"
              key="discount"
              width={90}
            />
            <Column title="Tax" dataIndex="tax" key="tax" width={90} />
            <Column
              title="Net Amt"
              dataIndex="netAmount"
              key="netAmount"
              render={(value, item, index) => item.netAmount.toFixed()}
              width={120}
            />
            <Column
              title="Action"
              key="action"
              fixed="right"
              width={120}
              render={(text, record) => {
                if (Id === "SALES_ADMIN" || Id === "ACCOUNTS") {
                  return (
                    <Row gutter={[8, 0]}>
                      <Col span={24}>
                        <a>
                          <ShowSampleBarcodePurchase
                            purchaseId={record.purchaseId}
                            data={record}
                          />
                        </a>
                      </Col>
                    </Row>
                  );
                } else {
                  return (
                    <Row gutter={[8, 0]}>
                      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <a>
                          <ShowSampleBarcodePurchase
                            purchaseId={record.purchaseId}
                            data={record}
                          />
                        </a>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <div className="btn">
                          <GenerateSampleBarcode
                            purchaseId={record.purchaseId}
                          />
                        </div>
                      </Col>
                    </Row>
                  );
                }
              }}
            />
          </Table>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SampleBarcodeList);
