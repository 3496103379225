import React, { Component } from "react";
import { Table, Row, Col,Popconfirm, Tooltip } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";

const { Column } = Table;

export default class AddedReturnsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      returnData: [],
    };
  }
  shouldComponentUpdate(nextProps, prevState) {
    if (
      nextProps.data != undefined &&
      nextProps.data.length != prevState.returnData.length
    ) {
      this.setState({
        returnData: nextProps.data,
      });
    }

    return true;
  }
  onRemoveQnty = (obj) => {
    this.props.onRemoveQnty(obj);
  };
  onAddQnty = (obj) => {
    this.props.onAddQnty(obj);
  };
  onDeleteClick = (id) => {
    this.props.deletePurchase(id);
    
  };
  onDeleteClick2 = (_id) => {
    console.log("addedList", _id)
    this.props.deletePurchaseReturn(_id);

  };
  render() {
    console.log("#state", this.state.returnData);
    console.log("#props", this.props.data);
    let data;
    return (
      <div className="purchase-list mt-3">
        <Table
          dataSource={this.props.data}
          bordered="true"
          size="middle"
          pagination={false}
          scroll={{ y: 240 }}
        >
          <Column
            title="SRN"
            key="index"
            render={(value, item, index) => index + 1}
            width={60}
          />
          <Column
            title="Purchase Id"
            dataIndex="purchaseId"
            key="purchaseId"
            width={110}
          />
           <Column
            title="Barcode"
            dataIndex="barcode"
            key="barcode"
            width={110}
          />
          <Column
            title="Qnty"
            dataIndex="quantity"
            key="quantity"
            width={110}
          />
             <Column
            title="Purchase Price"
            dataIndex="purchasePrice"
            key="purchasePrice"
            width={110}
            // render={(value, item, index) => item.quantity /item.MOQ }
          />
             <Column
            title="Amount"
            dataIndex="purchasePrice"
            key="purchasePrice"
            width={110}
            render={(value, item, index) => Number(item.quantity * item.purchasePrice )}
          />
          <Column
            title="Action"
            key="action"
            width={75}
            render={(text, record, index) => (
              <Row gutter={[8, 0]}>
                {(() => {
                  data = JSON.parse(JSON.stringify(text));
                  data.index = index;
                  console.log("TEXT", text)
                  console.log("TEXT.ID", text.id)
                  console.log("TEXT._ID", text._id)
                })()}
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  {(() => {
                    if (text.quantity >= this.props.quantity) {
                      return (
                        <Tooltip title="Add Qnty">
                          <a type="button" onClick={() => this.onAddQnty(text)}>
                            <PlusCircleOutlined />
                          </a>
                        </Tooltip>
                      );
                    } else {
                      return (
                        <Tooltip title="Add Qnty">
                          <a type="button" onClick={() => this.onAddQnty(text)}>
                            <PlusCircleOutlined />
                          </a>
                        </Tooltip>
                      );
                    }
                  })()}
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  {(() => {
                    if (text.quantity <= 1) {
                      return (
                        <Tooltip title="Remove Qnty">
                          <a type="button" aria-disabled>
                            <MinusCircleOutlined />
                          </a>
                        </Tooltip>
                      );
                    } else {
                      return (
                        <Tooltip
                          title="Remove Qnty"
                          onClick={() => this.onRemoveQnty(text)}
                        >
                          <a type="button">
                            <MinusCircleOutlined />
                          </a>
                        </Tooltip>
                      );
                    }
                  })()}
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  {(() =>{
                    if(text.id){
                      {console.log("delete .id", text.id)}
                      return(
                      <Popconfirm
                      title="Are you sure to delete this Record?"
                      onConfirm={() => this.onDeleteClick(text.id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Tooltip title="Delete Record">
                        <DeleteOutlined />
                      </Tooltip>
                    </Popconfirm>
                      );

                    }else{
                      {console.log("delete ._id", text._id)}
                      return(
                      <Popconfirm
                      title="Are you sure to delete this Record?"
                      onConfirm={() => this.onDeleteClick2(text._id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Tooltip title="Delete Record">
                        <DeleteOutlined />
                      </Tooltip>
                    </Popconfirm>
                      );

                    }
                  })()}
                    
                  </Col>
              </Row>
            )}
          />
        </Table>
      </div>
    );
  }
}
